import React, { useState } from "react";

import compact from "lodash/compact";
import { humanize } from "../../utils/railsNames";
import { Pagination  } from "rsuite";
import DopeTable from "../../ui/DopeTable";
import ExternalObjectViewer from "./ExternalObjectViewer";


const paginationWrapperStyles = { padding: 10 };
const paginationLocale = { total: 'Total: {0}' };
const filterWrapperStyles = { display: 'none' };

const fieldsToSkip = ["_id", "__v", "account", "dataSource", "dataType", "firstName", "lastName", "city", "state", "zip", "address_1", "address2", "address_2", "phone", "country", "zip4"];

const fieldToLabelMap = {
  dataId: "ID",
  dataCreatedAt: "Created",
  createdAt: "First Synced",
  dataUpdatedAt: "Updated",
  updatedAt: "Last Synced",
  full_name: "Name",
  address1: "Address",
};

const fieldToCellTypeMap = {
  dataId: "clickable_text",
  dataCreatedAt: "timestamp",
  dataUpdatedAt: "timestamp",
  createdAt: "timestamp",
  updatedAt: "timestamp",
  address1: "address_text",
};

const fieldToLabel = (field) => {
  return fieldToLabelMap[field] || humanize(field);
};

const itemToColumns = (item) => {
  const cols = compact(Object.keys(item).map(key => {
    if (fieldsToSkip.includes(key)) return null;

    return {
      type: fieldToCellTypeMap[key] || "text",
      dataKey: key,
      label: fieldToLabel(key),
      resizable: true,
      sortable: key !== "full_name", // TODO hate all these key changes....
      fullText: true,
      humanize: true,
    };
  }));

  const fieldsInOrder = Object.keys(fieldToLabelMap);
  const fieldToOrder = (field) => {
    const index = fieldsInOrder.indexOf(field);
    if (index === -1) return fieldsInOrder.length;
    return index;
  };

  return cols.sort((a, b) => {
    return fieldToOrder(a.dataKey) - fieldToOrder(b.dataKey);
  });
};

const parseData = (data) => {
  if (!data) return [];

  return data.map((item) => { // play nice with table cells
    return {
      ...item,
      full_name: `${item.firstName || '---'} ${item.lastName || '---'}`,
      city: item.city || '---',
      state: item.state || '---',
      zip: item.zip || '---',
      address_1: item.address1 || '---',
      address_2: item.address2 || '',
    };
  });
};

const DataTypeTable = ({ paginationProps = {}, tableProps = {} }) => {
  const [showingId, setShowingId] = useState(null);
  let { columns, data = [] } = tableProps;

  const parsedData = parseData(data);

  if (!columns) {
    const firstItem = parsedData[0];
    if (firstItem) {
      columns = itemToColumns(firstItem);
    } else {
      columns = [];
    }
  }

  const onClickDataId = (eo) => {
    setShowingId(eo._id);
  };

  return (
    <>
      <DopeTable
        filterWrapperStyles={filterWrapperStyles}
        {...tableProps}
        data={parsedData}
        columns={columns}
        onClickDataId={onClickDataId}
      />

      <div style={paginationWrapperStyles}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          limitOptions={[10, 25, 50]}
          locale={paginationLocale}
          {...paginationProps}
        />
      </div>

      <ExternalObjectViewer id={showingId} onClose={() => setShowingId(null)} />
    </>
  );
};

export default DataTypeTable;
