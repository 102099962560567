import React, { useEffect, useState } from "react";
import { Drawer } from "rsuite";

import DopeButton from "../ui/DopeButton";
import { useList } from "./listSlice";
import { DopeFormGroup } from "../ui/DopeForm";

const CreateListDrawer = ({ open, onClose, setListCount, listCount }) => {
  const [loading, setLoading] = useState(false);
  const [list, actions] = useList();

  useEffect(() => {
    actions.get("new");
  }, []);

  useEffect(() => {
    if (open) {
      actions.get("new");
    }
  }, [open]);

  const saveList = async () => {
    setLoading(true);
    try {
      await actions.save();
      setListCount(listCount + 1);
      onClose();
    } catch (error) {
      console.error("Error saving list", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Drawer open={open} onClose={onClose} size="sm">
      <Drawer.Body>
        <div className="margin-bottom">
          <h3 className="header-3">Create a List</h3>
          <p className="body-text">Start by giving your list a name, then use filters in our Lists & Contacts to build a list within the app. Add tags to help organize your marketing strategies.</p>
        </div>

        <DopeFormGroup
          input={{
            inputType: "text",
            name: "name",
            props: { label: "List Name" }
          }}
          values={list}
          onChange={actions.update}
          className="margin-bottom"
        />

        <DopeFormGroup
          input={{
            inputType: "tags",
            name: "taggings",
            props: { label: "Tags", modelName: "list" }
          }}
          values={list}
          onChange={actions.update}
          className="margin-80-b"
        />

        <div className="space-between pad">
          <DopeButton
            props={{
              label: "Cancel",
              onClick: onClose,
              buttonClass: "outlined-black action-button",
            }}
          />
          <DopeButton
            props={{
              label: "Create",
              onClick: saveList,
              buttonClass: "filled-black action-button",
            }}
            loading={loading}
          />
        </div>
      </Drawer.Body>
    </Drawer>
  );
}

export default CreateListDrawer;
