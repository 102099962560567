import React, { useState } from "react";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import StripeApi from "../stripe/StripeApi";
import { useStripeUI } from "../stripe/stripeUISlice";
import DopeTable from "../ui/DopeTable";
import DopeDrawer from "../ui/DopeDrawer";
import InvoiceDrawer from "../stripe/InvoiceDrawer";
import {unixTimestampToDateStr} from "../utils/date";
import DopeButton from "../ui/DopeButton";

const accountApi = new DopeApi("account");
const stripeApi = new StripeApi;

const invoiceIDStyles = {
  fontSize: '12px',
  fontWeight: 400,
  color: '#255FDF'
}

const columns = [
  { type: 'clickable_text', label: 'Invoice', dataKey: 'number', customStyles: invoiceIDStyles },
  { type: 'text', label: 'Company Name', dataKey: 'account' },
  { type: 'date', label: 'Created Date', dataKey: 'created', dateConverter: unixTimestampToDateStr },
  { type: 'text', label: 'Invoice Type', dataKey: 'billing_reason' },
  { type: 'currency', label: 'Amount', dataKey: 'amount_due' },
  { type: 'pill', label: 'Status', dataKey: 'status', pillTypeKey: 'status' },
];

const AllInvoicesTable = () => {
  const [account, setAccount] = useState(null);
  const [stripeUI, stripeUiActions] = useStripeUI();
  const { tableProps, reload, rest, loading } = useQuery2({
    api: stripeApi,
    initialScopes: [{ name: 'all_invoices' }],
    initialPerPage: 10
  });

  const getAccount = async (rowData) => {
    try {
      const accountData = await accountApi.get(rowData.subscription_details.metadata.dope_account_id);
      setAccount(accountData);

    } catch (err) {
      console.error(err);
    } finally {
      stripeUiActions.setDrawerInvoiceId(rowData.id);
    }
  }

  const nextPage = () => {
    reload({ starting_after: rest.next_cursor });
  };

  const previousPage = () => {
    reload({ ending_before: rest.previous_cursor });
  };

  return (
    <div>
      <DopeTable
        {...tableProps}
        columns={columns}
        onClickNumber={getAccount}
      />

      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <DopeButton
          disabled={!rest.has_previous || loading}
          props={{
            onClick: previousPage,
            label: "Previous Page",
            buttonClass: "text-link"
          }}
        />
        <DopeButton
          disabled={!rest.has_next || loading}
          props={{
            onClick: nextPage,
            label: "Next Page",
            buttonClass: "text-link"
          }}
        />
      </div>

      <DopeDrawer
        className="invoice-drawer"
        open={!!stripeUI.drawerInvoiceId}
        onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
        bodyContent={
          <InvoiceDrawer
            id={stripeUI.drawerInvoiceId}
            account={account}
            onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
          />
        }
      />
    </div>
  );
}

export default AllInvoicesTable;
