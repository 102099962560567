import React, { useEffect } from "react";
import { SelectPicker } from "rsuite";
import './Subscriptions.scss';
import { useAccount } from "../accounts/accountSlice";

const planClass = {
  "LIL' TASTE": "lil-taste",
  "DOPE": "dope",
  "DOPER": "doper",
  "DOPEST": "dopest",
  "FREEBIE": "freebie",
}

const SubscriptionItem = ({ subscription, discounts }) => {
  const subscriptionPrice = subscription.unit_amount / 100;
  const discountsPresent = discounts?.amount_off > 0 || discounts?.percent_off > 0;

  return (
    <div className="flex column top-align pad">
      <div className={`subscription-logo ${planClass[subscription.nickname]}`}>
        {subscription.nickname}
      </div>
      <div className="selector-price-interval">
        <div className={discountsPresent ? "discounted-price-interval" : ""}>${subscriptionPrice}/{subscription.recurring.interval}</div>
        {discounts?.amount_off > 0 && <div>${subscriptionPrice - discounts?.amount_off / 100}/{subscription.recurring.interval}</div>}
        {discounts?.percent_off > 0 && <div>${subscriptionPrice - (subscriptionPrice * discounts?.percent_off / 100)}/{subscription.recurring.interval}</div>}
      </div>
    </div>
  )
}

const SubscriptionSelectDropdown = ({ selectedSubscription, subscriptionOptions, handleSelectChange }) => {
  const [account, accountActions] = useAccount();

  useEffect(() => {
    const defaultSubscription = subscriptionOptions.find(sub => sub.nickname === "DOPE" && sub.recurring.interval === "year");
    if (defaultSubscription) {
      handleSelectChange(defaultSubscription.id);
    }
  }, [subscriptionOptions])

  const discounts = {
    amount_off: account.subscription_promo_code_data?.amount_off || 0,
    percent_off: account.subscription_promo_code_data?.percent_off || 0,
  }

  return (
    <>
      <h5 className="subscription-subheader">Your Subscription</h5>
      <SelectPicker
        data={subscriptionOptions}
        onChange={handleSelectChange}
        searchable={false}
        placeholder={"Select a plan"}
        value={selectedSubscription}
        cleanable={false}
        style={{width: "481px"}}
        renderValue={(value, item) => {
          if (item) {
            return <SubscriptionItem subscription={item} discounts={discounts} />;
          }
          return null;
        }}
        renderMenuItem={(label, item) => (
          <SubscriptionItem subscription={item}/>
        )}
      />
    </>
  )
}

export default SubscriptionSelectDropdown;
