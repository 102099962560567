import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate, Outlet, matchPath } from 'react-router-dom';
import { Drawer } from 'rsuite';

import { useCampaign } from './campaignSlice';
import { useCampaignUI } from './campaignUISlice';
import { useDopeUI } from '../ui/dopeUISlice';
import { toDateString } from "../utils/date";
import useDopePageTabs from '../ui/useDopePageTabs';
import DopePageLoader from '../ui/DopePageLoader';
import { Button } from 'rsuite';
import DopeButton from "../ui/DopeButton";
import { DopeFormGroup } from "../ui/DopeForm";

import './Campaign.scss';
import accountAbility from "../auth/accountAbility";
import Cannot from "../ui/Cannot";

const listBlitzCampaignPages = [
  {
    name: 'Campaign setup',
    path: '/campaigns/:id/details',
    isValid: (campaign) => {

      return !!campaign.list_generation_setting.starting_list_id;
    },
  }
];

const campaignPages = [
  {
    name: 'Define your audience',
    path: '/campaigns/:id/audience',
    isValid: (campaign) => {
      const isListBlitz = campaign.list_generation_setting.generation_type === 'list_blitz';
      if (isListBlitz) {
        return !!campaign.list_generation_setting.starting_list_id;
      }

      const { latitude, longitude, address_1, zip } = campaign.list_generation_setting;
      const hasStartingAddress = !!latitude && !!longitude && !!address_1 && !!zip;
      const hasShape = !!campaign.list_generation_setting.geo_shapes.length;
      const hasPermissions = accountAbility.can('create', 'Campaign', campaign)

      return hasStartingAddress && hasShape && hasPermissions;
    },
  },
  {
    name: 'Schedule your campaign',
    path: '/campaigns/:id/mailers',
    isValid: (campaign) => {
      return !!campaign.dispatches.length;
    },
  },
  {
    name: 'Name, review & submit',
    path: '/campaigns/:id/schedule',
    isValid: (campaign) => {
      const hasDispatches = !!campaign.dispatches.length;
      const noDispatchesAreInPast = campaign.dispatches.every(d => d.date >= toDateString(new Date()));

      return hasDispatches && noDispatchesAreInPast && campaign.confirmedPurchase;
    },
  }
];

const listCampaignPages = [
  {
    name: 'Campaign Setup',
    path: '/campaigns/:id/details',
    isValid: (campaign) => {
      const hasStartingList = !!campaign.list_generation_setting.starting_list_id;

      return hasStartingList || campaign.is_automation;
    },
  },
  {
    name: 'Schedule your campaign',
    path: '/campaigns/:id/mailers',
    isValid: (campaign) => {
      return !!campaign.dispatches.length;
    }
  },
  {
    name: 'Name, review & submit',
    path: '/campaigns/:id/schedule',
    isValid: (campaign) => {
      return !!campaign.dispatches.length && campaign.confirmedPurchase;
    },
  }
];

const eddmCampaignPages = [
  {
    name: 'Select your routes',
    path: '/campaigns/:id/eddm_routes',
    isValid: (campaign) => {
      const hasZipCode = !!campaign.eddm.zip_code;
      const hasRoutes = !!campaign.eddm.zip_crids.length;

      return hasZipCode && hasRoutes;
    },
  },
  {
    name: 'Schedule your campaign',
    path: '/campaigns/:id/mailers',
    isValid: (campaign) => {
      return !!campaign.dispatches.length;
    },
  },
  {
    name: 'Name, review & submit',
    path: '/campaigns/:id/schedule',
    isValid: (campaign) => {
      return !!campaign.dispatches.length && campaign.confirmedPurchase;
    },
  }
];

const getPages = (campaign) => {
  if (campaign.audience_generation_type === "eddm") {
    return eddmCampaignPages;
  }
  if (campaign.list_generation_setting.generation_type === "list") {
    return listCampaignPages;
  }
  if (campaign.list_generation_setting.generation_type === "list_blitz") {
    return [...listBlitzCampaignPages, ...campaignPages];
  }

  return campaignPages;
};

const CampaignPages = () => {
  const [campaignNameDrawerOpen, setCampaignNameDrawerOpen] = useState(false);
  const [campaign, actions] = useCampaign();
  const [ui] = useCampaignUI();
  const [dopeUI, { addFlashMessage }] = useDopeUI();
  const navigate = useNavigate();

  const isEddm = campaign.is_eddm;
  const pages = getPages(campaign, isEddm);

  const {
    PageTabs,
    BackLink,
    navigateForward,
    isSecondPage,
    isLastPage,
    isSecondToLastPage,
    isValid,
  } = useDopePageTabs({ pages, resource: {...campaign, confirmedPurchase: ui.confirmedPurchase}, exitUrl: '/campaigns', exitText: 'Back to all Campaigns' });

  useEffect(() => {
    if (campaign.eddm?.zip_code) {
      actions.fetchZipCodeRoutes(campaign.eddm?.zip_code);
    }
  }, [campaign.eddm?.zip_code]);

  const nextDisabled = !isValid;

  const continueButton = (
    <DopeButton
      disabled={nextDisabled}
      className="next-btn"
      props={{
        buttonClass: 'filled',
        label: isSecondToLastPage ? 'Save & Review' : 'Next',
        onClick: navigateForward,
      }}
    />
  );

  const handleSchedule = () => {
    if (campaign.name.includes("Campaign Draft")) {
      setCampaignNameDrawerOpen(true);
      return;
    }

    setCampaignNameDrawerOpen(false);

    actions.schedule()
      .then(actionResult => {
        const campaign = actionResult.payload;
        const scheduleResults = campaign.action_results.schedule;

        if (scheduleResults.success) {
          navigate(`/campaigns`);
        } else {
          scheduleResults.error_messages.forEach(body => addFlashMessage({ body, timeout: 10000, type: 'error' }));
        }
      });
  };

  // TODO: find a way to pass disabled to children of Cannot, avoid redundant checks
  const canSaveCampaign = accountAbility.can('create', 'Campaign', campaign);

  const scheduleButton = (
    <DopeButton
      disabled={nextDisabled || !ui.confirmedPurchase || !canSaveCampaign}
      loading={ui.saving}
      className="next-btn"
      props={{
        buttonClass: 'filled',
        label: 'Schedule Campaign',
        onClick: handleSchedule,
      }}
    />
  );

  const cannotTooltip = (
    <div>
      <div className="margin-4-b">The subscription level you’re on does not include B2B blitz.</div>
      <div>Interested in adding B2B in the blitz tool? Reach out to your Account Manager or our support team for assistance!</div>
    </div>
  );
  const canGoToNextPage = button => <Cannot
    action="create"
    subject="Campaign"
    data={campaign}
    cannotDisplay={{ tooltip: cannotTooltip }}
  >{button}</Cannot>


  const nextButton = isLastPage ? canGoToNextPage(scheduleButton) : isSecondPage ? canGoToNextPage(continueButton) : continueButton;

  const withActions = !campaign.scheduled;

  const actionsElement = withActions && (
    <div className="footer-actions margin-bottom">
      <Cannot action="create" subject="Campaign" data={campaign} cannotDisplay={{ tooltip: "Your current subscription does not support campaigns of this type" }}>
        <Button disabled={!canSaveCampaign} className="save-btn" appearance="subtle" onClick={actions.save} loading={ui.saving}>Save as draft</Button>
      </Cannot>
      {nextButton}
    </div>
  );

  const pageTabs = <PageTabs />;

  return (
    <div className='campaign-page-wrapper'>

      <BackLink className="margin" />

      <div className='campaign-page'>
        <div>

          <Outlet context={{ pageTabs }} />

          {actionsElement}

          <Drawer open={campaignNameDrawerOpen} onClose={() => setCampaignNameDrawerOpen(false)}>
            <Drawer.Body>
              <div className="flex column space-between full-height">
                <div>
                  <h3 className="header-3 margin-8-b">We need to name your campaign to schedule</h3>
                  <div className="body-1 color-text-secondary margin-bottom">To save your campaign give it a name.</div>
                  <div className="margin-80-b">
                    <DopeFormGroup input={{ inputType: "text", name: "name", props: { label: "Campaign Name" } }} values={campaign} onChange={actions.update} errors={campaign.errors} />
                  </div>
                </div>


                <div className="flex row space-between">
                  <DopeButton
                    props={{
                      buttonClass: 'outlined-black half-width margin-8-r',
                      label: 'Cancel',
                      onClick: () => setCampaignNameDrawerOpen(false),
                    }}
                  />

                  <DopeButton
                    props={{
                      buttonClass: 'filled-black half-width margin-8-l',
                      label: 'Schedule Campaign',
                      onClick: () => {
                        handleSchedule();
                      },
                    }}
                  />
                </div>
              </div>
            </Drawer.Body>
          </Drawer>
        </div>
      </div>

    </div>
  );
};

const Campaign = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [campaign, actions] = useCampaign();
  const [ui, uiActions] = useCampaignUI();

  useEffect(() => {
    if (!campaign) {
      actions.get(id + location.search)
        .then(actionResult => {
          const camp = actionResult.payload;
          if (!camp.scheduled) {
            const pages = getPages(camp);
            const firstInvalidPage = pages.find(page => !page.isValid(camp));
            const toPage = firstInvalidPage ? firstInvalidPage.path : pages[pages.length - 1].path;
            const to = toPage.replace(':id', id);
            navigate(to);
          }
        });
    }

    return () => {
      uiActions.resetToInitial();
      actions.resetToInitial();
    };
  }, []);

  if (!campaign) {
    return (<DopePageLoader />);
  }

  const isProcessing = campaign.status === 'generating' || campaign.status === 'queued';
  if (isProcessing) {
    return (
      <div className="page header-5 text-center">
        Campaign {campaign.name} is processing. Please check back later.
      </div>
    );
  }

  const isEddm = campaign.is_eddm

  const pages = getPages(campaign, isEddm);
  const isFormPath = pages.some(page => matchPath({ path: page.path }, location.pathname));

  return isFormPath ? (<CampaignPages />) : (<Outlet />);
};

export default Campaign;
