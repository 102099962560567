import React from 'react';
import { capitalize } from 'lodash';
import { useIntegrations, withIntegrations } from "../integrationsSlice";


function generateConditionsSummary(condition, options, depth = 0) {
  const summaryComponents = [];

  if ('rules' in condition) {
    const { combinator, rules } = condition;

    rules.forEach((rule, index) => {
      const components = generateConditionsSummary(rule, options, depth + 1);
      summaryComponents.push(...components);

      if (index < rules.length - 1) {
        summaryComponents.push(<br key={"br1" + depth + index} />);
        summaryComponents.push(<br key={"br2" + depth + index} />);
        summaryComponents.push(
          <span key={`combinator-${depth}-${index}`} className={`condition-${depth} subscription-summary_logic-label`}>
            {combinator.toUpperCase()}
          </span>
        );
      }
    });

    return summaryComponents;
  }

  const { field, operator, value } = condition;

  const fieldOption = options.dataFields.find(df => df.value === field);
  const fieldDisplay = fieldOption?.label || field; // TODO humanize fallback

  const operatorOption = options.operators.find(op => op.value === operator);
  const operatorDisplay = operatorOption?.label || operator;
  const operatorIsUnary = operatorOption?.isUnary || false;

  const valueType = options.field_to_type_map[field] || fieldOption?.value_type || 'string';

  let valueDisplay;
  if (value === '' || operatorIsUnary) {
    valueDisplay = '';
  } else if (valueType === 'date') {
    valueDisplay = value.split("T")[0];
  } else if (valueType === 'boolean') {
    valueDisplay = (<b>{value === 'true' ? 'TRUE' : 'FALSE'}</b>);
  } else if (valueType === 'number') {
    valueDisplay = (<b>{value}</b>);
  } else {
    valueDisplay = `"${value}"`;
  }

  return [
    <span key={condition.id}>
      <span className="subscription-summary_field-label">{fieldDisplay}</span>
      <span className="subscription-summary_operator-label"> {operatorDisplay}</span>
      <span className="subscription-summary_value-label">{valueDisplay}</span>
    </span>
  ];
}

const SubscriptionSummary = () => {
  const { subscription, operators, dataFields } = useIntegrations();
  const { data_source_label, data_type, event, conditions, field_to_type_map } = subscription;

  const conditionsSummary = generateConditionsSummary(conditions, {
    operators,
    dataFields,
    field_to_type_map
  });

  const hasConditions = conditionsSummary.length > 0; // TODO this is always true for dope but should make this work (above depends on it being > 1?)

  return (
    <div className='subscription-summary'>

      <div className='subscription-summary_conditions'>
        <div className="subscription-summary_trigger">Trigger</div>
        <div className="subscription-summary_header">
          When a
          <span className="subscription-summary_type-label"> {capitalize(data_type)} </span>
          is
          <span className="subscription-summary_type-label"> {capitalize(event)}d </span>
          in
          <span className="subscription-summary_type-label"> {data_source_label} </span>
          {hasConditions && (<span>and...</span>)}
        </div>

        <div className="subscription-summary_conditions-summary">
          {hasConditions && (<span className="subscription-summary_logic-label condition-0">IF</span>)}
          {conditionsSummary}
        </div>
      </div>

    </div>
  );
};

export default withIntegrations(SubscriptionSummary);
