import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Dropdown, Input, Checkbox} from "rsuite";
import { Icon } from "@rsuite/icons";
import DopeButton from "../DopeButton";
import {MdCheck, MdOutlineArrowDropDown} from "react-icons/md";

const dropdownItemStyles = {
  fontWeight: 600,
  fontSize: '14px',
  color: '#292B2E',
}

const DopeCheckboxFilterDropdown = ({ filterInput, filters }) => {
  const { title, getOptions, applyFilter, searchable, stateValue, placement = "bottomStart" } = filterInput;
  const [searchValue, setSearchValue] = useState("");
  const [nextFilters, setNextFilters] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [allFilterOptions, setAllFilterOptions] = useState([]);
  const [firstFilterLoad, setFirstFilterLoad] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const getFilterOptions = async () => {
      const options = await getOptions();
      setAllFilterOptions(options)
      setFilterOptions(options)
    }

    getFilterOptions();

    if (stateValue && firstFilterLoad && location.state && location.state[stateValue]) {
      location.state[stateValue].forEach((filter) => {
        handleApplyFilter(filter)
      });
      setFirstFilterLoad(false);
    }

    setFirstFilterLoad(false);
  }, []);

  useEffect(() => {
    // TODO: Revisit this, maybe pass filter field to filterInput, this is needed for 'Clear All' button
    if (filters.filter(filter => filter.hasOwnProperty('filter_category')).length === 0 && !firstFilterLoad) {
      handleResetFilters();
    }
  }, [filters]);

  const handleSearchInput = async (value) => {
    setSearchValue(value);

    if (value === "") {
      setFilterOptions(allFilterOptions);
      return;
    }

    if (searchable) {
      const searchedInputs = await getOptions(value);
      setFilterOptions(searchedInputs);
    } else {
      const searchedInputs = filterOptions.filter((input) =>
        input.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilterOptions(searchedInputs);
    }
  }

  const handleApplyFilter = (input, value, checked) => {
    let newFilters;
    if (nextFilters.some(obj => obj.value === input.value)) {
      newFilters = nextFilters.filter((i) => i.value !== input.value);
    } else {
      newFilters = [...nextFilters, input];
    }
    setNextFilters(newFilters);
    applyFilter(newFilters, value, checked);
  };

  const applyAllFilters = () => {
    applyFilter(allFilterOptions, true);
    setNextFilters(allFilterOptions);
  }

  const handleResetFilters = () => {
    setNextFilters([]);
    applyFilter(null, false);
  }

  const checked = (option) => {
    return nextFilters.some(obj => obj.value === option.value)
  }

  const selectedCount = nextFilters.length;
  const active = selectedCount > 0;

  const dropdownTitle = () => {
    return (
      <div>
        {active && <Icon as={MdCheck} style={{marginRight: "6px"}}/>}
        {title}
        <span style={{fontSize: "11px", fontWeight: 700, marginLeft: "6px"}}>{active && `+${selectedCount}`}</span>
        <Icon as={MdOutlineArrowDropDown} style={{ fontSize: "20px", color: "#494B4E", marginLeft: "6px"}} />
      </div>
    )
  }

  const filterOptionsPresent = allFilterOptions.length > 0;

  return (
    <>
    <Dropdown
      title={dropdownTitle()}
      toggleAs={"div"}
      placement={placement}
      menuStyle={{marginTop: "5px"}}
      className={`dope-filter-dropdown ${active ? "selected-filter" : ""}`}
    >
      {filterOptionsPresent ? (
      <div className="pad">
        <div>
          <Input
            value={searchValue}
            onChange={(value) => handleSearchInput(value)}
            placeholder={`Search for a ${title}`}
            style={{marginBottom: 5}}
          />
          <div
            style={{width: 300, maxHeight: 200, overflowY: 'auto', borderRadius: "8px", border: "1px solid #F3F3F3"}}>
            {filterOptions && filterOptions.map((option, index) => (
              <div key={index}>
                <Checkbox
                  style={dropdownItemStyles}
                  checked={checked(option)}
                  onChange={() => handleApplyFilter(option, option.value, !checked(option))}
                >
                  <div style={dropdownItemStyles}>{option.label}</div>
                </Checkbox>
              </div>
            ))}
          </div>
          <div style={{marginTop: "10px"}} className="space-between row">
            <DopeButton
              props={{
                buttonClass: "filled-gray",
                label: "Select All",
                onClick: () => applyAllFilters(),
                styles: {
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "4px 20px",
                }
              }}
            />

            <DopeButton
              props={{
                buttonClass: "text-link",
                label: "Reset Filters",
                onClick: handleResetFilters,
                styles: {fontSize: "14px", fontWeight: 600, padding: "4px", textDecoration: "none"}
              }}
            />

          </div>
        </div>
      </div>
      ) : (
      <div style={{padding: "20px", display: "flex", flexDirection: "row", width: "200px", justifyContent: "center"}}>
        <div style={{ color: "#8793A6"}}>
          No Options to Select
        </div>
      </div>)}
    </Dropdown>
    </>
  )
}

export default DopeCheckboxFilterDropdown
