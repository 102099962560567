import React, { useState } from 'react';

import { useAutomation } from './automationSlice';
import { SubscriptionsAPI } from '../integrations/IntegrationsAPI';
import { DopeLinkButton } from "../ui/DopeButton";

const AutomationSyncButton = ({ reload }) => {
  const [syncing, setSyncing] = useState(false);

  const { automation } = useAutomation();
  const { subscription_id } = automation;

  if (!subscription_id) {
    return null;
  }

  const onClickSync = async () => {
    setSyncing(true);
    await SubscriptionsAPI.sync(subscription_id);
    if (reload) {
      await reload();
    }
    setSyncing(false);
  };

  return (
    <DopeLinkButton
      disabled={syncing}
      loading={syncing}
      onClick={onClickSync}
    >
      {syncing ? 'Syncing New Data...' : 'Check For Events'}
    </DopeLinkButton>
  );
};

export default AutomationSyncButton;
