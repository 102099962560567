import ReactDatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './datePicker.scss';

export default function DatePicker(props) {
  return (
    <ReactDatePicker
      format="yyyy-MM-dd"
      monthPlaceholder="MM"
      dayPlaceholder="DD"
      yearPlaceholder="YYYY"
      clearIcon={null}
      {...props}
    />
  );
}
