import { useEffect, useRef, useState } from "react";

import DopeButton from "../ui/DopeButton";
import DopeDrawer from "../ui/DopeDrawer";
import { DopeFormGroup } from "../ui/DopeForm";
import DopeRichTextEditor from "../ui/DopeRichTextEditor";
import RichTextView from "../ui/RichTextView";
import { useSlideover } from "./slideoverSlice";
import { useDopeUI } from "../ui/dopeUISlice";

const nameInput = {
  inputType: "text",
  name: "name",
  props: { label: "Slideover Name" },
};

const descriptionInput = {
  inputType: "text",
  name: "description",
  props: { label: "Slideover Description" },
};



const headlineInput = {
  inputType: "text",
  name: "headline",
  props: { label: "Slideover Headline" },
};


const slugify = (text) => text.toLowerCase().replace(/[\s+]/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase();

const SlideoverPage = () => {
  const [dopeUI, dopeUIActions] = useDopeUI();
  const { slideover, actions } = useSlideover();

  const slideoverInputProps = { values: slideover, onChange: actions.update, errors: slideover.errors };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const canEdit = slideover.id === 'new';

  const slugInput = {
    inputType: "text",
    name: "slug",
    props: { label: "Slideover Slug", disabled: !canEdit },
  };

  useEffect(() => {
    if (slideover?.errors?.base?.length > 0) {
      dopeUIActions.addFlashMessage({ header: "Error", body: slideover.errors.base[0], type: "error" });
    }
  }, [slideover, slideover?.errors]);

  return (
    <>
      <div className="page">
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <h2>Slideover</h2>
          <DopeButton
            props={{
              onClick: () => setIsDrawerOpen(true),
              label: "Test Slideover",
              buttonClass: 'filled-black',
            }}
            />
        </div>
        <div>
          <DopeFormGroup
            input={nameInput}
            {...slideoverInputProps}
            onChange={({ name }) => {
              if (canEdit) {
                actions.update({ name, slug: slugify(name) });
              } else {
                actions.update({ name });
              }
            }}
            />
        </div>

        <div>
          <DopeFormGroup input={descriptionInput} {...slideoverInputProps} />
        </div>

        <div>
          <DopeFormGroup
            disabled={!canEdit}
            input={slugInput}
            {...slideoverInputProps}
            onChange={({ slug }) => {
              actions.update({ slug: slugify(slug) });
            }}
            />
            <small style={{ color: '#8793a6' }}>The slug is what the users will see on their URL when opening the drawer.</small>
        </div>
      </div>

      <div className="page">
        <h2>Content</h2>
        <div>
          <DopeFormGroup
            input={headlineInput}
            style={{ maxWidth: '800px' }}
            {...slideoverInputProps} />
        </div>
        <div>
        <label className="label">Slideover Content</label>
          <DopeRichTextEditor
            value={slideover.content || {}}
            onChange={(content) => actions.update({ content })}
            placeholder="Slideover Content"
          />
        </div>
      </div>

      <DopeDrawer
        className={'slideover-drawer'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        header={slideover.headline || ''}
        bodyContent={<RichTextView content={slideover.content} />}
        />
    </>
  );
};

export default SlideoverPage;
