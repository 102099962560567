import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import TrashIcon from "@rsuite/icons/Trash";
import PlusIcon from "@rsuite/icons/Plus";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";

import DopeApi from "../services/DopeApi";
import { useList } from "../lists/listSlice";
import { useContactUI } from "./contactUISlice";
import { useDopeUI } from "../ui/dopeUISlice";
import { useQuery2 } from "../hooks/useQuery";
import { handleFilterChange } from "../utils/fn";
import { errorsToErrorList } from "../utils/errors";

import DopeListPage from "../ui/DopeListPage";
import DeleteContactsModal from "./DeleteContactsModal";
import AddContactsToListDrawer from "../lists/AddContactsToListDrawer";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import DopeButton from "../ui/DopeButton";
import FixContactCell from "./FixContactCell";
import ContactNameCell from "./ContactNameCell";


const dateStyles = {
  fontSize: '11px',
  fontWeight: 500,
  color: '#292B2E'
}

const contactApi = new DopeApi('contact');
const contactListApi = new DopeApi('contact_lists');
const listApi = new DopeApi('list');
const taggingsApi = new DopeApi('taggings');

const getUrl = (rowData) => `/contacts/${rowData.contact_id}/details`;
const setFavorite = async (rowData) => {
  await contactApi.update({id: rowData.contact_id, favorite: !rowData.contact.favorite});
}

const columns = [
  { type: 'checkbox', dataKey: 'contact_id', flexGrow: 1 },
  { type: 'custom_component', dataKey: 'contact', label: 'Name', Component: ContactNameCell, flexGrow: 2, getUrl, sortable: true },
  { type: 'address_text', dataKey: 'contact.full_address', label: 'Address', flexGrow: 2, sortable: true },
  { type: 'date', dataKey: 'created_at', label: 'Date Added', customStyles: dateStyles, sortable: true },
  { type: 'date', dataKey: 'contact.most_recent_shipped_mail_piece_date', label: 'Last Mailing', customStyles: dateStyles },
  { type: 'custom_component', dataKey: 'has_valid_info', label: '', Component: FixContactCell },
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'contact', width: 75 }
];

const ContactListTable = ({ listFilter, setListFilters }) => {
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, listActions] = useList();
  const [contactUI, contactUIActions] = useContactUI();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const { paginationProps, tableProps, filters, setFilters, onSearchClear, onSearchInput, reload, exportCSV } =
    useQuery2({
      api: contactListApi,
      initialSearchScope: "contacts.full_name_and_address",
      initialFilters: [listFilter],
    });

  const { id } = useParams();

  useEffect(() => {
    setListFilters(filters);
  }, [filters]);


  const contactDesignations = [{label: "Clients", value: "contacts.is_client"}, {label: "Leads", value: "contacts.is_lead"}, {label: "Prospects", value: "contacts.is_prospect"}]
  const applyContactTypeFilters = (contactTypes, optionValue, checked) => {
    if (optionValue === "contacts.is_prospect") {
      applyProspectFilters(checked);
    } else {
      const filterObject = {
        filterField: optionValue ,
        operator: "=",
        filterCategory: optionValue
      }

      handleFilterChange(filters, setFilters, checked, filterObject);
    }
  }

  const applyProspectFilters = (checked) => {
    let filterObjects = [];
    ["contacts.is_client", "contacts.is_lead"].forEach((designation) => {
      const filterObject = {
        filterField: designation,
        operator: "!=",
        filterCategory: "contacts.is_prospect",
        allowMultiples: true
      }

      filterObjects.push(handleFilterChange(filters, setFilters, checked, filterObject));
    });

    if (filterObjects.filter(object => object !== undefined).length > 0) {
      setFilters([...filters, ...filterObjects])
    }
  }

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "contact.taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    }
    handleFilterChange(filters, setFilters, tags, filterObject);
  }

  const filterInputs = [
    { title: 'Tags', getOptions: () => taggingsApi.getOptions({valueKey: 'tag_id', labelKey: 'tag.name', filters: [{ field: "taggable_type", operator: "=", value: "Contact" }]}), applyFilter: applyTagFilters },
    { title: 'Contact Type', getOptions: () => contactDesignations, applyFilter: applyContactTypeFilters }
  ];

  const deleteContactsClick = (selectedIds) => {
    setSelectedContactIds(selectedIds);
    contactUIActions.toggleDeleteMultipleContactsModal();
  }

  const addToListClick = (selectedIds) => {
    setSelectedContactIds(selectedIds);
    contactUIActions.toggleAddContactsToListDrawer();
  }

  const removeContactsFromList = async () => {
    setLoading(true);
    try {
      await listApi.update({ id, actions: [{ name: "remove_contacts_from_list", args: selectedContactIds }] });
      reload();
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error", body: errorsToErrorList(error), type: "error" });
    } finally {
      setLoading(false);
      contactUIActions.toggleRemoveContactsFromListModal();
    }
  }

  const deleteSingleContact = async () => {
    setLoading(true);
    try {
      await contactApi.destroy(contactUI.deleteModalContactId);
      contactUIActions.setDeleteModalContactId(null);
      reload();
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error", body: errorsToErrorList(error), type: "error" });
    } finally {
      setLoading(false);
    }
  }

  const removeContactsClick = (selectedIds) => {
    setSelectedContactIds(selectedIds);
    contactUIActions.toggleRemoveContactsFromListModal();
  }

  const selectedActions = [
    { label: "Delete Contacts", action: deleteContactsClick, icon: <TrashIcon style={{ fontSize: "12px", marginRight: "4px" }} /> },
    { label: "Add to List", action: addToListClick, icon: <PlusIcon style={{ fontSize: "10px", marginRight: "4px" }} /> },
    { label: "Remove from List", action: removeContactsClick, icon: <AiOutlineMinusCircle style={{ fontSize: "14px", marginRight: "4px" }} /> }
  ];

  return (
    <>
      <div className="to-right margin-4-b">
        <DopeButton
          icon={<FiDownload style={{marginRight: "8px"}}/>}
          props={{
            label: "Export",
            onClick: () => exportCSV(`${list.name}_contacts`),
            buttonClass: "outlined-black rectangle",
          }}
        />
      </div>

      <DopeListPage
        columns={columns}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        filterInputs={filterInputs}
        filters={filters}
        paginationProps={paginationProps}
        tableProps={tableProps}
        selectedActions={selectedActions}
      />

      <AddContactsToListDrawer
        open={!!contactUI.addContactsToListDrawer}
        onClose={() => contactUIActions.toggleAddContactsToListDrawer()}
        contactIds={selectedContactIds}
      />

      <DeleteContactsModal
        open={!!contactUI.deleteMultipleContactsModal}
        onClose={() => contactUI.toggleDeleteMultipleContactsModal()}
        selectedContactIds={selectedContactIds}
        setSelectedContactIds={setSelectedContactIds}
        reload={reload}
      />

      <DopeConfirmationModal
        open={!!contactUI.deleteModalContactId}
        onCancel={() => contactUIActions.setDeleteModalContactId(null)}
        title="Are you sure you want to delete this contact?"
        onConfirm={deleteSingleContact}
        confirmButtonClass="filled-black action-button"
        cancelButtonClass="outlined-black action-button"
        confirmLabel="Yes, delete"
        cancelLabel="No, cancel"
        loading={loading}
      />

      <DopeConfirmationModal
        open={!!contactUI.removeContactsFromListModal}
        onCancel={() => contactUIActions.toggleRemoveContactsFromListModal()}
        title={`Are you sure you want to remove these ${selectedContactIds.length} contacts from this list?`}
        message={(
          <div>
            <p>If you accidentally remove these contacts you can always add them back onto a list.</p>
          </div>
        )}
        onConfirm={() => removeContactsFromList(list.id, selectedContactIds)}
        confirmButtonClass="filled-black action-button"
        cancelButtonClass="outlined-black action-button"
        confirmLabel="Yes, remove"
        cancelLabel="No, cancel"
        loading={loading}
      />
    </>
  );
};

export default ContactListTable;
