import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

import pick from "lodash/pick";
import { useCampaignListGenerationSettings } from "./campaignSlice";
import DopeApi from "../services/DopeApi";

import { DopeFormGroup } from "../ui/DopeForm";
import AdditionalCampaignOptions from "./AdditionalCampaignOptions";
import ExtraMailRules from "./ExtraMailRules";
import ListDropdownDisplay from "../lists/ListDropdownDisplay";

const listApi = new DopeApi("list");

const CampaignSetup = () => {
  const [loadingStartingContact, setLoadingStartingContact] = useState(false);
  const [listGenerationSettings, listGenerationSettingsActions] = useCampaignListGenerationSettings();
  const { pageTabs } = useOutletContext();

  const listGenerationSettingsInputProps = { values: listGenerationSettings, onChange: listGenerationSettingsActions.update, errors: listGenerationSettings.errors };

  const isListBlitz = listGenerationSettings.generation_type === "list_blitz";
  const isListOnly = listGenerationSettings.generation_type === "list";
  const requiresStartingList = isListBlitz || isListOnly;

  const startingListInput = { inputType: "belongsTo", name: "starting_list_id", props: {
    label: "What list should we send to?",
    labelClass: "body-1 bold color-text-label-blue-grey margin-4-b",
    belongsToModelName: "list",
    placeholder: "Select a list",
    labelKey: "name",
    loading: loadingStartingContact,
    renderMenuItem: (label, list) => {
      return (
        <ListDropdownDisplay list={list} />
      );
    }
  }};

  const handleStartingListChange = async (update, list) => {
    setLoadingStartingContact(true);

    const listId = update.starting_list_id;
    const startingContact = await listApi.fetchMember(listId, "starting_contact");

    if (startingContact) {
      listGenerationSettingsActions.update({
        starting_list_id: listId,
        starting_list: list,
        ...pick(startingContact, 'first_name', 'last_name', 'full_address', 'country', 'state', 'city', 'zip', 'zip_4', 'address_1', 'address_2', 'latitude', 'longitude'),
        errors: {},
      });
    } else {
      listGenerationSettingsActions.update({
        errors: { starting_list_id: `Selected list does not have any contacts with complete information.` },
        starting_list_id: null,
      });
    }
    setLoadingStartingContact(false);
  };

  return (
    <>
      <div className="page">
        {pageTabs}

        <div className="page-header">
          <p className="header-2">Campaign Setup</p>
          <p className="body-1 color-text-secondary">Start by selecting a list and refining who you want your campaign to go to</p>
        </div>

        <div className="flex column max-width-600">
          <div className="max-width-500 margin-bottom">
            {requiresStartingList && (
              <DopeFormGroup
                input={startingListInput}
                {...listGenerationSettingsInputProps}
                onChange={handleStartingListChange}
              />
            )}
          </div>

          <AdditionalCampaignOptions
            additionalRulesApplied={listGenerationSettings.add_list_generation_setting_seed_lists || listGenerationSettings.add_list_generation_setting_suppression_lists}
            types={["list", "list_blitz"]}
            settings={listGenerationSettings}
            panelBody={<ExtraMailRules />}
          />

        </div>

      </div>
    </>
  );
};

export default CampaignSetup;
