import React from "react";
import { formatToLocaleDateString } from "../utils/date";

const ListDropdownDisplay = ({ list }) => {

  return (
    <div className="list-dropdown-display">
      <div className="label large max-width-400 truncate margin-4-b">{list.name}</div>

      <div className="flex row space-between align-end margin-8-b">
        <div className="label large color-text-secondary">{list.contact_lists_count} contacts</div>
        <div className="label optional color-text-secondary">Last Updated on {formatToLocaleDateString(list.updated_at)}</div>
      </div>
    </div>
  );
}

export default ListDropdownDisplay;
