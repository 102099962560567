import React, {useEffect, useState} from "react";
import { Modal, Form } from "rsuite";

import { useAccount } from "../accounts/accountSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeTextInput from "../ui/DopeTextInput";
import DopeButton from "../ui/DopeButton";
import DopePhoneInput from "../ui/DopePhoneInput";

const AddAccountModal = ({ open, handleClose, reload }) => {
  const [account, actions] = useAccount();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    actions.get('new');
  }, [])

  useEffect(() => {
    if (account && account.id !== 'new') {
      actions.get('new');
    }
  }, [account])

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await actions.save()
      reload();
      handleClose();
      dopeUIActions.addFlashMessage({ header: `${account.name}'s account`, body: "was successfully created", type: "success" });
    } catch (err) {
      dopeUIActions.addFlashMessage({ header: "Something went wrong", body: "please try again", type: "error" });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal open={open} onClose={handleClose} >
      <Modal.Header>
        <Modal.Title style={{color: "#000000", fontWeight: 600, fontSize: "18px"}}>Add New Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          <div style={{marginBottom: "16px"}}>
            <label style={{ fontWeight: 600, fontSize: "11px", color: "#8793A6" }}>Company Name</label>
            <DopeTextInput name="name" value={account?.name} onChange={actions.update} />
          </div>

          <div style={{marginBottom: "16px"}}>
            <label style={{ fontWeight: 600, fontSize: "11px", color: "#8793A6" }}>Email Address</label>
            <DopeTextInput name="account_email" value={account?.account_email} onChange={actions.update} />
          </div>

          <div style={{marginBottom: "16px"}}>
            <label style={{ fontWeight: 600, fontSize: "11px", color: "#8793A6" }}>Phone Number</label>
            <DopePhoneInput name="phone_number" value={account?.phone_number} onChange={actions.update}  />
          </div>

          <div style={{display: "flex", justifyContent: "center", marginTop: "36px"}}>
            <DopeButton
              loading={loading}
              props={{ onClick: handleSubmit, label: "Create Account", buttonClass: "filled-black", styles: {width: "348px"} }}
            />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddAccountModal;
