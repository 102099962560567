import React, {useCallback, useState} from 'react'
import { useDropzone } from 'react-dropzone'

const DopeUploadButton = ({ name, onChange, disabled, accept, label = 'Upload Image' }) => {

  const onDrop = useCallback(files => {
    const file = files[0];
    onChange({[name]: file});
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept,
    maxFiles: 1,
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <p>{label}</p>
    </div>
  )
}

export default DopeUploadButton;

