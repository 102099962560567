import React, { useEffect, useState } from "react";
import {Form, Grid, Row, Input} from "rsuite";

import { useUser } from "../users/userSlice";
import authApi from "../auth/authApi";
import DopeTextInput from "../ui/DopeTextInput";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeButton from "../ui/DopeButton";
import DopePhoneInput from "../ui/DopePhoneInput";
import ChangePasswordModal from "./ChangePasswordModal";

const AccountUserForm = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [editing, setEditing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [user, userActions] = useUser()
  const [dopeUI, dopeUIActions] = useDopeUI()

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        let currentUser = await authApi.getCurrentUser();
        userActions.get(currentUser.id)
      } catch (error) {
        console.log(error);
      }
    }

    if (!user) {
      getCurrentUser();
    }

    return userActions.resetToInitial;
  }, []);

  const handleUserUpdate = async () => {
    try {
      let response = await userActions.save();
      if (!response.error) {
        dopeUIActions.addFlashMessage({header: `${user.first_name} ${user.last_name}`, body: 'was successfully updated!', type: 'success' });
      } else {
        dopeUIActions.addFlashMessage({header: `${user.first_name} ${user.last_name}`, body: 'could not be updated!', type: 'error' });
      }
    } catch (error) {
      dopeUIActions.addFlashMessage({header: `${user.first_name} ${user.last_name}`, body: 'could not be updated!', type: 'error' });
    }
  }

  return  (
    <div style={{marginBottom: "60px"}}>
      <Form onSubmit={handleUserUpdate} fluid >
        <Grid fluid style={{paddingBottom: "20px"}}>
          <Row style={{ width: "480px"}}>
            <label className="label">First Name</label>
            <DopeTextInput
              name="first_name"
              value={user?.first_name}
              onChange={userActions.update}
              className="margin-4-b"
              disabled={!editing}
            />
          </Row>

          <Row style={{ width: "480px"}}>
            <label className="label">Last Name</label>
            <DopeTextInput
              name="last_name"
              value={user?.last_name}
              onChange={userActions.update}
              className="margin-4-b"
              disabled={!editing}
            />
          </Row>

          <Row style={{ width: "480px"}}>
            <label className="label">Email</label>
            <DopeTextInput
              name="email"
              value={user?.email}
              onChange={userActions.update}
              className="margin-4-b"
              disabled={!editing}
            />
          </Row>

          <Row style={{ width: "480px"}}>
            <label className="label">Phone Number</label>
            <DopePhoneInput
              name="phone_number"
              value={user?.phone_number}
              onChange={userActions.update}
              className="margin-4-b"
              disabled={!editing}
            />
          </Row>

          <Row style={{ width: "480px"}}>
            <label className="label">Current Password</label>
            <Input
              name="current_password"
              value={currentPassword}
              disabled={true}
              type="password"
              className="margin-4-b"
            />
          </Row>

          <Row style={{ width: "480px"}}>
            <div className="to-right">
              <DopeButton
                props={{
                  label: 'Change Password >',
                  buttonClass: 'text-link',
                  onClick: () => setModalOpen(true)
                }}
              />
            </div>
          </Row>

        </Grid>

        <div className="to-right margin-16-t" style={{ width: "480px"}}>
          <DopeButton
            props={{
              label: editing ? 'Cancel' : 'Edit',
              buttonClass: 'outlined-black',
              onClick: () => setEditing(!editing)
            }}
            className="submit margin-4-r"
          />

          {editing && <DopeButton
            props={{
              label: 'Update User',
              type: 'submit',
              buttonClass: 'filled-black',
            }}
            className="submit"
          />}
        </div>

      </Form>

      <ChangePasswordModal setModalOpen={setModalOpen} open={modalOpen} />
    </div>
  )
}

export default AccountUserForm;
