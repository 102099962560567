import React, { useState } from "react";

import { Pagination, Panel, Stack, ButtonGroup, Button, Modal, Drawer } from "rsuite";

import DopeApi from "../services/DopeApi";
import useQuery from "../hooks/useQuery";
import toNames from "../utils/railsNames";

import DopeTable from "./DopeTable";
import DopeErrorMessages from "./DopeErrorMessages";
import DopeFilterBar from "./DopeFilterBar";
import DopeConfirmationModal from "./DopeConfirmationModal";

const paginationWrapperStyles = { paddingTop: 20 };

const defaultActionColumns = [
  { label: "Edit", dataKey: "edit", type: "button" },
  { label: "Delete", dataKey: "destroy", type: "button" },
];

const DopeManagement = ({
  modelName,
  columns,
  scopeOptions,
  modelForm: ModelForm,
  initialFilters,
  hideFiltering = false,
  useDrawer = false,
  formProps = {},
  tableProps: userTableProps = {},
  disableCreate = false,
  noDefaults = false,
}) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editingResourceId, setEditingResourceId] = useState(null);
  const [destroyingResourceId, setDestroyingResourceId] = useState(null);
  const [destroyErrors, setDestroyErrors] = useState(null);
  const [isDestroying, setIsDestroying] = useState(false);

  const { displayName, pluralDisplayName } = toNames(modelName);

  const api = new DopeApi(modelName);

  const OverlayComponent = useDrawer ? Drawer : Modal;
  const overlaySize = useDrawer ? 'md' : 'full';

  const {
    error,
    reload,
    tableProps,
    paginationProps,
    filterProps,
  } = useQuery(api, initialFilters);

  const fields = columns.filter((column) => column.filterable).map((column) => ({
    label: column.label,
    value: column.dataKey,
    input: column.input,
  }));

  const handleFormFinish = (triggerReload = false) => {
    setCreateModalOpen(false);
    setEditingResourceId(null);
    triggerReload && reload();
  };

  const handleDestroyConfirm = () => {
    setIsDestroying(true);
    api.destroy(destroyingResourceId)
      .then(() => {
        setDestroyErrors(null);
        setIsDestroying(false);
        setDestroyingResourceId(null);
        reload();
      })
      .catch((error) => {
        console.error(error);
        setIsDestroying(false);
        setDestroyErrors(error);
      });
  };

  const handleDestroyCancel = () => {
    setDestroyErrors(null);
    setDestroyingResourceId(null);
  };

  const allColumns = noDefaults ? columns : [...columns, ...defaultActionColumns];

  return (
    <>
      <Panel
        shaded
        header={
          <Stack justifyContent="space-between">
            <span>{pluralDisplayName}</span>
            <ButtonGroup>
              <Button appearance="primary" disabled={disableCreate} onClick={() => setCreateModalOpen(true)}>Create {displayName}</Button>
            </ButtonGroup>
          </Stack>
        }
      >

        {!hideFiltering &&
          <DopeFilterBar
            fields={fields}
            scopeOptions={scopeOptions}
            {...filterProps}
          />
        }

        <DopeErrorMessages errorMessages={error} />

        <DopeTable
          columns={allColumns}
          onClickEdit={(rowData) => setEditingResourceId(rowData.id)}
          onClickDestroy={(rowData) => setDestroyingResourceId(rowData.id)}
          {...tableProps}
          {...userTableProps}
        />

        <div style={paginationWrapperStyles}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            limitOptions={[10, 25, 50, 100]}
            {...paginationProps}
          />
        </div>

      </Panel>

      <OverlayComponent open={createModalOpen} size={overlaySize} onClose={() => handleFormFinish()}>
        <OverlayComponent.Header>
          <OverlayComponent.Title>Create {displayName}</OverlayComponent.Title>
        </OverlayComponent.Header>

        <OverlayComponent.Body>
          <ModelForm onFinish={handleFormFinish} {...formProps} />
        </OverlayComponent.Body>
      </OverlayComponent>

      <OverlayComponent open={!!editingResourceId} size={overlaySize} onClose={() => handleFormFinish()}>
        <OverlayComponent.Header>
          <OverlayComponent.Title>Edit {displayName} (ID: {editingResourceId})</OverlayComponent.Title>
        </OverlayComponent.Header>

        <OverlayComponent.Body>
          <ModelForm id={editingResourceId} onFinish={handleFormFinish} />
        </OverlayComponent.Body>
      </OverlayComponent>

      <DopeConfirmationModal
        open={!!destroyingResourceId}
        title={`Delete ${displayName}`}
        onConfirm={handleDestroyConfirm}
        onCancel={handleDestroyCancel}
        errors={destroyErrors}
        loading={isDestroying}
      />
    </>
  )
}

export default DopeManagement;
