import React from "react";
import { InputNumber, Toggle } from "rsuite";

import { useAccount } from "../accounts/accountSlice";
import { permissionsDisplayValues } from "../utils/permissions";

const PermissionToggle = ({ details, onChange, accountValue, baselinePermission }) => {
  return (
    <div className="flex row top-align margin-bottom">
      <div className="margin-right">
        <Toggle
          onChange={onChange}
          disabled={baselinePermission}
          checked={accountValue}
          size="md"
        />
      </div>

      <div className="flex column">
        <div className="header-6 non-bold">{details.name}</div>
        <div className="label large color-text-secondary">{details.description}</div>
      </div>
    </div>
  );
}

const PermissionNumberInput = ({ details, onChange, accountValue }) => {
  return (
    <div className="flex row top-align margin-bottom">
      <div className="margin-right">
        <Toggle
          checked={true}
          disabled={true}
          size="md"
        />
      </div>

      <div className="flex column margin-8-b">
        <div className="header-6 non-bold">{details.name}</div>
        <div className="label large color-text-secondary">{details.description}</div>

        <InputNumber
          value={accountValue}
          style={{ width: 255 }}
          onChange={onChange}
          name={details.name}
          min={0}
          max={details.limit || 1000}
        />
      </div>
    </div>
  );
}

const PermissionRow = ({ permissionSubject, accountPermissions, onPermissionChange, baselinePermission }) => {
  const details = permissionsDisplayValues[permissionSubject];
  const accountValue = accountPermissions[permissionSubject];

  const onChange = (value) => {
    onPermissionChange(permissionSubject, value);
  }

  return (
    <div>
      {details.type === "toggle" && <PermissionToggle details={details} onChange={onChange} accountValue={accountValue} baselinePermission={baselinePermission} />}
      {details.type === "number_input" && <PermissionNumberInput details={details} onChange={onChange} accountValue={accountValue} />}
    </div>
  );
}

const EditAccountPermissionsSection = ({ sectionTitle, permissionSubjects, onPermissionChange, baselinePermissions }) => {
  const [account, accountActions] = useAccount();

  return (
    <div>
      <div className="drawer-section-header label small color-text-primary text-upper">{sectionTitle}</div>

      <div className="pad-8">
        {permissionSubjects.map((permissionSubject) => {
          const baselinePermission = baselinePermissions[permissionSubject][account.permissions_key];

          return (
            <PermissionRow
              key={permissionSubject}
              permissionSubject={permissionSubject}
              accountPermissions={account.account_permissions_hash}
              onPermissionChange={onPermissionChange}
              baselinePermission={baselinePermission}
            />
          );
        })}
      </div>
    </div>
  );
}

export default EditAccountPermissionsSection;
