import React from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import DopeListPage from "../ui/DopeListPage";

const creditTransactionsApi = new DopeApi("credit_transaction");

const columns = [
  { type: 'text', dataKey: 'account_name', label: 'Company Name' },
  { type: 'timestamp', dataKey: 'created_at', label: 'Created Date' },
  { type: 'text', dataKey: 'product_name', label: 'Credit Type' },
  { type: 'text', dataKey: 'amount', label: '# of Credits' },
  { type: 'text', dataKey: 'transactionable_type', label: 'Payment Source' }
];

const AutomaticCreditsTable = () => {
  const { tableProps, paginationProps, onSearchClear, onSearchInput, filters, setFilters }
    = useQuery2({
      api: creditTransactionsApi,
      initialSearchScope: 'accounts.name'
    });

  return (
    <div>
      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
        setFilters={setFilters}
        filters={filters}
      />

    </div>
  );
}

export default AutomaticCreditsTable;
