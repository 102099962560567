import { useDispatch, useSelector } from "react-redux";
import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import { asNestedDestroyable } from "../services/createModelSelectors";
import DopeApi from '../services/DopeApi';

const api = new DopeApi('automation');

const getAutomationPurchaseSummary = createAsyncThunk(
  'automation/getAutomationPurchaseSummary',
  async (automation, { rejectWithValue }) => {
    try {
      return await api.post(automation, 'purchase_summary');
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const [automationSlice, asyncActions] = createModelSlice('automation', {
  updateDispatchTemplate: ({ current: automation }, { payload }) => {
    const { dispatch_templates } = automation.campaign_template;
    const { index, update } = payload;
    Object.assign(dispatch_templates[index], update);
  },
  addDispatchTemplate: ({ current: automation }, { payload: dispatchTemplate }) => {
    automation.campaign_template.dispatch_templates.push(dispatchTemplate);
  },
  removeDispatchTemplate: ({ current: automation }, { payload: index }) => {
    automation.campaign_template.dispatch_templates.splice(index, 1);
  },
  updateListGenerationSetting: ({ current: automation }, { payload: update }) => {
    Object.assign(automation.campaign_template.list_generation_setting, update);
  },
  updateDataAxleFilters: ({ current: automation }, { payload: update }) => {
    Object.assign(automation.campaign_template.list_generation_setting.data_axle_filters, update);
  },
  removeDataAxleFilter: ({ current: automation }, { payload: filterKey }) => {
    delete automation.campaign_template.list_generation_setting.data_axle_filters[filterKey];
  },
});

export default automationSlice.reducer;

const automationToParams = (automation, prevAutomation) => { // TODO white or blacklist this
  let campaign_template_params = {};
  if (automation.action_template_type === 'CampaignTemplate') {
    const { campaign_template } = automation;
    const { list_generation_setting } = campaign_template;
    const prevListGenerationSetting = prevAutomation.campaign_template.list_generation_setting;

    const campaign_template_attributes = {
      ...campaign_template,
      id: campaign_template.id === 'new' ? null : campaign_template.id,
      list_generation_setting_attributes: {
        ...list_generation_setting,
        ...asNestedDestroyable(prevListGenerationSetting, list_generation_setting, 'list_generation_setting_seed_lists'),
        ...asNestedDestroyable(prevListGenerationSetting, list_generation_setting, 'list_generation_setting_suppression_lists'),
        id: list_generation_setting.id === 'new' ? null : list_generation_setting.id,
      },
      ...asNestedDestroyable(prevAutomation.campaign_template, automation.campaign_template, 'dispatch_templates'),
    };
    campaign_template_params = { campaign_template_attributes };
  }

  return {
    ...automation,
    ...asNestedDestroyable(prevAutomation, automation, 'taggings'),
    ...campaign_template_params,
  };
};

const automationSelector = state => state.automation.current;

const derivedAutomationAttrsSelector = createSelector(
  [automationSelector],
  (automation) => {
    if (!automation) return {};

    const derivedAttrs = {};
    const settings = automation.campaign_template.list_generation_setting;

    derivedAttrs.sendingTypeDisplay = {
      list: 'Direct Sending',
      blitz: 'Neighborhood Blitz',
    }[settings.generation_type];
    derivedAttrs.audienceSize = settings.generation_type === 'blitz' ? settings.max_contacts_per_generation : 1;
    derivedAttrs.audienceSizeDisplay = `${derivedAttrs.audienceSize} (per trigger)`;
    derivedAttrs.tags = automation.taggings.map(({ tag }) => tag);
    derivedAttrs.excludeItems = settings.list_generation_setting_suppression_lists.map(list => {
      const item = { name: list.name, type: 'List', count: list.list.contact_lists_count, key: 'list-' + list.id, is_exclusion: true };
      return item;
    });
    if (settings.suppress_previous_sends) {
      derivedAttrs.excludeItems.push({ name: `"Anyone I've sent to"`, key: 'previous-sends', type: 'Previous sends', is_exclusion: true });
    }
    derivedAttrs.throttlePeriod = {
      1: 'day',
      7: 'week',
      30: 'month',
      365: 'year',
    }[automation.throttle_days];
    derivedAttrs.throttleDisplay = `${automation.throttle_count} times per ${derivedAttrs.throttlePeriod}`;
    if (!automation.throttle_count) {
      derivedAttrs.throttleDisplay = 'None';
    }
    derivedAttrs.isNativeTrigger = automation.trigger_source === 'native';
    derivedAttrs.isDraft = !automation.id || automation.id === 'new' || automation.status === 'draft';
    derivedAttrs.isActive = !!automation.id && automation.id !== 'new' && automation.status === 'active';
    derivedAttrs.noDispatches = automation.campaign_template.dispatch_templates.length === 0;

    return derivedAttrs;
  }
);

export function useAutomation() {
  const dispatch = useDispatch();
  const automation = useSelector(automationSelector);
  const derivedAutomationAttrs = useSelector(derivedAutomationAttrsSelector);
  const prevAutomation = useSelector(state => state.automation.prev);
  const isSaving = useSelector(state => state.automation.isSaving);

  const actions = wrapAll({
    ...automationSlice.actions,
    ...asyncActions,
    updateMode: (modeUpdate) => {
      return asyncActions.save({ id: automation.id, ...modeUpdate });
    },
    getPurchaseSummary: () => {
      const params = automationToParams(automation, prevAutomation);
      return getAutomationPurchaseSummary(params);
    },
  }, dispatch);

  actions.save = async (additionalParams = {}) => {
    if (additionalParams.preventDefault instanceof Function) {
      console.error("You're passing an event to saveAutomation! Automations cannot be updated with an event!!!");
      additionalParams = {};
    }

    const params = automationToParams(automation, prevAutomation);
    return dispatch(asyncActions.save({ ...params, ...additionalParams }));
  };


  return {
    automation,
    derivedAutomationAttrs,
    actions,
    isSaving,
  };
}

