import React, { useState, useEffect } from "react";

import { uniqBy } from "lodash";

import useQuery from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import { toDisplayName } from "../utils/railsNames";

import { IconButton } from "rsuite";
import { Icon } from '@rsuite/icons';
import PlusIcon from '@rsuite/icons/Plus';
import { MdOutlineClose } from "react-icons/md";

import DopeTextInput from "./DopeTextInput";

import "./DopeTags.scss";
import DopePill from "./DopePill";

const tagApi = new DopeApi("tag");
const taggingApi = new DopeApi("tagging");

const TagItem = ({ tag, onRemove, onAdd }) => {
  const isSelected = !!onRemove;
  const isHoverable = !!onAdd || isSelected;

  return (
    <DopePill
      pillType="tag"
      text={tag.name}
      size="small"
      onClick={!!onAdd ? () => onAdd(tag) : null}
      hoverable={isHoverable}
      showCircle={false}
      rightIcon={isSelected && <Icon onClick={() => onRemove(tag)} as={MdOutlineClose} style={{ cursor: "pointer", marginLeft: "8px", position: "relative"}} />}
    />
  );
};

const TagList = ({ tags, onRemove, onAdd, loading }) => {
  return (
    <div className="tag-list">
      {loading && <span>Loading...</span>}
      {tags.map((tag, index) => (
        <div key={index} style={{margin: "0 4px 4px 0", maxWidth: "fit-content"}}>
          <TagItem
            key={index}
            tag={tag}
            onRemove={onRemove}
            onAdd={onAdd}
          />
        </div>
      ))}
    </div>
  );
};

const EmptyTagList = ({hideSearch, tags, hideEmptyState}) => {
  const emptyClass = `empty-tag-list ${tags.length === 0 && hideSearch && !hideEmptyState ? '' : 'hidden'}`;
  return (
    <div className={emptyClass}>
      There haven’t been any tags added yet - add tags you’ve used before or create a new one to easily filter!
    </div>
  );
}

const TagDisplayList = ({ tags }) => {
  return (
    <div className="tag-display-list" style={{display: "flex"}}>
      {tags.map((tag, index) => (
        <div key={index} style={{marginRight: "4px"}}>
          <DopePill pillType="tag" text={tag.name} size="small" />
        </div>
      ))}
    </div>
  );
}

const RecentlyUsedTags = ({ accountId, modelName, onAdd }) => {
  const modelDisplayName = toDisplayName(modelName);

  const { allData: recentTaggings, loading: loadingRecentTaggings } = useQuery(taggingApi, [], null, [
    { name: "recently_used_for", args: [accountId, modelDisplayName] },
  ]);

  return (
    <TagList
      tags={recentTaggings.map((tagging) => tagging.tag)}
      onAdd={onAdd}
      loading={loadingRecentTaggings}
    />
  );
};

const DopeTags = ({ name, value, values, onChange, modelName, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [creatingTag, setCreatingTag] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  const accountId = values.account_id || 1; // TODO Remove and do better
  const accountFilter = { field: "account_id", operator: "=", value: accountId };

  const { data: tags, loading: loadingTags, setFilters, filters } = useQuery(tagApi, [accountFilter]);

  useEffect(() => {
    if (searchTerm) {
      setFilters([{ field: "name", operator: "ilike", value: searchTerm }, accountFilter]);
    } else if (filters.length > 1) {
      setFilters([accountFilter]);
    }
  }, [searchTerm]);

  const handleAddTagging = (tag) => {
    setSearchTerm("");
    const nextTaggings = uniqBy([...value, { tag_id: tag.id, tag }], 'tag_id');
    onChange({ taggings: nextTaggings });
  };

  const handleRemoveTagging = (tag) => {
    const nextTaggings = value.filter(tagging => tagging.tag_id !== tag.id);
    onChange({ taggings: nextTaggings });
  };

  const createAndAddTag = () => {
    setCreatingTag(true);
    tagApi.create({ name: searchTerm, account_id: accountId }).then((tag) => {
      setCreatingTag(false);
      handleAddTagging(tag);
    });
  };

  const hideSearch = !inputFocused && !searchTerm;

  return (
    <div className="dope-tags">

      <div className="search-wrapper">
        <DopeTextInput
          name="searchTerm"
          value={searchTerm}
          onChange={({ searchTerm }) => setSearchTerm(searchTerm)}
          placeholder="Search for a tag or add a new one"
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          style={{ borderRadius: "20px" }}
        />
        <IconButton
          className="create-tag"
          onClick={createAndAddTag}
          disabled={!searchTerm || creatingTag}
          icon={<PlusIcon />}
          circle
          size="xs"
          appearance="primary"
        />
      </div>

      <TagList
        tags={value.map(tagging => tagging.tag)}
        onRemove={handleRemoveTagging}
      />

      <div className={`tag-search-results ${hideSearch ? 'hidden' : ''}`}>

        <TagList
          tags={tags}
          onAdd={handleAddTagging}
        />

        <div className="list-divider">
          Recently Used
        </div>

        <RecentlyUsedTags
          accountId={accountId}
          modelName={modelName}
          onAdd={handleAddTagging}
        />

      </div>

      {<EmptyTagList hideSearch={hideSearch} tags={value.map(tagging => tagging.tag)} hideEmptyState={rest.hideEmptyState} />}

    </div>
  );
};

export default DopeTags;

export {
  TagList,
  TagDisplayList,
}
