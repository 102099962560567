import React from "react";

import map from "lodash/map";

import { useCampaignListGenerationSettings } from "./campaignSlice";

import DopeSelectionPill from "../ui/DopeSelectionPill";
import {Icon} from "@rsuite/icons";
import {MdOutlineClose, MdOutlineFilterAlt} from "react-icons/md";
import DopePill from "../ui/DopePill";

const leftIconStyles = { marginRight: "2px", color: "#255FDF", fontSize: "18px" };
const rightIconStyles = { cursor: "pointer", marginLeft: "8px" };

const FiltersSummary = ({ allowRemove = true, emptyText, filters, onRemove }) => {
  const filterSummaries = map(filters, (filter, key) => {
    let filterValue = filter.value;
    if (filter.relation === 'between' || filter.relation === 'range') {
      filterValue = `${filter.lower_bound} - ${filter.upper_bound}`;
    } else if (filter.data_type === 'boolean') {
      filterValue = 'True';
    } else if (filter.data_values !== undefined && Object.keys(filter.data_values).length > 0) {
      filterValue = filter.data_values[filterValue];
    }

    let filterDisplayName = key;
    if (filterDisplayName.includes(".")) {
      filterDisplayName = filterDisplayName.split(".")[1].split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    }
    const display = `${filterDisplayName}: ${filterValue}`
    const handleRemove = allowRemove ? () => onRemove(key) : undefined;
    return (
      <div className="margin-4-b margin-4-r" key={key}>
        <DopePill
          pillType="tag"
          text={display}
          size="small"
          hoverable={allowRemove}
          showCircle={false}
          leftIcon={<Icon as={MdOutlineFilterAlt} style={leftIconStyles} />}
          rightIcon={allowRemove ? <Icon onClick={handleRemove} as={MdOutlineClose} style={rightIconStyles} /> : null}
        />
      </div>
    );
  });

  return (
    <DopeSelectionPill.List>
      {filterSummaries}
      {filterSummaries.length === 0 && emptyText && <div>{emptyText}</div>}
    </DopeSelectionPill.List>
  );
};

const CampaignFiltersSummary = ({ allowRemove = true, emptyText }) => {
  const [settings, actions] = useCampaignListGenerationSettings();

  const { data_axle_filters } = settings;

  return (
    <FiltersSummary
      allowRemove={allowRemove}
      emptyText={emptyText}
      filters={data_axle_filters}
      onRemove={actions.removeDataAxleFilter}
    />
  );
};

export default CampaignFiltersSummary;

export {
  FiltersSummary,
};
