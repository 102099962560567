import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import authApi from "../auth/authApi";

import { useContact } from "./contactSlice";
import { useContactUI } from "./contactUISlice";
import { useObjectState } from "../hooks/useObjectState";
import DopeContentPage from "../ui/DopeContentPage";
import ContactListPage from "./ContactListPage";
import ListListPage from "../lists/ListListPage";
import ContactDrawerForm from "./ContactDrawerForm";
import DopeTabs from "../ui/DopeTabs";
import DopeDrawer from "../ui/DopeDrawer";
import DopeButton from "../ui/DopeButton";
import DopeSelectDrawer from "../ui/DopeSelectDrawer";
import CreateListDrawer from "../lists/CreateListDrawer";

const CustomerListPageContent = ({ listCount, setListCount }) => {
  const [contactCount, setContactCount] = useState(0);

  const location = useLocation();
  const defaultTab = location.state && location.state.tabName ? location.state.tabName : 'lists';

  return (
    <DopeTabs defaultTab={defaultTab}>
      <DopeTabs.Tab
        key={'lists'}
        name='lists'
        label={listCount ? `My Lists (${listCount})`: 'My Lists'}
        disabled={false}
      >
        <ListListPage setListCount={setListCount} listCount={listCount} />
      </DopeTabs.Tab>

      <DopeTabs.Tab
        key={'contacts'}
        name='contacts'
        label={contactCount ? `All Contacts (${contactCount})` : 'All Contacts'}
        disabled={false}
      >
        <ContactListPage setContactCount={setContactCount} />
      </DopeTabs.Tab>
    </DopeTabs>
  );
}

const canListPull = authApi.currentUserHasAdminPrivileges();

const listTypeOptions = [
  { label: "Upload a list", value: "upload", icon: "upload", description: "Upload a list that you have downloaded or purchased elsewhere." },
  { label: "Pull it from my CRM", value: "pull", icon: "pull", description: canListPull ? "Download contact data from your CRM" : "Coming Soon!", disabled: !canListPull },
  { label: "Create a new list", value: "create", icon: "list", description: "Build a list in the app by creating a list and adding contacts to it." }
];

const ListsAndContactsPage = () => {
  const [createListDrawerOpen, setCreateListDrawerOpen] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [state, setState] = useObjectState({ addList: false });
  const [contact, contactActions] = useContact();
  const [contactUI, contactUIActions] = useContactUI();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.uploadList) {
      handleActionClick("addList");
      location.state.uploadList = false;
    }
  }, []);

  const handleActionClick = (objectName) => {
    setState({...state, [objectName]: !state[objectName]});
  };

  const handleClickCreateList = (listType) => {
    if (listType === "upload") {
      navigate('/lists/new/upload');
    } else if (listType === "pull") {
      navigate('/list_pulls/new/setup');
    } else if (listType === "create") {
      setCreateListDrawerOpen(true);
      handleActionClick("addList");
    }
  };

  const actionButtons = (
    <>
      <DopeButton
        props={{
          onClick: () => contactUIActions.setDrawerContactId('new'),
          label: "Add a Contact",
          buttonClass: "outlined-black",
        }}
        className="margin-8-r action-button"
      />

      <DopeButton
        props={{
          onClick: () => handleActionClick("addList"),
          label: "Add a List",
          buttonClass: "filled",
        }}
        className="action-button"
      />
    </>
  );

  return (
    <>
      <DopeContentPage
        pageTitle="Lists & Contacts"
        actionButtons={actionButtons}
        pageContent={<CustomerListPageContent setListCount={setListCount} listCount={listCount} />}
      />

      <DopeSelectDrawer
        header="How do you want to add a list?"
        open={state.addList}
        onCancel={() => handleActionClick("addList")}
        onAction={handleClickCreateList}
        options={listTypeOptions}
      />

      <ContactDrawerForm
        open={!!contactUI.drawerContactId}
        onClose={() => contactUIActions.setDrawerContactId(null)}
        header={contactUI.drawerContactId === "new" ? "Add Contact" :
          `Update ${contact?.full_name ? contact.full_name : contact?.company_name ? contact.company_name : "Contact"}`}
          id={contactUI.drawerContactId}
        />

      <CreateListDrawer
        open={createListDrawerOpen}
        onClose={() => setCreateListDrawerOpen(false)}
        setListCount={setListCount}
        listCount={listCount}
      />
    </>
  );
}

export default ListsAndContactsPage;
