import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Input, InputGroup, Message } from "rsuite";
import { Icon } from "@rsuite/icons";
import { MdContentCopy, MdOutlineFileDownload } from "react-icons/md";

import StripeApi from "../stripe/StripeApi";
import DopeApi from "../services/DopeApi";
import { useAccount } from "../accounts/accountSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeSelect from "../ui/DopeSelect";
import {formatToLocaleDateString, unixTimestampToDateStr} from "../utils/date";
import DopePill from "../ui/DopePill";
import DopeButton from "../ui/DopeButton";
import PulseAccountModal from "./PulseAccountModal";
import AccountTypeModal from "./AccountTypeModal";

const creditPlanApi = new DopeApi("credit_plan");
const accountCreditPlanApi = new DopeApi("account_credit_plan");
const mainStripeProductId = process.env.REACT_APP_MAIN_STRIPE_PRODUCT;

const accountTypes = [
  { label: "Standard", value: "standard" },
  { label: "Agency", value: "agency" },
  { label: "Agency Client", value: "agency_client" },
];

const PulseSubscriptionDetails = () => {
  const [account, actions] = useAccount();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [selectOptions, setSelectOptions] = useState([]);
  const [subscriptionKeyMap, setSubscriptionKeyMap] = useState({});
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(account.account_type);
  const [subscriptionChangeOpen, setSubscriptionChangeOpen] = useState(false);
  const [subscriptionCancelOpen, setSubscriptionCancelOpen] = useState(false);
  const [creditPlans, setCreditPlans] = useState([]);
  const [selectedCreditPlan, setSelectedCreditPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [importSubscription, setImportSubscription] = useState("");

  const { id } = useParams();

  const stripeApi= new StripeApi(id);

  useEffect(() => {
    if (!account || account.id !== id) {
      actions.get(id);
    }
    getCreditPlans();
    getSubscriptionData();
    return actions.resetToInitial;
  }, []);

  const getCreditPlans = async () => {
    const plans = await creditPlanApi.getMany({ filters: [{ field: "status", value: 0, operator: "=" }, {field: "frequency", value: [0], operator: "in"}]});
    setCreditPlans(plans.map(plan => ({ label: plan.name, value: plan.id })));
    if (account.credit_plan) {
      setSelectedCreditPlan({ label: account.credit_plan.name, value: account.credit_plan.id });
    }
  }

  const selectCreditPlan = async (planId) => {
    if (planId === selectedCreditPlan.value) {
      return;
    }

    setSelectedCreditPlan(creditPlans[creditPlans.findIndex(plan => plan.value === planId)]);

    try {
      await accountCreditPlanApi.save({ id: account.account_credit_plan_id || 'new', account_id: account.id, credit_plan_id: planId });
      dopeUIActions.addFlashMessage({ type: 'success', body: 'Subscription updated', header: 'Success!'})
    } catch (err) {
      dopeUIActions.addFlashMessage({ type: 'error', body: 'Could not update subscription', header: 'Error!'})
    } finally {
      setLoading(false);
    }
  }

  const getSubscriptionData = async () => {
    stripeApi.get('stripe_subscription_data').then(res => {
      const options = []
      res.stripe_subscription_data.stripe_subscription_options.forEach(sub => {
        options.push({ label: `${sub.nickname} (${sub.recurring.interval}ly)`, value: sub.id })
      });
      setSelectOptions(options)

      const keyMap = {};
      options.map(sub => {
        keyMap[sub.value] = sub.label
      })
      setSubscriptionKeyMap(keyMap);
    });
  }

  const handleSubscriptionChangeOpen = () => setSubscriptionChangeOpen(true);
  const handleSubscriptionChangeClose = () => setSubscriptionChangeOpen(false);
  const handleSubscriptionCancelOpen = () => setSubscriptionCancelOpen(true);
  const handleSubscriptionCancelClose = () => setSubscriptionCancelOpen(false);

  const subscriptionLink = account.stripe_subscription ? `https://dashboard.stripe.com/subscriptions/${account.stripe_subscription.id}` : "";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(subscriptionLink).then(() => {
      dopeUIActions.addFlashMessage({ type: 'success', body: 'Copied to clipboard', header: 'Success!'})
    }).catch(err => {
      dopeUIActions.addFlashMessage({ type: 'error', body: 'Could not copy to clipboard', header: 'Error!'})
    });
  }

  const handleSubscriptionChange = async () => {
    setLoading(true);
    try {
      await actions.purchaseSubscription(selectedSubscription);
      handleSubscriptionChangeClose();
      dopeUIActions.addFlashMessage({ type: 'success', body: 'Subscription updated', header: 'Success!'})
    } catch (err) {
      dopeUIActions.addFlashMessage({ type: 'error', body: 'Could not update subscription', header: 'Error!'})
    } finally {
      setLoading(false);
    }
  }

  const handleSubscriptionImport = async () => {
    setLoading(true);
    try {
      await actions.importStripeSubscription(importSubscription);
      dopeUIActions.addFlashMessage({ type: 'success', body: 'Subscription imported', header: 'Success!'})
    } catch (err) {
      dopeUIActions.addFlashMessage({ type: 'error', body: 'Could not import subscription', header: 'Error!'})
    } finally {
      setLoading(false);
    }
  }

  const getSubscriptionDetails = (subscription) => {
    if (!subscription) { return; }

    let name, interval, subscription_price_id;
    subscription.items.data.forEach(item => {
      if (item.price.product === mainStripeProductId) {
        name = item.price.nickname;
        interval = item.price.recurring.interval;
        subscription_price_id = item.price.id;
      }
    })

    return { name, interval, subscription_price_id };
  }

  const subscriptionDetails = getSubscriptionDetails(account.stripe_subscription);
  const subscriptionName = account.stripe_subscription ? `${subscriptionDetails.name} (${subscriptionDetails.interval}ly)` : "None";
  const subscriptionPeriodStart = account.stripe_subscription ? formatToLocaleDateString(unixTimestampToDateStr(account.stripe_subscription?.current_period_start)) : "---";
  const subscriptionPeriodEnd = account.stripe_subscription ? formatToLocaleDateString(unixTimestampToDateStr(account.stripe_subscription?.current_period_end)) : "---";
  const currentSubscriptionStatus = account.latest_subscription_invoice ? account.latest_subscription_invoice?.status : "None";
  const openAccountTypeModal = selectedAccountType !== account.account_type;

  return (
    <>
      <PulseAccountModal
        open={subscriptionChangeOpen}
        handleClose={handleSubscriptionChangeClose}
        onSubmit={handleSubscriptionChange}
        submitText="Update Subscription"
        title="Change Subscription"
        loading={loading}
        text={`Change ${account.name}'s subscription from ${subscriptionName} to ${subscriptionKeyMap[selectedSubscription]}?`}
      />

      <PulseAccountModal
        open={subscriptionCancelOpen}
        handleClose={handleSubscriptionCancelClose}
        submitText={"Cancel Subscription"}
        title="Cancel Subscription"
        text={`Stripe Free tier must be set up before we can cancel a subscription. Please contact support.`}
      />

      <AccountTypeModal
        open={openAccountTypeModal}
        handleClose={() => setSelectedAccountType(account.account_type)}
        selectedAccountType={selectedAccountType}
      />

      <h4 className="header-4 color-black margin-bottom">Subscription</h4>
      <div style={{display: "grid", gridTemplateColumns: "49% 49%", justifyContent: "space-between"}}>
        <div>
          <label className="label">Current Subscription</label>
          <DopeSelect
            name="subscription"
            placeholder={subscriptionName}
            onChange={value => setSelectedSubscription(value.subscription)}
            options={account.stripe_subscription ? selectOptions.filter(option => option.value !== subscriptionDetails.subscription_price_id) : selectOptions}
            searchable={false}
          />

          {account.next_stripe_plan && (
            <div className="margin-4-t">
              <Message showIcon type="info">
                Account is scheduled to change to <strong>{account.next_stripe_plan.toUpperCase()}</strong> on {formatToLocaleDateString(unixTimestampToDateStr(account.stripe_subscription?.current_period_end))}
              </Message>
            </div>
          )}

          <label className="label">Stripe Subscription Link</label>
          <InputGroup inside>
            <Input onChange={() => {}} value={subscriptionLink}/>
            <InputGroup.Button onClick={copyToClipboard}>
              <Icon as={MdContentCopy} />
            </InputGroup.Button>
          </InputGroup>

          <label className="label">Account Type</label>
          <DopeSelect
            name="account_type"
            placeholder={accountTypes.find((type) => type.value === account.account_type).label}
            value={selectedAccountType}
            onChange={value => setSelectedAccountType(value.account_type)}
            options={accountTypes.filter(option => option.value !== account.account_type)}
            searchable={false}
          />

          <label className="label">Credit Plan</label>
          <DopeSelect
            name="creditPlan"
            value={selectedCreditPlan.value}
            placeholder={selectedCreditPlan.label}
            options={creditPlans}
            onChange={value => selectCreditPlan(value.creditPlan)}
            searchable={true}
          />

          {!account.stripe_subscription && (
            <div>
              <label className="label">Import Stripe Subscription</label>
              <InputGroup inside>
                <Input value={importSubscription} onChange={value => setImportSubscription(value)} />
                <InputGroup.Button onClick={handleSubscriptionImport}>
                  <Icon as={MdOutlineFileDownload}/>
                </InputGroup.Button>
              </InputGroup>
            </div>
          )}

          {account.stripe_subscription ? (<div className="space-between row margin-16-t">
            <DopeButton
              props={{
                buttonClass: "outlined-black",
                label: "Cancel Subscription",
                onClick: handleSubscriptionCancelOpen,
              }}
            />

            <DopeButton
              disabled={selectedSubscription === null}
              props={{
                buttonClass: "filled-black",
                label: "Change Subscription",
                onClick: handleSubscriptionChangeOpen,
              }}
            />

          </div>) : <DopeButton props={{buttonClass: "filled-black margin-16-t", label: "Import Subscription", onClick: handleSubscriptionImport}}/>}

        </div>

        <div>
          <h6 className="label color-black margin-4-b">Subscription Notes</h6>
          <div className="border pad-16">
            <div className="space-between row margin-4-b vertical-align">
              <span className="label large color-text-secondary">Last Bill Date</span>
              <span className="label large color-black">{subscriptionPeriodStart}</span>
            </div>

            <div className="space-between row margin-4-b vertical-align">
              <span className="label large color-text-secondary">Last Billed Subscription</span>
              <span className="label large color-black">{subscriptionName}</span>
            </div>

            <div className="space-between row margin-4-b vertical-align">
              <span className="label large color-text-secondary">Payment Status</span>
              <span className="label large color-black">
                <DopePill
                  size="small"
                  text={currentSubscriptionStatus}
                  pillType={currentSubscriptionStatus}
                />
              </span>
            </div>

            <hr className="margin-16-t margin-16-b"/>

            <div  className="space-between row margin-4-b vertical-align">
              <span className="label large color-text-secondary">Next Bill Date</span>
              <span className="label large color-black">{subscriptionPeriodEnd}</span>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default PulseSubscriptionDetails;
