import React, { useEffect, useState } from 'react';
import AccountForm from "./AccountForm";
import DopeManagement from "../ui/DopeManagement";

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, filterable: true },
  { type: 'text', dataKey: 'name', label: 'Account Name', sortable: true, filterable: true },
  { type: 'image_url', dataKey: 'logo_url', label: 'Logo URL' },
];

const scopeOptions = [
  { name: 'created_recently', label: 'Created Recently' },
  { name: 'is_pirate', label: 'Pirates Only' },
];

function AccountManagement() {
  return (
    <DopeManagement
      modelName="account"
      modelForm={AccountForm}
      columns={columns}
      scopeOptions={scopeOptions}
    />
  );
}

export default AccountManagement;
