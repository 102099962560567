import React, { useState, useEffect } from "react";

import DopeApi from "../services/DopeApi";
import MailPieceDrawer from "../mailPieces/MailPieceDrawer";

const mailRoutePieceApi = new DopeApi("mail_route_piece");

const MailRoutePieceDrawer = ({ id }) => {
  const [mailRoutePiece, setMailRoutePiece] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  useEffect(() => {
    if (!id) {
      return;
    }

    mailRoutePieceApi.get(id).then((response) => {
      setMailRoutePiece(response)
    });
  }, []);

  useEffect(() => {
    if (mailRoutePiece) {
      setSelectedImageUrl(mailRoutePiece.front_image_url);
    }
  }, [mailRoutePiece]);

  if (!mailRoutePiece) {
    return null;
  }

  const onImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
  }

  const frontImageSelected = mailRoutePiece.front_image_url === selectedImageUrl;
  const backImageSelected = mailRoutePiece.back_image_url === selectedImageUrl;

  return (
    <div>
      <div className="margin-40-b">
        <h3 className="header-3">{mailRoutePiece.campaign_name}</h3>
        <label className="label large">Route: {mailRoutePiece.zip_code}-{mailRoutePiece.crid_id}</label>
      </div>

      <div className="flex row margin-4-b">
        <MailPieceDrawer.ImagePreview mailPiece={mailRoutePiece} imageIsSelected={frontImageSelected} onImageClick={onImageClick} imageSide="front" />
        <MailPieceDrawer.ImagePreview mailPiece={mailRoutePiece} imageIsSelected={backImageSelected} onImageClick={onImageClick} imageSide="back" />
      </div>

      <img alt="selected-image" src={selectedImageUrl} className="selected-image"/>

      <MailPieceDrawer.MailItemBadge mailItem={mailRoutePiece} />

      <MailPieceDrawer.MailPieceDetails mailPiece={mailRoutePiece} />

      <MailPieceDrawer.MailPieceStatusProgress mailItem={mailRoutePiece} />
    </div>
  );
}

export default MailRoutePieceDrawer;
