import React from "react";

import { useContact } from "./contactSlice";
import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import {SelectPicker} from "rsuite";
import DopePill from "../ui/DopePill";
import {Icon} from "@rsuite/icons";
import {MdOutlineClose, MdOutlineGroup} from "react-icons/md";

const listApi = new DopeApi('lists');

const formatListsForSelectPicker = (lists, contactLists) => {
  const filtered_lists =  lists.filter(list => !contactLists.find(contactList => contactList.list_id === list.id));
  return filtered_lists.map((list) => {
    return {
      label: list.name,
      value: list.id
    }
  })
}

const ContactsListsEdit = () => {
  const [contact, contactActions] = useContact();
  // TODO: only return lists associated with the current account
  const { data: lists, onSearchClear, onSearchInput } = useQuery2({api: listApi, initialSearchScope: "name"});

  const addContactToList = (list_id) => {
    if (!list_id) return;
    const list = lists.find((list) => list.id === list_id);
    const contactList = { list_id: list_id, contact_id: contact.id, list_name: list.name };
    contactActions.addContactToList(contactList);
  }

  const removeContactFromList = (list_id) => {
    contactActions.removeContactFromList(list_id);
  }

  const allContactLists = contact.contact_lists.map((contactList) => {
    return (
      <div key={contactList.id} style={{display: "flex", margin: "0 4px 4px 0"}}>
        <DopePill
          pillType="tag"
          text={contactList.list_name}
          size="small"
          hoverable={true}
          trim={24}
          showCircle={false}
          leftIcon={<Icon as={MdOutlineGroup} style={{ marginRight: "2px", color: "#255FDF", position: "relative", top: 1, fontSize: "18px"}} />}
          rightIcon={<Icon onClick={() => removeContactFromList(contactList)} as={MdOutlineClose} style={{ cursor: "pointer", marginLeft: "8px", position: "relative"}} />}
        />
      </div>
    )
  })

  return (
    <>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
        <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column", width: "100%"}}>
          <label className="rs-form-control-label">Add to List</label>
          <SelectPicker
            data={formatListsForSelectPicker(lists, contact.contact_lists)}
            style={{ width: "100%" }}
            placeholder="Search and select a list"
            onSearch={onSearchInput}
            onChange={(value, event) => addContactToList(value, event)}
          />
        </div>
      </div>

      <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", margin: "8px 0 32px"}}>
        {allContactLists}
      </div>
    </>
  )
}

export default ContactsListsEdit;
