import React from "react";


import HandwrittenFontItem from "./HandwrittenFontItem";


const HandwrittenFontDrawer = ({ onSelect, selected, fonts }) => {
  return (
    <div className="handwritten-fonts">
      {fonts.map(f => <HandwrittenFontItem key={f.id} selected={f.id === selected?.id} onClick={onSelect} {...f} />)}
    </div>
  );
};

export default HandwrittenFontDrawer;
