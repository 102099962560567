import React, { useState, useEffect } from "react";
import { Grid, Row, Col, IconButton } from 'rsuite';
import { Icon } from "@rsuite/icons";
import CloseIcon from '@rsuite/icons/Close';
import { MdPanorama, MdAdd } from "react-icons/md";

import { useAutomation } from "./automationSlice";
import DopeButton from "../ui/DopeButton";
import CampaignMailerSelect from "../campaigns/CampaignMailerSelect";
import { DispatchMailer } from "../campaigns/CampaignDispatchList";
import DopeTextInput from "../ui/DopeTextInput";
import HotLeadDrawer from "../campaigns/HotLeadDrawer";

const AutomationDispatchTemplates = ({ editable = true, withHeader = false, withContactCol = false }) => {
  const { automation, derivedAutomationAttrs, actions } = useAutomation();
  const [mailersDrawerOpen, setMailersDrawerOpen] = useState(false);
  const [hotLeadDrawerOpen, setHotLeadDrawerOpen] = useState(false);
  const [needsSetup, setNeedsSetup] = useState(false);
  const [dispatchTemplate, setDispatchTemplate] = useState(null);

  const dispatchTemplates = automation.campaign_template.dispatch_templates;
  const hasDispatchTemplates = dispatchTemplates.length > 0;
  const settings = automation.campaign_template.list_generation_setting;
  const audienceSize = settings.generation_type === 'blitz' ? settings.max_contacts_per_generation : 1;

  const handleMailerSelect = (mailer) => {
    setMailersDrawerOpen(false);

    const maxDaysOffset = dispatchTemplates.reduce((max, dispatchTemplate) => {
      return Math.max(max, dispatchTemplate.days_offset);
    }, 0);

    const dispatch_template = {
      days_offset: maxDaysOffset + 1,
      hot_lead_url: null,
      hot_lead_phone: null,
      ...mailer,
    };

    actions.addDispatchTemplate(dispatch_template);

    if (mailer.mailer_type === 'hot_lead') {
      setNeedsSetup(true);
    } else {
      setMailersDrawerOpen(false);
    }
  };

  const addMailerPanel = editable && (
    <>
      <div className="text-center">
        {!hasDispatchTemplates && (<Icon as={MdPanorama} style={{ color: "rgb(243, 244, 246)", fontSize: "96px"}} />)}
        {!hasDispatchTemplates && (<p className="body-1 color-text-label-blue-grey margin-bottom">Add your first mailer!</p>)}
        <DopeButton
          icon={<MdAdd className="margin-4-r" />}
          className="outlined-black rectangle submit"
          onClick={() => setMailersDrawerOpen(true)}>
          Add mailer
        </DopeButton>
      </div>
    </>
  );

  const noDispatchesMessage = !editable && derivedAutomationAttrs.noDispatches && (
    <div className="pad-tb-70 margin-tb-70 text-center to-center">
      <div className="max-width-500">
        <div className="header-1">👨‍🎨 ‍🎨 🧑‍🎨</div>
        <div className="header-5 margin-8-tb">Your automation is missing mailers!</div>
        <div className="help-text">To approve triggers or turn your automation Active, designs will need to be added to your automation. You can still test your automation and receive triggers in the meantime.</div>
      </div>
    </div>
  );

  const mailerSpan = withContactCol = false ? 14 : 19;

  const tableHeader = withHeader && (
    <Row className="header">
      <Col xs={mailerSpan}>Mailer</Col>
      {withContactCol = false && (<Col xs={5}>Contacts</Col>)}
      <Col xs={5}>Send Date</Col>
    </Row>
  );

  const dispatchRows = dispatchTemplates.map((dispatchTemplate, index) => {
    return (
      <Row key={index}>
        <Col xs={mailerSpan}>
          <DispatchMailer
            dispatch={dispatchTemplate}
            openDrawer={(index) => {
              setDispatchTemplate(index);
              setHotLeadDrawerOpen(true);
            }}
            index={index}
            actions={actions}
            dispatches={dispatchTemplates}
            editable={editable}
          />
        </Col>
        {withContactCol = false && (<Col xs={5}>{audienceSize}</Col>)}
        <Col xs={5}>
          <div className="vertical-align gap-10">
            <span><b>Day</b></span>
            <DopeTextInput
              type="number"
              name="days_offset"
              value={dispatchTemplate.days_offset}
              onChange={update => actions.updateDispatchTemplate({ index, update })}
              readOnly={!editable}
            />
            {editable && (<IconButton
              onClick={() => actions.removeDispatchTemplate(index)}
              appearance="subtle"
              icon={<CloseIcon />}
            />)}
          </div>
        </Col>
      </Row>
    );
  });

  const dispatchesTable = derivedAutomationAttrs.noDispatches ? null : (
    <Grid fluid className="dispatch-list">
      {tableHeader}
      {dispatchRows}
    </Grid>
  );

  useEffect(() => {
    if (needsSetup) {
      setMailersDrawerOpen(false);
      setDispatchTemplate(dispatchTemplates.length - 1);
      setHotLeadDrawerOpen(true);
    }
  }, [dispatchTemplates, needsSetup]);

  return (
    <>
      {noDispatchesMessage}

      {dispatchesTable}

      {addMailerPanel}

      <CampaignMailerSelect
        onSelect={handleMailerSelect}
        onClose={() => setMailersDrawerOpen(false)}
        open={mailersDrawerOpen}
      />

      <HotLeadDrawer
        open={hotLeadDrawerOpen}
        onClose={(dispatch, remove) => {
          setNeedsSetup(false);
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);

          if (remove === true) {
            actions.removeDispatchTemplate(dispatchTemplate);
          }
        }}
        onSave={() => {
          setNeedsSetup(false);
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);
        }}
        index={dispatchTemplate}
        dispatches={dispatchTemplates}
        actions={actions}
      />
    </>
  )
};

export default AutomationDispatchTemplates;
