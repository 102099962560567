import React from "react";
import {Button, Dropdown} from "rsuite";
import ArrowDownIcon from '@rsuite/icons/ArrowDown';

let styles = {
  width: 'fit-content',
  border: '1px solid #EA0029',
  padding: '7px 15px',
  borderRadius: '21px',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '21px',
  cursor: 'pointer',
  margin: '0px 0px 0px 10px',
  height: '44px',
  minWidth: '127px'
};


const subtleCancelStyles = {
  color: '#6F6F6F',
  backgroundColor: 'transparent',
  fontSize: "14px",
  fontWeight: 600,
}

const DopeActionButton = ({ label, icon, props, onClick, type, buttonType, disabled, children }) => {
  const { primary, iconDirection } = props;

  if (primary) {
    styles = { ...styles, ...{ color: '#FFFFFF', backgroundColor: '#EA0029' } }
  } else {
    styles = { ...styles, ...{ color: '#EA0029', backgroundColor: 'transparent' } }
  }

  if (disabled === true) {
    styles = { ...styles, ...{ opacity: 0.3, cursor: 'not-allowed' } };
  } else {
    styles = { ...styles, ...{ opacity: 1, cursor: 'pointer' } };
  }

  const ActionButton = () => {
    switch (buttonType) {
      case 'dropdown':
        return (
          <Dropdown
            title={label}
            className="custom-dropdown-button"
            placement="bottomEnd"
            style={{ height: "44px", width: "fit-content", alignSelf: "center" }}
            icon={<ArrowDownIcon style={{ fontSize: "16px", position: "absolute", right: "7px", top: "14px"}} />}
          >
            {children}
          </Dropdown>
        )
      case 'subtle-cancel':
        return (
          <Button
            className="subtle-cancel-button"
            onClick={onClick}
            style={subtleCancelStyles}>{label}
          </Button>
        )
      default:
        return (
          <Button key={label} style={styles} onClick={onClick} type={type} disabled={disabled === true} >
            {iconDirection === 'right' ? (
              <>{ label } {icon}</>
            ) : (
              <>{ icon } {label}</>
            )}
          </Button>
        )
    }
  }

  return (
    <ActionButton />
  );
}

export default DopeActionButton;
