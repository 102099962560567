import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";

const [trackingNumberSlice, asyncActions] = createModelSlice('tracking_number', { });

export default trackingNumberSlice.reducer;

export const {
  resetToInitial,
} = trackingNumberSlice.actions;

const trackingNumberToParams = (trackingNumber, prevtrackingNumber) => {
  return {
    ...trackingNumber,
  };
};

const trackingNumberSelector = state => {
  return state.trackingNumber.current;
}

const derivedTrackingNumberAttrsSelector = createSelector([trackingNumberSelector], (trackingNumber) => {
  if (!trackingNumber) return {};

  const derivedUpdates = {};

  return derivedUpdates;
});

export function useTrackingNumber(trackingNumberId) {
  const dispatch = useDispatch();
  const trackingNumber = useSelector(trackingNumberSelector);
  const derivedTrackingNumberAttrs = useSelector(derivedTrackingNumberAttrsSelector);
  const prevTrackingNumber = useSelector(state => state.trackingNumber.prev);
  const isSaving = useSelector(state => state.trackingNumber.isSaving);

  const actions = wrapAll({
    ...trackingNumberSlice.actions,
    ...asyncActions,
    resetToInitial: resetToInitial,
    save: (additionalParams = {}) => {
      const { code, ...extras } = additionalParams;
      const { ...params } = { ...trackingNumberToParams(trackingNumber, prevTrackingNumber), ...extras };

      delete params.errors;

      return asyncActions.save({...params, code });
    },
    cancel: () => {
      const { id } = { ...trackingNumberToParams(trackingNumber, prevTrackingNumber) };
      return asyncActions.save({ id, status: 'canceled' });
    }
  }, dispatch);

  useEffect(() => {
    const shouldGet = trackingNumberId && (!trackingNumber || trackingNumber.id !== trackingNumberId);
    if (shouldGet) {
      actions.get(trackingNumberId);
    }
  }, [trackingNumberId, trackingNumber?.id]);



  return {
    trackingNumber,
    derivedTrackingNumberAttrs,
    actions,
    isSaving,
  };
};
