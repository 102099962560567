import React from "react";
import { Modal } from "rsuite";
import DopeButton from "../ui/DopeButton";

const PulseAccountModal = ({ open, handleClose, onSubmit, submitText, title, text, loading }) => {


    return (
      <div>
        <Modal open={open} onClose={handleClose}>
          <div style={{ paddingTop: "16px"}}>
            <Modal.Header>
              <Modal.Title style={{fontWeight: 600, fontSize: "18px", color: "#000000", marginBottom: "16px"}}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{fontWeight: 400, fontSize: "16px", color: "#000000", marginBottom: "16px"}}>
              {text}
            </Modal.Body>
            <Modal.Footer>
              <DopeButton
                loading={loading}
                props={{
                  label: submitText,
                  buttonClass: "filled-black",
                  onClick: onSubmit,
                  styles: { width: "100%" },
                }}
              />
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
}

export default PulseAccountModal;
