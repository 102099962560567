import React, {useEffect} from "react";
import {Form, Grid, Row, SelectPicker} from "rsuite";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import DopeButton from "../ui/DopeButton";

const userApi = new DopeApi('user');

const ExistingTeamMemberForm = ({getUser, resetModal}) => {
  const { tableProps, onSearchInput } = useQuery2({api: userApi, initialSearchScope: "email"});

  const handleSelect = async (userId) => {
    await getUser(userId);
    resetModal();
  }

  return (
    <div>
      <Form>
        <Grid fluid>
          <Row>
            <label className="label">Email</label>
            <SelectPicker
              data={tableProps.data.map(user => ({label: user.email, value: user.id}))}
              onSearch={onSearchInput}
              onChange={handleSelect}
              style={{ width: "100%", marginBottom: "16px"}}
            />
          </Row>
      </Grid>

        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "24px"}}>
          <DopeButton
            props={{
              buttonClass: "text-link",
              label: "Back",
              onClick: resetModal,
            }}
          />
        </div>
    </Form>
</div>
)
  ;
}

export default ExistingTeamMemberForm;
