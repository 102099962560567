
import React from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const initial = {
  days_offset: 1,
  position: 0,
  campaign_template_id: null,
};

const inputs = [
  { inputType: "text", name: "days_offset", props: { label: "Days Offset" } },
  { inputType: "text", name: "position", props: { label: "Position" } },
  { inputType: "belongsTo", name: "campaign_template_id", props: {
    label: "Campaign Template",
    belongsToModelName: "campaign_template"
  }},
  { inputType: "belongsTo", name: "mail_template_id", props: {
    label: "Mail Template",
    belongsToModelName: "postcard_template" // TODO need a belongsToPolymorphic
  }},
];

const DispatchTemplateForm = (props) => {
  return (
    <ModelForm
      modelName="dispatch_template"
      initialResource={initial}
      inputs={inputs}
      {...props}
    />
  );
};

const columns = [
  { type: "text", dataKey: "id", label: "ID", sortable: true, filterable: true },
  { type: "text", dataKey: "campaign_template_name", label: "Campaign Template" },
  { type: "text", dataKey: "mail_template_name", label: "Mail Template" },
  { type: "text", dataKey: "days_offset", label: "Days Offset" },
  { type: "text", dataKey: "position", label: "Position" },
];

const scopeOptions = [];

const DispatchTemplateManagement = (props) => {
  return (
    <DopeManagement
      modelName="dispatch_template"
      modelForm={DispatchTemplateForm}
      columns={columns}
      scopeOptions={scopeOptions}
      {...props}
    />
  );
};

export default DispatchTemplateManagement;
