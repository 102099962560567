import React from "react";

import { Loader } from 'rsuite';

import './DopePageLoader.scss';

const DopePageLoader = (props) => {
  return (
    <div className="page dope-page-loader">
      <Loader size="lg" {...props} />
    </div>
  );
};

export default DopePageLoader;
