import React, {useEffect, useState} from "react";
import {DateRangePicker} from "rsuite";

const DopeDateRangeFilter = ({ filterInput, filters }) => {
  const { applyFilter } = filterInput;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (filters.filter(filter => filter.hasOwnProperty('filter_category')).length === 0) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [filters]);

  const handleApplyDateRange = (value) => {
    let newStartDate, newEndDate;
    if (Array.isArray(value)) {
      newStartDate = value[0];
      newEndDate = value[1];
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      newStartDate = null;
      newEndDate = null;
      setStartDate(null);
      setEndDate(null);
    }

    applyFilter(newStartDate, newEndDate);
  }

  const defaultDateRange = (startDate && endDate) ? [startDate, endDate] : null;

  return (
    <div>
      <DateRangePicker
        onChange={value => handleApplyDateRange(value)}
        value={defaultDateRange}
        placeholder="Select Date Range"
        size="sm"
        format="MM.dd.yy"
      />
    </div>
  )
}

export default DopeDateRangeFilter;
