import './RichTextView.scss';

const RichTextView = ({ content }) => {

  if (!content) {
    return null;
  }

  const { blocks } = (typeof content === 'string') ? JSON.parse(content) : content;

  if (!blocks) {
    return null;
  }

  return (
    <div className="rich-text-view">
      {blocks.map((block, index) => {
        switch (block.type) {
          case 'header':
            if (block.data.level === 1) {
              return <h1 key={index}>{block.data.text}</h1>;
            } else if (block.data.level === 2) {
              return <h2 key={index}>{block.data.text}</h2>;
            } else if (block.data.level === 3) {
              return <h3 key={index}>{block.data.text}</h3>;
            } else if (block.data.level === 4) {
              return <h4 key={index}>{block.data.text}</h4>;
            } else if (block.data.level === 5) {
              return <h5 key={index}>{block.data.text}</h5>;
            } else if (block.data.level === 6) {
              return <h6 key={index}>{block.data.text}</h6>;
            }
            break;
          case 'paragraph':
            return <p key={index} dangerouslySetInnerHTML={{ __html: block.data.text }}></p>;
          case 'list':
            if (block.data.style === 'ordered') {
              return (
                <ol key={index}>
                  {block.data.items.map((item, index) => (
                    <li key={index} dangerouslySetInnerHTML={{ __html: item.content }}></li>
                  ))}
                </ol>
              );
            }

            if (block.data.style === 'unordered') {
              return (
                <ul key={index}>
                  {block.data.items.map((item, index) => (
                    <li key={index} dangerouslySetInnerHTML={{ __html: item.content }}></li>
                  ))}
                </ul>
              );
            }

            return null;
          case 'image':
            return <img key={index} src={block.data.file.url} alt={block.data.caption} />;
          case 'embed':
            return <iframe key={index} src={block.data.embed} title={block.data.caption} />;
          default:

            return null;
        }

        return null;
      })}
    </div>
  );
};

export default RichTextView;
