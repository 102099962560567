import React from "react";

import { useNavigate } from 'react-router-dom';

import useQuery from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import { Icon } from "@rsuite/icons";
import { FaPlus } from "react-icons/fa";

import DopeButton from "../ui/DopeButton";
import DopeListPage from "../ui/DopeListPage";

const api = new DopeApi('conversion_report');

const columns = [
  { type: 'name_link', dataKey: 'name', label: 'Name', sortable: true, getUrl: (rowData) => `/conversion_reports/${rowData.id}/show` },
  { type: 'pill', dataKey: 'conversion_type', pillTypeKey: '', label: 'Conversion Type' }, // TODO label here
  { type: 'date', dataKey: 'created_at', label: 'Created Date', sortable: true },
];

const ConversionReportTable = () => {
  const navigate = useNavigate();

  const handleClickNew = () => {
    navigate('/conversion_reports/new/setup');
  };

  const {
    error,
    tableProps,
    paginationProps,
    filters,
    setFilters,
  } = useQuery(api);

  const handleSearchClear = () => {
    setFilters([]);
  };

  // TODO: Refactor this to use searchable
  const handleSearchChange = (value) => {
    if (value) {
      setFilters([{
        field: 'name',
        value: value,
        operator: 'ilike'
      }]);
    } else {
      handleSearchClear();
    }
  };

  return (
    // <div className="page">
    //   <div className="page-header">
    //     <p className="header-text">Matchback Reports</p>
    //   </div>

    //   <div className="top-right-actions">
    //     <DopeButton
    //       icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "0px 4px 0 0"}} />}
    //       props={{
    //         label: "Create New",
    //         onClick: handleClickNew,
    //         buttonClass: "filled"
    //       }}
    //     />
    //   </div>

      <DopeListPage
        paginationProps={paginationProps}
        tableProps={tableProps}
        columns={columns}
        error={error}
        filters={filters}
        setFilters={setFilters}
        onSearchClear={handleSearchClear}
        onSearchChange={handleSearchChange}
      />
    // </div>
  );
};

export default ConversionReportTable;
