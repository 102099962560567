import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "rsuite";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";

import { useList } from "./listSlice";
import { useListUI } from "./listUISlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeApi from "../services/DopeApi";
import { DopeFormGroup } from "../ui/DopeForm";
import DeleteListConfirmationModal from "./DeleteListConfirmationModal";

const contactListApi = new DopeApi('contact_lists');

const DropdownMenuItem = ({ icon, text, onClick, hidden }) => {
  if (hidden) return null;

  return (
    <Dropdown.Item className="clickable margin-8-b" onSelect={onClick}>
      <div className="label large flex row vertical-align bold">
        {icon}
        <div>{text}</div>
      </div>
    </Dropdown.Item>
  )
}

const ListDetailsDropdown = ({ filters, setLoading }) => {
  const [list, listActions] = useList();
  const [listUI, listUIActions] = useListUI();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const navigate = useNavigate();

  const handleEditList = () => {
    listUIActions.setDrawerListId(list.id);
  }

  const handleDownloadList = async () => {
    setLoading(true)
    try {
      await contactListApi.exportCSV({ filters }, list.name);
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error downloading list", body: error.message, type: "error" });
    } finally {
      setLoading(false)
    }
  }

  const handleAddToList = () => {
    navigate(`/lists/${list.id}/upload`, { state: { listType: 'existing', listId: list.id } });
  }

  const handleEditListPull = () => {
    navigate(`/list_pulls/${list.list_pull_id}/setup`);
  };

  const isFromListPull = !!list.list_pull_id;

  const dropdownItems = [
    { icon: <FaRegEdit className="dropdown-item-icon margin-8-r" />, text: "Edit List", onClick: handleEditList },
    { icon: <FaRegEdit className="dropdown-item-icon margin-8-r" />, text: "Edit List Pull", onClick: handleEditListPull, hidden: !isFromListPull },
    { icon: <FaRegTrashAlt className="dropdown-item-icon margin-8-r" />, text: "Delete List", onClick: () => listUIActions.setModalListId(list.id) },
    { icon: <MdOutlineFileDownload className="dropdown-item-icon margin-8-r" />, text: "Download List", onClick: handleDownloadList },
    // { icon: <PlusRoundIcon style={iconStyles}/>, text: "Add to List", onClick: handleAddToList },
    // { icon: <PublicOpinionIcon style={iconStyles}/>, text: "Add to Favorites", onClick: handleFavoriteList },
  ];

  return (
    <div className="margin-4-t">
      {dropdownItems.map((item, index) => (
          <DropdownMenuItem key={index} icon={item.icon} text={item.text} onClick={item.onClick} hidden={item.hidden} />
      ))}

      <hr style={{height: "1px", color: "#EAECEF", margin: "16px 20px"}}/>

      <div className="flex column pad-lr">
        <div className="label bold margin-4-b">Custom Tags</div>
        <DopeFormGroup
          input={{
            inputType: "tags",
            name: "taggings",
            props: {modelName: "list", hideEmptyState: true}
          }}
          values={list}
          onChange={listActions.addTagToList}
        />
      </div>

      <DeleteListConfirmationModal open={!!listUI.modalListId} onClose={() => listUIActions.setModalListId(null)} />

    </div>
  )
}

export default ListDetailsDropdown;
