import React from "react";
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const initialFilter = {
  name: '',
  description: '',
  target_type: '',
  active: false,
  price: 0.0,
  accepted_inputs: '',
  upper_bound: 0,
  lower_bound: 0,
}

const filterInputs = [
  {
    inputType: 'text',
    name: 'display_name',
    props: {
      label: 'Name',
      type: 'text'
    }
  },
  {
    inputType: 'text',
    name: 'description',
    props: {
      label: 'Description',
      type: 'text'
    },
  },
  {
    inputType: 'text',
    name: 'target_type',
    props: {
      label: 'Target',
      type: 'text',
      disabled: true,
    }
  },
  {
    inputType: 'toggle',
    name: 'active',
    props: {
      checkedText: 'Active',
      uncheckedText: 'Inactive',
    }
  },
  {
    inputType: 'text',
    name: 'price',
    props: {
      label: 'Price',
      type: 'number',
    }
  },
  {
    inputType: 'text',
    name: 'accepted_inputs',
    props: {
      label: 'Accepted Inputs (comma separated)',
      type: 'text',
    }
  },
  {
    inputType: 'text',
    name: 'lower_bound',
    props: {
      label: 'Lower Bound',
      type: 'number',
    }
  },
  {
    inputType: 'text',
    name: 'upper_bound',
    props: {
      label: 'Upper Bound',
      type: 'number',
    }
  }
]

const DataAxleFilterForm = (props) => {
  return (
    <ModelForm
      modelName="data_axle_filter"
      initialResource={initialFilter}
      inputs={filterInputs}
      {...props}
    />
  )
}

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, filterable: true },
  { type: 'text', dataKey: 'display_name', label: 'Name'},
  { type: 'text', dataKey: 'description', label: 'Description' },
  { type: 'text', dataKey: 'price', label: 'Price per Record' },
  { type: 'text', dataKey: 'packages', label: 'Packages' }
]

const scopeOptions = [
  { name: 'active', label: 'Active' },
  { name: 'people', label: 'People' },
  { name: 'places', label: 'Places' }
]

function DataAxleFilterManagement() {
  return (
    <DopeManagement
      modelName="data_axle_filter"
      modelForm={DataAxleFilterForm}
      columns={columns}
      scopeOptions={scopeOptions}
    />
  )
}

export default DataAxleFilterManagement;