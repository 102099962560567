import React, { useEffect, useState, useRef } from 'react';

import DopeApi from '../services/DopeApi';

import { Loader } from 'rsuite';

import DopePill from '../ui/DopePill';

const delayedJobApi = new DopeApi('delayed_job');

const wrapperStyles = { position: 'relative' };
const loaderWrapperStyles = { position: 'absolute', top: 8, left: 16 };

const DelayedJobProgress = ({ id, onSuccess, completeStatus = 'complete', runningStatus = 'running' }) => {
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);
  const [complete, setComplete] = useState(false);

  const intervalIdRef = useRef(null);

  const fetchJob = () => {
    delayedJobApi.get(id)
      .then(job => {
        setJob(job);
        if (job.status === 'error') {
          clearInterval(intervalIdRef.current);
        }
      })
      .catch(error => {
        if (error.error_status_code === 404) {
          setJob(null); // Job deleted means it's complete
          setComplete(true);
          onSuccess();
        } else {
          setError("Failed To Fetch Progress Update")
        }
        clearInterval(intervalIdRef.current);
      });
  };

  useEffect(() => {
    fetchJob();
    const intervalId = setInterval(fetchJob, 1000);
    intervalIdRef.current = intervalId;

    return () => clearInterval(intervalIdRef.current);
  }, [id]);

  let status = 'queued';
  if (job) {
    status = job.status;
    if (status === 'running') {
      status = runningStatus;
    }
  }
  if (complete) {
    status = completeStatus;
  }
  if (error) {
    return error;
  }

  const isError = status === 'error' || !!error;
  const showLoader = !complete && !isError;

  return (
    <span style={wrapperStyles}>
      <DopePill text={status} />

      {showLoader && <span style={loaderWrapperStyles}><Loader size="xs" inverse /></span>}
    </span>
  );
};

export default DelayedJobProgress;
