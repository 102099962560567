import React from "react";
import { MdOutlineCheckCircle, MdOutlineCancel } from "react-icons/md";

import { useCampaign, useCampaignListGenerationSettings } from "./campaignSlice";
import { useCampaignUI } from "./campaignUISlice";

import { Tooltip, Whisper } from "rsuite";
import RemindRoundIcon from '@rsuite/icons/RemindRound';
import { AttributeDisplay, AttributesDisplay } from "../ui/AttributeDisplay";
import { capitalize } from "lodash";
import CampaignFiltersSummary from "./CampaignFiltersSummary";
import { TagList } from "../ui/DopeTags";


const AudienceItem = ({ item }) => {
  const { type, name, count, status, status_message, is_exclusion } = item;

  const countDisplay = count ? `${count} Contacts` : '';
  const isError = status === 'error';

  let marker = is_exclusion ? <MdOutlineCancel className="color-text-red" /> : <MdOutlineCheckCircle className="color-text-success-green" />;
  let typeDisplay = (<div className="flex row vertical-align no-wrap-text">{marker}&nbsp;&nbsp;{type} - </div>)
  if (isError) {
    marker = <RemindRoundIcon color="red" />;
    const errorMessage = status_message || 'Failed to generate list';
    typeDisplay = (
      <Whisper trigger="hover" placement="top" speaker={<Tooltip>{errorMessage}</Tooltip>}>
        <span className="color-text-red bold hoverable"><span>{marker}&nbsp;&nbsp;<b>{type}:</b></span></span>
      </Whisper>
    );
  }

  return (
    <div className="vertical-align space-between margin-8-b">
      <div className="body-1 flex row vertical-align three-quarter-width clip-overflow">{typeDisplay}&nbsp;<div className="no-wrap-text">{name}</div>&nbsp;</div>
      <div className="body-1 color-text-secondary no-wrap-text">{countDisplay}</div>
    </div>
  );
};

const CampaignContactGroupList = ({ contactGroups }) => {
  const empty = contactGroups.length === 0;

  return (
    <div className="campaign-list-list">
      {contactGroups.map(item => (
        <div className="campaign-list-list-item" key={item.key}>
          <AudienceItem item={item} />
        </div>
      ))}
      {empty && <div className="campaign-list-list-item">None</div>}
    </div>
  );
};

const campaignTypeDisplay = {
  User: "User Generated Campaign",
  AutomationRun: "Automated Campaign",
};

const EddmCampaignSummary = () => {
  const [campaign, actions, options] = useCampaign();
  const { eddm } = campaign;
  const { audience } = eddm;
  const { eddmData, routesData } = options;

  const campaignDetails = [
    { label: "Campaign Type", value: campaignTypeDisplay[campaign.creator_type] },
    { label: "Sending Type", value: "EDDM" },
    { label: "Audience Type", value: capitalize(audience) },
  ];

  const eddmListGeneration = [
    {
      key: 'eddm-lists',
      type: 'Routes',
      count: eddmData.audienceCount.toLocaleString(),
      name: eddm.zip_crids.join(", "),
    }
  ];

  return (
    <div>
      <AttributeDisplay label="List Generation" value={<CampaignContactGroupList contactGroups={eddmListGeneration} />}/>
      <AttributeDisplay label="Campaign Details" value={<AttributesDisplay attributes={campaignDetails} />} />
      {campaign.taggings.length > 0 && <AttributeDisplay label="Tags" value={<TagList tags={campaign.taggings.map(t => t.tag)}/>}/>}
    </div>
  );
};

const CampaignListsSummary = () => {
  const [campaign] = useCampaign();
  const [settings] = useCampaignListGenerationSettings();
  const [campaignUI] = useCampaignUI();
  const { starting_list, generation_type, full_name, full_address } = settings;
  const { list_generations, list_generation_setting_seed_lists, list_generation_setting_suppression_lists } = settings;

  const seedLists = list_generation_setting_seed_lists.map(l => ({
    key: 'list-' + l.list.id,
    type: 'Seeding List',
    count: l.list.contacts_count,
    name: l.list.name,
  }));

  const generationLists = list_generations.map(l => ({
    key: 'list-' + l.list.id,
    type: 'List Generation',
    count: l.list.contacts_count,
    name: l.list.name,
    status: l.status,
    status_message: l.status_message,
  }));

  const contactGroups = [...seedLists, ...generationLists];
  if (!campaign.scheduled && generation_type !== 'list') {
    const toGenerateList = {
      key: 'to-generate',
      type: 'List Generation',
      count: campaignUI.purchase_summary?.new_contacts_count || '---',
      name: 'Campaign Contact Generation Estimate',
    };
    contactGroups.push(toGenerateList);
  }

  if (generation_type == 'list') {
    if (starting_list) {
      contactGroups.push({
        key: 'starting-list-' + starting_list.id,
        type: 'Starting List',
        count: starting_list.contacts_count,
        name: starting_list.name,
      });
    } else { // direct send
      contactGroups.push({
        key: 'starting-contact',
        type: 'Contact',
        count: null,
        name: `${full_name} - ${full_address}`,
      });
    }
  }

  const suppressionLists = list_generation_setting_suppression_lists.map(l => ({
    key: 'list-' + l.list.id,
    type: 'Suppression List',
    is_exclusion: true,
    count: l.list.contacts_count,
    name: l.list.name,
  }));
  contactGroups.push(...suppressionLists);

  const tagList = (
    <div>
      <TagList tags={campaign.taggings.map(t => t.tag)} />
    </div>
  );

  let sendingType = {
    list: "List Campaign",
    blitz: "Neighborhood Blitz",
    list_blitz: "List Blitz",
  }[generation_type];
  if (generation_type === 'list' && !starting_list) {
    sendingType = "Direct Sending";
  }

  const campaignDetails = [
    { label: "Campaign Type", value: campaignTypeDisplay[campaign.creator_type] },
    { label: "Sending Type", value: sendingType },
  ]

  if (generation_type !== 'list') {
    campaignDetails.push({ label: "Audience Type", value: capitalize(campaign.list_generation_setting.audience_type) });
  }

  const campaignSettings = [
    { label: "# of contacts per address", value: settings.max_contacts_per_generation },
    { label: "Max distance", value: settings.max_distance / 1609 + " miles" },
  ]

  return (
    <div>
      <AttributeDisplay label="List Generation" value={<CampaignContactGroupList contactGroups={contactGroups} />}/>
      {Object.keys(settings.data_axle_filters).length > 0 && <AttributeDisplay label="Filters" value={<CampaignFiltersSummary allowRemove={false} emptyText="None"/>}/>}
      <AttributeDisplay label="Campaign Details" value={<AttributesDisplay attributes={campaignDetails} />} />
      {generation_type === "list_blitz" && <AttributeDisplay label="Campaign Settings" value={<AttributesDisplay attributes={campaignSettings}/>} />}
      {campaign.taggings.length > 0 && <AttributeDisplay label="Campaign Tags" value={tagList} />}
    </div>
  );
};

const CampaignAudienceSummary = () => {
  const [campaign] = useCampaign();

  return (
    <div className="campaign-audience-summary">
      <div className="campaign-lists-summary pad">
        {campaign.is_eddm ? <EddmCampaignSummary /> : <CampaignListsSummary/>}
      </div>
    </div>
  );
};

export default CampaignAudienceSummary;

export {
  AudienceItem,
}
