import React, {useState, useEffect} from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import { useStripeUI } from "../stripe/stripeUISlice";
import { useAccount } from "./accountSlice";
import DopeListPage from "../ui/DopeListPage";
import DopeQuickScopeButton from "../ui/DopeQuickScopeButton";
import authApi from "../auth/authApi";
import map from "lodash/map";
import DopeDrawer from "../ui/DopeDrawer";
import InvoiceDrawer from "../stripe/InvoiceDrawer";

const creditTransactionsApi = new DopeApi("credit_transaction");
const accountApi = new DopeApi("account");

// const displayNames = {
//   postcard: "Postcards",
//   handwritten_card: "Handwritten Cards",
//   goodies: "Goodies",
//   data: "Data"
// }

const invoiceIDStyles = {
  fontSize: '12px',
  fontWeight: 400,
  color: '#255FDF'
};

const columns = [
  { type: 'timestamp', dataKey: 'created_at', label: 'Date', flexGrow: 2, sortable: true },
  { type: 'text', dataKey: 'product_label', label: 'Credit Type', flexGrow: 2 },
  { type: 'text', dataKey: 'description', label: 'Description', flexGrow: 2 },
  { type: 'clickable_text', dataKey: 'purchase_details', label: 'Notes', flexGrow: 2, customStyles: invoiceIDStyles },
  { type: 'text', dataKey: 'deposit_amount', label: 'Deposits', flexGrow: 2 },
  { type: 'text', dataKey: 'withdrawal_amount', label: 'Withdrawals', flexGrow: 2 },
];

const scopeOptions = [
  { name: "postcards", label: "Postcards" },
  { name: "handwritten", label: "Handwritten" },
  { name: "goodies", label: "Goodies" },
  { name: "data", label: "Data" },
  { name: "eddm_6_5x9", label: "EDDM 6.5x9" },
  { name: "eddm_6_5x11", label: "EDDM 6.5x11" },
  { name: "eddm_8_5x11", label: "EDDM 8.5x11" },
];

const AccountCreditLedger = () => {
  // const [creditBalances, setCreditBalances] = useState(null);
  const [postcardScopeApplied, setPostcardScopeApplied] = useState(false);
  const [handwrittenScopeApplied, setHandwrittenScopeApplied] = useState(false);
  const [goodiesScopeApplied, setGoodiesScopeApplied] = useState(false);
  const [dataScopeApplied, setDataScopeApplied] = useState(false);
  const [stripeUI, stripeUiActions] = useStripeUI();
  const [account, accountActions] = useAccount();
  const { tableProps, paginationProps, onSearchClear, onSearchInput, scopes, setScopes }
    = useQuery2({
      api: creditTransactionsApi,
      initialSearchScope: "description",
    });

  const accountId = authApi.getCurrentAccountId();

  // const getCreditBalances = async () => {
  //   accountApi.get(accountId).then(({ credit_balances }) => {
  //     setCreditBalances(credit_balances);
  //   });
  // }

  useEffect(() => {
    // getCreditBalances();

    if (!account) {
      accountActions.get(accountId);
    }
  }, [accountId]);

  const toggleScope = (scopeName) => {
    const hasScope = scopes.find(scope => scope.name === scopeName);
    if (hasScope) {
      setScopes([]);
    } else {
      setScopes([{ name: scopeName }]);
    }
  };

  const scopeButtons = scopeOptions.map((scope) => {
    return (
      <DopeQuickScopeButton
        key={scope.name}
        onClick={() => toggleScope(scope.name)}
        label={scope.label}
        selected={scopes.find(s => s.name === scope.name)}
      />
    );
  });

  const onCellClick = (rowData) => {
    if (rowData.purchase_details.length > 0) {
      stripeUiActions.setDrawerInvoiceId(rowData.purchase_details);
    }
  }

  return (
    <div>
      {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "24px"}}>
        <h2 className="header-2">Credit Ledger</h2>
        <div style={{display: "flex", flexDirection: "column", width: "290px", fontSize: "14px", color: "#6F6F6F"}}>
          {
            map(creditBalances, (balance, creditType) => {
              return (
                <div key={creditType} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <div>{displayNames[creditType] ? displayNames[creditType] : creditType}</div>
                  <div>{balance}</div>
                </div>
              );
            })
          }
        </div>
      </div> */}

      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
        onClickPurchaseDetails={onCellClick}
        scopeButtons={scopeButtons}
      />

      <DopeDrawer
        className="invoice-drawer"
        open={!!stripeUI.drawerInvoiceId}
        onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
        bodyContent={
          <InvoiceDrawer
            id={stripeUI.drawerInvoiceId}
            account={account}
            onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
          />
        }
      />
    </div>
  );
}

export default AccountCreditLedger;
