import React from "react";
import { useNavigate } from "react-router-dom";
import DopeNavBar from "./DopeNavBar";
import DopeButton from "./DopeButton";

const Dope404Page = () => {
  const navigate = useNavigate();

  return (
    <div>
      <DopeNavBar userType="user" />
      <div className={"user-Page show-nav"}>
        <div style={{ color: "#FFFFFF", background: "#494B4E", height: "387px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <div style={{fontSize: "16px", fontWeight: 600, margin: "120px 0 60px", borderBottom: "1px solid #FFFFFF"}}>ERROR 404</div>
          <h1 style={{ fontSize: "40px", fontWeight: 500 }}>That ain't right</h1>
          <div style={{ fontSize: "24px", fontWeight: 400 }}>We can't find that page but we can still help! What do you want to do?</div>
        </div>

        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "36px"}}>
          <DopeButton
            props={{
              label: "Go to my Dashboard",
              buttonClass: "outlined-black",
              onClick: () => navigate("/overview"),
              styles: { width: "200px", marginRight: "36px" }
            }}
          />

          <DopeButton
            props={{
              label: "Send Mail",
              buttonClass: "outlined-black",
              onClick: () => navigate("/campaigns", { state: { openDrawer: true } }),
              styles: { width: "200px" }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Dope404Page;
