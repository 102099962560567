import authApi from "./authApi";

const accountAbility = {
  can: (action, subject, data) => {
    const conditions = accountAbility.getConditions(action, subject);
    return accountAbility.checkConditions(conditions, data);
  },
  canUseFeature: (action, subject, featurePath) => {
    const conditions = accountAbility.getConditions(action, subject);
    return accountAbility.checkFeaturePermissions(conditions, featurePath);
  },

  getConditions: (action, subject) => {
    const currentAccountAbilities = authApi.getCurrentAccountAbilities();

    if (!currentAccountAbilities) return false;

    const actionPermissions = currentAccountAbilities[action];
    if (!actionPermissions) return false;

    const subjectPermissions = actionPermissions[subject];
    if (!subjectPermissions) return false;

    const { account_id, ...conditions } = subjectPermissions.conditions;
    if (!conditions) return false;

    return conditions;
  },

  checkConditions: (conditions, instance) => {
    if (!conditions) return false;
    if (Object.entries(conditions).length === 0) return true;

    return Object.entries(conditions).every(([field, value]) => {
      const instanceValue = instance[field];
      if (!instanceValue) return false;

      // Presence of an empty object means the model can be created with an empty object and ONLY an empty object for the specified field
      if (typeof instanceValue === 'object' && Object.entries(instanceValue).length === 0) return true;

      if (typeof value === 'object' && !Array.isArray(value)) {
        return accountAbility.checkConditions(value, instanceValue);
      } else {
        return Array.isArray(value) && value.includes(instanceValue);
      }
    });
  },

  checkFeaturePermissions: (conditions, featurePath) => {
    if (!conditions) return false;
    // No conditions defined for the feature, it is permissible
    if (Object.entries(conditions).length === 0) return true;

    let featureConditions = conditions;
    featurePath.forEach(feature => {
      featureConditions = featureConditions[feature];
    });

    // whitelist omitted permissions - if the feature is not defined with conditions on the subject, it is permissible
    if (!featureConditions) return true;
    // empty objects are not permissible for features
    if (typeof featureConditions === 'object' && Object.entries(featureConditions).length === 0) return false;
    if (Array.isArray(featureConditions)) {
      let hasPermission = true;
      featureConditions.forEach(condition => {
       if (typeof condition === 'object') hasPermission = false;
      });
      return hasPermission;
    }
    return true
  },
}

export default accountAbility;
