import React, { useEffect } from 'react';

import { useIntegrations } from "../integrationsSlice";

import SubscriptionEvent from "./SubscriptionEvent";
import SubscriptionEventConditions from "./SubscriptionEventConditions";
import SubscriptionFieldMap from "./SubscriptionFieldMap";
import SubscriptionQuery from "./SubscriptionQuery";

import '../integrations.scss';


const Subscription = ({ onChange, query = false, shared = false, subProps = {}, hide = {} }) => {
  const { subscription } = useIntegrations();

  useEffect(() => { // TODO how to prevent initial onChange call?
    onChange && onChange(subscription);
  }, [JSON.stringify(subscription)]);

  const fieldMap = !hide.fieldMap && <SubscriptionFieldMap query={query} {...(subProps.fieldMap || {})} />;
  const queryData = !hide.viewData && <SubscriptionQuery query={query} {...(subProps.viewData || {})} />;

  return (
    <>
      <div>
        <SubscriptionEvent query={query} {...(subProps.event || {})} />
        <SubscriptionEventConditions query={query} shared={shared} {...(subProps.conditions || {})} />
        {fieldMap}
        {queryData}
      </div>
    </>
  );
};

export default Subscription;
