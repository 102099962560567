import React from "react";
import { InputNumber, Toggle } from "rsuite";

import { permissionsDisplayValues, subscriptionTiers } from "../utils/permissions";

const PermissionsToggle = ({ permissions, permissionSubject, tier, updatePermission, editing }) => {
  return (
    <Toggle checked={permissions[permissionSubject][tier]} onChange={(value) => updatePermission(permissionSubject, tier, value)} disabled={!editing} style={{ width: "80px", display: "flex", justifyContent: "center" }} />
  )
}

const PermissionsInput = ({ permissions, permissionSubject, tier, updatePermission, editing }) => {
  return (
    <InputNumber value={permissions[permissionSubject][tier]} onChange={(value) => updatePermission(permissionSubject, tier, parseInt(value))} disabled={!editing} style={{ width: "80px" }} />
  )
}

const PermissionsRow = ({ permissions, permissionSubject, tier, displayType, updatePermission, editing }) => {
  return (
    <div className="flex row top-align margin-bottom" >
      {displayType === 'toggle' ?
        <PermissionsToggle permissions={permissions} permissionSubject={permissionSubject} tier={tier} editing={editing} updatePermission={updatePermission} /> :
        <PermissionsInput permissions={permissions} permissionSubject={permissionSubject} tier={tier} updatePermission={updatePermission} editing={editing} />
      }
    </div>
  )
}

const PermissionsHeader = ({ name, description }) => {
  return (
    <div className="flex column features-header">
      <div className="header-6 non-bold">{name}</div>
      <div className="label large color-text-secondary">{description}</div>
    </div>
  )
}

const FeaturesTableSection = ({ subject, permissionSubjects, permissions, updatePermission, editing }) => {
  return (
    <div>
      <div className="drawer-section-header label small color-text-primary margin-8-b text-upper">{subject}</div>
      <div className="pad-8 margin-8-b">
        {permissionSubjects.map((permissionSubject) => (
          <div className="flex row space-between margin-8-b" key={permissionsDisplayValues[permissionSubject].name}>
            <PermissionsHeader description={permissionsDisplayValues[permissionSubject].description} name={permissionsDisplayValues[permissionSubject].name} key={permissionSubject} />
            {subscriptionTiers.map((tier) => {
              return (
                <PermissionsRow
                  key={tier + '-' + permissionSubject}
                  updatePermission={updatePermission}
                  permissions={permissions}
                  permissionSubject={permissionSubject}
                  tier={tier}
                  displayType={permissionsDisplayValues[permissionSubject].type}
                  editing={editing}
                />
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

export default FeaturesTableSection
