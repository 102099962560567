import { useState } from 'react';

import * as svg from 'polotno/utils/svg';

import { createStore } from 'polotno/model/store';
import { useEffect } from 'react';

import { sizeToPixels } from '../utils/design';

export const store = createStore({ key: process.env.REACT_APP_POLOTNO_API_KEY });

const BLEED = 37.5;

export const customSVG = (width, height) => {
  const template = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 100">
    <rect width="1384" height="935"
      fill='#fff'
    />
  </svg>`;
  const url = svg.svgToURL(template);

  return {
    src: url,
    type: "svg",
    custom: { customSvg: true },
    name: "backMarkPlace",
    keepRatio: false, // can we change aspect ration of svg?
    x: width - 1384,
    y: height - 935,
    rotation: 0,
    locked: true, // deprecated
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    shadowEnabled: false,
    shadowBlur: 10,
    width: 1384,
    height: 935,
    flipX: false,
    flipY: false,
    cornerRadius: 0,
    // can user select element?
    // if false, element will be "invisible" for user clicks
    selectable: false,
    // use for absolute positing of element
    alwaysOnTop: true,
    // // also we can hide some elements from the export
    showInExport: true,
    // // map of originalColor -> newColor, used to replace colors in svg image
    // // do not change it manually. Instead use `el.replaceColor(originalColor, newColor)

    // can element be moved and resized
    draggable: false,
    // // can we change content of element?
    // // contentEditable: false,
    contentEditable: false,
    // // can we change style of element?
    // // styleEditable: false,
    styleEditable: false,
  };
};

export const usePolotnoStore = () => {

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [hasBackSide, setHasBackSide] = useState(false);

  const isActiveBleed = true;

  const setFrontPage = (width, height) => {
    const page = store.addPage({
      custom: {
        side: 'front',
        // templateName: name || '',
        // contactEmail: contactEmail || '',
      },
    });

    if (isActiveBleed) {
      store.activePage.set({ bleed: BLEED });
      store.toggleBleed(true);
    }

    store.selectPage(store.pages[0].id);
  };

  const setBackPage = (width, height) => {
    const page = store.addPage({ custom: { side: 'back' } });

    if (isActiveBleed) {
      store.activePage.set({ bleed: BLEED });
    }

    page.addElement(customSVG(width, height));

    store.selectPage(store.pages[0].id);
  };

  useEffect(() => {
    if (width && height) {

    }
  }, [width, height]);


  const initialize = (size) => {
    const { width, height } = sizeToPixels(size);

    setWidth(width);
    setHeight(height);

    setFrontPage(width, height);
    setBackPage(width, height);

    store.setSize(width, height);
  };


  return {
    store,
    initialize
  };
};
