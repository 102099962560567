function joinWithAnd(items) {
  if (!items) return '';

  if (items.length === 0) return '';

  if (items.length === 1) return items[0];

  if (items.length === 2) return items.join(' and ');

  const itemsCopy = items.slice();
  const lastItem = itemsCopy.pop();

  return `${itemsCopy.join(', ')} and ${lastItem}`;
}

export {
  joinWithAnd,
};
