import React from "react";
import AccountUserForm from "./AccountUserForm";
import AccountInformationForm from "./AccountInformationForm";
import AccountEmailNotificationForm from "./AccountEmailNotificationForm";
import DopeTabs from "../ui/DopeTabs";

const AccountSettingsTab = () => {

  return (
    <div style={{ marginTop: "30px"}}>
      <DopeTabs>
        <DopeTabs.Tab
          key='account_details'
          name='account_details'
          label='Account Details'
        >
          <AccountInformationForm />
        </DopeTabs.Tab>

        <DopeTabs.Tab
          key='account_settings'
          name='account_settings'
          label='Account Settings'
        >
          <AccountEmailNotificationForm />
        </DopeTabs.Tab>

        <DopeTabs.Tab
          key='my_profile'
          name='my_profile'
          label='My Profile'
        >
          <AccountUserForm />
        </DopeTabs.Tab>
      </DopeTabs>
    </div>
  );
}

export default AccountSettingsTab;
