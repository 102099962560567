import React, { useEffect } from "react";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";

import { useList } from "./listSlice";
import { useListUI } from "./listUISlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeApi from "../services/DopeApi";
import { DopeFormGroup } from "../ui/DopeForm";

const contactListApi = new DopeApi('contact_lists');

const ListDropdown = ({ rowData }) => {
  const [list, listActions] = useList();
  const [listUI, listUIActions] = useListUI();
  const [dopeUI, dopeUIActions] = useDopeUI();

  useEffect(() => {
    if (!list || list.id !== rowData.id)
    listActions.get(rowData.id);
  }, []);

  const handleEditList = () => {
    listUIActions.setDrawerListId(list.id);
  }

  const handleDownloadList = async () => {
    dopeUIActions.addFlashMessage({ header: "Downloading list...", type: "info" });
    try {
      await contactListApi.exportCSV( { filters: [{ field: 'list_id', operator: '=', value: rowData.id }] }, list.name);
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error downloading list", body: error.message, type: "error" });
    }
  }

  const dropdownItems = [
    { icon: <FaRegEdit className="dropdown-item-icon margin-8-r" />, text: "Edit List", onClick: handleEditList },
    { icon: <FaRegTrashAlt className="dropdown-item-icon margin-8-r" />, text: "Delete List", onClick: () => listUIActions.setModalListId(rowData.id) },
    { icon: <MdOutlineFileDownload className="dropdown-item-icon margin-8-r" />, text: "Download List", onClick: handleDownloadList },
  ];

  return (
    <>
      {list && <div>
        {dropdownItems.map((item, index) => (
          <div onClick={item.onClick} className="label large flex row vertical-align bold clickable margin-16-b" key={index}>
            {item.icon}
            <div>{item.text}</div>
          </div>
        ))}

        <hr style={{height: "1px", color: "#EAECEF", margin: "16px 20px"}}/>

        <div className="flex column">
          <div className="label bold margin-8-b">TAGS</div>
          <DopeFormGroup
            input={{
              inputType: "tags",
              name: "taggings",
              props: {modelName: "list", hideEmptyState: true}
            }}
            values={list}
            onChange={listActions.addTagToList}
          />
        </div>
      </div>}
    </>
  )
}

export default ListDropdown;
