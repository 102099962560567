import React, { useState } from "react";
import { Modal } from "rsuite";
import { useAccount } from "./accountSlice";
import DopeImageSelect from "../ui/DopeImageSelect";
import DopeButton from "../ui/DopeButton";
import ImageIcon from "@rsuite/icons/Image";

const accountLogoStyles = {
  height: "100px",
  width: "100px",
  borderRadius: "50%",
  boxShadow: "0px 6px 6px 0px #BABABA40",
  border: "1px solid #F3F3F3"
}

const AccountLogoModal = ({show, setShow}) => {
  const [account, actions] = useAccount();
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShow(false);
  }

  const removeImage = () => {
    actions.update({logo: null})
  }

  const saveImage = async () => {
    setLoading(true);
    try {
      await actions.save();
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }


  return (
    <Modal open={show} onClose={closeModal} size="xs">
      <Modal.Header>
      </Modal.Header>
      <Modal.Body>
        <h3 className="header-3 margin-bottom">Update Company Logo</h3>
        <div className="bordered-section">
          <div className="vertical-align column">
            <DopeImageSelect
              name="logo"
              value={account?.logo}
              values={{logo: account?.logo, logo_url: account?.logo_url}}
              onChange={actions.update}
              onRemove={removeImage}
              avatar={true}
            />
          </div>
        </div>

        <div className="help-text small margin-16-b">
          Drag and drop an image or click to select a file
        </div>

        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <DopeButton
            loading={loading}
            props={{
              buttonClass: "filled-black full-width margin-top",
              label: "Save Logo",
              onClick: saveImage,
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AccountLogoModal;
