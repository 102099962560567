import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  flashMessages: [],
};

export const dopeUISlice = createSlice({
  name: "dopeUI",
  initialState,
  reducers: {
    addFlashMessage: (dopeUI, { payload: message }) => {
      const messageId = Date.now();
      dopeUI.flashMessages.push({ ...message, id: messageId });
    },
    removeFlashMessage(dopeUI, { payload: messageId }) {
      dopeUI.flashMessages = dopeUI.flashMessages.filter(
        (message) => message.id !== messageId
      );
    }
  }
});

export default dopeUISlice.reducer;

export const {
  addFlashMessage,
  removeFlashMessage,
} = dopeUISlice.actions;

export const selectDopeUI = (state) => state.dopeUI;

export function useDopeUI() {
  const dispatch = useDispatch();
  const dopeUI = useSelector(selectDopeUI);
  const actions = {
    addFlashMessage: (message) => dispatch(addFlashMessage(message)),
    removeFlashMessage: (messageId) => dispatch(removeFlashMessage(messageId)),
  };

  return [dopeUI, actions];
}
