import React from "react";

import { useNavigate } from "react-router-dom";

const ActionResultLink = ({ rowData }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    let url;
    if (rowData.action_template_type === 'CampaignTemplate') { // MORE IN FUTURE
      url = `/campaigns/${rowData.action_template_result_id}/show`;
    }
    navigate(url);
  };

  let actionTemplateLabel;
  if (rowData.action_template_type === 'CampaignTemplate') {
    actionTemplateLabel = 'Campaign';
  }

  return (
    <span className="link-label" onClick={handleClick}>Go To {actionTemplateLabel} →</span>
  );
};

export default ActionResultLink;
