import React from 'react';

import { DopeFormGroup } from "../../ui/DopeForm";

const nameInput = { inputType: "text", name: "name", props: { label: "Name" } };
const isQueryInput = { inputType: "checkbox", name: "is_query", props: { label: "Is Query Template" } };

const SubscriptionTemplateSetup = ({ subscriptionTemplate, onChange }) => {
  return (
    <div className="rs-form max-width-500">
      <DopeFormGroup input={nameInput} onChange={onChange} values={subscriptionTemplate} />
      <DopeFormGroup input={isQueryInput} onChange={onChange} values={subscriptionTemplate} />
      {/* query or trigger */}
      {/* TODO replace or append, ie is quick add */}
    </div>
  );
}

export default SubscriptionTemplateSetup;
