import axios from "axios";
import dopeClient from "../services/dopeClient";

const apiBase = process.env.REACT_APP_API_BASE_URL;

const handleUserResponse = (resp) => {
  const currentUser = resp.data.user;
  const currentAccount = resp.data.current_account;
  const defaultAccount = resp.data.default_account;

  localStorage.setItem('currentUser', JSON.stringify(currentUser));
  localStorage.setItem('currentAccount', JSON.stringify(currentAccount));
  localStorage.setItem('defaultAccount', JSON.stringify(defaultAccount));
};

const authApi = {
  login: async (credentials, reroute) => {
    const resp = await axios.post(`${apiBase}/login`, {
      user: {
        email: credentials.email,
        password: credentials.password,
      }
    });

    handleUserResponse(resp);

    const authHeader = resp.headers.authorization;
    localStorage.setItem('authHeaderValue', authHeader);

    if (reroute === 'none') {
      return;
    } else if (authApi.doesNotHaveSubscription()) {
      window.location.href = "/subscription_signup";
    } else if (reroute) {
      window.location.href = reroute;
    } else {
      window.location.href = "/";
    }
  },

  doesNotHaveAccount: () => {
    const defaultAccount = authApi.getDefaultAccount();

    return !defaultAccount || Object.keys(defaultAccount).length === 0;
  },

  signup: async (user) => {
    return await axios.post(`${apiBase}/signup`, { user });
  },

  logout: async () => {
    try {
      await dopeClient.delete(`${apiBase}/logout`);
    } catch (err) {
      // do nothing
    }
    authApi.clearSession();
  },

  setAccount: async (accountId, reroute) => {
    try {
      const resp = await dopeClient.post(`${apiBase}/users/set_account`, { account_id: accountId });
      handleUserResponse(resp);
      if (reroute) {
        window.location.href = reroute;
      } else if (/\d+/.test(window.location.pathname)) {
        window.location.href = "/";
      } else {
        window.location.reload();
      }
    } catch (err) {
      return err
    }
  },

  fetchMe: async () => {
    try {
      const resp = await dopeClient.get(`${apiBase}/users/me`);
      handleUserResponse(resp);
    } catch (err) {
      // do nothing TODO: handle error
    }
  },

  updateAccount: async (updates) => {
    const accountResp = await dopeClient.put(`${apiBase}/accounts/${authApi.getCurrentAccountId()}`, updates);
    const userResp = await dopeClient.get(`${apiBase}/users/me`);
    handleUserResponse(userResp);
    return accountResp.data.account;
  },

  sendResetPasswordEmail: async (email) => {
    return await axios.post(`${apiBase}/users/email_password_reset`, { email });
  },

  resetPassword: async (password, passwordConfirmation, token) => {
    return await axios.put(`${apiBase}/users/reset_password`, { user: { password: password, password_confirmation: passwordConfirmation, reset_password_token: token }});
  },

  updatePassword: async (userId, password, passwordConfirmation, currentPassword) => {
    return await dopeClient.put(`${apiBase}/users/update_password`, {
      id: userId,
      user: { password: password, password_confirmation: passwordConfirmation, current_password: currentPassword }
    });
  },

  clearSession: () => {
    localStorage.removeItem('authHeaderValue');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentAccount');
    window.location.href = '/login';
  },

  getCurrentUser: () => {
    return JSON.parse(localStorage.getItem('currentUser'));
  },

  getCurrentUserId: () => {
    const currentUser = authApi.getCurrentUser();
    return currentUser ? currentUser.id : null;
  },

  getCurrentUserRole: () => {
    const currentUser = authApi.getCurrentUser();
    return currentUser ? currentUser.role : null;
  },

  currentUserIsSuperAdmin: () => {
    return authApi.getCurrentUserRole() === 'super_admin';
  },

  currentUserIsAdmin: () => {
    return authApi.getCurrentUserRole() === 'admin';
  },

  currentUserHasAdminPrivileges: () => {
    const isAdmin = authApi.currentUserIsAdmin();
    const isSuperAdmin = authApi.currentUserIsSuperAdmin();

    return isAdmin || isSuperAdmin;
  },

  getAuthHeaderValue: () => {
    return localStorage.getItem('authHeaderValue');
  },

  getCurrentAccount: () => {
    return JSON.parse(localStorage.getItem('currentAccount'));
  },

  getDefaultAccount: () => {
    return JSON.parse(localStorage.getItem('defaultAccount'));
  },

  getCurrentAccountId: () => {
    const currentAccount = authApi.getCurrentAccount();
    return currentAccount ? currentAccount.id : null;
  },

  getCurrentAccountRole: () => {
    const currentAccount = authApi.getCurrentAccount();
    return currentAccount ? currentAccount.role : null;
  },

  getCurrentAccountType: () => {
    const currentAccount = authApi.getCurrentAccount();
    return currentAccount ? currentAccount.account_type : null;
  },

  getCurrentAccountSubscriptionKey: () => {
    const currentAccount = authApi.getCurrentAccount();
    return currentAccount ? currentAccount.subscription_key : null;
  },

  getCurrentAccountAbilities: () => {
    const currentAccount = authApi.getCurrentAccount();
    return currentAccount ? currentAccount.account_ability : null;
  },

  doesNotHaveSubscription: () => {
    const subscriptionKey = authApi.getCurrentAccountSubscriptionKey();
    return !subscriptionKey || subscriptionKey === '';
  },

  currentAccountSubscriptionIsActive: () => {
    const account = authApi.getCurrentAccount();
    return account.stripe_subscription_is_active || authApi.currentUserHasAdminPrivileges();
  },

  currentAccountSubscriptionIsPaid: () => {
    const account = authApi.getCurrentAccount();
    return account.stripe_subscription_is_current || authApi.currentUserHasAdminPrivileges();
  },

  currentAccountIsRegistered: () => {
    const account = authApi.getCurrentAccount();
    return account.account_registered || authApi.currentUserHasAdminPrivileges();
  },

  currentAccountIsAdmin: () => {
    return authApi.getCurrentAccountRole() === 'admin';
  },

  isAuthenticated: () => {
    return !!localStorage.getItem('authHeaderValue');
  },
};

export default authApi;
