import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, Outlet, useNavigate } from 'react-router-dom';
import { useDesign } from './designSlice';
import useDopePageTabs from '../ui/useDopePageTabs';
import DopePageLoader from '../ui/DopePageLoader';
import DopeButton from "../ui/DopeButton";

import { objectURLToFile  } from "../utils/design";

import './Design.scss';
import './HandwryttenCustom.scss';


import DesignEditorPage from '../editor/DesignEditorPage';
import HandwrittenCardApprovalDrawer from './HandwrittenCardApprovalDrawer';
import Cannot from "../ui/Cannot";
import accountAbility from "../auth/accountAbility";

const designerStyle = {
  margin: 0,
  padding: '2rem',
  minHeight: '100dvh',
};

const postcardDesignPages = [{
  name: 'Upload Postcard',
  path: '/designs/postcards/:id',
  isValid: (design) => {
    return true;
  }
}];

const hwcFrontDesignPages = [{
  name: 'Upload Handwritten Front',
  path: '/designs/handwritten-fronts/:id',
  isValid: (design) => {
    return true;
  }
}];

const revisionDesignPages = [{
  name: 'Design Revision',
  path: '/designs/:id/revision',
  isValid: (design) => {
    return true;
  }
}];

const pulseRevisionDesignPages = [{
  name: 'Design Revision',
  path: '/pulse/design_revisions/:id/revision',
  isValid: (design) => {
    return true;
  }
}];

const designerRevisionDesignPages = [{
  name: 'Design Revision',
  path: '/designer/design',
  isValid: (design) => {
    return true;
  }
}];


const eddmDesignPages = [{
  name: 'Upload Postcard',
  path: '/designs/eddms/:id',
  isValid: (design) => {
    return true;
  }
}];

const handwrittenCardesignPages = [
  {
    name: 'Card Setup',
    path: '/designs/handwritten-cards/:id/setup',
    isValid: (design) => {
      return typeof design.name === 'string' && design.name.trim() !== '';
    }
  },
  {
    name: 'Card Design',
    path: '/designs/handwritten-cards/:id/design',
    isValid: (design) => {
      if (typeof design.mail_template.body !== 'string' || design.mail_template.body.trim() === '') {
        return false;
      }

      if ((!design.mail_template.cover_image && !design.mail_template.cover_image_url) && !design.mail_template.card) {
        return false;
      }

      if (!design.mail_template.font) {
        return false;
      }

      return true;
    }
  },
  {
    name: 'Return Address & Extras',
    path: '/designs/handwritten-cards/:id/address',
    isValid: (design) => {
      if (typeof design.mail_template.sender_name !== 'string' || design.mail_template.sender_name.trim() === '') {
        return false;
      }

      if (typeof design.mail_template.address_1 !== 'string' || design.mail_template.address_1.trim() === '') {
        return false;
      }

      if (typeof design.mail_template.city !== 'string' || design.mail_template.city.trim() === '') {
        return false;
      }

      if (typeof design.mail_template.state !== 'string' || design.mail_template.state.trim() === '') {
        return false;
      }

      if (typeof design.mail_template.zip !== 'string' || design.mail_template.zip.trim() === '') {
        return false;
      }

      return true;
    }
  },
  {
    name: 'Review & Approve',
    path: '/designs/handwritten-cards/:id/review',
    isValid: (design) => {
      return false;
    }
  }
];

const goodieDesignPages = [{
  name: 'Upload Goodie',
  path: '/designs/goodies/:id',
  isValid: (design) => {
    return true;
  }
}];

const DesignPages = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { design, actions, isSaving } = useDesign(id);

  const [showApproval, setShowApproval] = useState(false);

  let pages = [];

  const isPostcard = location.pathname.includes('postcards') || location.pathname.includes('eddms');
  const isHwcFront = location.pathname.includes('handwritten-fronts');
  const isGoodie = location.pathname.includes('goodies');
  const isRevision = location.pathname.includes('revision');
  const isPulse = location.pathname.includes('pulse');
  const isDesigner = location.pathname.includes('designer');
  const isHandwrittenCard = location.pathname.includes('handwritten-cards');

  let exitUrl = '/designs';
  let exitText = 'Back to all Designs';

  if (location.pathname.includes('postcards')) {
    pages = postcardDesignPages;
  } else if (location.pathname.includes('eddms')) {
    pages = eddmDesignPages;
  } else if (isHandwrittenCard) {
    pages = handwrittenCardesignPages;
  } else if (location.pathname.includes('goodies')) {
    pages = goodieDesignPages;
  } else if (isHwcFront) {
    pages = hwcFrontDesignPages;
  } else if (isRevision) {
    if (isPulse) {
      pages = pulseRevisionDesignPages;
      exitUrl = '/pulse/design_revisions';
      exitText = 'Back to all Design Revisions';
    } else {
      pages = revisionDesignPages;
    }
  } else if (isDesigner) {
    pages = designerRevisionDesignPages;
    exitUrl = '/';
    exitText = 'Exit';
  };

  const {
    PageTabs,
    BackLink,
    navigateForward,
    exit,
    isLastPage,
    isValid,
  } = useDopePageTabs({ pages, resource: design, exitUrl, exitText });

  const prevDesignIdRef = useRef();
  const prevStatusRef = useRef();
  const prevTemplateIdRef = useRef();

  useEffect(() => {
    const prevDesignId = prevDesignIdRef.current;
    const prevStatus = prevStatusRef.current;
    const prevTemplateId = prevTemplateIdRef.current;

    if (isDesigner) {
      return;
    }

    if (design.id !== 'new' && prevDesignId === 'new') {
      navigate(`/designs/${design.id}`, { replace: true });
    } else if (design.id !== 'new' && prevStatus !== 'approved' && design.status === 'approved' && !isPulse) {
      navigate(`/designs/${design.id}`, { replace: true });
    } else if (design.id !== 'new' && prevTemplateId && prevTemplateId !== design.mail_template?.id) {
      if (isPulse) {
        navigate('/pulse/design_revisions');
      } else {
        navigate(`/designs/${design.id}`, { replace: true });
      }
    }

    prevDesignIdRef.current = design.id;
    prevStatusRef.current = design.status;
    prevTemplateIdRef.current = design.mail_template?.id;
  }, [design.id, design.mail_template?.id, design.status]);


  const handleSave = async (status = 'draft') => {

    if (design.mail_template_type === 'HandwrittenCardTemplate') {
      const files = {};

      const now = Date.now();

      const { cover_image, cover_image_type } = design.mail_template;

      if (cover_image) {
        files.cover_image = await objectURLToFile(cover_image, `cover_${now}.${cover_image_type.split('/')[1]}`);
      }

      const { header_image, header_image_type } = design.mail_template;

      if (header_image) {
        files.header_image = await objectURLToFile(header_image, `header_${now}.${header_image_type.split('/')[1]}`);
      }

      const { footer_image, footer_image_type } = design.mail_template;

      if (footer_image) {
        files.footer_image = await objectURLToFile(footer_image, `footer_${now}.${footer_image_type.split('/')[1]}`);
      }

      await actions.save({ files, status });
    } else {
      if (status === 'approved') {
        await actions.approve();
      }
    }

  };

  const continueButton = (
    <DopeButton
      disabled={!isValid}
      props={{
        onClick: navigateForward,
        label: 'Continue',
        buttonClass: 'filled',
      }}
    />
  );

  const canCreateDesign = accountAbility.can("create", "Design", design)
  const cannotDisplay = (
    <div>
      <div>The subscription level you’re on does not include Handwritten Cards.</div>
      <div>Interested in adding Handwritten Cards to your account? Reach out to your Account Manager or our support team for assistance!</div>
    </div>
  )

  const activateButton = (
    <Cannot action="create" subject="Design" data={design} cannotDisplay={{ tooltip: cannotDisplay }} >
      <DopeButton
        loading={isSaving}
        disabled={(!isValid && !isLastPage) || !canCreateDesign}
        props={{
          onClick: () => {
            if (design.mail_template_type === 'HandwrittenCardTemplate') {
              setShowApproval(true);
            } else {
              handleSave('approved');
            }
          },
          label: 'Approve design',
          buttonClass: 'filled',
        }}
      />
    </Cannot>
  );

  const saveButton = (
    <DopeButton
      loading={isSaving}
      disabled={!canCreateDesign}
      props={{
        onClick: () => handleSave('draft'),
        label: 'Save as draft',
        buttonClass: 'text-button',
      }}
    />
  );

  return (
    <div className='campaign-page-wrapper'>
      {!isDesigner && <BackLink className='margin' /> }

      <div className='campaign-page'>

        <div className={isPostcard || isDesigner || isHwcFront ? '' : 'page'} style={isDesigner ? designerStyle : {}}>
          { !isPostcard && !isGoodie && !isRevision && !isDesigner && !isHwcFront && <PageTabs /> }
          <Outlet />
        </div>
        {!isPostcard && !isGoodie && !isRevision && !isDesigner && !isHwcFront && (
          <div className="footer-actions" style={{justifyContent: 'flex-end'}}>
            <Cannot action="create" modelName="Design" data={design} cannotDisplay={{ tooltip: "Your subscription does not include Handwritten Cards" }} >
              {saveButton}
            </Cannot>
            {isLastPage ? activateButton : continueButton}
          </div>
        )}

        {isHandwrittenCard && (
          <HandwrittenCardApprovalDrawer
            open={showApproval}
            onConfirm={() => handleSave('approved')}
            onCancel={() => setShowApproval(false)}
            design={design}
            actions={actions}
            loading={isSaving}
          />
        )}
      </div>
    </div>
  );
}

const Design = () => {
  const { id } = useParams();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const shareToken = query.get('share');

  const { design, actions } = useDesign();

  useEffect(() => {
    if (!design) {
      if (id) {
        actions.get(id + location.search);
      } else if (shareToken) {
        actions.get('shared' + location.search);
      }
    }

    return actions.resetToInitial;
  }, []);

  if (!design) {
    return <DopePageLoader />;
  }

  return (location.pathname.includes('/editor') ? <DesignEditorPage /> : <DesignPages />);
};

export default Design;
