import React from 'react';
import { Input } from 'rsuite';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

const isValueMissing = (value) => {
  return isUndefined(value) || isNull(value);
};

const DopeTextInput = ({ name, value, onChange, disabled, ...rest }) => {
  const missingValue = isValueMissing(value);
  return (
    <>
      <Input
        disabled={disabled}
        name={name}
        value={missingValue ? '' : value}
        onChange={(value) => onChange({ [name]: value }, value, name)}
        {...rest}
      />
      {rest.error && <div style={{ color: 'red' }}>{rest.error}</div>}
    </>
  );
};

export default DopeTextInput;
