import React, {useState} from "react";
import {Input, Modal} from "rsuite";
import DopeButton from "../ui/DopeButton";
import authApi from "../auth/authApi";
import {useDopeUI} from "../ui/dopeUISlice";
import {useUser} from "../users/userSlice";

const ChangePasswordModal = ({open, setModalOpen}) => {
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dopeUI, dopeUIActions] = useDopeUI()
  const [user, userActions] = useUser()

  const handleNewPasswordSubmit = async () => {
    setLoading(true);
    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      setLoading(false);
      return;
    } else {
      setPasswordsMatch(true);
    }

    try {
      const updatedUser = await authApi.updatePassword(user.id, newPassword, confirmPassword, currentPassword);
      if (updatedUser.data.message === 'success') {
        dopeUIActions.addFlashMessage({body: 'Your password was successfully updated!', type: 'success' });
        setNewPassword('');
        setConfirmPassword('');
        setCurrentPassword('');
      } else {
        dopeUIActions.addFlashMessage({ body: `${updatedUser.data.errors.join(", ")}`, type: 'error' });
      }
      setModalOpen(false);
    } catch (error) {
      dopeUIActions.addFlashMessage({ body: 'could not update password', type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const validPasswords = newPassword === confirmPassword && newPassword.length > 6;

  return (
    <Modal open={open} onClose={() => setModalOpen(false)} size='xs'>
      <Modal.Header>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label className="label">Current Password</label>
        <Input
          name="current_password"
          value={currentPassword}
          onChange={(value) => setCurrentPassword(value)}
          type="password"
        />
        <label className="label">New Password</label>
        <Input
          name="new_password"
          type="password"
          value={newPassword}
          onChange={(value) => setNewPassword(value)}
        />
        <label className="label">Confirm Password</label>

        <Input
          name="confirm_password"
          type="password"
          value={confirmPassword}
          onChange={(value) => setConfirmPassword(value)}
        />
        <span style={{
          color: 'red',
          display: 'block',
          marginBottom: '10px'
        }}>{!passwordsMatch ? 'Passwords do not match' : ''}</span>

        <div className='label optional'>Password must be at least 6 characters</div>

        <DopeButton
          props={{
            buttonClass: 'filled-black',
            label: 'Change Password',
            onClick: handleNewPasswordSubmit,
            styles: {width: '100%', marginTop: '20px'}
          }}
          disabled={!validPasswords}
          loading={loading}
        />
      </Modal.Body>
    </Modal>
  );
}

export default ChangePasswordModal;
