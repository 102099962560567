import React, {useEffect} from "react";
import {useParams} from "react-router-dom";

import {useAccount} from "../accounts/accountSlice";
import AccountInformationForm from "../accounts/AccountInformationForm";
import AccountTeamMemberForm from "./AccountTeamMemberForm";

const PulseAccountDetails = () => {
  const [account, actions] = useAccount();
  const { id } = useParams();

  useEffect(() => {
    if (!account || account.id !== id) {
      actions.get(id);
    }

    return actions.resetToInitial;
  }, []);

  return (
    <div className="margin-bottom">
      <AccountInformationForm />
      <AccountTeamMemberForm />
    </div>
  );
}

export default PulseAccountDetails;
