import React from "react";

import { useConversionReport } from "./conversionReportSlice";

import { DopeFormGroup } from "../ui/DopeForm";
import DopePlayer from "../ui/DopePlayer";

const videoUrl = "https://dopemail-assets.s3.amazonaws.com/Understanding+Matchback+Analysis+%F0%9F%93%8A.mp4"

const nameInput = { inputType: "text", name: "name", props: { label: "Matchback report name", placeholder: "ex. Matchback Sep-Dec" } };
const conversionTypeInput = { inputType: "radio", name: "conversion_type", props: {
  label: "Matchback Type",
  options: [
    { value: "lead", label: "Leads" },
    { value: "client", label: "Customers" },
  ],
}};

const ConversionReportSetup = () => {
  const { conversionReport, actions } = useConversionReport();

  const reportInputProps = {
    values: conversionReport,
    onChange: actions.update,
    errors: conversionReport.errors,
  };

  return (
    <div>
      <div className="page-header">
        <p className="header-text">Matchback Setup</p>
      </div>

      <div className="rs-form split-cols">
        <div>
          <DopeFormGroup input={nameInput} {...reportInputProps} />
          <DopeFormGroup input={conversionTypeInput} {...reportInputProps} />
        </div>
        <div><DopePlayer url={videoUrl} /></div>
      </div>
    </div>
  );
};

export default ConversionReportSetup;
