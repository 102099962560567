import React from "react";

import { Pagination  } from "rsuite";

import DopeTable from "./DopeTable";
import DopeErrorMessages from "./DopeErrorMessages";


const paginationWrapperStyles = { paddingTop: 20 };
const paginationLocale = {
  total: 'Total: {0}'
};

const DopeListPage = ({ selectedActions, error, columns, tableProps, paginationProps, onSearchChange, onSearchClear, filterInputs, filters, setFilters, scopeButtons, tableHeader, rowHeight, filterActionButtons, ...rest }) => {

  return (
    <>
      <DopeErrorMessages errorMessages={error} />

      <DopeTable
        tableHeader={tableHeader}
        columns={columns}
        onSearchChange={onSearchChange}
        onSearchClear={onSearchClear}
        filterInputs={filterInputs}
        setFilters={setFilters}
        filters={filters}
        selectedActions={selectedActions}
        scopeButtons={scopeButtons}
        rowHeight={rowHeight}
        filterActionButtons={filterActionButtons}
        {...rest}
        {...tableProps}
      />

      <div style={paginationWrapperStyles}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          limitOptions={[10, 25, 50, 100]}
          locale={paginationLocale}
          {...paginationProps}
        />
      </div>
    </>
  )
}

export default DopeListPage;
