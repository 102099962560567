import React from 'react';
import DopeManagement from "../ui/DopeManagement";
import ListForm from "./ListForm";

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, filterable: true },
  { type: 'text', dataKey: 'account_name', label: 'Account' },
  { type: 'text', dataKey: 'name', label: 'Name' },
  { type: 'text', dataKey: 'data_source', label: 'Data Source' },

  { type: 'text', dataKey: 'account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
];


const scopeOptions = [];

function ListsManagement() {
  return (
    <DopeManagement
      modelName="list"
      modelForm={ListForm}
      columns={columns}
      scopeOptions={scopeOptions}
    />
  );
}

export default ListsManagement;
