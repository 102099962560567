import React from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const initial = {
  campaign_id: null,
  list_id: null,
};

const inputs = [
  {
    inputType: 'belongsTo',
    name: 'campaign_id',
    props: {
      label: 'Campaign',
      belongsToModelName: 'campaign',
    }
  },
  {
    inputType: 'belongsTo',
    name: 'list_id',
    props: {
      label: 'List',
      belongsToModelName: 'list',
    }
  },
];

const CampaignListForm = (props) => {
  return (
    <ModelForm
      modelName="campaign_list"
      initialResource={initial}
      inputs={inputs}
      {...props}
    />
  );
};

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID' },
  { type: 'text', dataKey: 'campaign_name', label: 'Campaign' },
  { type: 'text', dataKey: 'list_name', label: 'List' },
];

function CampaignListManagement(props) {
  return (
    <DopeManagement
      modelName="campaign_list"
      modelForm={CampaignListForm}
      columns={columns}
      scopeOptions={[]}
      {...props}
    />
  );
}

export default CampaignListManagement;
