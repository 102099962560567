import React, {useEffect, useState} from 'react';
import {Button} from "rsuite";

import { useNavigate } from "react-router-dom";
import {useObjectState} from "../hooks/useObjectState";
import DopeLogo from "../icons/DopeLogo";
import {Col, Form, Row, Checkbox} from "rsuite";
import DopeTextInput from "../ui/DopeTextInput";
import DopeApi from "../services/DopeApi";
import DopeButton from "../ui/DopeButton";
import DopePhoneInput from "../ui/DopePhoneInput";
import authApi from "./authApi";

const initialCredentials = {
  name: '',
  account_email: '',
  billing_email: '',
  phone_number: '',
};

const loginFormStyles = {
  width: "468px",
  height: "auto",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 6px 6px 0px #BABABA40",
}


const accountApi = new DopeApi('account')

const AccountSignupForm = () => {
  const [credentials, updateCredentials, setCredentials, clearCredentials] = useObjectState(initialCredentials);
  const [errors, setErrors] = useState(null);
  const [billingSameAsAccount, setBillingSameAsAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseErrors, setBaseErrors] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (errors && errors.base) {
      let newErrors = []
      errors.base.forEach(error => {
        if (typeof error === 'string') {
          newErrors.push(error)
        }
      })
      setBaseErrors(newErrors)
    }
  }, [errors])

  const setNewAccount = async (account) => {
    localStorage.setItem('currentAccount', JSON.stringify({id: account.id, name: account.name}));
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const newAccount = await accountApi.create(credentials)
      await setNewAccount(newAccount)
      navigate('/subscription_signup')
    } catch (error) {
      setErrors(error)
    } finally {
      setLoading(false)
    }
  };

  const handleBillingSameAsAccount = () => {
    if (!billingSameAsAccount) {
      updateCredentials({billing_email: credentials.account_email})
    }
    setBillingSameAsAccount(!billingSameAsAccount);
  }

  const handleLogout = async () => {
    await authApi.logout();
  }

  return (
    <div className="vertical-align column">
      <DopeLogo style={{ width: "169px", height: "73px", margin: "20px 0" }}/>
      <div style={loginFormStyles}>
        <h1 className="header-1 margin-bottom">Create Company Account</h1>

        <Form onSubmit={handleSubmit}>
          <Row gutter={10} className="margin-bottom">
            <Col xs={24}>
              <label className="label large label-blue-grey bold">Company Name</label>
              <DopeTextInput
                name="name"
                value={credentials.name}
                onChange={updateCredentials}
                error={errors?.name}
              />
            </Col>
          </Row>
          <Row gutter={10} className="margin-bottom">
            <Col xs={24}>
              <label className="label large label-blue-grey bold">Account Email</label>
              <DopeTextInput
                name="account_email"
                value={credentials.account_email}
                onChange={updateCredentials}
                error={errors?.account_email}
              />
            </Col>
          </Row>
          <Row gutter={10} className="margin-bottom">
            <Col xs={24}>
              <label className="label large label-blue-grey bold">
                <div className="vertical-align row">
                  <div>Billing Email</div>
                  <div><Checkbox onChange={handleBillingSameAsAccount}>Same as Account</Checkbox></div>
                </div>
              </label>
              <DopeTextInput
                name="billing_email"
                value={billingSameAsAccount ? credentials.account_email : credentials.billing_email}
                onChange={updateCredentials}
                error={errors?.billing_email}
              />
            </Col>
          </Row>
          <Row gutter={10} className="margin-bottom">
            <Col xs={24}>
              <label className="label large label-blue-grey bold">Phone Number</label>
              <DopePhoneInput
                name="phone_number"
                value={credentials.phone_number}
                onChange={updateCredentials}
                error={errors?.phone_number}
              />
            </Col>
          </Row>

          {baseErrors && <div style={{color: "red", marginBottom: "20px"}}>{baseErrors.join(" ")}</div>}

          <DopeButton
            props={{
              buttonClass: "filled full-width",
              type: "submit"
            }}
            loading={loading}>
            Next
          </DopeButton>

        <div className="label optional margin-top">
          You landed on this page because there is no company account associated with your login credentials.
          If you think this happened in error please reach out to your account manager.
        </div>

          <DopeButton
            props={{
              buttonClass: "text-link margin-top float-right",
              onClick: handleLogout,
            }}>
            Back to Login
          </DopeButton>

        </Form>
      </div>
    </div>
  );
};

export default AccountSignupForm;
