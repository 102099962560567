import React from "react";
import ModelForm from "../ui/ModelForm";

const initialAccount = {
  name: '',
  logo_url: '',
};

const accountInputs = [
  {
    inputType: 'text',
    name: 'name',
    props: {
      label: 'Account Name',
      type: 'text',
    }
  },
  {
    inputType: 'image',
    name: 'logo',
    props: {
      label: 'Logo URL',
    }
  },
];

const AccountDetailsForm = (props) => {
  return (
    <ModelForm
      modelName="account"
      initialResource={initialAccount}
      inputs={accountInputs}
      {...props}
    />
  );
};

export default AccountDetailsForm;
