import React from "react";

import { DopeFormGroup } from "../ui/DopeForm";
import HasManyMultiSelect from "../ui/HasManyMultiSelect";

const CampaignListsSelect = ({ headerText, helpText, name, values, onChange }) => {
  const booleanName = `add_${name}`;

  const handleBooleanChange = (booleanUpdate) => {
    const update = {
      ...booleanUpdate,
      [name]: [],
    };
    onChange(update);
  };

  return (
    <>
      <DopeFormGroup
        input={{ inputType: "checkbox", name: booleanName, props: {
          headerText,
          helpText,
          size: "sm",
        }}}
        values={values}
        onChange={handleBooleanChange}
      />

      {values[booleanName] && (
        <div style={{ paddingLeft: 35, marginTop: -10, marginBottom: 15 }}>
          <HasManyMultiSelect
            modelName="list"
            name={name}
            values={values}
            onChange={onChange}
          />
        </div>
      )}
    </>
  );
};

export default CampaignListsSelect;
