import React, { useEffect } from "react";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import DopeContentPage from "../ui/DopeContentPage";
import { handleFilterChange, handleDateFilter } from "../utils/fn";
import { formatToLocaleDateString } from "../utils/date";

import { FiDownload } from "react-icons/fi";
import { useIntegrations, withIntegrations } from "../integrations/integrationsSlice";



const columns = [
  { type: 'stacked_text', dataKey: ['name', 'account_name'], label: 'Name', sortable: true },
  { type: 'custom', dataKey: 'campaign_template.list_generation_setting.generation_type', label: 'Sending Type', sortable: false, render: (row) => {
    if (row.campaign_template?.list_generation_setting?.generation_type === 'blitz') {
      return <span>Blitz Sending</span>;
    }

    if (row.campaign_template?.list_generation_setting?.generation_type === 'list') {
      return <span>Direct Sending</span>;
    }

    return '';
  }},
  { type: 'custom', dataKey: 'data_source_label', label: 'Connection', sortable: true, render: (row) => {
    if (row.data_source_label) {
      return <span>{row.data_source_label}</span>;
    }

    if (row.trigger_source === 'other') {
      return <span>Zapier / Webhook</span>;
    }
  }},
  { type: 'custom', dataKey: 'automation_runs_count', label: 'Triggers', sortable: true, render: (row) => {
    return <div>
      <span>{row.automation_runs_count}</span>
      <br />
      {row.last_run_at && <small style={{color: '#8793A6'}}>Last triggered {formatToLocaleDateString(row.last_run_at)}</small>}
    </div>;
  } },
  { type: 'custom', dataKey: 'created_at', label: 'Created Date', sortable: true, render: (row) => {
    return <div>
      <span>{formatToLocaleDateString(row.created_at)}</span>
      <br />
      <small style={{color: '#8793A6'}}>Last updated {formatToLocaleDateString(row.updated_at)}</small>
    </div>;
  } },
  { type: 'pill', pillTypeKey: 'effective_status', dataKey: 'effective_status', label: 'Status', sortable: false },
];

const api = new DopeApi('automation');
const accountApi = new DopeApi('account');

const typeOptions = [
  { value: "manual", label: "Validation Mode" },
  { value: "automatic", label: "Automatic" },
  { value: "reject", label: "Paused" },
];

const statusOptions = [
  { value: "draft", label: "Draft" },
  { value: "active", label: "Active" },
  { value: "archived", label: "Archived" },
];

const PulseAutomationTable = () => {
  const {
    error,
    tableProps,
    paginationProps,
    onSearchClear,
    onSearchInput,
    setFilters,
    filters,
    exportCSV,
    exporting
  } = useQuery2({
    api,
    initialSearchScope: 'name'
  });

  const { dataSources } = useIntegrations();

  const applyModeOptions = (mode) => {
    const filterObject = {
      filterField: 'mode',
      operator: 'in',
      filterCategory: 'Mode'
    }

    handleFilterChange(filters, setFilters, mode, filterObject)
  };


  if (!dataSources || !dataSources.length) {
    return null;
  }

  const applyConnectionTypeFilter = (type) => {
    const filterObject = {
      filterField: 'data_source',
      operator: 'in',
      filterCategory: 'Connection Type'
    }
    handleFilterChange(filters, setFilters, type, filterObject)
  };

  const applyStatusFilter = (status) => {
    const filterObject = {
      filterField: 'status',
      operator: 'in',
      filterCategory: 'Status'
    }
    handleFilterChange(filters, setFilters, status, filterObject);
  };

  const applyDateRangeFilter = (startDate, endDate) => {
    handleDateFilter(filters, setFilters, "created_at", startDate, endDate);
  };

  const onAccountsSelect = (accountIds) => {
    const filterObject = {
      filterField: "account_id",
      operator: "in",
      filterCategory: "Account",
    };

    handleFilterChange(filters, setFilters, accountIds, filterObject);
  };

  const actionButtons = [
    {
      label: 'Export',
      icon: <FiDownload />,
      onClick: () => exportCSV('automations'),
      isLoading: () => exporting,
      isDisabled: () => exporting,
    }
  ]


  const filterInputs = [
    {
      title: "Account",
      type: "MultiSelect",
      name: "account_ids",
      field: "account_id",
      searchable: true,
      getOptions: (text = "") => accountApi.getOptions({
        valueKey: 'id',
        labelKey: 'name',
        search: { text, column: 'name' },
      }),
      applyFilter: onAccountsSelect,
    },
    {
      title: "Connection Type",
      getOptions: () => {
        return dataSources.map((dataSource) => ({
          value: dataSource.value,
          label: dataSource.label,
        }));
      },
      applyFilter: applyConnectionTypeFilter,
    },

    {
      title: "Status",
      getOptions: () => statusOptions,
      applyFilter: applyStatusFilter,
    },
    {
      title: "Date Range",
      type: "dateRange" ,
      applyFilter: applyDateRangeFilter,
    },
  ];



  return (
    <DopeContentPage
      pageTitle="All Automations"
      pageContent={<DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={filterInputs}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        filters={filters}
        filterActionButtons={actionButtons}
      />}
    />
  );
}

export default withIntegrations(PulseAutomationTable);
