import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { useAccount } from "./accountSlice";
import DopeContentPage from "../ui/DopeContentPage";
import DopePlaceholder from "../ui/DopePlaceholder";
import { formatToLocaleDateString } from "../utils/date";
import ImageIcon from "@rsuite/icons/Image";
import ArrowBackIcon from "@rsuite/icons/ArowBack";
import AccountHealth from "../pulse/AccountHealth";
import PulseAccountTabBar from "../pulse/PulseAccountTabBar";
import DopeButton from "../ui/DopeButton";
import AccountLogoModal from "./AccountLogoModal";
import './Account.scss';

const accountLogoStyles = {
  height: "148px",
  width: "148px",
  borderRadius: 100,
  boxShadow: "0px 6px 6px 0px #BABABA40",
  border: "1px solid #F3F3F3"
};

const accountNameStyles = {
  fontSize: "24px",
  fontWeight: 600,
};

const subHeaderStyles = {
  fontSize: "12px",
  fontWeight: 400,
  color: "#8793A6",
};

const navLinkStyles = {
  fontWeight: 700,
  fontSize: '13px',
  paddingLeft: '20px',
  paddingTop: '20px',
  marginBottom: '-10px',
  cursor: 'pointer',
  color: '#255FDF',
  width: "fit-content",
};

const imagePlaceholder = (
  <div style={{...accountLogoStyles, display: "flex", alignItems: "center", justifyContent: "center"}}>
    <ImageIcon style={{ color: "#8793A6", fontSize: "48px" }} />
  </div>
);

const Account = ({ pulse }) => {
  const [showLogoModal, setShowLogoModal] = useState(false);
  const [account, actions] = useAccount()

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!account || account.id !== id) {
      actions.get(id);
    }

    return actions.resetToInitial;
  }, []);


  const handlePrevious = () => {
    navigate('/pulse/accounts');
  }

  const navLink = (
    <div onClick={handlePrevious} style={navLinkStyles}>
      <ArrowBackIcon /> Back to all Accounts
    </div>
  );

  const accountSharedContact = account && (
    <div style={{padding: "10px"}}>

      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "30px" }}>
          {/* TODO: Make this real when images are working properly, aws issues */}
          { account.logo_url ? <img src={account.logo_url} alt="logo" style={accountLogoStyles} /> : imagePlaceholder }
          {/*<img src={dopeLogo} alt="Dope Logo" style={accountLogoStyles}/>*/}
          <div style={{marginLeft: "10px"}}>
            <h5 style={accountNameStyles}>{account.name}</h5>
            <p style={subHeaderStyles}>Joined <span>{formatToLocaleDateString(account.created_at)}</span></p>
            <DopeButton
              props={{
                buttonClass: "outlined-black",
                label: "Update Logo",
                styles: { height: "24px", marginTop: "12px", fontSize: "11px", fontWeight: 700},
                onClick: () => setShowLogoModal(true),
              }}
            />
          </div>
        </div>

        {pulse && <AccountHealth />}
      </div>

      {pulse && <PulseAccountTabBar />}
      {/*<AccountTabBar accountId={account.id} />*/}
      <Outlet/>

      <AccountLogoModal show={showLogoModal} setShow={setShowLogoModal} />
    </div>
  );

  return (
    <DopeContentPage
      tabComponents={[]}
      actionButtons={[]}
      pageContent={account ? accountSharedContact : <DopePlaceholder type='image' />}
      navLink={pulse ? navLink : null}
    />
  );
}

export default Account;
