import React from 'react';
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

const DopeAccordionHeader = ({ open, header, label = false }) => {
  let className = " flex row vertical-align";
  if (label) {
    className = "label large bold" + className;
  } else {
    className = "body-1 bold color-text-label-blue-grey" + className;
  }

  return (
    <div className={className} >
      <div className="border flex vertical-align margin-8-r circle-icon color-text-primary">
        {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
      </div>
      <div>{header}</div>
    </div>
  );
}

export default DopeAccordionHeader;
