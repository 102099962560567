import React, { useState } from "react";

import { Drawer } from "rsuite";
import DopeCardSelect from "./DopeCardSelect";
import DopeButton from "./DopeButton";

const DopeSelectDrawer = ({ open, onCancel, onAction, header, infoBox, options }) => {
  const [selection, setSelection] = useState(options[0].value);

  return (
    <Drawer
      className="action-drawer"
      size="sm"
      placement="right"
      open={open}
      onClose={onCancel}
    >

      <Drawer.Body style={{ padding: '30px 0' }}>
        {infoBox}
        {header && <h5 style={{ padding: '0 60px' }}>{header}</h5>}

        <div className="pad">
          <DopeCardSelect
            value={selection}
            options={options}
            onSelect={setSelection}
          />
        </div>


      </Drawer.Body>
      <Drawer.Actions>
          <DopeButton
            props={{
              label: "Cancel",
              onClick: onCancel,
              buttonClass: "outlined-black action-button",
            }}
          />

          <DopeButton
            props={{
              label: "Create",
              onClick: () => onAction(selection),
              buttonClass: "filled-black action-button",
            }}
          />
      </Drawer.Actions>
    </Drawer>
  );
};

export default DopeSelectDrawer;
