import React from 'react';
import { Navbar, Nav, Dropdown } from 'rsuite';
import { useNavigate } from "react-router-dom";

import DopeLogo from "../icons/DopeLogo";
import UserIcon from "../icons/UserIcon";
import ExitIcon from '@rsuite/icons/Exit';
import TagIcon from '@rsuite/icons/Tag';
import CreditCardPlusIcon from '@rsuite/icons/CreditCardPlus';
import GearIcon from '@rsuite/icons/Gear';
import BranchIcon from '@rsuite/icons/Branch';
import PeoplesIcon from '@rsuite/icons/Peoples';
import EmailIcon from '@rsuite/icons/Email';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import { IoMdPulse } from "react-icons/io";
import authApi from "../auth/authApi";
import { humanize } from "../utils/railsNames";
import BelongsToSelect from "./BelongsToSelect";
import { useDopeUI } from "./dopeUISlice";

const dropdownCaret = <ArrowDownLineIcon style={{ fontSize: "22px", color: "#292B2E", marginLeft: "5px" }} />;

const navStyle = {
  backgroundColor: "#FFFFFF",
  height: "68px",
  padding: "0 24px",
  display: "flex",
  alignItems: "center",
  position: "fixed",
  width: "100%",
  top: 0,
  zIndex: 100,
  borderBottom: "1px solid #EAECEF"
};

const hrStyles = {
  margin: "15px 0px",
  color: "#EAECEF",
};

const navItemStyles = {
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "20px",
  padding: "10px 0px",
  cursor: "pointer",
  color: "#000000",
};

const navItemIconStyles = {
  marginRight: "7px",
  color: "#000000",
};

const viewingLabel = process.env.REACT_APP_ADMIN_VIEWING_LABEL || "Viewing:";

const DopeNavBar = ({ userType }) => {
  const currentUser = authApi.getCurrentUser();
  const currentAccount = authApi.getCurrentAccount();
  const canViewPulse = authApi.currentUserIsSuperAdmin() || authApi.currentUserIsAdmin()
  const isPulseUser = userType === 'pulse';
  const isStandardUser = userType === 'user';

  const navigate = useNavigate();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const handleAccountClick = (route) => {
    navigate(`/accounts/${currentAccount.id}/${route}`);
  };

  const handlePulseClick = () => {
    navigate("/pulse/accounts");
  };

  const handleLogout = async () => {
    try {
      await authApi.logout();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleAccountChange = async (update) => {
    try {
      await authApi.setAccount(update.account_id);
    } catch (error) {
      dopeUIActions.addFlashMessage({header: "Error", body: "You are not authorized to access this account.", type: "error"})
    }
  };

  const showAccountSwitcher = currentUser.can_switch_accounts && !isPulseUser;

  return (
    <Navbar appearance="subtle" style={navStyle} className="user-navbar">
      <Nav>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <DopeLogo style={{ height: "41px", cursor: "pointer" }} onClick={() => navigate("/overview")} />
          {userType === 'pulse' &&
            <div style={{fontWeight: 600, fontSize: "22px", fontStyle: "italic", color: "#000000"}}>
            Pulse
          </div>}
        </div>
      </Nav>

      {showAccountSwitcher && (
        <Nav style={{display: "flex", flexDirection: "row", marginLeft: "24px", alignItems: "center"}}>
          <div style={{fontSize: "14px", color: "#000000", display: "flex", flexDirection: "row", marginRight: "24px"}}>
            <div style={{fontWeight: 700, marginRight: "8px"}}>{currentUser.account_role ? humanize(currentUser.account_role) : humanize(currentUser.role)}:</div>
            <div style={{fontWeight: 400}}>{currentUser.first_name} {currentUser.last_name}</div>
          </div>

          <div style={{fontSize: "14px", color: "#000000", display: "flex", flexDirection: "row", alignItems: "center"}}>
            <div style={{fontWeight: 700, marginRight: "12px"}}>{viewingLabel}</div>
            <BelongsToSelect
              belongsToModelName="account"
              name="account_id"
              value={currentAccount.id}
              onChange={handleAccountChange}
            />
          </div>
        </Nav>
      )}

      <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
        <Dropdown
          icon={<UserIcon />}
          title={dropdownCaret}
          className="navbar-dropdown-button"
          placement="bottomEnd"
          menuStyle={{width: "200px", marginRight: "20px", padding: "20px"}}
        >
          {isStandardUser && (
            <>
              <Dropdown.Item className="drop-nav-item" onClick={() => handleAccountClick('subscriptions')} icon={<TagIcon stye={navItemIconStyles} />} style={navItemStyles}>My Subscriptions</Dropdown.Item>
              <Dropdown.Item className="drop-nav-item" onClick={() => handleAccountClick('billing/automated_purchases')} icon={<CreditCardPlusIcon stye={navItemIconStyles} />} style={navItemStyles}>Payments & Billing</Dropdown.Item>
              <Dropdown.Item className="drop-nav-item" onClick={() => handleAccountClick('settings')} icon={<GearIcon stye={navItemIconStyles} />} style={navItemStyles}>Profile Settings</Dropdown.Item>
              <Dropdown.Item className="drop-nav-item" onClick={() => handleAccountClick('integrations')} icon={<BranchIcon stye={navItemIconStyles} />} style={navItemStyles}>Integrations</Dropdown.Item>
              <Dropdown.Item className="drop-nav-item" onClick={() => handleAccountClick('user_access')} icon={<PeoplesIcon stye={navItemIconStyles} />} style={navItemStyles}>User Access</Dropdown.Item>
              {/*<Dropdown.Item className="drop-nav-item" onClick={() => handleAccountClick('mailer_pricing')} icon={<EmailIcon stye={navItemIconStyles} />} style={navItemStyles}>Mailer Pricing</Dropdown.Item>*/}

              <hr style={hrStyles} />
            </>
          )}
          {isStandardUser && canViewPulse && <Dropdown.Item className="drop-nav-item" onClick={() => handlePulseClick()} icon={<IoMdPulse style={navItemIconStyles}/>} style={navItemStyles} >Pulse</Dropdown.Item>}
          <Dropdown.Item className="drop-nav-item" onClick={handleLogout} icon={<ExitIcon style={navItemIconStyles}/>} style={navItemStyles}>Logout</Dropdown.Item>
        </Dropdown>
      </div>
    </Navbar>
  );
};

export default DopeNavBar;
