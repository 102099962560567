import React from "react";
import { Link } from "react-router-dom";
import EditIcon from '@rsuite/icons/Edit';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import TrashIcon from '@rsuite/icons/Trash';

import DopeApi from "../services/DopeApi";
import { useContactUI } from "./contactUISlice";
import { useContact } from "./contactSlice";
import { useList } from "../lists/listSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopePill from "../ui/DopePill";
import { DopeFormGroup } from "../ui/DopeForm";
import "../ui/DopeDropdown.scss"


const styles = {
  cursor: "pointer",
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "20px",
  color: "#292B2E",
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
}

const hrStyles = {
  margin: "15px 0px",
  color: "#EAECEF",
}

const iconStyles = {
  marginRight: "7px",
  color: "#8793A6",
  fontSize: "18px",
}

// TODO: Dead? get rid of this
const ContactListDropdown = ({ rowData: contactList, reloadTable }) => {
  let { id, contact_id } = contactList;
  const [contact, contactActions] = useContact(contact_id);
  const [list, listActions] = useList();
  const [contactUI, contactUIActions] = useContactUI();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const contactListApi = new DopeApi('contact_list');

  const removeContactFromList = async () => {
    // TODO: update the table
    try {
      await contactListApi.destroy(contactList.id);
      dopeUIActions.addFlashMessage({ header: contact.full_name, body: "removed from list successfully", type: "success" });
      reloadTable();
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: contact.full_name, body: "could not be removed from list", type: "error" });
    }
  }

  const handleClickEdit = () => {
    contactUIActions.setDrawerContactId(contact_id);
  }

  const handleSaveTags = () => {
    try {
      contactActions.save()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div>
        <div onClick={handleClickEdit} className="clickable margin-8-b label large flex row vertical-align bold">
          <div><EditIcon className="dropdown-item-icon margin-8-r" /></div>
          <div>Edit Contact</div>
        </div>

        <Link to={`/contacts/${contact_id}/details`} className="clickable margin-8-b label large flex row vertical-align bold">
          <div><UserBadgeIcon className="dropdown-item-icon margin-8-r" /></div>
          <div>View Contact Details</div>
        </Link>

        <div onClick={removeContactFromList} className="clickable margin-8-b label large flex row vertical-align bold">
          <div><TrashIcon className="dropdown-item-icon margin-8-r" /></div>
          <div>Remove from List</div>
        </div>

        {contact && (contact.is_lead || contact.is_client) && (
          <>
            <hr style={hrStyles}/>
            <div style={styles}>Contact Status</div>
            <div className="flex row">
              {contact.is_lead && (
                <div style={{margin: '0 5px 5px 0'}}>
                  <DopePill text="Lead" pillType="tag" trim={30}/>
                </div>
              )}

              {contact.is_client && (
                <div style={{margin: '0 5px 5px 0'}}>
                  <DopePill text="Client" pillType="tag" trim={30}/>
                </div>
              )}
            </div>
          </>
        )}

        <hr style={hrStyles} />

        <div style={{fontWeight: 700, fontSize: "10px", lineHeight: "12px", margin: "20px 0px 5px"}}>CUSTOM TAGS</div>
        {contact &&
          <div style={{ display: "flex", flexDirection: "column" }}>
            <DopeFormGroup
              input={{
                inputType: "tags",
                name: "taggings",
                props: {modelName: "contact",  hideEmptyState: true},
              }}
              values={contact}
              onChange={contactActions.update}
            />
            <button onClick={handleSaveTags} style={{fontSize: "12px", fontWeight: 600, color: "#6F6F6F", backgroundColor: "transparent", alignSelf: "end"}}>Save Tags</button>
          </div>
        }
      </div>
    </>
  )
}

export default ContactListDropdown;
