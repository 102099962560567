import { DopeFormGroup } from "../ui/DopeForm";
import DopeButton from "../ui/DopeButton";
import DopeDrawer from "../ui/DopeDrawer";
import HandwrittenCardBodyPreview from "./HandwrittenCardBodyPreview";
import { sizeToRatio } from "../utils/design";

const flexStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem 0',
  gap: '1rem',
};

const acknowledgements = [
  { inputType: "checkbox", name: "copy_correct", props: { helpText: "All copy is correct" } },
  { inputType: "checkbox", name: "images_correct", props: { helpText: "Images are correct and not blurry" } },
  { inputType: "checkbox", name: "qr_code_correct", props: { helpText: "QR Code is working and goes to the correct URL (check if not applicable)" } },
  { inputType: "checkbox", name: "phone_numbers_correct", props: { helpText: "Phone numbers are correct" } },
  { inputType: "checkbox", name: "website_urls_correct", props: { helpText: "Website URL(s) are correct" } }
];

const aspectRatio = sizeToRatio('5x7');


const HandwrittenCardApprovalDrawer = ({
  open,
  onCancel,
  onConfirm,
  loading,
  design,
  actions,
}) => {

  const inputProps = { values: design, onChange: actions.update, errors: design.errors };

  const canSubmit = () => {
    let acknowledged = acknowledgements.every(acknowledgementInput => design[acknowledgementInput.name]);

    return acknowledged;
  };

  if (!design.mail_template) {
    return null;
  }

  return (
    <DopeDrawer
      open={open}
      onClose={onCancel}
      header={"Review & Approve for Use"}
      subheader={"Are you sure you are ready to approve this design? Once this design is approved you will not be able to make additional edits to it."}
      size="sm"
      bodyContent={(
        <>
          <div className="flex row space-between margin-16-b">
            <div className="border-bottom min-width-240">
              <p className="label large bold color-text-primary">Design Name</p>
              <p>{design.name}</p>
            </div>
          </div>

          <div className="border-bottom margin-16-b">
            <p className="label large bold color-text-primary">Design Type</p>
            <p>5x7 Handwritten Card</p>
          </div>

          <div>
            <div className="full-width">
              <div style={{ aspectRatio, margin: '1rem 0' }}>
                <img src={design.mail_template.cover_image} alt='Front of Card' style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </div>
            </div>
            <div className="full-width">
              <div style={{ aspectRatio, margin: '1rem 0' }}>
                <HandwrittenCardBodyPreview
                  header={design.mail_template.header_image_url}
                  footer={design.mail_template.footer_image_url}
                  font={design.mail_template.font}
                  body={design.mail_template.body}
                  signature={design.mail_template.signature}
                  limit={design.mail_template?.card?.characters || 500}
                />
              </div>
            </div>
          </div>
          <div className="background-blue-grey-shade-light border-radius-8 pad-16">
            <h4 className="header-4 margin-4-b">Acknowledgment Checklist</h4>
            <p className="label large color-text-secondary">By confirming this template, you acknowledge that you have reviewed and all items below are correct. Once these go to print, the printing and sending will NOT be able to be stopped</p>
            {acknowledgements.map((acknowledgementInput, index) => (
              <DopeFormGroup key={index} input={acknowledgementInput} {...inputProps} />
            ))}
          </div>

          <footer style={{...flexStyle, justifyContent: "flex-end", padding: 0 }}>
            <DopeButton onClick={onCancel} props={{ buttonClass: "text-button"}} disabled={loading}>Keep Editing</DopeButton>
            <DopeButton
              onClick={onConfirm}
              props={{buttonClass: "filled-black", label: "Approve Design"}}
              disabled={loading || !canSubmit()}
              loading={loading}
            />
          </footer>
        </>
      )}
    />
  );
};


export default HandwrittenCardApprovalDrawer;
