import React, { useState } from "react";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import StripeCardAdder from "./StripeCardAdder";
import { useDopeUI } from "../ui/dopeUISlice";
import { useAccount } from "../accounts/accountSlice";

const StripeCardDrawer = ({ clientSecret, props }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [account, accountActions] = useAccount();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [loading, setLoading] = useState(false);

  const { onClose } = props;

  const handleSubmit = async () => {
    setLoading(true);
    if (!stripe || !elements) return;

    const { paymentMethod, error } = await stripe.confirmSetup( {
      elements,
      redirect: 'if_required'
    })

    if (error) {
      dopeUIActions.addFlashMessage({ header: "Error", body: error.message, type: "error" })
      setLoading(false);
    } else {
      stripe.retrieveSetupIntent(clientSecret).then(async (result) => {
        if (result.setupIntent.status === "succeeded") {
          try {
            await accountActions.addPaymentMethod(result.setupIntent.payment_method);
            dopeUIActions.addFlashMessage({ header: "Success!", body: "Your card has been added", type: "success" })
            onClose()
          } catch (error) {
            dopeUIActions.addFlashMessage({ header: "Error", body: error.message, type: "error" })
          } finally {
            setLoading(false);
          }
        }
      })
    }
  }

  return (
    <div className="stripe-card-drawer" style={{ marginTop: "10px"}}>
      <StripeCardAdder submittable={true} onSubmit={handleSubmit}  submitText="Add Card" loading={loading} />
    </div>
  );
}

export default StripeCardDrawer;
