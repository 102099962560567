import { Tooltip, Whisper } from "rsuite";
import { timeSince, timestampToDateTimeStr } from "../utils/date";

const RelativeTime = ({ time }) => {
  const relativeTime = timeSince(new Date(time));
  const timestampString = timestampToDateTimeStr(time);

  return (
    <Whisper trigger="hover" placement="top" speaker={<Tooltip>{timestampString}</Tooltip>}>
      <span>{relativeTime}</span>
    </Whisper>
  );
};

export default RelativeTime;
