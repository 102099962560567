import { observer } from 'mobx-react-lite';

import { DEFAULT_SECTIONS } from 'polotno/side-panel';

const DefaultPhotosSection = DEFAULT_SECTIONS.find(
  (section) => section.name === 'photos'
);

const DefaultPhotosSidePanel = observer(({ store }) => {
  return (
    <div className='custom-photos'>
      <div>
        <DefaultPhotosSection.Panel store={store} />
      </div>
    </div>
  );
});

export default DefaultPhotosSidePanel;
