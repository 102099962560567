import React, { useEffect, useRef, useState } from "react";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import DopeDrawer from "../ui/DopeDrawer";
import { formatToLocaleDateString } from "../utils/date";
import TrackingNumberDrawer from "./TrackingNumberDrawer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTrackingNumberUI } from "./trackingNumberUISlice";
import TrackingNumberEventsDrawer from "./TrackingNumberEventsDrawer";
import { useTrackingNumber } from "./trackingNumberSlice";
import { formatPhone } from "../utils/phone";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import DopePill from "../ui/DopePill";
import DopeCheckbox from "../ui/DopeCheckbox";

const api = new DopeApi('tracking_numbers');

const columns = [
  { type: "custom", dataKey: 'number', label: "Tracking Number", flexGrow: 2, sortable: true, render: (rowData) => {
    return (
      <div >
        <p style={{margin: 0}}>{formatPhone(rowData.number)}</p>
        <p style={{margin: 0}}><small>{rowData.name}</small></p>
      </div>
    );
  }},
  { type: "custom", dataKey: "forward_to", label: "Forwards to", flexGrow: 2, render: (rowData) => {
    if (rowData.status === "active") {
      return formatPhone(rowData.forward_to);
    }

    if (rowData.status === "canceled") {
      return <DopePill text={"Canceled"} />;
    }

    return null;
  }},
  { type: "event_time", dataKey: "last_called_at", label: "Last Call", sortable: true, flexGrow: 2 },
  { type: 'date', dataKey: 'created_at', label: 'Created Date', sortable: true, filterable: false, dateConverter: formatToLocaleDateString },
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'tracking_number', flexGrow: 1 }
];

const TrackingNumbersTable = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { trackingNumber, actions: trackingNumberActions, isSaving } = useTrackingNumber();
  const [ trackingNumberUI, actions ] = useTrackingNumberUI();

  const [cancelAcknowledged, setCancelAcknowledged] = useState(false);

  const prevSavingState = useRef(false);

  useEffect(() => {
    if (id && !location.pathname.includes("calls")) {
      actions.setDrawerTrackingNumberId(id);
    } else if (id && location.pathname.includes("calls")) {
      trackingNumberActions.get(id);
    }
  }, [id]);

  useEffect(() => {
    const prevSaving = prevSavingState.current;

    if (prevSaving && !isSaving && trackingNumberUI.cancelTrackingNumberId) {
      actions.setCancelTrackingNumberId(null);
      reload();
    }

    prevSavingState.current = isSaving;
  }, [isSaving]);

  useEffect(() => {
    if (trackingNumberUI.cancelTrackingNumberId) {
      trackingNumberActions.get(trackingNumberUI.cancelTrackingNumberId);
    }
  }, [trackingNumberUI.cancelTrackingNumberId]);

  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, setScopes, scopes, reload }
  = useQuery2({
    api,
    initialFilters: [],
    initialSearchScope: "name_and_number",
  });


  const closeDrawer = (shouldReload = false) => {
    actions.setDrawerTrackingNumberId(null);
    if (shouldReload) {
      reload();
    }
    navigate("/tracking_numbers");
  };


  return (
    <>
      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={[]}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        //onClickId={(mailPiece) => actions.setDrawerMailPieceId(mailPiece.id)}
      />

      <TrackingNumberDrawer
        open={!!trackingNumberUI.drawerTrackingNumberId}
        id={trackingNumberUI.drawerTrackingNumberId}
        reload={reload}
        onClose={closeDrawer}
      />

      <TrackingNumberEventsDrawer
        open={!!id && location.pathname.includes("calls")}
        onClose={() => {
          trackingNumberActions.resetToInitial();
          navigate("/tracking_numbers");
        }}
        trackingNumber={trackingNumber}
      />

      <DopeConfirmationModal
        open={!!trackingNumberUI.cancelTrackingNumberId && trackingNumber}
        title={<span>Are you sure you want to cancel your tracking number<br/>{formatPhone(trackingNumber?.number)}?</span>}
        message={(
          <>
            <p>Canceling this subscription will also release the number from our provider so someone else could pick this number up.</p>
            <p>If you are interested in saving this number, please reach out to your account manager or our support team for assistance.</p>
            <p>
              <DopeCheckbox
                value={cancelAcknowledged}
                onChange={({ acknowledged }) => setCancelAcknowledged(acknowledged)}
                name="acknowledged"
                headerText="I acknowledge that I am canceling and releasing this number"
              />
            </p>
          </>
        )}
        onConfirm={async () => {
          trackingNumberActions.cancel();
          setCancelAcknowledged(false);
        }}
        onCancel={() => actions.setCancelTrackingNumberId(null)}
        confirmLabel='Yes, Cancel'
        cancelLabel='No, Keep'
        confirmButtonClass='filled-black'
        cancelButtonClass='outlined'
        loading={isSaving}
        size="sm"
        canConfirm={cancelAcknowledged}
      />
    </>
  );
};

export default TrackingNumbersTable;
