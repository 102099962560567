import {FaBell, FaEnvelope, FaHouseChimney, FaImages} from "react-icons/fa6";
import {BsBox2Heart} from "react-icons/bs";
import {IoBarChart} from "react-icons/io5";
import {
  MdOutlineMarkunreadMailbox,
  MdOutlineCampaign,
  MdOutlineHomeWork,
  MdChecklist,
  MdFormatListBulletedAdd,
  MdMailOutline,
  MdOutlineAddHome,
  MdOutlineUploadFile,
  MdOutlineCloudSync,
  MdOutlineDraw,
  MdOutlineCookie,
  MdOutlineQrCode,
  MdPhone
} from "react-icons/md";

const iconMap = {
  house: <FaHouseChimney />,
  add_house: <MdOutlineAddHome />,
  blitz: <MdOutlineHomeWork />,
  blitz_campaign: <MdOutlineHomeWork />,
  list_blitz: <MdChecklist />,
  list_blitz_campaign: <MdChecklist />,
  mail: <MdMailOutline />,
  list: <MdFormatListBulletedAdd />,
  list_campaign: <MdOutlineCampaign />,
  notify: <FaBell />,
  report: <IoBarChart />,
  eddm: <MdOutlineMarkunreadMailbox />,
  eddm_campaign: <MdOutlineMarkunreadMailbox />,
  images: <FaImages />,
  handwritten: <MdOutlineDraw />,
  cookie: <MdOutlineCookie />,
  box: <BsBox2Heart />,
  upload: <MdOutlineUploadFile />,
  pull: <MdOutlineCloudSync />,
  qr: <MdOutlineQrCode />,
  phone: <MdPhone />,
};

export default iconMap;
