import React, { useEffect } from 'react';
import { Modal } from 'rsuite';

import { useDesign } from "./designSlice";
import AddDesignForm from "./AddDesignForm";

const AddDesignModal = ({ open, designId = 'new', handleClose, reload, editable = true }) => {
  const { design, actions } = useDesign();

  useEffect(() => {
    if (open) {
      actions.get(designId);
    }
    return actions.resetToInitialState;
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose} size="lg" >
      <Modal.Header>
        <Modal.Title>Upload New Design</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddDesignForm handleClose={handleClose} reload={reload} editable={editable} />
      </Modal.Body>
    </Modal>
  );
}

export default AddDesignModal;

