export const formatPhone = (phone) => {
  if (!phone) return '';

  const hasIntlCode = phone.startsWith('+');

  let intlCode = '';
  let number = '';

  if (hasIntlCode) {
    // The number are the last 10 digits
    number = phone.slice(-10);
    intlCode = phone.slice(0, phone.length - 10);
  } else {
    number = phone;
  }

  const cleaned = number.replace(/\D/g, '');

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phone;
}
