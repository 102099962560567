import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from "react-redux";
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { asNestedDestroyable } from "../services/createModelSelectors";
import dopeClient from '../services/dopeClient';
import DopeApi from '../services/DopeApi';


const api = new DopeApi('campaign');

export const saveCampaign = createAsyncThunk(
  'campaign/saveCampaign',
  async (campaign, { rejectWithValue }) => {
    try {
      return await api.save(campaign);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const getCampaign = createAsyncThunk(
  'campaign/getCampaign',
  async (id, { rejectWithValue }) => {
    try {
      return await api.get(id);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

export const fetchZipCodeRoutes = createAsyncThunk(
  'campaign/fetchZipCodeRoutes',
  async (zipCode, { rejectWithValue }) => {
    try {
      const url = `zip_codes/${zipCode}/usps_routes`;
      const resp = await dopeClient.get(url);
      return resp.data.usps_routes;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const initialState = {
  prev: null,
  current: null,
  loading: false,
  routesData: {
    fetching: false,
    routes: [],
    errors: null,
  },
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    updateCampaign: ({ current: campaign }, { payload: update }) => {
      Object.assign(campaign, update);
    },
    updateListGenerationSettings: ({ current: campaign }, { payload: update }) => {
      Object.assign(campaign.list_generation_setting, update);
    },
    updateEDDM: ({ current: campaign }, { payload: update }) => {
      Object.assign(campaign.eddm, update);
    },
    updateEDDMZipCodeData: ({ current: campaign }, { payload: zipCodeData }) => {
      const update = { ...zipCodeData, zip_crids: [], all_addresses_count: 0, residential_addresses_count: 0, commercial_addresses_count: 0 };
      Object.assign(campaign.eddm, update);
    },
    toggleEDDMRoute: ({ current: campaign }, { payload: toggleCrid }) => {
      const routeIndex = campaign.eddm.zip_crids.findIndex(zipCrid => zipCrid === toggleCrid);
      if (routeIndex === -1) {
        campaign.eddm.zip_crids.push(toggleCrid);
      } else {
        campaign.eddm.zip_crids = campaign.eddm.zip_crids.filter((r) => r !== toggleCrid);
      }
    },
    clearEDDMRoutes: ({ current: campaign }) => {
      campaign.eddm.zip_crids = [];
    },
    setEDDMRoutes: ({ current: campaign }, { payload: zipCrids }) => {
      campaign.eddm.zip_crids = zipCrids;
    },
    updateDispatch: ({ current: campaign }, { payload }) => {
      const { dispatches } = campaign;
      const { index, update } = payload;
      Object.assign(dispatches[index], update);
    },
    addDispatch: ({ current: campaign }, { payload: dispatch }) => {
      campaign.dispatches.push(dispatch);
    },
    removeDispatch: ({ current: campaign }, { payload: dispatch }) => {
      campaign.dispatches = campaign.dispatches.filter((d) => d.date !== dispatch.date);
    },
    updateGeoShape: ({ current: campaign }, { payload }) => {
      const { geo_shapes } = campaign.list_generation_setting;
      const { id, update } = payload;
      const index = geo_shapes.findIndex((geo_shape) => geo_shape.id === id);
      Object.assign(geo_shapes[index], update);
    },
    addGeoShape: ({ current: campaign }, { payload: geo_shape }) => {
      campaign.list_generation_setting.geo_shapes.push(geo_shape);
    },
    removeGeoShape: ({ current: campaign }, { payload: index }) => {
      campaign.list_generation_setting.geo_shapes.splice(index, 1);
    },
    updateDataAxleFilters: ({ current: campaign }, { payload: update }) => {
      Object.assign(campaign.list_generation_setting.data_axle_filters, update);
    },
    removeDataAxleFilter: ({ current: campaign }, { payload: filterKey }) => {
      delete campaign.list_generation_setting.data_axle_filters[filterKey];
    },
    resetToInitial: (state) => {
      state.current = null;
      state.prev = null;
      state.loading = false;
      state.routesData = {
        fetching: false,
        routes: [],
        errors: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCampaign.fulfilled, (state, action) => {
        state.prev = action.payload;
        state.current = action.payload;
      })
      .addCase(saveCampaign.rejected, (state, action) => {
        Object.assign(state.current, { errors: action.payload });
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.prev = action.payload;
        state.current = action.payload;
      })
      .addCase(fetchZipCodeRoutes.pending, (state) => {
        state.routesData.fetching = true;
      })
      .addCase(fetchZipCodeRoutes.fulfilled, (state, action) => {
        state.routesData.fetching = false;
        state.routesData.routes = action.payload;
      })
      .addCase(fetchZipCodeRoutes.rejected, (state, action) => {
        state.routesData.fetching = false;
        state.routesData.errors = action.payload;
      });
  }
});

export default campaignSlice.reducer;

export const {
  updateCampaign,
  updateListGenerationSettings,
  updateEDDM,
  updateEDDMZipCodeData,
  toggleEDDMRoute,
  clearEDDMRoutes,
  setEDDMRoutes,
  updateDispatch,
  addDispatch,
  removeDispatch,
  updateGeoShape,
  addGeoShape,
  removeGeoShape,
  updateDataAxleFilters,
  removeDataAxleFilter,
  resetToInitial,
} = campaignSlice.actions;

export const selectCampaign = (state) => state.campaign.current;
export const selectPrevCampaign = (state) => state.campaign.prev;
export const selectListGenerationSettings = (state) => state.campaign.current.list_generation_setting;
export const selectRoutesData = (state) => state.campaign.routesData;
export const selectEDDMData = createSelector([selectCampaign, selectRoutesData], (campaign, routesData) => {
  if (!campaign) {
    return {};
  }

  const eddm = campaign.eddm;
  const routes = routesData.routes;

  const addressCounts = eddm.zip_crids.reduce((acc, zipCrid) => {
    const route = routes.find(route => route.attributes.ZIP_CRID === zipCrid);
    if (route) {
      acc.all += route.attributes.TOT_CNT;
      acc.residential += route.attributes.RES_CNT;
      acc.commercial += route.attributes.BUS_CNT;
    }

    return acc;
  }, { all: 0, commercial: 0, residential: 0 });

  return {
    addressCounts,
    audienceCount: eddm.total_delivery_addresses > 0 ? eddm.total_delivery_addresses : addressCounts[eddm.audience],
  };
});

const campaignToParams = (campaign, prevCampaign) => { // TODO figure out if account ids are needed for perms
  const campaignAttrs = ['name', 'account_id', 'audience_generation_type'];
  const eddmAttrs = ['zip_code', 'audience', 'zip_crids', 'latitude', 'longitude'];
  const listGenerationSettingAttrs = [
    'first_name', 'last_name', 'city', 'address_1', 'address_2', 'state', 'zip', 'zip_4', 'state', 'country', 'latitude', 'longitude',
    'starting_list_id', 'suppress_starting', 'audience_type', 'generation_type', 'max_distance', 'max_contacts_per_generation', 'account_id', 'data_axle_filters'
  ];
  const taggingsAttrs = ['id', 'tag_id'];
  const hasManyListsAttrs = ['id', 'list_id'];
  const dispatchesAttrs = ['id', 'date', 'time', 'mail_template_id', 'mail_template_type', 'hot_lead_phone', 'hot_lead_url'];

  let campaignParams = {
    id: campaign.id === 'new' ? null : campaign.id, // this happens automatically from dope api but it doesnt do nested so I probably just want to make this explicit
    ...pick(campaign, campaignAttrs),
    ...asNestedDestroyable(prevCampaign, campaign, 'taggings', taggingsAttrs),
    ...asNestedDestroyable(prevCampaign, campaign, 'dispatches', dispatchesAttrs),
  };

  if (campaign.is_eddm) {
    campaignParams = {
      ...campaignParams,
      eddm_attributes: {
        id: campaign.eddm.id === 'new' ? null : campaign.eddm.id,
        ...pick(campaign.eddm, eddmAttrs),
      }
    }
  }

  if (campaign.is_list_generation_setting) {
    campaignParams = {
      ...campaignParams,
      list_generation_setting_attributes: {
        id: campaign.list_generation_setting.id === 'new' ? null : campaign.list_generation_setting.id,
        ...pick(campaign.list_generation_setting, listGenerationSettingAttrs),
        geo_shapes: campaign.list_generation_setting.geo_shapes.map(geo_shape => omit(geo_shape, 'addresses', 'estimated_contacts')), // using omit for now... but should make this exact...
        ...asNestedDestroyable(prevCampaign.list_generation_setting, campaign.list_generation_setting, 'list_generation_setting_seed_lists', hasManyListsAttrs),
        ...asNestedDestroyable(prevCampaign.list_generation_setting, campaign.list_generation_setting, 'list_generation_setting_suppression_lists', hasManyListsAttrs),
      }
    }
  }

  return campaignParams;
};

export function useCampaign() {
  const dispatch = useDispatch();
  const campaign = useSelector(selectCampaign);
  const prevCampaign = useSelector(selectPrevCampaign);
  const routesData = useSelector(selectRoutesData);
  const eddmData = useSelector(selectEDDMData);

  const actions = {
    update: (payload) => dispatch(updateCampaign(payload)),
    updateListGenerationSettings: (payload) => dispatch(updateListGenerationSettings(payload)),
    updateDispatch: (payload) => dispatch(updateDispatch(payload)),
    addDispatch: (payload) => dispatch(addDispatch(payload)),
    removeDispatch: (payload) => dispatch(removeDispatch(payload)),
    updateEDDM: (payload) => dispatch(updateEDDM(payload)),
    updateEDDMZipCodeData: (payload) => dispatch(updateEDDMZipCodeData(payload)),
    toggleEDDMRoute: (payload) => dispatch(toggleEDDMRoute(payload)),
    clearEDDMRoutes: () => dispatch(clearEDDMRoutes()),
    setEDDMRoutes: (payload) => dispatch(setEDDMRoutes(payload)),
    get: (id) => dispatch(getCampaign(id)),
    resetToInitial: () => dispatch(resetToInitial()),
    save: (additionalParams = {}) => { // TODO - figure out the best way to parse params for the api - white list? Black lists? Sending too much to server
      if (additionalParams.preventDefault instanceof Function) {
        console.error("You're passing an event to saveCampaign! Campaigns cannot be updated with an event!!!");
        additionalParams = {};
      }

      const campaignParams = { ...campaignToParams(campaign, prevCampaign), ...additionalParams };
      return dispatch(saveCampaign(campaignParams));
    },
    schedule: () => {
      const campaignParams = { ...campaignToParams(campaign, prevCampaign), actions: [{ name: 'schedule' }] };
      return dispatch(saveCampaign(campaignParams));
    },
    cancelDispatch: (dispatchId) => {
      const campaignParams = { ...campaignToParams(campaign, prevCampaign), actions: [{ name: 'cancel_dispatch', args: [dispatchId] }] };
      return dispatch(saveCampaign(campaignParams));
    },
    fetchZipCodeRoutes: (zipCode) => dispatch(fetchZipCodeRoutes(zipCode)),
  };

  return [
    campaign,
    actions,
    {
      routesData,
      eddmData,
    }
  ];
}

export function useCampaignListGenerationSettings() {
  const dispatch = useDispatch();
  const listGenerationSettings = useSelector(selectListGenerationSettings);
  const actions = {
    update: (payload) => dispatch(updateListGenerationSettings(payload)),
    updateGeoShape: (payload) => dispatch(updateGeoShape(payload)),
    addGeoShape: (payload) => dispatch(addGeoShape(payload)),
    removeGeoShape: (payload) => dispatch(removeGeoShape(payload)),
    updateDataAxleFilters: (payload) => dispatch(updateDataAxleFilters(payload)),
    removeDataAxleFilter: (payload) => dispatch(removeDataAxleFilter(payload)),
  };

  return [listGenerationSettings, actions];
}


