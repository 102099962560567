import React from "react";
import { Link } from "react-router-dom";

const ContactNameCell = ({ rowData }) => {
  const contact = rowData.contact ? rowData.contact : rowData;

  return (
    <div className="vertical-align row">
      {!contact.has_valid_info && <div className="table-badge invalid">INVALID</div>}

      <Link to={`/contacts/${contact.id}/details`}>
        <div className="label large">{contact.full_name}</div>
        <div className="label small optional color-text-secondary">{contact.company_name}</div>
      </Link>
    </div>
  )
}

export default ContactNameCell;
