import React, {useEffect, useState} from 'react';
import {useNavigate, Link} from "react-router-dom";

import authApi from "./authApi";
import DopeApi from "../services/DopeApi";
import DopeForm from "../ui/DopeForm";
import {useObjectState} from "../hooks/useObjectState";
import DopeLogo from "../icons/DopeLogo";
import {Input} from "rsuite";

const initialCredentials = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
};

let signupInputs = [
  {
    inputType: 'text',
    name: 'first_name',
    props: {
      label: 'First Name',
      type: 'text',
    }
  },
  {
    inputType: 'text',
    name: 'last_name',
    props: {
      label: 'Last Name',
      type: 'text',
    }
  },
  {
    inputType: 'text',
    name: 'email',
    props: {
      label: 'Email',
      type: 'email',
    }
  },
  {
    inputType: 'text',
    name: 'password',
    props: {
      label: 'Password',
      type: 'password',
    }
  },
  {
    inputType: 'phone',
    name: 'phone_number',
    props: {
      label: 'Phone Number',
      type: 'tel',
      placeholder: '+16513098577'
    }
  },
];

const submitButtonProps = {
  styles: {
    backgroundColor: '#EA0029',
    width: '100%',
    borderRadius: '24px',
    fontSize: '14px',
    fontWeight: 600,
  },
  className: 'login-form-button'
}

const loginFormStyles = {
  width: "468px",
  height: "auto",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 6px 6px 0px #BABABA40",
}

const accountApi = new DopeApi('account')

const SignupForm = () => {
  const [credentials, updateCredentials, setCredentials, clearCredentials] = useObjectState(initialCredentials);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    updateCredentials({role: 'user'})
  }, [])

  const handleSubmit = async (event) => {
    setLoading(true)
    try {
      await authApi.signup(credentials)
      try {
        await authApi.login({email: credentials.email, password: credentials.password}, 'none')
        await handleAccountSubmit()
      } catch (error) {
        console.log(error)
      }
    } catch (error) {
      setErrors(error.response.data.errors)
    } finally {
      setLoading(false)
    }
  };

  const handleAccountSubmit = async () => {
    let account = null
    try {
      account = await accountApi.create({name: accountName, account_email: credentials.email})
      await authApi.setAccount(account.id, '/subscription_signup')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "20px"}}>
      <DopeLogo style={{ width: "169px", height: "73px", margin: "10px 0" }}/>
      <div style={loginFormStyles}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <h3 style={{marginBottom: "10px"}}>{'Sign Up'}</h3>
          <Link to="/login" style={{fontWeight: 600, fontSize: "14px", color: "#255FDF",}}>Back to Sign In</Link>
        </div>

        <label className="label">Company Name</label>
        <Input
          type="text"
          value={accountName}
          onChange={(value) => setAccountName(value)}
          style={{marginBottom: "20px"}}
        />

        <DopeForm
          submitText="Register"
          onSubmit={handleSubmit}
          inputs={signupInputs}
          values={credentials}
          onChange={updateCredentials}
          errors={errors}
          props={{submitButtonProps: submitButtonProps}}
          loading={loading}
        />

      </div>
    </div>
  );
};

export default SignupForm;
