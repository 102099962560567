import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";


import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";

const [slideoverSlice, asyncActions] = createModelSlice('slideover', {

});

export default slideoverSlice.reducer;

export const {
  updateSlideover,
  cancelSlideoverUpdate,
  resetToInitial,
} = slideoverSlice.actions;

export const selectSlideover = (state) => state.slideover.current

export function useSlideover(slideoverId) {
  const dispatch = useDispatch();
  const slideover = useSelector(selectSlideover);
  const isSaving = useSelector(state => state.design.isSaving);
  const isGetting = useSelector(state => state.design.isGetting);

  const actions = wrapAll({
    update: updateSlideover,
    resetToInitial: resetToInitial,
    get: asyncActions.get,
    destroy: asyncActions.destroy,
  }, dispatch);

  actions.save = (update = {}) => {

    const { content, ...rest } = slideover;

    const params = {
      ...rest,
      content: JSON.stringify(content),
      ...update
    };

    return dispatch(asyncActions.save(params));
  };

  useEffect(() => {
    const hasId = typeof slideoverId !== 'undefined' && slideoverId !== null && slideoverId !== '';
    const shouldGet = hasId && (!slideover || slideover.id !== slideoverId);

    if (shouldGet) {
      actions.get(typeof slideoverId === 'string' ? slideoverId.replace('#', '') : slideoverId);
    }
  }, [slideoverId]);


  return {
    slideover,
    actions,
    isSaving,
    isGetting,
  };
}
