import axios from "axios";
import authApi from "../auth/authApi";
import qs from "qs";

const apiBase = process.env.REACT_APP_API_BASE_URL;
const apiV1Base = `${apiBase}`;

const serializeParams = (params) => {
  return qs.stringify(params, { arrayFormat: 'brackets' });
};

const deserializeParams = (queryString) => {
  return qs.parse(queryString, { ignoreQueryPrefix: true, arrayFormat: 'brackets' });
};

const dopeClient = axios.create({
  baseURL: apiV1Base,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: serializeParams,
});

dopeClient.interceptors.request.use(
  (config) => {
    const authHeaderValue = authApi.getAuthHeaderValue();
    if (authHeaderValue) {
      config.headers["Authorization"] = authHeaderValue;
    }
    const currentAccountId = authApi.getCurrentAccountId();
    if (currentAccountId) {
      config.headers["X-Current-Account-Id"] = currentAccountId;
    }
    const isAdminRequest = window.location.pathname.startsWith('/admin') || window.location.pathname.startsWith('/pulse')
    if (isAdminRequest) {
      config.headers["X-Admin-Request"] = true;
    }
    return config;
  }
);

dopeClient.interceptors.response.use((response) => {
  return response;
}, async function (error) {
  if (axios.isCancel(error)) {
    return Promise.reject({ name: "CanceledError" });
  }
  if (error.response?.status === 401) {
    authApi.clearSession();
  }
  console.log(error);
  return Promise.reject(error.response.data.errors);
});

export {
  serializeParams,
  deserializeParams,
}

export default dopeClient;

