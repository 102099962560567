import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';

import DopeSelect from "../ui/DopeSelect";

import DefaultPhotosSidePanel from './DefaultPhotosSidePanel';
import CustomPhotosPanel from './CustomPhotosPanel';

import ImImages from '@meronex/icons/im/ImImages';

const OPTIONS = [
  { value: 'uploads', label: 'My Uploads' },
  { value: 'stock', label: 'Stock Images' },
  // { value: 2, label: 'CompanyCam' },
];

const PhotosSidePanel = {
  name: 'photos',
  Tab: (props) => (
    <SectionTab name="Photos" {...props}>
      <ImImages icon="new-text-box" />
    </SectionTab>
  ),
  Panel: observer((props) => {
    const DEFAULT = OPTIONS[0];

    const [tab, setTab] = useState(DEFAULT.value);

    return (
      <div className='custom-photos'>
        <div className='header'>
          <h2 className='title'>Photos</h2>
        </div>

        <div className='select-wrapper'>
          <DopeSelect
            name='tab'
            value={tab}
            options={OPTIONS}
            defaultValue={DEFAULT.value}
            placeholder=''
            onChange={opt => {
              setTab(opt.tab);
            }}
          />
        </div>

        <div className='tabs-wrapper'>
          {tab === 'stock' && <DefaultPhotosSidePanel {...props} /> }
          {tab === 'uploads' && <CustomPhotosPanel {...props} /> }
        </div>
      </div>
    );
  }),
};

export default PhotosSidePanel;
