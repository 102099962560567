import React from "react";
import { Link } from 'react-router-dom';

import DopeApi from "../services/DopeApi";
import { serializeParams } from "../services/dopeClient";
import { useStripeUI } from "../stripe/stripeUISlice";
import { useQuery2 } from "../hooks/useQuery";

import DopeListPage from "../ui/DopeListPage";

const api = new DopeApi('purchase');

const expandedLineHeight = 16;
const expandedLineStyle = {
  height: expandedLineHeight,
  lineHeight: `${expandedLineHeight}px`,
  fontSize: "12px",
  marginLeft: 10,
  color: '#6F6F6F',
};

const DetailsCell = ({ rowData, expandedKeys }) => {
  const isExpanded = expandedKeys.includes(rowData?.id);

  const detailItems = isExpanded && rowData.batch_details.items.map((item, i) => (
    <li key={i} style={expandedLineStyle}>
      {item}
    </li>
  ));

  return (
    <div>
      {rowData?.batch_details?.header}
      {detailItems}
    </div>
  );
};

const LinksCell = ({ rowData }) => { // Leaving campaign link for now but likely will never use / should remove
  // const isUpFront = rowData.purchase_type == "NIGHTLY_PAY_UPFRONT";

  // if (isUpFront) {
  //   const campaignSearch = serializeParams({
  //     initialScopes: [{ name: "has_mail_piece_purchased_by", args: rowData.id }],
  //   });

  //   const viewRelatedCampaignsLink = (
  //     <div>
  //       <Link to={`/campaigns?${campaignSearch}`}>View Campaigns</Link>
  //     </div>
  //   );

  //   return viewRelatedCampaignsLink;
  // }

  const mailPieceSearch = serializeParams({
    initialScopes: [{ name: "purchased_by", args: rowData.id }],
  });

  const viewPurcasedMailPiecesLink = (
    <div>
      <Link to={`/mail_history?${mailPieceSearch}`}>View Mail Pieces</Link>
    </div>
  );

  return viewPurcasedMailPiecesLink;
};

const style = { alignItems: "start" };

const invoiceIDStyle = {
  ...style,
  color: '#255FDF',
};

const columns = [
  { type: 'expand', dataKey: 'id', width: 10, style },
  { type: 'date', dataKey: 'created_at', label: 'Date', width: 100, sortable: true, style },
  { type: 'custom_component', dataKey: 'batch_details.header', label: 'Details', flexGrow: 1, Component: DetailsCell, style },
  { type: 'cents', dataKey: 'total_price', label: 'Amount', width: 100, style },
  { type: 'pill', dataKey: 'status', label: 'Status', width: 150, messageKey: 'status_message', style },
  { type: 'clickable_text', dataKey: 'stripe_invoice_id', label: 'Invoice', width: 250, customStyles: invoiceIDStyle },
  { type: 'custom_component', width: 150, Component: LinksCell, style },
];

const filterInputs = [];
const initialScopes = [{ name: "with_batch" }];
const extraParams = { view: "batch_purchase" };
const initialSortColumn = "created_at";

const rowHeight = (props) => {
  const { rowData, expandedKeys } = props;
  const isExpanded = expandedKeys.includes(rowData?.id);
  if (isExpanded) {
    return expandedLineHeight * rowData.batch_details.items.length + 50;
  }

  return 58;
};

const AccountBatchPurchaseTable = () => {
  const [stripeUI, stripeUiActions] = useStripeUI();

  const {
    error,
    tableProps,
    paginationProps,
    filters,
    setFilters,
  } = useQuery2({ api, initialScopes, extraParams, initialSortColumn });

  const handleClickStripeInvoiceId = (rowData) => {
    stripeUiActions.setDrawerInvoiceId(rowData.stripe_invoice_id);
  };

  return (
    <div>
      <DopeListPage
        paginationProps={paginationProps}
        tableProps={tableProps}
        columns={columns}
        error={error}
        filterInputs={filterInputs}
        filters={filters}
        setFilters={setFilters}
        onClickStripeInvoiceId={handleClickStripeInvoiceId}
        rowHeight={rowHeight}
      />
    </div>
  );
};

export default AccountBatchPurchaseTable;
