import React, { useState } from "react";
import { RadioTile, RadioTileGroup } from "rsuite";
import { Icon } from "@rsuite/icons";
import './DopeRadioTile.scss';

const DopeRadioTile = ({ name, value, onChange, options, defaultValue }) => {
  const [selected, setSelected] = useState(defaultValue);

  const isSelected = (value, index) => {
    if (!selected && defaultValue !== null) {
      return index === 0;
    } else {
      return selected === value;
    }
  };

  const handleSelect = (activeKey) => {
    setSelected(activeKey);
  };

  return (
    <RadioTileGroup value={value} onChange={nextValue => onChange({ [name]: nextValue })} defaultValue={defaultValue} style={{ display: "flex", flexDirection: "row" }}>
      {options.map((option, index) => (
        <RadioTile value={option.value} disabled={option.disabled} key={option.value} onChange={value => handleSelect(value)} className={`radio-tile ${isSelected(option.value) ? 'selected' : ''}`}>
          <div className="flex column space-between">
            <div className="to-center">
              <Icon as={option.icon} className={`tile-icon ${isSelected(option.value) ? 'selected-icon' : ''}`}/>
            </div>
            <div className="text-center color-text-secondary">{option.label}</div>
          </div>
        </RadioTile>
      ))}
    </RadioTileGroup>
  );
};

export default DopeRadioTile;
