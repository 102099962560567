import React from 'react';
import { Modal } from 'rsuite';

import { useDesignTemplate } from "../designs/designTemplateSlice";
import AddDesignTemplateForm from "./AddDesignTemplateForm";
import { Workspace } from 'polotno/canvas/workspace';

const AddDesignTemplateModal = ({ open, designTemplateId = 'new', handleClose, reload}) => {
  const { designTemplate, actions } = useDesignTemplate(designTemplateId);

  return (
    <Modal open={open} onClose={handleClose} size={"lg"} >
      <Modal.Header>
        <Modal.Title>Add Design Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddDesignTemplateForm handleClose={handleClose} reload={reload} />
      </Modal.Body>
    </Modal>
  );
}

export default AddDesignTemplateModal;

