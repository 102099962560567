import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import RemindOutlineIcon from '@rsuite/icons/RemindOutline';
import SendIcon from '@rsuite/icons/Send';

import authApi from "../auth/authApi";
import DopeApi from "../services/DopeApi";
import StripeApi from "../stripe/StripeApi";
import DopeButton from "../ui/DopeButton";
import {MdOutlineFilter} from "react-icons/md";
import {Icon} from "@rsuite/icons";

const accountsApi = new DopeApi("account");
const automationRunsApi = new DopeApi("automation_run");
const designsApi = new DopeApi("design");
const stripeApi = new StripeApi;

const actionSectionStyle = {
  borderRadius: "8px",
  border: "1px solid #EAECEF",
  background: "#FFFFFF",
  padding: "20px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}

async function getInvalidContacts(setHasInvalidContacts) {
  const hasInvalidContacts = await accountsApi.fetchMember(authApi.getCurrentAccountId(), "has_invalid_contacts");
  setHasInvalidContacts(hasInvalidContacts);
}

async function getPendingAutomations(setAutomationsCount) {
  const resp = await automationRunsApi.query({
    scopes: [{ name: "needs_approval" }],
    filters: [{ field: "automations.account_id", operator: "=", value: authApi.getCurrentAccountId() }],
  });
  setAutomationsCount(resp.total);
}

async function getPendingDesigns(setDesignsCount) {
  const pendingDesigns = await designsApi.query({scopes: [{name: "needs_review"}]});
  setDesignsCount(pendingDesigns.total);
}

async function getLatestInvoice(setLatestInvoice) {
  const invoices = await stripeApi.query();
  setLatestInvoice(invoices.stripe[0]);
}

const actionButtonStyles = { flex: 1, padding: "10px 20px"}


const PendingActions = () => {
  const [hasInvalidContacts, setHasInvalidContacts] = useState(false);
  const [automationsCount, setAutomationsCount] = useState(0);
  const [designsCount, setDesignsCount] = useState(0);
  const [latestInvoice, setLatestInvoice] = useState(null);
  const [anyPendingActions, setAnyPendingActions] = useState(false);

  const navigate = useNavigate();

  const latestInvoiceFailed = latestInvoice && latestInvoice.status === "open" && latestInvoice.attempt_count > 0 && latestInvoice.amount_remaining > 0;

  useEffect(() => {
    getInvalidContacts(setHasInvalidContacts);
    getPendingAutomations(setAutomationsCount);
    getPendingDesigns(setDesignsCount);
    getLatestInvoice(setLatestInvoice)
  }, []);

  useEffect(() => {
    setAnyPendingActions(hasInvalidContacts || automationsCount > 0 || designsCount > 0 || latestInvoiceFailed );
  }, [hasInvalidContacts, automationsCount, designsCount, latestInvoiceFailed]);

  const handleBadContactsClick = () => {
    navigate("/lists_and_contacts", {
      state: {
        applyInvalidScope: true,
        tabName: 'contacts'
      }
    });
  };

  const handleDesignsClick = () => {
    navigate("/designs", {
      state: {
        applyUnapprovedScope: true,
      }
    });
  };

  const handleFailedPaymentsClick = () => {
    navigate(`/accounts/${authApi.getCurrentAccountId()}/billing/invoices`, {
      state: {
        unpaidInvoices: true
      }
    });
  };

  const handleAutomationsClick = () => {
    navigate("/automations", {
      state: {
        tab: 'automation_runs',
        statuses: [
          { value: 'needs_approval', label: 'Needs Approval' },
        ]
      }
    });
  };

  return (
    <>
      { anyPendingActions ? (
        <div style={{marginBottom: "20px"}}>
          <div style={actionSectionStyle}>
            <h4 className="overview-header">Pending Actions</h4>
          </div>

          { designsCount > 0 &&
            <div style={{...actionSectionStyle, marginTop: "10px", height: "100px", borderLeft: "4px solid #488FFF" }}>
              <div style={{ display: "flex", alignItems: "center", flex: 2}}>
                <Icon as={MdOutlineFilter} size={"24px"} />
                <div className="pending-action-header">{designsCount === 1 ? "Design needs approval" : "Designs need approval"}</div>
              </div>
              <div className="pending-action-description">
                <div className="pending-action-description-text">{designsCount === 1 ? "There is 1 design that is pending your review" : `There are ${designsCount} designs that are pending your review`}</div>
                <div className="pending-action-description-detail">Provide feedback or approve for use</div>
              </div>

              <DopeButton
                props={{
                  onClick: handleDesignsClick,
                  buttonClass: "outlined-black",
                  label: "Review",
                  styles: actionButtonStyles
                }}
              />
            </div>
          }

          { hasInvalidContacts &&
            <div style={{...actionSectionStyle, marginTop: "10px", height: "100px", borderLeft: "4px solid #B40020" }}>
              <div style={{ display: "flex", alignItems: "center", flex: 2}}>
                <RemindOutlineIcon style={{color: "background: #292B2E", fontSize: "24px"}} />
                <div className="pending-action-header">Invalid Contacts</div>
              </div>
              <div className="pending-action-description">
                <div className="pending-action-description-text">There are invalid contacts in your contact lists</div>
                <div className="pending-action-description-detail">Review and update</div>
              </div>

              <DopeButton
                props={{
                  onClick: handleBadContactsClick,
                  buttonClass: "outlined-black",
                  label: "Review",
                  styles: actionButtonStyles
                }}
              />
            </div>
          }

          { latestInvoiceFailed &&
            <div style={{...actionSectionStyle, marginTop: "10px", height: "100px", borderLeft: "4px solid #B40020" }}>
              <div style={{ display: "flex", alignItems: "center", flex: 2}}>
                <RemindOutlineIcon style={{color: "background: #292B2E", fontSize: "24px"}} />
                <div className="pending-action-header">Your latest payment has failed</div>
              </div>
              <div className="pending-action-description">
                <div className="pending-action-description-text">Update your card</div>
                <div className="pending-action-description-detail">Add/update payment method and rerun payments to dispatch unsent mailers</div>
              </div>

              <DopeButton
                props={{
                  onClick: handleFailedPaymentsClick,
                  buttonClass: "outlined-black",
                  label: "Review",
                  styles: actionButtonStyles
                }}
              />
            </div>
          }

          {automationsCount > 0 &&
            <div style={{...actionSectionStyle, marginTop: "10px", height: "100px", borderLeft: "4px solid #FFB800" }}>
              <div style={{ display: "flex", alignItems: "center", flex: 2}}>
                <SendIcon style={{color: "background: #292B2E", fontSize: "24px"}} />
                <div className="pending-action-header">{automationsCount === 1 ? "Automation awaiting approval" : "Automations awaiting approval"}</div>
              </div>
              <div className="pending-action-description">
                <div className="pending-action-description-text">{automationsCount === 1 ? "There is 1 pending automation" : `There are ${automationsCount} pending automations`}</div>
                <div className="pending-action-description-detail">Review and approve</div>
              </div>

              <DopeButton
                props={{
                  onClick: handleAutomationsClick,
                  buttonClass: "outlined-black",
                  label: "Review",
                  styles: actionButtonStyles
                }}
              />
            </div>
          }

        </div>) : null }
    </>
  );
}

export default PendingActions;
