import React from 'react';
import ReactPlayer from 'react-player';
import './DopePlayer.scss'; // Make sure to create this SCSS file with your custom styles

const PlayIcon = (
  <svg width="134" height="133" viewBox="0 0 134 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1206_55097"  maskUnits="userSpaceOnUse" x="0" y="0" width="134" height="133">
    <rect x="0.5" width="133" height="133" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_1206_55097)">
    <path d="M53.1465 91.4374L91.9381 66.4999L53.1465 41.5624V91.4374ZM67.0006 121.917C59.3347 121.917 52.1305 120.462 45.3881 117.553C38.6458 114.643 32.7809 110.695 27.7934 105.707C22.8059 100.72 18.8574 94.8548 15.948 88.1124C13.0387 81.3701 11.584 74.1659 11.584 66.4999C11.584 58.8339 13.0387 51.6298 15.948 44.8874C18.8574 38.1451 22.8059 32.2801 27.7934 27.2926C32.7809 22.3051 38.6458 18.3567 45.3881 15.4473C52.1305 12.5379 59.3347 11.0833 67.0006 11.0833C74.6666 11.0833 81.8708 12.5379 88.6132 15.4473C95.3555 18.3567 101.22 22.3051 106.208 27.2926C111.195 32.2801 115.144 38.1451 118.053 44.8874C120.963 51.6298 122.417 58.8339 122.417 66.4999C122.417 74.1659 120.963 81.3701 118.053 88.1124C115.144 94.8548 111.195 100.72 106.208 105.707C101.22 110.695 95.3555 114.643 88.6132 117.553C81.8708 120.462 74.6666 121.917 67.0006 121.917ZM67.0006 110.833C79.377 110.833 89.86 106.538 98.4496 97.9489C107.039 89.3593 111.334 78.8763 111.334 66.4999C111.334 54.1235 107.039 43.6405 98.4496 35.051C89.86 26.4614 79.377 22.1666 67.0006 22.1666C54.6243 22.1666 44.1413 26.4614 35.5517 35.051C26.9621 43.6405 22.6673 54.1235 22.6673 66.4999C22.6673 78.8763 26.9621 89.3593 35.5517 97.9489C44.1413 106.538 54.6243 110.833 67.0006 110.833Z" fill="white"/>
    </g>
  </svg>
);

const DopePlayer = ({ url, ...rest }) => {
  return (
    <div className="dope-player-wrapper">
      <ReactPlayer
        url={url}
        className="react-player"
        width='100%'
        height='100%'
        light
        playIcon={PlayIcon}
        controls
        playing
        {...rest}
      />
    </div>
  );
};

export default DopePlayer;
