import React, { useState } from 'react';
import DopeManagement from "../ui/DopeManagement";
import DopeButton from '../ui/DopeButton';
import DopeApi from "../services/DopeApi";

const batchApi = new DopeApi('batch');

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, filterable: true },
  { type: 'timestamp', dataKey: 'created_at', label: 'Date', sortable: true, filterable: true },
  { type: 'pill', dataKey: 'effective_status', pillTypeKey: 'status', label: 'Status', filterable: true },
  // { type: 'text', dataKey: 'status_message', label: 'Status Message', fullText: true },
  { type: 'download_files', dataKey: 'files', label: 'Files' },
  // { type: 'text_list', dataKey: 'error_purchase_summary_text_list', label: 'Failed Purchases' },
  // { type: 'text_list', dataKey: 'paid_purchase_summary_text_list', label: 'Successful Purchases' },
  // { type: 'text_list', dataKey: 'successful_mail_piece_counts_text_list', label: 'Successful Delivery' },
  // { type: 'text_list', dataKey: 'failed_mail_piece_counts_by_status_text_list', label: 'Failed Delivery' },
];

const scopeOptions = [

];

export function BatchForm({ onFinish }) {
  const [creating, setCreating] = useState(false);

  const handleCreate = async () => {
    setCreating(true);
    try {
      await batchApi.create({ anything: "something" }); // api needs an obj with something in it...
      onFinish(true);
    } catch (errors) {
      console.log(errors);
    } finally {
      setCreating(false);
    }
  };

  return (
    <div>
      <div className="bold header-6">Hello there! Are you sure you want to do this?</div>
      <div className="bold">This action cannot be undone!</div>
      <div className="bold">Cards will be charged!</div>
      <div className="bold">All ready to process mail pieces will be processed!</div>
      <div className="split margin-top">
        <DopeButton disabled={creating} className="filled" onClick={() => onFinish(false)}>Nope! I don't know what I am doing so I won't</DopeButton>
        <DopeButton disabled={creating} onClick={handleCreate}>Yes, Do it</DopeButton>
      </div>
    </div>
  )
}

function BatchManagement() {
  return (
    <DopeManagement
      modelName="batch"
      modelForm={BatchForm}
      columns={columns}
      scopeOptions={scopeOptions}
      noDefaults
      tableProps={{
        rowHeight: 125,
        wordWrap: "break-word"
      }}
    />
  );
}

export default BatchManagement;
