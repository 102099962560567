import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import DopeApi from "../services/DopeApi";
import { ImagesGrid } from 'polotno/side-panel';
import DopeUploadButton from '../ui/DopeUploadButton';
import { useMediaUpload } from './mediaUploadSlice';

const mediaApi = new DopeApi('media_upload');

const CustomShapesPanel = observer(({ store }) => {
  const [shapes, setShapes] = useState([]);
  const [media, setMedia] = useState(null);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [loading, setLoading] = useState(false);

  const { mediaUpload, actions } = useMediaUpload();

  const loadShapes = (page, reset = false) => {
    setLoading(true);
    mediaApi.query({
      per_page: 25,
      scopes: [{ name: 'shape' }],
      page,
    })
      .then(data => {
        setShapes([...(reset ? [] : shapes), ...data.media_uploads]);
        setLastPage(Math.ceil(data.total / data.per_page))
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    loadShapes(page);
  }, [page]);

  const uploadMedia = async (media) => {
    const now = Date.now();

    console.log(media);

    const file = new File([media], `media_shape_${now}.svg`, { type: media.type });

    await actions.save({
      media: file,
      media_type: 'shape'
    });

    loadShapes(1, true);
  }

  return (
    <div className='photos-tab-container'>
      <div className='photo-list'>
        <DopeUploadButton
          name="media"
          value={media}
          values={{media: null}}
          onChange={({ media }) => uploadMedia(media)}
          label='Upload Shape'
          accept={{
            'image/svg': ['.svg']
          }}
          />
        <ImagesGrid
          images={shapes}
          isLoading={loading}
          getPreview={(shape) => shape.media_url }
          getCredit={(shape) => {console.log(shape);}}
          crossOrigin={null}
          loadMore={() => {
            if (page < lastPage) {
              setPage(page + 1);
            }
          }}
          onSelect={async (image, pos, element) => {
          const { media_url } = image;
          const temp = new Image();
          temp.onload = async () => {
            let { width, height } = temp;
            const isSVG = media_url.indexOf('svg+xml') >= 0 || media_url.indexOf('.svg') >= 0;

            const type = isSVG ? 'svg' : 'image';

            if (element && element.type === 'svg' && !element.locked && type === 'image') {
              element.set({ maskSrc: media_url });
              return;
            }

            if (element && element.type === 'image' && !element.locked && type === 'image') {
              element.set({ src: media_url, crossOrigin: null });
              return;
            }

            const scale = Math.min(store.width / width, store.height / height, 1);
            width = width * scale;
            height = height * scale;

            const x = (pos?.x || store.width / 2) - width / 2;
            const y = (pos?.y || store.height / 2) - height / 2;


            store.activePage?.addElement({
              type,
              src: media_url,
              x,
              y,
              width,
              height,
              crossOrigin: null,
            });
          };
          temp.src = media_url;

          }}
        />
      </div>
    </div>
  );
});

export default CustomShapesPanel;
