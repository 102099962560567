import React, { useState } from "react";

import dopeClient from "../services/dopeClient";

import { Input, InputGroup, Loader } from "rsuite";
import SearchIcon from '@rsuite/icons/Search';
import { Icon } from '@rsuite/icons';
import { MdClose } from "react-icons/md";

const isValidZipCode = (zipCode) => {
  const zipCodePattern = /^\d{5}$/;
  return zipCodePattern.test(zipCode);
};

const fetchZipCodeCoordinates = async (zipCode) => {
  const url = `zip_codes/${zipCode}/geo_point`;
  const resp = await dopeClient.get(url);

  return resp.data.geo_point;
};

const EddmZipCodeInput = ({ zipCode, onChange, loading = false }) => {

  const [nextZipCode, setNextZipCode] = useState(zipCode || '');
  const [fetchingCoordinates, setFetchingCoordinates] = useState(false);
  const [error, setError] = useState(null);

  const handleZipCodeChange = async (value) => {
    setError(null);
    setNextZipCode(value);
    if (isValidZipCode(value)) {
      setFetchingCoordinates(true);
      const coordinates = await fetchZipCodeCoordinates(value);
      if (coordinates) {
        onChange({ zip_code: value, ...coordinates });
      } else {
        setError('Could not fetch coordinates for zip code');
      }
      setFetchingCoordinates(false);
    }
  };

  const loadingRoutesOrFetchingCoordinates = loading || fetchingCoordinates;

  return (
    <div>
      <label className="label">Zip Code</label>

      <InputGroup>
        {loadingRoutesOrFetchingCoordinates && <InputGroup.Addon><Loader/></InputGroup.Addon>}

        <Input
          name="zip_code"
          value={nextZipCode}
          onChange={value => handleZipCodeChange(value)}
          placeholder="Enter Zip Code"
          disabled={loadingRoutesOrFetchingCoordinates}
        />

        {!!zipCode ? (
          <InputGroup.Button>
            <Icon as={MdClose} onClick={() => setNextZipCode('')} />
          </InputGroup.Button>
          ) : (
          <InputGroup.Addon>
            <SearchIcon/>
          </InputGroup.Addon>
        )}
      </InputGroup>

      {nextZipCode.length >= 5 && !isValidZipCode(nextZipCode) && <div className="color-text-error-red">Invalid Zip Code</div>}
      {error && <div className="color-text-error-red">{error}</div>}
    </div>
  );
};

export default EddmZipCodeInput;
