import { useRef, useEffect } from 'react';

import EditorJS from '@editorjs/editorjs';

import Embed from "@editorjs/embed";
import Hyperlink from "editorjs-hyperlink";
import List from "@editorjs/list";
// import Quote from "@editorjs/quote";
import Paragraph from "@editorjs/paragraph";
import Header from "@editorjs/header";
import Image from '@editorjs/image';

import './DopeRichTextEditor.scss';
import { useMediaUpload } from '../editor/mediaUploadSlice';

const DopeRichTextEditor = ({
  value = {},
  onChange = () => {},
}) => {
  const { mediaUpload, actions } = useMediaUpload();

  const ref = useRef();

  const EDITOR_CONFIG = {
    header: {
      class: Header,
      config: {
        levels: [2, 3, 4, 5, 6],
        defaultLevel: 2
      }
    },

    hyperlink: {
      class: Hyperlink,
      config: {
        shortcut: 'CMD+L',
        target: '_blank',
        rel: 'nofollow',
        availableTargets: ['_blank', '_self'],
        availableRels: ['author', 'noreferrer'],
        validate: false,
      }
    },

    paragraph: {
      class: Paragraph,
      inlineToolbar: true
    },

    image: {
      class: Image,
      config: {
        features: {
          // caption: false,
        },
        uploader: {
          uploadByFile: async (file) => {
            try {
              const result = await actions.save({ media: file, media_type: 'image' });
              console.log(result);
              return {
                success: 1,
                file: {
                  url: result.payload.media_url,
                }
              }
            } catch (error) {
              console.log(error);
              return {
                success: 0,
                file: {
                  url: '',
                },
              }
            }
          }
        }
      }

    },

    embed: Embed,

    list: {
      class: List,
      inlineToolbar: true
    },

    // quote: Quote
  };

  useEffect(() => {
    if (!ref.current) {
      const editor = new EditorJS({
        holder: 'rich-text-editor',
        tools: EDITOR_CONFIG,
        data: typeof value === 'string' ? JSON.parse(value) : value,
        async onChange(api, event) {
          const data = await api.saver.save();
          onChange(data);
        },
      })

      ref.current = editor;
    }


    return () => {
      if (ref.current && ref.current.destroy) {
        ref.current.destroy();
      }
    };
  }, []);

  return (
    <div className='dope-rich-text-editor'>
      <div id='rich-text-editor'></div>
    </div>
  );

};

export default DopeRichTextEditor;
