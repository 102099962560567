import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import { useSlideover } from "../slideovers/slideoverSlice";
import DopeListPage from "../ui/DopeListPage";

import DopeButton from "../ui/DopeButton";

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, },
  { type: 'stacked_text', dataKey: ['name', 'description'], label: 'Name', sortable: true, flexGrow: 2 },
  { type: 'timestamp', dataKey: 'created_at', label: 'Creation Date', sortable: true },
  { type: 'timestamp', dataKey: 'updated_at', label: 'Last Update', sortable: true },
  { type: 'button', dataKey: 'edit', width: 75, label: 'Edit', sortable: false },
  { type: 'button', dataKey: 'archive', width: 75, label: 'Archive', sortable: false },
];

const api = new DopeApi('slideover');


const PulseSlideoverTable = () => {
  const { slideover, actions } = useSlideover();

  const {
    error,
    tableProps,
    paginationProps,
    onSearchClear,
    onSearchInput,
    setFilters,
    filters,
    reload,
    setScopes,
    scopes,
  } = useQuery2({
    api,
    initialSortColumn: 'updated_at',
    initialSearchScope: 'name',
  });


  const navigate = useNavigate();

  const onRowClick = (row) => {
    navigate(`/pulse/slideovers/${row.id}/edit`);
  };

  return (
    <div className="page">
      <div className="margin-bottom" style={{display: 'flex', gap: '2rem', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <div className="header-2">Learning Slideovers</div>
        <DopeButton
          props={{
            buttonClass: "filled",
            label: "Add new slideover",
            onClick: () => navigate('/pulse/slideovers/new/edit'),
          }}
        />
      </div>

      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
        onRowClick={onRowClick}
        onClickEdit={(row) => navigate(`/pulse/slideovers/${row.id}/edit`)}
        onClickArchive={(row) => actions.archive(row)}
        />
    </div>
  );
}

export default PulseSlideoverTable;
