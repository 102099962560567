import React from "react";
import { Icon } from "@rsuite/icons";
import { MdOutlineContactMail, MdOutlineCalendarToday } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa";

import ContactsListsEdit from "./ContactListsEdit";
import MailPieceListItem from "../mailPieces/MailPieceListItem";

import { useContact } from "./contactSlice";
import { useContactUI } from "./contactUISlice";
import DopeButton from "../ui/DopeButton";
import {DopeFormGroup} from "../ui/DopeForm";
import {Input, InputGroup} from "rsuite";
import {formatToLocaleDateString} from "../utils/date";

const contactInformationStyles = {
  width: "45%",
  display: "flex",
  flexDirection: "column",
  fontWeight: 400,
  fontSize: "16px",
  color: "#6F6F6F",
  border: "1px solid #EAECEF",
  boxShadow: "0px 6px 6px 0px #BABABA40",
  borderRadius: "8px",
  padding: "20px",
  height: "fit-content",
}

const ContactDetailsPage = () => {
  const [contact, contactActions] = useContact();
  const [contactUI, contactUIActions] = useContactUI();

  return (
    <div style={{display: "flex", justifyContent: "space-around"}}>
      <div style={contactInformationStyles}>
        <div style={{  textAlign: "center" }}>
            <div className="header-2 margin-4-b">{contact.full_name}</div>
            <div className="help-text large margin-16-b">{contact.company_name}</div>
            <div>{contact.address_1} {contact.address_2}</div>
            <div>{contact.city_state_zip}</div>
            <DopeButton
              props={{
                onClick: () => contactUIActions.setDrawerContactId(contact.id),
                buttonClass: "filled-black",
                label: "Edit Contact",
                styles: { marginTop: "20px", width: "127px" }
              }}
            />
        </div>

        <hr style={{width: "100%", margin: "32px 0 24px", color: "#EAECEF"}} />

        <div style={{fontSize: "16px", fontWeight: 600, color: "#292B2E", marginBottom: "16px"}}>Contact Details</div>
        <ContactsListsEdit />

        <DopeFormGroup
          input={{
            inputType: "tags",
            name: "taggings",
            props: { modelName: "contact", label: "Contact Tags" }
          }}
          values={contact}
          onChange={contactActions.addTagToContact}
        />

        <div>
          <div style={{ fontSize: "16px", fontWeight: 600, color: "#292B2E", margin: "16px 0 8px"}}>Customer Journey</div>

          <label className="rs-form-control-label">Creation date in DOPE</label>
          <InputGroup inside style={{marginBottom: "8px"}}>
            <Input readOnly value={formatToLocaleDateString(contact.created_at, true)} />
            <InputGroup.Addon style={{ borderLeft: "1px solid #F3F3F3" }}>
              <Icon as={MdOutlineCalendarToday} />
            </InputGroup.Addon>
          </InputGroup>

          <label className="rs-form-control-label">Lead Date</label>
          <InputGroup inside style={{marginBottom: "8px"}}>
            <Input readOnly value={contact.became_lead_at ? formatToLocaleDateString(contact.became_lead_at, true) : ""} placeholder="Date will be updated when contact becomes a lead" />
            <InputGroup.Addon style={{ borderLeft: "1px solid #F3F3F3" }}>
              <Icon as={MdOutlineCalendarToday} />
            </InputGroup.Addon>
          </InputGroup>

          <label className="rs-form-control-label">Lead Value</label>
          <InputGroup inside style={{marginBottom: "8px"}}>
            <InputGroup.Addon style={{ borderRight: "1px solid #F3F3F3" }}>
              <Icon as={FaDollarSign} />
            </InputGroup.Addon>
            <Input style={{ paddingLeft: 12 }} readOnly value={contact.lead_value || 0} />
          </InputGroup>

          <label className="rs-form-control-label">Customer Date</label>
          <InputGroup inside style={{marginBottom: "8px"}}>
            <Input readOnly value={contact.became_client_at ? formatToLocaleDateString(contact.became_client_at, true) : ""} placeholder="Date will be updated when contact becomes a customer" />
            <InputGroup.Addon style={{ borderLeft: "1px solid #F3F3F3" }}>
              <Icon as={MdOutlineCalendarToday} />
            </InputGroup.Addon>
          </InputGroup>

          <label className="rs-form-control-label">Customer Value</label>
          <InputGroup inside style={{marginBottom: "8px"}}>
            <InputGroup.Addon style={{ borderRight: "1px solid #F3F3F3" }}>
              <Icon as={FaDollarSign} />
            </InputGroup.Addon>
            <Input style={{ paddingLeft: 12 }} readOnly value={contact.client_value || 0} />
          </InputGroup>

        </div>
      </div>

      <div style={{width: "45%"}}>
        <div style={{marginTop: "10px"}}>
          <h5>Mail Pieces</h5>
          { contact.mail_pieces.map(mailPiece => (
            <MailPieceListItem key={mailPiece.id} mailPiece={mailPiece} />
          ))}

          { contact.mail_pieces.length === 0 && (
            <div style={{textAlign: "center", marginTop: "20px", fontSize: "16px", fontWeight: 400, color: "#6F6F6F", padding: "40px"}}>
              <Icon as={MdOutlineContactMail} style={{fontSize: "64px", color: "#EAECEF", marginBottom: "8px"}} />
              <p>We haven’t sent any mail to this contact yet. Once we’ve sent them mail you’ll be able to see all their mailers here!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactDetailsPage;
