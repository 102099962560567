import React from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

// TODO: how do we handle latitude and longitude when an address is changed?
const initialContact = {
  first_name: '',
  last_name: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip: '',
  zip_4: '',
  country: '',
}

const contactInputs = [
  {
    inputType: "belongsTo",
    name: "account_id",
    props: {
      label: "Account",
      belongsToModelName: "account"
    }
  },
  {
    inputType: 'text',
    name: 'first_name',
    props: {
      label: 'First Name',
    }
  },
  {
    inputType: 'text',
    name: 'last_name',
    props: {
      label: 'Last Name',
    }
  },
  {
    inputType: 'text',
    name: 'address_1',
    props: {
      label: 'Address 1',
    }
  },
  {
    inputType: 'text',
    name: 'address_2',
    props: {
      label: 'Address 2',
    }
  },
  {
    inputType: 'text',
    name: 'city',
    props: {
      label: 'City',
    }
  },
  {
    inputType: 'text',
    name: 'state',
    props: {
      label: 'State',
    }
  },
  {
    inputType: 'text',
    name: 'zip',
    props: {
      label: 'Zip',
    }
  },
  {
    inputType: 'text',
    name: 'zip_4',
    props: {
      label: 'Zip 4',
    }
  },
  {
    inputType: 'text',
    name: 'country',
    props: {
      label: 'Country',
    }
  },
];

const ContactsForm = (props) => {
  return (
    <ModelForm
      modelName="contact"
      initialResource={initialContact}
      inputs={contactInputs}
      {...props}
    />
  );
}

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, filterable: true },
  { type: 'text', dataKey: 'account_name', label: 'Account' },
  { type: 'text', dataKey: 'first_name', label: 'First Name' },
  { type: 'text', dataKey: 'last_name', label: 'Last Name' },
  { type: 'text', dataKey: 'full_address', label: 'Full Address' },
  { type: 'text', dataKey: 'created_by', label: 'Created By' },

  { type: 'text', dataKey: 'account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
];

const scopeOptions = [];

function ContactsManagement() {
  return (
    <DopeManagement
      modelName="contact"
      modelForm={ContactsForm}
      columns={columns}
      scopeOptions={scopeOptions}
    />
  );
}

export default ContactsManagement;
