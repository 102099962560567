import React from "react";
import { Divider } from "rsuite";
import Cents from "../ui/Cents";

const InvoiceSummaryTable = ({ invoice, trimString }) => {
  return (
    <div style={{ backgroundColor: "rgba(188, 210, 225, 0.1)", borderRadius: "8px", padding: "20px"}}>
      <div>
        <div style={{ fontWeight: 600, fontSize: "18px", color: "#292B2E", marginBottom: "10px"}}>Summary</div>
        <div className="help-text margin-bottom small">{invoice.description}</div>
        <table width="100%">
          <thead>
          <tr style={{ fontSize: "11px", fontWeight: 700, color: "#8793A6", height: "30px"}}>
            <th style={{width: "40%", textAlign: "left"}}>Item</th>
            <th style={{width: "20%", textAlign: "right"}}>Quantity</th>
            <th style={{width: "20%", textAlign: "right"}}>Unit Price</th>
            <th style={{width: "20%", textAlign: "right"}}>Amount</th>
          </tr>
          </thead>
          <tbody>
          {invoice.lines.data.map((line_item, index) => {
            return (
              <tr key={index} style={{ fontSize: "14px", fontWeight: 400, color: "#6F6F6F"}}>
                <td style={{width: "40%", textAlign: "left"}}>{trimString(line_item.description, 30)}</td>
                <td style={{width: "20%", textAlign: "right"}}>{line_item.quantity}</td>
                <td style={{width: "20%", textAlign: "right"}}>{line_item.price.unit_amount ? (<Cents>{line_item.price.unit_amount}</Cents>) : "-"}</td>
                <td style={{width: "20%", textAlign: "right"}}><Cents>{line_item.amount}</Cents></td>
              </tr>
            );
          })}
          </tbody>
        </table>

        <Divider />

        <div style={{display: "flex", justifyContent: "space-between", fontWeight: 600, fontSize: "14px", color: "#6F6F6F"}}>
          <div>Total</div>
          <div><Cents>{invoice.amount_due}</Cents></div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceSummaryTable;
