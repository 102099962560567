import React from "react";

import { useNavigate, useParams } from 'react-router-dom';

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import QRCodeDrawer from "../editor/QRCodeDrawer";
import { useQRCode } from "../editor/qrCodeSlice";
import ScansDrawer from "./ScansDrawer";
import { useLocation } from "react-router-dom";
import ChangeLogDrawer from "./ChangeLogDrawer";

const api = new DopeApi('qr_codes');

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, flexGrow: 0 },
  { type: 'image_url', dataKey: 'code_url', label: '',flexGrow: 0 },
  { type: 'text', dataKey: 'name', label: 'Name', sortable: true, flexGrow: 1 },
  { type: 'date', dataKey: 'created_at', label: 'Created Date', sortable: true },
  { type: 'text', dataKey: 'scans_count', label: '# of Scans', sortable: true },
  { type: 'event_time', dataKey: 'last_scanned_at', label: 'Latest Scan', sortable: true },
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'qr_code', flexGrow: 1 }
];

const QRCodeTable = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { qrCode, actions } = useQRCode(id);

  const {
    error,
    tableProps,
    paginationProps,
    filters,
    setFilters,
    reload,
    onSearchClear,
    onSearchInput,
  } = useQuery2({
    api,
    initialSearchScope: "name",
  });

  const openScansDrawer = !!id && location.pathname.includes('scans');

  return (
    <>
      <DopeListPage
        paginationProps={paginationProps}
        tableProps={tableProps}
        columns={columns}
        error={error}
        filters={filters}
        setFilters={setFilters}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
      />

      <QRCodeDrawer
        open={!!id && !location.pathname.includes('scans') && !location.pathname.includes('changelog')}
        id={id}
        onClose={() => {
          actions.resetToInitial();
          navigate('/qr_codes');
        }}
        onConfirm={async (svg) => {
          const code = new File([svg], `media_qr_code_${Date.now()}.svg`, { type: 'image/svg+xml' });
          await actions.save({ code });
          actions.resetToInitial();
          reload();
          navigate('/qr_codes');
        }}
      />

      {openScansDrawer && <ScansDrawer
        open={openScansDrawer}
        scannable={qrCode}
        scannableId={id}
        scope="for_qr"
        onClose={() => {
          actions.resetToInitial();
          navigate('/qr_codes');
        }}
      />}

      <ChangeLogDrawer
        open={!!id && location.pathname.includes('changelog')}
        qrCode={qrCode}
        onClose={() => {
          actions.resetToInitial();
          navigate('/qr_codes');
        }}
      />



    </>
  );
};

export default QRCodeTable;
