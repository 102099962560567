import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import { asNestedDestroyable } from "../services/createModelSelectors";

const [designTemplateSlice, asyncActions] = createModelSlice('design_template', {
  updateMailTemplate: ({ current: designTemplate }, { payload: update }) => {
    Object.assign(designTemplate.mail_template, update);
  },
});

export default designTemplateSlice.reducer;

const designToParams = (designTemplate, prevDesignTemplate) => {
  return {
    ...designTemplate,
  };
};

const designTemplateSelector = state => {
  return state.designTemplate.current;
}

const derivedDesignAttrsSelector = createSelector([designTemplateSelector], (designTemplate) => {
  if (!designTemplate) return {};

  const derivedUpdates = {};

  return derivedUpdates;
});

export function useDesignTemplate(designTemplateId) {
  const dispatch = useDispatch();
  const designTemplate = useSelector(designTemplateSelector);
  const derivedDesignTemplateAttrs = useSelector(derivedDesignAttrsSelector);
  const prevDesignTemplate = useSelector(state => state.designTemplate.prev);
  const isSaving = useSelector(state => state.designTemplate.isSaving);

  const actions = wrapAll({
    ...designTemplateSlice.actions,
    ...asyncActions,
    save: (additionalParams = {}) => {
      const { files, json_template, ...extras } = additionalParams;
      const params = { ...designToParams(designTemplate, prevDesignTemplate), ...extras };

      const template = { ...(files ?? {}), json_template: json_template ?? null };


      delete params.errors;

      return asyncActions.save({...params, ...template });
    },
    approve: () => {
      const params = designToParams(designTemplate, prevDesignTemplate);
      return asyncActions.save({ ...params, status: 'approved' });
    },
  }, dispatch);

  useEffect(() => {
    const shouldGet = designTemplateId && (!designTemplate || designTemplate.id !== designTemplateId);
    if (shouldGet) {
      actions.get(designTemplateId);
    }
  }, [designTemplateId, designTemplate?.id]);



  return {
    designTemplate,
    derivedDesignTemplateAttrs,
    actions,
    isSaving,
  };
};

