import React from 'react';

import DopePill from '../ui/DopePill';
import DelayedJobProgress from '../ui/DelayedJobProgress';

const ListStatusCell = ({ rowData, reload }) => {
  const { delayed_job_id, status, effective_status } = rowData;
  const shouldBackgroundPull = !!delayed_job_id && ['queued', 'processing'].includes(status);

  if (shouldBackgroundPull) {
    return (
      <DelayedJobProgress
        id={delayed_job_id}
        onSuccess={reload}
        completeStatus="success"
        runningStatus="processing"
      />
    );
  }

  return <DopePill text={effective_status || 'success'} />;
};

export default ListStatusCell;
