import React from 'react';
import reduce from 'lodash/reduce';
import isObject from 'lodash/isObject';

const DopeErrorMessage = ({ errorMessage }) => {
 if (!errorMessage) return null;

  return (
   <div style={{ color: "red" }}>
    { errorMessage }
  </div>
 )
}
const DopeErrorMessages = ({ errorMessages }) => {

  if (!errorMessages) return null;

  let errorMessagesArray;

  if (Array.isArray(errorMessages)) {
    errorMessagesArray = errorMessages;
  } else if (isObject(errorMessages)) {
    errorMessagesArray = reduce(errorMessages, (acc, value, key) => {
      let keyDisplay = key === "base" ? "" : `${key}: `;
      return [...acc, `${keyDisplay} ${value.join(', ')}`]
    }, []);
  } else {
    errorMessagesArray = [errorMessages];
  }

  return errorMessagesArray.map((errorMessage, i) => {
    return (
      <DopeErrorMessage key={i} errorMessage={errorMessage} />
    );
  })
}

export default DopeErrorMessages;
