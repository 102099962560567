import React, { useEffect, useState } from "react";
import { Drawer } from "rsuite";

const DopeDrawer = ({ open, onClose, header, bodyContent, children, className, subheader, actions, size='sm', ...rest }) => {

  const [needsScroll, setNeedsScroll] = useState(false);

  const drawerBodyRef = React.useRef(null);

  useEffect(() => {
    if (!drawerBodyRef.current) return;

    const currentDrawerBody = drawerBodyRef.current;

    if (currentDrawerBody.scrollHeight > currentDrawerBody.clientHeight) {
      setNeedsScroll(true);
    }
  }, [open]);

  return (
    <>
      <Drawer open={open} onClose={onClose} className={className} size={size} {...rest}>
        <Drawer.Body ref={drawerBodyRef} >
          {header && <h5>{header}</h5>}
          {subheader && <p><small>{subheader}</small></p>}
          {React.cloneElement(bodyContent || children, { onClose: onClose })  }
        </Drawer.Body>
        {actions && <Drawer.Actions className={needsScroll ? "overflow-actions" : ''}>{actions}</Drawer.Actions>}
      </Drawer>
    </>
  );
}

export default DopeDrawer;
