import React from 'react';
import { Modal } from 'rsuite';

import GuidelinesImage from "../images/design-guidelines.jpg";

const DesignGuidelinesModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} size="md">
      <Modal.Header>
        <Modal.Title>Postcard Design Guidelines</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          style={{ width: "100%" }}
          src={GuidelinesImage}
          alt="Postcard Design Guidelines"
        />
      </Modal.Body>
    </Modal>
  );
};


export default DesignGuidelinesModal;
