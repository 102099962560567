import React, { useEffect } from "react";
import { useParams, useLocation, useNavigate, Outlet, matchPath } from 'react-router-dom';

import { useConversionReport } from './conversionReportSlice';

import DopePageLoader from '../ui/DopePageLoader';
import DopeButton from "../ui/DopeButton";
import useDopePageTabs from '../ui/useDopePageTabs';

const pages = [
  {
    name: 'Matchback setup',
    path: '/conversion_reports/:id/setup',
    isValid: (report) => {
      return !!report.name;
    },
  },
  {
    name: 'Refine Report',
    path: '/conversion_reports/:id/filters',
    isValid: (report) => {
      return !!report.name;
    },
  }
];

const ConversionReportFormPages = () => {
  const { conversionReport, actions, isSaving } = useConversionReport();
  const navigate = useNavigate();

  const {
    PageTabs,
    BackLink,
    navigateForward,
    isLastPage,
    isValid,
  } = useDopePageTabs({ pages, resource: conversionReport, exitUrl: '/conversion_reports', exitText: 'Back to all Reports' });

  const handleSaveAndRedirect = async () => {
    const actionResult = await actions.save();
    navigate(`/conversion_reports/${actionResult.payload.id}/show`);
  };

  const saveButton = (
    <DopeButton
      className="save-btn"
      loading={isSaving}
      props={{
        buttonClass: isLastPage ? "filled" : "text-button",
        label: isLastPage ? "Save" : "Save as draft",
        onClick: isLastPage ? handleSaveAndRedirect : actions.save,
      }}
    />
  );

  const continueButton = (
    <DopeButton
      className="next-btn"
      disabled={!isValid}
      props={{
        buttonClass: "filled",
        label: "Continue",
        onClick: navigateForward,
      }}
    />
  );

  const footer = (
    <div className="space-between margin">
      {isLastPage ? <div></div> : saveButton}
      {isLastPage ? saveButton : continueButton}
    </div>
  );

  return (
    <div>
      <BackLink className="margin" />

      <div className="page">
        <PageTabs />
        <Outlet />
      </div>

      {footer}
    </div>
  );
};

const ConversionReport = () => {
  const { id } = useParams();
  const location = useLocation();
  const { conversionReport, actions, isSaving } = useConversionReport();
  const isFormPath = pages.some(page => matchPath({ path: page.path }, location.pathname));

  useEffect(() => {
    if (!conversionReport || conversionReport.id !== id) {
      actions.get(id);
    }

    return actions.resetToInitial;
  }, []);

  if (!conversionReport) {
    return <DopePageLoader />;
  }

  if (isFormPath) {
    return (<ConversionReportFormPages />);
  } else {
    const saveButton = (
      <DopeButton
        className="save-btn"
        loading={isSaving}
        props={{
          buttonClass: "filled",
          label: "Save",
          onClick: () => actions.save(),
        }}
      />
    );

    return (
      <div>
        <div className="page">
          <Outlet />
        </div>

        <div className="space-between margin">
          <div></div>
          {saveButton}
        </div>
      </div>
    );
  }
};

export default ConversionReport;

