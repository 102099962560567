import DopeApi from "../services/DopeApi";
import DopeDrawer from "../ui/DopeDrawer";
import DopeTable from "../ui/DopeTable";

import useQuery, { useQuery2 } from "../hooks/useQuery";
import { useEffect } from "react";
import DopeLoader from "../ui/DopeLoader";

const api = new DopeApi("scans");

const columns = [
  { type: 'event_time', dataKey: 'created_at', label: 'Date', },
  { type: 'custom', dataKey: 'city', label: 'Approximate Location', render: (rowData) => {
    if (!rowData.city || !rowData.state) {
      return 'No data available';
    }
    return `${rowData.city}, ${rowData.state}`;
  }},
];

const ScansDrawer = ({ open, onClose, scannable, scannableId, scope }) => {
  const { tableProps } = useQuery2({
    api,
    initialScopes: [
      { name: scope, args: scannableId },
    ],
  });

  return (
    <DopeDrawer
      className="qr-code-scans-drawer"
      open={open}
      onClose={onClose}
      header={scannable?.name ?? ""}
    >
        <div>
          {!scannable && <DopeLoader />}
          {scannable && (
            <>
              <p><small>{scannable.scans_count || 0} total scans</small></p>
              <DopeTable
                {...tableProps}
                columns={columns}
              />
            </>
          )}
        </div>
    </DopeDrawer>
  );
};

export default ScansDrawer;
