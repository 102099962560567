import React from "react";
import { Icon } from "@rsuite/icons";
import './Cannot.scss';

const CannotInfoBox = ({ icon, body, classes }) => {
  return (
    <div className={`background-blue-grey-shade-light border-radius-8 pad-row margin-8-b flex row ${classes}`}>
      <div className="color-text-blue margin-8-r"><Icon as={icon} style={{fontSize: "24px"}}/></div>
      <div>{body}</div>
    </div>
  );
}

export default CannotInfoBox;
