import React, { useEffect } from 'react';

import { useParams, Outlet } from 'react-router-dom';

import DopeContentPage from '../ui/DopeContentPage';
import DopePlaceholder from '../ui/DopePlaceholder';
import { useList } from './listSlice';

const List = () => {
  const { id } = useParams();

  const [list, actions] = useList();

  useEffect(() => {
    if (!list) {
      actions.get(id);
    }

    return actions.resetToInitial;
  }, []);

  if (!list) {
    return <DopeContentPage
        tabComponents={[]}
        actionButtons={[]}
        pageTitle={'Gathering list information...'}
        pageContent={<DopePlaceholder type="grid" rows={10} columns={4} />}
      />
  }

  return <Outlet />;
}

export default List;
