import React from 'react';
import Select from 'react-select';
import { useIntegrations } from "../integrationsSlice";
import humanizeKey from '../util/humanizeKey';
import './SubscriptionFieldMap.scss';

const REQUIRED_MAP_FIELDS = ['address1', 'city', 'state', 'firstName', 'lastName'];

// TODO, chunk entries instead of this pushing to array (ai made it...)

const SubscriptionFieldMap = ({ header }) => {
  const {
    subscription,
    updateSubscription,
    dataFields,
    fetchingCDMMap,
  } = useIntegrations();

  const { cdm_map } = subscription;
  const isLoading = fetchingCDMMap;

  const handleChange = (selectedOption, commonField) => {
    updateSubscription({ cdm_map: { ...cdm_map, [commonField]: selectedOption?.value } });
  };

  const cdmEntries = Object.entries(cdm_map);
  const cdmEntriesCol1 = cdmEntries.slice(0, Math.ceil(cdmEntries.length / 2));
  const cdmEntriesCol2 = cdmEntries.slice(Math.ceil(cdmEntries.length / 2));

  const renderSelectColumn = (entries) => {
    const fieldInputs = entries.map(([commonField, integrationField], index) => {
      const selectedOption = dataFields.find(
        (field) => field.value === integrationField
      );

      const isRequired = REQUIRED_MAP_FIELDS.includes(commonField);

      return (
        <div key={commonField} className="object-map__field">
          <label htmlFor={commonField} className="object-map__label">
            {isRequired && '*'} {humanizeKey(commonField)}
          </label>
          <div className="object-map__equals">=</div>
          <Select
            id={commonField}
            value={selectedOption}
            options={dataFields}
            onChange={(selectedOption) =>
              handleChange(selectedOption, commonField)
            }
            className="object-map__select"
            isLoading={isLoading}
            isClearable={!isRequired}
          />
        </div>
      );
    });

    return (
      <div className="object-map__column">{fieldInputs}</div>
    );
  };

  return (
    <>
      {header || <div className="subscription-header">Map Your Data</div>}
      <div className="object-map">
        <div className="object-map__content">
          {renderSelectColumn(cdmEntriesCol1)}
          {renderSelectColumn(cdmEntriesCol2)}
        </div>
      </div>
    </>
  );
};

export default SubscriptionFieldMap;
