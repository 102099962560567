import React, {useEffect, useState} from "react";

import DopeTabs from "../ui/DopeTabs";
import DopeButton from "../ui/DopeButton";
import CreditPlanModal from "./CreditPlanModal";
import CreditPlansTable from "./CreditPlansTable";
import AutomaticCreditsTable from "./AutomaticCreditsTable";
import IssuedCreditsTable from "./IssuedCreditsTable";
import AllInvoicesTable from "./AllInvoicesTable";
import PaymentsTable from "./PaymentsTable";
import RefundsTable from "./RefundsTable";

const PaymentsAndCredits = () => {
  const [creditPlanId, setCreditPlanId] = useState(null);
  const [planModalOpen, setPlanModalOpen] = useState(false);

  useEffect(() => {
    if (creditPlanId) {
      setPlanModalOpen(true);
    }
  }, [creditPlanId]);

  const closePlanModal = () => {
    setPlanModalOpen(false);
    setCreditPlanId(null);
  }

  return (
    <div className="page">
      <CreditPlanModal open={planModalOpen} onClose={closePlanModal} creditPlanId={creditPlanId} />

      <div style={{marginBottom: "16px"}}>
        <h2 className="header-2">Payments & Credits</h2>
      </div>

      <DopeTabs>
        <DopeTabs.Tab name="allInvoices" label="Invoices">
          <AllInvoicesTable />
        </DopeTabs.Tab>
        <DopeTabs.Tab name="payments" label="Payments">
          <PaymentsTable />
        </DopeTabs.Tab>
        <DopeTabs.Tab name="refunds" label="Refunds">
          <RefundsTable />
        </DopeTabs.Tab>
        <DopeTabs.Tab name="issuedCredits" label="Issued Credits">
          <IssuedCreditsTable />
        </DopeTabs.Tab>
        {/*<DopeTabs.Tab name="automaticCredits" label="Automatic Credits">*/}
        {/*  <AutomaticCreditsTable />*/}
        {/*</DopeTabs.Tab>*/}
        <DopeTabs.Tab name="creditPlans" label="Credit Plans">
          <DopeButton
            props={{
              onClick: () => setPlanModalOpen(true),
              label: "Add Credit Plan",
              buttonClass: "filled",
              styles: { position: "absolute", top: "40px", right: "40px" }
            }}
          />
          <CreditPlansTable setCreditPlanId={setCreditPlanId} modalOpen={planModalOpen} />
        </DopeTabs.Tab>
      </DopeTabs>

    </div>
  );
}

export default PaymentsAndCredits;
