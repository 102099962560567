import pluralize from "pluralize";

function toDisplayName(modelName) { // TODO is this just humanize?
  return modelName.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function toPluralName(modelName) {
  return pluralize.plural(modelName);
}

function toPluralDisplayName(modelName) {
  return toDisplayName(toPluralName(modelName));
}

function toBelongsName(modelName) {
  return `${modelName}_id`;
}

function humanize(str = '') {
  return str.toString().replace(/__/g, ': ').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function toNames(modelName) {
  const pluralName = toPluralName(modelName);
  const displayName = toDisplayName(modelName);
  const pluralDisplayName = toPluralDisplayName(modelName);
  const belongsName = toBelongsName(modelName);

  return {
    pluralName,
    displayName,
    pluralDisplayName,
    belongsName,
  };
}

export default toNames;

export {
  toDisplayName,
  toPluralName,
  toPluralDisplayName,
  toBelongsName,
  humanize,
  toNames,
};
