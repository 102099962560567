import React, {useEffect, useState} from "react";
import EmailIcon from '@rsuite/icons/Email';

import { useAccount } from "../accounts/accountSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import authApi from "../auth/authApi";
import DopeApi from "../services/DopeApi";

const accountAssignmentApi = new DopeApi("account_assignment");

const fetchAccountAssignments = async (setAccountAssignments, setDisplay) => {
  const accountAssignments = await accountAssignmentApi.getMany({
    filters: [{ field: "account_id", value: authApi.getCurrentAccountId(), operator: "=" }],
    scopes: [{name: "dope_team_assignments"}]
  })
  setAccountAssignments(accountAssignments);
  accountAssignments.length > 0 ? setDisplay('block') : setDisplay('none');
}

const avatarImageStyles = {
  width: "44px",
  height: "44px",
  borderRadius: "50%",
  marginRight: "10px"
}


const userItems = (accountAssignment, handleEmailClick) => {
  const user = accountAssignment.user;

  return (
    <div className="your-team-item" key={user.full_name.split(" ").join("-")}>
      <div className="your-team-user-info">
        {/* TODO: Are we adding profile pictures? Pictures just for our staff? */}
          {user.avatar_url ? (
            <div>
              <img src={user.avatar_url} alt={`${user.full_name} photo`} style={avatarImageStyles} />
            </div>
          ) : (
            <div className="account-user-initials" style={{backgroundColor: user.profile_color}}>
              {user.first_name[0]}{user.last_name[0]}
            </div>
          )}
        <div>
          <div className="account-user-name">{user.first_name} {user.last_name[0]}.</div>
          <div className="account-user-role">{accountAssignment.role}</div>
        </div>
      </div>
      <div className="your-team-icon" onClick={() => handleEmailClick(user.email)}><EmailIcon /></div>
    </div>

  )
}


const YourTeam = () => {
  const [account, accountActions] = useAccount();
  const [accountAssignments, setAccountAssignments] = useState([]);
  const [display, setDisplay] = useState('none');
  const [dopeUI, dopeUIActions] = useDopeUI();

  useEffect(() => {
    if (!account) {
      accountActions.get(authApi.getCurrentAccountId())
    } else {
      fetchAccountAssignments(setAccountAssignments, setDisplay);
    }
  }, [account]);

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
    navigator.clipboard.writeText(email).then(() => {
      dopeUIActions.addFlashMessage({header: 'Email copied to clipboard', body: email, type: 'success' });
    }).catch(err => {
      dopeUIActions.addFlashMessage({header: 'Something went wrong', body: 'please try again', type: 'error' });
    });
  }

  return (
    <div style={{display: display}}>
      <div className="overview-section">
        <div style={{marginBottom: "8px"}}>
          <h4 className="overview-header">Your Team</h4>
        </div>
        <div className="your-team">
          { accountAssignments && accountAssignments.map((accountUser, index) => {
            return (
              <div key={index}>
                {userItems(accountUser, handleEmailClick)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default YourTeam;
