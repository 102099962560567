import React, { useEffect, useState } from "react";

import { subscriptionTiers, groupedPermissionSubjects, permissionsApi } from "../utils/permissions";
import { useDopeUI } from "../ui/dopeUISlice";
import authApi from "../auth/authApi";

import FeaturesTableSection from "./FeaturesTableSection";
import DopeButton from "../ui/DopeButton";

import './Pulse.scss'

const FeaturesTable = () => {
  const [permissions, setPermissions] = useState(null);
  const [editing, setEditing] = useState(false);
  const [dopeUI, dopeUIActions] = useDopeUI();

  useEffect(() => {
    const fetchPermissions = async () => {
      const currentPermissions = await permissionsApi.get();
      setPermissions(currentPermissions.permissions_settings);
    }

    fetchPermissions();
  }, []);

  if (!permissions) {
    return <div>Loading...</div>
  }

  const updatePermission = async (permission, tier, value) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permission]: {
          ...prevPermissions[permission],
          [tier]: value,
      },
    }));
  }

  const savePermissions = async () => {
    try {
      const permission = await permissionsApi.update({ permissions_settings: permissions });
      setPermissions(permission.permissions_settings);
      dopeUIActions.addFlashMessage({ body: "Permissions saved", type: "success" });
      setEditing(false);
    } catch (error) {
      dopeUIActions.addFlashMessage({ body: "Failed to save permissions", type: "error" });
    }
  }

  const isSuperAdmin = authApi.currentUserIsSuperAdmin();

  return (
    <div>

      {isSuperAdmin && <div className="row to-right margin-16-b">
        <DopeButton
          props={{
            onClick: () => setEditing(!editing),
            label: editing ? "Cancel" : "Edit",
            buttonClass: "outlined-black",
          }}
          className="feature-button"
        />

        {editing && (
          <DopeButton
            props={{
              onClick: savePermissions,
              label: "Save",
              buttonClass: "filled-black",
            }}
            className="feature-button margin-8-l"
          />
        )}
      </div>}

      <div className="flex row space-between features-header-wrapper pad-8 margin-16-b">
        <div className="label large bold features-header">Features</div>
        {subscriptionTiers.map((tier) => {
          return (
            <div className="label large bold feature-tier-header" key={tier + '-feature'}>{tier}</div>
          )
        })}
      </div>

      {permissions && Object.entries(groupedPermissionSubjects).map(([section, permissionSubjects]) => (
        <FeaturesTableSection
          key={section}
          permissions={permissions}
          subject={section}
          updatePermission={updatePermission}
          permissionSubjects={permissionSubjects}
          editing={editing}
        />
      ))}
    </div>
  )
}

export default FeaturesTable
