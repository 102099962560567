import React, {useState} from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Button, Input } from "rsuite";

import DopeLogo from "../icons/DopeLogo";
import authApi from "./authApi";

const loginFormStyles = {
  width: "468px",
  height: "auto",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 6px 6px 0px #BABABA40",
}
const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const submitResetRequest = async () => {
    if (newPassword !== confirmNewPassword) {
      alert("Passwords do not match");
      return;
    }

    setLoading(true)
    const passwordToken = new URLSearchParams(location.search).get("reset_password_token");

    try {
      const response = await authApi.resetPassword(newPassword, confirmNewPassword, passwordToken);
      if (response.data.message === "success") {
        await authApi.login({email: response.data.email, password: newPassword});
      } else if (response.data.message === "error") {
        setErrors(response.data.errors);
      }
    } catch (error) {
      setErrors(error);
    } finally {
      setLoading(false);
    }
  }

  const updatePassword = (password) => {
    setNewPassword(password)
  }

  const updateConfirmPassword = (confirm) => {
    setConfirmNewPassword(confirm)
  }

  const invalidToken = Object.keys(errors || {}).includes("reset_password_token");

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <DopeLogo style={{ width: "169px", height: "73px", margin: "20px 0" }}/>
      <div style={loginFormStyles}>
        <h3 style={{marginBottom: "20px"}} >Reset Password</h3>
        <div style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="newPassword" style={{fontWeight: 600, fontSize: "14px", color: "#8793A6", marginBottom: "2px"}}>New Password</label>
          <Input
            name="newPassword"
            type="password"
            value={newPassword}
            onChange={(password) => updatePassword(password)}
            style={{marginBottom: "20px"}}
          />

          <label htmlFor="confirmNewPassword" style={{fontWeight: 600, fontSize: "14px", color: "#8793A6", marginBottom: "2px"}}>Confirm New Password</label>
          <Input
            name="confirmNewPassword"
            type="password"
            value={confirmNewPassword}
            onChange={(confirm) => updateConfirmPassword(confirm)}
            style={{marginBottom: "20px"}}
          />

          {errors && Object.keys(errors).map((key, index) => (
            <div key={index} style={{color: "red", marginBottom: "10px"}}>{key} {errors[key]}</div>
          ))}

          {invalidToken && <Link to="/forgot_password" className="link-label">Request a new token</Link>}

          <Button
            style={{backgroundColor: "#EA0029", width: "100%", borderRadius: "24px", color: "#fff", fontWeight: 600, fontSize: "14px", padding: "12px 0", marginTop: "20px", marginBottom: "20px", border: "none", cursor: "pointer"}}
            onClick={submitResetRequest}
            loading={loading}
          >
            Reset Password
          </Button>
        </div>

        <div>
          <Link to="/login" style={{fontWeight: 600, fontSize: "14px", color: "#255FDF"}}>Back to Sign In</Link>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;
