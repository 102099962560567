import React from "react";

import SpinnerIcon from '@rsuite/icons/legacy/Spinner';

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import { toDisplayName } from "../utils/railsNames";

import DopeSelect from "./DopeSelect";

// TODO this will load options on mount - may want to lazy load
// TODO make more generic and change to modelSelect

const BelongsToSelect = ({ belongsToModelName, modelName, name, value, labelKey = 'name', onChange, searchable = true, ...rest }) => {
  const api = new DopeApi(belongsToModelName || modelName);
  name = name || `${modelName}_id`;

  const { allData: options, loading, setFilters } = useQuery2({ api, ensureResourceId: value });

  const handleSearch = (searchTerm) => {
    // TODO - this is called on select and on search, creating extra calls maybe switch to react-select
    if (!searchable) return;
    setFilters([{ field: labelKey, operator: 'ilike', value: searchTerm }]);
  };

  const renderMenu = menu => {
    if (loading) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> Loading...
        </p>
      );
    }
    return menu;
  };

  const handleChange = (update) => {
    const item = options.find(o => o.id === update[name]);
    onChange(update, item);
  };

  return (
    <DopeSelect
      options={options}
      value={value}
      onChange={handleChange}
      placeholder={`Select ${toDisplayName(belongsToModelName || modelName)}`}
      labelKey={labelKey}
      valueKey="id"
      onSearch={handleSearch}
      name={name}
      renderMenu={renderMenu}
      searchable={searchable}
      {...rest}
    />
  )
}

export default BelongsToSelect;
