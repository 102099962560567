import React, { useState } from "react";
import { Modal } from "rsuite";
import each from "lodash/each";
import map from "lodash/map";

import DopeButton from "../ui/DopeButton";
import DopeApi from "../services/DopeApi";
import Cents from "../ui/Cents";
import { useQuery2 } from "../hooks/useQuery";
import { BatchForm } from "../batches/BatchManagement";
import DopeContentPage from "../ui/DopeContentPage";
import DopeListPage from "../ui/DopeListPage";
import { timestampToDayOfWeekStr } from "../utils/date";
import { humanize } from "../utils/railsNames";
import DopePill from "../ui/DopePill";
import authApi from "../auth/authApi";

const batchApi = new DopeApi('batch');

const summaryWidth = 175;

const columns = [
  { type: 'custom', dataKey: 'created_at', label: 'Date', minWidth: 250, sortable: true, filterable: true, resizable: true, render: (row) => {
    return (
      <div className="flex column top-align full-height">
        <div className="label large bold">{timestampToDayOfWeekStr(row.created_at)}</div>
        <div className="badge xs text-upper margin-4-t">TEC# {row.tec_mail_order_ticket}</div>
        <div className="badge xs text-upper margin-4-b margin-4-t">ID# {row.id}</div>
        <div className="badge xs">Bin Count: {row.bin_count || '---'}</div>
      </div>
    );
  }},
  { type: 'custom', dataKey: 'failed_summary', label: 'Validation', width: summaryWidth, resizable: true, render: (row) => {
    const summaries = ['postcard_summary', 'handwritten_summary', 'goodies_summary'];
    const rejectStatuses = ['sent', 'delivered'];
    let totalUnshipped = 0;
    const unshippedStatusToCount = summaries.reduce((acc, summary) => {
      each(row.summary[summary], (statusCount, status) => {
        if (!rejectStatuses.includes(status)) {
          acc[status] = acc[status] || 0;
          acc[status] += statusCount;
          totalUnshipped += statusCount;
        }
      });
      return acc;
    }, {});

    const statusCountItems = map(unshippedStatusToCount, (count, status) => {
      return (
        <div className="label large color-text-secondary" key={status}>{count} {status} pieces</div>
      );
    });

    const failedPurchasesCount = row.summary?.purchase_summary_for_status_error?.purchase_count || 0;
    const failedPurchasesTotalPrice = row.summary?.purchase_summary_for_status_error?.total_price || 0;

    const failedPurchaseSummary = (
      <>
        <div className="label large bold">{failedPurchasesCount} Failed Purchases</div>
        <div><Cents>{failedPurchasesTotalPrice}</Cents> in failed charges</div>
      </>
    );

    return (
      <div className="flex column top-align full-height">
        <div className="label large bold">{totalUnshipped} pieces rejected</div>
        {statusCountItems}
        {failedPurchaseSummary}
      </div>
    );
  }},
  {
    type: 'custom', dataKey: 'purchase_summary', label: 'Payment', width: summaryWidth, resizable: true, render: (row) => {
    return (
      <div className="flex column top-align full-height">
        <div className="label large bold"><Cents>{(row.summary?.purchase_summary_for_status_paid?.total_price || 0) }</Cents> charged</div>
        <div className="label large color-text-secondary">{row.summary?.purchase_summary_for_status_paid?.total_credits_used || 0} credits used</div>
        <div className="label large color-text-secondary"><Cents>{row.summary?.purchase_summary_for_status_paid?.eddm_total_price || 0}</Cents> EDDM</div>
        <div className="label large color-text-secondary">{row.summary?.purchase_summary_for_status_paid?.eddm_credits_used || 0} EDDM credits</div>
        <div className="label large color-text-secondary">{row.summary?.purchase_summary_for_status_paid?.eddm_total_quantity || 0} EDDM total</div>
      </div>
    );
  }},
  { type: 'custom', dataKey: 'delivered_summary', label: 'Final Batch', width: summaryWidth, resizable: true, render: (row) => {
    const postcard_count = row.summary?.postcard_summary?.delivered || 0;
    const handwritten_card_count = row.summary?.handwritten_summary?.delivered || 0;
    const goodie_count = row.summary?.goodies_summary?.delivered || 0;
    const total_count = postcard_count + handwritten_card_count + goodie_count;

    return (
      <div className="flex column top-align full-height">
        <div className="label large bold">{total_count} Pieces</div>
        <div className="label large color-text-secondary">{postcard_count} postcards</div>
        <div className="label large color-text-secondary">{handwritten_card_count} handwritten cards</div>
        <div className="label large color-text-secondary">{goodie_count} goodies</div>
      </div>
    );
  }},
  {
    type: 'custom', dataKey: 'statuses', label: 'Process Satuses', flexGrow: 2, resizable: true, render: (row) => {

    const statusItems = map(row.statuses, (statusData, statusOfName) => {
      const { status, message } = statusData;
      const fullMessage = `${humanize(statusOfName)}: ${humanize(status)} - ${message}`;

      return (
        <div key={fullMessage}>
          <DopePill text={fullMessage} pillType={status} size="small" />
        </div>
      );
    });

    return (
      <div className="flex column space-between full-height">
        {statusItems}
      </div>
    );
  }},
  { type: 'download_files', dataKey: 'files', label: 'Files', width: 200 },
  { type: 'download_files', dataKey: 'print_files', label: 'Print Files', width: 150 },
];

const CreateBatchModal = ({ open, setShowModal, reload }) => {
  const handleFinish = () => {
    setShowModal(false);
    reload();
  }

  return (
    <Modal open={open} onClose={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title><div className="header-2">Create Batch</div></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BatchForm onFinish={handleFinish} />
      </Modal.Body>
    </Modal>
  );
}

const PulseBatchingDetails = () => {
  const [showCreateBatchModal, setShowCreateBatchModal] = useState(false);
  const { error, tableProps, paginationProps, setFilters, filters, reload } = useQuery2({ api: batchApi });

  const actionButtons = [
    <DopeButton
      key="refresh"
      props={{
        buttonClass: "text-link",
        label: "Refresh",
        onClick: () => reload(),
      }}
    />
  ];

  if (authApi.currentUserIsSuperAdmin()) {
    actionButtons.push(
      <DopeButton
        key="create-batch"
        props={{
          buttonClass: "filled",
          label: "Create Batch",
          onClick: () => setShowCreateBatchModal(true),
        }}
      />
    )
  }

  const rowHeight = ({ rowData }) => {
    const printFileHeight = (rowData?.print_files?.length || 0) * 20;
    return Math.max(135, printFileHeight);
  };

  return (
    <div>
      <CreateBatchModal open={showCreateBatchModal} setShowModal={setShowCreateBatchModal} reload={reload} />

      <DopeContentPage
        pageTitle="Batching Details"
        pageContent={<DopeListPage
          columns={columns}
          tableProps={tableProps}
          paginationProps={paginationProps}
          setFilters={setFilters}
          filters={filters}
          error={error}
          rowHeight={rowHeight}
        />}
        actionButtons={actionButtons}
      />
    </div>
  );
}

export default PulseBatchingDetails;
