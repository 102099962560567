import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import map from "lodash/map";
import DopeApi from "../../services/DopeApi";
import { IntegrationsProvider } from "../integrationsSlice";
import { useDopeUI } from "../../ui/dopeUISlice";

import DopeLoader from "../../ui/DopeLoader";
import DopeButton from "../../ui/DopeButton";
import Subscription from "../Subscriptions/Subscription";
import DataSourceSelect from "../DataSources/DataSourceSelect";
import SubscriptionTemplateSetup from "./SubscriptionTemplateSetup";

const api = new DopeApi("subscription_template", false);

const errorsToMessages = (errors) => {
  return map(errors, (value, key) => {
    return `${key}: ${value.message}`;
  });
};

const SubscriptionTemplate = () => {
  const [subscriptionTemplate, setSubscriptionTemplate] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [dopeUI, dopeUIActions] = useDopeUI();

  useEffect(() => {
    if (id === "new") {
      setSubscriptionTemplate({ id: "new" });
    } else {
      api.get(id).then(setSubscriptionTemplate);
    }
  }, []);

  const subProps = {
    dataSource: {
      header: (<div className="header-3 margin-bottom">Template Data Source</div>),
    },
    event: {
      header: (<div className="header-3 margin-bottom">Template Conditions</div>),
    },
    conditions: {
      header: (<div className="header-5 margin-header">Add filters</div>),
    },
  };

  if (!subscriptionTemplate) {
    return <DopeLoader />;
  }

  const initial = subscriptionTemplate.id && subscriptionTemplate.id !== "new" ? subscriptionTemplate : null;
  const query = subscriptionTemplate.is_query;

  const saveDisabled = false;

  const handleUpdate = (update) => {
    setSubscriptionTemplate({
      ...subscriptionTemplate,
      ...update,
    });
  };

  const handleSave = async () => {
    try {
      const sub = await api.save(subscriptionTemplate);
      if (id === "new") {
        const nextUrl = location.pathname.replace("new", sub.id);
        navigate(nextUrl, { replace: true });
      }
      setSubscriptionTemplate(sub);
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error", body: errorsToMessages(error), type: "error" });
    }
  };

  // TODO update automation to remove the double call of IntegrationsProvider / IntegrationsLoader, also double call from connection select?
  // TODO fix dup props mess, shared and query should only be in one place
  return (
    <IntegrationsProvider subscriptionId={null} initialSubscription={initial} query={query} shared={true}>

      <div className="page">
        <div className="header-3 margin-bottom">Template Setup</div>
        <SubscriptionTemplateSetup
          subscriptionTemplate={subscriptionTemplate}
          onChange={handleUpdate}
        />
      </div>

      <div className="page">
        <DataSourceSelect header={subProps.dataSource.header} />
      </div>

      <div className="page">
        <Subscription
          query={query}
          shared={true}
          onChange={sub => handleUpdate({
            data_source: sub.data_source,
            data_type: sub.data_type,
            event: sub.event,
            conditions: sub.conditions,
            field_to_type_map: sub.field_to_type_map,
          })}
          subProps={subProps}
          hide={{ fieldMap: true, viewData: true }}
        />
      </div>

      <div className="footer-actions margin-bottom">
        <div></div>
        <DopeButton
          disabled={saveDisabled}
          className="next-btn"
          props={{
            buttonClass: 'filled',
            label: 'Save',
            onClick: handleSave,
          }}
        />
      </div>

    </IntegrationsProvider>
  );
};

export default SubscriptionTemplate;
