import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  drawerMailPieceId: null,
}

export const mailPieceUISlice = createSlice({
  name: "mailPieceUI",
  initialState,
  reducers: {
    updateMailPieceUI: (mailPieceUI, { payload: update }) => {
      Object.assign(mailPieceUI, update);
    },
    setDrawerMailPieceId: (mailPieceUI, { payload: id }) => {
      mailPieceUI.drawerMailPieceId = id;
    }
  }
});

export default mailPieceUISlice.reducer;

export const {
  updateMailPieceUI,
  setDrawerMailPieceId,
} = mailPieceUISlice.actions;

export const selectMailPieceUI = (state) => state.mailPieceUI;

export function useMailPieceUI() {
  const dispatch = useDispatch();
  const mailPieceUI = useSelector(selectMailPieceUI);
  const actions = {
    update: (payload) => dispatch(updateMailPieceUI(payload)),
    setDrawerMailPieceId: (id) => dispatch(setDrawerMailPieceId(id)),
  };

  return [mailPieceUI, actions];
}
