import React, { useState, useEffect } from "react";

import DopeApi from "../services/DopeApi";
import { useObjectState } from "../hooks/useObjectState";
import { toDisplayName } from "../utils/railsNames";

import DopeForm from "../ui/DopeForm";

const ModelForm = ({ modelName, id, initialResource, inputs, onFinish, formTypeSwitcher }) => {
  const [nextResource, updateNextResource, setNextResource, resetNextResource] = useObjectState(initialResource);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const api = new DopeApi(modelName);

  const handleGet = (resource) => {
    setNextResource(resource);
    setLoading(false);
  };

  const handleErrors = (errors) => {
    setErrors(errors);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      api.get(id).then(handleGet).catch(handleErrors);
    } else {
      resetNextResource();
    }
  }, [id]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const resource = await api.save(nextResource);
      handleGet(resource);
      onFinish(true, resource);
    } catch (errors) {
      handleErrors(errors);
    }
  };

  const handleCancel = () => {
    resetNextResource();
    onFinish(false);
  };

  const modelNameDisplay = toDisplayName(modelName);
  const submitText = id ? `Update ${modelNameDisplay}` : `Create ${modelNameDisplay}`;

  return (
    <DopeForm
      submitText={submitText}
      onSubmit={handleSubmit}
      onClickCancel={handleCancel}
      inputs={inputs}
      values={nextResource}
      onChange={updateNextResource}
      errors={errors}
      loading={loading}
      formTypeSwitcher={formTypeSwitcher}
    />
  );
};

export default ModelForm;
