import { useRef } from 'react';

import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFRenderer = ({ file, page, onDocumentLoad, onPageLoad, width, height }) => {
  const canvas = useRef(null);

  if (!file) {
    return null;
  }

  if (file.type !== 'application/pdf') {
    return null;
  }

  return (
    <div style={{ display: 'none' }}>
      <Document file={file} onLoadSuccess={onDocumentLoad}>
        <Page
          pageNumber={page}
          orientation="landscape"
          renderAnnotationLayer={false}
          renderTextLayer={false}
          scale={1}
          width={width}
          height={height}
          canvasRef={canvas}
          onRenderSuccess={() => {
            const image = canvas.current.toDataURL('image/jpg', 1.0);
            onPageLoad(image);
          }}
        />
      </Document>
    </div>
  );
};

export default PDFRenderer;
