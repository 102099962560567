import React from "react";

import { useAccount } from "./accountSlice";

const AccountAgencyTab = () => {
  const [account, actions] = useAccount()

  return (
    <>

    </>
  );
}

export default AccountAgencyTab;