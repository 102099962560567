import React from "react";
import {Table} from "rsuite";
import {unixTimestampToDateStr} from "../utils/date";
const { Column, HeaderCell, Cell } = Table;

const InvoiceTransactionsTable = ({ payment }) => {

  return (
    <>
      <h6 style={{ fontWeight: 600, fontSize: "14px", color: "#292B2E", margin: "20px 0 10px"}}>TRANSACTIONS</h6>
      <Table
        data={[payment]}
        bordered
        autoHeight={true}
        style={{ borderRadius: "8px 8px 0 0" }}
      >
        <Column align="left" flexGrow={1}>
          <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Transaction ID</HeaderCell>
          <Cell dataKey="payment.id" style={{color: "#255FDF"}}/>
        </Column>
        <Column align="left" flexGrow={1}>
          <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Date</HeaderCell>
          <Cell dataKey="payment.created" style={{color: "#292B2E"}}>
            {rowData => (
              <div>
                {unixTimestampToDateStr(rowData.payment.created)}
              </div>
            )}
          </Cell>
        </Column>
        <Column align="left" flexGrow={1}>
          <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Amount</HeaderCell>
          <Cell dataKey="payment.amount" style={{color: "#292B2E"}}>
            {rowData => (
              <div>${(rowData.payment.amount / 100).toFixed(2)}</div>
            )}
          </Cell>
        </Column>
        <Column align="left" flexGrow={1}>
          <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Status</HeaderCell>
          <Cell dataKey="payment.status" style={{color: "#292B2E"}} />
        </Column>
        <Column align="left" flexGrow={1}>
          <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Method</HeaderCell>
          {/* TODO: display card brand icons */}
          <Cell dataKey="payment_method.id" style={{color: "#292B2E"}}>
            {rowData => {
              return (
                <div>
                  {rowData.payment_method && <div>{rowData.payment_method.card.brand} **{rowData.payment_method.card.last4}</div>}
                </div>
              )
            }}
          </Cell>
        </Column>
      </Table>
    </>
  );
}

export default InvoiceTransactionsTable;