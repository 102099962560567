import React from "react";
import { useOutletContext } from "react-router-dom";

import { useAutomation } from "./automationSlice";

import DopePageStepHeader from "../ui/DopePageStepHeader";
import AutomationTriggerNative from "./AutomationTriggerNative";
import AutomationTriggerOther from "./AutomationTriggerOther";

import '../integrations/integrations.scss';

const pillContainerStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 20,
};

const pillStyles = { // TODO pill tabs, duming styles here
  display: 'flex',
  flexDirection: 'column',
  height: 36,
  padding: '8px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  borderRadius: 20,
  border: '1px solid var(--ui-selector-testing-blue, #255FDF)',
  background: 'var(--ui-selector-testing-blue, #255FDF)',
  color: 'white',
  width: 200,
  overflow: 'hidden',
};

const unselectedPillStyles = {
  ...pillStyles,
  cursor: 'pointer',
  background: 'white',
  border: '1px solid var(--ui-input-stroke, #EAECEF)',
  color: 'black',
};

const triggerSourceOptions = [
  { value: 'native', label: 'Direct Connection' },
  // { value: 'zapier', label: 'Zapier Connection' },
  { value: 'other', label: 'Zapier / Webhooks' },
];


const AutomationTrigger = () => {
  const { automation, actions } = useAutomation();
  const { pageTabs } = useOutletContext();

  const TriggerComponent = {
    native: AutomationTriggerNative,
    zapier: AutomationTriggerNative,
    other: AutomationTriggerOther,
  }[automation.trigger_source];

  return (
    <div className="margin-bottom">
      <div className="page">
        {pageTabs}
        <div className="page-header">
          <h2 className="header-2">Connect with DOPE and define your trigger</h2>
          <p className="body-1 color-text-secondary">Select your integration type and define rules so you can set your automation and forget it.</p>
        </div>

        <DopePageStepHeader
          step="1"
          headerText="Choose your connection to connect with DOPE"
        />

        <div style={pillContainerStyles}>
          {triggerSourceOptions.map((opt) => (
            <div
              key={opt.value}
              onClick={() => {
                actions.update({ trigger_source: opt.value });
              }}
              style={automation.trigger_source === opt.value ? pillStyles : unselectedPillStyles}
            >
              {opt.label}
            </div>
          ))}
        </div>
      </div>

      <TriggerComponent />
    </div>
  );
}

export default AutomationTrigger;
