import React from "react";
import { Placeholder } from 'rsuite';

const placeholderDeterminer = (type, rows, columns, active = true) => {
  // const rowCount = rows || 2;
  // const columnCount = columns || 2;

  switch(type) {
    case 'image':
      return <Placeholder.Paragraph graph="image" rows={rows} active={active}/>
    case 'grid':
      return <Placeholder.Grid rows={rows} columns={columns} active={active} />;
    case 'paragraph':
      return <Placeholder.Paragraph rows={rows} active={active} />;
    case 'block':
      return <Placeholder.Graph active={active} />;
    default:
      return <Placeholder.Paragraph rows={rows} active={active} />;
  }
}

const DopePlaceholder = ({ type, rows, columns, active, title }) => {

  return (
    <>
      {title && <h3>{title}</h3>}
      {placeholderDeterminer(type, rows, columns, active)}
    </>
  )
}

export default DopePlaceholder;