import React, { useEffect } from "react";
import { useParams, useLocation, useNavigate, Outlet, matchPath } from 'react-router-dom';

import { useSweetSpotAnalysis } from './sweetSpotAnalysisSlice';

import DopePageLoader from '../ui/DopePageLoader';
import DopeButton from "../ui/DopeButton";
import useDopePageTabs from '../ui/useDopePageTabs';

const pages = [
  {
    name: 'Sweet Spots Setup',
    path: '/sweet_spot_analyses/:id/setup',
    isValid: (analysis) => {
      return !!analysis.name && !!analysis.list_id;
    },
  },
  {
    name: 'Review Report',
    path: '/sweet_spot_analyses/:id/review',
    isValid: (analysis) => {
      return !!analysis.name && !!analysis.list_id;
    },
  }
];


const SweetSpotAnalysisFormPages = () => {
  const { sweetSpotAnalysis, actions, isSaving } = useSweetSpotAnalysis();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    PageTabs,
    BackLink,
    navigateForward,
    isLastPage,
    isValid,
  } = useDopePageTabs({ pages, resource: sweetSpotAnalysis, exitUrl: '/sweet_spot_analyses', exitText: 'Back to all Reports' });

  const handleSaveAndRedirect = async () => {
    const actionResult = await actions.save();
    navigate(`/sweet_spot_analyses`);
  };

  const saveButton = (
    <DopeButton
      className="save-btn"
      loading={isSaving}
      props={{
        buttonClass: isLastPage ? "filled" : "text-button",
        label: isLastPage ? "Save" : "Save as draft",
        onClick: isLastPage ? handleSaveAndRedirect : actions.save,
      }}
    />
  );

  const continueButton = (
    <DopeButton
      className="next-btn"
      disabled={!isValid}
      props={{
        buttonClass: "filled",
        label: "Continue",
        onClick: navigateForward,
      }}
    />
  );

  const footer = (
    <div className="space-between margin">
      <div></div>
      {isLastPage ? saveButton : continueButton}
    </div>
  );

  const isShowPage = location.pathname.includes('show');

  return (
    <div>
      <BackLink className="margin" />

      <div className="page">
        <PageTabs />
        <Outlet />
      </div>

      {!isShowPage && footer}
    </div>
  );
};

const SweetSpotAnalysis = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { sweetSpotAnalysis, actions, isSaving, isGetting } = useSweetSpotAnalysis();
  const isFormPath = pages.some(page => matchPath({ path: page.path }, location.pathname));

  useEffect(() => {
    if (!sweetSpotAnalysis || sweetSpotAnalysis.id !== id) {
      actions.get(id);
    }

    return actions.resetToInitial;
  }, []);

  if (!sweetSpotAnalysis || isGetting) {
    return <DopePageLoader />;
  }


const BackLink = ({ className = '' }) => (
  <div className={`link ${className}`} onClick={() => navigate('/sweet_spot_analyses')}>← Back to all Analyses</div>
);

  if (isFormPath) {
    return (<SweetSpotAnalysisFormPages />);
  } else {
    return (
      <div>
        <BackLink className="margin" />
        <div className="page">
          <Outlet />
        </div>
      </div>
    );
  }
};

export default SweetSpotAnalysis;

