import React from "react";

import authApi from "../auth/authApi";

import { Divider } from "rsuite";
import StripeDefaultCardDisplay from "../stripe/StripeDefaultCardDisplay";
import Cents from "../ui/Cents";
import DopeLoader from "../ui/DopeLoader";
import DopeCheckbox from "../ui/DopeCheckbox";

import "./PurchaseSummary.scss";

const PurchaseSummary = ({
  purchase_summary,
  priceHeader = "Estimated Total",
  onClickUpdateCard = null,
  resourceType,
  showConfirmation = true,
  confirmed = false,
  onChangeConfirmed,
  showSuppressionDisclaimer = true,
}) => {
  if (!purchase_summary) {
    return <DopeLoader />;
  }

  const isAutomation = resourceType === "automation";
  const purchaseSetting = authApi.getCurrentAccount().purchase_setting;
  const detailsHeader = purchaseSetting === "AS_YOU_GO" ? "Your account is set up to “Pay as You Go”" : "Your account is set up to “Pay Upfront”";
  const confirmCopy = isAutomation ? "activating this automation" : "scheduling this campaign";

  const confirmationInput = showConfirmation && (
      <DopeCheckbox
        helpText={`I acknowledge that I am ${confirmCopy} and agreeing to pay for the listed cost.  I understand that I can cancel any sending scheduled for the future and will be credited for those sendings.`}
        value={confirmed}
        name="confirmedPurchase"
        onChange={onChangeConfirmed}
      />
  );

  const asYouGoBreakdown = purchaseSetting === "AS_YOU_GO" && (
    <div>
      {purchase_summary.dispatch_summaries.map((dispatch_summary, index) => {
        const { label, total_price } = dispatch_summary;
        return (
          <div key={index} className="split margin-bottom">
            <div className="purchase-header medium text">{label}</div>
            <div className="purchase-header"><Cents>{total_price}</Cents></div>
          </div>
        );
      })}

      <Divider />
    </div>
  );

  return (
    <div>
      <div className="header-3 margin-bottom">Payment Summary</div>

      <div className="split-cols">
        <div>
          <div className="purchase-summary page-content-box dark margin-0-t">
            <div className="purchase-header text-center margin-bottom">ESTIMATED SUMMARY</div>

            <Divider />

            <table>
              <thead>
              <tr>
                <th className="product-header">Item</th>
                <th>Credits Needed</th>
                {isAutomation ? null : (<th>You Have</th>)}
                <th>Price per Credit</th>
                <th>To Be Purchased</th>
              </tr>
              </thead>
              <tbody>
              {purchase_summary.line_items.map((line_item, index) => {
                return (
                  <tr key={index}>
                    <td className="product-name">{line_item.product_name}</td>
                    <td>{line_item.required_credits}</td>
                    {isAutomation ? null : (<td>{line_item.available_credits}</td>)}
                    <td>{line_item.unit_price_str}</td>
                    <td><Cents>{line_item.total_price}</Cents></td>
                  </tr>
                );
              })}
              </tbody>
            </table>

            <Divider />

            <div className="purchase-header small margin-bottom">{detailsHeader}</div>

            {asYouGoBreakdown}

            <div className="split">
              <span className="purchase-header medium">{priceHeader}</span>
              <span className="purchase-header"><Cents>{purchase_summary.total_price}</Cents></span>
            </div>

            <Divider />

            <div>
              FYI: this is just an estimate. This campaign will be added to your daily bill along with any other scheduled campaign or automations triggered. We will deduct your credits before charging your card if necessary.
            </div>

            {showSuppressionDisclaimer && (<div className="margin-tb">Your final campaign size may be slightly smaller because suppression logic is applied after submission.</div>)}

            {showSuppressionDisclaimer && (<div>Any contacts marked as undeliverable will be returned to your account as credits.</div>)}

          </div>
        </div>

        <div>
          <div className="label">Payment Method</div>
          <div className="page-content-box">
            <StripeDefaultCardDisplay onClickUpdate={onClickUpdateCard} />
          </div>

          {confirmationInput}
        </div>
      </div>
    </div>
  );
};

export default PurchaseSummary;
