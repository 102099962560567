import { useDispatch, useSelector } from "react-redux";

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import {useEffect} from "react";

const [userSlice, asyncActions] = createModelSlice("user");

export default userSlice.reducer;

export const {
  updateUser,
  resetToInitial,
} = userSlice.actions;

export const selectUser = (state) => state.user.current;

export function useUser (userId) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const actions = wrapAll({
    update: updateUser,
    resetToInitial: resetToInitial,
    get: asyncActions.get,
    destroy: asyncActions.destroy,
  }, dispatch);

  actions.save = (update = {})  => {
    const params = {
      ...user,
      ...update,
    };

    return dispatch(asyncActions.save(params));
  }

  useEffect(() => {
    const shouldGet = userId && (!user || user.id !== user);
    if (shouldGet) {
      actions.get(userId);
    }

  }, [userId, user?.id]);

  return [user, actions];
}
