import dopeClient from "../services/dopeClient";

export const permissionsDisplayValues = {
  automation_allotment: { name: "Automations Count", description: "Access to create and send mail through automations", type: "number_input", limit: 100 },
  automation_filters: { name: "Automation Filters", description: "Access to add filters to automations", type: "toggle" },
  commercial_blitz: { name: "B2B Blitz", description: "Access to create B2B blitzes", type: "toggle" },
  campaign_filters: { name: "Blitz Filters", description: "Access to add filters to campaigns", type: "toggle" },
  goodies: { name: "Goodie Boxes", description: "Access to create and send Goodie Boxes", type: "toggle" },
  handwritten_cards: { name: "Handwritten Cards", description: "Access to create and send handwritten cards", type: "toggle" },
  hot_leads: { name: "Hot Leads", description: "Access to create and send hot leads", type: "toggle" },
}

export const permissionSubjects = Object.keys(permissionsDisplayValues);

export const groupedPermissionSubjects = {
  automations: ["automation_allotment", "automation_filters"],
  blitzes: ["commercial_blitz", "campaign_filters"],
  goodies: ["goodies", "handwritten_cards"],
  hotLeads: ["hot_leads"],
};

export const subscriptionTiers = ["freebie", "dope", "doper", "dopest"];

export const permissionsApi = {
  get: async () => {
    const resp = await dopeClient.get(`/permissions/1`); // id does not matter as permission is a singleton
    return resp.data.permission;
  },
  update: async (data) => {
    const resp = await dopeClient.put(`/permissions/1`, data);
    return resp.data.permission;
  },
};
