import React, { useState } from "react";
import { uniq } from "lodash";

import { Input } from 'rsuite';

import BelongsToSelect from "./BelongsToSelect";
import DopeSelect from "./DopeSelect";
import DopePill from "./DopePill";

const operatorOptions = [
  { label: 'Include', value: 'in' },
  { label: 'Exclude', value: 'not in' },
];

const DopeInlineFilter = ({ filters, field, label, modelName, options, onChange, valueKey = "id", labelKey = "name" }) => {
  const [editing, setEditing] = useState(false);
  const [operator, setOperator] = useState('in');

  const handleRemove = (filter, valueIndex) => {
    const nextFilters = filters.map(f => {
      if (f.field === filter.field && f.operator === filter.operator) {
        return {
          ...f,
          value: f.value.filter((v, i) => i !== valueIndex),
          value_labels: f.value_labels.filter((v, i) => i !== valueIndex),
        };
      }
      return f;
    });
    onChange(nextFilters);
  };

  const fieldFilters = filters.filter(f => f.field === field);
  let filterPills = fieldFilters.map(f => {
    const prefix = f.operator === 'not in' ? 'Exclude: ' : '';

    return f.value_labels.map((label, i) => {
      return (
        <DopePill
          key={f.operator + label}
          pillType="tag"
          text={`${prefix}${label}`}
          showCircle={false}
          removeable={editing}
          onClick={editing ? () => handleRemove(f, i) : null}
        />
      );
    });
  }).flat();

  if (!filterPills.length && !editing) {
    filterPills = [
      <DopePill
        key="empty"
        pillType="tag"
        text="All"
        showCircle={false}
        removeable={true}
        onClick={() => setEditing(true)}
      />,
    ];
  }

  const filtersDisplay = (
    <div className="flex flex-wrap gap-xs">
      {filterPills}
    </div>
  );

  const handleSelect = (update, item) => {
    const filter = filters.find(f => f.field === field && f.operator === operator);
    const nextFilter = {
      ...filter,
      field,
      operator,
      value: uniq([...filter.value, item[valueKey]]),
      value_labels: uniq([...filter.value_labels, item[labelKey]]),
    };
    const nextFilters = filters.map(filter => {
      if (filter.field === field && filter.operator === operator) {
        return nextFilter;
      }
      return filter;
    });
    onChange(nextFilters);
  };

  let selectInput;
  if (modelName) {
    selectInput = (
      <BelongsToSelect
        modelName={modelName}
        name={field}
        onChange={handleSelect}
        style={{ width: 400 }}
        value={null}
      />
    );
  } else if (options) {
    selectInput = (
      <DopeSelect
        options={options}
        value={null}
        name={field}
        onChange={handleSelect}
        style={{ width: 400 }}
        searchable={options.length > 10}
        valueKey={valueKey}
        labelKey={labelKey}
      />
    );
  } else {
    selectInput = (
      <Input
        type="text"
        placeholder="Press enter to add"
        onPressEnter={e => {
          if (e.target.value) {
            handleSelect({}, { [valueKey]: e.target.value.trim().toLowerCase(), [labelKey]: e.target.value });
            e.target.value = '';
          }
        }}
        style={{ width: 400 }}
      />
    );
  };

  const filterForm = (
    <div className="flex gap margin-bottom">
      <DopeSelect
        options={operatorOptions}
        value={operator}
        name="operator"
        onChange={update => setOperator(update.operator)}
        style={{ width: 120 }}
        searchable={false}
      />
      {selectInput}
    </div>
  );

  return (
    <>
      <div className="space-between margin-tb gap top-align">
        <div className="label large text-right" style={{ minWidth: 150 }}>{label}</div>
        <div className="flex-1">
          {editing && filterForm}
          {filtersDisplay}
        </div>
        <div className="link" onClick={() => setEditing(!editing)}>{editing ? 'Done' : 'Edit'}</div>
      </div>
    </>
  );
};

export default DopeInlineFilter;

