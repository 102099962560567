import React from "react";

import { uniqBy } from "lodash";

import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { IconButton } from "rsuite";
import CloseIcon from '@rsuite/icons/Close';

import useQuery from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import { toDisplayName } from "../utils/railsNames";

import DopeSelect from "./DopeSelect";
import DopePill from "./DopePill";
import {Icon} from "@rsuite/icons";
import {MdOutlineGroup} from "react-icons/md";

// TODO THIS IS REALLY HAS AND BELONGS TO MANY MULTI SELECT
// TODO REALLY THIS IS NOT GENERIC YET
// TODO REFACTOR

const SelectedList = ({ value, onRemove }) => {
  return (
    <div className="selected-items-list">
      {value.map((item, index) => (
        <div key={index} className="margin-4-t margin-4-r">
          <DopePill
            text={item.name}
            pillType="tag"
            removeable
            onClick={() => onRemove(index)}
            leftIcon={<Icon as={MdOutlineGroup} style={{ marginRight: "2px", color: "#255FDF", position: "relative", top: 1, fontSize: "18px"}} />}
          />
        </div>
      ))}
    </div>
  );
};

const HasManyMultiSelect = ({ modelName, name, values, labelKey = 'name', onChange }) => {
  const value = values[name] || [];
  const api = new DopeApi(modelName);

  const { allData: options, loading, setFilters } = useQuery(api, []);

  const handleSearch = (searchTerm) => {
    console.log('searchTerm', searchTerm) // TODO - this is called on select and on search, creating extra calls maybe switch to react-select
    setFilters([{ field: labelKey, operator: 'ilike', value: searchTerm }]);
  };

  const handleChange = (update) => {
    const fk = `${modelName}_id`;
    const id = update[name];
    const resource = options.find(option => option.id === id);
    const resourceLabel = resource[labelKey];
    const selection = { [fk]: id, name: resourceLabel, [modelName]: resource };
    const nextValue = uniqBy([...value, selection], fk);
    onChange({ [name]: nextValue });
  };

  const handleRemove = (index) => {
    const nextValue = [...value];
    nextValue.splice(index, 1);
    onChange({ [name]: nextValue });
  };

  const renderMenu = menu => {
    if (loading) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> Loading...
        </p>
      );
    }

    return menu;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
      <div style={{ width: 400, marginRight: 10 }}>
        <DopeSelect
          options={options}
          value={value}
          onChange={handleChange}
          placeholder={`Select ${toDisplayName(modelName)}`}
          labelKey={labelKey}
          valueKey="id"
          onSearch={handleSearch}
          name={name}
          renderMenu={renderMenu}
        />
      </div>
      <SelectedList value={value} onRemove={handleRemove} />
    </div>
  )
}

export default HasManyMultiSelect;
