import React from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const initial = {
  contact_id: null,
  list_id: null,
};

const inputs = [
  {
    inputType: 'belongsTo',
    name: 'contact_id',
    props: {
      label: 'Contact',
      belongsToModelName: 'contact',
      labelKey: 'full_name_and_address',
    }
  },
  {
    inputType: 'belongsTo',
    name: 'list_id',
    props: {
      label: 'List',
      belongsToModelName: 'list',
    }
  },
];

const ContactListForm = (props) => {
  return (
    <ModelForm
      modelName="contact_list"
      initialResource={initial}
      inputs={inputs}
      {...props}
    />
  );
};

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID' },
  { type: 'text', dataKey: 'contact_full_name_and_address', label: 'Contact' },
  { type: 'text', dataKey: 'list_name', label: 'List' },
];

function ContactListManagement(props) {
  return (
    <DopeManagement
      modelName="contact_list"
      modelForm={ContactListForm}
      columns={columns}
      scopeOptions={[]}
      {...props}
    />
  );
}

export default ContactListManagement;
