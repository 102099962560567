import React, { useState, useEffect } from "react";

import { orderBy } from "lodash";
import authApi from "../auth/authApi";

import { Grid, Row, Col } from "rsuite";

import DopeButton from "../ui/DopeButton";
import DopeTextInput from "../ui/DopeTextInput";
import Cents from "../ui/Cents";
import { useDopeUI } from "../ui/dopeUISlice";
import { errorsToErrorList } from "../utils/errors";

import DopeApi from "../services/DopeApi";

const productsApi = new DopeApi("product");
const productPricingTiersApi = new DopeApi("product_pricing_tier");
const productPricingTierDiscountsApi = new DopeApi("product_pricing_tier_discount");

const planKeys = ['freebie', 'lil_taste', 'dope', 'doper', 'dopest'];

const inputStyles = { width: 60, padding: '2px 4px' };
const buttonWrapperStyles = { position: 'absolute', top: 0, right: 0 };


const ProductStandardPricing = () => {
  const [dopeUI, uiActions] = useDopeUI();
  const [editing, setEditing] = useState(false);
  const [products, setProducts] = useState([]);
  const [productPricingTiers, setProductPricingTiers] = useState([]);
  const [productPricingTierDiscounts, setProductPricingTierDiscounts] = useState([]);

  const canEdit = authApi.currentUserIsSuperAdmin();

  useEffect(() => {
    productsApi.getMany({ per_page: 1000 }).then(setProducts);
    productPricingTiersApi.getMany({ per_page: 1000 }).then(setProductPricingTiers);
    productPricingTierDiscountsApi.getMany({ per_page: 1000 }).then(setProductPricingTierDiscounts);
  }, []);

  const handleDiscountChange = (id, nextDiscountCents) => {
    const nextProductPricingTierDiscounts = productPricingTierDiscounts.map(discount => {
      if (discount.id === id) {
        return { ...discount, nextDiscount: nextDiscountCents };
      }
      return discount;
    });
    setProductPricingTierDiscounts(nextProductPricingTierDiscounts);
  };

  const handleSave = () => {
    const updatedDiscounts = productPricingTierDiscounts.filter(discount => !!discount.nextDiscount);
    const updates = updatedDiscounts.map(discount => {
      return { id: discount.id, discount: discount.nextDiscount };
    });
    productPricingTierDiscountsApi.updateMany(updates)
      .then(resp => {
        uiActions.addFlashMessage({
          header: 'Success!',
          body: 'Pricing updated',
          type: 'success',
        });
        setEditing(false);
      })
      .catch(errorMessage => {
        uiActions.addFlashMessage({
          header: 'Error!',
          body: errorsToErrorList(errorMessage),
          type: 'error',
        });
      });
  };

  const handleCancel = () => {
    const nextProductPricingTierDiscounts = productPricingTierDiscounts.map(discount => {
      return { ...discount, nextDiscount: null };
    });
    setProductPricingTierDiscounts(nextProductPricingTierDiscounts);
    setEditing(false);
  };


  const productTierTableHeader = (
    <Row className="margin-16-b">
      <Col xs={3} className="label">Quantity</Col>
      {
        planKeys.map(planKey => {
          return [
            editing && <Col key="discount" xs={3} className="label text-upper">{planKey} ↓</Col>,
            <Col key="final" xs={3} className="label text-upper">{planKey}</Col>,
          ];
        })
      }
    </Row>
  );

  let buttons;
  if (!canEdit) {
    buttons = null;
  } else if (editing) {
    buttons = (
      <div style={buttonWrapperStyles}>
        <DopeButton onClick={handleCancel} className="margin-right">Cancel</DopeButton>
        <DopeButton onClick={handleSave} className="outlined-black">Save</DopeButton>
      </div>
    );
  } else {
    buttons = (
      <div style={buttonWrapperStyles}>
        <DopeButton onClick={() => setEditing(true)}>Edit</DopeButton>
      </div>
    );
  }

  return (
    <div className="product-pricing-wrapper">
      {buttons}
      {
        products.map(product => {
          const pricingTiers = orderBy(productPricingTiers.filter(tier => tier.product_id === product.id), 'minimum_quantity');

          return (
            <div key={product.id} className="margin-tb">
              <div className="header-4 margin-bottom">{product.label} - Base Price: <Cents>{product.default_unit_price}</Cents> </div>

              <Grid>
                {productTierTableHeader}

                {
                  pricingTiers.map((tier, tierIndex) => {
                    const nextTier = pricingTiers[tierIndex + 1];
                    const nextMinQuantity = nextTier ? nextTier.minimum_quantity - 1 : null;
                    const separator = nextMinQuantity ? ' - ' : '+';
                    return (
                      <Row key={tier.id} className="margin-bottom">
                        <Col xs={3}>{tier.minimum_quantity}{separator}{nextMinQuantity}</Col>
                        {
                          planKeys.map(planKey => {
                            const discount = productPricingTierDiscounts.find(discount => discount.subscription_key === planKey && discount.product_pricing_tier_id === tier.id);

                            let discountCents = 0;
                            if (discount) {
                              discountCents = discount.nextDiscount || discount.discount;
                            }
                            const finalPrice = product.default_unit_price - discountCents;

                            let discountInput = null;
                            if (editing) {
                              discountInput = (
                                <Col key="discount-input" xs={3}>
                                  <DopeTextInput
                                    type="number"
                                    min={0}
                                    style={inputStyles}
                                    value={discountCents}
                                    onChange={(update, nextDiscountCents) => {
                                      handleDiscountChange(discount?.id, nextDiscountCents);
                                    }}
                                  />
                                </Col>
                              );
                            }

                            return [
                              discountInput,
                              <Col key="final" xs={3}><Cents>{finalPrice}</Cents></Col>,
                            ];
                          })
                        }
                      </Row>
                    );
                  })
                }
              </Grid>

            </div>
          )
        })
      }
    </div>
  );
};

export default ProductStandardPricing;
