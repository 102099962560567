import DopeApi from "../services/DopeApi";
import DopeDrawer from "../ui/DopeDrawer";
import DopeTable from "../ui/DopeTable";

import useQuery from "../hooks/useQuery";
import { useEffect } from "react";
import DopeLoader from "../ui/DopeLoader";
import { formatPhone } from "../utils/phone";

const api = new DopeApi("tracking_number_events");

const columns = [
  { type: 'event_time', dataKey: 'created_at', label: 'Date', },
  { type: 'custom', dataKey: 'from', label: 'Call Number', render: (rowData) => {
    return formatPhone(rowData.from);
  }},
];

const TrackingNumberEventsDrawer = ({ open, onClose, trackingNumber }) => {
  const { tableProps, filters, setFilters, setScopes, scopes, rest, reload, loading } = useQuery(api, [],null, [{ name: 'calls' }]);

  useEffect(() => {
    if (open && trackingNumber) {

      setFilters([
        { field: 'tracking_number_id', value: trackingNumber.id, operator: '=' },
      ]);

    }
  }, [trackingNumber, open]);


  return (
    <DopeDrawer
      className="qr-code-scans-drawer"
      open={open}
      onClose={onClose}
      header={trackingNumber?.name ?? ""}
    >
        <div>
          {!trackingNumber && <DopeLoader />}
          {trackingNumber && (
            <>
              <p><small>{trackingNumber.calls_count} total calls</small></p>
              <DopeTable
                {...tableProps}
                columns={columns}
              />
            </>
          )}
        </div>
    </DopeDrawer>
  );
};

export default TrackingNumberEventsDrawer;
