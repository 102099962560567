import React, { useEffect } from "react";
import { Loader } from 'rsuite';
import { Icon } from '@rsuite/icons';
import EditIcon from '@rsuite/icons/Edit';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import { FaRegTrashAlt } from "react-icons/fa";

import { useContactUI } from "./contactUISlice";
import { useContact } from "./contactSlice";

import { Link } from "react-router-dom";
import DopePill from "../ui/DopePill";

const ContactDropdownItem = ({ icon, text, onClick, type }) => {
  if (type === 'link') {
    return (
      <Link to={onClick} className="vertical-align row clickable margin-16-b label large bold">
        <Icon as={icon} className="dropdown-item-icon margin-8-r" /> {text}
      </Link>
    )
  }

  return (
    <div className="vertical-align row clickable margin-16-b label large bold" onClick={onClick}>
      <Icon as={icon} className="dropdown-item-icon margin-8-r" /> {text}
    </div>
  )
}


const ContactDropdown = ({ rowData: currentContact }) => {
  const id = currentContact.contact_id ? currentContact.contact_id : currentContact.id;
  const [contact, contactActions, errors, loading] = useContact(id);
  const [contactUI, contactUIActions] = useContactUI();

  useEffect(() => {
    if (!contact || contact.id !== id)
      contactActions.get(id);
  }, []);

  const handleClickEdit = () => {
    contactUIActions.setDrawerContactId(id);
  };

  const handleDeleteContactClick = () => {
    contactUIActions.setDeleteModalContactId(id);
  }

  const contactDropdownItems = [
    { text: 'Edit Contact', icon: EditIcon, onClick: handleClickEdit },
    { text: 'View Contact Details', icon: UserBadgeIcon, onClick: `/contacts/${id}/details`, type: 'link' },
    { text: 'Delete Contact', icon: FaRegTrashAlt, onClick: handleDeleteContactClick },
  ];

  return (
    <>
      <div>
        {contactDropdownItems.map((item, index) => (
          <ContactDropdownItem key={index} {...item} />
        ))}

        <hr className="margin-16-b margin-16-t"/>

        <div className="vertical-align row clickable margin-8-b label large bold">Lists</div>

        {!loading && contact && contact.contact_lists.length > 0 && (
          <div className="flex row flex-wrap vertical-align">
            {contact && contact.contact_lists.slice(0, 3).map((contact_list) => (
              <div className="margin-4-r margin-4-b" key={contact_list.id}>
                <DopePill text={contact_list.name} url={`/lists/${contact_list.list_id}/details`} pillType="tag" trim={30}/>
              </div>
            ))}
            {contact && contact.contact_lists.length > 3 && (
              <div style={{fontSize: "11px", fontWeight: 700, color: "#255FDF", marginLeft: "2px"}}>
                +{contact.contact_lists.length - 3}
              </div>
            )}
          </div>
        )}

        {!loading && contact && (contact.is_lead || contact.is_client) && (
          <>
            <hr className="margin-16-b margin-16-t"/>
            <div className="vertical-align row clickable margin-8-b label large bold">Contact Status</div>
            <div className="flex row">
              {contact.is_lead && (
                <div className="margin-4-r margin-4-b">
                  <DopePill text="Lead" pillType="tag" trim={30}/>
                </div>
              )}

              {contact.is_client && (
                <div className="margin-4-r margin-4-b">
                  <DopePill text="Client" pillType="tag" trim={30}/>
                </div>
              )}
            </div>
          </>
        )}

        {loading && (<div className="label optional color-text-secondary vertical-align row"><Loader style={{marginRight: "5px"}}/>Loading Lists...</div>)}

        {!loading && contact && contact.contact_lists.length === 0 && (<div className="label optional color-text-secondary">Contact does not belong to any lists</div>)}

      </div>
    </>
  )
}

export default ContactDropdown;
