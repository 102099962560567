import React, { useState } from "react";

const DopeFavoriteIcon = ({ rowData, isFavorite, setFavorite }) => {
  const [favorite, setFavoriteState] = useState(isFavorite);
  const favoriteColor = favorite ? "#255FDF" : "#E0E0E0";

  const handleClick = () => {
    setFavoriteState(!favorite);
    setFavorite(rowData);
  }

  const icon = favorite ?  "★" : "☆";

  return (
    <div style={{ fontSize: "20px", cursor: "pointer", color: favoriteColor, margin: "0 0 2px 4px"}} onClick={handleClick}>
      {icon}
    </div>
  );
}

export default DopeFavoriteIcon;