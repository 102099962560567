import { useState } from 'react';

import { observer } from 'mobx-react-lite';

import DopeSelect from "../ui/DopeSelect";

// import existing section, import default tab component
import { SectionTab } from 'polotno/side-panel';
import BsTextareaT from '@meronex/icons/bs/BsTextareaT';

import DefaultTextSidePanel from './DefaultTextSidePanel';
// import MyTextSection  from './MyFonts/index';

const OPTIONS = [
  {
    value: 'layout',
    label: 'Layout Options',
  },
  {
    value: 'fonts',
    label: 'My Fonts',
  },
];

const TextSidePanel = {
  name: 'text',
  Tab: (props) => (
    <SectionTab name="Text" {...props} fontSize='30px'>
      <BsTextareaT icon="grid" width='20px' height='20px' />
    </SectionTab>
  ),
  Panel: observer((props) => {
    const DEFAULT = OPTIONS[0];

    const [tab, setTab] = useState(DEFAULT.value);

    return (
      <div className='custom-text'>
        <h2 className='title'>Text</h2>
        <div className='select-wrapper'>
          <DopeSelect
            name ='tab'
            value={tab}
            options={OPTIONS}
            defaultValue={DEFAULT.value}
            placeholder=''
            onChange={opt => {
              setTab(opt.tab);
            }}
          />
        </div>
        <div className='tabs-wrapper'>
          {tab === 'layout' && <DefaultTextSidePanel {...props} /> }
          {/* <DopeTabContent activeTab={tab?.value ?? 0}>
            <TabPane tabId={0}>
              <TextDefSection {...props} />
            </TabPane>
            <TabPane tabId={1}>
              <MyTextSection {...props} />
            </TabPane>
          </DopeTabContent> */}
        </div>
      </div>
    );
  }),
};

export default TextSidePanel;
