import React from 'react';
import ModelForm from "../ui/ModelForm";

const initial = {
  name: '',
  account_id: null,
};

const inputs = [
  { inputType: "text", name: "name", props: { label: "Name" } },
  { inputType: "belongsTo", name: "account_id", props: {
    label: "Account",
    belongsToModelName: "account"
  }},
];

const CampaignTemplateDetailsForm = (props) => {
  return (
    <ModelForm
      modelName="campaign_template"
      initialResource={initial}
      inputs={inputs}
      {...props}
    />
  );
};

export default CampaignTemplateDetailsForm;
