import { useState } from 'react';

import DopeSelect from "../ui/DopeSelect";

import { observer } from 'mobx-react-lite';

import { SectionTab } from 'polotno/side-panel';
import HiTemplate from '@meronex/icons/hi/HiTemplate';

import CustomTemplatesPanel from './CustomTemplatesPanel';
import CustomInspirationTemplatesPanel from './CustomInspirationTemplatesPanel';

import './Editor.scss';

const OPTIONS = [
  {
    value: 'templates',
    label: 'My Templates',
  },
  // {
  //   value: 'drafts',
  //   label: 'Draft Templates',
  // },
  {
    value: 'inspiration',
    label: 'Inspiration',
  }
];



const TemplatesSidePanel = {
  name: 'templates',
  Tab: (props) => (
    <SectionTab name="Templates" {...props}>
      <HiTemplate icon="new-text-box" />
    </SectionTab>
  ),
  Panel: observer((props) => {
    const [DEFAULT] = OPTIONS;

    const [tab, setTab] = useState(DEFAULT.value);

    return (
      <div className='custom-templates'>
        <h2 className='title'>Templates</h2>
        <div className='select-wrapper'>
            <DopeSelect
              name='tab'
              value={tab}
              options={OPTIONS}
              defaultValue={DEFAULT.value}
              placeholder=''
              onChange={opt => {
                setTab(opt.tab);
              }}
            />
          </div>
        <div className='tabs-wrapper'>
          {tab === 'templates' && <CustomTemplatesPanel {...props} /> }
          {tab === 'inspiration' && <CustomInspirationTemplatesPanel {...props} /> }
        </div>
      </div>
    );
  }),
};

export default TemplatesSidePanel;
