import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { useConversionReport } from './conversionReportSlice';
import dopeClient from '../services/dopeClient';

import { Divider, Pagination } from 'rsuite';

import DopePageLoader from '../ui/DopePageLoader';
import Cents from '../ui/Cents';
import DopeTable from '../ui/DopeTable';

import ConversionReportFilters from './ConversionReportFilters';

const columns = [
  { dataKey: 'full_name', label: 'Name', type: 'clickable_text', sortable: true },
  { dataKey: 'full_address', label: 'Address', type: 'address_text' },
  { dataKey: 'mail_piece_count', label: 'Total Mailers', type: 'text', sortable: true },
  { dataKey: 'first_dispatch_date', label: 'First Mailer', type: 'date', sortable: true },
  { dataKey: 'last_dispatch_date', label: 'Last Mailer', type: 'date', sortable: true },
];

const leadColumns = [
  ...columns,
  { dataKey: 'became_lead_at', label: 'Lead Date', type: 'date', sortable: true },
  { dataKey: 'lead_value', label: 'Lead Value', type: 'cents', sortable: true },
];

const clientColumns = [
  ...columns,
  { dataKey: 'became_client_at', label: 'Customer Date', type: 'date', sortable: true },
  { dataKey: 'client_value', label: 'Customer Value', type: 'cents', sortable: true },
];

const paginationLocale = {
  total: 'Total: {0}'
};


const ConversionHeroMetrics = ({ report, reportData, loading }) => {
  const { cost, roi, converts } = reportData;

  const cardClasses = classNames('border pad text-center flex-1', {
    'opacity-50': loading,
  });

  const costCard = (
    <div className={cardClasses}>
      <div className="label large">Total Mailers</div>
      <div className="header-3 margin-4-b margin-16-t"><Cents>{cost.total}</Cents></div>
      <div className="label large color-black">(total spend)</div>
      <Divider />
      <div className="label large bold color-text-secondary">
        {cost.count} total mailers sent
      </div>
    </div>
  );

  const valueCard = (
    <div className={cardClasses}>
      <div className="label large">Total Matches</div>
      <div className="header-3 margin-4-b margin-16-t"><Cents>{converts.aggregates.value}</Cents></div>
      <div className="label large color-black">({report.conversion_type} value on matches)</div>
      <Divider />
      <div className="label large bold color-text-secondary">
        {converts.aggregates.count} total matches
      </div>
    </div>
  );

  const roiCard = (
    <div className={cardClasses}>
      <div className="label large">Return On Investment</div>
      <div className="header-1 margin-header">{roi}%</div>
    </div>
  );

  return (
    <div className="space-between margin-tb gap">
      {costCard}
      {valueCard}
      {roiCard}
    </div>
  );
};

const conversionTypeToLabel = {
  client: 'Customer',
  lead: 'Lead',
};

const fetchReportData = debounce((params, setReportData, setLoading) => {
  dopeClient.get('/conversions', { params })
    .then((response) => {
      setReportData(response.data);
    })
    .finally(() => {
      setLoading(false);
    });
}, 500);

// TODO: figure out proper page back to 1 when filters change / useQuery somehow
const ConversionReportShow = () => {
  const {
    conversionReport: report,
    actions,
  } = useConversionReport();

  const navigate = useNavigate();

  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);

  const valueColumn = report.conversion_type === 'client' ? 'client_value' : 'lead_value';

  const [sortColumn, setSort] = useState(valueColumn);
  const [sortDirection, setSortType] = useState('desc');

  const params = {
    window_months: report.window_months,
    filters: report.filters,
    conversion_type: report.conversion_type,
    search_text: searchText,
    page,
    per_page: limit,
    sort_column: sortColumn,
    sort_direction: sortDirection,
  };

  const handleSortChange = (sortColumn, sortType) => {
    setSort(sortColumn);
    setSortType(sortType);
  };

  const tableProps = {
    sortColumn,
    sortType: sortDirection,
    onSortColumn: handleSortChange,
  };

  const paginationProps = {
    activePage: page,
    limit,
    total: reportData?.converts?.total,
    onChangePage: setPage,
    onChangeLimit: setLimit,
  };

  const setLoadingAndFetch = () => {
    setLoading(true);
    fetchReportData(params, setReportData, setLoading);
  };

  const onClickFullName = (rowData) => { // TODO: commenting out for now, need mailers sent to this address and not just contact
    // navigate(`/contacts/${rowData.id}/details`);
  };

  useEffect(() => {
    fetchReportData.cancel();
    setLoadingAndFetch();

    return fetchReportData.cancel;
  }, [JSON.stringify(params)]);

  if (!reportData) {
    return <DopePageLoader />;
  }

  const convertColumns = report.conversion_type === 'client' ? clientColumns : leadColumns;
  const conversionLabel = conversionTypeToLabel[report.conversion_type];

  return (
    <>
      <div>
        <div className="header-2">{report.name}</div>
        <p className="margin-tb margin-40-b">This report is currently comparing ALL contacts marked "{conversionLabel}" and sent mail. Use the filters below to update this report.</p>

        <ConversionHeroMetrics
          report={report}
          reportData={reportData}
          loading={loading}
        />

        <ConversionReportFilters
          report={report}
          onChange={actions.update}
        />

        <Divider />

        <DopeTable
          tableHeader={conversionLabel + "s"}
          data={reportData.converts.converts}
          columns={convertColumns}
          onSearchChange={setSearchText}
          onSearchClear={() => setSearchText('')}
          onClickFullName={onClickFullName}
          loading={loading}
          {...tableProps}
        />

        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          limitOptions={[10, 25, 50, 100]}
          className='margin-top'
          locale={paginationLocale}
          {...paginationProps}
        />
      </div>
    </>
  );
};

export default ConversionReportShow;
