import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PlusIcon from '@rsuite/icons/Plus';
import TrashIcon from "@rsuite/icons/Trash";
import { FiDownload } from "react-icons/fi";

import DopeListPage from "../ui/DopeListPage";
import DopeApi from "../services/DopeApi";
import authApi from "../auth/authApi";
import { useQuery2 } from "../hooks/useQuery";
import { useContact } from "./contactSlice";
import { useContactUI } from "./contactUISlice";
import { useDopeUI } from "../ui/dopeUISlice";
import { errorsToErrorList } from "../utils/errors";
import { handleFilterChange } from "../utils/fn";

import DopeQuickScopeButton from "../ui/DopeQuickScopeButton";
import AddContactsToListDrawer from "../lists/AddContactsToListDrawer";
import DeleteContactsModal from "./DeleteContactsModal";
import DopeButton from "../ui/DopeButton";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import ContactNameCell from "./ContactNameCell";
import FixContactCell from "./FixContactCell";

const contactApi = new DopeApi('contact');
const listApi = new DopeApi('list');
const taggingsApi = new DopeApi('taggings');

const getUrl = (rowData) => `/contacts/${rowData.id}/details`;

const columns = [
  { type: 'checkbox',  dataKey: 'id', flexGrow: 1 },
  { type: 'custom_component', dataKey: 'full_name', label: 'Name', Component: ContactNameCell, flexGrow: 3 },
  // { type: 'stacked_text', dataKey: ['full_name', 'company_name'], label: 'Name', flexGrow: 3, customStyles: { fontSize: '14px', fontWeight: 400, color: '#292B2E' }, getUrl, sortable: true },
  { type: 'address_text', dataKey: 'full_address', label: 'Full Address', flexGrow: 3, sortable: true },
  { type: 'timestamp', dataKey: 'created_at', label: 'Date Created', flexGrow: 2, sortable: true },
  { type: 'text', dataKey: 'shipped_mail_pieces_count', label: 'Mail Sent', flexGrow: 1 },
  { type: 'list_pills', dataKey: 'contact_lists', label: 'List(s)', flexGrow: 3 },
  { type: 'custom_component', dataKey: 'has_valid_info', label: '', Component: FixContactCell, flexGrow: 2 },
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'contact', flexGrow: 1 },
];

const ContactListPage = ({ setContactCount }) => {
  const [invalidScopeApplied, setInvalidScopeApplied] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [contact, contactActions] = useContact();
  const [contactUI, contactUIActions] = useContactUI();
  const [dopeUi, dopeUiActions] = useDopeUI();

  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, scopes, setScopes, reload, exportCSV, exporting }
    = useQuery2({
      api: contactApi,
      initialSearchScope: "full_name_and_address",
    });

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      if (location.state.applyInvalidScope) {
        applyInvalidScope();
        location.state.applyInvalidScope = false;
      }

      if (location.state.refresh) {
        reload();
        location.state.refresh = false;
      }
    }
  }, [location.state]);

  useEffect(() => {
    setContactCount(paginationProps.total)
  }, [paginationProps.total]);

  const applyListFilters = (lists) => {
    const filterObject = {
      filterField: "contact_lists.list_id",
      operator: "in",
      filterCategory: "Lists"
    }
    handleFilterChange(filters, setFilters, lists, filterObject)
  }

  const contactDesignations = [{label: "Clients", value: "is_client"}, {label: "Leads", value: "is_lead"}, {label: "Prospects", value: "is_prospect"}]
  const applyContactTypeFilters = (contactTypes, optionValue, checked) => {
    if (optionValue === "is_prospect") {
      applyProspectFilters(checked);
    } else {
      const filterObject = {
        filterField: optionValue ,
        operator: "=",
        filterCategory: optionValue
      }

      handleFilterChange(filters, setFilters, checked, filterObject);
    }
  }

  const applyProspectFilters = (checked) => {
    let filterObjects = [];
    ["is_client", "is_lead"].forEach((designation) => {
      const filterObject = {
        filterField: designation,
        operator: "!=",
        filterCategory: "is_prospect",
        allowMultiples: true
      }

      filterObjects.push(handleFilterChange(filters, setFilters, checked, filterObject));
    });

    if (filterObjects.filter(object => object !== undefined).length > 0) {
      setFilters([...filters, ...filterObjects])
    }
  }

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    }
    handleFilterChange(filters, setFilters, tags, filterObject);
  }

  const applyInvalidScope = () => {
    const scopeApplied = !invalidScopeApplied;
    if (scopeApplied) {
        setScopes([...scopes, {name: "with_special_chars_or_blank"}]);
    } else {
      setScopes(scopes.filter(scope => scope.name !== "with_special_chars_or_blank"));
    }
    setInvalidScopeApplied(scopeApplied);
  }

  const applyInvalidScopeButton = (<DopeQuickScopeButton onClick={() => applyInvalidScope()} label="Invalid Contacts" selected={invalidScopeApplied}/>);

  const filterInputs = [
    { title: "Lists",
      getOptions: (name = "") => listApi.getOptions({
        valueKey: "id",
        labelKey: "name",
        search: name.length > 0 ? {text: name, column: "name"} : {},
      }),
      applyFilter: applyListFilters,
      searchable: true
    },
    { title: "Contact Type", getOptions: () => contactDesignations, applyFilter: applyContactTypeFilters },
    { title: "Tags",
      getOptions: (name = "") => taggingsApi.getOptions( {
        valueKey: "tag_id",
        labelKey: "tag.name",
        filters: [{ field: "taggable_type", operator: "=", value: "Contact" }, {field: "tags.account_id", operator: "=", value: authApi.getCurrentAccountId()}],
        scopes: [{ name: "search_tag_by_name", args: name }]
      }),
      applyFilter: applyTagFilters,
      searchable: true
    }
  ];

  const addToListClick = (selectedIds) => {
    setSelectedContactIds(selectedIds);
    contactUIActions.toggleAddContactsToListDrawer();
  }

  const deleteContactsClick = (selectedIds) => {
    setSelectedContactIds(selectedIds);
    contactUIActions.toggleDeleteMultipleContactsModal();
  }

  const selectedActions = [
    { label: "Delete Contacts", action: deleteContactsClick, icon: <TrashIcon style={{ fontSize: "12px", marginRight: "4px" }} /> },
    { label: "Add to List", action: addToListClick, icon: <PlusIcon style={{ fontSize: "10px", marginRight: "4px" }} /> }
  ];

  const deleteSingleContact = async () => {
    try {
      await contactApi.destroy(contact.id);
      reload();
    } catch (error) {
      dopeUiActions.addFlashMessage({ header: "Error deleting contact", body: errorsToErrorList(error), type: "error" });
    } finally {
      contactUIActions.setDeleteModalContactId(null);
    }
  }

  return (
    <>
      <div className="to-right margin-4-b">
        <DopeButton
          icon={<FiDownload style={{ marginRight: "8px"}} />}
          loading={exporting}
          props={{
            label: "Export",
            onClick: () => exportCSV("contacts_export"),
            buttonClass: "outlined-black rectangle",
          }}
        />
      </div>

      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        selectedActions={selectedActions}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        filterInputs={filterInputs}
        filters={filters}
        setFilters={setFilters}
        scopeButtons={applyInvalidScopeButton}
      />

      <AddContactsToListDrawer
        open={!!contactUI.addContactsToListDrawer}
        onClose={contactUIActions.toggleAddContactsToListDrawer}
        contactIds={selectedContactIds}
      />

      <DeleteContactsModal
        selectedContactIds={selectedContactIds}
        setSelectedContactIds={setSelectedContactIds}
        open={!!contactUI.deleteMultipleContactsModal}
        onClose={contactUIActions.toggleDeleteMultipleContactsModal}
        reload={reload}
      />

      {contact && <DopeConfirmationModal
        open={!!contactUI.deleteModalContactId}
        size="sm"
        title={`Are you sure you want to delete ${contact.full_name ? contact.full_name : contact.company_name} from your contact lists?`}
        message="We will not be able to undo this action after completed."
        onConfirm={deleteSingleContact}
        onCancel={() => contactUIActions.setDeleteModalContactId(null)}
        confirmLabel="Yes, delete"
        cancelLabel="No, cancel"
        confirmButtonClass="filled-black action-button"
        cancelButtonClass="outlined-black action-button"
      />}

    </>
  )
}


export default ContactListPage;
