import React, { useState } from "react";
import { Drawer } from "rsuite";

import DopeApi from "../services/DopeApi";
import { useAccount } from "../accounts/accountSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeButton from "../ui/DopeButton";
import authApi from "../auth/authApi";
import { groupedPermissionSubjects, subscriptionTiers } from "../utils/permissions";
import EditAccountPermissionsSection from "./EditAccountPermissionsSection";
import DopeSelect from "../ui/DopeSelect";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";

const accountApi = new DopeApi("account");

const EditAccountPermissionsDrawer = ({ baselinePermissions, onClose, open, reload }) => {
  const [loading, setLoading] = useState(false);
  const [account, accountActions] = useAccount();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [confirming, setConfirming] = useState(false);

  if (!account) return null;

  const onPermissionChange = (key, value) => {
    accountActions.updateCustomPermissions({ [key]: value });
  }

  const onPermissionsSave = async () => {
    setLoading(true);
    try {
      await accountApi.update2({ id: account.id, actions: [{ name: 'update_custom_permissions', args: [JSON.stringify(account.custom_permissions)] }]});
      await authApi.fetchMe();
      reload();
      onClose();
      dopeUIActions.addFlashMessage({ body: "Account permissions saved", type: "success" });
    } catch (error) {
      dopeUIActions.addFlashMessage({ body: "Failed to save account permissions", type: "error" });
    } finally {
      setLoading(false);
    }
  }

  const onPermissionsKeySave = async () => {
    setLoading(true);
    try {
      await accountApi.update({ id: account.id, actions: [{ name: 'update_permissions_key', args: account.permissions_key }] });
      reload();
      dopeUIActions.addFlashMessage({ body: "Account permissions key saved", type: "success" });
    } catch (error) {
      dopeUIActions.addFlashMessage({ body: "Failed to save account permissions tier", type: "error" });
    } finally {
      setConfirming(false)
      setLoading(false);
      accountActions.get(account.id);
    }
  }

  const onPermissionsKeyChange = (value) => {
    accountActions.update({ permissions_key: value });
    setConfirming(true)
  }

  return (
    <div style={{fontFamily: "IBM Plex Sans"}}>
      {account &&
        <Drawer
          className="action-drawer"
          open={open}
          onClose={onClose}
          size="md"
          closeButton={true}>
          <Drawer.Body>
            <div>
              <div className="flex row space-between margin-bottom">
                <h3 className="header-3">Update feature tier</h3>
              </div>

              <div className="flex row margin-bottom vertical-align">
                {account.logo_url && <div className="account-logo margin-8-r">
                  <img src={account.logo_url} alt={`${account.name}-logo`} className="account-logo" />
                </div>}

                <div>
                  <div className="header-6 non-bold">{account.name}</div>
                  <div className="label large">{account.account_email}</div>
                </div>
              </div>

              <hr className="divider" />

              <div className="flex column margin-bottom">
                <label className="label margin-4-b">Features Tier</label>
                <DopeSelect
                  options={subscriptionTiers.map(tier => ({ value: tier, label: tier }))}
                  name="permissions_key"
                  value={account.permissions_key}
                  onChange={(update, item, value) => onPermissionsKeyChange(value)}
                  style={{width: 468, textTransform: "uppercase" }}
                />
              </div>

              {Object.entries(groupedPermissionSubjects).map(([section, permissionSubjects]) => (
                <EditAccountPermissionsSection
                  key={section}
                  sectionTitle={section}
                  permissionSubjects={permissionSubjects}
                  onPermissionChange={onPermissionChange}
                  baselinePermissions={baselinePermissions}
                />
              ))}

            </div>
          </Drawer.Body>
          <Drawer.Actions>
              <DopeButton
                props={{
                  buttonClass: "outlined-black",
                  onClick: onClose,
                  label: "Cancel",
                }}
              />
              <DopeButton
                props={{
                  buttonClass: "filled-black",
                  onClick: onPermissionsSave,
                  label: "Save",
                }}
                loading={loading}
              />
            </Drawer.Actions>
        </Drawer>
      }

      <DopeConfirmationModal
        open={confirming}
        title={"Update Permissions Tier"}
        message={`Are you sure you want to update ${account.name}'s permission tier to ${account.permissions_key}?`}
        onConfirm={onPermissionsKeySave}
        confirmButtonClass="filled-black action-button"
        cancelButtonClass="outlined-black action-button"
        confirmLabel={"Confirm"}
        onCancel={() => setConfirming(false)}
      />
    </div>
  );
}

export default EditAccountPermissionsDrawer;
