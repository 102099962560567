import React from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const creditTransactionInputs = [
  {
    inputType: 'belongsTo',
    name: 'account_id',
    props: {
      label: 'Account',
      belongsToModelName: 'account',
      labelKey: 'name',
    }
  },
  {
    inputType: 'simpleSelect',
    name: 'product_type',
    props: {
      label: 'Product Type',
      options: ['postcard', 'handwritten_card', 'goodies', 'data'],
    },
  },
  {
    inputType: 'text',
    name: 'amount',
    props: {
      label: 'Amount',
    },
  },
  {
    inputType: 'text',
    name: 'description',
    props: {
      label: 'Description',
    },
  }
];

const CreditTransactionForm = ({ initialCreditTransaction, ...rest }) => {
  return (
    <ModelForm
      modelName="credit_transaction"
      initialResource={initialCreditTransaction}
      inputs={creditTransactionInputs}
      {...rest}
    />
  );
};

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID' },
  { type: 'text', dataKey: 'account_name', label: 'Account Name' },
  { type: 'text', dataKey: 'product_type', label: 'Product Type' },
  { type: 'text', dataKey: 'amount', label: 'Amount' },
  { type: 'text', dataKey: 'description', label: 'Description' },
];

const scopeOptions = [];

function CreditTransactionManagement(props) {
  return (
    <DopeManagement
      modelName="credit_transaction"
      modelForm={CreditTransactionForm}
      columns={columns}
      scopeOptions={scopeOptions}
      noDefaults={true}
      {...props}
    />
  );
}

export default CreditTransactionManagement;
