import React from 'react';
import {Link} from "react-router-dom";

import LoginForm from './LoginForm';
import { Button } from  'rsuite';
import DopeLogo from '../icons/DopeLogo';


const loginFormStyles = {
  width: "468px",
  height: "auto",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 6px 6px 0px #BABABA40",
}


const LoginToggleForm = () => {

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <DopeLogo style={{ width: "169px", height: "73px", margin: "20px 0" }}/>
      <div style={loginFormStyles}>
        <h3 style={{marginBottom: "20px"}} >Sign In</h3>
        <LoginForm />
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px", alignItems: "center"}}>
          <Link to="/signup" style={{fontWeight: 600, fontSize: "14px", color: "#255FDF"}}>Don't have an account? Sign Up</Link>
          <Link to="/forgot_password" style={{fontWeight: 600, fontSize: "14px", color: "#255FDF"}}>Forgot Password?</Link>
        </div>
      </div>
    </div>
  );
};

export default LoginToggleForm;
