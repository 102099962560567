import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import {asNestedDestroyable, markRemovedForDestruction} from "../services/createModelSelectors";

const [contactSlice, asyncActions] = createModelSlice('contact', {});

export default contactSlice.reducer;

export const {
  updateContact,
  resetToInitial,
} = contactSlice.actions;

export const selectContact = (state) => state.contact.current;
export const selectPrevContact = (state) => state.contact.prev;
export const selectContactError = (state) => state.contact.error;
export const selectContactLoading = (state) => state.contact.isGetting || state.contact.isSaving;

export function useContact(contactId) {
  const dispatch = useDispatch();
  const contact = useSelector(selectContact);
  const prevContact = useSelector(selectPrevContact);
  const error = useSelector(selectContactError);
  const loading = useSelector(selectContactLoading);

  const actions = wrapAll({
    update: updateContact,
    resetToInitial: resetToInitial,
    get: asyncActions.get,
    destroy: asyncActions.destroy,
  }, dispatch);

  actions.save = (update = {}) => {
    const params = {
      ...contact,
      ...update,
      ...asNestedDestroyable(prevContact, contact, 'taggings'),
    };

    return dispatch(asyncActions.save(params));
  };

  actions.addTagToContact = (tags) => {
    const params = {
      ...contact,
      ...asNestedDestroyable(prevContact, {...contact, taggings: tags.taggings}, 'taggings'),
    }

    return dispatch(asyncActions.save(params))
  }

  actions.addContactToList = (contactList) => {
    const params = {
      ...contact,
      contact_lists_attributes: [contactList],
    }

    return dispatch(asyncActions.save(params))
  }

  actions.removeContactFromList = (contactList) => {
    const contactListToRemove = { id: contactList.value, list_id: contactList.list_id, _destroy: true };
    const params = {
      ...contact,
      contact_lists_attributes: [contactListToRemove],
    }

    return dispatch(asyncActions.save(params))
  }

  useEffect(() => {
    const shouldGet = contactId && (!contact || contact.id !== contactId);
    if (shouldGet) {
      actions.get(contactId);
    }
    // return actions.resetToInitial; TODO - want this?
  }, [contactId]);

  return [contact, actions, error, loading];
}
