import React, { useState } from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";

import DopeListPage from "../ui/DopeListPage";
import DopeFilters from "../ui/dopeFilters/DopeFilters";
import DopeChart from "../ui/DopeChart";
import DopeDrawer from "../ui/DopeDrawer";
import PulseUserEdit from "./PulseUserEdit";

const api = new DopeApi('user');
const accountApi = new DopeApi('account');

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', width: 50, sortable: true },
  { type: 'pill', dataKey: 'role', label: 'Role', width: 165, sortable: false },
  { type: 'pill', dataKey: 'dope_role', label: 'Dope Role', width: 165, sortable: false },
  { type: 'stacked_text', dataKey: ['full_name', 'email'], label: 'User', sortable: true },
  { type: 'text', dataKey: 'phone_number', label: 'Phone', width: 185, sortable: false },
  { type: 'timestamp', dataKey: 'created_at', width: 120, label: 'Created At', sortable: true },
  { type: 'button', dataKey: 'edit', width: 75, label: 'Edit', sortable: false },
];

const filterInputs = [
  {
    title: "Account",
    type: "MultiSelect",
    name: "account_ids",
    field: "account_assignments.account_id",
    searchable: true,
    getOptions: (text = "") => accountApi.getOptions({
      valueKey: 'id',
      labelKey: 'name',
      search: { text, column: 'name' },
    }),
  },
  {
    title: "Role",
    type: "MultiSelect",
    name: "role",
    field: "role",
    searchable: false,
    getOptions: () => ([
      { value: 'user', label: 'User' },
      { value: 'admin', label: 'Admin' },
      { value: 'super_admin', label: 'Super Admin' },
    ]),
  },
  {
    title: "Date Range",
    type: "DateRange",
    name: "created_at_range",
    field: "created_at",
  },
];

const chartOptions = {
  xFields: [
    { value: 'created_at', type: 'datetime' },
  ],
  initialXFunc: 'week',
  yFields: [
    { value: 'id', type: 'numeric' },
  ],
  initialYFunc: 'count',
};


const PulseUserTable = () => {
  const { error, tableProps, paginationProps, setFilters, filters, updateResource, chartDataParams, searchText, onSearchTextChange } = useQuery2({
    api,
    searchColumn: 'full_name',
  });
  const [editingId, setEditingId] = useState(null);

  const handleClickEdit = ({ id }) => {
    setEditingId(id);
  };

  const handleEditFinish = (success, user) => {
    console.log('handleEditFinish', success, user);
    if (success) {
      updateResource(user);
    }
    setEditingId(null);
  }

  const editDrawer = (
    <DopeDrawer
      open={!!editingId}
      onClose={() => setEditingId(null)}
    >
      <PulseUserEdit
        id={editingId}
        onFinish={handleEditFinish}
      />
    </DopeDrawer>
  );

  const filterBar = (
    <DopeFilters
      filterInputs={filterInputs}
      filters={filters}
      setFilters={setFilters}
      onSearchTextChange={onSearchTextChange}
      searchText={searchText}
    />
  );

  const listPage = (
    <DopeListPage
      columns={columns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      setFilters={setFilters}
      filters={filters}
      error={error}
      onClickEdit={handleClickEdit}
    />
  );

  const chart = (
    <div className="margin-tb-10">
      <DopeChart
        api={api}
        params={chartDataParams}
        options={chartOptions}
      />
    </div>
  );

  return (
    <>
      <div className="page">
        <div className="header-2 margin-bottom">All Users</div>
        {filterBar}
        {chart}
        {listPage}
      </div>

      {editDrawer}
    </>
  );
};

export default PulseUserTable;
