import React from "react";

import DopeTabs from "../ui/DopeTabs";
import AccountDetailsForm from "./AccountDetailsForm";
import AccountAssignmentManagement from "../accountAssignment/AccountAssignmentManagement";
import CreditTransactionManagement from "../credits/CreditTransactionManagement";

const AccountForm = (props) => {
  const accountId = props.id;
  const accountFilter = [{ field: 'account_id', operator: '=', value: accountId }];

  const initialAccountAssignment = {
    user_id: null,
    account_id: accountId,
    role: 'member',
  };

  const initialCreditTransaction = {
    account_id: accountId,
    product_type: 'postcard',
    amount: 1,
    description: '',
  };

  return (
    <DopeTabs>

      <DopeTabs.Tab name="accountDetails" label="Details">
        <AccountDetailsForm {...props} />
      </DopeTabs.Tab>

      <DopeTabs.Tab name="accountAssignments" label="Assignments" disabled={!accountId}>
        <AccountAssignmentManagement
          useDrawer={true}
          hideFiltering={true}
          initialFilters={accountFilter}
          formProps={{initialAccountAssignment}}
        />
      </DopeTabs.Tab>

      <DopeTabs.Tab name="accountCreditTransactions" label="Credit Transactions" disabled={!accountId}>
        <CreditTransactionManagement
          useDrawer={true}
          hideFiltering={true}
          initialFilters={accountFilter}
          formProps={{initialCreditTransaction}}
        />
      </DopeTabs.Tab>

    </DopeTabs>
  );
}

export default AccountForm;
