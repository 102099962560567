import React from "react";

import { useIntegrations } from "../integrationsSlice";

const ConnectionShow = ({ dataSource, onClickCancel }) => {
  const {
    destroyConnection,
    destroyingConnection,
    syncConnection,
    syncingConnection,
  } = useIntegrations();

  const handleDestroy = () => {
    destroyConnection(dataSource.connection_id)
      .then(onClickCancel);
  };

  const handleSync = () => {
    syncConnection(dataSource.connection_id);
  };

  return (
    <div className="connection__content">
      <div className="connection__content__actions">

        <button onClick={handleDestroy}>{destroyingConnection ? '...' : 'Disconnect'}</button>

        <button onClick={handleSync}>{syncingConnection ? 'Checking...' : 'Check For Events'}</button>

        <button onClick={onClickCancel}>Done</button>

      </div>
    </div>
  );
};

export default ConnectionShow;
