import React, { useState } from "react";
import { Form, Row, Col, Grid } from "rsuite";
import { useAccount } from "./accountSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeTextInput from "../ui/DopeTextInput";
import DopeButton from "../ui/DopeButton";

const AccountEmailNotificationForm = () => {
  const [account, actions] = useAccount();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [editing, setEditing] = useState(false);

  const handleAccountUpdate = async () => {
    try {
      actions.save();
      dopeUIActions.addFlashMessage({header: `${account.name}`, body: 'was successfully updated!', type: 'success' });
      setEditing(false)
    } catch (error) {
      dopeUIActions.addFlashMessage({header: `${account.name}`, body: 'could not be updated!', type: 'error' });
    }
  }

  return (
    <div style={{marginBottom: "60px"}}>
      <h5 style={{margin: "10px"}}>Email Notifications</h5>
      <Form fluid onSubmit={handleAccountUpdate}>
        <Grid fluid>
          <Row gutter={10} className="margin-16-b">
            <Col xs={12}>
              <Col xs={22}>
                <h6 style={{margin: "10px 0 0 0"}}>Account Emails</h6>
                <div style={{ fontWeight: 400, fontSize: "14px", color: "#6F6F6F", marginBottom: "10px"}}>
                  We will send you emails when your designs are ready, a credit card needs to be updated, or any other notifications surrounding the activity of your account that needs attention.
                </div>
              </Col>
            </Col>
            <Col xs={12} style={{paddingTop: "8px"}}>
              <label className="label">Email Address</label>
              <DopeTextInput
                name="account_email"
                value={account?.account_email}
                onChange={actions.update}
                className="margin-4-b"
                disabled={!editing}
              />
            </Col>
          </Row>
          <Row gutter={10} className="margin-16-b">
            <Col xs={12}>
              <Col xs={22}>
                <h6 style={{margin: "10px 0 0 0"}}>Billing Emails</h6>
                <div style={{ fontWeight: 400, fontSize: "14px", color: "#6F6F6F", marginBottom: "10px"}}>
                  We will send order summary emails including invoices so you know exactly how much you are spending.  Please include the email address you would like those to go to and how often you want to receive them.
                </div>
              </Col>
            </Col>
            <Col xs={12} style={{paddingTop: "8px"}}>
              <label className="label">Email Address</label>
              <DopeTextInput
                name="billing_email"
                value={account?.billing_email}
                onChange={actions.update}
                className="margin-4-b"
                disabled={!editing}
              />
            </Col>
          </Row>
        </Grid>

        <div className="to-right row margin-16-t">
          <DopeButton
            props={{
              label: editing ? "Cancel" : "Edit",
              buttonClass: "outlined-black",
              onClick: () => setEditing(!editing)
            }}
            className="submit margin-4-r"
          />

          {editing && <DopeButton
            props={{
              type: "submit",
              label: "Save",
              buttonClass: "filled-black"
            }}
            className="submit"
          />}
        </div>
      </Form>
    </div>
  )
}

export default AccountEmailNotificationForm;
