import React from "react";
import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import DopeTable from "../ui/DopeTable";

const mailRoutePieceApi = new DopeApi('mail_route_piece');

const columns = [
  { type: 'text', dataKey: 'zip_code', label: 'Zip Code' },
  { type: 'text', dataKey: 'crid_id', label: 'Carrier Route', sortable: true },
  { type: 'text', dataKey: 'total_delivery_addresses', label: 'Delivery Addresses', sortable: true },
  { type: 'text', dataKey: 'audience', label: 'Audience Type' },
  { type: 'text', dataKey: 'mail_template_size', label: 'Mailer Size' }
]

const EddmDispatchDrawer = ({ eddmDispatch }) => {
  const { error, tableProps, paginationProps }
    = useQuery2({
      api: mailRoutePieceApi,
      initialFilters: [{ field: 'dispatch_id', operator: '=', value: eddmDispatch.id }],
    });

  return (
    <div>
      <h2 className="header-2">EDDM Dispatch {eddmDispatch.id} Details</h2>
      <h4 className="header-4 margin-40-b color-text-secondary">{eddmDispatch.account_name}</h4>

      <DopeTable
        {...tableProps}
        paginationProps={paginationProps}
        error={error}
        columns={columns}
      />
    </div>
  );
}

export default EddmDispatchDrawer;
