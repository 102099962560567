import { useEffect, useRef, useState } from "react";

import DopeButton from "../ui/DopeButton";

import DopeLoader from "../ui/DopeLoader";

import DopeApi from "../services/DopeApi";
import "./Agency.scss";

import { useNavigate } from "react-router-dom";
import { useAgency } from "./agencySlice";
import { useQuery2 } from "../hooks/useQuery";
import DopeListPage from "../ui/DopeListPage";
import ImageIcon from "@rsuite/icons/Image";

import { simpleColumns } from "./../agencies/AgencyClientsTable";
import DopeTabs from "../ui/DopeTabs";
import { formatDateStringToLocaleDateString, timestampToDateStr } from "../utils/date";
import DopeCentsInput from "../ui/DopeCentsInput";
import Cents from "../ui/Cents";
const accountLogoStyles = {
  height: "80px",
  width: "80px",
  borderRadius: 100,
  // boxShadow: "0px 6px 6px 0px #BABABA40",
  border: "1px solid #F3F3F3"
};

const accountNameStyles = {
  fontSize: "20px",
  fontWeight: 600,
};

const subHeaderStyles = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#8793A6",
};

const api = new DopeApi("agencies");
const clientsApi = new DopeApi('agency_clients');
const historyApi = new DopeApi("activity_history");


const imagePlaceholder = (
  <div style={{...accountLogoStyles, display: "flex", alignItems: "center", justifyContent: "center"}}>
    <ImageIcon style={{ color: "#8793A6", fontSize: "48px" }} />
  </div>
);

const ClientsTable = ({ agencyId }) => {
  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, scopes, setScopes, reload }
  = useQuery2({
    api: clientsApi,
    initialScopes: [{name: 'clients_for_agency', args: agencyId}],
  });

  return (
    <DopeListPage
      columns={simpleColumns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      error={error}
      filterInputs={[]}
      onSearchChange={onSearchInput}
      onSearchClear={onSearchClear}
      setFilters={setFilters}
      rowHeight={100}
    />
  );
};

const activityColumns = [
  { type: "custom", dataKey: 'id', label: "What Happened?", flexGrow: 3, sortable: false, render: (row) => {
    const { data, action, category } = row;

    if (category === 'agency') {
      let title = "Client Added";

      if (action === 'removed as a client') {
        title = "Client Removed";
      } else if (action === 'kickback updated') {
        title = "Kick"
      }

      return (
        <div>
          <p>{title}</p>
          <p><small>"{data.account.name}" was {action}</small></p>
        </div>
      );
    }

    if (category === 'kickback') {
      return (
        <div>
          <p>Kickback Updated</p>
          <p><small><Cents>{data.kickback.amount}</Cents> on {formatDateStringToLocaleDateString(data.kickback.start_date)}</small></p>
        </div>
      );
    }

    return null;

  }},
  { type: "timestamp", dataKey: 'created_at', label: "Date", flexGrow: 2, sortable: true, },
  { type: "user", dataKey: 'creator', label: "User", flexGrow: 2, sortable: false, },
];

const ActivityTable = ({ agencyId }) => {
  const {
    tableProps,
    paginationProps
  } = useQuery2({
    api: historyApi,
    initialScopes: [
      {
        name: 'for_agency',
        args: agencyId
      }
    ]
  });

  return (
    <DopeListPage
      columns={activityColumns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      // error={error}
      // filterInputs={[]}
      // onSearchChange={onSearchInput}
      // onSearchClear={onSearchClear}
      // setFilters={setFilters}
      rowHeight={100}
    />
  );
};


const AgencyDrawer = ({ id = "new", onClose, setKickback, kickback,setShowKickbackUpdate  }) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('clients');

  const { agency, actions, isSaving } = useAgency(id);

  useEffect(() => {

    return () => {
      actions.resetToInitial();
    };
  }, []);

  useEffect(() => {
    if (agency && agency.kickback) {
      setKickback({
        amount: agency.kickback.amount / 100,
        id: agency.kickback.id,
      });
    }
  }, [agency]);


  if (!agency) {
    return <DopeLoader />;
  }

  return (
    <div className="agency-drawer">
      <div className="agency-drawer-header">
        <div className="agency-card">
          { agency.logo_url ? <img src={agency.logo_url} alt="logo" style={accountLogoStyles} /> : imagePlaceholder }
          <div>
            <p style={accountNameStyles}>{agency.name}</p>
            <p style={subHeaderStyles}>{agency.account_email}</p>
          </div>
        </div>
        <div>
          <p style={subHeaderStyles}>Account ID #: {agency.id}</p>
          <p style={subHeaderStyles}>Joined Date: {timestampToDateStr (agency.created_at)}</p>
        </div>
      </div>
      <div className="agency-drawer-body">
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <div className="margin-tb-10" >
            <div className="label margin-4-b">Kickback</div>
            <DopeCentsInput
              name="amount"
              value={kickback?.amount || 0}
              onChange={({ amount }) => {
                setKickback({ ...kickback, amount });
              }}
            />
            {agency?.kickback?.next_period && <p style={{color: '#8793A6'}}><small><Cents>{agency.kickback.next_period.amount}</Cents> on {formatDateStringToLocaleDateString(agency.kickback.next_period.start_date)}</small></p>}
          </div>
          <div>
          <DopeButton
            props={{
              label: "Update",
              onClick: () => setShowKickbackUpdate(true),
              buttonClass: "filled",
              loading: isSaving,
            }}
          />
          </div>

        </div>

        <DopeTabs defaultTab={tab} onChange={(tab) => setTab(tab)}>
          <DopeTabs.Tab name="clients" label="Clients">
            <ClientsTable agencyId={agency.id} />
          </DopeTabs.Tab>
          <DopeTabs.Tab name="activity" label="Activity">
            <ActivityTable agencyId={agency.id} />
          </DopeTabs.Tab>
        </DopeTabs>
      </div>


    </div>
  );

};

export default AgencyDrawer;
