import React, { useState } from "react";

import { useIntegrations } from "../integrationsSlice";

const ConnectionOAuth = ({ dataSource, onClickCancel }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { queryConnections } = useIntegrations();

  const linkData = dataSource.config.link;

  const handleOAuthClose = (error) => { // TODO errors
    if (error) {
      setError(error);
      setLoading(false);
    } else {
      setLoading(false);
      setSuccess(true);
      queryConnections();
    }
  };

  const handleConnect = () => {
    setLoading(true);
    window.__onClose = (error) => {
      handleOAuthClose(error);
      window.__onClose = undefined;
    };
    const popup = window.open(linkData.href, 'popup', 'width=800,height=800');
  };


  // TODO ui broken on success

  return (
    <div className="connection-form">
      <div className="connection-form__actions">
        <button
          className="connection-form__cancel_button"
          onClick={onClickCancel}
        >Cancel</button>

        <button
          className="connection-form__connect_button"
          onClick={handleConnect}
        >{linkData.label}</button>
      </div>

      {loading && (
        <div className="connection-form__loading">
          <div className="connection-form__loading__spinner" />
          <div className="connection-form__loading__text">Waiting For OAuth Connection...</div>
        </div>
      )}

      {error && (
        <div className="connection-form__error">
          Error: {error}
        </div>
      )}

      {success && (
        <div className="connection-form__success">
          <h4 className="message">Success!</h4>
          <button className="done" onClick={onClickCancel}>Done</button>
        </div>
      )}
    </div>
  );
};

export default ConnectionOAuth;
