import React, { useState } from "react";

import { Input, InputGroup, Tooltip, Whisper } from 'rsuite';
import CopyIcon from '@rsuite/icons/Copy';

const CopyInput = ({ value, prefix, textarea = false, rows, inputStyles = {} }) => {
  const [showCopied, setShowCopied] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(value);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 1000);
  };

  if (textarea) {
    inputStyles = {
      ...inputStyles,
      backgroundColor: "rgb(41,44,51)",
      color: "white",
    };
  }

  return (
    <InputGroup>
      {prefix && <InputGroup.Addon>{prefix}</InputGroup.Addon>}

      <Input value={value} as={textarea ? "textarea" : "input"} rows={rows} style={inputStyles} readOnly />

      <Whisper
        trigger="none"
        placement="top"
        open={showCopied}
        speaker={<Tooltip arrow={false}>Copied!</Tooltip>}
      >
        <InputGroup.Button onClick={copy}><CopyIcon /></InputGroup.Button>
      </Whisper>
    </InputGroup>
  );
};

export default CopyInput;
