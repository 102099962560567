import React, { useEffect, useState } from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import { useAccount } from "../accounts/accountSlice";
import DopeListPage from "../ui/DopeListPage";
import EditAccountPricingDrawer from "./EditAccountPricingDrawer";
import EditAccountPermissionsDrawer from "./EditAccountPermissionsDrawer";
import { permissionsApi } from "../utils/permissions";
import './Pulse.scss'
import DopeButton from "../ui/DopeButton";
import { FiDownload } from "react-icons/fi";
import { useDopeUI } from "../ui/dopeUISlice";

const columns = [
  { type: 'text', dataKey: 'name', label: 'Client', sortable: true, flexGrow: 1.5 },
  { type: 'text', dataKey: 'subscription_name', label: 'Subscription Tier', className: 'text-upper', flexGrow: 1 },
  { type: 'text', dataKey: 'subscription_key', label: 'Pricing Tier', className: 'text-upper', flexGrow: 1 },
  { type: 'text', dataKey: 'permissions_key_display', label: 'Features Tier', className: 'text-upper', flexGrow: 1 },
  { type: 'button', dataKey: 'edit_pricing', label: 'Edit Pricing', sortable: false, flexGrow: 0.75 },
  { type: 'button', dataKey: 'edit_permissions', label: 'Edit Permissions', sortable: false, flexGrow: 1},
];

const accountApi = new DopeApi("account");

const AccountsPricingTable = () => {
  const [openPriceDrawer, setOpenPriceDrawer] = useState(false);
  const [openPermissionsDrawer, setOpenPermissionsDrawer] = useState(false);
  const [baselinePermissions, setBaselinePermissions] = useState({});
  const [loading, setLoading] = useState(false);
  const [account, accountActions] = useAccount();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const { tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, reload }
    = useQuery2({
    api: accountApi,
    initialSearchScope: "name",
  });

  useEffect(() => {
    const fetchPermissions = async () => {
      const currentPermissions = await permissionsApi.get();
      setBaselinePermissions(currentPermissions.permissions_settings);
    }

    fetchPermissions();
  }, []);

  const closeDrawer = () => {
    setOpenPriceDrawer(false)
    setOpenPermissionsDrawer(false)
  };

  const editPricingSelect = async (accountId) => {
    await accountActions.get(accountId)
    setOpenPriceDrawer(true)
  };

  const editPermissionsSelect = async (accountId) => {
    await accountActions.get(accountId)
    setOpenPermissionsDrawer(true)
  };

  const handleAccountsDownload = async () => {
    setLoading(true);
    try {
      await accountApi.exportCSV({ filters }, "account_pricing_tiers_export");
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Export Failed", type: "error" });
    } finally {
      setLoading(false);
    }
  }


  return (
    <div>
      <div className="to-right margin-4-b">
        <DopeButton
          icon={<FiDownload style={{ marginRight: "8px"}} />}
          loading={loading}
          props={{
            label: "Export",
            onClick: handleAccountsDownload,
            buttonClass: "outlined-black rectangle",
          }}
        />
      </div>

      <DopeListPage
        title="Pricing"
        tableProps={tableProps}
        paginationProps={paginationProps}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        filters={filters}
        columns={columns}
        reload={reload}
        onClickEditPricing={account => editPricingSelect(account.id)}
        onClickEditPermissions={account => editPermissionsSelect(account.id)}
      />

      <EditAccountPricingDrawer
        onClose={closeDrawer}
        open={openPriceDrawer}
        reload={reload}
        accountId={account?.id}
      />

      <EditAccountPermissionsDrawer
        onClose={closeDrawer}
        open={openPermissionsDrawer}
        baselinePermissions={baselinePermissions}
        reload={reload}
      />
    </div>
  );
};

export default AccountsPricingTable;
