import React from "react";

import ConnectionForm from "./ConnectionForm";
import ConnectionOAuth from "./ConnectionOAuth";
import ConnectionShow from "./ConnectionShow";

const TOKEN_TYPES = {
  OAUTH2: 'OAUTH2',
  SIMPLE: 'SIMPLE',
  OAUTH_CLIENT_GRANT: 'OAUTH_CLIENT_GRANT',
};

const Connection = ({ dataSource, onClickCancel }) => {
  if (dataSource.connected) {
    return <ConnectionShow dataSource={dataSource} onClickCancel={onClickCancel} />;
  }

  const shouldUseForm = [TOKEN_TYPES.OAUTH_CLIENT_GRANT, TOKEN_TYPES.SIMPLE].includes(dataSource.token_type);
  if (shouldUseForm) {
    return <ConnectionForm dataSource={dataSource} onClickCancel={onClickCancel} />;
  }

  if (dataSource.token_type === TOKEN_TYPES.OAUTH2) {
    return <ConnectionOAuth dataSource={dataSource} onClickCancel={onClickCancel} />;
  }
}

export default Connection;
