import './HandwrittenFontItem.scss';


const HandwrittenFontItem = ({ selected, onClick, ...font }) => {
  return (
    <div
      className={`font-item${selected ? ' font-item--selected' : ''}${typeof onClick === 'function' ? ' font-item--selectable' : ''}`}
      onClick={() => onClick && onClick(font)}
    >
      <p style={{fontFamily: font.id }}>{font.label}</p>
    </div>
  );
};

export default HandwrittenFontItem;
