import React from 'react';

import iconMap from "../ui/dopeIconMap";

import { DopeToolTip } from '../ui/DopeToolTip';

const iconStyles = {
  fontSize: '24px',
};

const tooltipStyles = {
  background: 'black',
  color: 'white',
  minWidth: 10,
  padding: '4px 16px',
};

const audienceTypeKeyToLabel = {
  list: "List Campaign",
  list_blitz: "List Blitz",
  blitz: "Neighborhood Blitz",
  eddm: "EDDM",
};

const CampaignStatusCell = ({ rowData }) => {
  const { audience_generation_type_key } = rowData;
  const iconKey = `${audience_generation_type_key}_campaign`;
  const icon = iconMap[iconKey];
  const tooltipContent = audienceTypeKeyToLabel[audience_generation_type_key];

  return (
    <DopeToolTip content={tooltipContent} placement="left" style={tooltipStyles} arrow>
      <span style={iconStyles} className="vertical-align">{icon}</span>
    </DopeToolTip>
  );
};

export default CampaignStatusCell;
