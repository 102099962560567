import React, { useState, useEffect } from "react";
import { Drawer } from "rsuite";
import { MdOutlineFilterAlt } from "react-icons/md";

import DopeApi from "../services/DopeApi";

import { DopeFormGroup } from "../ui/DopeForm";
import DopeButton from "../ui/DopeButton";
import { useCampaign } from "./campaignSlice";
import { useAutomation } from "../automations/automationSlice";
import accountAbility from "../auth/accountAbility";
import Cannot from "../ui/Cannot";
import CannotInfoBox from "../ui/CannotInfoBox";

const filterApi = new DopeApi("data_axle_filter");

const cannotUseFiltersDisplay = <CannotInfoBox
  icon={MdOutlineFilterAlt}
  body="Your subscription does not include filters. Feel free to play around with the tool and get in touch with us if you’re interested in adding it on."
  classes="negative-top-margin"
/>;

const AudienceFiltersInput = ({ onUpdate, onRemove, values, buttonClass = "elevated full-width" }) => { // values here is listGenerationSetting
  const [filters, setFilters] = useState([]);
  const [open, setOpen] = useState(false);
  const [campaign] = useCampaign();
  const { automation } = useAutomation();

  const audienceScope = {
    residential: "people",
    all_residential: "people",
    commercial: "places",
  }[values.audience_type];

  useEffect(() => { // TODO minor loader
    filterApi.query({ scopes: [{ name: "active" }, { name: audienceScope }], sort_direction: 'asc' })
      .then(data => {
        setFilters(data.data_axle_filters);
      })
      .catch(error => console.log(error));
  }, [audienceScope]);

  const handleInputChange = (update) => {
    onUpdate(update);
  };

  const handleInputRemove = (filterKey) => {
    onRemove(filterKey);
  };

  const hasCampaignFilterPermissions = accountAbility.canUseFeature('create', 'ListGenerationSetting', ['data_axle_filters']);
  const hasAutomationFilterPermissions = accountAbility.canUseFeature('create', 'CampaignTemplate', ['list_generation_setting', 'data_axle_filters']);

  const filterInputs = filters.map(filter => {
    const inputConfig =  {
      inputType: 'data_axle_filter',
      name: 'data_axle_filters',
      customInput: 'DataAxleFilterInputs',
      props: {
        inputType: filter.data_type,
        label: filter.display_name,
        helperText: filter.description,
        labelKey: filter.filter_attribute,
        filter: filter,
        disabled: campaign ? !hasCampaignFilterPermissions : !hasAutomationFilterPermissions,
        values,
        onChange: handleInputChange,
        onRemove: handleInputRemove,
      }
    };

    return <DopeFormGroup key={filter.id} input={inputConfig} values={values} onChange={handleInputChange} />;
  });


  const cannotUseFilters = {
    subject: "Campaign",
    action: "create",
    data: { list_generation_setting: { audience_type: campaign?.list_generation_setting?.audience_type, data_axle_filters: [{ any_filter: "present" }] } },
  }

  const cannotCreateAutomation = {
    subject: "CampaignTemplate",
    action: "create",
    data: { list_generation_setting: { audience_type: automation?.campaign_template?.list_generation_setting?.audience_type, data_axle_filters: [{ any_filter: "present"}] } },
  }

  const cannotCreate = automation ? cannotCreateAutomation : cannotUseFilters;

  return (
    <>
      <DopeButton
        props={{
          buttonClass: buttonClass,
          label: "Add filters",
          onClick: () => setOpen(true),
        }}
        icon={<MdOutlineFilterAlt style={{fontSize: "16px", marginRight: "4px"}} />}
      />


      <Drawer
        size="sm"
        placement="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Drawer.Header style={{ borderBottom: "none"}}>
          <div className="flex column">
            <h4 className="header-4 margin-4-t">How else do you want to narrow down your audience?</h4>
          </div>
        </Drawer.Header>
        <Drawer.Body style={{paddingTop: 16}}>
          <Cannot action="create" subject={cannotCreate.subject} data={cannotCreate.data} cannotDisplay={{ display: cannotUseFiltersDisplay }} />

          <div className="rs-form">
            {filterInputs}
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default AudienceFiltersInput;
