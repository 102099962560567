import { DatePicker } from 'rsuite';
import { toDateString, fromDateString } from '../utils/date';

const style = { minWidth: 200, width: '100%' };

const DopeDatePicker = ({ name, value, onChange, ...rest }) => {
  const dateValue = value ? fromDateString(value) : null;
  return (
    <DatePicker
      value={dateValue}
      onChange={value => onChange({ [name]: toDateString(value) }, toDateString(value))}
      style={style}
      {...rest}
    />
  );
};

export default DopeDatePicker;
