import React from 'react'
import SignaturePad from 'react-signature-canvas'

const DopeSignaturePad = ({ handleSignatureInput }) => {
  let sigPad = {}
  const clear = () => {
    sigPad.clear()
  }
  const trim = () => {
    return sigPad.getTrimmedCanvas().toDataURL('image/png')
  }

  return (
    <div style={{height: "180px"}}>
      <div style={{height: "150px", position: 'relative', border: '2px dashed #EAECEF', borderRadius: "8px"}}>
        <SignaturePad
          canvasProps={{style: {width: "100%", height: "100%"}}}
          ref={(ref) => {
            sigPad = ref
          }}
          onEnd={() => handleSignatureInput(trim)}
        />

        <div style={{
          position: 'absolute',
          top: '65%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#8793A6',
          fontSize: '16px',
          pointerEvents: 'none',
          width: "90%"
        }}>
          <div className="text-center">SIGN HERE</div>
          <div className="full-width border-bottom">x</div>
        </div>

        <button onClick={clear} style={{backgroundColor: "transparent", fontWeight: 600, fontSize: "14px", color: "#136EC2"}}>
          Clear
        </button>
      </div>
    </div>
  )
}

export default DopeSignaturePad
