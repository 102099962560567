import React, { Component } from 'react';

import './ErrorBoundary.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const header = this.props.header || 'Something went wrong.';
      const message = this.props.message || 'This error has been reported. Please try saving your work, refreshing the page, and trying again.';
      return (
        <div className="error-boundary">
          <div className="error-boundary_header">{header}</div>
          <div className="error-boundary_message">{message}</div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
