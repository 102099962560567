import React, { useEffect } from 'react';
import { Message } from 'rsuite';

import { useDopeUI } from "./dopeUISlice";

const flashMessagesStyle = {
  position: 'fixed',
  top: 20,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 10000000,
}

function DopeFlashMessage({ message }) {
  const [dopeUI, dopeUIActions] = useDopeUI();
  const { id, type, header, body, timeout } = message;

  const handleClose = () => {
    dopeUIActions.removeFlashMessage(id);
  };

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, timeout || 3000);
  }, []);

  let content = body;
  if (typeof body === 'object') {
    content = body.map((line, i) => {
      return <div key={i}>{line}</div>;
    });
  }

  return (
    <Message
      showIcon
      type={type}
      header={header}
      closable={true}
      onClose={handleClose}
      style={{ minWidth: 320 }}
    >
      {content}
    </Message>
  );
}

function DopeFlashMessages() {
  const [dopeUI] = useDopeUI();
  const { flashMessages } = dopeUI;

  const messages = flashMessages.map(message => {
    return (
      <DopeFlashMessage
        key={message.id}
        message={message}
      />
    )
  });

  return (
    <div className="flash-messages" style={flashMessagesStyle}>
      {messages}
    </div>
  );
}

export default DopeFlashMessages;
