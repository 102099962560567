import React from "react";

import { useIntegrations } from "../integrationsSlice";

import DataSourceItem from "./DataSourceItem";

const DataSourceList = ({ onClickConnect }) => {
  const { dataSources } = useIntegrations();

  return (
    <div className="data-source-list">
      {dataSources.map((dataSource, index) => (
        <DataSourceItem
          key={index}
          dataSource={dataSource}
          onClickConnect={onClickConnect}
        />
      ))}
    </div>
  );
};

export default DataSourceList;
