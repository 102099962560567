import React from 'react';
import Select from 'react-select';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    display: 'inline-block',
    width: 'auto',
    fontSize: '12px',
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: '18px',
    height: '18px',
    maxHeight: '18px',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    cursor: 'pointer',
    borderRadius: '0',
    padding: '0',
    alignItems: 'flex-start',
    overflow: 'hidden',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '18px',
    padding: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition, top: '-2px' };
  },
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '18px',
    padding: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    borderRadius: 0,
    minWidth: 75,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '2px 4px',
    fontSize: '12px',
  }),
};

const TinySelect = (props) => {
  return (
    <Select
      styles={customStyles}
      isSearchable={false}
      {...props}
    />
  );
};

export default TinySelect;
