import React from 'react';
import classNames from 'classnames';

const DataSourceItem = ({ dataSource, isSelected = false, isDisabled = false, onClick = () => {}, onClickConnect = false }) => {

  const disabled = isDisabled || dataSource.coming_soon;

  const itemClasses = classNames({
    'data-source-item': true,
    'data-source-item--selected': isSelected,
    'data-source-item--disabled': disabled,
  });

  let connectButton = null;
  if (onClickConnect) {
    const buttonClasses = classNames({
      'data-source-item__connect_button': true,
      'data-source-item__connect_button--connected': dataSource.connected,
      'data-source-item__connect_button--disabled': disabled,
    });
    let buttonText = 'Connect';
    if (dataSource.coming_soon) {
      buttonText = 'Coming Soon!';
    }
    if (dataSource.connected) {
      buttonText = 'Manage';
    }
    connectButton = (
      <button className={buttonClasses} onClick={() => onClickConnect(dataSource)}>{buttonText}</button>
    );
  }

  return (
    <div className={itemClasses} onClick={() => onClick(dataSource)}>
      {dataSource.icon && (
        <div className="data-source-item__icon">
          <img src={dataSource.icon} alt={dataSource.label} />
        </div>
      )}
      <div className="data-source-item__label">{dataSource.label}</div>
      {connectButton}
    </div>
  );

};

export default DataSourceItem;
