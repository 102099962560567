import React, {useEffect, useState} from "react";
import {Modal, Grid, Row, Col, InputNumber, SelectPicker} from "rsuite";

import DopeApi from "../services/DopeApi";
import StripeApi from "../stripe/StripeApi";
import DopeTextInput from "../ui/DopeTextInput";
import DopeButton from "../ui/DopeButton";

const creditPlanApi = new DopeApi("credit_plan");
const stripeApi = new StripeApi();

// TODO: do we want to write a generic enum fetcher?
const frequencies = [{label: "Monthly", value: "monthly"}, {label: "One Time", value: "one_time"}]
const statuses = [
  {label: "Active", value: "active"},
  // {label: "Deleted", value: "deleted"},
  {label: "Archived", value: "archived"}
]

// TODO: move to use object state hook
const CreditPlanModal = ({ open, onClose, creditPlanId }) => {
  const [stripePlans, setStripePlans] = useState([]);
  const [name, setName] = useState("");
  const [association, setAssociation] = useState(null);
  const [frequency, setFrequency] = useState("");
  const [notes, setNotes] = useState("");
  const [postcardCredits, setPostcardCredits] = useState(0);
  const [handWrittenCredits, setHandWrittenCredits] = useState(0);
  const [dataCredits, setDataCredits] = useState(0);
  const [cookieCredits, setCookieCredits] = useState(0);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStripePlans();
  }, []);

  useEffect(() => {
    if (creditPlanId) {
      getCreditPlan();
    }
  }, [creditPlanId]);

  const getCreditPlan = async () => {
    const creditPlan = await creditPlanApi.get(creditPlanId);
    setName(creditPlan.name);
    setAssociation(creditPlan.subscription_level);
    setFrequency(creditPlan.frequency);
    setNotes(creditPlan.description);
    setPostcardCredits(creditPlan.postcard_credits);
    setHandWrittenCredits(creditPlan.handwritten_credits);
    setDataCredits(creditPlan.data_credits);
    setCookieCredits(creditPlan.goodie_credits);
    setStatus(creditPlan.status);
  }

  const getStripePlans = async () => {
    const selectPlans = [];
    const plans = await stripeApi.getSubscriptionPlans();
    plans.forEach(plan => {
      selectPlans.push({label: `${plan.nickname}`, value: plan.nickname});
    });
    setStripePlans(selectPlans.filter((item, index, arr) =>
      index === arr.findIndex(t => t.value === item.value))
    );
  }

  const handleCreditInputChange = (update, value) => {
    if (value < 0) {
      update(0);
    } else {
      update(value);
    }
  }

  const createCreditPlan = async () => {
    setLoading(true);
    let creditPlan = {
      id: creditPlanId,
      name: name,
      subscription_level: association,
      frequency: frequency,
      description: notes,
      postcard_credits: postcardCredits,
      handwritten_credits: handWrittenCredits,
      data_credits: dataCredits,
      goodie_credits: cookieCredits,
    };

    if (status) {
      creditPlan.status = status;
    }

    try {
      creditPlanId ? await creditPlanApi.update(creditPlan) : await creditPlanApi.create(creditPlan);
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      resetValues();
      setLoading(false);
    }
  }

  const resetValues = () => {
    setDataCredits(0);
    setHandWrittenCredits(0);
    setPostcardCredits(0);
    setCookieCredits(0);
    setNotes("");
    setName("");
    setAssociation("");
    setFrequency("");
    setStatus("");
  }

  const closeModal = () => {
    resetValues();
    onClose();
  }

  return (
    <div>
      <Modal open={open} onClose={closeModal} size="md">
        <Modal.Body>
          <h4>Create a new Credit Plan</h4>
          <Grid fluid>
            <Col xs={12}>
              <Row style={{marginBottom: "8px"}}>
                <div className="label" style={{marginBottom: "2px"}}>Name</div>
                <DopeTextInput
                  style={{width: "348px"}}
                  placeholder="ex: 2024 DOPEST Credit Plan"
                  name="name"
                  value={name}
                  onChange={(update, value) => setName(value)}
                />
              </Row>
              <Row style={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                <div>
                  <div className="label" style={{marginBottom: "2px"}}>Association</div>
                  <SelectPicker
                    name="association"
                    searchable={false}
                    cleanable={true}
                    value={association}
                    onChange={(value) => setAssociation(value)}
                    onClean={() => setAssociation(null)}
                    data={stripePlans}
                    style={{width: "170px"}}
                  />
                </div>

                <div style={{marginLeft: "8px"}}>
                  <div className="label" style={{marginBottom: "2px"}}>Frequency</div>
                  <SelectPicker
                    name="frequency"
                    value={frequency}
                    searchable={false}
                    onChange={(value) => setFrequency(value)}
                    data={frequencies}
                    style={{width: "170px"}}
                  />
                </div>
              </Row>

              {creditPlanId && <Row style={{marginBottom: "8px"}}>
                <div className="label" style={{marginBottom: "2px"}}>Status</div>
                <SelectPicker
                  name="status"
                  value={status}
                  searchable={false}
                  onChange={(value) => setStatus(value)}
                  data={statuses}
                  style={{width: "348px"}}
                />
              </Row>}

              <Row>
                <div className="label" style={{marginBottom: "2px"}}>Notes</div>
                <DopeTextInput
                  style={{width: "348px", resize: "none"}}
                  name="notes"
                  value={notes}
                  as="textarea"
                  rows={5}
                  onChange={(update, value) => setNotes(value)}
                />
              </Row>
            </Col>

            <Col xs={12}>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div className="label" style={{marginBottom: "2px"}}>Credit Amount</div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "2px"}}>
                  <div className="label large">Postcard Credits</div>
                  <InputNumber
                    style={{width: "100px"}}
                    value={postcardCredits}
                    min={0}
                    onChange={(value) => handleCreditInputChange(setPostcardCredits, value)}
                  />
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "2px"}}>
                  <div className="label large">Handwritten Credits</div>
                  <InputNumber
                    style={{width: "100px"}}
                    value={handWrittenCredits}
                    min={0}
                    onChange={(value) => handleCreditInputChange(setHandWrittenCredits, value)}
                  />
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "2px"}}>
                  <div className="label large">Data Credits</div>
                  <InputNumber
                    style={{width: "100px"}}
                    value={dataCredits}
                    min={0}
                    onChange={(value) => handleCreditInputChange(setDataCredits, value)}
                  />
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "2px" }}>
                  <div className="label large">Cookie Credits</div>
                  <InputNumber
                    style={{width: "100px"}}
                    value={cookieCredits}
                    min={0}
                    onChange={(value) => handleCreditInputChange(setCookieCredits, value)}
                  />
                </div>

                <DopeButton
                  props={{
                    buttonClass: "filled-black",
                    label: creditPlanId ? "Save changes & close" : "Create Credit Plan",
                    onClick: createCreditPlan,
                    styles: {width: "100%", marginTop: "64px"}
                  }}
                  loading={loading}
                />
              </div>
            </Col>
          </Grid>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CreditPlanModal;
