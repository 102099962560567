import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import axios from 'axios';

import { ImagesGrid } from 'polotno/side-panel';
import { getImageSize } from 'polotno/utils/image';

const API_URL = "https://api.polotno.dev/api/get-basic-shapes?KEY=";

const DefaultShapesSidePanel = observer(({ store }) => {
  const [loading ,setLoading] = useState(false);
  const [shapes, setShapes] = useState([]);

  const fetchDefaultShapes = async () => {
    try {
      const response = await axios.get(API_URL);
      let items = response.data?.items ?? [];

      items = items.slice(0, Math.min(items.length, 21));

      const list = items.map(d => ({ ...d, defShapes: true, type: 'svg' }));

      setShapes(list);
    } catch (err) {
      console.error('Error: ', err);
    }
  };

  const loadDefaultShapes = async () => {
    setLoading(true);

    try {
      await fetchDefaultShapes();
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  }


  useEffect(() => {
    loadDefaultShapes();
  }, []);


  return (
    <div className='custom-photos'>
      <div>
        {shapes.length > 0 && (
          <ImagesGrid
            images={shapes}
            getPreview={shape => shape.url}
            crossOrigin="anonymous"
            onSelect={async (image, pos, element) => {
              const { url } = image;
              let { width, height } = await getImageSize(url);
              const isSVG = url.indexOf('svg+xml') >= 0 || url.indexOf('.svg') >= 0;

              const type = isSVG ? 'svg' : 'image';

              if (element && element.type === 'svg' && !element.locked && type === 'image') {
                element.set({ maskSrc: url });
                return;
              }

              if (element && element.type === 'image' && !element.locked && type === 'image') {
                element.set({ src: url });
                return;
              }

              const scale = Math.min(store.width / width, store.height / height, 1);
              width = width * scale;
              height = height * scale;

              const x = (pos?.x || store.width / 2) - width / 2;
              const y = (pos?.y || store.height / 2) - height / 2;

              store.activePage?.addElement({
                type,
                src: url,
                x,
                y,
                width,
                height,
              });
            }}
          />
        )}
      </div>
    </div>
  );
});

export default DefaultShapesSidePanel;


