import React from "react";

import DopeTabs from "../ui/DopeTabs";
import CampaignTemplateDetailsForm from "./CampaignTemplateDetailsForm";
import DispatchTemplateManagement from "./DispatchTemplateManagement";

const CampaignTemplateForm = (props) => {
  const campaignTemplateId = props.id;
  const belongsToCampaignTemplateFilter = [{ field: 'campaign_template_id', operator: '=', value: campaignTemplateId }];

  const initialDispatchTemplate = {
    campaign_template_id: campaignTemplateId,
    days_offset: 1,
    position: 0,
  };

  return (
    <DopeTabs>

      <DopeTabs.Tab name="details" label="Details">
        <CampaignTemplateDetailsForm {...props} />
      </DopeTabs.Tab>

      <DopeTabs.Tab name="dispatch_templates" label="Dispatch Templates" disabled={!campaignTemplateId}>
        <DispatchTemplateManagement
          useDrawer={true}
          hideFiltering={true}
          initialFilters={belongsToCampaignTemplateFilter}
          formProps={{ initialResource: initialDispatchTemplate }}
        />
      </DopeTabs.Tab>

    </DopeTabs>
  );
}

export default CampaignTemplateForm;
