import React from "react";

import { useListPull } from "./listPullSlice";
import { DopeFormGroup } from "../ui/DopeForm";

const contactsTypeOptions = [
  { label: "Combination", value: "combination" },
  { label: "Customers", value: "client" },
  { label: "Leads", value: "lead" },
  { label: "Prospects", value: "prospect" },
];

const nameInput = { inputType: "text", name: "name", props: { label: "Name" } };
const descriptionInput = { inputType: "text", name: "description", props: { label: "Description", as: "textarea", rows: 3 } };
const keepInSyncInput = { inputType: "radio", name: "keep_in_sync", props: { label: "How often should we update this list?", options: [{ value: true, label: "Every day" }, { value: false, label: "Just this once" }] } };
const contactsTypeInput = { inputType: "select", name: "contacts_type", props: { label: "Who is on this list?", options: contactsTypeOptions } };


const ListPullSetup = () => {
  const { listPull, actions } = useListPull();

  const listPullInputProps = { values: listPull, onChange: actions.update, errors: listPull.errors };


  return (
    <div className="page bottom">
      <div className="page-header">
        <p className="header-text">CRM List Pull Details</p>
      </div>

      <div className="rs-form split-cols">
        <div>
          <DopeFormGroup input={nameInput} {...listPullInputProps} />
          <DopeFormGroup input={descriptionInput} {...listPullInputProps} />
          <DopeFormGroup input={contactsTypeInput} {...listPullInputProps} />
          <DopeFormGroup input={keepInSyncInput} {...listPullInputProps} />
        </div>

        <div>
          {/* Video? */}
        </div>
      </div>
    </div>
  );
};

export default ListPullSetup;
