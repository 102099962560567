import React, { useState } from "react";


import { useQuery2 } from "../hooks/useQuery";
import { useStripeUI } from "../stripe/stripeUISlice";
import StripeApi from "../stripe/StripeApi";
import { unixTimestampToDateStr } from "../utils/date";
import DopeTable from "../ui/DopeTable";
import DopeButton from "../ui/DopeButton";
import DopeDrawer from "../ui/DopeDrawer";
import InvoiceDrawer from "../stripe/InvoiceDrawer";

const stripeApi = new StripeApi();

const invoiceIDStyles = {
  fontSize: '12px',
  fontWeight: 400,
  color: '#255FDF'
}

const columns = [
  { type: 'text', dataKey: 'id', label: 'Trans. ID', flexGrow: 2 },
  { type: 'text', dataKey: ['account', 'name'], label: 'Company Name', flexGrow: 2 },
  { type: 'date', dataKey: 'created', label: 'Date', dateConverter: unixTimestampToDateStr, flexGrow: 1},
  { type: 'currency', dataKey: 'amount', label: 'Amount', flexGrow: 1 },
  { type: 'text', dataKey: ['payment_method_details', 'card', 'last4'], label: 'Payment', flexGrow: 1 },
  { type: 'clickable_text', dataKey: 'invoice', label: 'Invoice #', customStyles: invoiceIDStyles, flexGrow: 2 },
];

const PaymentsTable = () => {
  const [account, setAccount] = useState(null);
  const [stripeUI, stripeUiActions] = useStripeUI();
  const { tableProps, rest, reload, loading } = useQuery2({
    api: stripeApi,
    initialScopes: [{ name: 'all_charges' }],
    initialPerPage: 10,
    extraParams: { route: 'stripe/index_transactions'},
  });

  const nextPage = () => {
    reload({ starting_after: rest.next_cursor });
  };

  const previousPage = () => {
    reload({ ending_before: rest.previous_cursor });
  };

  const onClickInvoice = (rowData) => {
    try {
      setAccount(rowData.account);
    } catch (err) {
      console.error(err);
    } finally {
      stripeUiActions.setDrawerInvoiceId(rowData.invoice);
    }
  }

  return (
    <div>
      <DopeTable
        {...tableProps}
        columns={columns}
        onClickInvoice={onClickInvoice}
      />

      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <DopeButton
          disabled={!rest.has_previous || loading}
          props={{
            onClick: previousPage,
            label: "Previous Page",
            buttonClass: "text-link"
          }}
        />
        <DopeButton
          disabled={!rest.has_next || loading}
          props={{
            onClick: nextPage,
            label: "Next Page",
            buttonClass: "text-link"
          }}
        />
      </div>

      <DopeDrawer
        className="invoice-drawer"
        open={!!stripeUI.drawerInvoiceId}
        onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
        bodyContent={
          <InvoiceDrawer
            id={stripeUI.drawerInvoiceId}
            account={account}
            onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
          />
        }
      />
    </div>
  );
}

export default PaymentsTable;
