import React, { useState } from "react";

import { Drawer } from "rsuite";

import DataSourceList from "../DataSources/DataSourceList";
import Connection from "./Connection";

const drawerStyleOverride = { width: 615 };
const drawerBodyStyleOverride = { padding: '20px 40px' }; // TODO figure out DopeDrawer

const DataSourceConnectDrawer = ({ open, onClose }) => {
  const [selectedDataSource, setSelectedDataSource] = useState(null);

  const header = selectedDataSource ?
    (<>
      <div className="header-3">{selectedDataSource.connected ? '' : 'Connect to '}{selectedDataSource.label}</div>
    </>) : (<>
      <div className="header-3">Your Data Source</div>
      <div className="help-text">Select from the list below of sources we directly connect with. Select the app and follow the directions to get the direct connection setup.</div>
    </>);

  const content = selectedDataSource ? (
      <Connection dataSource={selectedDataSource} onClickCancel={() => setSelectedDataSource(null)} />
    ) : (
      <DataSourceList onClickConnect={setSelectedDataSource} />
    );


  return (
    <>
      <Drawer
        size="sm"
        placement="right"
        open={open}
        onClose={onClose}
        style={drawerStyleOverride}
      >
        <Drawer.Header style={{ display: 'block' }}>
          {header}
        </Drawer.Header>
        <Drawer.Body style={drawerBodyStyleOverride}>
          {content}
        </Drawer.Body>
      </Drawer>
    </>
  );

}

export default DataSourceConnectDrawer;
