import React from "react";
import { useContactUI } from "./contactUISlice";
import DopeButton from "../ui/DopeButton";

const FixContactCell = ({ rowData }) => {
  const [contactUI, contactUIActions] = useContactUI();
  if (rowData.has_valid_info || rowData.contact?.has_valid_info) return null;
  const contactId = rowData.contact_id ? rowData.contact_id : rowData.id;

  return (
    <DopeButton
      props={{
        label: "Fix Contact",
        onClick: () => contactUIActions.setDrawerContactId(contactId),
        buttonClass: "text-link",
      }}
    />
  );
}

export default FixContactCell;
