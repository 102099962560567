import { useState, useEffect, useCallback, useRef } from "react";
import uniqBy from "lodash/uniqBy";
import omit from "lodash/omit";
import debounce from "lodash/debounce";

// TODO - should debouncing be in here or should we dounce in state?

const useQuery = (api, initialFilters = [], ensureResourceId = null, initialScopes = [], extraParams = {}, initialSortColumn = "id") => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [rest, setRest] = useState({});
  const [sortColumn, setSortColumn] = useState(initialSortColumn);
  const [sortDirection, setSortDirection] = useState("desc");
  const [filters, setFilters] = useState(initialFilters);
  const [scopes, setScopes] = useState(initialScopes);
  const [searchScope, setSearchScope] = useState(null);
  const [search, setSearch] = useState(null);
  const [ensuredResource, setEnsuredResource] = useState(null);

  const params = {
    page,
    per_page: perPage,
    sort_column: sortColumn,
    sort_direction: sortDirection,
    filters,
    scopes,
    search: { text: search, column: searchScope }
  };

  const abortControllerRef = useRef(null);

  const debouncedQueryData = useCallback(debounce((queryParams) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    api.query({...queryParams, ...extraParams}, abortControllerRef.current.signal)
      .then((data) => {
        const { page, per_page, total, ...rest } = data;
        const resources = rest[api.pluralModelName()];

        setError(null);
        setData(resources);
        setTotal(total);
        setPerPage(per_page);
        setPage(page);
        setLoading(false);
        setRest(rest);
      })
      .catch((errors) => {
        console.error(errors);
        setError(errors);
        setLoading(false);
      });
  }, 300, { leading: true }), []);

  const queryData = (queryParams) => {
    setLoading(true);
    debouncedQueryData(queryParams);
  };

  useEffect(() => {
    queryData({
      ...params,
      page: 1,
    });
  }, [perPage, sortColumn, sortDirection, filters, scopes, search]);

  useEffect(() => {
    if (ensureResourceId) {
      api.get(ensureResourceId, { view: 'list' })
        .then((resource) => {
          setEnsuredResource(resource);
        })
        .catch((errors) => {
          console.error(errors);
        });
    }
  }, [ensureResourceId]);

  const setPageAndQuery = (page) => {
    setPage(page);
    queryData({
      ...params,
      page,
    });
  };

  const setSort = (sortColumn, sortDirection) => {
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
  };

  const reload = (customParams) => {
    return queryData({...params, ...customParams});
  };

  const onSearchClear = () => {
    setSearch(null);
  };

  const onSearchInput = (search) => {
    if (!search) return onSearchClear();
    setSearch(search);
  };

  const tableProps = {
    data,
    onSortColumn: setSort,
    sortColumn,
    sortType: sortDirection,
    loading,
    reload,
  };

  const paginationProps = {
    total,
    limit: perPage,
    activePage: page,
    onChangePage: setPageAndQuery,
    onChangeLimit: setPerPage,
  };

  const filterProps = {
    onChangeFilters: setFilters,
    onChangeScopes: setScopes,
    loading,
    reload,
  };

  const dataWithEnsuredResource = ensuredResource ? uniqBy([ensuredResource, ...data], 'id') : data;
  const allData = dataWithEnsuredResource;

  return {
    reload,
    loading,
    error,
    data,
    dataWithEnsuredResource,
    allData,
    total,
    perPage,
    page,
    rest,
    sortColumn,
    sortDirection,
    setPerPage,
    setPage: setPageAndQuery,
    setSort,
    setSortColumn,
    setSortDirection,
    filters,
    setFilters,
    scopes,
    setScopes,
    onSearchInput,
    onSearchClear,
    setSearchScope,
    tableProps,
    paginationProps,
    filterProps,
  };
};

export default useQuery;


const useQuery2 = ({
  api,
  ensureResourceId = null,
  extraParams = {},
  initialFilters = [],
  initialScopes = [],
  initialSortColumn = "id",
  initialSortDirection = "desc",
  initialPerPage = 25,
  initialSearchScope = null, // todo -> search column
  searchColumn = null,
}) => {
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [perPage, setPerPage] = useState(initialPerPage);
  const [page, setPage] = useState(1);
  const [rest, setRest] = useState({});
  const [sortColumn, setSortColumn] = useState(initialSortColumn);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);
  const [filters, setFilters] = useState(initialFilters);
  const [scopes, setScopes] = useState(initialScopes);
  const [searchScope, setSearchScope] = useState(initialSearchScope || searchColumn);
  const [search, setSearch] = useState('');
  const [ensuredResource, setEnsuredResource] = useState(null);

  const params = {
    page,
    per_page: perPage,
    sort_column: sortColumn,
    sort_direction: sortDirection,
    filters,
    scopes,
    search: { text: search, column: searchScope }
  };

  const exportParams = {
    export: true,
    ...omit(params, 'page', 'per_page'),
  };

  const chartDataParams = {
    filters,
    scopes,
  };

  const abortControllerRef = useRef(null);

  const debouncedQueryData = useCallback(debounce((queryParams) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    api.query({...queryParams, ...extraParams}, abortControllerRef.current.signal)
      .then((data) => {
        const { page, per_page, total, ...rest } = data;
        const resources = rest[api.pluralModelName()];

        setError(null);
        setData(resources);
        setTotal(total);
        setPerPage(per_page);
        setPage(page);
        setLoading(false);
        setRest(rest);
      })
      .catch((errors) => {
        const isCancelError = errors.name === "CanceledError";
        if (!isCancelError) {
          console.error(errors);
          setError(errors);
          setLoading(false);
        }
      });
  }, 300, { leading: true }), []); // TODO debounce only search text somehow

  const queryData = (queryParams) => {
    setLoading(true);
    debouncedQueryData(queryParams);
  };

  useEffect(() => {
    queryData({
      ...params,
      page: 1,
    });
  }, [perPage, sortColumn, sortDirection, filters, scopes, search]);

  useEffect(() => {
    if (ensureResourceId) {
      api.get(ensureResourceId, { view: 'list' })
        .then((resource) => {
          setEnsuredResource(resource);
        })
        .catch((errors) => {
          console.error(errors);
        });
    }
  }, [ensureResourceId]);

  const setPageAndQuery = (page) => {
    setPage(page);
    queryData({
      ...params,
      page,
    });
  };

  const setSort = (sortColumn, sortDirection) => {
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
  };

  const reload = (customParams) => {
    return queryData({ ...params, ...customParams });
  };

  const exportCSV = async (fileName) => {
    setExporting(true);
    try {
      await api.exportCSV(exportParams, fileName);
    } catch (error) {
      console.error('CSV Export Error:', error);
    } finally {
      setExporting(false);
    }
  };

  const updateResource = (nextResource) => {
    const nextResources = data.map(resource => {
      if (resource.id === nextResource.id) {
        return nextResource;
      }
      return resource;
    });
    setData(nextResources);
  };

  const onSearchClear = () => {
    setSearch('');
  };

  const onSearchInput = (search) => {
    if (!search) return onSearchClear(); // TODO: is this needed?
    setSearch(search);
  };

  const tableProps = {
    data,
    onSortColumn: setSort,
    sortColumn,
    sortType: sortDirection,
    loading,
    reload,
  };

  const paginationProps = {
    total,
    limit: perPage,
    activePage: page,
    onChangePage: setPageAndQuery,
    onChangeLimit: setPerPage,
  };

  const filterProps = {
    onChangeFilters: setFilters,
    onChangeScopes: setScopes,
    loading,
    reload,
  };

  const dataWithEnsuredResource = ensuredResource ? uniqBy([ensuredResource, ...data], 'id') : data;
  const allData = dataWithEnsuredResource;

  return {
    reload,
    exportCSV,
    updateResource,
    loading,
    exporting,
    error,
    data,
    dataWithEnsuredResource,
    allData,
    total,
    exportParams,
    chartDataParams,
    perPage,
    page,
    rest,
    sortColumn,
    sortDirection,
    setPerPage,
    setPage: setPageAndQuery,
    setSort,
    setSortColumn,
    setSortDirection,
    filters,
    setFilters,
    scopes,
    setScopes,
    searchText: search, // TODO - update all to searchText as search represents the column + text
    onSearchInput,
    onSearchTextChange: onSearchInput, // TODO - update all to onSearchTextChange
    onSearchClear,
    setSearchScope,
    tableProps,
    paginationProps,
    filterProps,
  };
};

export { useQuery2 };


