import React, {useEffect, useState} from "react";
import { Modal } from "rsuite";

import { useAccount } from "../accounts/accountSlice";
import DopeApi from "../services/DopeApi";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeButton from "../ui/DopeButton";
import DopeSelect from "../ui/DopeSelect";

const accountApi = new DopeApi("account");

const AccountTypeModal = ({ open, handleClose, selectedAccountType }) => {
  const [account, accountActions] = useAccount();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [agencies, setAgencies] = useState([]);
  const [parentAccountId, setParentAccountId] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAgencies = async (search) => {
    const agencies = await accountApi.getMany({ scopes: [{name: 'agencies'}], search: search });
    setAgencies(agencies);
  }

  useEffect(() => {
    getAgencies()
  }, []);

  const changeAccountType = async () => {
    setLoading(true);
    try {
      await accountApi.update({ id: account.id, actions: [{ name: 'change_account_type', args: [selectedAccountType, parentAccountId] }]});
      accountActions.update({ account_type: selectedAccountType });
      dopeUIActions.addFlashMessage({ type: 'success', body: 'Account type updated', header: 'Success!'})
      accountActions.get(account.id)
      setParentAccountId(null);
    } catch (err) {
      dopeUIActions.addFlashMessage({ type: 'error', body: 'Could not update account type', header: 'Error!'})
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div style={{ paddingTop: "16px"}}>
          <Modal.Header>
            <Modal.Title className="header-5">You are changing {account.name}'s account type</Modal.Title>
          </Modal.Header>
          <Modal.Body className="help-text margin-16-b">
            <div className="margin-16-b">
              Click "Change Account Type" to change {account.name}'s account type from {account.account_type} to {selectedAccountType}.
              Please note that this action will change the account's subscription plan and may affect the account's features.
            </div>
            {selectedAccountType === "agency_client" &&
              <div>
                <label className="label">Select Parent Account</label>
                <DopeSelect
                  name="account"
                  onChange={value => setParentAccountId(value.account)}
                  options={agencies.map(agency => ({label: agency.name, value: agency.id}))}
                  onSearch={searchValue => getAgencies({search: {text: searchValue, column: 'name'}})}
                  searchable={true}
                />
              </div>
            }


          </Modal.Body>
          <Modal.Footer>
            <DopeButton
              loading={loading}
              props={{
                label: "Cancel",
                buttonClass: "outlined-black",
                onClick: handleClose,
              }}
            />

            <DopeButton
              disabled={selectedAccountType === "agency_client" && !parentAccountId}
              loading={loading}
              props={{
                label: "Change Account Type",
                buttonClass: "filled-black",
                onClick: changeAccountType,
              }}
            />
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}

export default AccountTypeModal;
