
import React from 'react';
import DopeManagement from "../ui/DopeManagement";
import CampaignForm from './CampaignForm';

const columns = [
  { type: "text", dataKey: "id", label: "ID", sortable: true, filterable: true },
  { type: "text", dataKey: "account_name", label: "Account" },
  { type: "text", dataKey: "name", label: "Name", sortable: true, filterable: true },
  { type: "text", dataKey: "status", label: "Status", sortable: true, filterable: true },

  { type: 'text', dataKey: 'account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
];

const scopeOptions = [];

const CampaignManagement = () => {
  return (
    <DopeManagement
      modelName="campaign"
      modelForm={CampaignForm}
      columns={columns}
      scopeOptions={scopeOptions}
    />
  );
};

export default CampaignManagement;
