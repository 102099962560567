import React from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import DopeButton from "../ui/DopeButton";


const PAYMENT_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  },
  paymentMethods: ['card'],
};

const inputStyle = {
  border: '1px solid #EAECEF',
  padding: '10px',

  borderRadius: '4px'
};

function StripeCardAdder({ submittable, onSubmit, submitText, loading }) {

  return (
    <>
      <PaymentElement options={PAYMENT_ELEMENT_OPTIONS} />

      {submittable &&
        <DopeButton
          loading={loading}
          props={{
            onClick: () => onSubmit(),
            label: submitText,
            buttonClass: "outlined-black",
            styles: { marginTop: '10px' }
          }}
        />
      }
    </>
  );
}
export default StripeCardAdder;
