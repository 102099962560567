import React from "react";

const AccountInformationIcon = ({ size, fill, className, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill={fill} stroke={fill} className={className}style={{ strokeWidth: 0 }}>
      <path d="M12.375 15H13.125V12H12.375V15ZM12.75 11.25C12.85 11.25 12.9375 11.2125 13.0125 11.1375C13.0875 11.0625 13.125 10.975 13.125 10.875C13.125 10.775 13.0875 10.6875 13.0125 10.6125C12.9375 10.5375 12.85 10.5 12.75 10.5C12.65 10.5 12.5625 10.5375 12.4875 10.6125C12.4125 10.6875 12.375 10.775 12.375 10.875C12.375 10.975 12.4125 11.0625 12.4875 11.1375C12.5625 11.2125 12.65 11.25 12.75 11.25ZM5.25 10.5H8.00625C8.14375 10.2125 8.30312 9.94375 8.48438 9.69375C8.66563 9.44375 8.86875 9.2125 9.09375 9H5.25V10.5ZM5.25 13.5H7.55625C7.51875 13.25 7.5 13 7.5 12.75C7.5 12.5 7.51875 12.25 7.55625 12H5.25V13.5ZM3.75 16.5C3.3375 16.5 2.98438 16.3531 2.69063 16.0594C2.39688 15.7656 2.25 15.4125 2.25 15V3C2.25 2.5875 2.39688 2.23438 2.69063 1.94063C2.98438 1.64688 3.3375 1.5 3.75 1.5H9.75L14.25 6V7.725C14.0125 7.65 13.7688 7.59375 13.5188 7.55625C13.2688 7.51875 13.0125 7.5 12.75 7.5V6.75H9V3H3.75V15H8.00625C8.14375 15.2875 8.30312 15.5563 8.48438 15.8063C8.66563 16.0563 8.86875 16.2875 9.09375 16.5H3.75ZM12.75 9C13.7875 9 14.6719 9.36563 15.4031 10.0969C16.1344 10.8281 16.5 11.7125 16.5 12.75C16.5 13.7875 16.1344 14.6719 15.4031 15.4031C14.6719 16.1344 13.7875 16.5 12.75 16.5C11.7125 16.5 10.8281 16.1344 10.0969 15.4031C9.36563 14.6719 9 13.7875 9 12.75C9 11.7125 9.36563 10.8281 10.0969 10.0969C10.8281 9.36563 11.7125 9 12.75 9Z" />
    </svg>
  );
};

export default AccountInformationIcon;
