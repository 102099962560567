import React from 'react';
import { Form } from 'rsuite';

import DopeTextInput from '../ui/DopeTextInput';
import DopeSubmitButton from '../ui/DopeSubmitButton';
import DopeErrorMessages from '../ui/DopeErrorMessages';
import BelongsToSelect from './BelongsToSelect';
import DopeImageSelect from './DopeImageSelect';
import DopeSelect from './DopeSelect';
import DopeSimpleSelect from './DopeSimpleSelect';
import DopeDatePicker from './DopeDatePicker';
import DopeToggle from './DopeToggle';
import DataAxleInputRow from '../filters/DataAxleFilterInputs';
import DopeTags from './DopeTags';
import DopeRadio from './DopeRadio';
import DopeRadioTile from './DopeRadioTile';
import DopeCheckbox from './DopeCheckbox';
import DopePhoneInput from './DopePhoneInput';

const DopeInput = ({ inputType, props = {}, ...rest }) => {
  switch (inputType) {
    case 'text':
    case 'url':
      return <DopeTextInput {...props} {...rest} />;
    case 'number':
      return null;
    case 'phone':
      return <DopePhoneInput {...props} {...rest} />;
    case 'checkbox':
      return <DopeCheckbox {...props} {...rest} />;
    case 'radio':
      return <DopeRadio {...props} {...rest} />;
    case 'radio_tile':
      return <DopeRadioTile {...props} {...rest} />;
    case 'tags':
      return <DopeTags {...props} {...rest} />;
    case 'date':
      return <DopeDatePicker {...props} {...rest} />;
    case 'select':
      return <DopeSelect {...props} {...rest} />;
    case 'simpleSelect':
      return <DopeSimpleSelect {...props} {...rest} />;
    case 'belongsTo':
      return <BelongsToSelect {...props} {...rest} />;
    case 'image':
      return <DopeImageSelect {...props} {...rest} />;
    case 'toggle':
      return <DopeToggle {...props} {...rest} />;
    case 'data_axle_filter':
      return <DataAxleInputRow {...props} {...rest} />;
    default:
      throw new Error(`Invalid input type: ${inputType}`);
  }
};

const DopeFormGroup = ({ input, values, onChange, errors, ...rest }) => {
  const { inputType, name, props } = input;
  const value = values[name];
  const errorMessages = errors?.[name];
  const { label, ...restProps } = props;
  const allProps = { ...restProps, name, value, onChange, values };

  let labelClass = (() => {
    if (props?.labelClass) return props.labelClass;
    return props.helperText ? 'label large bold' : 'label';
  })();

  return (
    <Form.Group key={name} {...rest}>
      <Form.ControlLabel className={labelClass}>{label}</Form.ControlLabel>
      { props.helperText && <div className="label color-text-secondary optional margin-4-b">{props.helperText}</div>}
      <DopeInput inputType={inputType} props={allProps} />
      <DopeErrorMessages errorMessages={errorMessages} />
    </Form.Group>
  );
}; // TODO use in dope form, waiting to prevent conflicts

const DopeForm = ({ submitText, onSubmit, onClickCancel, inputs, values, onChange, errors, loading, layout = 'vertical', formTypeSwitcher, props }) => {
  const dopeInputs = inputs.map((input) => {
    const { inputType, name, props } = input;
    const value = values[name] || props?.defaultValue;
    const errorMessages = errors?.[name];
    const allProps = { ...props, name, value, onChange, values };

    let generatedInput
    switch (input.customInput) {
      case 'DataAxleFilterInputs':
        generatedInput =  <DataAxleInputRow props={allProps} values={values} />;
        break;
      default:
        generatedInput = <DopeInput inputType={inputType} props={allProps} />
    }

    return (
      <Form.Group key={name}>
        <Form.ControlLabel>{props.label}</Form.ControlLabel>
        {generatedInput}
        <DopeErrorMessages errorMessages={errorMessages} />
      </Form.Group>
    );
  });

  return (
    <Form onSubmit={onSubmit} fluid disabled={loading} layout={layout} style={{ maxWidth: 700 }}>

      {formTypeSwitcher}

      {dopeInputs}

      <DopeSubmitButton props={props?.submitButtonProps} text={submitText} onClickCancel={onClickCancel} loading={loading} />

      <DopeErrorMessages errorMessages={errors?.base} />

    </Form>
  );
};

export default DopeForm;

export {
  DopeInput,
  DopeFormGroup,
  DopeForm,
};
