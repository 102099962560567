import React from "react";
import { Link, useLocation } from "react-router-dom";

import { useAccount } from "../accounts/accountSlice";

const tabs = [
  { label: "Account Details", to: "details" },
  { label: "Plan Information", to: "subscription" },
  // { label: "Pricing", to: "pricing" },
  // { label: "Account Log", to: "activity_log" },
];


const tabStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "24px",
  paddingBottom: "8px"
}

const selectedStyles = {
  ...tabStyles,
  textDecoration: "none",
  borderBottom: "3px solid #EA0029",
  borderRadius: "3px",
  fontSize: "16px",
  fontWeight: 600,
  color: "#000000",
};

const unselectedStyles = {
  ...tabStyles,
  textDecoration: "none",
  fontSize: "16px",
  fontWeight: 400,
  color: "#292B2E"
};

const PulseAccountTabBar = () => {
  const [account, actions] = useAccount()

  const location = useLocation();

  return (
    <div style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #EAECEF", marginBottom: "24px"}}>
      {tabs.map((tab) => (
          <Link
            key={tab.to}
            to={`/pulse/accounts/${account.id}/${tab.to}`}
            style={location.pathname.includes(tab.to) ? selectedStyles : unselectedStyles}
          >
            {tab.label}
          </Link>
      ))}
    </div>
  );
}

export default PulseAccountTabBar;
