import React from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const initialPoscardTemplate = {
  name: '',
  account_id: null,
  front_image: null,
  back_image: null,
};

const postcardTemplateInputs = [
  {
    inputType: 'text',
    name: 'name',
    props: {
      label: 'Name',
    }
  },
  {
    inputType: 'simpleSelect',
    name: 'status',
    props: {
      label: 'Status',
      options: ['draft', 'approved', 'in_review'],
    },
  },
  {
    inputType: 'belongsTo',
    name: 'account_id',
    props: {
      label: 'Account',
      labelKey: 'name',
      belongsToModelName: 'account',
    }
  },
  {
    inputType: 'image',
    name: 'front_image',
    props: {
      label: 'Front Image',
    }
  },
  {
    inputType: 'image',
    name: 'back_image',
    props: {
      label: 'Back Image',
    }
  },
];

const PostcardTemplateForm = (props) => {
  return (
    <ModelForm
      modelName="postcard_template"
      initialResource={initialPoscardTemplate}
      inputs={postcardTemplateInputs}
      {...props}
    />
  );
};

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, filterable: true },
  { type: 'image_url', dataKey: 'front_image_url', label: 'Front Image' },
  { type: 'image_url', dataKey: 'back_image_url', label: 'Back Image' },
  { type: 'text', dataKey: 'account_name', label: 'Account' },
  { type: 'text', dataKey: 'name', label: 'Name' },
  { type: 'text', dataKey: 'status', label: 'Status' },

  { type: 'text', dataKey: 'account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
];

const scopeOptions = [];

function PostcardTemplateManagement() {
  return (
    <DopeManagement
      modelName="postcard_template"
      modelForm={PostcardTemplateForm}
      columns={columns}
      scopeOptions={scopeOptions}
    />
  );
}

export default PostcardTemplateManagement;
