import React, { useState } from "react";

import { useCampaignListGenerationSettings } from "./campaignSlice";

import { Accordion } from "rsuite";
import DopeTextInput from "../ui/DopeTextInput";
import DopeRadio from "../ui/DopeRadio";
import DopeAccordionHeader from "../ui/DopeAccordionHeader";

const metersPerMile = 1609;

const maxDistanceOptions = [
  { label: "3 miles", value: 3 * metersPerMile },
  { label: "5 miles", value: 5 * metersPerMile },
  { label: "10 miles", value: 10 * metersPerMile },
  { label: "25 miles", value: 25 * metersPerMile },
  { label: "50 miles", value: 50 * metersPerMile },
  { label: "100 miles", value: 100 * metersPerMile },
];

const CampaignListBlitzSettings = ({ editable }) => {
  const [sampleAddressesOpen, setSampleAddressesOpen] = useState(false); // TODO get summary
  const [distanceAccordionOpen, setDistanceAccordionOpen] = useState(false);
  const [settings, actions] = useCampaignListGenerationSettings();

  const toggleDistanceAccordion = () => {
    setDistanceAccordionOpen(!distanceAccordionOpen);
  }

  return (
    <>
      <div className="geo-shapes-summary">
        <div className="geo-shapes-header">Contacts per address</div>

        <DopeTextInput
          name="max_contacts_per_generation"
          value={settings.max_contacts_per_generation}
          onChange={actions.update}
          style={{ margin: '20px 0' }}
        />

        <div className='geo-shapes-show-addresses' onClick={() => setSampleAddressesOpen(true)}>See Sample Addresses</div>
      </div>

      <div className="list-blitz-count-estimate margin-top color-text-secondary">
        Based on your list size, we would be sending to <b>{settings.max_contacts_per_generation * settings.starting_list.contacts_count}</b> addresses.
      </div>

      <div className="max-distance-radio-wrapper">
        <Accordion className="campaign-accordion" onSelect={toggleDistanceAccordion}>
          <Accordion.Panel header={<DopeAccordionHeader header="List Blitz Settings" label={true} open={distanceAccordionOpen} />} caretAs={() => <></>} eventKey={1}>
            <div className="color-text-secondary margin-8-t">
              We'll do our best to get <b>{settings.max_contacts_per_generation}
            </b> contacts around <i>each</i> address, but at what distance should we cap our search?
            </div>

            <DopeRadio
              name="max_distance"
              value={settings.max_distance}
              options={maxDistanceOptions}
              onChange={actions.update}
            />
          </Accordion.Panel>
        </Accordion>
      </div>
    </>
  );
};

export default CampaignListBlitzSettings;
