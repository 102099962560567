import React, { useState } from "react";

import DopeApi from "../../services/DopeApi";

const automationRunApi = new DopeApi('automation_run');

const wrapperStyle = {
  display: 'flex',
  gap: 20,
};

const loadingWrapperStyle = {
  ...wrapperStyle,
  pointerEvents: 'none',
  opacity: 0.5,
};

const Approve = ({ id, reload, approveText = 'Approve' }) => {
  const [updating, setUpdating] = useState(false);

  const handleApprove = async () => {
    setUpdating(true);
    await automationRunApi.update({ id, actions: [{ name: 'run', args: [true] }] });
    await reload(); // TODO: I HATE THIS
    setUpdating(false);
  };

  const handleDecline = async () => {
    setUpdating(true);
    await automationRunApi.update({ id, actions: [{ name: 'decline' }] });
    await reload(); // TODO: I HATE THIS
    setUpdating(false);
  };

  return (
    <div style={updating ? loadingWrapperStyle : wrapperStyle}>
      <span className="link-label" onClick={handleApprove}>
        {approveText}
      </span>

      <span className="link-label" onClick={handleDecline}>
        Decline
      </span>
    </div>
  );
};

const UndoDecline = ({ id, reload }) => {
  const [updating, setUpdating] = useState(false);

  const handleUndo = async () => {
    setUpdating(true);
    await automationRunApi.update({ id, actions: [{ name: 'undo_decline' }] });
    await reload(); // TODO: Coley hates this
    setUpdating(false);
  };

  return (
    <div style={updating ? loadingWrapperStyle : wrapperStyle}>
      <span className="link-label" onClick={handleUndo}>
        Undo
      </span>
    </div>
  );
};

const SendAnyways = (props) => {
  return (<Approve {...props} approveText="Send Anyways" />)
};

export {
  Approve,
  SendAnyways,
  UndoDecline,
}
