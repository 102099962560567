import React from 'react';
import {InputNumber} from "rsuite";

const DopeCentsInput = ({ name, value, onChange, disabled, ...rest }) => {

  return (
    <InputNumber
      disabled={disabled}
      name={name}
      value={value || ''}
      onChange={(value) => onChange({ [name]: value }, value, name)}
      {...rest}
      style={rest.styles}
      placeholder='0.00'
    />
  )
}

export default DopeCentsInput;
