function toDateString(date) {
  if (!date) return null;

  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  return `${year}-${month}-${day}`;
}

function fromDateString(dateString) {
  // Check if timestamp was provided
  let date = dateString;

  if (dateString.indexOf('T') > -1) {
    date = dateString.split('T')[0];
  }

  const [year, month, day] = date.split('-');
  return new Date(year, month - 1, day);
}

function timestampToDateStr(timestamp) {
  const date = new Date(timestamp);
  const month = date.getMonth() + 1; // Months are 0-indexed
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}


function timestampToDateTimeStr(timestamp) {
  if (!timestamp) return '';

  const timestampDate = new Date(timestamp);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(timestampDate).replace(" at ", " @ ")
}

function timestampToDayOfWeekStr(timestamp) {
  if (!timestamp) return '';

  const timestampDate = new Date(timestamp);
  const options = {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };

  return timestampDate.toLocaleDateString('en-US', options).replace(/, (?=\d{4})/, ' ');
}

// Returns a date string in the format of "Jan 1, 2021"
function formatToLocaleDateString(dateString, long = false) {
  if (!dateString) return '';

  const date = new Date(dateString);

  const options = { year: 'numeric', month: long ? 'long' : 'short', day: 'numeric' };
  // undefined varies according to local timezone and default locale, needs more testing
  return date.toLocaleDateString(undefined, options);
}

function formatDateStringToLocaleDateString(dateString, long = false) {
  const date = fromDateString(dateString);
  return formatToLocaleDateString(date, long);
}


function formatDate(inputDate) {
  const parsedDate = new Date(inputDate);
  const month = parsedDate.getMonth() + 1;
  const day = parsedDate.getDate();
  const year = parsedDate.getFullYear();

  return `${month}-${day}-${year}`;
}

function formatISO8601Date(date) {
  const splitDate = date.split('-');
  const year = splitDate[0];
  const month = splitDate[1];
  const day = splitDate[2];

  return formatDate(new Date(year, month - 1, day));
}

function unixTimestampToDateStr(timestamp, options = {}) {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString( 'en-US', options);
}

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export {
  toDateString,
  fromDateString,
  timestampToDateStr,
  timestampToDateTimeStr,
  timestampToDayOfWeekStr,
  formatToLocaleDateString,
  formatDateStringToLocaleDateString,
  formatDate,
  formatISO8601Date,
  unixTimestampToDateStr,
  timeSince,
};
