import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import DopeApi from "../services/DopeApi";

import DopeButton from '../ui/DopeButton';
import QRCodeDrawer from './QRCodeDrawer';
import { useQRCode } from './qrCodeSlice';

const qrCodeApi = new DopeApi('qr_code');

const CustomQRCodesPanel = observer(({ store, scope }) => {
  const [codes, setCodes] = useState([]);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [currentScope, setCurrentScope] = useState(null);

  const [loading, setLoading] = useState(false);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [id, setId] = useState(null);

  const { qrCode, actions } = useQRCode();

  const loadCodes = (page, reset = false) => {
    setLoading(true);
    qrCodeApi.query({
      per_page: 25,
      scopes: [{ name: scope }],
      page,
    })
      .then(data => {
        setCodes([...(reset ? [] : codes), ...data.qr_codes]);
        setLastPage(Math.ceil(data.total / data.per_page));
        setCurrentScope(scope);
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (scope !== currentScope) {
      loadCodes(1, true);
    } else {
      loadCodes(page);
    }
  }, [page, scope]);


  const QRCode = ({ name, code_url, content, status, onClick, onEdit, onArchive }) => {
    return (
      <div className='qr-code' onClick={onClick}>
        <div className='qr-code-image'>
          <img src={code_url} alt={name} />
        </div>
        <div className='qr-code-content'>
          <p><b>{name}</b></p>
          <p>{content}</p>
          <div>
            {/* <a onClick={onEdit}>Edit</a> */}
            {status === 'archived' && <a onClick={() => onArchive('active')}>Unarchive</a>}
            {status !== 'archived' && <a onClick={() => onArchive('archived')}>Archive</a>}

          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='qr-code-tab-container'>
      <div className='qr-code-list'>
        <DopeButton
          props={{
            label: 'Create Code',
            onClick: () => {
              setId('new');
              setCreateModalOpen(true)
            },
          }}
        />
        {codes.map((code, index) => (
          <QRCode
            key={index}
            {...code}
            onClick={() => {
              console.log(code);
              const data = {
                width: 300,
                height: 300,
                type: 'svg',
                src: code.code_url,
                maskSrc: '',
              };
              store.activePage?.addElement(data);
            }}
            onEdit={() => {
              setId(code.id);
              setCreateModalOpen(true);
            }}
            onArchive={async (status) => {
              await qrCodeApi.update({ id: code.id, status });
              loadCodes(1, true);
            }}
          />
        ))}
      </div>
      <QRCodeDrawer
        open={createModalOpen}
        id={id}
        onClose={() => {
          setCreateModalOpen(false);
          setId(null);
          actions.resetToInitial();
        }}
        onConfirm={async (svg) => {
          setLoading(true);
          const code = new File([svg], `media_qr_code_${Date.now()}.svg`, { type: 'image/svg+xml' });
          await actions.save({ code });
          actions.resetToInitial();
          loadCodes(1, true);
          setCreateModalOpen(false);
          setLoading(false);
        }}
        />
    </div>
  );
});

export default CustomQRCodesPanel;
