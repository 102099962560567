import React from "react";
import { useNavigate } from "react-router-dom";
import iconMap from "../dopeIconMap";

const automationIconMap = {
  CampaignTemplate: "mail",
  UpdateListTemplate: "list",
  NotifyTemplate: "notify",
  ReportTemplate: "report",
}

const IconNameDisplay = ({ item, rest }) => {
  const { iconKey, getUrl } = rest;
  const navigate = useNavigate();

  return (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer", width: "100%"}}>
      <div style={{ marginRight: "8px", minWidth: "40px", minHeight: "40px", borderRadius: "50%", backgroundColor: "#F0F4F7", display: "flex", alignItems: "center", justifyContent: "center"}}>
        {iconMap[automationIconMap[item[iconKey]]] || item[iconKey]}
      </div>
      <div onClick={() => navigate(getUrl(item))}>
        <div style={{fontSize: "14px", color: "#000000", fontWeight: 600}}>{item.name}</div>
        <div style={{ fontSize: "11px", color: "#8793A6", display: "flex", flexWrap: "wrap"}}>
          <div>{item.description}</div>
        </div>
      </div>
    </div>
  );
}

export default IconNameDisplay;
