import React from "react";

import DopeApi from "../../services/DopeApi";
import { useQuery2 } from "../../hooks/useQuery";

import DopeListPage from "../../ui/DopeListPage";
import DopeFilters from "../../ui/dopeFilters/DopeFilters";
import DopeButton from "../../ui/DopeButton";
import DopeChart from "../../ui/DopeChart";

const api = new DopeApi('delayed_job');
const accountApi = new DopeApi('account');

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', width: 100, sortable: true },
  { type: 'text', dataKey: 'last_error', label: 'Last Error', sortable: false, fullText: true },
  { type: 'text', dataKey: 'handler', label: 'Handler', sortable: false, fullText: true },
  { type: 'text', dataKey: 'account_id', label: 'Acc #', width: 75, sortable: false },
  { type: 'text', dataKey: 'account_name', label: 'Acc Name', sortable: false, fullText: true },
  { type: 'pill', dataKey: 'queue', label: 'Queue', sortable: false },
  { type: 'text', dataKey: 'priority', label: 'Priority', width: 75, sortable: false },
  { type: 'text', dataKey: 'attempts', width: 100, label: 'Attempts', sortable: false },
  { type: 'timestamp', dataKey: 'created_at', width: 120, label: 'Created At', sortable: true },
  { type: 'timestamp', dataKey: 'run_at', width: 120, label: 'Run At', sortable: true },
  { type: 'timestamp', dataKey: 'locked_at', width: 120, label: 'Locked At', sortable: true },
  { type: 'timestamp', dataKey: 'failed_at', width: 120, label: 'Failed At', sortable: true },
];

const filterInputs = [
  {
    title: "Account",
    type: "MultiSelect",
    name: "account_ids",
    field: "account_id",
    searchable: true,
    getOptions: (text = "") => accountApi.getOptions({
      valueKey: 'id',
      labelKey: 'name',
      search: { text, column: 'name' },
    }),
  },
  {
    title: "Queue",
    type: "MultiSelect",
    name: "queue",
    field: "queue",
    searchable: false,
    getOptions: () => ([
      { value: 'default', label: 'Default' },
      { value: 'default_mailer', label: 'Mailers' },
      { value: 'highest_priority', label: 'Highest Priority' },
      { value: 'generate', label: 'Generate' },
      { value: 'import', label: 'Historical Import' },
    ]),
  },
  {
    title: "Date Range",
    type: "DateRange",
    name: "created_at_range",
    field: "created_at",
  },
];

const chartOptions = {
  xFields: [
    { value: 'created_at', type: 'datetime' },
    { value: 'account_id', type: 'text' },
    { value: 'queue', type: 'text' },
  ],
  initialXFunc: 'day',
  yFields: [
    { value: 'id', type: 'numeric' },
  ],
  initialYFunc: 'count',
};


const DelayedJobTable = () => {
  const { error, tableProps, paginationProps, setFilters, filters, exportCSV, exporting, reload, chartDataParams } = useQuery2({
    api,
  });

  const refreshButton = <DopeButton
    key="refresh"
    props={{
      buttonClass: "text-link",
      label: "Refresh",
      onClick: () => reload(),
    }}
  />

  const filterBar = (
    <DopeFilters
      filterInputs={filterInputs}
      filters={filters}
      setFilters={setFilters}
    />
  );

  const listPage = (
    <DopeListPage
      columns={columns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      setFilters={setFilters}
      filters={filters}
      error={error}
    />
  );

  const chart = (
    <div className="margin-tb-10">
      <DopeChart
        api={api}
        params={chartDataParams}
        options={chartOptions}
      />
    </div>
  );

  return (
    <div>
      <div className="to-right gap-10 vertical-align">
        {refreshButton}
      </div>
      {filterBar}
      {chart}
      {listPage}
    </div>
  );
};

export default DelayedJobTable;
