import React from "react";
import { useLocation } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { Message } from "rsuite";

import DopeTabs from "../ui/DopeTabs";

import { useAccount } from "./accountSlice";

import { useStripeUI } from "../stripe/stripeUISlice";
import DopeDrawer from "../ui/DopeDrawer";
import InvoiceDrawer from "../stripe/InvoiceDrawer";
import AccountPaymentMethods from "./AccountPaymentMethods";
import StripeCardAdderWrapper from "../stripe/StripeCardAdderWrapper";
import StripeCardDrawer from "../stripe/StripeCardDrawer";
import authApi from "../auth/authApi";

const billingPages = [
  { name: "automated_purchases", label: "Mail Purchases" },
  { name: "credit_ledger", label: "Credit Ledger" },
  { name: "invoices", label: "Invoices" },
];

const AccountInvoiceDrawer = ({ account }) => {
  const [stripeUI, stripeUiActions] = useStripeUI();

  return (
    <DopeDrawer
      className="invoice-drawer"
      open={!!stripeUI.drawerInvoiceId}
      onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
      bodyContent={
        <InvoiceDrawer
          id={stripeUI.drawerInvoiceId}
          account={account}
          onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
        />
      }
    />
  );
};

const AccountCardDrawer = () => {
  const [stripeUI, stripeUiActions] = useStripeUI();

  return (
    <DopeDrawer
      className="payment-method-drawer"
      header={"Add Payment Method"}
      open={!!stripeUI.cardDrawerOpen}
      onClose={() => stripeUiActions.setCardDrawerOpen(false)}
      bodyContent={
        <StripeCardAdderWrapper
          Children={StripeCardDrawer}
          props={{
            onClose: () => stripeUiActions.setCardDrawerOpen(false),
          }}
        />
      }
    />
  );
};


const AccountBilling = () => {
  const [stripeUI, stripeUiActions] = useStripeUI();
  const [account] = useAccount();
  const navigate = useNavigate();
  const childTab = useLocation().pathname.split("/").pop();

  const subscriptionInvoicePaid = authApi.currentAccountSubscriptionIsPaid();

  const tabs = billingPages.map((page) => <DopeTabs.Tab key={page.name} name={page.name} label={page.label} />);
  const onTabChange = (tab) => {
    navigate(`/accounts/${account.id}/billing/${tab}`);
  };

  return (
    <>
      <div className="header-2 margin-4-b">Payments & Billing</div>

      {!subscriptionInvoicePaid && (
        <Message showIcon bordered={true} header="Your subscription has lapsed due to a failed payment" type="error">
          Please <div className="bold link-label inline-block" onClick={() => stripeUiActions.setCardDrawerOpen(true)}>update</div> your payment method below or reach out to your account executive.
        </Message>
      )}

      <AccountPaymentMethods
        account={account}
        cardReload={!stripeUI.cardDrawerOpen}
      />

      <DopeTabs defaultTab={childTab} onChange={onTabChange}>
        {tabs}
      </DopeTabs>

      <Outlet />


      <AccountInvoiceDrawer account={account} />
      <AccountCardDrawer />
    </>
  );
}

export default AccountBilling;
