const validateRules = (rules) => {
  const defaultField = '~';
  const defaultValue = '';

  return rules.every((rule) => {
    if (rule.rules) {
      return validateRules(rule.rules);
    }
    const operator = rule.operator;
    const hasValidOperator = !!operator;
    const hasValidField = !!rule.field && rule.field !== defaultField;
    const hasValidValue = ["isNull", "isNotNull", "isEmptyString", "changed"].includes(operator) || (rule.hasOwnProperty('value') && rule.value !== defaultValue);

    return hasValidOperator && hasValidField && hasValidValue;
  });
};

// TODO move validates for querys and triggers here


export {
  validateRules,
}
