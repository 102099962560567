import React from "react";

const AttributeDisplay = ({ label, value }) => {
  return (
    <div className="pad-4 margin-bottom">
      <div className="label large bold margin-4-b">{label}</div>
      <div className="label large">{value}</div>
    </div>
  );
};

const AttributesDisplay = ({ attributes }) => {
  return (
    <div className="campaign-details">
      {attributes.map((detail, i) => (
        <div key={i} className="flex row space-between margin-8-b">
          <div className="body-1 margin-8-r">{detail.label}</div>
          <div className="body-1 color-text-secondary">{detail.value}</div>
        </div>
      ))}
    </div>
  );
}

export { AttributeDisplay, AttributesDisplay };
