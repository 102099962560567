import React, { useState } from "react";

import { useAutomation } from './automationSlice';
import useObjectState from "../hooks/useObjectState";
import { useDopeUI } from "../ui/dopeUISlice"
import { humanize } from "../utils/railsNames";
import DopeApi from "../services/DopeApi";

import DopeDrawer from "../ui/DopeDrawer";
import DopeAddressInput from "../ui/DopeAddressInput";
import { DopeFormGroup } from "../ui/DopeForm";
import DopeButton from "../ui/DopeButton";

const automationsApi = new DopeApi('automation');

const buttonStyles = {
  minWidth: 170,
};

const AutomationManualTrigger = ({ open, onClose }) => {
  const { automation } = useAutomation();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [triggerParams, updateTriggerParams, setTriggerParams] = useObjectState({});
  const [triggering, setTriggering] = useState(false);
  const header = `Submit a manual trigger for ${automation.name}`;

  const isDirectSend = automation.campaign_template.list_generation_setting.generation_type === 'list';
  const labelPostfix = isDirectSend ? '' : ' (optional)';

  const firstNameInput = {
    inputType: 'text',
    name: 'first_name',
    props: {
      label: 'First Name' + labelPostfix,
    },
  };

  const lastNameInput = {
    inputType: 'text',
    name: 'last_name',
    props: {
      label: 'Last Name' + labelPostfix,
    },
  };

  const nameInputs = (
    <div className="split-cols">
      <DopeFormGroup input={firstNameInput} values={triggerParams} onChange={updateTriggerParams} />
      <DopeFormGroup input={lastNameInput} values={triggerParams} onChange={updateTriggerParams} />
    </div>
  );

  const hasAddress = !!triggerParams.latitude && !!triggerParams.longitude;
  const hasName = isDirectSend ? !!triggerParams.first_name && !!triggerParams.last_name : true;
  const canTrigger = hasAddress && hasName;

  const handleClose = () => {
    setTriggerParams({});
    onClose();
  };

  const handleTrigger = async () => {
    try {
      setTriggering(true);
      const { status } = await automationsApi.postMember(automation.id, 'manual_trigger', triggerParams);
      handleClose();
      dopeUIActions.addFlashMessage({ type: 'success', body: `Trigger was created with the status: ${humanize(status)}`, header: 'Success!' });
    } catch (error) {
      console.error(error);
      dopeUIActions.addFlashMessage({ type: 'error', body: "Could not trigger selected address", header: 'Error!' });
    } finally {
      setTriggering(false);
    }
  };

  return (
    <DopeDrawer open={open} onClose={handleClose} header={header}>
      <div className="pad-tb">
        {nameInputs}
        <div className="margin-tb">
          <DopeAddressInput values={triggerParams} onChange={updateTriggerParams} label="Address" />
        </div>
        <div className="margin-header space-between">
          <DopeButton
            className="outlined-black"
            onClick={handleClose}
            style={buttonStyles}
          >Cancel</DopeButton>

          <DopeButton
            className="filled-black"
            onClick={handleTrigger}
            disabled={!canTrigger || triggering}
            loading={triggering}
            style={buttonStyles}
          >Submit</DopeButton>
        </div>
      </div>
    </DopeDrawer>
  );
}

export default AutomationManualTrigger;
