import React, { useState }  from "react";

import { chunk } from "lodash";
import { useAutomation } from "./automationSlice";

import DopePageStepHeader from "../ui/DopePageStepHeader";
import CopyInput from "../ui/CopyInput";
import AccountUserTokens from "../accounts/AccountUserTokens";

import '../integrations/integrations.scss';

const triggerParams = [
  { label: "First Name", value: "first_name", required: true },
  { label: "Last Name", value: "last_name", required: true },
  { label: "Address 1", value: "address_1", required: true },
  { label: "Address 2", value: "address_2", required: false },
  { label: "City", value: "city", required: true },
  { label: "State", value: "state", required: true },
  { label: "Zip", value: "zip", required: true },
  { label: "Zip 4", value: "zip_4", required: false },
  { label: "Phone Number", value: "phone_number", required: false },
  { label: "Country", value: "country", required: false },
]

// TODO highlight url ability, remove token index
const AutomationTriggerNative = () => {
  const { automation, actions } = useAutomation();
  const [tokenIndex, setTokenIndex] = useState(0);
  const [token, setToken] = useState(null);

  const handleTokenChange = (index, token) => {
    setTokenIndex(index);
    setToken(token);
  };

  let url = automation.action_url;
  if (token) {
    url = `${url}?token=${token.token}`;
  }

  const exampleCurl = `curl --request POST ${automation.action_url} \\\n\t--header "Content-Type: application/json" \\\n\t--header "Authorization: Bearer ${token ? token.token : 'YOUR_TOKEN'}" \\\n\t--data '{"first_name": "John", "last_name": "Doe", "address_1": "123 Main St", "city": "Anytown", "state": "CA", "zip": "12345"}'`;
  const exampleCurlUsingQueryParams = `curl --request POST "${automation.action_url}?token=${token ? token.token : 'YOUR_TOKEN'}&first_name=John&last_name=Doe&address_1=123%20Main%20St&city=Anytown&state=CA&zip=12345"`;

  return [
    (
      <div className="page" key="step-2">
        <DopePageStepHeader
          step="2"
          headerText="Webook Connections"
          helpText="Each software and app have different requirements to make a connection but all will need an authenticated webhook.  Generate one below and copy and paste in your software."
          callOutText="(Remember: you will first need to create and save your automation here in order to see it in other apps and to make that connection.)"
        />

        <br />

        <AccountUserTokens
          headerClass="header-5"
          headerText="Select an API token"
          helpText="Create or select a token below to authenticate your webhook."
          selectedTokenIndex={tokenIndex}
          onTokenChange={handleTokenChange}
        />
      </div>
    ), (
      <div className="page" key="step-3">
        <DopePageStepHeader
          step="3"
          headerText="Copy authenticated webhook url"
          helpText="Copy the generated authenticated webhook and paste in your app."
        />

        <CopyInput value={url} prefix="POST" inputStyles={{ color: "#255FDF" }} />
      </div>
    ), (
      <div className="page" key="step-4">
        <DopePageStepHeader
          step="4"
          headerText="Format you POST request data like the example below"
          helpText={(<span>
            We require, <b>First Name</b>, <b>Last Name</b>, <b>Address 1</b>, <b>City</b>, <b>State</b>, and <b>Zip</b> to send mail. Copy the data fields provided below to map in your app.
          </span>)}
        />

        {
          chunk(triggerParams, 2).map((chunk, i) => (
            <div key={i} className="margin-16-b space-between gap-80">
              {chunk.map((param) => (
                <div key={param.value} className="flex-1">
                  <div className="space-between margin-4-b">
                    <span className="label">{param.required && "*"} {param.label}</span>
                    {!param.required && (<span className="label color-text-blue">Optional</span>)}
                  </div>

                  <CopyInput value={param.value} />
                </div>
              ))}
            </div>
          ))
        }

        <div className="margin-header">
          <div className="header-6 margin-4-b">Example using JSON data</div>
          <CopyInput value={exampleCurl} textarea rows={4} />
        </div>

        <div className="margin-header">
          <div className="header-6 margin-4-b">Example using query parameters</div>
          <CopyInput value={exampleCurlUsingQueryParams} />
        </div>
      </div>
    )
  ]
};

export default AutomationTriggerNative;
