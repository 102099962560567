import React from "react";

import { useNavigate } from 'react-router-dom';

import { useCampaign } from "./campaignSlice";
import { toDateString } from "../utils/date";
import { joinWithAnd } from "../utils/string";

import DopeMessage from "../ui/DopeMessage";


const CampaignDispatchWarning = () => {
  const [campaign] = useCampaign();
  const navigate = useNavigate();

  const dispatchesInPast = campaign.dispatches.filter((d) => d.date < toDateString(new Date()));
  const hasDispatchesInThePast = dispatchesInPast.length > 0;
  if (!hasDispatchesInThePast) {
    return null;
  }

  const bodyText = (
    <div>
      To schedule your campaign, please update the sending dates of the mailers scheduled for <b>{joinWithAnd(dispatchesInPast.map((d) => d.date))}</b>
    </div>
  );

  const handleClickAction = () => {
    const mailersUrl = `/campaigns/${campaign.id}/mailers`;
    navigate(mailersUrl);
  };

  return (
    <div className="page padding-none">
      <DopeMessage
        type="error"
        header="You have mailers with send dates in the past"
        body={bodyText}
        actionText="Edit Mailers"
        onClickAction={handleClickAction}
      />
    </div>
  );
};

export default CampaignDispatchWarning;
