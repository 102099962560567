import React, {useEffect, useState} from "react";

import { useCampaign } from "./campaignSlice";
import CampaignAudienceSummary from "./CampaignAudienceSummary";

const CampaignDetails = () => {
  const [campaign, campaignActions] = useCampaign();
  const [totalContacts, setTotalContacts] = useState(0);
  const [totalMailPieces, setTotalMailPieces] = useState(0);

  useEffect(() => {
    let contactCount = 0;
    if (campaign.is_eddm) {
      contactCount = campaign.eddm.total_delivery_addresses || campaign.action_results?.purchase_summary?.total_contacts_count;
      setTotalContacts(contactCount);
    } else {
      contactCount = campaign.final_contacts_count || campaign.action_results?.purchase_summary?.total_contacts_count;
      setTotalContacts(contactCount);
    }

    setTotalMailPieces(campaign.dispatches.filter(dispatch => dispatch.status !== "cancelled").length * contactCount)
  }, []);

  return (
    <div className="border">
      <div className="flex row drawer-info-box border-bottom">
        <div className="half-width flex column to-center vertical-align border-right">
          <div className="label-large margin-8-b">Total Contacts</div>
          <h4 className="header-4">{totalContacts || 0}</h4>
        </div>

        <div className="half-width flex column to-center vertical-align">
          <div className="label-large margin-8-b">Total Mail Pieces</div>
          <h4 className="header-4">{totalMailPieces || 0}</h4>
        </div>
      </div>

      <CampaignAudienceSummary/>
    </div>
  )
}

export default CampaignDetails;
