import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import railsNames from '../utils/railsNames';

import DopeApi from '../services/DopeApi';
// const api = new DopeApi('campaign');

// ex: list
export default function createModelSlice(modelName, reducers = {}, extraReducers = () => {}, extraState = {}) {
  const { displayName } = railsNames(modelName); // List

  const api = new DopeApi(modelName); // new DopeApi('list');

  const saveAction = createAsyncThunk(
    `${modelName}/save${displayName}`,
    async (resource, { rejectWithValue }) => {
      try {
        return await api.save(resource);
      } catch (errors) {
        return rejectWithValue(errors);
      }
    }
  );

  const createAction = createAsyncThunk(
    `${modelName}/create${displayName}`,
    async (resource, { rejectWithValue }) => {
      try {
        return await api.create(resource);
      } catch (errors) {
        return rejectWithValue(errors);
      }
    }
  );

  const getAction = createAsyncThunk(
    `${modelName}/get${displayName}`,
    async (id, { rejectWithValue }) => {
      try {
        return await api.get(id);
      } catch (errors) {
        return rejectWithValue(errors);
      }
    }
  );

  const destroyAction = createAsyncThunk(
    `${modelName}/destroy${displayName}`,
    async (id, { rejectWithValue }) => {
      try {
        return await api.destroy(id);
      } catch (errors) {
        return rejectWithValue(errors);
      }
    }
  );

  const initialState = {
    prev: null,
    current: null,
    isGetting: false,
    isSaving: false,
    isDestroying: false,
    error: null,
    ...extraState,
  };

  const slice = createSlice({
    name: modelName,
    initialState,
    reducers: {
      [`update${displayName}`]: ({ current: resource }, { payload: update }) => {
        Object.assign(resource, update);
      },
      update: ({ current: resource }, { payload: update }) => { // TODO what naming do we want?
        Object.assign(resource, update);
      },
      resetToInitial: (state) => {
        state.current = null;
        state.prev = null;
      },
      ...reducers,
    },
    extraReducers: (builder) => {
      builder
        .addCase(saveAction.pending, (state) => {
          state.isSaving = true;
        })
        .addCase(saveAction.fulfilled, (state, { payload: resource }) => {
          state.prev = resource;
          state.current = resource;
          state.isSaving = false;
        })
        .addCase(saveAction.rejected, (state, { payload: errors }) => {
          Object.assign(state.current, { errors });
          state.isSaving = false;
        })
        .addCase(createAction.pending, (state) => {
          state.isSaving = true;
        })
        .addCase(createAction.fulfilled, (state, { payload: resource }) => {
          state.prev = resource;
          state.current = resource;
          state.isSaving = false;
        })
        .addCase(createAction.rejected, (state, { payload: errors }) => {
          Object.assign(state.current, { errors });
          state.isSaving = false;
        })
        .addCase(getAction.pending, (state) => {
          state.isGetting = true;
        })
        .addCase(getAction.fulfilled, (state, { payload: resource }) => {
          state.prev = resource;
          state.current = resource;
          state.isGetting = false;
        })
        .addCase(getAction.rejected, (state, { payload: errors }) => {
          state.error = errors;
          state.isGetting = false;
        })
        .addCase(destroyAction.pending, (state) => {
          state.isDestroying = true;
        })
        .addCase(destroyAction.fulfilled, (state, { payload: resource }) => {
          state.prev = resource;
          state.current = resource;
          state.isDestroying = false;
        })
        .addCase(destroyAction.rejected, (state, { payload: errors }) => {
          state.error = errors;
          state.isDestroying = false;
        });
      extraReducers(builder);
    },
  });

  return [slice, {
    create: createAction,
    save: saveAction,
    get: getAction,
    destroy: destroyAction,
  }];
};
