import React from "react";
import { FlexboxGrid } from "rsuite";

import CreditSummary from "../credits/CreditSummary";
import QuickActions from "./QuickActions";
import PendingActions from "./PendingActions";
import ToDoList from "./ToDoList";
import YourTeam from "./YourTeam";
import RecentActivity from "./RecentActivity";
import MailingsGraphWrapper from "./MailingsGraphWrapper";
import DopeDrawer from "../ui/DopeDrawer";
import ContactDrawerForm from "../contacts/ContactDrawerForm";
import {useContactUI} from "../contacts/contactUISlice";
import {useAccount} from "../accounts/accountSlice";

import "./Overview.scss";
import StrategyBoard from "./StrategyBoard";

const Overview = () => {
  const [contactUI, contactUIActions] = useContactUI();
  const [account, accountActions] = useAccount();

  return (
    <div className="overview">
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={16}>
          <PendingActions />
          <MailingsGraphWrapper />
          <RecentActivity initialPerPage={5} />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8} style={{paddingLeft: "20px"}}>
          <QuickActions />
          <CreditSummary />
          {(account?.strategy_board_url || account?.assets_drive_url) && <StrategyBoard />}
          <YourTeam />
          <ToDoList />
        </FlexboxGrid.Item>
      </FlexboxGrid>


      <ContactDrawerForm
        open={!!contactUI.drawerContactId}
        onClose={() => contactUIActions.setDrawerContactId(null)}
        header="Update Contact"
        id={contactUI.drawerContactId}
      />
    </div>
  );
};

export default Overview;
