import React from "react";

import classNames from "classnames";
import iconMap from "./dopeIconMap";

import './DopeIconCard.scss';


const DopeIconCard = ({ icon, header, subheader, selected, disabled, onClick }) => {
  const classes = classNames("dope-icon-card", { selected, disabled });

  return (
    <div className={classes} onClick={onClick}>
      <div className="dope-icon-card-icon">{iconMap[icon] || icon}</div>
      <div className="dope-icon-card-text">
        <div className="dope-icon-card-header">{header}</div>
        <div className="dope-icon-card-subheader">{subheader}</div>
      </div>
    </div>
  );
};

export default DopeIconCard;
