import React from 'react';
import {MaskedInput} from "rsuite";

const DopePhoneInput = ({ name, value, onChange, disabled, ...rest }) => {

  return (
    <MaskedInput
      disabled={disabled}
      name={name}
      value={value || ''}
      onChange={(value) => onChange({ [name]: value }, value, name)}
      {...rest}
      style={rest.styles}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  )
}

export default DopePhoneInput;
