import React from "react";
import { Modal } from "rsuite";

import DopeApi from "../services/DopeApi";
import useObjectState from "../hooks/useObjectState";
import { useDopeUI } from "../ui/dopeUISlice";

import DopeButton from "../ui/DopeButton";
import DopeTextInput from "../ui/DopeTextInput";
import BelongsToSelect from "../ui/BelongsToSelect";
import DopeSelect from "../ui/DopeSelect";

const creditTransactionApi = new DopeApi("credit_transaction");

const creditReasons = [
  { value: "Marketing Campaign", label: "Marketing Campaign" },
  { value: "Technical Issue", label: "Technical Issue" },
  { value: "Billing Issue", label: "Billing Issue" },
  { value: "DOPE Team Error", label: "DOPE Team Error" },
  { value: "Customer Complaint", label: "Customer Complaint" },
  { value: "One-Off Purchase", label: "One-Off Purchase" },
  { value: "Other", label: "Other" }
];

const isValid = (creditTransaction) => {
  return creditTransaction.account_id && creditTransaction.product_id && creditTransaction.credit_reason && creditTransaction.amount;
};

const AddCreditsModal = ({ open, onClose }) => {
  const [creditTransaction, updateCreditTransaction, _set, resetCreditTransation] = useObjectState({});
  const [dopeUI, dopeUIActions] = useDopeUI();

  const handleClose = () => {
    resetCreditTransation();
    onClose(true);
  };

  const handleSubmit = async () => {
    try {
      await creditTransactionApi.create(creditTransaction);
      handleClose(true);
      dopeUIActions.addFlashMessage({ header: "Credits Transaction Applied", type: "success" });
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error creating credit transaction", type: "error" });
    }
  };

  return (
    <Modal open={open} onClose={() => handleClose(false)} size="md">
      <Modal.Header>
        <div className="header-5">Add / Remove Credits</div>
      </Modal.Header>

      <Modal.Body>
        <div className="split-cols">
          <div>

            <div className="margin-10-b">
              <div className="label margin-4-b">Account</div>
              <BelongsToSelect
                modelName="account"
                onChange={updateCreditTransaction}
                value={creditTransaction.account_id}
              />
            </div>

            <div className="margin-tb-10">
              <div className="label margin-4-b">Type of Credit</div>
              <BelongsToSelect
                modelName="product"
                labelKey="label"
                onChange={updateCreditTransaction}
                value={creditTransaction.product_id}
                searchable={false}
              />
            </div>

            <div className="margin-tb-10">
              <div className="label margin-4-b">Amount</div>
              <DopeTextInput
                type="number"
                name="amount"
                value={creditTransaction.amount}
                onChange={updateCreditTransaction}
              />
            </div>

            <div className="margin-tb-10">
              <div className="label margin-4-b">Reason</div>
              <DopeSelect
                name="credit_reason"
                options={creditReasons}
                onChange={updateCreditTransaction}
                value={creditTransaction.credit_reason}
                searchable={false}
              />
            </div>
          </div>

          <div>
            <div className="margin-10-b">
              <div className="label margin-4-b">Notes</div>
              <DopeTextInput
                as="textarea"
                rows={7}
                name="description"
                value={creditTransaction.description}
                onChange={updateCreditTransaction}
              />
            </div>

            <DopeButton
              disabled={!isValid(creditTransaction)}
              className="filled-black"
              onClick={handleSubmit}
              style={{ width: "100%", marginTop: "20px" }}
            >{creditTransaction.amount < 0 ? 'Remove' : 'Add'} Credits</DopeButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCreditsModal;
