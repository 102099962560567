import React from "react";
import DopeTabs from "../ui/DopeTabs";
import MailPieceHistory from "./MailPieceHistory";
import MailRoutePieceHistory from "../eddm/MailRoutePieceHistory";

const MailHistory = () => {
  return (
    <div className="page">
      {/*<h5 style={{fontWeight: 600, fontSize: "22px", color: "#292B2E", marginBottom: "5px"}}>Mailing History</h5>*/}
      {/*<div style={{fontWeight: 400, fontSize: "14px", color: "#6F6F6F"}}>Keep an eye on what’s coming up for your*/}
      {/*  scheduled mail, where in the DOPE process your mail is and when it’s out being brought to mailboxes.*/}
      {/*</div>*/}

      <DopeTabs>
        <DopeTabs.Tab
          key="mail"
          name="mail"
          label="Mailing History"
          disabled={false}
        >
          <MailPieceHistory/>
        </DopeTabs.Tab>

        <DopeTabs.Tab
          key="eddm"
          name="eddm"
          label="EDDM History"
          disabled={false}
        >
          <MailRoutePieceHistory/>
        </DopeTabs.Tab>
      </DopeTabs>
    </div>
  );
}

export default MailHistory;
