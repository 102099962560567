import React, { useEffect } from 'react';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { useListPull } from './listPullSlice';
import { useDopeUI } from '../ui/dopeUISlice';
import { errorsToErrorList } from '../utils/errors';
import DopeLoader from '../ui/DopeLoader';
import DopeButton from '../ui/DopeButton';
import useDopePageTabs from '../ui/useDopePageTabs';
import { validateRules } from '../integrations/util/validate'; // TODO all validations in validate.js

const REQUIRED_MAP_FIELDS = ['address1', 'city', 'state', 'firstName', 'lastName'];

const pages = [
  {
    name: 'Details',
    path: '/list_pulls/:id/setup',
    isValid: (listPull) => {
      return !!listPull.name;
    },
  },
  {
    name: 'Filters & Fields',
    path: '/list_pulls/:id/query',
    isValid: (listPull) => {
      const { query_data } = listPull;
      const { connection_id, data_source, data_type, conditions, cdm_map } = query_data;

      const hasConnection = !!connection_id;
      const hasDataSource = !!data_source;
      const hasDataType = !!data_type;
      const cdmValid = !!cdm_map && REQUIRED_MAP_FIELDS.every(field => !!cdm_map[field]);
      const allRulesValid = validateRules(conditions?.rules || []);

      const valid = hasConnection && hasDataSource && hasDataType && allRulesValid && cdmValid;

      return valid;
    },
  }
];

const ListPull = () => {
  const { id } = useParams();
  const { listPull, actions, isSaving } = useListPull();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const navigate = useNavigate();

  useEffect(() => {
    if (!listPull) {
      actions.get(id);
    }

    return actions.resetToInitial;
  }, []);

  const {
    PageTabs,
    BackLink,
    navigateForward,
    isLastPage,
    isValid,
  } = useDopePageTabs({ pages, resource: listPull, exitUrl: '/lists_and_contacts', exitText: 'Back to lists & contacts' });

  const navigateToLists = (listPull) => navigate(`/lists_and_contacts`);

  const flashErrors = (errors) => {
    dopeUIActions.addFlashMessage({
      type: 'error',
      header: 'Save Failed',
      body: errorsToErrorList(errors),
      timeout: 10000,
    });
  };

  const save = (extraParams = {}) => {
    return actions.save(extraParams)
      .then(action => {
        const success = action.meta.requestStatus === 'fulfilled';
        if (success) {
          return Promise.resolve(action.payload);
        }

        return Promise.reject(action.payload);
      });
  };

  const handleSave = () => save().catch(flashErrors);
  const handleActivate = () => save({ actions: [{ name: "activate" }] }).then(navigateToLists).catch(flashErrors);

  if (!listPull) {
    return <DopeLoader />;
  }

  const continueButton = (
    <DopeButton
      disabled={!isValid}
      className="next-btn"
      props={{
        buttonClass: 'filled',
        label: 'Next',
        onClick: navigateForward,
      }}
    />
  );

  const saveAndCreateButton = (
    <DopeButton
      disabled={!isValid || isSaving}
      className="next-btn"
      props={{
        buttonClass: 'filled',
        label: 'Save & Pull List',
        onClick: handleActivate,
      }}
    />
  );

  const isDraft = listPull.status === 'draft';
  const saveLabel = isDraft ? 'Save As Draft' : 'Save';

  const saveButton = (
    <DopeButton
      disabled={!isValid}
      className="outlined-black"
      loading={isSaving}
      props={{
        buttonClass: 'filled',
        label: saveLabel,
        onClick: handleSave,
      }}
    />
  );

  return (
    <div>
      <BackLink className="margin" />

      <div className="page top">
        <PageTabs />
      </div>

      <Outlet />

      <div className="footer-actions">
        {isLastPage ? saveButton : <div></div>}
        {isLastPage ? saveAndCreateButton : continueButton}
      </div>
    </div>
  );
};

export default ListPull;
