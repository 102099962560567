import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import authApi from "../auth/authApi";
import { useAutomation } from "./automationSlice";
import { useAutomationUI } from "./automationUISlice";
import PurchaseSummary from "../purchasing/PurchaseSummary";
import ErrorBoundary from "../ui/ErrorBoundary";

const AutomationPurchaseSummary = () => {
  const navigate = useNavigate();

  const { automation, actions } = useAutomation();
  const [automationUI, automationUIActions] = useAutomationUI();
  const [purchase_summary, setPurchaseSummary] = useState(null);

  useEffect(() => {
    actions.getPurchaseSummary().then(({ payload: purchase_summary }) => {
      setPurchaseSummary(purchase_summary);
    });
  }, []);

  const isDraft = automation.status === "draft";
  const handleClickUpdateCard = isDraft ? () => {
    actions.save()
      .then(() => {
        navigate(`/accounts/${authApi.getCurrentAccountId()}/billing`);
      });
  } : null;

  return (
    <ErrorBoundary header="Failed To Load Purchase Summary">
      <PurchaseSummary
        purchase_summary={purchase_summary}
        onClickUpdateCard={handleClickUpdateCard}
        resourceType="automation"
        showConfirmation={isDraft}
        confirmed={automationUI.confirmedPurchase}
        onChangeConfirmed={automationUIActions.update}
        priceHeader="Estimated Total (per trigger)"
      />
    </ErrorBoundary>
  );
};

export default AutomationPurchaseSummary;
