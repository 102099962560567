import { Table } from "rsuite";
import { capitalize } from "lodash";

import DopeDrawer from "../ui/DopeDrawer";

import { useEffect, useState } from "react";
import DopeLoader from "../ui/DopeLoader";
import { timestampToDateTimeStr } from "../utils/date";

const { Column, HeaderCell, Cell } = Table;


const ChangeLogDrawer = ({ open, onClose, qrCode }) => {

  const [changes, setChanges] = useState([]);

  useEffect(() => {
    if (open && qrCode) {
      setChanges(qrCode.activity_histories.filter(h => h.data.content));
    } else {
      setChanges([]);
    }

    return () => {
      setChanges([]);
    };
  }, [qrCode, open]);

  return (
    <DopeDrawer
      className="qr-code-scans-drawer"
      open={open}
      onClose={onClose}
      header={qrCode?.name ?? ""}
    >
        <div>
          {!qrCode && <DopeLoader />}
          {qrCode && (
            <>
              <p><small>{changes.length} change{changes.length !== 1 ? 's' : ''}</small></p>
              <Table
                data={changes}
                rowStyle={{ borderBottom: 'none'}}
                bordered
                rowHeight={60}
                autoHeight={true}
              >
                <Column align="left" flexGrow={1}>
                  <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Date</HeaderCell>
                  <Cell dataKey="created_at" style={{color: "#6F6F6F", fontSize: "12px"}}>
                    {rowData => (
                      <div>{timestampToDateTimeStr(rowData.created_at)}</div>
                    )}
                  </Cell>
                </Column>
                <Column align="left" flexGrow={1}>
                  <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Action</HeaderCell>
                  <Cell dataKey="action" style={{color: "#6F6F6F", fontSize: "14px", height: 'auto' }}>
                    {rowData => {
                      if (rowData.action === 'created') {
                        return <div><span>{capitalize(rowData.action)} QR Code</span><br/><small>{rowData.data.content}</small></div>
                      } else if (rowData.action === 'updated') {
                        return <div><span>{capitalize(rowData.action)} QR Code</span><br/><small>{rowData.data.content[1]}</small>
                        </div>
                      }
                    }}
                  </Cell>
                </Column>
              </Table>
            </>
          )}
        </div>
    </DopeDrawer>
  );
};

export default ChangeLogDrawer;
