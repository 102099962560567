import React from "react";
import { useOutletContext } from "react-router-dom";
import { MdApartment, MdMapsHomeWork, MdOutlineFilterAlt, MdRoofing } from "react-icons/md";

import GeoFilterInput from "./GeoFilterInput";
import { useCampaign, useCampaignListGenerationSettings } from "./campaignSlice";
import { DopeFormGroup } from "../ui/DopeForm";
import Cannot from "../ui/Cannot";
import CannotInfoBox from "../ui/CannotInfoBox";
import AdditionalCampaignOptions from "./AdditionalCampaignOptions";
import ExtraMailRules from "./ExtraMailRules";

const audienceTypeInput = { inputType: "radio_tile", name: "audience_type", props: {
    label: "Audience Type",
    options: [
      { value: "residential", label: "Single Family Homeowners", icon: MdRoofing },
      { value: "all_residential", label: "All Residents", icon: MdMapsHomeWork },
      { value: "commercial", label: "Businesses", icon: MdApartment },
    ],
  }};

const cannotCreateCampaignDisplay = (
  <CannotInfoBox
    icon={MdOutlineFilterAlt}
    body="Your subscription does not include B2B. Feel free to play around with the tool and get in touch with us if you’re interested in adding it on."
    classes="max-width-800 margin-4-t"
  />
);

const audienceText = {
  list_blitz: { header: "Now let’s target your ideal audience", subHeader: "Start by selecting your audience and defining the neighbors of the contacts on your list that you want to send to."},
  blitz: { header: "Target your ideal audience", subHeader: "Hyper-target your ideal customer using our interactive Blitz tool by selecting your audience and drawing on the map."}
}

const CampaignAudience = () => {
  const [campaign, campaignActions] = useCampaign();
  const [listGenerationSettings, listGenerationSettingsActions] = useCampaignListGenerationSettings();

  const { pageTabs } = useOutletContext();

  const handleAudienceTypeChange = (value) => {
    listGenerationSettingsActions.update({ audience_type: value.audience_type, data_axle_filters: {} });
  };

  return (
    <>
      <div className="page">
        {pageTabs}
        <div className="page-header">
          <h2 className="header-2 margin-8-b">{audienceText[listGenerationSettings.generation_type].header}</h2>
          <p className="body-1 color-text-secondary">{audienceText[listGenerationSettings.generation_type].subHeader}</p>
        </div>

        <div className="margin-bottom">
          <DopeFormGroup input={audienceTypeInput} onChange={handleAudienceTypeChange} values={listGenerationSettings} errors={listGenerationSettings.errors} />
          <Cannot action="create" subject="Campaign" data={campaign} cannotDisplay={{ display: cannotCreateCampaignDisplay }} />
        </div>

        <GeoFilterInput />

        <div className="margin-top">
          <AdditionalCampaignOptions
            additionalRulesApplied={listGenerationSettings.add_list_generation_setting_seed_lists || listGenerationSettings.add_list_generation_setting_suppression_lists}
            types={["blitz"]}
            settings={listGenerationSettings}
            panelBody={<ExtraMailRules />}
          />
        </div>
      </div>
    </>
  );
};

export default CampaignAudience;
