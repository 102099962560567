import { Toggle } from 'rsuite';

import './DopeCheckbox.scss';

const noMargin = { margin: 0 };

const CustomCheckbox = ({ checked, onChange, ...rest }) => {
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      {...rest}
    />
  );
};

const DopeCheckbox = ({ headerText, helpText, value, name, onChange, inline = false, useToggle = false, ...rest }) => {

  const BooleanComponent = useToggle ? Toggle : CustomCheckbox;

  const wrapperStyle = inline ? noMargin : {};

  return (
    <div className="dope-checkbox" style={wrapperStyle}>

      <div className="boolean-wrapper">
        <BooleanComponent
          checked={value}
          onChange={() => onChange({ [name]: !value }, !value, name)}
          {...rest}
        />
      </div>

      <div className="flex-1">
        {headerText && <div className="header-6 non-bold">{headerText}</div>}
        {helpText && <div className="help-text">{helpText}</div>}
      </div>

    </div>
  );
};

export default DopeCheckbox;
