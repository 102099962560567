import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  drawerTrackingNumberId: null,
  cancelTrackingNumberId: null,
}

export const trackingNumberUISlice = createSlice({
  name: "trackingNumberUI",
  initialState,
  reducers: {
    updateTrackingNumberUI: (trackingNumberUI, { payload: update }) => {
      Object.assign(trackingNumberUI, update);
    },
    setDrawerTrackingNumberId: (trackingNumberUI, { payload: id }) => {
      trackingNumberUI.drawerTrackingNumberId = id;
    },
    setCancelTrackingNumberId: (trackingNumberUI, { payload: id }) => {
      trackingNumberUI.cancelTrackingNumberId = id;
    },
  }
});

export default trackingNumberUISlice.reducer;

export const {
  updateTrackingNumberUI,
  setDrawerTrackingNumberId,
  setCancelTrackingNumberId,
} = trackingNumberUISlice.actions;

export const selectTrackingNumberUI = (state) => state.trackingNumberUI;

export function useTrackingNumberUI() {
  const dispatch = useDispatch();
  const trackingNumberUI = useSelector(selectTrackingNumberUI);
  const actions = {
    update: (payload) => dispatch(updateTrackingNumberUI(payload)),
    setDrawerTrackingNumberId: (id) => dispatch(setDrawerTrackingNumberId(id)),
    setCancelTrackingNumberId: (id) => dispatch(setCancelTrackingNumberId(id)),
  };

  return [trackingNumberUI, actions];
}
