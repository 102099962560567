import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import authApi from "../auth/authApi";
import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import DopeListPage from "../ui/DopeListPage";

import { columns } from "./AgencyClientsTable";

const clientsApi = new DopeApi('agency_clients');

const adviceStyle = {
  color: '#6c757d',
  textAlign: 'center',
  marginTop: '2rem',
};

const AgencyClientsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const accountId = authApi.getCurrentAccountId();

  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, scopes, setScopes, reload }
    = useQuery2({
      api: clientsApi,
      initialScopes: [{name: 'clients_for_agency', args: accountId}],
    });

  return (
    <div className="page">

      <div className="page-header">
        <p className="header-text">My Clients Dashboard</p>
      </div>

      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={[]}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        rowHeight={100}
      />

      <p style={adviceStyle}>Need to remove or add a client? Reach out to your Account Manager or <a href="mailto:support@dopemarketing.com" target="__blank">support@dopemarketing.com</a></p>


    </div>
  );
};

export default AgencyClientsPage;
