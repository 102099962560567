import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import DopeListPage from "../ui/DopeListPage";
import DopePill from "../ui/DopePill";
import { formatToLocaleDateString } from "../utils/date";
import Cents from "../ui/Cents";
import DopeFilters from "../ui/dopeFilters/DopeFilters";
import { FiDownload } from "react-icons/fi";
import { Placeholder } from "rsuite";

const columns = [
  { type: "stacked_text", dataKey: ['account_name', 'account_email'], label: "Name", flexGrow: 3, sortable: true, },

  { type: "timestamp", dataKey: 'start_date', label: "Start Date", flexGrow: 2, sortable: true, },
  { type: "custom", dataKey: 'end_date', label: "End Date", flexGrow: 2, sortable: true, render: (row) => {
    if (row.status === 'active') {
      return <DopePill
        text={row.status}
        dataKey={'status'}
        pillTypeKey={'status'}
      />;
    }

    return formatToLocaleDateString(row.end_date);
  }
  },
  { type: "text", dataKey: 'mail_count', label: "Mail Count", flexGrow: 2, sortable: true, placeholder: '0' },
  { type: "cents", dataKey: 'amount', label: "Kickback", flexGrow: 2, sortable: true, },
  { type: "cents", dataKey: 'owed', label: "Total Owed", flexGrow: 2, sortable: true, },
];

const accountsApi = new DopeApi('kickbacks');
const agencyApi = new DopeApi('agencies');

const filterInputs = [
  {
    title: "Account Name",
    type: "MultiSelect",
    name: "account_ids",
    field: "account_id",
    searchable: true,
    getOptions: (text = "") => agencyApi.getOptions({
      valueKey: 'id',
      labelKey: 'name',
      search: { text, column: 'name' },
    }),
  },
];


const PulseKickbacksTable = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, searchText, onSearchTextChange, setFilters, filters, scopes, setScopes, reload, exportCSV, exporting }
    = useQuery2({
      api: accountsApi,
    });

  const actionButtons = [
    {
      label: 'Export',
      icon: <FiDownload />,
      onClick: () => exportCSV('kickbacks'),
      isLoading: () => exporting,
      isDisabled: () => exporting,
    }
  ]

  const filterBar = (
    <DopeFilters
      filterInputs={filterInputs}
      filters={filters}
      setFilters={setFilters}
      onSearchTextChange={onSearchTextChange}
      searchText={searchText}
      actionButtons={actionButtons}
    />
  );

  return (
    <div className="page">

      <div className="page-header">
        <p className="header-text">Agency Kickbacks</p>
      </div>
      {filterBar}
      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filters={filters}
        setFilters={setFilters}
        rowHeight={100}
      />
    </div>
  );
};

export default PulseKickbacksTable;
