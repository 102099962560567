import React, { useEffect, useState } from "react";

import { FaImage } from "react-icons/fa6";


import { useDesign } from "./designSlice";
import { DopeFormGroup } from "../ui/DopeForm";
import DopePageStepHeader from "../ui/DopePageStepHeader";
import DopeDrawer from "../ui/DopeDrawer";
import DesignGuidelinesModal from "./DesignGuidelinesModal";
import DopeCheckbox from "../ui/DopeCheckbox";

import { sizeToRatio } from "../utils/design";
import DopeApi from '../services/DopeApi';
import DopeTabs from "../ui/DopeTabs";
import HandwryttenAPI from "./handwryttenAPI";
import './Design.scss';


import DopeDesignInput from "./DopeDesignInput";
import DopeActionButton from "../ui/DopeActionButton";

import HandwrittenCardDrawer from "./HandwrittenCardDrawer";
import HandwrittenCardItem from "./HandwrittenCardItem";
import HandwrittenFontDrawer from "./HandwrittenFontDrawer";
import HandwrittenFontItem from "./HandwrittenFontItem";
import HandwrittenCardBodyPreview from "./HandwrittenCardBodyPreview";
import DopeRadio from "../ui/DopeRadio";
import HandwrittenDesignDrawer from "./HandwrittenDesignDrawer";
import { FaTrash } from "react-icons/fa";

const api = new DopeApi('media_upload');

const DEFAULT_CHARACTER_COUNT = 500;
const SIGNATURE_CHARACTER_COUNT = 50;

const SIZE = '5x7';

const HandwrittenCardSetup = () => {
  const { design, actions } = useDesign();

  const [showDesignGuidelines, setShowDesignGuidelines] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_CHARACTER_COUNT);

  const [showCardSelection, setShowCardSelection] = useState(false);
  const [showFontSelection, setShowFontSelection] = useState(false);
  const [showDesignSelection, setShowDesignSelection] = useState(false);

  const [fonts, setFonts] = useState([]);

  const [addHeader, setAddHeader] = useState(false);
  const [addFooter, setAddFooter] = useState(false);

  const [source, setSource] = useState('upload');

  useEffect(() => {
    if (!addHeader) {
      actions.updateMailTemplate({ header_image: null });
    }

    if (!addFooter) {
      actions.updateMailTemplate({ footer_image: null });
    }
  }, [addHeader, addFooter]);

  useEffect(() => {
    if (source === 'designs' && !design.mail_template.cover_image) {
      setShowDesignSelection(true);
    }
  }, [source]);

  useEffect(() => {
    if (design.mail_template.header_image_url) {
      setAddHeader(true);
    }

    if (design.mail_template.footer_image_url) {
      setAddFooter(true);
    }
  }, [design.mail_template]);

  useEffect(() => {
    HandwryttenAPI.fonts()
      .then(response => response.data)
      .then(data => {
        setFonts(data.fonts);
        if (!design.mail_template.font) {
          actions.updateMailTemplate({ font: data.fonts[0] });
        }
      });
  }, []);

  const preview = (mail_template) => {

    if (mail_template.cover_image) {
      if (mail_template.card) {
        return <img src={design.mail_template.card.cover}  />;
      } else {
        return <img src={design.mail_template.cover_image}  />;
      }
    } else if (mail_template.cover_image_url) {
      return <img src={design.mail_template.cover_image_url}  />;
    }

    return (
      <div className="postcard-design-preview-placeholder">
        <FaImage size="2x" />
      </div>
    );
  };

  return (
    <div className="handwritten-card-form-wrapper">
      <div className="handwritten-card-design-form">
        <DopePageStepHeader
          step="1"
          headerText="Upload Designs"
          helpText={(<>Use our <a onClick={() => setShowDesignGuidelines(true)}>Design Guidelines</a> to make sure your design fits our specs.</>)}
        />
        <h6>Preview</h6>
        <div className="handwritten-card-cover-form">
          <div className="handwritten-card-design-preview" style={{ aspectRatio: sizeToRatio(SIZE) }}>
            {preview(design.mail_template)}
          </div>
          <DopeTabs
            defaultTab={design.mail_template.card_type || 'stock'}
            onChange={(type) => {
              actions.updateMailTemplate({ card: null, cover_image: null, card_type: type });
            }}
          >
            <DopeTabs.Tab name="stock" label="Stock Card">
              <div className="handwritten-card-stock">
                {design.mail_template.card && (<HandwrittenCardItem {...design.mail_template.card} showCover={false} /> )}
                <DopeActionButton
                  props={{primary: true}}
                  label={`${design.mail_template.card ? 'Change' : 'Select'} Card`}
                  onClick={() => setShowCardSelection(true)}
                />
              </div>
            </DopeTabs.Tab>
            <DopeTabs.Tab name="custom" label="Custom Card">
              <div className="handwritten-card-form-tab">
                <DopeRadio
                  name='source'
                  value={source}
                  options={[
                    { label: "Upload a logo or image", value: 'upload' },
                  ]}
                  onChange={s => setSource(s.source)}
                />
                {source === 'upload' && (
                  <DopeDesignInput
                    defaultPreview={design.mail_template.cover_image_url}
                    onChange={(srcPreview, preview, file) => {
                      let type = file?.type;

                      if (file?.type === 'application/pdf') {
                        type = 'image/png';
                      }

                      actions.updateMailTemplate({ cover_image: srcPreview, cover_image_type: type });
                    }}
                    size={SIZE}
                    showPreview={false}
                    allowCrop={false}
                  />
                )}

                <DopeRadio
                  name='source'
                  value={source}
                  options={[
                    { label: "Select from my approved designs", value: 'designs' },
                  ]}
                  onChange={s => setSource(s.source)}
                />

                {source === 'designs' && design.mail_template.cover_image && (
                  <div className="postcard-design-upload">
                    <div className="postcard-design-crop-controls">
                      <img src={design.mail_template.cover_image} style={{maxWidth: '100%' }}/>
                      <div className="postcard-design-crop-actions">
                        <button
                          onClick={() => {
                            actions.updateMailTemplate({ cover_image: null, cover_image_type: null });
                          }}
                        >
                          <FaTrash/> Remove
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {source === 'designs' && !design.mail_template.cover_image && (
                    <DopeActionButton
                      props={{primary: true}}
                      label={'Select Design'}
                      onClick={() => setShowDesignSelection(true)}
                    />
                )}
              </div>

            </DopeTabs.Tab>

          </DopeTabs>
        </div>

        <DopePageStepHeader
          step="2"
          headerText="Customize The Back"
        />
        <div className="handwritten-card-design-back">
          <div>
            <HandwrittenCardBodyPreview
              font={design.mail_template.font}
              body={design.mail_template.body}
              signature={design.mail_template.signature}
              limit={limit}
            />
            <small>In the final result, the size of your copy will adjust to maximize space on the card.</small>
          </div>
          <div>
            <label className="rs-form-control-label">Handwriting Style</label>
            <div className="handwritten-card-font">
              {design.mail_template.font && (<HandwrittenFontItem {...design.mail_template.font} /> )}
              <DopeActionButton
                props={{primary: true}}
                label={`${design.mail_template.font ? 'Change' : 'Select'} Font`}
                onClick={() => setShowFontSelection(true)}
              />
            </div>
            <div>
              <DopeFormGroup
                input={{
                  inputType: "text",
                  name: "body",
                  props: {
                    label: "Main Message",
                    as: "textarea",
                    rows: 12,
                  }
                }}
                values={design.mail_template}
                onChange={(value) => {
                  if (value.body.length + (value.signature || '').length > limit) {
                    return;
                  }

                  actions.updateMailTemplate(value);
                }}
              />
              <div className="input-footer">
                <div>
                  <p>Insert: <a onClick={() => {
                    actions.updateMailTemplate({ body: `${design.mail_template.body} {{firstname}}` });
                  }}>First Name</a> | <a onClick={() => {
                    actions.updateMailTemplate({ body: `${design.mail_template.body} {{lastname}}` });
                  }}>Last Name</a></p>
                </div>
                <small>{(design.mail_template.body || '').length + (design.mail_template.signature || '').length}/{limit} characters</small>
              </div>
            </div>
            <div>
              <DopeFormGroup
                input={{
                  inputType: "text",
                  name: "signature",
                  props: {
                    label: "Signature",
                    as: "textarea",
                    rows: 4,
                  }
                }}
                values={design.mail_template}
                onChange={(value) => {
                  if (value.signature.length + (value.body || '').length > limit) {
                    return;
                  }
                  actions.updateMailTemplate(value);
                }}
              />
            </div>
            <div className="input-footer">
              <div>

              </div>
              <small>{(design.mail_template.signature || '').length}/{SIGNATURE_CHARACTER_COUNT} characters</small>
            </div>
            {design.mail_template?.card_type === 'custom' && (
              <>
                <h6>Optional Design Choices</h6>
                <DopeCheckbox
                  headerText="Add a header image"
                  value={addHeader}
                  onChange={() => setAddHeader(!addHeader)}
                  useToggle={false}
                />
                {addHeader && (

                  <DopeDesignInput
                    defaultPreview={design.mail_template.header_image_url}
                    onChange={(srcPreview, preview, file) => {
                      let type = file?.type;

                      if (file?.type === 'application/pdf') {
                        type = 'image/png';
                      }

                      actions.updateMailTemplate({ header_image: srcPreview, header_image_type: type });
                    }}
                    showPreview={false}
                    allowCrop={false}
                  />
                )}
                <DopeCheckbox
                  headerText="Add a footer image"
                  value={addFooter}
                  onChange={() => setAddFooter(!addFooter)}
                  useToggle={false}
                />
                {addFooter && (

                  <DopeDesignInput
                    defaultPreview={design.mail_template.footer_image_url}
                    onChange={(srcPreview, preview, file) => {
                      let type = file?.type;

                      if (file?.type === 'application/pdf') {
                        type = 'image/png';
                      }

                      actions.updateMailTemplate({ footer_image: srcPreview, footer_image_type: type });
                    }}
                    showPreview={false}
                    allowCrop={false}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <DesignGuidelinesModal
        open={showDesignGuidelines}
        onClose={() => setShowDesignGuidelines(false)}
      />

      <DopeDrawer
        open={showCardSelection}
        onClose={() => setShowCardSelection(false)}
        bodyContent={
          <HandwrittenCardDrawer onSelect={async (card) => {
            const proxy = await api.get('proxy' , { url: card.cover });

            const { content, mime_type, url } = proxy;

            const base64 = `data:${mime_type};base64,${content}`;

            actions.updateMailTemplate({ card, cover_image: base64, cover_image_type: mime_type });

            setShowCardSelection(false);
          }} selected={design.mail_template.card}/>
        }
      />

      <DopeDrawer
        open={showFontSelection}
        onClose={() => setShowFontSelection(false)}
        bodyContent={
          <HandwrittenFontDrawer
            onSelect={(font) => {
              actions.updateMailTemplate({ font });
              setShowFontSelection(false);
            }}
            selected={design.mail_template.font}
            fonts={fonts}
            />
        }
      />

      <HandwrittenDesignDrawer
        open={showDesignSelection}
        onClose={() => setShowDesignSelection(false)}
        onSelect={async (design) => {
          const proxy = await api.get('proxy' , { url: design.mail_template.front_image_url });

          const { content, mime_type, url } = proxy;

          const base64 = `data:${mime_type};base64,${content}`;

          actions.updateMailTemplate({ cover_image: base64, cover_image_type: mime_type });

          setShowDesignSelection(false);
        }}
      />
    </div>
  );
};

export default HandwrittenCardSetup;
