import React from 'react';

import DopePill from '../ui/DopePill';
import DelayedJobProgress from '../ui/DelayedJobProgress';

const CampaignStatusCell = ({ rowData, reload }) => {
  const { delayed_job_id, status, effective_status } = rowData;
  const shouldBackgroundPull = !!delayed_job_id && ['queued', 'generating'].includes(status);

  if (shouldBackgroundPull) {
    return (
      <DelayedJobProgress
        id={delayed_job_id}
        onSuccess={reload}
        completeStatus="scheduled"
        runningStatus="generating"
      />
    );
  }

  return <DopePill text={effective_status} />;
};

export default CampaignStatusCell;
