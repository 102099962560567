import React, {useEffect} from 'react';

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import DopeListPage from "../ui/DopeListPage";

const creditPlanApi = new DopeApi("credit_plan");

const columns = [
  { type: 'text', dataKey: 'name', label: 'Name', sortable: true },
  { type: 'text', dataKey: 'total_accounts', label: '# of Accounts', sortable: true },
  { type: 'timestamp', dataKey: 'created_at', label: 'Created Date', sortable: true },
  { type: 'text', dataKey: 'subscription_level', label: 'Subscription', sortable: true },
  { type: 'pill', dataKey: 'status', pillTypeKey: 'status', label: 'Status' },
  { type: 'button', dataKey: 'Edit', label: 'Edit', sortable: false},
];

const CreditPlansTable = ({ setCreditPlanId, modalOpen }) => {
  const { tableProps, paginationProps, onSearchClear, onSearchInput, setFilters, filters, reload }
    = useQuery2({
      api: creditPlanApi,
      initialSearchScope: 'name'
    });

  useEffect(() => {
    if (!modalOpen) {
      reload();
    }
  }, [modalOpen]);

  return (
    <div>
      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
        setFilters={setFilters}
        filters={filters}
        onClickEdit={(row) => setCreditPlanId(row.id)}
      />
    </div>
  );
}

export default CreditPlansTable;
