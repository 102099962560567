import React, { forwardRef, useEffect, useMemo, useState, useRef } from'react';
import { observer } from 'mobx-react-lite';
// import { FadeLoader } from 'react-spinners';
import { useNavigate, useLocation } from 'react-router-dom';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { DEFAULT_SECTIONS as POLOTNO_DEFAULT_SECTIONS } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel } from 'polotno/side-panel';
import { DopeFormGroup } from "../ui/DopeForm";
import { useDesign } from './../designs/designSlice';
import { objectURLToFile, sizeToRatio } from "../utils/design";

// import { ReactComponent as ScissorsIcon  } from 'assets/img/icons/scissors.svg'

import { Workspace } from 'polotno/canvas/workspace';

// import css styles from blueprint framework (used by polotno)
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import TemplatesSidePanel from './TemplatesSidePanel';
import PhotosSidePanel from './PhotosSidePanel';
import TextSidePanel from './TextSidePanel';
import QRCodesSidePanel from './QRCodesSidePanel';

import ScissorsIcon from '../icons/ScissorsIcon';
import ShapesSidePanel from './ShapesSidePanel';
import DopeButton from '../ui/DopeButton';
import PostcardApprovalDrawer from '../designs/PostcardApprovalDrawer';
import { Loader } from 'rsuite';
import DopeLoader from '../ui/DopeLoader';

const LayersDefSection = POLOTNO_DEFAULT_SECTIONS.find(
  (section) => section.name === 'layers'
);

const PageControls = observer(({ store }) => {
  const { page, xPadding, yPadding } = store;

  const side = page.custom?.side;

  const pageLabel = {
    'front': 'FRONT',
    'back': 'BACK',
  }

  return (
    <div
      className='page-label'
      style={{
        left: `${xPadding}px`,
        top: `${Math.abs(yPadding - 35)}px`
      }}
    >
      <h2 className='title'>{pageLabel[side] || '' }</h2>
    </div>
  );
});

// it is important to define component onside of `MyToolbar` render function
const ActionControls = ({ store, actions }) => {
  const [toggle, setToggle] = useState(true);

  const toggleBleed = () => {
    setToggle(!toggle);

    store.toggleBleed(!toggle);
  }

  return (
    <div>
      <button onClick={toggleBleed} className={`bleed-button ${!toggle ? 'active' : ''}`}>
        <ScissorsIcon />
      </button>
    </div>
  );
};

export const DEFAULT_SECTIONS = [
  TemplatesSidePanel,
  PhotosSidePanel,
  ShapesSidePanel,
  TextSidePanel,
  QRCodesSidePanel,
  // TrackingNumberPanel,
];

const nameInput = {
  inputType: "text",
  name: "name",
  props: { label: "Design Name" },
};

const Editor =  observer(forwardRef(({ store, initialize }, ref) => {
  const navigate = useNavigate();
  const { design, actions } = useDesign();
  const location = useLocation();
  const prevDesignIdRef = useRef(design.id);

  const params = new URLSearchParams(location.search);

  const [showApproval, setShowApproval] = useState(false);
  const [loading, setLoading] = useState(false);
  const [front, setFront] = useState(design.mail_template?.front_image_url);
  const [back, setBack] = useState(design.mail_template?.back_image_url);



  const size = params.get('size') || design.mail_template.size;

  const activePageElements = store.activePage?.children;

  const designInputProps = { values: design, onChange: actions.update, errors: design.errors };
  const activeElement = useMemo(() => {
    if ((store.selectedElements?.length ?? 0) === 1 && store.selectedElements[0].type === 'image') {
      return store.selectedElements[0];
    }

    return null;
  }, [store.selectedElements.length]);

  useEffect(() => {
    actions.update({ mail_template_type: 'PostcardTemplate', size, mail_template_format: 'json' });
    initialize(size);

    return () => {
      store.deletePages(store.pages.map(({id}) => id));
    }
  }, [size]);

  const activeLayers = (activePageElements?.length ?? 0) > 0;

  const isActiveBleed = true;

  useEffect(() => {
    const prevDesignId = prevDesignIdRef.current;

    if (design.id !== 'new' && prevDesignId === 'new') {
      navigate(`/designs/${design.id}`);
    }

    if (typeof design.mail_template.json_template === 'object' && design.mail_template.json_template !== null) {
      store.loadJSON(design.mail_template.json_template);
    }

    prevDesignIdRef.current = design.id;
  }, [design.id]);


  const handleUpload = async (status, showApproval = true) => {
    setLoading(true);
    const now = Date.now();

    const isNew = design.id === 'new';

    const front = await store.toDataURL({
      pageId: store.pages[0].id,
      dpi: 300,
      pixelRatio: 2,
    });

    const back = await store.toDataURL({
      pageId: store.pages[1].id,
      dpi: 300,
      pixelRatio: 2
    });

    if (showApproval) {
      setLoading(false);
      setFront(front);
      setBack(back);
      setShowApproval(true);
      return;
    }

    const template = store.toJSON();

    const [frontImage, backImage] = await Promise.all([
      objectURLToFile(front, `front_${now}.png`),
      objectURLToFile(back, `back_${now}.png`),
    ]);

    const data = {
      files: {
        front_image: frontImage,
        back_image: backImage,
      },
      json_template: JSON.stringify(template),
      status,
    };

    await actions.save(data);

    setLoading(false);

    if (!isNew) {
      navigate(`/designs/${design.id}`);
    }
  }

  return (
    <div className='Editor'>
      <PolotnoContainer className={`polotno-container${activeLayers ? ' polotno-container--layers-active' : ''}`}>
        <SidePanelWrap className="app__sidebar">
          <SidePanel store={store} sections={DEFAULT_SECTIONS} defaultSection='templates' />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar store={store} components={{ ActionControls: isActiveBleed ? ActionControls : null }} />
          {/* { activeElement && <ImageLoadPopover store={store} /> } */}
          {store.pages.length > 0 && (
            <Workspace
              store={store}
              backgroundColor='#D9D9D9'
              components={{
                PageControls: (store) => <>
                  <PageControls store={store} />
                </>
              }}
            />
          )}
          {store.pages.length === 0 && (
            <div className='empty-page'>
              <p>No pages loaded yet</p>
            </div>
          )}
          <ZoomButtons store={store} />
        </WorkspaceWrap>
        {activeLayers && (
          <div className='right-side-bar app__sidebar'>
            <div className='custom-layers'>
              <div className='file-controls'>
                <DopeFormGroup input={nameInput} {...designInputProps} />

                <div className='file-controls-buttons'>
                  <DopeButton
                    disabled={loading || !design.name}
                    loading={loading}
                    props={{
                      label: 'Approve',
                      buttonClass: 'filled',
                      onClick: async () => {
                        await handleUpload('approved');
                      }
                    }}
                    />

                  <DopeButton
                    disabled={loading || !design.name}
                    loading={loading}
                    props={{
                      label: 'Save as Draft',
                      buttonClass: 'outlined-red',

                      onClick: async () => {
                        await handleUpload('draft', false);
                      }
                    }}
                    />
                </div>

              </div>
              <div>
                <h2 className='title'>Layers</h2>
                <p>You can rearrange the elements on your design.</p>
              </div>
              <div className='layers'>
                <LayersDefSection.Panel store={store} />
              </div>
            </div>
          </div>
        )}
      </PolotnoContainer>
      <PostcardApprovalDrawer
        open={showApproval}
        onConfirm={() => handleUpload('approved', false)}
        onCancel={() => setShowApproval(false)}
        loading={loading}
        design={design}
        actions={actions}
        front={front}
        back={back}
        aspectRatio={sizeToRatio(size)}
      />


    </div>
  );
}));

export default Editor;
