import { readPsd } from 'ag-psd';
import { createStore } from 'polotno/model/store';
import { sizeToPixels } from './design';

// TODO: A good part of these functions were just carried over and need to be
// refactored to better cast numeric values.
const shadow = data => {
  if (!data) {
    return { shadowEnabled: false };
  }

  const { angle, distance, size, color, opacity } = data;

  return {
    shadowEnabled: true,
    shadowBlur: +size.value,
    shadowColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
    shadowOpacity: +opacity,
    shadowOffsetX: +(distance.value * Math.cos((angle * Math.PI) / 180)).toFixed(),
    shadowOffsetY: +(distance.value * Math.sin((angle * Math.PI) / 180)).toFixed(),
  };
};

const stroke = (data, transform) => {
  if (!data) {
    return {};
  }

  const { size, color, opacity } = data;

  return {
    strokeWidth: +(size.value * ((transform[0] + transform[3]) / 2)).toFixed(),
    stroke: `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`,
  };
};

const size = data => {
  const { canvas, left, top, text } = data;

  return {
    width: text ? canvas.width * 1.1 : canvas.width,
    height: text ? canvas.height * 1.1 : canvas.height,
    x: left,
    y: top,
    rotation: text?.transform?.length ? Math.atan2(text.transform[1], text.transform[0]) * (180 / Math.PI) : 0
  };
};

const text = (data, effects) => {
  const { text, style, paragraphStyle, transform } = data;

  return {
    text: !!style.fontCaps ? text.toUpperCase() : text,
    fontFamily: style?.font?.name || 'Arial',
    // fontSize: style?.fontSize ? style.fontSize * ((transform[0] + transform[3]) / 2) : 20 * ((transform[0] + transform[3]) / 2),
    fontSize: Math.sqrt(transform[0] * transform[3] - transform[1] * transform[2]) * style?.fontSize || 0,
    // align: paragraphStyle.justification,
    align: 'left',
    fill: effects?.solidFill?.length && effects?.solidFill[0]?.enabled
      ? `rgba(${effects.solidFill[0].color.r}, ${effects.solidFill[0].color.g}, ${effects.solidFill[0].color.b}, ${effects.solidFill[0].opacity})`
      : style?.fillColor
        ? `rgba(${style.fillColor.r}, ${style.fillColor.g}, ${style.fillColor.b}, ${style.fillColor.a})`
        : 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
  };
};

const parseLayers = layers => {
  try {
    const visible = layers.filter(item => !item.hidden);

    const result = [];

    for (const layer of visible) {
      if ('children' in layer) {
        result.push(parseLayers(layer.children));
      } else if ('canvas' in layer) {
        if ('text' in layer) {
          result.push({
            type: 'text',
            ...size(layer),
            ...text(layer?.text, layer?.effects),
            ...shadow(layer?.effects?.dropShadow[0]),
            ...stroke(layer?.effects?.stroke[0], layer?.text?.transform),
            fullInfo: layer,
          });
        } else {
          result.push({
            type: 'image',
            ...size(layer),
            ...shadow(layer?.effects?.dropShadow[0]),
            src: layer?.canvas?.toDataURL(),
            opacity: layer?.opacity,
            fullInfo: layer
          });
        }
      }
    }

    return result;
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const parsePSD = async (file, upload, size = '6x9') => {
  const buffer = await file.arrayBuffer();

  const psd = readPsd(buffer);

  const { width, height } = sizeToPixels(size);

  const store = createStore({ apiKey: process.env.REACT_APP_POLOTNO_API_KEY });

  store.setSize(width, height);

  const page = store.addPage();

  page.set({ width: width, height: height });

  const { children } = psd;

  let elements = [];

  if (children && children.length > 0) {
    elements = await parseLayers(children);
  } else {
    elements = await parseLayers([
      {
        canvas: psd.canvas,
        name: 'Background',
        opacity: 1,
        x: 0,
        y: 0
      }
    ]);
  }

  elements = elements.flat(Infinity);

  for (const element of elements) {
    if (element?.src) {
      const url = await upload(element.src);

      if (typeof url !== 'string') {
        throw new Error('Failed to upload image from PSD file. Try again.');
      }

      page.addElement({ ...element, src: url });
    } else {
      page.addElement(element);
    }
  }

  return page.toJSON();
};

