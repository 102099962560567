import React from "react";
import {Table} from "rsuite";
import {unixTimestampToDateStr} from "../utils/date";
const { Column, HeaderCell, Cell } = Table;


const InvoiceSubscriptionTable = ({ subscription }) => {

  return (
    <div style={{ marginBottom: "30px"}}>
      <h5>Subscriptions</h5>
      <Table
        data={[subscription]}
        rowStyle={{ borderBottom: 'none'}}
        bordered
        autoHeight={true}
        style={{ borderRadius: "8px" }}
      >
        <Column align="left" flexGrow={1}>
          <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Name</HeaderCell>
          <Cell dataKey="plan.nickname" style={{color: "#6F6F6F", fontSize: "12px"}}/>
        </Column>
        <Column align="left" flexGrow={1}>
          <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Charged</HeaderCell>
          <Cell dataKey="price.recurring.interval" style={{color: "#6F6F6F", fontSize: "12px"}}>
            {rowData => (
              <div>{rowData.price.recurring.interval}ly</div>
            )}
          </Cell>
        </Column>
        <Column align="left" flexGrow={1}>
          <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Period Ends</HeaderCell>
          <Cell dataKey="current_period_end" style={{color: "#6F6F6F", fontSize: "12px"}}>
            {rowData => (
              <div>{unixTimestampToDateStr(rowData.period.end)}</div>
            )}
          </Cell>
        </Column>
        <Column align="left" flexGrow={1}>
          <HeaderCell style={{ fontWeight: 700, fontSize: "11px", color: "#8793A6" }}>Amount</HeaderCell>
          <Cell dataKey="plan.amount" style={{color: "#6F6F6F", fontSize: "12px"}}>
            {rowData => (
              <div>${(rowData.plan.amount / 100).toFixed(2)}</div>
            )}
          </Cell>
        </Column>
      </Table>
    </div>
  );
}

export default InvoiceSubscriptionTable;