import React, { useEffect, useState } from 'react';
import ModelForm from "../ui/ModelForm";

const initialUser = {
  first_name: '',
  last_name: '',
  email: '',
  role: 'user',
  password: '',
  password_confirmation: '',
};

const userInputs = [
  {
    inputType: 'text',
    name: 'first_name',
    props: {
      label: 'First Name',
      type: 'text',
    }
  },
  {
    inputType: 'text',
    name: 'last_name',
    props: {
      label: 'Last Name',
      type: 'text',
    }
  },
  {
    inputType: 'text',
    name: 'email',
    props: {
      type: 'text',
      label: 'Email',
      readOnly: true,
      disabled: true,
    }
  },
  {
    inputType: 'text',
    name: 'phone_number',
    props: {
      label: 'Phone Number',
      type: 'text',
    }
  },
];

const UserForm = (props) => {
  return (
    <ModelForm
      modelName="user"
      initialResource={initialUser}
      inputs={userInputs}
      {...props}
    />
  );
};

export default UserForm;
