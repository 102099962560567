import React, { useState, useEffect } from "react";

import HandwryttenAPI from "./handwryttenAPI";

import DopeTabs from "../ui/DopeTabs";
import HandwrittenCardItem from "./HandwrittenCardItem";

const CATEGORIES = [
  // { name: 'christmas', label: 'Christmas', id: 999 },
  { name: 'thank-you', label: 'Thank You', id: 6 },
  { name: 'holidays', label: 'Holidays', id: 12 },
  { name: 'birthday', label: 'Birthday', id: 7 },
  { name: 'all', label: 'All', id: -1 },
];

const HandwrittenCardDrawer = ({ onSelect, selected }) => {
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(CATEGORIES[0]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const isAll = selectedCategory.name === 'all';
    setLoading(true);

    HandwryttenAPI.cards(isAll ? null : selectedCategory.id)
      .then(response => response.data)
      .then(data => {
        // The first two cards on the all category are blank cards so we don't
        // want to show them.
        if (selectedCategory.name === 'all') {
          setCards(data.cards.slice(2, data.cards.length));
        } else {
          setCards(data.cards);
        }

      })
      .finally(() => setLoading(false));
  }, [selectedCategory]);

  return (
    <DopeTabs onChange={(name) => setSelectedCategory(CATEGORIES.find(c => c.name === name))}>
      {CATEGORIES.map(c => (
        <DopeTabs.Tab name={c.name} label={c.label}>
          <div className="handwritten-stock-cards">
            {cards.map(card => <HandwrittenCardItem key={card.id} {...card} onClick={() => onSelect(card)} selected={card.id === selected?.id} />)}
          </div>
        </DopeTabs.Tab>
      ))}
    </DopeTabs>
  );
};

export default HandwrittenCardDrawer;
