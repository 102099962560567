import React, { useState } from 'react';

import authApi from '../../auth/authApi';
import { useIntegrations } from "../integrationsSlice";
import DataTypeTable from '../DataSources/DataTypeTable';
import DopeButton from '../../ui/DopeButton';
import { validateRules } from '../util/validate';
import { Drawer } from "rsuite";

const SubscriptionQuery = ({ query = false, header = null, disabled = false }) => {
  const { subscription, connections, queryConnectionData, connectionData, loadingConnectionData } = useIntegrations();
  const connection = connections.find(c => c.data_source.name === subscription.data_source);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSort] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('desc');

  if (!authApi.currentUserHasAdminPrivileges()) return null;

  const canQuery = !!connection && !!subscription.data_type && !!subscription.data_source && validateRules(subscription.conditions.rules);

  const queryData = (page, perPage, sortColumn, sortDirection) => {
    setPage(page);
    setPerPage(perPage);
    setSort(sortColumn);
    setSortDirection(sortDirection);

    queryConnectionData({
      connectionId: connection.id,
      params: {
        ...subscription,
        page,
        perPage,
        sortColumn,
        sortDirection,
      },
    });
  };

  const paginationProps = {
    total: connectionData?.total,
    limit: perPage,
    activePage: page,
    onChangePage: (page) => {
      queryData(page, perPage, sortColumn, sortDirection);
    },
    onChangeLimit: (perPage) => {
      queryData(1, perPage, sortColumn, sortDirection);
    },
  };

  const tableProps = {
    data: connectionData ? connectionData.data : [],
    sortColumn,
    sortType: sortDirection,
    loading: loadingConnectionData,
    onSortColumn: (col, dir) => {
      queryData(1, perPage, col, dir);
    },
  };

  const handleOpen = () => {
    setOpen(true);
    queryData(1, perPage, sortColumn, sortDirection);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {header}

      <div className="to-right margin-header">
        <div>
          <div><DopeButton onClick={handleOpen} className="outlined-black full-width" disabled={!canQuery || disabled}>See sample results</DopeButton></div>
          <br />
          <p key="help" className="help-text">Note: Records without address 1, city, state, or zip will be excluded from final results</p>
        </div>
      </div>

      <Drawer open={open} onClose={handleClose} size="450px" placement="bottom" closeButton={false}>
        <DataTypeTable
          tableProps={tableProps}
          paginationProps={paginationProps}
        />
      </Drawer>
    </div>
  );
};

export default SubscriptionQuery;
