import React from "react";
import { useNavigate } from "react-router-dom";
import {humanize} from "../utils/railsNames";
import {formatToLocaleDateString} from "../utils/date";
import HandwrittenCardBodyPreview from "./HandwrittenCardBodyPreview";
import DopeLogo from "../icons/DopeLogo";

const DesignPreview = ({ design }) => {
  if (design.mail_template_type === 'PostcardTemplate') {
    return (
      <>
        <div style={{marginRight: "4px", width: "49%"}}>
          <img src={design.mail_template.front_image_thumbnail_url} alt={`${design.name}-front`} />
        </div>
        <div style={{ width: "49%"}}>
          <img src={design.mail_template.back_image_thumbnail_url} alt={`${design.name}-back`} />
        </div>
      </>
    );
  }

  if (design.mail_template_type === 'HandwrittenCardTemplate') {
    return (
      <>
        <div style={{marginRight: "4px", width: "49%"}}>
          <img src={design.mail_template.front_image_thumbnail_url} alt={`${design.name}-front`} />
        </div>
        <div style={{ width: "49%", background: 'white', aspectRatio: "145/102", overflow: 'hidden' }}>
          <HandwrittenCardBodyPreview
            font={design.mail_template.font}
            body={design.mail_template.body}
            signature={design.mail_template.signature}
            limit={design.mail_template?.card?.characters || 500}
          />
        </div>
      </>
    );
  }

  if (design.mail_template_type === 'GoodieTemplate') {
    return (
      <>
        <div style={{marginRight: "4px", width: "49%"}}>
          <img src={design.mail_template.front_image_thumbnail_url} alt={`${design.name}-front`}/>
        </div>
        <div style={{width: "49%"}}>
          <DopeLogo />
        </div>
      </>
    );

  }

  if (design.mail_template_type === 'DesignTemplate') {
    return (
      <>
        <div style={{marginRight: "4px", width: "49%"}}>
          <img src={design.mail_template.front_image_thumbnail_url} alt={`${design.name}-front`} />
        </div>
        {design.mail_template.back_image_thumbnail_url && (
          <div style={{ width: "49%"}}>
            <img src={design.mail_template.back_image_thumbnail_url} alt={`${design.name}-back`} />
          </div>
        )}
      </>
    );
  }
};

const designStatusConverter = (status) => {
  switch (status) {
    case 'approved':
      return 'Approved';
    case 'needs_review':
      return 'Version uploaded';
    case 'in_design':
    case 'wip':
      return 'Revisions submitted';
    case 'draft':
      return 'Created';
    case 'archived':
      return 'Archived';
    default:
      return '';
  }
}

const DesignGridItem = ({ design, selectedStatus }) => {
  const navigate = useNavigate();

  const isHotLead = design.mail_template.mailer_type === 'hot_lead';

  let productName = design ? humanize(design.mail_template.product_name) : '';

  if (design?.mail_template_type === 'DesignTemplate') {
    if (design?.mail_template.template_type === 'HandwrittenCardTemplate') {
      productName = 'HWC - Front';
    }
  }

  return (
    <div className="grid-item" onClick={() => navigate(`/designs/${design.id}`)} key={design.id}>
      <div className="to-center full-width border-radius-8" style={{ padding: "8px", background: "#566E801A" }}>
        <DesignPreview design={design} />
      </div>
      <div style={{ marginTop: "4px"}}>
        { selectedStatus === "needs_review" && <div className="badge text-upper">Version #{design.design_versions_count}</div>}
        <div style={{fontSize: "16px", fontWeight: 600, color: "#000000"}}>
          {design.name}
        </div>
        <div style={{display: "flex", flexDirection: "row", color: "#6F6F6F", alignItems: "center"}}>
          <div style={{ fontSize: "14px", fontWeight: 600, marginRight: "4px" }}>
            {isHotLead ? 'Hot Lead' : design.mail_template.size} {design.mail_template.is_eddm ? 'Eddm' : productName} <span>&#183;</span>
          </div>
          <div style={{fontSize: "11px", fontWeight: 400}}>
            {designStatusConverter(design.status)} { design.status === 'approved' ? formatToLocaleDateString(design.approved_at) : formatToLocaleDateString(design.updated_at)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesignGridItem;
