import React from "react";

import { Loader } from 'rsuite';

const wrapperStyles = {
  minHeight: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
};

const DopeLoader = ({ style = {}, ...rest }) => {
  return (
    <div style={{ ...wrapperStyles, ...style }}>
      <Loader size="md" {...rest} />
    </div>
  );
};

export default DopeLoader;
