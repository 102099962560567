import React, { useState }from "react";
import { useLocation } from "react-router-dom";
import { Form, Grid } from "rsuite";

import { useContact } from "./contactSlice";
import { useDopeUI } from "../ui/dopeUISlice";

import DopeErrorMessages from "../ui/DopeErrorMessages";
import ContactFormInputs from "./ContactFormInputs";
import DopePlaceholder from "../ui/DopePlaceholder";
import DopeButton from "../ui/DopeButton";
import DopeDrawer from "../ui/DopeDrawer";

const ContactDrawerForm = ({ id, open, header, onClose }) => {
  const [saveClicked, setSaveClicked] = useState(false);
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [contact, actions, errors, loading] = useContact(id);

  const location = useLocation();

  // TODO: add edit message
  const successMessage = () => {
    dopeUIActions.addFlashMessage({header: `${contact.first_name} ${contact.last_name}`, message: 'was successfully added to your contacts!', type: 'success' });
  }

  const handleSubmit = async () => {
    setSaveClicked(true);
    if ((!contact.first_name || !contact.last_name) && (!contact.company_name) || !contact.address_1 || !contact.zip ) {
      return dopeUIActions.addFlashMessage({
        header: 'Please fill out all required fields',
        body: 'Contacts must have a first name, last name, address 1, and zip' ,
        type: 'error'
      });
    }

    try {
      await actions.save();
      successMessage();
      onClose();
      location.state = { refresh: true };
    } catch (error) {
      console.log(error);
    }
  };

  const content = () => {
    if (!contact) {
      return <DopePlaceholder type={'image'} rows={5} />
    } else if (loading && contact) {
      return <DopePlaceholder type={'image'} rows={5} title={"Creating Contact..."}/>
    }

    return (
      <Form onSubmit={handleSubmit} fluid disabled={loading} className="flex column space-between margin-top">
        <div className="margin-80-b">
          <Grid fluid>
            <ContactFormInputs errors={errors} attemptedToSave={contact.id !== "new" ? true : saveClicked} />
          </Grid>
        </div>

        <DopeErrorMessages errorMessages={errors?.base} />
      </Form>
    );
  };

  // TODO: update to dopeforminput to include errors / warnings
  return (
    <DopeDrawer
      className="action-drawer"
      open={open}
      onClose={onClose}
      header={header}
      bodyContent={content()}
      actions={(
        <>
          <DopeButton
              props={{
                onClick: onClose,
                buttonClass: "outlined-black action-button",
                label: "Cancel",
              }}
            />

            <DopeButton props={{ label: "Save", onClick: handleSubmit, buttonClass: "filled-black action-button" }} />
        </>
      )}
    />
  );
}

export default ContactDrawerForm;
