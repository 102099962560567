import React, {useEffect, useState} from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";
import DopeApi from "../services/DopeApi";
import { Form, SelectPicker } from "rsuite";

const initialListGenerationSetting = {
  account_id: null,
  data_axle_filters: '',
};

const listGenerationSettingInputs = [
  {
    inputType: 'belongsTo',
    name: 'account_id',
    props: {
      label: 'Account',
      labelKey: 'name',
      belongsToModelName: 'account',
    }
  }
];

const dataAxleInputs = (input) => {
  return {
    inputType: input.data_type,
    name: 'data_axle_filters',
    customInput: 'DataAxleFilterInputs',
    props: {
      label: input.display_name,
      labelKey: input.filter_attribute,
      data_type: input.data_type,
      filter: input,
    }
  }
}

const ListsGenerationSettingForm = (props) => {
  const [filterTarget, setFilterTarget] = useState("people");
  const [scopes, setScopes] = useState([{name: "active"}, {name: "people"}]);
  const [filterInputs, setFilterInputs] = useState([]);

  const api = new DopeApi("data_axle_filters");

  const fetchFilters = (value) => {
    let queryScopes = value ? [{name: "active"}, {name: value}] : scopes;
    api.query({ scopes: queryScopes })
    .then(data => {
      handleGet(data);
    })
    .catch(error => console.log(error));
  }

  const audienceTypes = [{ target: "people", label: "Single Family Residential"}, { target: "places", label: "Commercial - Occupants"}].map(
    item => ({ label: item.label, value: item.target })
  );

  const handleGet = (response) => {
    let filtersForForm = listGenerationSettingInputs.concat(response.data_axle_filters.map(input => dataAxleInputs(input)));
    setFilterInputs(filtersForForm);
  }
  const handleAudienceChange = (value) => {
    setFilterTarget(value)
    setScopes([{name: "active"}, {name: filterTarget}]);
    fetchFilters(value);
  }

  const audiencePicker = (
    <Form.Group>
      <Form.ControlLabel>Audience Type</Form.ControlLabel>
      <SelectPicker
        data={audienceTypes}
        searchable={false}
        style={{ width: 224 }}
        onChange={handleAudienceChange}
        placeholder={audienceTypes[0].label}
      />
    </Form.Group>
  );

  useEffect(() => {
    fetchFilters();
  }, []);

  return (
      <ModelForm
        modelName="list_generation_setting"
        initialResource={initialListGenerationSetting}
        inputs={filterInputs}
        formTypeSwitcher={audiencePicker}
        {...props}
      />
  );
};

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, filterable: true },
  { type: 'text', dataKey: 'account_name', label: 'Account' },
  { type: 'text', dataKey: 'data_axle_filters_for_list', label: 'Filters' },
  { type: 'text', dataKey: 'account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
];

const scopeOptions = [];

function ListGenerationSettingManagement() {
  return (
    <DopeManagement
      modelName="list_generation_setting"
      modelForm={ListsGenerationSettingForm}
      columns={columns}
      scopeOptions={scopeOptions}
    />
  );
}

export default ListGenerationSettingManagement;
