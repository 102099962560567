import DopeSelect from './DopeSelect';
import { isObject } from 'lodash';
import { humanize } from '../utils/railsNames';

const DopeSimpleSelect = ({ options, ...rest }) => {
  return (
    <DopeSelect
      searchable={false}
      options={options.map(option => (isObject(option) ? option : { label: humanize(option), value: option }))}
      {...rest}
    />
  );
};

export default DopeSimpleSelect;
