import { useEffect } from "react";

import { useDesign } from "./designSlice";
import { DopeFormGroup } from "../ui/DopeForm";
import DopeStateSelector from "../ui/DopeStateSelector";
import Stamp from "../images/stamp.png";

import './Design.scss';

const SENDER_NAME_LIMIT = 30;

const senderNameInput = { inputType: "text", name: "sender_name", props: { label: "Sender Name", maxLength: SENDER_NAME_LIMIT } };

const address1Input = { inputType: "text", name: "address_1", props: { label: "Address 1" } };
const address2Input = { inputType: "text", name: "address_2", props: { label: "Address 2 (optional)" } };
const cityInput = { inputType: "text", name: "city", props: { label: "City" } };

const zipInput = { inputType: "text", name: "zip", props: { label: "Zip Code" } };




const HandwrittenCardExtras = () => {
  const { design, actions } = useDesign();

  const inputProps = { values: design.mail_template, onChange: actions.updateMailTemplate, errors: design.errors };

  useEffect(() => {
    if ((design?.mail_template?.sender_name ?? '').length > 30) {
      actions.updateMailTemplate({ sender_name: design.mail_template.sender_name.slice(0, 30) });
    }
  }, []);

  return (
    <div className="handwritten-card-form-wrapper">
      <div className="page-header">
        <p className="header-text">Set the return address</p>
      </div>

      <div className="handwritten-card-extras-form">
        <div className="handwritten-card-text-preview">
          <div style={{ fontFamily: design.mail_template.font?.id, fontSize: `2rem`, lineHeight: design.mail_template.font?.line_spacing || 'initial' }} className="handwritten-card-text-preview-address">
            <div>
              <p>{design.mail_template.sender_name || ''}</p>
              <p>{design.mail_template.address_1 || ''} {design.mail_template.address_2 || ''}</p>
              <p>{design.mail_template.city || ''}{design.mail_template.city && design.mail_template.state && ','} {design.mail_template.state || ''} {design.mail_template.zip || ''}</p>
            </div>
            <div>
              <img src={Stamp} />
            </div>
          </div>
        </div>
        <div className="rs-form ">
          <DopeFormGroup input={senderNameInput} {...inputProps}  className="handwritten-card-sender-name" />
          <small className="character-count">{(design.mail_template.sender_name || '').length}/{SENDER_NAME_LIMIT} characters</small>
          <DopeFormGroup input={address1Input} {...inputProps} />
          <DopeFormGroup input={address2Input} {...inputProps} />
          <DopeFormGroup input={cityInput} {...inputProps} />

          <div className="rs-form-group">
            <label className="rs-form-control-label">State</label>
            <DopeStateSelector
              name="state"
              value={design.mail_template.state}
              onChange={value => actions.updateMailTemplate({ state: value })}
              error={design.errors?.state}
            />
          </div>

          <DopeFormGroup input={zipInput} {...inputProps} />
        </div>
      </div>
    </div>
  );
};

export default HandwrittenCardExtras;
