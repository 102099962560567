import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import DopeSelect from "../ui/DopeSelect";


// import existing section, import default tab component
import { SectionTab } from 'polotno/side-panel';
import ImQrcode from '@meronex/icons/im/ImQrcode';

import CustomQRCodesPanel from './CustomQRCodesPanel';

const OPTIONS = [
  {
    value: 'active',
    label: 'Active Codes',
  },
  {
    value: 'archived',
    label: 'Archived',
  }
];

const QRCodesSidePanel = {
  name: 'qr_codes',
  Tab: (props) => (
    <SectionTab name="QR Codes" {...props} fontSize='30px'>
      <ImQrcode icon="grid" width='20px' height='20px' />
    </SectionTab>
  ),
  Panel: observer((props) => {
    const DEFAULT = OPTIONS[0];

    const [tab, setTab] = useState(DEFAULT.value);

    return (
      <div className='qr-codes'>
        <div className='header'>
          <h2 className='title'>QR Codes</h2>
        </div>

        <div className='select-wrapper'>
          <DopeSelect
            name='tab'
            value={tab}
            options={OPTIONS}
            defaultValue={DEFAULT.value}
            placeholder=''
            onChange={opt => {
              setTab(opt.tab);
            }}
          />
        </div>

        <div className='tabs-wrapper'>
          <CustomQRCodesPanel {...props} scope={tab} />
        </div>
      </div>
    );
  }),
};

export default QRCodesSidePanel;
