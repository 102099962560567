import React from "react";

import { useIntegrations } from "../integrationsSlice";

import DataSourceItem from "../DataSources/DataSourceItem";

const DataSourceSelect = ({ header }) => {
  const {
    subscription,
    setSubscriptionDataSource,
    dataSources,
  } = useIntegrations();

  const activeDataSourceOptions = dataSources.filter(dS => !dS.coming_soon).map(dataSource => ({ name: dataSource.value, label: dataSource.label, icon: dataSource.icon }));

  return (
    <>
      {header || <div className="subscription-header">Choose Your Connection</div>}

      <div className="data-source-list">
        {activeDataSourceOptions.map((dataSource, index) => (
          <DataSourceItem
            key={index}
            dataSource={dataSource}
            onClick={() => setSubscriptionDataSource({ data_source: dataSource })} // In connection format but do not care about connection id
            isSelected={subscription.data_source === dataSource.name}
          />
        ))}
      </div>
    </>
  );
};

export default DataSourceSelect;
