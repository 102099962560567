import React, { useEffect, useState } from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const initialAccountAssignment = {
  user_id: null,
  account_id: null,
  role: 'member',
};

const accountAssignmentInputs = [
  {
    inputType: 'belongsTo',
    name: 'user_id',
    props: {
      label: 'User',
      belongsToModelName: 'user',
      labelKey: 'full_name',
    }
  },
  {
    inputType: 'belongsTo',
    name: 'account_id',
    props: {
      label: 'Account',
      belongsToModelName: 'account',
      labelKey: 'name',
    }
  },
  {
    inputType: 'simpleSelect',
    name: 'role',
    props: {
      label: 'Role',
      options: ['member', 'admin'],
    },
  }
];

const AccountAssignmentForm = (props) => {
  return (
    <ModelForm
      modelName="account_assignment"
      initialResource={props.initialAccountAssignment ? props.initialAccountAssignment : initialAccountAssignment}
      inputs={accountAssignmentInputs}
      {...props}
    />
  );
};

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID' },
  { type: 'text', dataKey: 'account.name', label: 'Account Name' },
  { type: 'text', dataKey: 'user.full_name', label: 'User Name' },
  { type: 'text', dataKey: 'role', label: 'Role', },
];

function AccountAssignmentManagement(props) {
  return (
    <DopeManagement
      modelName="account_assignment"
      modelForm={AccountAssignmentForm}
      columns={columns}
      scopeOptions={[]}
      {...props}
    />
  );
}

export default AccountAssignmentManagement;
