import React from 'react';
import ModelForm from "../ui/ModelForm";

const initialCampaign = {
  name: '',
  status: 'draft',
  account_id: null,
  campaign_template_id: null,
};

const inputs = [
  { inputType: "text", name: "name", props: { label: "Name" } },
  { inputType: "simpleSelect",  name: "status",  props: {
    label: "Status",
    options: [
      "draft",
      "active",
      "paused",
    ],
  }},
  { inputType: "belongsTo", name: "account_id", props: {
    label: "Account",
    belongsToModelName: "account"
  }},
  { inputType: "belongsTo", name: "campaign_template_id", props: {
    label: "Campaign Template",
    belongsToModelName: "campaign_template"
  }},
];

const CampaignDetailsForm = (props) => {
  return (
    <ModelForm
      modelName="campaign"
      initialResource={initialCampaign}
      inputs={inputs}
      {...props}
    />
  );
};

export default CampaignDetailsForm;
