import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Grid, Row, Col, Input, InputGroup } from "rsuite";
import { Icon } from "@rsuite/icons";
import { MdContentCopy } from "react-icons/md";

import { useAccount } from "./accountSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeTextInput from "../ui/DopeTextInput";
import DopeButton from "../ui/DopeButton";
import DopePhoneInput from "../ui/DopePhoneInput";
import DopeStateSelector from "../ui/DopeStateSelector";


const AccountInformationForm = () => {
  const [account, actions] = useAccount();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [editing, setEditing] = useState(false);

  const location = useLocation();
  const pulse = location.pathname.includes('pulse');

  const handleAccountUpdate = async () => {
    try {
      actions.save();
      dopeUIActions.addFlashMessage({header: `${account.name}`, body: 'was successfully updated!', type: 'success'});
      setEditing(false)
    } catch (error) {
      dopeUIActions.addFlashMessage({header: `${account.name}`, body: 'could not be updated!', type: 'error'});
    }
  }

  const actionButtons = <div className="to-right row margin-16-t">
    <DopeButton
      props={{
        label: editing ? "Cancel" : "Edit",
        type: "button",
        buttonClass: "outlined-black",
        onClick: () => setEditing(!editing)
      }}
      className="submit margin-4-r"
    />

    {editing && <DopeButton
      props={{
        label: "Save",
        type: "submit",
        buttonClass: "filled-black"
      }}
      className="submit"
    />}
  </div>

  return (
    <div style={{marginBottom: "60px"}}>
      <Form fluid onSubmit={handleAccountUpdate}>
        <Grid fluid>
          <Col xs={12}>
            <Row gutter={10} className="margin-4-b">
              <label className="label">Company Name</label>
              <DopeTextInput
                name="name"
                value={account?.name}
                onChange={actions.update}
                disabled={!editing}
              />
            </Row>
            <Row gutter={10} className="margin-4-b">
              <label className="label">Company Phone Number</label>
              <DopePhoneInput
                name="phone_number"
                value={account?.phone_number}
                onChange={actions.update}
                disabled={!editing}
              />
            </Row>

            <Row gutter={10} className="margin-4-b">
              <label className="label">Address 1</label>
              <DopeTextInput
                name="address_1"
                value={account?.address_1}
                onChange={actions.update}
                disabled={!editing}
              />
            </Row>

            <Row gutter={10} className="margin-4-b">
              <label className="label">Address 2</label>
              <DopeTextInput
                name="address_2"
                value={account?.address_2}
                onChange={actions.update}
                disabled={!editing}
              />
            </Row>

            <Row gutter={10} className="margin-4-b">
              <label className="label">City</label>
              <DopeTextInput
                name="city"
                value={account?.city}
                onChange={actions.update}
                disabled={!editing}
              />
            </Row>

            <Row className="margin-4-b">
              <Col xs={12} style={{padding: 'none'}}>
                <label className="label">State</label>
                <DopeStateSelector
                  value={account?.state}
                  onChange={value => actions.update({state: value})}
                  disabled={!editing}
                />
              </Col>
              <Col xs={12} style={{padding: 'none'}}>
                <label className="label">Zip Code</label>
                <DopeTextInput
                  name="zip"
                  value={account?.zip}
                  onChange={actions.update}
                  disabled={!editing}
                />
              </Col>
            </Row>

            {!pulse && actionButtons}
          </Col>

          <Col xs={12}>
            {pulse && <div>
              <label className="label">Hubspot ID</label>
              <InputGroup className="margin-4-b">
                <Input
                  name="hubspot_id"
                  value={account?.hubspot_id}
                  placeholder="Paste link here"
                  onChange={(value) => actions.update({hubspot_id: value})}
                  disabled={!editing}
                />
                <InputGroup.Addon>
                  <Icon as={MdContentCopy}/>
                </InputGroup.Addon>
              </InputGroup>

              <label className="label">Client Strategy Board</label>
              <InputGroup className="margin-4-b">
                <Input
                  placeholder="Paste link here"
                  value={account?.strategy_board_url}
                  onChange={(value) => actions.update({strategy_board_url: value})}
                  name="strategy_board_url"
                  disabled={!editing}
                />
                <InputGroup.Addon>
                  <Icon as={MdContentCopy}/>
                </InputGroup.Addon>
              </InputGroup>

              <label className="label">Assets Drive</label>
              <InputGroup className="margin-4-b">
                <Input
                  placeholder="Paste link here"
                  value={account?.assets_drive_url}
                  onChange={(value) => actions.update({assets_drive_url: value})}
                  name="assets_drive_url"
                  disabled={!editing}
                />
                <InputGroup.Addon>
                  <Icon as={MdContentCopy}/>
                </InputGroup.Addon>
              </InputGroup>
            </div>}
          </Col>
        </Grid>

        {pulse && actionButtons}

      </Form>
    </div>
  );
}

export default AccountInformationForm;
