import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import authApi from "../auth/authApi";
import DopeApi from "../services/DopeApi";

const designTemplatesApi = new DopeApi('design_templates');

export const DesignPreview = ({
  design,
  onClick,
  selected
}) => {
  return (
    <div
      onClick={onClick}
      className={selected ? 'template-preview-wrapper active' :'template-preview-wrapper'}
    >
      <div className='image-wrapper'>
        <img src={design.front_image_url} alt='Postcard Front' />
        <img src={design.back_image_url} alt='Postcard Back' />
      </div>
    </div>
  );
};


const CustomInspirationTemplatesPanel = observer(({ store }) => {
 const [designs, setDesigns] = useState([]);
 const currentAccount = authApi.getCurrentAccount();

  useEffect(() => {
    const scopes = [{ name: 'for_account', args: [currentAccount.id, currentAccount.customer_category_id] }];

    designTemplatesApi.query({
      per_page: 25,
      scopes,
    })
      .then(data => {
        setDesigns(data.design_templates);
      })
      .catch(error => console.log(error));
  }, []);

  const setTemplate = async (template) => {
    try {
      console.log(template);
      store.loadJSON(template);
      console.log();

      await store.waitLoading();
    } catch (err) {
      console.error('Error', err);
    }
  }


  return (
    <div className='templates-tab-container'>
      <div className='templates-list'>
        a
        {designs.length === 0 && <div className='no-templates'>You don’t have any templates yet.</div>}
        {designs.length > 0 && designs.map(design => <DesignPreview key={design.id} design={design} onClick={() => setTemplate(design.json_template) }/>)}

        {/* <CustomTemplatesWrapper
          store={store}
          showMainLoader={showMainLoader}
          loader={postcardTemplatesIsLoaded}
          pagination={omit(['data'], postcardTemplatesData)}
          templates={pathOr([], ['data'], postcardTemplatesData)}
          length={pathOr([], ['data'], postcardTemplatesData).length}
          onPaginationLoad={({page}) => {
            getPostcards({page: page, limit:8, types: types, status: 'approved' })
          }}
        /> */}
      </div>
    </div>
  );
});

export default CustomInspirationTemplatesPanel;
