import React, { useState } from "react";
import { MdOutlineForwardToInbox } from "react-icons/md";

import { useAccount } from "../accounts/accountSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import {formatToLocaleDateString} from "../utils/date";
import DopeButton from "../ui/DopeButton";
import DopeApi from "../services/DopeApi";

const accountHealthStyles = {
  borderRadius: "8px",
  boxShadow: "0px 2px 2px 0px #BABABA40",
  padding: "16px",
  width: "330px",
}

const healthStatusColors = {
  "All Good": "#00A82F",
  "Needs Love": "#FFC107",
  "Help Now": "#FF0000",
}

const accountApi = new DopeApi('account');

const AccountHealth = () => {
  const [account, actions] = useAccount();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [loading, setLoading] = useState(false);

  const healthStatusColor = healthStatusColors[account.account_health];

  const sendMigrationEmail = async () => {
    setLoading(true);
    try {
      await accountApi.update({ id: account.id, actions: [{ name: 'send_migration_email' }] });
      dopeUIActions.addFlashMessage({ header: "Success", body: "Migration email sent successfully", type: "success" })
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error", body: "Failed to send migration email", type: "error" })
    } finally {
      // Stop users from spamming the button
      setTimeout(() => setLoading(false), 2000);
    }
  }

  return (
    <div>
      <div style={{...accountHealthStyles, borderTop: `8px solid ${healthStatusColor}`}}>

        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "4px"}}>
          <div style={{fontWeight: 600, fontSize: "16px", color: "#000000"}}>Account Health</div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <div style={{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: healthStatusColor}}></div>
            <div style={{color: healthStatusColor, fontSize: "14px", fontWeight: 600, marginLeft: "4px"}}>{account.account_health}</div>
          </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "4px"}}>
          <div style={{fontWeight: 400, fontSize: "14px", color: "#000000"}}>Most Recent Dispatch</div>
          <div>
            {account?.most_recent_dispatch_date ? formatToLocaleDateString(account.most_recent_dispatch_date) : "none"}
          </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "4px"}}>
          <div style={{fontWeight: 400, fontSize: "14px", color: "#000000"}}>Next Dispatch</div>
          <div>
            {account?.last_dispatch_date && account.last_dispatch_date > Date.today ? formatToLocaleDateString(account.last_dispatch_date) : "none"}
          </div>
        </div>
      </div>

      <div className="flex to-right margin-16-t">
        <DopeButton
          props={{
            buttonClass: "outlined-black thin",
            label: "Send Migration email",
            onClick: sendMigrationEmail,
          }}
          loading={loading}
          icon={<MdOutlineForwardToInbox className="margin-4-r" />}
        />
      </div>
    </div>
  );
}

export default AccountHealth;
