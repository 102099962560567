import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery2 } from "../../hooks/useQuery";
import DopeApi from "../../services/DopeApi";

import DopeContentPage from "../../ui/DopeContentPage";
import DopeListPage from "../../ui/DopeListPage";
import DopeButton from "../../ui/DopeButton";
import DopeConfirmationModal from "../../ui/DopeConfirmationModal";


const columns = [
  { type: 'text', dataKey: 'name', label: 'Name', flexGrow: 1 },
  { type: 'text', dataKey: 'data_source', label: 'Data Source' },
  { type: 'text', dataKey: 'data_type', label: 'Data Type' },
  { type: 'custom', dataKey: 'is_query', label: 'Type', render: (row) => row.is_query ? 'Query' : 'Trigger' },
  { type: 'text', dataKey: 'event', label: 'Event' },
  { type: 'date', width: 150, dataKey: 'created_at', label: 'Created Date' },
  { type: 'date', width: 150, dataKey: 'updated_at', label: 'Updated Date' },
  { type: 'button', width: 100, dataKey: 'delete', label: 'Delete' },
];

const api = new DopeApi('subscription_template');

const SubscriptionTemplatesTable = () => {
  const {
    error,
    tableProps,
    paginationProps,
    reload,
  } = useQuery2({
    api,
  });

  const [deletingId, setDeletingId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();

  const onRowClick = (row) => {
    navigate(`/pulse/subscription_templates/${row.id}`);
  };

  const onClickDelete = (row, e) => {
    e.stopPropagation();
    setDeletingId(row.id);
  };

  const onConfirmDelete = async () => {
    try {
      setIsDeleting(true);
      await api.destroy(deletingId);
      await reload();
      setDeletingId(null);
    } catch (error) {
      console.error(error);
    }
    setIsDeleting(false);
  };

  const addButton = (
    <DopeButton
      props={{
        buttonClass: "filled",
        label: "Create new",
        onClick: () => onRowClick({ id: 'new' }),
      }}
    />
  );

  return (
    <div>
      <DopeContentPage
        pageTitle="Subscription Templates"
        pageContent={<DopeListPage
          columns={columns}
          tableProps={tableProps}
          paginationProps={paginationProps}
          error={error}
          onRowClick={onRowClick}
          onClickDelete={onClickDelete}
        />}
        actionButtons={addButton}
      />

      <DopeConfirmationModal
        open={!!deletingId}
        title="Delete Subscription Template"
        message="Are you sure you want to delete this subscription template?"
        onConfirm={onConfirmDelete}
        onCancel={() => setDeletingId(null)}
        loading={isDeleting}
      />
    </div>
  );
}

export default SubscriptionTemplatesTable;
