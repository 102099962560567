import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  drawerInvoiceId: null,
  cardDrawerOpen: false,
}

export const stripeUISlice = createSlice({
  name: "stripeUI",
  initialState,
  reducers: {
    updateStripeUI: (stripeUI, { payload: update }) => {
      Object.assign(stripeUI, update);
    },
    setDrawerInvoiceId: (stripeUI, { payload: id }) => {
      stripeUI.drawerInvoiceId = id;
    },
    setCardDrawerOpen: (stripeUI, { payload: open }) => {
      stripeUI.cardDrawerOpen = open;
    }
  }
});

export default stripeUISlice.reducer;

export const {
  updateStripeUI,
  setDrawerInvoiceId,
  setCardDrawerOpen,
} = stripeUISlice.actions;

export const selectStripeUI = (state) => state.stripeUI;

export function useStripeUI() {
  const dispatch = useDispatch();
  const stripeUI = useSelector(selectStripeUI);
  const actions = {
    update: (payload) => dispatch(updateStripeUI(payload)),
    setDrawerInvoiceId: (id) => dispatch(setDrawerInvoiceId(id)),
    setCardDrawerOpen: (open) => dispatch(setCardDrawerOpen(open)),
  };

  return [stripeUI, actions];
}