import React, { useState } from "react";

import { useNavigate } from 'react-router-dom';

import useQuery from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import { useSweetSpotAnalysisUI } from "./sweetSpotAnalysisUISlice";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";

const api = new DopeApi('sweet_spot_analyses');

const columns = [
  { type: 'name_link', dataKey: 'name', label: 'Name', sortable: true, getUrl: (rowData) => `/sweet_spot_analyses/${rowData.id}/show` },
  { type: 'text', dataKey: 'list_name', label: 'List', flexGrow: 1 },
  { type: 'pill', dataKey: 'status', label: 'Status', pillTypeKey: 'status', width: 180 },
  { type: 'date', dataKey: 'created_at', label: 'Created Date', sortable: true, width: 150 },
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'sweet_spot_analysis', width: 75 }
];

const SweetSpotAnalysisTable = () => {
  const navigate = useNavigate();
  const [sweetSpotAnalysisUI, sweetSpotAnalysisUIActions] = useSweetSpotAnalysisUI();
  const [loading, setLoading] = useState(false);

  const {
    error,
    tableProps,
    paginationProps,
    filters,
    setFilters,
    reload,
  } = useQuery(api);

  const handleSearchClear = () => {
    setFilters([]);
  };

  // TODO: Refactor this to use searchable
  const handleSearchChange = (value) => {
    if (value) {
      setFilters([{
        field: 'name',
        value: value,
        operator: 'ilike'
      }]);
    } else {
      handleSearchClear();
    }
  };

  return (
    <>
      <DopeListPage
        paginationProps={paginationProps}
        tableProps={tableProps}
        columns={columns}
        error={error}
        filters={filters}
        setFilters={setFilters}
        onSearchClear={handleSearchClear}
        onSearchChange={handleSearchChange}
      />
      <DopeConfirmationModal
        open={!!sweetSpotAnalysisUI.deleteSweetSpotAnalysisId}
        title="Delete Sweet Spot Analysis"
        message="Are you sure you want to delete this Sweet Spot Analysis?"
        loading={loading}
        onConfirm={() => {
          setLoading(true);
          api.destroy(sweetSpotAnalysisUI.deleteSweetSpotAnalysisId).then(() => {
            setLoading(false);
            sweetSpotAnalysisUIActions.setDeleteSweetSpotAnalysisId(null);
            reload();
          }).catch((error) => {
            console.error(error);
            setLoading(false);
          });
        }}
        onCancel={() => sweetSpotAnalysisUIActions.setDeleteSweetSpotAnalysisId(null)}
      />
    </>
  );
};

export default SweetSpotAnalysisTable;
