import { useEffect, createRef, useState } from 'react';
import { Modal, Button } from 'rsuite';

import { DopeFormGroup } from "../ui/DopeForm";

import { useQRCode } from './qrCodeSlice';
import QRCode from 'easyqrcodejs';

import DopeDrawer from '../ui/DopeDrawer';
import DopeButton from '../ui/DopeButton';


const nameInput = { inputType: "text", name: "name", props: { label: "QR Code Name", placeholder: "Name your QR Code" } };

const contentInput = { inputType: "url", name: "content", props: { label: "Destination URL", placeholder: "https://" } };


const QRCodeDrawer = ({
  open,
  id,
  onClose,
  onConfirm,
}) => {

  const [loading, setLoading] = useState(false);

  const { qrCode, actions } = useQRCode();


  if (!qrCode) {
    return null;
  }

  const inputProps = { values: qrCode, onChange: actions.update, errors: qrCode.errors };


  const canSubmit = () => {
    if (!qrCode.name || qrCode.name.trim() === '') {
      return false;
    }

    if (!qrCode.content || qrCode.content.trim() === '') {
      return false;
    }

    return true;
  };

  if (!open) {
    return null;
  }

  return (
    <DopeDrawer
      className="action-drawer"
      size="sm"
      placement="right"
      open={open}
      onClose={onClose}
      header='QR Code Setup'
      bodyContent={(
        <div>
          <div>
            <div>
              <DopeFormGroup input={nameInput} {...inputProps} />
            </div>
            <div className='margin-tb'>
              <DopeFormGroup input={contentInput} {...inputProps} />
            </div>
            {id !== 'new' && (
              <>
                <p><b>Preview</b></p>
                <div>
                  <img style={{ width: '150px', height: '150px' }} src={qrCode.code_url} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      actions={(
        <>
          <DopeButton
            props={{
              onClick: () => {
                onClose();
              },
              buttonClass: 'outlined-black',
              disabled: loading,
              label: 'Cancel',
              loading: loading,
            }}
          />

          <DopeButton
            props={{
              onClick: () => {
                setLoading(true);
                onConfirm(qrCode);
              },
              buttonClass: 'filled-black',
              disabled: loading || !canSubmit(),
              label: id === 'new' ? 'Create' : 'Save Code',
              loading: loading,
            }}
          />
        </>
      )}
    />
  );
};


export default QRCodeDrawer;
