import React from 'react';
import { Icon } from '@rsuite/icons';
import { MdTaskAlt, MdOutlineCircle, MdOutlineCancel, MdLocalPrintshop } from "react-icons/md";

import { humanize } from '../utils/railsNames';
import { formatDateStringToLocaleDateString } from "../utils/date";
import { DopeToolTip } from "../ui/DopeToolTip";

const statusToIcon = {
  draft: <Icon as={MdOutlineCircle} className="circle-icon color-text-label-blue-grey" />,
  scheduled: <Icon as={MdOutlineCircle} className="circle-icon color-text-label-blue-grey" />,
  cancelled: <Icon as={MdOutlineCancel} className="circle-icon color-text-button-grey" />,
  error: <Icon as={MdOutlineCancel} className="circle-icon color-text-error-red" />,
  sent: <Icon as={MdTaskAlt} className="circle-icon color-text-blue" />,
  delivered: <Icon as={MdTaskAlt} className="circle-icon color-text-blue" />,
  printed: <Icon as={MdLocalPrintshop} className="circle-icon color-text-scheduled-purple" />,
  printing_complete: <Icon as={MdLocalPrintshop} className="circle-icon color-text-scheduled-purple" />,
  printing: <Icon as={MdLocalPrintshop} className="circle-icon color-text-pending-yellow" />,
};


const CampaignDispatchesStatusCell = ({ rowData }) => {
  return (
    <div className="flex row gap">
      {rowData.dispatch_statuses_and_dates.map((dispatch, i) => {
        const tooltipItems = [
          { label: 'Product', value: dispatch.product_label },
          { label: 'Design', value: dispatch.mail_template_name },
          { label: 'Status', value: humanize(dispatch.effective_status) },
          { label: 'Date', value: formatDateStringToLocaleDateString(dispatch.date) },
        ];

        return (
          <DopeToolTip.FromList
            key={i}
            list={tooltipItems}
            triggerAction="hover"
            children={statusToIcon[dispatch.effective_status]}
          />
        );
      })}
    </div>
  );
}

export default CampaignDispatchesStatusCell;
