import React, {useEffect, useState} from "react";

import StripeApi from "../stripe/StripeApi";
import {useDopeUI} from "../ui/dopeUISlice";
import DopePlaceholder from "../ui/DopePlaceholder";

const stripeApi = new StripeApi;

const addOnPlans = ["Tracking Number Subscription Plan"]

const addOnWrapperStyles = {
  height: "181px",
  border: "1px solid #EAECEF",
  borderRadius: "8px",
  boxShadow: "0px 6px 6px 0px #BABABA40",
  padding: "20px",
  color: "#000000",
  cursor: "pointer",
}

const selectedAddOnWrapperStyles = {
  ...addOnWrapperStyles,
  border: "2px solid #255FDF",
}

const selectTextStyles = {
  fontWeight: 700,
  fontSize: "11px",
  color: "#255FDF",
  background: "#F0F4F7",
  borderRadius: "4px",
  padding: "2px 4px",
  width: "fit-content",
  marginLeft: "10px",
}

const currentPlanStyles = {
  ...selectTextStyles,
  color: "#EA0029",
  background: "rgba(234, 0, 41, 0.1)",
}

const AddOnSelectPage = () => {
  const [addOns, setAddOns] = useState([]);
  const [selectedAddOnsIds, setSelectedAddOnsIds] = useState([]);
  const [accountSubscriptions, setAccountSubscriptions] = useState([]);
  const [dopeUI, dopeUIActions] = useDopeUI();

  useEffect(() => {
    const fetchAddOns = async () => {
      const stripePlans = await stripeApi.get("index_stripe_plans");
      const accountStripeData = await stripeApi.get("stripe_subscription_data");
      setAccountSubscriptions(accountStripeData.stripe_subscription_data.account_stripe_subscriptions);
      setAddOns(stripePlans.filter(plan => addOnPlans.includes(plan.nickname)));
    }

    fetchAddOns();
  }, []);

  const onAddOnClick = (addOnId, isSubscribed) => {
    if (isSubscribed) {
      return;
    }

    if (selectedAddOnsIds.includes(addOnId)) {
      setSelectedAddOnsIds(selectedAddOnsIds.filter(selectedAddOnId => selectedAddOnId !== addOnId));
    } else {
      setSelectedAddOnsIds([...selectedAddOnsIds, addOnId]);
    }
  }

  const purchasePlan = async (addOnId) => {
    try {
      const stripeData = await stripeApi.post("purchase_stripe_plan", { plan_id: addOnId })
      dopeUIActions.addFlashMessage({ header: 'Success!', message: 'Your subscriptions have been updated.', type: 'success' });
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: 'Error!', message: 'There was an error updating your subscriptions.', type: 'error' });
    }
  }

  const onPurchaseSelectedPlans = async () => {
    selectedAddOnsIds.forEach((addOnId) => {
      purchasePlan(addOnId);
    })
  }

  return (
    <>
      {addOns.length > 0 ? (
        <>
          <h5 style={{margin: "20px 0 10px"}}>Add Ons</h5>
          {addOns.map(addOn => {
            const isSelected = selectedAddOnsIds.includes(addOn.id);
            const isSubscribed = accountSubscriptions.map(sub => sub.plan.id).includes(addOn.id);
            let wrapperStyles;
            if (isSubscribed) {
              wrapperStyles = {...addOnWrapperStyles, cursor: "default"};
            }
            else if (isSelected) {
              wrapperStyles = selectedAddOnWrapperStyles;
            } else {
              wrapperStyles = addOnWrapperStyles;
            }

            return (
              <div key={addOn.id} style={wrapperStyles} onClick={() => onAddOnClick(addOn.id, isSubscribed)}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <h6 style={{fontSize: "18px", fontWeight: 600}} >{addOn.nickname}</h6>
                    {isSelected && <span style={selectTextStyles}>SELECTED PLAN</span>}
                    {isSubscribed && <span style={currentPlanStyles}>SUBSCRIBED</span>}
                  </div>

                  <p style={{fontWeight: 600, fontSize: "22px"}}>${addOn.amount / 100} / {addOn.interval}</p>
                </div>
              </div>
            )
          })}

          {selectedAddOnsIds.length > 0 && <button onClick={onPurchaseSelectedPlans}>Purchase Selected Plans</button>}
        </>
      ) : (<DopePlaceholder rows={5}/> )}
    </>
  );
}

export default AddOnSelectPage;
