const HandwrittenCardBodyPreview = ({ font, body, signature, limit, mailPiece = false, header, footer }) => {
  const mailPieceClass = mailPiece ? 'mail-piece-preview' : '';

  return (
    <div className={`handwritten-card-text-preview ${mailPieceClass}`}>
      <div className="handwritten-card-text-preview-header">{header && <img src={header} />}</div>
      <div>
        <div style={{ fontFamily: font?.id, fontSize: `${2.5 - (0.5 * ((body || '').length / limit))}rem`, lineHeight: font?.line_spacing || 'initial' }} className="handwritten-card-text-preview-body">
          {(body || '').split('\n').map((paragraph, index) => {
            if (paragraph.trim() === '') {
              return <p style={{height: '1rem' }}></p>;
            }

            return <p key={index}>{paragraph.split(' ').map(word => {
              // if (word.indexOf('{{firstname}}') > -1 || word.indexOf('{{lastname}}') > -1) {
              //   const replacement = word.replace('{{firstname}}', testFirstName || 'firstname').replace('{{lastname}}', testLastName || 'lastname');

              //   return <span>{replacement}</span>;
              // }
              return `${word} `;
            })}</p>;
          })}
        </div>
        <br />
        <div style={{ fontFamily: font?.id, textAlign: 'right', marginRight: '2rem', fontSize: `${2.5 - (0.5 * ((body || '').length / limit))}rem`, lineHeight: font?.line_spacing || 'initial' }} className='signature'>
          {(signature || '').split('\n').map((paragraph, index) => <p key={index}>{paragraph}</p>)}
        </div>
      </div>
      <div className="handwritten-card-text-preview-footer">{footer && <img src={footer} />}</div>
    </div>
  );
}

export default HandwrittenCardBodyPreview;
