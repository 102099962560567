import React from "react";

import moment from "moment";

import DopeApi from "../../services/DopeApi";
import { useQuery2 } from "../../hooks/useQuery";

import DopeListPage from "../../ui/DopeListPage";
import DopeFilters from "../../ui/dopeFilters/DopeFilters";
import DopeButton from "../../ui/DopeButton";
import DopeChart from "../../ui/DopeChart";

const api = new DopeApi('data_axle_report_logs_account_day');
const accountApi = new DopeApi('account');

const columns = [
  { type: 'text', dataKey: 'created_date', label: 'Date', width: 100, sortable: true },
  { type: 'text', dataKey: 'account_id', label: 'Acc #', width: 100, sortable: true },
  { type: 'text', dataKey: 'account_name', label: 'Acc Name', sortable: false },
  { type: 'text', dataKey: 'api_name', label: 'API', sortable: false },
  { type: 'text', dataKey: 'data_package_type', label: 'Data', sortable: false },
  { type: 'text', dataKey: 'records_pulled', label: 'Records Pulled', sortable: true },
  { type: 'text', dataKey: 'records_kept', label: 'Records Kept', sortable: true },
  { type: 'text', dataKey: 'list_generations_count', label: 'List Generations', sortable: true },
];

const filterInputs = [
  {
    title: "Account",
    type: "MultiSelect",
    name: "account_ids",
    field: "account_id",
    searchable: true,
    getOptions: (text = "") => accountApi.getOptions({
      valueKey: 'id',
      labelKey: 'name',
      search: { text, column: 'name' },
    }),
  },
  {
    title: "Date Range",
    type: "DateRange",
    name: "created_date_range",
    field: "created_date",
  },
];

const chartOptions = {
  xFields: [
    { value: 'created_date', type: 'datetime' },
    { value: 'account_id', type: 'text' },
  ],
  initialXFunc: 'week',
  yFields: [
    { value: 'records_kept', type: 'numeric' },
    { value: 'records_pulled', type: 'numeric' },
    { value: 'list_generations_count', type: 'numeric' },
  ],
  initialYFunc: 'sum',
};


const DataAxleUsage = () => {
  const { error, tableProps, paginationProps, setFilters, filters, exportCSV, exporting, reload, chartDataParams } = useQuery2({
    api,
    initialSortColumn: 'created_date',
    initialPerPage: 100,
    initialFilters: [
      { name: "created_date_range", field: 'created_date', operator: '>=', value: moment().subtract(1, 'month').startOf('month').toDate() },
      { name: "created_date_range", field: 'created_date', operator: '<=', value:  moment().subtract(1, 'month').endOf('month').toDate() },
    ],
  });

  const handleExport = () => exportCSV("DataAxleUsageExport");

  const exportButton = <DopeButton onClick={handleExport} className="outlined-black" loading={exporting}>Export</DopeButton>;
  const refreshButton = <DopeButton
    key="refresh"
    props={{
      buttonClass: "text-link",
      label: "Refresh",
      onClick: () => reload(),
    }}
  />

  const filterBar = (
    <DopeFilters
      filterInputs={filterInputs}
      filters={filters}
      setFilters={setFilters}
    />
  );

  const listPage = (
    <DopeListPage
      columns={columns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      setFilters={setFilters}
      filters={filters}
      error={error}
    />
  );

  const chart = (
    <div className="margin-tb-10">
      <DopeChart
        api={api}
        params={chartDataParams}
        options={chartOptions}
      />
    </div>
  );

  return (
    <div>
      <div className="to-right gap-10 vertical-align">
        {refreshButton}
        {exportButton}
      </div>
      {filterBar}
      {chart}
      {listPage}
    </div>
  );
};

export default DataAxleUsage;
