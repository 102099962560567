import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useQuery from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import DopeContentPage from "../ui/DopeContentPage";
import DopeButton from "../ui/DopeButton";
import AddDesignTemplateModal from "./AddDesignTemplateModal";

const columns = [
  { type: 'image_url', dataKey: 'front_image_url', label: '' },
  { type: 'image_url', dataKey: 'back_image_url', label: '' },
  { type: 'text', dataKey: 'name', label: 'Name', sortable: true },
  { type: 'text', dataKey: 'customer_category.name', label: 'Customer Category', sortable: false },
  { type: 'text', dataKey: 'customer.name', label: 'Account', sortable: false },
  { type: 'timestamp', dataKey: 'created_at', label: 'Created', sortable: true },
];

const api = new DopeApi('design_templates');
const filterInputs = [];

const PulseDesignGrid = () => {
  const [showAddDesignTemplateModal, setShowAddDesignTemplateModal] = useState(false);
  const [designTemplateId, setDesignTemplateId] = useState('new');


  const {
    error,
    tableProps,
    paginationProps,
    onSearchClear,
    onSearchInput,
    setSearchScope,
    setFilters,
    filters,
    reload,
  } = useQuery(api, [], null, [{ name: 'standalone' }]);

  useEffect(() => {
    setSearchScope('name');
  }, []);

  const navigate = useNavigate();

  const onRowClick = (row) => {
    setDesignTemplateId(row.id);
    setShowAddDesignTemplateModal(true);
  }

  const addDesignTemplateButton = <DopeButton
    props={{
      buttonClass: "filled",
      label: "Add new template",
      onClick: () => setShowAddDesignTemplateModal(true),
    }}
  />

  return (
    <div>
      <AddDesignTemplateModal open={showAddDesignTemplateModal} handleClose={() => {
        setDesignTemplateId('new');
        setShowAddDesignTemplateModal(false);
      }} reload={reload} designTemplateId={designTemplateId} />

      <DopeContentPage
        pageTitle="All Design Templates"
        pageContent={<DopeListPage
          columns={columns}
          tableProps={tableProps}
          paginationProps={paginationProps}
          error={error}
          filterInputs={filterInputs}
          filters={filters}
          setFilters={setFilters}
          onSearchClear={onSearchClear}
          onSearchChange={onSearchInput}
          onRowClick={onRowClick}
        />}
        actionButtons={addDesignTemplateButton}
      />
    </div>
  );
}

export default PulseDesignGrid;
