import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'rsuite';

const Tab = ({ label, name, children }) => {
  return (
    <div>
      {children}
    </div>
  );
};

const DopeTabs = ({ onChange, children, defaultTab }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [underlineStyle, setUnderlineStyle] = useState({});

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');

  const onTabClick = (name, index) => {
    setActiveTab(name);
    const underlineWidth = 100 / children.length;
    const underlineOffset = index * underlineWidth;
    setUnderlineStyle({ left: `${underlineOffset}%`, width: `${underlineWidth}%` });
  };

  useEffect(() => {
    if (location.state && location.state.tab_name) {
      onTabClick(location.state.tab_name, children.findIndex(obj => obj.name === location.state.tab_name));
    } else {
      onTabClick(activeTab, 0);
    }
  }, []);

  useEffect(() => {
    setActiveTab(defaultTab || children[0].props.name);
  }, [defaultTab]);

  const tabStyle = {
    backgroundColor: 'transparent',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000000',
    borderRadius: '0',
    padding: '10px 4px',
    justifyContent: 'flex-start',
    marginRight: '20px'
  };

  const activeTabStyle = {
    ...tabStyle,
    borderBottom: "3px solid #EA0029",
    borderRadius: "0 0 3px 3px",
    fontWeight: 700
  };

  const baseUnderlineStyle = {
    height: '2px',
    background: '#0D6EFD',
    position: 'absolute',
    bottom: '0',
    transition: 'left 0.3s ease-in-out',
  };

  const tabsWrapperStyle = {
    display: 'flex',
    position: 'relative',
  };

  if (children[0].props.hasOwnProperty('underlineStyles')) {
    tabsWrapperStyle['width'] = 'fit-content';
  }

  return (
    <div>
      <div style={tabsWrapperStyle}>
        {children.map((child, index) => {
          const {name, label, disabled} = child.props;

          let tabStyles;
          if (child.props.hasOwnProperty('customStyle')) {
            tabStyles = child.props.customStyle;
            if (name === activeTab) {
              tabStyles = {
                borderBottom: '2px solid #EA0029',
                fontWeight: 700,
                padding: '10px 20px'
              };
            }
          } else {
            tabStyles = name === activeTab ? activeTabStyle : tabStyle;
          }

          return (
            <Button
              key={name}
              disabled={disabled}
              onClick={() => {
                onTabClick(name, index);

                if (typeof onChange === 'function') {
                  onChange(name);
                }
              }}
              style={tabStyles}
            >
              {label}
            </Button>
          );
        })}
        {isAdmin && <div style={{...baseUnderlineStyle, ...underlineStyle}}/>}
      </div>

      <hr className="hr margin-16-b" style={{ marginTop: "-2px" }}/>

      {children.map((child) => {
        if (child.props.name !== activeTab) return undefined;
        return child.props.children;
      })}
    </div>
  );
};

DopeTabs.Tab = Tab;

export default DopeTabs;
