import React from "react";
import { formatToLocaleDateString } from "../utils/date";

const imageStyle = {
  // TODO: images need to be resized to fit the container, will postcard images be standaradized?
  maxWidth: "100%",
  maxHeight: "67.57%",
  width: "100%",
  height: "67.57%",
  borderRadius: "7px",
}


const MailPieceListItem = ({ mailPiece }) => {

  return (
    <div style={{ border: "1px solid #EAECEF", borderRadius: "4px", margin: "20px 0px" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderBottom: "1px solid #EAECEF", width: "100%"}}>
        <div style={{ width: "50%", padding: "10px" }}>
          <img alt={`${mailPiece.mail_template_name} front-image`} src={mailPiece.front_image_url} style={imageStyle} />
        </div>
        <div style={{ width: "50%", padding: "10px" }}>
          <img alt={`${mailPiece.mail_template_name} back-image`} src={mailPiece.back_image_url} style={imageStyle} />
        </div>
      </div>
      <div style={{display: "flex", padding: "10px", justifyContent: "space-between"}}>
        <div style={{fontWeight: 700, fontSize: "11px", color: "#292B2E", marginLeft: "5px"}}>
          TRIGGER
          <div style={{ color: "#255FDF", fontWeight: 500, fontSize: "12px" }}>{mailPiece.campaign_name}</div>
        </div>
        <div style={{fontWeight: 700, fontSize: "11px", color: "#292B2E", marginRight: "20px", borderLeft: "1px solid #EAECEF", paddingLeft: "10px"}}>
          SEND DATE
          <div style={{ color: "#255FDF", fontWeight: 500, fontSize: "12px" }}>{formatToLocaleDateString(mailPiece.dispatch_date)}</div>
        </div>
      </div>
    </div>
  )
}

export default MailPieceListItem;