import React, { useState } from "react";
import { Icon } from "@rsuite/icons";
import { MdOutlinePlayCircle } from "react-icons/md";
import {Modal} from "rsuite";
import DopePlayer from "../ui/DopePlayer";

const ToDoListItems = [
  {
    header: "Welcome to Dope!",
    description: "Learn more about our DOPE software and our team at DOPE Marketing",
    videoUrl: "https://dopemail-assets.s3.amazonaws.com/Dope+Marketing+Training+Overview.mp4"
  },
  {
    header: "Creating a List Campaign",
    description: "Learn how to setup a campaign and send it to a list of contacts",
    videoUrl: "https://dopemail-assets.s3.amazonaws.com/Sending+a+List+Campaign+Tutorial.mp4"
  },
  {
    header: "Request a design",
    description: "We’ll walk you through the form and how to get the best results",
    videoUrl: "https://dopemail-assets.s3.amazonaws.com/Requesting+Custom+Designs+on+Dope+Platform+%F0%9F%8E%A8.mp4"
  },
  {
    header: "Creating a Blitz Campaign",
    description: "Learn how to set up a targeted campaign using our Neighborhood Blitz tool",
    videoUrl: "https://dopemail-assets.s3.amazonaws.com/Sending+Targeted+Mail+Campaigns+Tutorial.mp4"
  },
];

const ToDoList = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  return (
    <div className="overview-section">
      <div style={{marginBottom: "8px"}}>
        <h4 className="overview-header">Getting Started</h4>
      </div>

      <div>
        {ToDoListItems.map((item, index) => {
          return (
            <div key={index} className="to-do-item" onClick={() => setSelectedVideo(item)}>
              <div className="to-do-item-icon">
                <Icon as={MdOutlinePlayCircle} />
              </div>
              <div>
                <div className="to-do-item-header">{item.header}</div>
                <div className="to-do-item-description">{item.description}</div>
              </div>
            </div>
          );
        })}
      </div>

      <Modal open={!!selectedVideo} onClose={() => setSelectedVideo(null)} size="md">
        <Modal.Header>
          <h3 className="header-3">{selectedVideo?.header}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="to-center">
            <DopePlayer url={selectedVideo?.videoUrl} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ToDoList;
