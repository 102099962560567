import React from "react";

import { states } from "../utils/constants";

import DopeInlineFilter from "../ui/DopeInlineFilter";
import DopeSelect from "../ui/DopeSelect";
import DopeDatePicker from "../ui/DopeDatePicker";

const objectiveTypeOptions = [
  { name: "Reactivation Mailers", id: "reactivation" },
  { name: "Thank You Mailers", id: "thank_you" },
  { name: "Marketing Mailers", id: "marketing" },
];

const attributionWindowOptions = [
  { label: "6 Months", value: 6 },
  { label: "12 Months", value: 12 },
  { label: "18 Months", value: 18 },
  { label: "24 Months", value: 24 },
  { label: "3 Years", value: 36 },
  { label: "4 Years", value: 48 },
  { label: "5 Years", value: 60 },
];

const labelStyle = {
  minWidth: 150,
};

const ConversionReportFilters = ({ report, onChange }) => {
  const handleChangeFilters = (filters) => onChange({ filters });
  const sharedProps = { filters: report.filters, onChange: handleChangeFilters };

  const sendingStartFilter = report.filters.find(f => f.field === 'dispatches.date' && f.operator === '>=');
  const sendingEndFilter = report.filters.find(f => f.field === 'dispatches.date' && f.operator === '<=');

  const handleUpdateFilterValue = (filter, value) => {
    const nextFilters = report.filters.map(f => {
      if (f.field === filter.field && f.operator === filter.operator) {
        return { ...f, value };
      }
      return f;
    });

    onChange({ filters: nextFilters });
  };

  return (
    <div className="page-panel gray">
      <DopeInlineFilter
        label="Automation Sendings"
        field="dispatches.campaigns.campaign_template_id"
        modelName="automation"
        valueKey="action_template_id"
        {...sharedProps}
      />

      <DopeInlineFilter
        label="Campaign Sendings"
        field="dispatches.campaign_id"
        modelName="campaign"
        valueKey="id"
        {...sharedProps}
      />

      <DopeInlineFilter
        label="Mailer Objectives"
        field="dispatches.mail_templates.objective_type"
        options={objectiveTypeOptions}
        valueKey="id"
        labelKey="name"
        {...sharedProps}
      />

      <DopeInlineFilter
        label="Cities"
        field="TRIM(LOWER(address.city))"
        {...sharedProps}
      />

      <DopeInlineFilter
        label="States"
        field="address.state"
        options={states}
        valueKey="value"
        labelKey="label"
        {...sharedProps}
      />

      <DopeInlineFilter
        label="Zip Codes"
        field="address.zip"
        {...sharedProps}
      />

      <div className="margin-tb gap vertical-align">
        <div className="label large text-right" style={labelStyle}>Sending Date Range</div>
        <DopeDatePicker
          value={sendingStartFilter.value}
          name="sendingStart"
          onChange={(update, value) => handleUpdateFilterValue(sendingStartFilter, value)}
          style={{ width: 200 }}
        />
        <DopeDatePicker
          value={sendingEndFilter.value}
          name="sendingEnd"
          onChange={(update, value) => handleUpdateFilterValue(sendingEndFilter, value)}
          style={{ width: 200 }}
        />
      </div>

      <div className="margin-tb gap vertical-align">
        <div className="label large text-right" style={labelStyle}>Attribution Window</div>
        <div className="flex-1">
          <DopeSelect
            options={attributionWindowOptions}
            value={report.window_months}
            name="window_months"
            onChange={onChange}
            style={{ width: 200 }}
            searchable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ConversionReportFilters;
