import React, {useEffect, useState} from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import DopeListPage from "../ui/DopeListPage";
import DopeButton from "../ui/DopeButton";
import AddCreditsModal from "./AddCreditsModal";
import ViewCreditModal from "./ViewCreditModal";

const creditTransactionsApi = new DopeApi("credit_transaction");

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', flexGrow: 1, sortable: true },
  { type: 'text', dataKey: 'account_name', label: 'Company Name', flexGrow: 3 },
  { type: 'timestamp', dataKey: 'created_at', label: 'Date', flexGrow: 2, sortable: true },
  { type: 'text', dataKey: 'product_name', label: 'Credit Type', flexGrow: 2 },
  { type: 'text', dataKey: 'credit_reason', label: 'Reason', flexGrow: 2, sortable: true },
  { type: 'text', dataKey: 'amount', label: '# of Credits', flexGrow: 2, sortable: true },
  { type: 'text', dataKey: 'created_by', label: 'Issued By', flexGrow: 3 }
];

const initialFilters = [
  { field: "transactionable_type", operator: "!=", value: "Purchase" },
];

const IssuedCreditsTable = () => {
  const [refundsModalOpen, setRefundsModalOpen] = useState(false);
  const [viewCreditID, setViewCreditID] = useState(null);
  const { tableProps, paginationProps, onSearchClear, onSearchInput, filters, setFilters, reload }
    = useQuery2({
      api: creditTransactionsApi,
      initialFilters,
      initialSearchScope: 'accounts.name'
  });

  const selectCredit = (creditId) => {
    setViewCreditID(creditId);
  };

  const handleCreditsClose = (success) => {
    setRefundsModalOpen(false);
    success && reload();
  };

  return (
    <div>
      <AddCreditsModal open={refundsModalOpen} onClose={handleCreditsClose} />
      <ViewCreditModal creditId={viewCreditID} setCreditId={setViewCreditID} />

      <DopeButton
        props={{
          onClick: () => setRefundsModalOpen(true),
          label: "Add Credits to Accounts",
          buttonClass: "filled",
          styles: { position: "absolute", top: "40px", right: "40px" }
        }}
      />

      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
        setFilters={setFilters}
        onRowClick={rowData => selectCredit(rowData.id)}
        filters={filters}
      />

    </div>
  );
}

export default IssuedCreditsTable;
