import React, { useEffect } from 'react';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import IdMappingIcon from '@rsuite/icons/IdMapping';

import DopeContentPage from "../ui/DopeContentPage";
import DopePlaceholder from "../ui/DopePlaceholder";

import { useContact } from "./contactSlice";
import { useContactUI } from "./contactUISlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeApi from "../services/DopeApi";
import DopeButton from "../ui/DopeButton";
import ContactDrawerForm from "./ContactDrawerForm";
import DopeDrawer from "../ui/DopeDrawer";

const contactApi = new DopeApi('contacts');

const Contact = () => {
  const { id } = useParams();
  const [contact, actions] = useContact(id);
  const [contactUI, contactUIActions] = useContactUI();
  const [dopeUI, dopeUIActions] = useDopeUI();

  const navigate = useNavigate();

  const successMessage = () => {
    dopeUIActions.addFlashMessage({header: `${contact.first_name} ${contact.last_name}`, body: 'was successfully updated!', type: 'success' });
  }
  const saveContact = async () => {
    try {
      await actions.save();
      successMessage();
    } catch (error) {
      console.log(error);
    }
  }

  const deleteContact = async () => {
    try {
      await contactApi.destroy(contact.id);
      dopeUIActions.addFlashMessage({header: `${contact.first_name} ${contact.last_name}`, body: 'was successfully deleted!', type: 'success' });
      navigate('/lists_and_contacts');
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!contact) {
      actions.get(id);
    }

    return actions.resetToInitial;
  }, []);

  if (!contact) {
    return <DopeContentPage
      tabComponents={[]}
      actionButtons={[]}
      pageTitle={"Loading Contact..."}
      pageContent={<DopePlaceholder type="image" rows={5} />}
    />
  }

  const actionButtons = (
    <>
      <DopeButton
        icon={<IdMappingIcon style={{fontSize: '16px', marginRight: "5px"}} />}
        props={{
          buttonClass: "outlined-red",
          onClick: deleteContact,
          label: "Delete Contact",
          styles: {marginRight: "10px"}
        }}
      />

     <DopeButton
       icon={<CharacterAuthorizeIcon style={{fontSize: '21px', marginRight: "5px"}} />}
       props={{
         buttonClass: "filled",
         onClick: saveContact,
         label: "Update Contact",
       }}/>

    </>
  );

  return (
    <>
      <DopeContentPage
        tabComponents={[]}
        pageContent={<Outlet />}
      />

      <ContactDrawerForm
        open={!!contactUI.drawerContactId}
        onClose={() => contactUIActions.setDrawerContactId(null)}
        header="Add Contact"
        id={contactUI.drawerContactId}
      />
    </>
  )
}

export default Contact;
