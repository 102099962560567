import React from "react";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { Button } from "rsuite";
import DopeLoader from "./DopeLoader";

const uploaderStyles = {
  height: '137px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '2px dashed #F3F3F3'
}

const buttonStyles = {
  color: "#255FDF",
  fontWeight: 700,
  fontSize: '11px',
  width: '111px',
  height: '32px',
  borderRadius: '16px',
  padding: '8px 16px',
  backgroundColor: 'transparent',
  border: '1px solid #EAECEF'
}

const DopeAttachmentDropZone = ({ onUpload, onRemove, currentFile, accept = {}, instructions = '', loading = false, validator, onRejection }) => {

  const onDrop = async (acceptedFiles, fileRejections) => {
    // Check if at least one file was dropped
    if (acceptedFiles.length > 0 && !loading) {
      const file = acceptedFiles[0];
      onUpload(file);
    }

    if (fileRejections.length > 0 && typeof onRejection === 'function') {
      onRejection(fileRejections);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
    validator,
  });

  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      <div style={uploaderStyles}>

        {loading && (<DopeLoader />)}

        {(currentFile && !loading) && (
          <>
            <h4>{currentFile.name}</h4>
            <div><Button onClick={onRemove} style={buttonStyles}>Remove</Button></div>
          </>
        )}

        {(!currentFile && !loading) && (
          <>
            <h4><FileUploadIcon />Drag your file here</h4>
            {instructions && <div>({instructions})</div>}
            <div>or</div>
            <Button style={buttonStyles}>Browse Files</Button>
          </>
        )}
      </div>

    </div>
  );
};

export default DopeAttachmentDropZone;
