import React from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import { useCampaign } from "./campaignSlice";

import DopeListPage from "../ui/DopeListPage";

const api = new DopeApi('mail_piece');

const columns = [
  { type: 'stacked_text', dataKey: ['contact.full_name', 'contact.company_name'], label: 'Name' },
  { type: 'address_text', dataKey: 'contact.full_address', label: 'Address' },
  { type: 'pill', pillTypeKey: 'status', dataKey: 'effective_status', label: 'Status' },
  { type: 'text', dataKey: 'dispatch.date', label: 'Send Date', sortable: true }, // date formatter is thinking dates in zero offset timezone
  { type: 'image_url', dataKey: 'front_image_thumbnail_url', label: '' },
];

const filterInputs = [

];

const CampaignMailPieceTable = () => {
  const [campaign] = useCampaign();
  const campaignFilter = { field: 'dispatches.campaign_id', operator: '=', value: campaign.id };
  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters }
    = useQuery2({
      api,
      initialFilters: [campaignFilter],
      initialSearchScope: "contacts.full_name_and_address",
    });

  return (
    <DopeListPage
      columns={columns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      error={error}
      filterInputs={filterInputs}
      onSearchChange={onSearchInput}
      onSearchClear={onSearchClear}
      setFilters={setFilters}
    />
  );
};

export default CampaignMailPieceTable;
