import React, { useEffect, useState } from 'react';

import { useCampaign } from "../campaigns/campaignSlice";
import authApi from "../auth/authApi";
import DopeButton from "../ui/DopeButton";
import Cents from '../ui/Cents';
import DopeApi from "../services/DopeApi";

const productApi = new DopeApi('product');
const accountApi = new DopeApi('account');

const fetchAccount = async (accountId) => {
  return await accountApi.get(accountId);
}

const EddmRoutesSummary = () => {
  const [eddmProducts, setEddmProducts] = useState([]);
  const [campaign, campaignActions, options] = useCampaign();
  const { eddm } = campaign;
  const { eddmData, routesData } = options;
  const { routes, fetching } = routesData;
  const allCrids = routes.map(route => route.attributes.ZIP_CRID);

  useEffect(() => {
    const fetchEddmProducts = async () => {
      const account = await fetchAccount(authApi.getCurrentAccountId());
      const accountDiscounts = account.product_pricing_tier_account_discounts;
      const response = await productApi.query({ scopes: [{ name: 'eddm' }] });
      let products = response.products;

      products.forEach(product => {
        const tierDiscount = product.product_pricing_tier_discounts.find(discount => discount.subscription_key === account.subscription_key);
        const accountTierDiscount = accountDiscounts.find(discount => discount.product_pricing_tier_id === tierDiscount.product_pricing_tier_id)?.discount || 0;
        product.discounted_price = product.default_unit_price - (tierDiscount.discount + accountTierDiscount);
      });

      const sortedProducts = products.sort((a, b) => a.discounted_price - b.discounted_price);
      setEddmProducts(sortedProducts);
    }

    fetchEddmProducts();
  }, []);

  const costsPerProduct = eddmProducts.map(product => {
    // TODO: Hate this but we keep changing the product labels and product team wants it different in different places
    const productDisplayName = product.label.replace("EDDM Postcard", "");

    return (
      <div className="flex row space-between margin-8-b" key={product.id}>
        <div>{productDisplayName} (<Cents>{product.discounted_price}</Cents>)</div>
        <div><Cents>{product.discounted_price * eddmData.audienceCount}</Cents></div>
      </div>
    );
  });

  return (
    <div className="background-blue-grey-shade-light border-radius-8 pad">
      <h4 className="header-4 margin-bottom">Summary</h4>

      <div className="label margin-4-b">Routes Selected</div>
      <p className="margin-bottom">{eddm.zip_crids.length}</p>

      <div className="label margin-4-b">Total Delivery Addresses</div>
      <p className="margin-bottom">{eddmData.audienceCount.toLocaleString()}</p>

      <div className="label margin-4-b">Cost Per Sending by Mailer Size</div>
      {costsPerProduct}

      {eddm.zip_code && (
        <div className="flex row space-between margin-top">
          <DopeButton
            props={{
              label: "Select All",
              onClick: () => campaignActions.setEDDMRoutes(allCrids),
              buttonClass: "text-link",
            }}
            className="padding-none"
            disabled={fetching}
          />

          <DopeButton
            props={{
              label: "Clear All",
              onClick: campaignActions.clearEDDMRoutes,
              buttonClass: "text-link",
            }}
            className="padding-none"
            disabled={fetching}
          />
        </div>
      )}
    </div>
  );
}

export default EddmRoutesSummary;
