import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import { Icon } from '@rsuite/icons';
import { FaPlus } from "react-icons/fa";
import DopeTabs from "../ui/DopeTabs";
import DopeSelectDrawer from "../ui/DopeSelectDrawer";

import DopeButton from "../ui/DopeButton";

import ConversionReportTable from "./ConversionReportTable";
import QRCodeTable from "./QRCodeTable";
import HotLeadsTable from "./HotLeadsTable";
import TrackingNumbersTable from "./TrackingNumbersTable";
import HomePinIcon from "../icons/HomePinIcon";
import SweetSpotAnalysisTable from "./SweetSpotAnalysisTable";

const analysisTypeOptions = [
  { label: "Matchback Analysis Report", value: "report", icon: "mail", description: "Examine how successful yopur marketing campaigns have been and see your ROI." },
  { label: "Sweet Spots List Analysis", value: "sweet-spot-analysis", icon: <Icon as={HomePinIcon} /> , description: "Identify the top Zip Codes of your lists." },
  { label: "QR Code", value: "qr_code", icon: "qr", description: "Setup a QR code for easy URL sharing and tracking." },
  { label: "Tracking Number", value: "tracking_number", icon: "phone", description: "Create a phone number to track where leads are coming from." },
];

const AnalysisPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCreateNew = (analysisTypeSelection) => {
    if (analysisTypeSelection === 'report') {
      setDrawerOpen(false);
      navigate('/conversion_reports/new/setup');
    } else if (analysisTypeSelection === 'qr_code') {
      setDrawerOpen(false);
      navigate('/qr_codes/new');
    } else if (analysisTypeSelection === 'tracking_number') {
      setDrawerOpen(false);
      navigate('/tracking_numbers/new');
    } else if (analysisTypeSelection === 'sweet-spot-analysis') {
      setDrawerOpen(false);
      navigate('/sweet_spot_analyses/new/setup');
    }
  };

  return (
    <div className="page">

      <div className="page-header">
        <p className="header-text">Tracking & Analysis</p>
      </div>

      <div className="top-right-actions">
        <DopeButton
          icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 4px 0 0"}} /> }
          props={{
            buttonClass: "filled",
            onClick: () => setDrawerOpen(true),
            label: "Create New",
          }}
        />
      </div>

      <DopeTabs defaultTab={location.pathname.split('/')[1] ?? location.state?.tab} onChange={t => navigate(`/${t}`)  }>
        <DopeTabs.Tab
          key={'conversion_reports'}
          name='conversion_reports'
          label={'Reports'}
          disabled={false}
        >
          <ConversionReportTable />
        </DopeTabs.Tab>
        <DopeTabs.Tab
          key={'sweet_spot_analyses'}
          name='sweet_spot_analyses'
          label={'Analyses'}
          disabled={false}
        >
          <SweetSpotAnalysisTable />
        </DopeTabs.Tab>
        <DopeTabs.Tab
          key={'qr_codes'}
          name='qr_codes'
          label={'QR Codes'}
          disabled={false}
        >
          <QRCodeTable />
        </DopeTabs.Tab>

        <DopeTabs.Tab
          key={'hot_leads'}
          name='hot_leads'
          label={'Hot Leads'}
          disabled={false}
        >
          <HotLeadsTable />
        </DopeTabs.Tab>
        <DopeTabs.Tab
          key={'tracking_numbers'}
          name='tracking_numbers'
          label={'Tracking Numbers'}
          disabled={false}
        >
          <TrackingNumbersTable />
        </DopeTabs.Tab>

      </DopeTabs>

      <DopeSelectDrawer
        header="What do you want to setup?"
        open={drawerOpen}
        onCancel={() => setDrawerOpen(false)}
        onAction={handleCreateNew}
        options={analysisTypeOptions}
      />
    </div>
  );
};

export default AnalysisPage;
