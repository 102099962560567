export const PHOTOSHOP_IMAGE_TYPES = [
  'image/vnd.adobe.photoshop',
  'image/x-photoshop',
  'image/psd',
];

export const DPI = 300;
export const BLEED_INCHES = 0.25;
export const BLEED_PIXELS = DPI * BLEED_INCHES;

export const sizeDisplay = (size) => {
  const [height, width] = size.split('x');

  return `${height}” x ${width}”`;
};

// These are equivalents to the sizeToPixels function values.
export const sizeToStyle = (size = '6x9', dpi = 300, bleed = 0.25) => {
  const [height, width] = size.split('x');

  return {
    height: (parseFloat(height) + bleed) * dpi,
    width: (parseFloat(width) + bleed) * dpi,
  };
};

export const objectURLToFile = (url, fileName, contentType = 'image/png') => {
  if (url instanceof Blob) {
    return new File([url], fileName, { type: contentType });
  }

  return fetch(url)
    .then(res => res.blob())
    .then(blob => new File([blob], fileName, { type: contentType }));
};

export const extensionToType = (ext) => {
  switch (ext) {
    case 'png':
      return 'image/png';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    default:
      return '';
  }
};

export const sizeWithBleed = (size) => {
  const [width, height] = size.split('x');

  return `${parseFloat(width) + 0.25}"x${parseFloat(height) + 0.25}"`;
};

// All sizes are based on 300 DPI.
// First size listed is "display" size, the one users should see.
// Second is "print" size which is the actual one we use for print
// and includes bleed.
export const sizeToPixels = (size) => {
  switch (size) {
    case "4.25x11":
    case "4.5x11.25":
      return { width: 1350, height: 3375 };
    case "6x9":
    case "6.25x9.25":
      return { width: 2775, height: 1875 };
    case "5x7":
    case "5.25x7.25":
      return { width: 2175, height: 1575 };
    case "6.5x9":
    case "6.75x9.25":
      return { width: 2775, height: 2025 };
    case "6.5x11":
    case "6.75x11.25":
      return { width: 3375, height: 2025 };
    case "6x11":
    case "6.25x11.25":
      return { width: 3375, height: 1875 };
    case "8.5x11":
    case "8.75x11.25":
      return { width: 3375, height: 2625 };
    case "18x24":
      return { width: 7200, height: 5400 };
    case "12x18":
      return { width: 5400, height: 3600 };
    default:
      return { width: 2775, height: 1875 };
  }
};

export const sizeToRatio = (size) => {
  const { width, height } = sizeToPixels(size);

  const greatestCommonDivisor = (a, b) => (b === 0 ? a : greatestCommonDivisor(b, a % b));

  const divisor = greatestCommonDivisor(width, height);

  return `${width / divisor}/${height / divisor}`;
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];

  const bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
