import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { FaUpload } from "react-icons/fa6";

import { useDesign } from "./designSlice";
import { DopeFormGroup } from "../ui/DopeForm";
import DopePageStepHeader from "../ui/DopePageStepHeader";

import { objectURLToFile, sizeToRatio  } from "../utils/design";

import './Design.scss';
import 'react-image-crop/src/ReactCrop.scss';
import DopeDesignInput from "./DopeDesignInput";
import DopeButton from "../ui/DopeButton";
import DopePlayer from "../ui/DopePlayer";
import HWCFrontApprovalDrawer from "./HWCFrontApprovalDrawer";
import accountAbility from "../auth/accountAbility";
import Cannot from "../ui/Cannot";

const nameInput = {
  inputType: "text",
  name: "name",
  props: { label: "Design Name" },
};

const HWCFrontForm = () => {
  const { design, actions } = useDesign();

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const size = params.get('size') || design.mail_template.size || '5x7';

  const designInputProps = { values: design, onChange: actions.update, errors: design.errors };

  const [front, setFront] = useState(design.mail_template?.front_image_url);

  const [loading, setLoading] = useState(false);

  const [showApproval, setShowApproval] = useState(false);

  useEffect(() => {
    actions.update({ mail_template_type: 'DesignTemplate' });

    if (size) {
      actions.updateMailTemplate({ size, template_type: 'HandwrittenCardTemplate' });
    }
  });

  const handleUpload = async () => {
    setLoading(true);

    const now = Date.now();

    const frontImage = await objectURLToFile(front, `front_${now}.png`);

    await actions.save({
      files: {
        front_image: frontImage,
      },
      status: 'approved',
    });

    setLoading(false);

    navigate('/designs');
  };

  const canSubmit = () => {
    if (loading) {
      return false;
    }

    if (front === null) {
      return false;
    }

    if (!design.name || design.name.trim() === '') {
      return false;
    }

    return true;
  };

  const canCreateHandwrittenCard = accountAbility.can('create', 'Design', { mail_template_type: 'HandwrittenCardTemplate' });

  return (
    <div className="postcard-design-form-wrapper">
      <div className="page">
        <div className="page-header">
          <p className="header-text">Upload a New "Handwritten Card Front" Design</p>
        </div>

        <div>
          <DopePageStepHeader
            step="1"
            headerText="The Basics"
          />
          <div className="flex row">
            <div className="rs-form margin-40-b postcard-basics" style={{ width: 'min(80%, 400px)' }}>
              <div>
                <DopeFormGroup input={nameInput} {...designInputProps} />
              </div>
            </div>

            <div className="full-width">
              <DopePlayer url="https://dopemail-assets.s3.amazonaws.com/Uploading+Custom+Designs+on+Dope+Platform+%F0%9F%8E%A8.mp4" />
            </div>
          </div>
        </div>
      </div>


      <div className="page">
        <DopePageStepHeader
          step="2"
          headerText="Upload Designs"
        />
        <p className='instructions'></p>
        <div className={`postcard-front-preview ${design.mail_template.mailer_type}`}>
          <h6>Front Preview</h6>
          <DopeDesignInput
            size={size}
            onChange={(srcImage, previewImage) => {
              setFront(previewImage);
            }}
            allowCrop={false}
          />
        </div>
      </div>
      <div className="footer-actions to-right">
        <Cannot action="create" subject="Design" data={design} cannotDisplay={{ tooltip: "Your current subscription does not include Handwritten Cards. PLease upgrade or reach out to your Account Manager to activate this feature" }}>
          <DopeButton
            icon={<FaUpload style={{fontSize: '10px', marginRight: "5px"}} />}
            disabled={!canSubmit() || !canCreateHandwrittenCard}
            props={{
              label: "Upload as Approved",
              styles: { width: "200px" },
              buttonClass: "filled",
              onClick: () => {
                if (canSubmit()) {
                  setShowApproval(true);
                }
              }
            }}
          />
        </Cannot>
      </div>

      <HWCFrontApprovalDrawer
        open={showApproval}
        onConfirm={handleUpload}
        onCancel={() => setShowApproval(false)}
        loading={loading}
        design={design}
        actions={actions}
        front={front}
        aspectRatio={sizeToRatio(size)}
      />
    </div>
  );
};

export default HWCFrontForm;
