import React, {useState} from "react";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import DopeButton from "../ui/DopeButton";
import '../overview/Overview.scss'
import {Input, InputGroup, Pagination} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import AddDopeTeamMemberModal from "./AddDopeTeamMemberModal";

const userApi = new DopeApi('user');

const contentBodyStyles = {
  backgroundColor: '#FFFFFF',
  padding: '40px',
  margin: '20px',
  boxShadow: '0px 6px 6px 0px #BABABA40',
  borderRadius: '4px',
  height: 'fit-content',
}

const userImageStyles = {
  width: "44px",
  height: "44px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: "600",
  color: "#FFFFFF",
  marginRight: "10px"
}

const avatarImageStyles = {
  width: "44px",
  height: "44px",
  borderRadius: "50%",
  marginRight: "10px"
}

const tableHeaders = ['Name', 'Role', 'Phone Number', ''];

const paginationLocale = {
  total: 'Total: {0}'
};


const DopeTeamUser = ({ user, selectMember }) => {
  return (
    <div style={{ padding: "16px", border: "1px solid #F3F3F3", borderRadius: "8px", display: "flex", flexDirection: "row", background: "#FFFFFF", alignItems: "center", marginBottom: "8px"}} >
      <div style={{ flex: 2, display: "flex", flexDirection: "row" }}>
        {user.avatar_url ? (
          <div>
            <img src={user.avatar_url} alt={`${user.full_name} photo`} style={avatarImageStyles} />
          </div>
        ) : (
          <div style={{...userImageStyles, backgroundColor: user.profile_color}}>
            {user.first_name[0]}{user.last_name[0]}
          </div>
        )}
        <div>
          <div style={{fontSize: "16px", color: "#000000"}}>{user.full_name}</div>
          <div style={{ fontSize: "14px", color: "#6F6F6F"}}>{user.email}</div>
        </div>
      </div>

      <div style={{ fontSize: "14px", color: "#292B2E", flex: 2}}>
        {user.dope_role}
      </div>

      <div style={{ fontSize: "14px", color: "#292B2E", flex: 2}}>
        {user.phone_number}
      </div>

      <div style={{flex: 1}}>
        <DopeButton
          props={{
            buttonClass: "text-link",
            label: "Update",
            onClick: () => selectMember(user.id),
          }}
        />
      </div>
    </div>
  );
}

const DopeTeam = () => {
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const { tableProps, paginationProps, onSearchInput, reload }
    = useQuery2({
      api: userApi,
      initialScopes: [{ name: "dope_team"}],
      initialSearchScope: "full_name"
    });

  const handleSearchInput = (value) => {
    setSearchText(value);
    onSearchInput(value);
  }

  const handleAddTeamMember = () => {
    setShowModal(true);
  }

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedTeamMember(null)
  }

  const handleSelectMember = (userId) => {
    setSelectedTeamMember(userId);
    setShowModal(true);
  }

  return (
    <div style={contentBodyStyles}>

      <AddDopeTeamMemberModal
        open={showModal}
        handleClose={handleModalClose}
        userId={selectedTeamMember ? selectedTeamMember : 'new'}
        setSelectedTeamMember={setSelectedTeamMember}
        reload={reload}
      />

      <div style={{marginBottom: "24px", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <h5 style={{fontWeight: 600, fontSize: "25px"}}>DOPE Team</h5>
        <DopeButton
          props={{
            buttonClass: "filled",
            label: "Add Team Member",
            onClick: handleAddTeamMember,
          }}
        />
      </div>

      <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "8px"}}>
        <InputGroup style={{width: "300px"}}>
          <Input onChange={value => handleSearchInput(value)} value={searchText}/>
          <InputGroup.Addon>
            <SearchIcon/>
          </InputGroup.Addon>
        </InputGroup>
      </div>

      <div style={{
        display: "flex",
        flexDirection: "row",
        borderRadius: "8px 8px 0 0",
        border: "1px solid #F3F3F3",
        padding: "8px 16px",
        marginBottom: "8px"
      }}>
        {tableHeaders.map((header, index) => {
          const flexSize = index === tableHeaders.length - 1 ? 1 : 2;
          return (
            <div key={index} style={{flex: flexSize, fontSize: "11px", color: "#8793A6", fontWeight: 700}}>
              {header}
            </div>
          );
        })}
      </div>

      {tableProps.data && tableProps.data.map((user, index) => {
        return (
          <DopeTeamUser key={index} user={user} selectMember={handleSelectMember}/>
        );
      })}

      <div>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          limitOptions={[10, 25, 50, 100]}
          locale={paginationLocale}
          {...paginationProps}
        />
      </div>
    </div>
  );
}

export default DopeTeam;
