import { useEffect, useState } from 'react';

import ReactCrop, { makeAspectCrop, centerCrop } from 'react-image-crop';
import {
  FaImage,
  FaTrash,
  FaArrowRotateLeft,
  FaCircleArrowLeft,
  FaCircleArrowRight
} from "react-icons/fa6";

import { nanoid } from 'nanoid';

import { PolotnoContainer, WorkspaceWrap } from 'polotno';
import { Workspace } from 'polotno/canvas/workspace';
import createStore from "polotno/model/store";

import DopeAttachmentDropZone from '../ui/DopeAttachmentDropZone';
import PDFRenderer from "./PDFRenderer";

import { useMediaUpload } from "../editor/mediaUploadSlice";

import { objectURLToFile, dataURLtoFile, PHOTOSHOP_IMAGE_TYPES } from "../utils/design";

import {
  sizeToStyle,
  sizeToRatio,
  sizeToPixels,
  sizeWithBleed,
} from "../utils/design";
import DopeMessage from '../ui/DopeMessage';
import { parsePSD } from '../utils/psd';

import DopeApi from '../services/DopeApi';

const api = new DopeApi('media_upload');

const hasSameAspectRatio = (size, width, height) => {
  const { width: expectedWidth, height: expectedHeight } = size;

  return ( width / height ) === ( expectedWidth / expectedHeight );
};

const resizeImage = (image, width, height, contentType) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(image, 0, 0, width, height);

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        return;
      }

      blob.name = `${Date.now()}.png`;

      resolve(window.URL.createObjectURL(blob));

    }, contentType);
  });
};

const getCroppedImg = async (base64, crop, element, previousObject, size) => {
  const block = base64.split(";");
  const contentType = block[0].split(":")[1];

  const canvas = document.createElement('canvas');
  const image = element;

  image.setAttribute('crossorigin', 'anonymous');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const { height, width } = sizeToStyle(size);

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');


  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    width,
    height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        return;
      }

      blob.name = `${Date.now()}.png`;

      window.URL.revokeObjectURL(previousObject);

      resolve(window.URL.createObjectURL(blob));

    }, contentType);
  });
};

const validator = (file) => {
  const isImage = file.type.indexOf('image') === 0;
  const isPSD = file.name.endsWith('.psd');
  const isPDF = file.type === 'application/pdf';

  if (isImage || isPSD || isPDF) {
    return null;
  }

  return {
    code: "invalid-type",
    message: 'File must be an image, PDF or a PSD file'
  };
};

const store = createStore({ key: process.env.REACT_APP_POLOTNO_API_KEY });

const DopeDesignInput = ({
  onChange,
  size,
  defaultPreview,
  showPreview,
  showControls,
  allowCrop,
  currentFile = null,
  onRejection = (rejections) => {},
}) => {
  const { mediaUpload, actions: mediaUploadActions } = useMediaUpload();

  const [croppedFile, setCroppedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [json, setJson] = useState(null);

  const [ratio, setRatio] = useState(null);

  const [sourcePreview, setSourcePreview] = useState(null);
  const [preview, setPreview] = useState(null);


  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);


  const [loading, setLoading] = useState(false);

  const [crop, setCrop] = useState({
    unit: '%',
    height: 100
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    onChange(sourcePreview, preview, file, json);
  }, [preview, sourcePreview]);

  useEffect(() => {
    setRatio(sizeToRatio(size || '6x9'));
  }, [size]);

  useEffect(() => {
    if (defaultPreview) {
      setLoading(true);
      setPreview(defaultPreview);

      api.get('proxy' , { url: defaultPreview })
        .then(async ({ content, mime_type, url }) => {
          if (!mime_type.startsWith('image')) {
            setFile(null);
            setSourcePreview(null);
            setPreview(null);
            return;
          }
          // Get name without the query string or hash
          const originalName = url.split('?')[0].split('#')[0].split('/').pop();
          const base64 = `data:${mime_type};base64,${content}`;

          const file = await objectURLToFile(base64, originalName, mime_type);

          setFile(file);
          setSourcePreview(base64);
        }).catch(() => {
          setFile(null);
          setSourcePreview(null);
          setPreview(null);
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [defaultPreview]);

  const upload = async (base64) => {
    const id = nanoid();

    const file = dataURLtoFile(base64, `layer_${id}_${Date.now()}.png`);

    try {
      const result = await mediaUploadActions.save({ media: file, media_type: 'layer' });

      return result.payload.media_url;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to upload image. Please try again.');
    }
  };

  const expected = sizeToPixels(size);

  if (!ratio) {
    return null;
  }

  const aspectRatio = ratio.split('/').map((n) => parseInt(n)).reduce((a, b) => a / b);

  return (
    <div className="postcard-design-upload">
      <div style={{ width: '1000px', height: '1000px', display: 'none' }}>
        <Workspace store={store}  />
      </div>

      {showPreview !== false && (

        <div className="postcard-design-preview" style={{ aspectRatio: ratio}}>
          <div className="postcard-design-preview-wrapper">
            {preview !== null && <img src={preview}  /> }
            {preview === null && (
              <div className="postcard-design-preview-placeholder">
                <p>{size} Postcard</p>
                <FaImage size="2em" />
              </div>
            )}
          </div>
        </div>
      )}

      {sourcePreview !== null && (
        <div className="postcard-design-crop-controls">
          {allowCrop !== false ? (
            <ReactCrop
              className="postcard-design-crop"
              crop={crop}
              aspect={aspectRatio}
              ruleOfThirds
              onComplete={async (crop) => {
                const image = await getCroppedImg(
                  sourcePreview,
                  crop,
                  croppedFile,
                  preview,
                  size
                );

                setPreview(image);
              }}
              onChange={(newCrop) => setCrop(newCrop)}
            >
              <img
                src={sourcePreview}
                onLoad={async (e) => {
                  setCroppedFile(e.currentTarget);

                  const { width, height } = e.currentTarget

                  const crop = makeAspectCrop(
                    {
                      unit: '%',
                      width: 100,
                    },
                    aspectRatio,
                    width,
                    height
                  );

                  const pixelCrop = {
                    unit: 'px',
                    width: width * (crop.width / 100),
                    height: height * (crop.height / 100),
                    x: 0,
                    y: 0,
                  };

                  setCrop(pixelCrop);

                  const image = await getCroppedImg(
                    sourcePreview,
                    pixelCrop,
                    e.currentTarget,
                    preview,
                    size
                  );

                  setPreview(image);
                }}
                style={{ aspectRatio: ratio }}
                alt="Crop Preview"
              />
            </ReactCrop>
          ) : (
            <img src={sourcePreview} style={{maxWidth: '100%' }}/>
          )}
          {showControls !== false && (
            <div className="postcard-design-crop-actions">
              {file && file.type === 'application/pdf' && (
                <button
                  disabled={loading}
                  onClick={() => {
                    if (page > 1) {
                      setPage(page - 1);
                    }
                }}>
                  <FaCircleArrowLeft />
                </button>
              )}

              {file && file.type === 'application/pdf' && (
                <small>{page} / {totalPages}</small>
              )}

              {file && file.type === 'application/pdf' && (
                <button
                  disabled={loading}
                  onClick={() => {
                    if (page < totalPages) {
                      setPage(page + 1);
                    }
                }}>
                  <FaCircleArrowRight />
                </button>
              )}
              {allowCrop !== false && (
                <button
                  disabled={loading}
                  onClick={() => {
                    setCrop({
                      unit: '%',
                      x: 0,
                      y: 0,
                    });
                    setPreview(sourcePreview);
                }}>
                  <FaArrowRotateLeft /> Reset
                </button>
              )}
              <button
                disabled={loading}
                onClick={() => {
                  setCrop({
                    unit: '%',
                    x: 0,
                    y: 0,
                  });
                  setFile(null);
                  setSourcePreview(null);
                  setPreview(null);
                }}
              >
                <FaTrash/> Remove
              </button>

            </div>
          )}
        </div>
      )}

      {file === null  && (
        <div style={{ width: '100%', ...(showPreview !== false ? { maxWidth: 'calc(50% - 2rem)' } : {})}}>
        {error && (
          <DopeMessage
            type="error"
            header="Design Error"
            body={error}
            style={{boxShadow: 'none' }}
            />
        )}
        <DopeAttachmentDropZone
          validator={validator}
          currentFile={currentFile}
          loading={loading}
          onRejection={onRejection}
          onUpload={async (file) => {
            setLoading(true);
            setError(null);
            const reader = new FileReader();

            reader.onloadend = async () => {
              if (file.type === 'application/pdf') {
                setFile(file);
                setJson(null);
                // If the file is a PDF file, we need to wait until the renderer
                // finishes loading the document.
                return;
              }

              if (file.type.indexOf('image') === 0 && !PHOTOSHOP_IMAGE_TYPES.includes(file.type)) {

                const image = new Image();

                image.onload = () => {
                  const { width, height } = image;

                  if (!allowCrop && size && (width !== expected.width || height !== expected.height)) {
                    setSourcePreview(null);
                    setPreview(null);
                    setFile(null);
                    setError(`For a ${size} card, we require the file to be ${sizeWithBleed(size)} (${expected.height}x${expected.width} pixels) to account for bleed and cutting.`);
                  } else {
                    setSourcePreview(reader.result);
                    setPreview(reader.result);
                    setFile(file);
                    setJson(null);
                  }
                  setLoading(false);
                };

                image.src = reader.result;
              } else {
                try {
                  const page = await parsePSD(file, upload, size);
                  store.clear();
                  store.setSize(expected.width, expected.height);
                  store.loadJSON({ pages: [page] });
                  await store.waitLoading();

                  const imageData = await store.toDataURL({
                    pageId: store.pages[0].id,
                    dpi: 300,
                    pixelRatio: 2,
                  });

                  const imageFile = await objectURLToFile(imageData, file.name, 'image/png');

                  setJson(page);

                  setSourcePreview(imageData);
                  setPreview(imageData);
                  setFile(imageFile);
                } catch (error) {
                  console.error(error);
                  setError(error.message);
                }

                setLoading(false);
              }
            };

            reader.readAsDataURL(file);
          }} />
          </div>
        )}
      <PDFRenderer
        file={file}
        width={expected.width}
        height={expected.height}
        onDocumentLoad={({ numPages }) => {
          setTotalPages(numPages);
        }}
        onPageLoad={(result) => {
          // Once we load the document, we handle continue normally
          const image = new Image();

          image.onload = async () => {
            const { width, height } = image;

            // PDF Files may differ in size depending on how they were exported
            // so apart from checking the size, we also check if the ratio is the same.
            const sameRatio = hasSameAspectRatio(expected, width, height);
            const sameSize = width === expected.width && height === expected.height;

            if (!allowCrop && size && (!sameRatio && !sameSize)) {
              setSourcePreview(null);
              setPreview(null);
              setFile(null);
              setError(`For a ${size} card, we require the file to be ${sizeWithBleed(size)} (${expected.height}x${expected.width} pixels) to account for bleed and cutting.`);
            } else if (!sameSize && sameRatio) {
              // If the ratio is the same, we can center crop the image
              const resizedImage = await resizeImage(image, expected.width, expected.height, 'image/png');
              setSourcePreview(resizedImage);
              setPreview(resizedImage);
            } else {
              setSourcePreview(result);
              setPreview(result);
            }
            setLoading(false);
          };

          image.src = result;
        }}
        page={page}
      />
    </div>
  );
};

export default DopeDesignInput;
