import React from "react";
import accountAbility from "../auth/accountAbility";
import { DopeToolTip } from "./DopeToolTip";

const Cannot = ({ action, subject, data, cannotDisplay, children }) => {
  const can = accountAbility.can(action, subject, data);
  let content;

  if (can) return children;

  if (cannotDisplay.display) content = cannotDisplay.display;
  if (cannotDisplay.tooltip) content = <DopeToolTip content={cannotDisplay.tooltip}>{children}</DopeToolTip>;

  return (
    <>{content}</>
  )
}

export default Cannot;
