import React from "react";

import DopeTabs from "../ui/DopeTabs";
import ListDetailsForm from "./ListDetailsForm";
import ContactListManagement from "./ContactListManagement";

const ListForm = (props) => {
  const listId = props.id;
  const belongsToListFilters = [{ field: 'list_id', operator: '=', value: listId }];

  const initialContactList = {
    contact_id: null,
    list_id: listId,
  };

  return (
    <DopeTabs>

      <DopeTabs.Tab name="listDetails" label="Details">
        <ListDetailsForm {...props} />
      </DopeTabs.Tab>

      <DopeTabs.Tab name="contactLists" label="Contacts" disabled={!listId}>
        <ContactListManagement
          useDrawer={true}
          hideFiltering={true}
          initialFilters={belongsToListFilters}
          formProps={{ initialResource: initialContactList }}
        />
      </DopeTabs.Tab>

    </DopeTabs>
  );
}

export default ListForm;
