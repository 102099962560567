import React, { useEffect, useState } from "react";

import { ExternalObjectsAPI } from "../IntegrationsAPI";

import { JsonEditor } from "json-edit-react";
import { Modal } from 'rsuite';
import DopeLoader from "../../ui/DopeLoader";
import DopeTextInput from "../../ui/DopeTextInput";

const ExternalObjectViewer = ({ id, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [eo, setEo] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (id) {
      setLoading(true);
      ExternalObjectsAPI.fetch(id)
        .then(setEo)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  let content;
  if (loading) {
    content = <DopeLoader />;
  } else if (eo) {
    const rootName = [eo.dataSource, eo.dataType].join("_");
    content = (
      <JsonEditor
        data={eo}
        rootName={rootName}
        restrictEdit={true}
        restrictDelete={true}
        restrictAdd={true}
        searchFilter="all"
        maxWidth="100%"
        minWidth="100%"
        searchText={searchText}
      />
    );
  }

  const handleOnClose = () => {
    setEo(null);
    setSearchText("");
    onClose();
  };

  return (
    <Modal open={!!id} size="lg" onClose={handleOnClose}>
      <Modal.Header>
        <Modal.Title>External Object Viewer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <DopeTextInput
            label="Search"
            value={searchText}
            onChange={(update, value) => setSearchText(value)}
            placeholder="Search by key or value"
          />
        </div>
        {content}
      </Modal.Body>
    </Modal>
  );
};

export default ExternalObjectViewer;
