import React from "react";

const ImageCompareIcon = ({ size, fill, className, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill={fill} stroke={fill} className={className}style={{ strokeWidth: 0 }}>

      <path d="M7.5 17.25V15.75H3.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39688 15.0156 2.25 14.6625 2.25 14.25V3.75C2.25 3.3375 2.39688 2.98438 2.69063 2.69063C2.98438 2.39688 3.3375 2.25 3.75 2.25H7.5V0.75H9V17.25H7.5ZM3.75 13.5H7.5V9L3.75 13.5ZM10.5 15.75V9L14.25 13.5V3.75H10.5V2.25H14.25C14.6625 2.25 15.0156 2.39688 15.3094 2.69063C15.6031 2.98438 15.75 3.3375 15.75 3.75V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H10.5Z" />
    </svg>
  );
};

export default ImageCompareIcon;
