import axios from "axios";

const clientConfig = {
  getBaseUrl: null,
  getHeaders: null,
};

const setGetBaseUrl = (getter) => {
  clientConfig.getBaseUrl = getter;
};

const setBaseUrl = (baseUrl) => {
  setGetBaseUrl(() => baseUrl);
};

const setGetHeaders = (getter) => {
  clientConfig.getHeaders = getter;
};

const setHeaders = (headers) => {
  setGetHeaders(() => headers);
};

const client = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use(
  (config) => {
    if (!clientConfig.getBaseUrl || !clientConfig.getHeaders) {
      console.error('Integrations API client configuration must be set before making requests');
      throw new Error('API client configuration must be set before making requests');
    }

    const baseUrl = clientConfig.getBaseUrl();
    if (baseUrl) {
      config.baseURL = baseUrl;
    }

    const headers = clientConfig.getHeaders();
    if (headers) {
      config.headers = { ...config.headers, ...headers };
    }

    return config;
  }
);

client.interceptors.response.use((response) => {
  return response.data;
});


const DataSourcesAPI = {

  query: async () => {
    return await client.get('data_sources');
  },

  queryDataTypes: async (data_source) => {
    return await client.get(`data_sources/${data_source}/data_types`);
  },

  queryDataFields: async ({ data_source, data_type, shared = false }) => {
    return await client.get(`data_sources/${data_source}/data_types/${data_type}/data_fields?shared=${shared}`);
  },

  queryDataFieldValues: async ({ data_source, data_type, data_field, shared = false }) => {
    return await client.get(`data_sources/${data_source}/data_types/${data_type}/data_fields/${encodeURIComponent(data_field)}/values?shared=${shared}`);
  },

  fetchCDMMap: async ({ data_source, data_type, full = false }) => {
    return await client.get(`data_sources/${data_source}/data_types/${data_type}/cdm_map?full=${full}`);
  },

};

const ConnectionsAPI = {

  query: async () => {
    return await client.get('connections');
  },

  create: async (params) => {
    return await client.post('connections', params);
  },

  sync: async (connectionId) => {
    return await client.post(`connections/${connectionId}/sync`);
  },

  destroy: async (connectionId) => {
    return await client.delete(`connections/${connectionId}`);
  },

  queryData: async ({ connectionId, params }) => {
    return await client.post(`connections/${connectionId}/query`, params);
  },

};

const SubscriptionsAPI = {

  query: async () => { // TODO not implemented on backend
    return await client.get('subscriptions');
  },

  fetch: async (id) => {
    return await client.get(`subscriptions/${id}`);
  },

  create: async (subscription) => {
    return await client.post('subscriptions', subscription);
  },

  update: async (subscription) => {
    return await client.put(`subscriptions/${subscription.id}`, subscription);
  },

  save: async (subscription) => {
    if (subscription.id) {
      return await SubscriptionsAPI.update(subscription);
    } else {
      return await SubscriptionsAPI.create(subscription);
    }
  },

  sync: async (subscriptionId) => {
    return await client.post(`subscriptions/${subscriptionId}/sync`);
  },

  destroy: async (id) => {
    return await client.delete(`subscriptions/${id}`);
  },

};

const OperatorsAPI = {

  query: async () => {
    return await client.get('operators');
  },

};

const ExternalObjectsAPI = {

  fetch: async (id) => {
    return await client.get(`external_objects/${id}`);
  },

};

const SubscriptionTemplatesAPI = {

  query: async (params) => {
    return await client.get('subscription_templates', { params });
  },

};

const API = {
  dataSources: DataSourcesAPI,
  connections: ConnectionsAPI,
  subscriptions: SubscriptionsAPI,
  operators: OperatorsAPI,
  externalObjects: ExternalObjectsAPI,
  subscriptionTemplates: SubscriptionTemplatesAPI,
};

export default API;

export {
  API,
  DataSourcesAPI,
  ConnectionsAPI,
  SubscriptionsAPI,
  OperatorsAPI,
  ExternalObjectsAPI,
  SubscriptionTemplatesAPI,

  setGetBaseUrl,
  setBaseUrl,
  setGetHeaders,
  setHeaders,
};
