import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Button } from "rsuite";
import CheckRoundIcon from '@rsuite/icons/CheckRound';

import DopeLogo from "../icons/DopeLogo";
import DopeTextInput from "../ui/DopeTextInput";
import authApi from "./authApi";

const loginFormStyles = {
  width: "468px",
  height: "auto",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 6px 6px 0px #BABABA40",
}

const buttonStyles = {
  backgroundColor: "#EA0029",
  width: "100%",
  borderRadius: "24px",
  color: "#fff",
  fontWeight: 600,
  fontSize: "14px",
  padding: "12px 0",
  marginTop: "20px",
  marginBottom: "20px",
  border: "none",
  cursor: "pointer",
}

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [errors, setErrors] = useState(null);

  const updateEmail = (email) => {
    setEmail(email)
  }

  const submitResetRequest = async () => {
    try {
      const response = await authApi.sendResetPasswordEmail(email);
      if (response.data.message === "success") {
        setEmailSent(true);
      } else {
        console.log(response)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <DopeLogo style={{ width: "169px", height: "73px", margin: "20px 0" }}/>
      <div style={loginFormStyles}>

        <h3 style={{marginBottom: "20px"}} >Password Recovery</h3>

        {!emailSent && (
          <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px", marginBottom: "20px"}}>
              <p style={{fontWeight: 400, fontSize: "14px", color: "#6F6F6F"}}>Enter your email address and we will send you a link to reset your password.</p>
            </div>

            <label htmlFor="email" style={{fontWeight: 600, fontSize: "14px", color: "#8793A6"}}>Email</label>
              <DopeTextInput
              name="email"
              value={email}
              onChange={({email}) => updateEmail(email)}
              />
            {errors && <div style={{color: 'red'}}>{errors}</div>}

            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px"}}>
              <Button
                style={buttonStyles}
                className="btn btn-primary"
                onClick={submitResetRequest}
              >
                Send me a link to reset my password
              </Button>
            </div>
          </>
        )}

        {emailSent && (
          <>
            <div style={{ color: "#4CAF50", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "48px", margin: "20px 0"}}>
              <CheckRoundIcon />
            </div>
            <div style={{ fontWeight: 400, fontSize: "14px", color: "#000000"}}>Your link was sent. Please check your email and click the link to reset your password.</div>
          </>
        )}

        <div style={{marginTop: "20px "}}>
          <Link to="/login" style={{fontWeight: 600, fontSize: "14px", color: "#255FDF"}}>Back to Sign In</Link>
        </div>

      </div>
    </div>
  );
}

export default ForgotPassword;
