import { useRef } from'react';
import { usePolotnoStore } from './usePolotnoStore';
import Editor from './Editor';


const DesignEditorPage = (props) => {
  const store = usePolotnoStore();
  const ref = useRef();

  return <Editor {...props} ref={ref} {...store} />;
};

export default DesignEditorPage;
