import React, {useEffect, useState} from "react";
import {Col, Grid, Row, SelectPicker} from "rsuite";


import DopeApi from "../services/DopeApi";
import { useAccount } from "../accounts/accountSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeButton from "../ui/DopeButton";

const userApi = new DopeApi("user");
const accountAssignmentsApi = new DopeApi("account_assignment");
const accountApi = new DopeApi("account");

const selectStyles = {
  width: "100%",
  minWidth: "200px"
}

const avatarImageStyles = {
  width: "44px",
  height: "44px",
  borderRadius: "50%",
  marginRight: "10px"
}

const userInitialStyles = {
  ...avatarImageStyles,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: 600,
  color: "#FFFFFF",
  marginRight: "10px"
}

const AccountTeamMemberForm = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [accountAssignments, setAccountAssignments] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [account, actions] = useAccount();
  const [ui, uiActions] = useDopeUI();

  useEffect(() => {
    getAccountAssignments();
    getTeamMembers();
  }, [account]);

  const getAccountAssignments = async () => {
    if (account && account.id !== 'new') {
      accountAssignmentsApi.query({
        filters: [{field: "account_id", operator: "=", value: account.id}],
        scopes: [{name: "dope_team_assignments"}]
      }).then((response) => {
        const assignments = response.account_assignments;
        setAccountAssignments(assignments);
      });
    }
  }

  const getTeamMembers = async () => {
    userApi.query({scopes: [{name: "dope_team"}], per_page: 1000}).then((response) => {
      setTeamMembers(response.users);
    })
  }

  const handleAddTeamMember = async () => {
    if (accountAssignments.find((assignment) => assignment.user.id === selectedUser)) {
      uiActions.addFlashMessage({ header: "User is already assigned to account", type: "warning" });
      return;
    }
    const assignmentParams = {
      account_id: account.id,
      user_id: selectedUser,
    }

    try {
      await accountApi.postMember(account.id, "add_dope_team_member", assignmentParams);
      uiActions.addFlashMessage({type: "success", header: "Team member added successfully"});
      setSelectedUser(null);
      getAccountAssignments();
    } catch (error) {
      uiActions.addFlashMessage({type: "error", header: "Error adding team member"});
    }
  }

  const removeTeamMember = async (assignment) => {
    try {
      await accountAssignmentsApi.destroy(assignment.id);
      uiActions.addFlashMessage({type: "success", header: "Team member removed successfully"});
      getAccountAssignments();
    } catch (error) {
      uiActions.addFlashMessage({type: "error", header: "Error removing team member"});
    }
  }

  return (
    <div>
      <Grid fluid className="border pad-l pad-r">
        <h2 style={{ fontWeight: 600, fontSize: "20px", color: "#000000"}}>DOPE Team</h2>
        <Col xs={12}>
          <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{width: "80%"}}>
                <SelectPicker
                  data={teamMembers.map((member) => {
                    return {label: `${member.full_name} - ${member.dope_role}`, value: member.id}
                  })}
                  onChange={(value) => setSelectedUser(value)}
                  value={selectedUser}
                  cleanable={false}
                  placement="topStart"
                  style={selectStyles}
                  menuStyle={{ zIndex: 9999, marginBottom: "4px" }}
                />
              </div>

            <div style={{width: "80%"}}>
              <DopeButton
                disabled={!selectedUser}
                props={{
                  buttonClass: "filled-black",
                  label: "Assign Team Member",
                  onClick: handleAddTeamMember,
                  styles: {width: "100%", marginTop: "30px", marginBottom: "8px" },
                }}
              />
            </div>
            </div>
        </Col>
        <Col xs={12}>
          {accountAssignments.map((assignment) => {
            return (
              <div
                key={assignment.id}
                style={{display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid #E5E5E5", padding: "10px", marginBottom: "10px", borderRadius: "8px", justifyContent: "space-between"}}
              >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                  {assignment.user.avatar_url ? (
                    <div>
                      <img src={assignment.user.avatar_url} alt={`${assignment.user.full_name} photo`} style={avatarImageStyles} />
                    </div>
                  ) : (
                    <div className="initial-icon" style={{...userInitialStyles, backgroundColor: assignment.user.profile_color}}>
                      {assignment.user.first_name[0]}{assignment.user.last_name[0]}
                    </div>
                  )}
                  <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                    <div style={{fontSize: "18px", fontWeight: 600, marginRight: "8px"}}>{assignment.user.first_name} {assignment.user.last_name}</div>
                    <div style={{fontSize: "16px", color: "#8793A6"}} className="account-user-role">{assignment.role}</div>
                  </div>

                </div>

                  <DopeButton
                    props={{
                      buttonClass: "text-link",
                      label: "Remove Team Member",
                      onClick: () => removeTeamMember(assignment),
                    }}
                  />
              </div>
            )
          })}
        </Col>
      </Grid>
    </div>
  )
}

export default AccountTeamMemberForm;
