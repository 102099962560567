import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";

const [mediaUploadSlice, asyncActions] = createModelSlice('media_upload', { });

export default mediaUploadSlice.reducer;

const mediaUploadToParams = (mediaUpload, prevMediaUpload) => {
  return {
    ...mediaUpload,
  };
};

const mediaUploadSelector = state => {
  return state.mediaUpload.current;
}

const derivedMediaUploadAttrsSelector = createSelector([mediaUploadSelector], (mediaUpload) => {
  if (!mediaUpload) return {};

  const derivedUpdates = {};

  return derivedUpdates;
});

export function useMediaUpload(mediaUploadId) {
  const dispatch = useDispatch();
  const mediaUpload = useSelector(mediaUploadSelector);
  const derivedMediaUploadAttrs = useSelector(derivedMediaUploadAttrsSelector);
  const prevMediaUpload = useSelector(state => state.mediaUpload.prev);
  const isSaving = useSelector(state => state.mediaUpload.isSaving);

  const actions = wrapAll({
    ...mediaUploadSlice.actions,
    ...asyncActions,
    save: (additionalParams = {}) => {
      const { media, ...extras } = additionalParams;
      const { ...params } = { ...mediaUploadToParams(mediaUpload, prevMediaUpload), ...extras };

      delete params.errors;

      return asyncActions.create({...params, media });
    },
    resetToInitial: mediaUploadSlice.actions.resetToInitial,
  }, dispatch);

  useEffect(() => {
    const shouldGet = mediaUploadId && (!mediaUpload || mediaUpload.id !== mediaUploadId);
    if (shouldGet) {
      actions.get(mediaUploadId);
    }

    return () => {
      actions.resetToInitial();
    };
  }, [mediaUploadId, mediaUpload?.id]);



  return {
    mediaUpload,
    derivedMediaUploadAttrs,
    actions,
    isSaving,
  };
};

