import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  confirmedPurchase: false,
};

export const automationUISlice = createSlice({
  name: 'automationUI',
  initialState,
  reducers: {
    update: (state, { payload: update }) => {
      Object.assign(state, update);
    },
    resetToInitial: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export default automationUISlice.reducer;

export const {
  update,
  resetToInitial,
} = automationUISlice.actions;

export const selectAutomationUI = (state) => state.automationUI;

export function useAutomationUI() {
  const dispatch = useDispatch();
  const automationUI = useSelector(selectAutomationUI);
  const actions = {
    update: (payload) => dispatch(update(payload)),
    resetToInitial: () => dispatch(resetToInitial()),
  };

  return [automationUI, actions];
}
