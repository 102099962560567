import React from "react";

import { DopeFormGroup } from "../ui/DopeForm";
import DopeButton from "../ui/DopeButton";
import DopeDrawer from "../ui/DopeDrawer";

const DesignEditDrawer = ({ open, onClose, design, actions, setEditing, reload, loading }) => {

  if (!design) {
    return null;
  }

  const inputProps = { values: design, onChange: actions.update, errors: design.errors };
  const templateInputProps = { values: design.mail_template, onChange: actions.updateMailTemplate, errors: design.errors };

  const nameInput = { inputType: "text", name: "name", props: { label: "Design Name" } };
  const objectiveTypeInput = {
    inputType: "radio",
    name: 'objective_type',
    props: {
      label: "Mailer Tag",
      options: [
        { label: 'Marketing', value: 'marketing', helpText: '(Brand awareness targeting new customers)' },
        { label: 'Thank You', value: 'thank_you', helpText: '(More reviews & referrals from current jobs)' },
        { label: 'Reactivation', value: 'reactivation', helpText: '(Targeting existing customers)' },
      ],
      defaultValue: design.mail_template?.objective_type || 'marketing',
    },
  };

  const handleSave = async () => {
    try {
      await actions.save();
      reload();
      setEditing(false);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <DopeDrawer
      className="action-drawer"
      open={open}
      onClose={onClose}
      bodyContent={(
        <div>
          <h3 className="header-3 margin-16-b">
            Edit Design Details
          </h3>

          <div className="margin-16-b">
            <div>
              <DopeFormGroup className="margin-16-b" input={nameInput} {...inputProps} />
              <DopeFormGroup className="margin-4-b" input={objectiveTypeInput} {...templateInputProps} />
            </div>

            <div className="help-text">Note: updating “Marketing Tag” will affect any current and future Matchback reports setup.</div>
          </div>


        </div>
      )}
      actions={(
        <>
          <DopeButton
            loading={loading}
            props={{
              buttonClass: "filled-black",
              label: "Save Edits",
              onClick: handleSave,
            }}
          />
        </>
      )}
    />
  );
}

export default DesignEditDrawer;
