import React from "react";
import dopeLogo from "../images/dope-logo.svg";

// TODO: find logo/icon storage location
const DopeLogo = ({ style, onClick, className }) => {
   return (
     <img src={dopeLogo} alt="Dope Logo" style={style} onClick={onClick} className={className} />
    );
}

export default DopeLogo;
