import React from "react";

const FoldedCardIcon = () => {
  return (
    <svg width="34" height="17" viewBox="0 0 34 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.2308 1H3.73077L2 16H24.5L24.8 13.1154M26.2308 1L32 13.1154H24.8M26.2308 1L24.8 13.1154" stroke="black" stroke-width="2"/>
    </svg>
  );
}

export default FoldedCardIcon;
