import React, { useState, useEffect } from "react";
import { orderBy } from "lodash";
import {Grid, Row, Col} from "rsuite";

import DopeButton from "../ui/DopeButton";
import DopeTextInput from "../ui/DopeTextInput";
import Cents from "../ui/Cents";
import { useDopeUI } from "../ui/dopeUISlice";
import authApi from "../auth/authApi";
import DopeApi from "../services/DopeApi";
import { errorsToErrorList } from "../utils/errors";

import './ProductPricing.scss'
import DopeSelect from "../ui/DopeSelect";

const accountApi = new DopeApi("account");
const productsApi = new DopeApi("product");
const productPricingTiersApi = new DopeApi("product_pricing_tier");
const productPricingTierDiscountsApi = new DopeApi("product_pricing_tier_discount");
const productPricingTierAccountDiscountsApi = new DopeApi("product_pricing_tier_account_discount");

const inputStyles = { width: 60, padding: '2px 4px' };

const stripePlans = [
  { label: 'FREEBIE', value: 'freebie' },
  { label: 'LIL\' TASTE', value: 'lil_taste' },
  { label: 'DOPE', value: 'dope' },
  { label: 'DOPER', value: 'doper' },
  { label: 'DOPEST', value: 'dopest' },
];

const ProductAccountPricing = ({ providedAccountId, reload }) => {
  const [dopeUI, uiActions] = useDopeUI();
  const [account, setAccount] = useState({});
  const [accountDiscountTier, setAccountDiscountTier] = useState('');
  const [editing, setEditing] = useState(false);
  const [products, setProducts] = useState([]);
  const [productPricingTiers, setProductPricingTiers] = useState([]);
  const [productPricingTierDiscounts, setProductPricingTierDiscounts] = useState([]);
  const [productPricingTierAccountDiscounts, setProductPricingTierAccountDiscounts] = useState([]);

  const canEdit = authApi.currentUserHasAdminPrivileges();
  const accountId = providedAccountId || authApi.getCurrentAccountId();

  useEffect(() => {
    const accountFilter = {
      field: 'account_id',
      value: accountId,
      operator: '=',
    };

    accountApi.get(accountId).then((account) => {
      setAccount(account)
      setAccountDiscountTier(account.subscription_key)
    });
    productsApi.getMany({ per_page: 1000 }).then(setProducts);
    productPricingTiersApi.getMany({ per_page: 1000 }).then(setProductPricingTiers);
    productPricingTierDiscountsApi.getMany({ per_page: 1000 }).then(setProductPricingTierDiscounts);
    productPricingTierAccountDiscountsApi.getMany({ per_page: 1000, filters: [accountFilter] }).then(setProductPricingTierAccountDiscounts);
  }, []);

  const handleAccountDiscountChange = (id, nextDiscountCents, tierId) => {
    let updated = false;
    let nextProductPricingTierAccountDiscounts = productPricingTierAccountDiscounts.map(discount => {
      if (discount.product_pricing_tier_id === tierId) {
        updated = true;
        return { ...discount, nextDiscount: nextDiscountCents };
      }
      return discount;
    });
    if (!updated) {
      const newAccountDiscount = {
        discount: nextDiscountCents,
        nextDiscount: nextDiscountCents,
        account_id: accountId,
        product_pricing_tier_id: tierId,
      };
      nextProductPricingTierAccountDiscounts = [...productPricingTierAccountDiscounts, newAccountDiscount];
    }
    setProductPricingTierAccountDiscounts(nextProductPricingTierAccountDiscounts);
  };

  const handleTierChange = async () => {
    try {
      const account = await accountApi.update({ id: accountId, product_pricing_discount_key: accountDiscountTier });
      setAccount(account);
      await authApi.fetchMe();
      uiActions.addFlashMessage({
        header: 'Success!',
        body: 'Pricing tier updated',
        type: 'success',
      });
    } catch (errorMessages) {
      uiActions.addFlashMessage({
        header: 'Error!',
        body: errorsToErrorList(errorMessages),
        type: 'error',
      });
    }
  };

  const saveAccountDiscounts = async () => {
    const updatedDiscounts = productPricingTierAccountDiscounts.filter(discount => !!discount.nextDiscount);
    const updates = updatedDiscounts.map(discount => {
      return { id: discount.id, discount: discount.nextDiscount, account_id: discount.account_id, product_pricing_tier_id: discount.product_pricing_tier_id };
    });

    if (updates.length === 0) return;

    try {
      await productPricingTierAccountDiscountsApi.updateMany(updates);
      uiActions.addFlashMessage({
        header: 'Success!',
        body: 'Pricing updated',
        type: 'success',
      });
    } catch (errorMessages) {
      uiActions.addFlashMessage({
        header: 'Error!',
        body: errorsToErrorList(errorMessages),
        type: 'error',
      });
    }
  }

  const handleSave = async () => {
    if (accountDiscountTier && accountDiscountTier.length > 0 && accountDiscountTier !== account.subscription_key) {
      await handleTierChange();
    }
    await saveAccountDiscounts();
    setEditing(false);
    if (reload) reload();
  };

  const handleCancel = () => {
    const nextProductPricingTierAccountDiscounts = productPricingTierAccountDiscounts.map(discount => {
      return { ...discount, nextDiscount: null };
    });
    setProductPricingTierAccountDiscounts(nextProductPricingTierAccountDiscounts);
    setAccountDiscountTier(account.subscription_key);
    setEditing(false);
  };

  const convertTierKeys = (tier) => {
    if (tier === 'lil_taste') return "LIL' TASTE";
    if (!tier) return 'None';
    return tier.toUpperCase();
  };


  const productTierTableHeader = (
    <Row className="margin-16-b space-between">
      <Col xs={5} className="label large bold">Quantity</Col>

      <Col key="discount" xs={5} className="label large bold text-upper">{convertTierKeys(accountDiscountTier)} ↓</Col>
      <Col key="final" xs={5} className="label large bold">Extra ↓</Col>

      <Col xs={5} className="label large bold">Price</Col>

    </Row>
  );

  let buttons;
  if (!canEdit) {
    buttons = null;
  } else if (editing) {
    buttons = (
      <div>
        <DopeButton onClick={handleCancel} className="margin-right">Cancel</DopeButton>
        <DopeButton onClick={handleSave} className="outlined-black">Save</DopeButton>
      </div>
    );
  } else {
    buttons = (
      <div>
        <DopeButton onClick={() => setEditing(true)}>Edit</DopeButton>
      </div>
    );
  }

  let tierAssignment;
  if (!canEdit) {
    tierAssignment = null;
  } else {
    tierAssignment = (
      <div className="flex column">
        <label className="label">Pricing Tier</label>
        <DopeSelect
          options={stripePlans}
          name="subscription_key"
          value={accountDiscountTier}
          onChange={(update, item, value) => setAccountDiscountTier(value)}
          style={{width: 468}}
          disabled={!editing}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="space-between row margin-16-t">
        <div>{tierAssignment}</div>
        <div>{buttons}</div>
      </div>

      <div className="product-pricing-wrapper">
        {
          products.map((product, index) => {
            const pricingTiers = orderBy(productPricingTiers.filter(tier => tier.product_id === product.id), 'minimum_quantity');

            const defaultUnitPrice = (
              <div style={{
                background: "#BCD2E11A",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "row",
                width: "fit-content",
                padding: "4px 8px"
              }}>
                <div style={{fontWeight: 600, fontSize: "14px", color: "#000000"}}>Base Price:&nbsp;</div>
                <div style={{fontWeight: 400, fontSize: "14px", color: "#000000"}}>
                  <Cents>{product.default_unit_price}</Cents>
                </div>
              </div>
            )

            return (
              <div key={product.id} className="margin-tb" style={{
                border: "1px solid #F3F3F3",
                maxWidth: "468px",
                marginRight: "24px",
                padding: "10px 10px 0 10px",
                borderRadius: "8px"
              }}>
                <div className="header-4 margin-bottom" style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}>
                  <div>{product.label}</div>
                  <div>{defaultUnitPrice}</div>
                </div>

                <Grid style={{maxWidth: '468px'}}>
                  {productTierTableHeader}

                  {
                    pricingTiers.map((tier, tierIndex) => {
                      const nextTier = pricingTiers[tierIndex + 1];
                      const nextMinQuantity = nextTier ? nextTier.minimum_quantity - 1 : null;
                      const separator = nextMinQuantity ? ' - ' : '+';

                      const discount = productPricingTierDiscounts.find(discount => discount.product_pricing_tier_id === tier.id && discount.subscription_key === accountDiscountTier);
                      const discountCents = discount?.discount || 0;

                      const accountDiscount = productPricingTierAccountDiscounts.find(discount => discount.product_pricing_tier_id === tier.id);
                      const accountDiscountCents = accountDiscount?.nextDiscount || accountDiscount?.discount || 0;

                      const finalPrice = product.default_unit_price - discountCents - accountDiscountCents;

                      let discountInput, tierDiscount;
                      if (editing) {
                        tierDiscount = (
                          <Col key="discount" xs={5}>
                            <Cents>{discountCents}</Cents>
                          </Col>
                        );
                        discountInput = (
                          <Col key="discount-input" xs={5}>
                            <DopeTextInput
                              type="number"
                              placeholder="0"
                              min={0}
                              style={inputStyles}
                              value={accountDiscountCents}
                              onChange={(update, nextDiscountCents) => {
                                handleAccountDiscountChange(accountDiscount?.id, nextDiscountCents, tier.id);
                              }}
                            />
                          </Col>
                        );
                      } else {
                        tierDiscount = (
                          <Col key="discount" xs={5}>
                            <Cents>{discountCents}</Cents>
                          </Col>
                        );
                        discountInput = (
                          <Col key="discount-input" xs={5}>
                            <Cents>{accountDiscountCents}</Cents>
                          </Col>
                        );
                      }

                      return (
                        <Row key={tier.id} className="margin-bottom"
                             style={{display: "flex", justifyContent: 'space-between'}}>
                          <Col xs={5}>{tier.minimum_quantity}{separator}{nextMinQuantity}</Col>
                          {tierDiscount}
                          {discountInput}
                          <Col xs={5}><Cents>{finalPrice}</Cents></Col>
                        </Row>
                      );
                    })
                  }
                </Grid>

              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default ProductAccountPricing;
