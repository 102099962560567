import React from "react";
import { RadioGroup, Radio } from "rsuite";

const DopeRadio = ({ name, value, onChange, options, defaultValue }) => {
  return (
    <RadioGroup value={value} onChange={nextValue => onChange({ [name]: nextValue })} defaultValue={defaultValue}>
      {options.map((option) => (
        <Radio value={option.value} key={option.value} >
          {option.label} {option.helpText && <span className="color-text-secondary">{option.helpText}</span>}
        </Radio>
      ))}
    </RadioGroup>
  );
};

export default DopeRadio;
