import React from 'react';
import { Form, Button, Stack } from 'rsuite';

const btnStyles = { minWidth: 200 };

const DopeSubmitButton = ({ text, onClickCancel, loading, props }) => {
  const buttonStyles = { ...btnStyles, ...props?.styles}
  return (
    <Form.Group>
      <Stack justifyContent="space-between">
        {onClickCancel && <Button appearance="default" style={btnStyles} onClick={onClickCancel}>Cancel</Button>}

        <Button className={props?.className} type="submit" appearance="primary" style={buttonStyles} loading={loading}>{text}</Button>
      </Stack>
    </Form.Group>
  );
};

export default DopeSubmitButton;
