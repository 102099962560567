import React, {useState} from 'react';
import { Modal } from 'rsuite';

import { useUser } from "../users/userSlice";
import AddTeamMemberForm from "./AddTeamMemberForm";
import ExistingTeamMemberForm from "./ExistingTeamMemberForm";
import DopeButton from "../ui/DopeButton";

const VALID_DOPE_ROLES = [
  "Account Executive",
  "Product Specialist",
  "Sales Rep",
  "Design Team",
  "Product Team",
  "Customer Experience",
]

const roleOptions = VALID_DOPE_ROLES.map(role => {
  return { label: role, value: role}
})

const AddDopeTeamMemberModal = ({ open, userId = 'new', handleClose, reload, setSelectedTeamMember}) => {
  const [addNewUser, setAddNewUser] = useState(false);
  const [addExistingUser, setAddExistingUser] = useState(false);

  const getUser = (id) => {
    setSelectedTeamMember(id);
  }

  const resetModal = () => {
    setAddNewUser(false);
    setAddExistingUser(false);
  }

  const closeModal = () => {
    resetModal();
    handleClose();
  }

  const handleNewUser = () => {
    setAddNewUser(true);
    setAddExistingUser(false);
  }

  const handleExistingUser = () => {
    setAddNewUser(false);
    setAddExistingUser(true);
  }

  return (
    <Modal open={open} onClose={closeModal} >
      <Modal.Header>
        <Modal.Title>Add Dope Team Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        { !addNewUser && !addExistingUser && userId === "new" &&
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", margin: '36px 0'}}>
            <DopeButton
              props={{
                onClick: handleNewUser,
                label: 'Add New User',
                buttonClass: 'elevated',
                styles: { width: "45%" }
              }}
            />

            <DopeButton
              props={{
                onClick: handleExistingUser,
                label: 'Add Existing User',
                buttonClass: 'elevated',
                styles: { width: "45%" }
              }}
            />
          </div>
        }

        {(userId !== "new" || addNewUser) && <AddTeamMemberForm handleClose={closeModal} roleOptions={roleOptions} resetModal={resetModal} reload={reload} userId={userId} />}
        {addExistingUser && <ExistingTeamMemberForm getUser={getUser} resetModal={resetModal} />}
      </Modal.Body>
    </Modal>
  );
}

export default AddDopeTeamMemberModal;

