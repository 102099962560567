import React, { useEffect } from "react";

import { useSweetSpotAnalysis } from "./sweetSpotAnalysisSlice";

import { DopeFormGroup } from "../ui/DopeForm";
import BelongsToSelect from "../ui/DopeSelect";
import DopePlayer from "../ui/DopePlayer";

// TODO: Update video URL
const videoUrl = "https://dopemail-assets.s3.amazonaws.com/Understanding+Matchback+Analysis+%F0%9F%93%8A.mp4"

const nameInput = { inputType: "text", name: "name", props: { label: "Sweet Spot Analysis Name", placeholder: "ex. 2024 Customer List Sweet Spots Analysis" } };

const listInput = {
  inputType: "belongsTo",
  name: "list_id", props: {
  label: "My Lists",
  belongsToModelName: "list",
  placeholder: "Select a list",
  labelKey: "name",
}};

const SweetSpotAnalysisSetup = () => {
  const { sweetSpotAnalysis, actions } = useSweetSpotAnalysis();

  const reportInputProps = {
    values: sweetSpotAnalysis,
    onChange: actions.update,
    errors: sweetSpotAnalysis.errors,
  };

  return (
    <div>
      <div className="page-header">
        <p className="header-text">Sweet Spot Setup</p>
      </div>

      <div className="rs-form split-cols">
        <div>
          <DopeFormGroup input={nameInput} {...reportInputProps} />
          <DopeFormGroup input={listInput} {...reportInputProps} />
        </div>
        <div><DopePlayer url={videoUrl} /></div>
      </div>
    </div>
  );
};

export default SweetSpotAnalysisSetup;
