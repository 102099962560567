import React, {useState, useEffect} from "react";
import {Modal, Grid, Col, Row} from "rsuite";

import DopeApi from "../services/DopeApi";
import DopeButton from "../ui/DopeButton";
import DopeTextInput from "../ui/DopeTextInput";

const creditTransactionApi = new DopeApi("credit_transaction");

const ViewCreditModal = ({ creditId, setCreditId }) => {
  const [credit, setCredit] = useState(null);

  useEffect(() => {
    if (!creditId) return;
    creditTransactionApi.get(creditId).then((response) => {

      setCredit(response);
    });
  }, [creditId]);

  const handleClose = () => {
    setCreditId(null);
    setCredit(null);
  }

  return (
    <Modal open={creditId !== null} onClose={handleClose} size="md" >
      <Modal.Header>
        <Modal.Title>Credit Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid fluid>

          <Col xs={12}>
            <Row style={{marginBottom: "12px"}}>
              <div className="label" style={{marginBottom: "2px"}}>Account</div>
              <DopeTextInput
                name="account_name"
                value={credit?.account_name}
                style={{ width: "348px" }}
                onChange={() => {}}
              />
            </Row>

            <Row style={{marginBottom: "12px"}}>
              <div className="label" style={{marginBottom: "2px"}}>Type of Credit</div>
              <DopeTextInput
                name="product_name"
                value={credit?.product_name}
                style={{ width: "348px" }}
                onChange={() => {}}
              />
            </Row>

            <Row style={{marginBottom: "12px"}}>
              <div className="label" style={{marginBottom: "2px"}}>Amount</div>
              <DopeTextInput
                style={{ width: "348px" }}
                name="amount"
                value={credit?.amount}
                onChange={() => {}}
              />
            </Row>

            <Row>
              <div className="label" style={{marginBottom: "2px"}}>Reason</div>
              <DopeTextInput
                name="credit_reason"
                value={credit?.credit_reason}
                style={{ width: "348px" }}
                onChange={() => {}}
              />
            </Row>

          </Col>

          <Col xs={12}>
            <div className="label" style={{marginBottom: "2px"}}>Notes</div>
            <DopeTextInput
              style={{width: "348px", resize: "none"}}
              name="notes"
              value={credit?.description}
              as="textarea"
              rows={7}
              onChange={() => {}}
            />
            <DopeButton
              props={{
                buttonClass: "filled-black",
                label: "Close",
                onClick: handleClose,
                styles: {width: "100%", marginTop: "24px"}
              }}
            />
          </Col>
        </Grid>
      </Modal.Body>
    </Modal>
  );
}

export default ViewCreditModal;
