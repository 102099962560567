import React, { useEffect, useState } from "react";

import { useCampaign } from "./campaignSlice";
import { useAccount } from "../accounts/accountSlice";

import DopeTabs from "../ui/DopeTabs";
import CampaignDispatchList from "./CampaignDispatchList";
import CampaignMailPieceTable from "./CampaignMailPieceTable";
import {useStripeUI} from "../stripe/stripeUISlice";
import DopeDrawer from "../ui/DopeDrawer";
import InvoiceDrawer from "../stripe/InvoiceDrawer";
import authApi from "../auth/authApi";
import CampaignDetails from "./CampaignDetails";
import GeoFilterInput from "./GeoFilterInput";
import EddmRouteMapInput from "../eddm/EddmRouteMapInput";
import HotLeadDrawer from "./HotLeadDrawer";

const DispatchInvoiceDrawer = () => {
  const [stripeUI, stripeUiActions] = useStripeUI();
  const [account, actions] = useAccount();

  useEffect(() => {
    if (!account) {
      actions.get(authApi.getCurrentAccountId());
    }
  }, []);

  return (
    <DopeDrawer
      className="invoice-drawer"
      open={!!stripeUI.drawerInvoiceId}
      onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
      bodyContent={
        <InvoiceDrawer
          id={stripeUI.drawerInvoiceId}
          account={account}
          onClose={() => stripeUiActions.setDrawerInvoiceId(null)}
        />
      }
    />
  );
};


const CampaignShow = () => {
  const [campaign, campaignActions] = useCampaign();

  const [hotLeadDrawerOpen, setHotLeadDrawerOpen] = useState(false);
  const [dispatchTemplate, setDispatchTemplate] = useState(null);

  const campaignDetails = (
    <div className="pad-t">
      <CampaignDispatchList
        withCancel
        editable={false}
        openDrawer={(index) => {
          setDispatchTemplate(index);
          setHotLeadDrawerOpen(true);
        }}
      />
      <HotLeadDrawer
        open={hotLeadDrawerOpen}
        onClose={(dispatch) => {
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);
        }}
        dispatches={campaign.dispatches}
        index={dispatchTemplate}
        onSave={(dispatch) => {
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);
        }}
        actions={campaignActions}
        editable={false}
      />
    </div>
  );

  const eddmTabs = (
    <DopeTabs>
      <DopeTabs.Tab name="schedule" label="Mailer Schedule">
        {campaignDetails}
      </DopeTabs.Tab>

      <DopeTabs.Tab name="details" label="Campaign Details">
        <EddmRouteMapInput editable={false} />
        <CampaignDetails />
      </DopeTabs.Tab>
    </DopeTabs>
  );

  const campaignTabs = (
    <DopeTabs>
      <DopeTabs.Tab name="schedule" label="Mailer Schedule">
        {campaignDetails}
      </DopeTabs.Tab>

      <DopeTabs.Tab name="mailing" label="Mailing List">
        <CampaignMailPieceTable/>
      </DopeTabs.Tab>

      <DopeTabs.Tab name="details" label="Campaign Details">
        {campaign.list_generation_setting.generation_type === "blitz" && <div className="margin-bottom">
          <GeoFilterInput editable={false}/>
        </div>}
        <CampaignDetails />
      </DopeTabs.Tab>
    </DopeTabs>
  );

  const subheader = {
    list: campaign.list_generation_setting?.starting_list?.name,
    blitz: "Addresses around " + campaign.list_generation_setting.full_address,
    list_blitz: "",
  }

  return (
    <div className="campaign-page-wrapper">
      <div className='campaign-page'>
        <div className="page">
          <div className="campaign-name-header column">
            <span>{campaign.name}</span>
            {campaign.is_automation && <span className="label large blue-grey">Trigger: {campaign.trigger_full_name} ({campaign.trigger_full_address})</span>}
            {!campaign.is_automation && <span className="label large blue-grey">{subheader[campaign.list_generation_setting.generation_type]}</span>}
          </div>

          <div style={{ paddingTop: 20 }}>
            {campaign.is_eddm ? eddmTabs : campaignTabs}
          </div>
        </div>
      </div>

      <DispatchInvoiceDrawer />

    </div>
  );
};

export default CampaignShow;
