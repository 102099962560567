import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';

import FaRegObjectUngroup from '@meronex/icons/fa/FaRegObjectUngroup';

import DopeSelect from "../ui/DopeSelect";

import DefaultShapesSidePanel from './DefaultShapesSidePanel';
import CustomShapesPanel from './CustomShapesPanel';
// import { DopeTabContent } from 'components/DopeNav/DopeTabContent';
// // import existing section, import default tab component
// import { ElementsDefSection } from './DefObjects/index';
// import MyObjectsSidePanel from './Objects/index';
// import { SVGEditorModal } from '../../modals/SVGEditorModal/index';
// import { UploadSVGInput } from './UploadSVGInput';
// import { useSVGLoad } from './useSVGLoad';


const OPTIONS = [
  {
    value: 'default',
    label: 'Default Shapes',
  },
  {
    value: 'uploads',
    label: 'My Uploads',
  }
];

const ShapesSidePanel = {
  name: 'object',
  Tab: (props) => (
    <SectionTab name="Shapes" {...props}>
      <FaRegObjectUngroup icon="new-text-box" />
    </SectionTab>
  ),
  Panel: observer((props) => {
    const DEFAULT = OPTIONS[0];
    // const isAdmin = useSelector(state => state.account.isAdmin);

    const [tab, setTab] = useState(DEFAULT.value);
    // const [loading ,setLoading] = useState(false);
    // const [isOpen ,setOpen] = useState(false);
    // const [data ,setSVGData] = useState(false);

    // const {
    //   open,
    //   toggle,
    //   queryType,
    //   getFileData,
    //   uploadImage,
    //   getBucketKey2,
    //   dataURLtoFile,
    //   submitAdsGlobal,
    //   submitAsPrivate,
    //   handleUploadImage,
    // } = useSVGLoad({ setLoading });

    return (
      <div className='objects-container'>
        <div className='header' style={{ marginBottom: '10px' }}>
          <h2 className='title'>Shapes</h2>
          {/* {tab?.value === 1 || isAdmin
            ? <UploadSVGInput
                open={open}
                toggle={toggle}
                setLoading={setLoading}
                submitAsGlobal={submitAsGlobal}
                submitAsPrivate={submitAsPrivate}
                handleUploadImage={handleUploadImage}
              />
            : null
          } */}
        </div>
        {/* {
          isOpen ? (
            <SVGEditorModal
              data={data}
              isOpen={isOpen}
              toggle={() => {
                setOpen(false);
                setSVGData(null);
              }}
              queryType={queryType}
              setLoading={setLoading}
              getFileData={getFileData}
              uploadImage={uploadImage}
              dataURLtoFile={dataURLtoFile}
              getBucketKey2={getBucketKey2}
            />
          ) : null
        } */}

        <div className='select-wrapper'>
          <DopeSelect
            name='tab'
            value={tab}
            options={OPTIONS}
            defaultValue={DEFAULT.value}
            placeholder=''
            onChange={opt => {
              setTab(opt.tab);
            }}
          />
        </div>

        <div className='tabs-wrapper'>
          {tab === 'default' && <DefaultShapesSidePanel {...props} /> }
          {tab === 'uploads' && <CustomShapesPanel {...props} /> }
        </div>
      </div>
    );
  }),
};

export default ShapesSidePanel;
