import React from "react";

import classNames from "classnames";

import DopeIconCard from "./DopeIconCard";

import "./DopeCardSelect.scss";
import Cannot from "./Cannot";

// TODO this should probs take name and send update instead of value for consistency
const DopeCardSelect = ({ value, options, onSelect, layout = 'vertical' }) => {
  const classes = classNames({ 'dope-card-select': true, [layout]: true });

  return (
    <div className={classes}>
      {options.map((option) => {
        const selected = option.value === value;
        const { can } = option;

        return (
          <div key={option.value}>
            <DopeIconCard
              icon={option.icon}
              header={option.label}
              subheader={option.description}
              selected={selected}
              disabled={option.disabled}
              onClick={() => onSelect(option.value)}
            />

            {can && selected && <Cannot action={can.action} subject={can.subject} data={can.data} cannotDisplay={can.cannotDisplay} />}
          </div>
        );
      })}
    </div>
  );
};

export default DopeCardSelect;
