import React from "react";

const ScissorsIcon = () => {
  return (
    <svg width="27" height="17" viewBox="0 0 27 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.40381 16.5995L5.20738 16.1794C6.69582 15.4033 7.40808 13.7139 7.02456 12.1524C6.92411 11.7324 7.14327 11.2941 7.53593 11.1023L26.3195 2.21731L26.1278 2.17165C23.6896 1.53244 21.1054 1.82465 18.8682 2.98436L1.39952 12.0976C-0.709864 13.2026 -0.362864 16.3895 1.95655 16.91C2.76013 17.0926 3.62762 17.0013 4.40381 16.5995ZM2.24876 15.8051C0.942948 15.5311 0.742054 13.7322 1.92002 13.1112L4.82385 11.5954C5.14346 11.431 5.54525 11.5589 5.70962 11.8785C6.32143 13.0656 5.85572 14.5358 4.66862 15.1567L3.86504 15.5768C3.35368 15.8507 2.78752 15.9146 2.24876 15.8051Z" fill="#D20C0C"/>
      <path d="M26.1371 14.8371L26.3289 14.7915L7.54531 5.90649C7.15265 5.72386 6.92436 5.28554 7.03394 4.85636C7.41746 3.29486 6.7052 1.60553 5.21676 0.829347L4.41318 0.409295C3.62787 -0.00162426 2.76951 -0.0929397 1.96593 0.0896912C-0.353483 0.619321 -0.700482 3.80623 1.40891 4.90202L18.8776 14.0153C21.1057 15.1933 23.699 15.4763 26.1371 14.8371ZM1.9294 3.89755C0.742303 3.2766 0.943198 1.47768 2.25814 1.20374C2.78777 1.09416 3.36306 1.15808 3.88356 1.43203L4.68713 1.85208C5.87423 2.47302 6.33994 3.9432 5.72813 5.1303C5.56376 5.44991 5.16197 5.58688 4.84237 5.41338L1.9294 3.89755Z" fill="#D20C0C"/>
      <path d="M22.5698 8.85763H21.255V8.12711H22.5698V8.85763ZM19.9467 8.85763H18.6319V8.12711H19.9467V8.85763ZM17.3237 8.85763H16.0088V8.12711H17.3237V8.85763Z" fill="#D20C0C"/>
      <path d="M23.604 8.85763H24.9188V8.12711H23.604V8.85763Z" fill="#D20C0C"/>
    </svg>
  );
}

export default ScissorsIcon;


