import React from 'react';
import { useAutomation } from "./automationSlice";
import { AttributeDisplay, AttributesDisplay } from "../ui/AttributeDisplay";
import { FiltersSummary } from "../campaigns/CampaignFiltersSummary";
import { TagList } from "../ui/DopeTags";
import { AudienceItem } from "../campaigns/CampaignAudienceSummary";

const AutomationDetails = () => {
  const { automation, derivedAutomationAttrs, actions } = useAutomation();
  const settings = automation.campaign_template.list_generation_setting;

  const totalContacts = derivedAutomationAttrs.audienceSize
  const totalMailPieces = automation.campaign_template.dispatch_templates.length * totalContacts;

  const excludeList = derivedAutomationAttrs.excludeItems?.map(item => <AudienceItem isExclusion={true} item={item} key={item.key} />);
  const automationDetails = [
    { label: "Automation Type", value: "Marketing Automation" },
    { label: "Sending Type", value: derivedAutomationAttrs.sendingTypeDisplay },
  ]
  if (automation.description) {
    automationDetails.push({ label: "Description", value: automation.description });
  }

  const automationSettings = [
    { label: "Automation Throttle Max", value: derivedAutomationAttrs.throttleDisplay} ,
  ]

  if (settings.generation_type === 'blitz') {
    automationSettings.push({ label: "Max distance", value: settings.max_distance / 1609 + " miles" });
  }

  return (
    <div className="border">
      <div className="flex row drawer-info-box border-bottom">
        <div className="half-width flex column to-center vertical-align border-right">
          <div className="label-large margin-8-b">Contacts per trigger</div>
          <h4 className="header-4">{totalContacts}</h4>
        </div>

        <div className="half-width flex column to-center vertical-align">
          <div className="label-large margin-8-b">Mail pieces per trigger</div>
          <h4 className="header-4">{totalMailPieces}</h4>
        </div>
      </div>

      <div className="pad">
        {excludeList.length > 0 && <AttributeDisplay label="List Generation" value={excludeList}/>}
        <AttributeDisplay label="Automation Details" value={<AttributesDisplay attributes={automationDetails}/>} />
        {Object.keys(settings.data_axle_filters).length > 0 &&
          <AttributeDisplay label="Filters" value={<FiltersSummary filters={settings.data_axle_filters} onRemove={actions.removeDataAxleFilter}/>} />}
        <AttributeDisplay label="Automation Settings" value={<AttributesDisplay attributes={automationSettings} />} />
        {derivedAutomationAttrs.tags.length > 0 && <AttributeDisplay label="Automation Tags" value={<TagList tags={derivedAutomationAttrs.tags}/>}/>}
      </div>
    </div>
  );
}

export default AutomationDetails;
