
import React from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";
import { toDateString } from '../utils/date';

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const initial = {
  date: toDateString(tomorrow),
  status: 'scheduled',
};

const inputs = [
  { inputType: "date", name: "date", props: { label: "Date" } },
  { inputType: "simpleSelect", name: "status", props: {
    label: "Status",
    options: [
      "scheduled",
      "paused",
    ],
  }},
  { inputType: "belongsTo", name: "campaign_id", props: {
    label: "Campaign",
    belongsToModelName: "campaign"
  }},
  { inputType: "belongsTo", name: "mail_template_id", props: {
    label: "Mail Template",
    belongsToModelName: "postcard_template" // TODO need a belongsToPolymorphic
  }},
];

const DispatchForm = (props) => {
  return (
    <ModelForm
      modelName="dispatch"
      initialResource={initial}
      inputs={inputs}
      {...props}
    />
  );
};

const columns = [
  { type: "text", dataKey: "id", label: "ID", sortable: true, filterable: true },
  { type: "text", dataKey: "account_name", label: "Account" },
  { type: "text", dataKey: "campaign_name", label: "Campaign", sortable: true, filterable: true },
  { type: "date", dataKey: "date", label: "Date", sortable: true, filterable: true },
  { type: "text", dataKey: "status", label: "Status", sortable: true, filterable: true },

  { type: 'text', dataKey: 'campaigns.account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
];

const scopeOptions = [
  { name: 'scheduled', label: 'Scheduled' },
  { name: 'future', label: 'Future' },
  { name: 'today', label: 'Today' },
  { name: 'tomorrow', label: 'Tomorrow' },
];

const DispatchManagement = (props) => {
  return (
    <DopeManagement
      modelName="dispatch"
      modelForm={DispatchForm}
      columns={columns}
      scopeOptions={scopeOptions}
      {...props}
    />
  );
};

export default DispatchManagement;
