import React from "react";
import { Icon } from "@rsuite/icons";
import { PiStrategyBold } from "react-icons/pi";

import { useAccount } from "../accounts/accountSlice";
import {MdOutlinePlayCircle, MdAddToDrive} from "react-icons/md";


const StrategyBoard = () => {
  const [account, accountActions] = useAccount();

  return (
    <div className="overview-section">
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "16px"
      }}>
        <h4 className="overview-header">Important Links</h4>
      </div>

      {account.strategy_board_url && <div className="to-do-item" onClick={() => window.open(account.strategy_board_url, "_blank")}>
        <div className="to-do-item-icon">
          <Icon as={PiStrategyBold} style={{color: "#494B4E"}}/>
        </div>
        <div>
          <div className="to-do-item-header">Strategy Board</div>
          <div className="to-do-item-description">Your personalized marketing plan</div>
        </div>
      </div>}

      {account.assets_drive_url && <div className="to-do-item">
        <div className="to-do-item-icon">
          <Icon as={MdAddToDrive}/>
        </div>
        <div>
          <div className="to-do-item-header" onClick={() => window.open(account.assets_drive_url, "_blank")}>Assets
            Drive
          </div>
          <div className="to-do-item-description">All your company assets in one place</div>
        </div>
      </div>}
    </div>
  );
}

export default StrategyBoard;
