import React from "react";
import {Accordion} from "rsuite";
import HandwrittenFontItem from "./HandwrittenFontItem";

const HandwrittenCardDetails = ({design}) => {
  return (
    <>
      <label className="label">Body</label>
      <div className="border pad-8 margin-16-b">
        {design.mail_template.body}
      </div>

      <label className="label">Signature</label>
      <div className="border pad-8 margin-16-b">
        <div>{design.mail_template.signature}</div>
      </div>

      <label className="label">Handwriting Style</label>
      <HandwrittenFontItem {...design.mail_template.font} />
    </>
  );
}

const GoodieTemplateDetails = () => {
  return (
    <>
      <label className="label">Mailer Type</label>
      <div className="border pad-8 margin-16-b">
        Goodies Box
      </div>

      <label className="label">Details</label>
      <div className="border pad-8 margin-16-b">
        <div>Custom Branded Box</div>
        <div>(3) Gourmet Cookies</div>
        <div>Handwritten Thank You</div>
      </div>
    </>
  );
}

const PostcardTemplateDetails = ({design}) => {
  if (!design.dispatch) return (<></>);

  return (
    <>
      <label className="label">Mailer Type</label>
      <div className="border pad-8 margin-16-b">
        Hot Lead
      </div>

      <label className="label">URL</label>
      <div className="border pad-8 margin-16-b">
        {design.dispatch.hot_lead_url}
      </div>

      <label className="label">Phone Number</label>
      <div className="border pad-8 margin-16-b">
        {design.dispatch.hot_lead_phone}
      </div>
    </>
  );
}


const MailTemplateDetails = ({design, templateType, headerText}) => {
  if (templateType === "PostcardTemplate" && design.mail_template.mailer_type !== "hot_lead") return (<></>);

  const templateDetails = {
    "HandwrittenCardTemplate": <HandwrittenCardDetails design={design} />,
    "GoodieTemplate": <GoodieTemplateDetails design={design} />,
    "PostcardTemplate": <PostcardTemplateDetails design={design} />
  }

  return (
    <Accordion>
      <Accordion.Panel header={headerText}>
      {templateDetails[templateType]}
      </Accordion.Panel>
    </Accordion>
  );
}

export default MailTemplateDetails;
