import React, { useState, useEffect } from "react";
import { useSearchParams, useParams } from 'react-router-dom';

import { useIntegrations, withIntegrationsStore } from "./integrationsSlice";

const dataSourceParamToKey = { // normally they are the same but some require no brand mention in url
  levelhighgo: 'gohighlevel',
};

function AutoConnect() {
  const [message, setMessage] = useState('Connecting...');
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  const { dataSource } = useParams();
  const dataSourceKey = dataSourceParamToKey[dataSource] || dataSource;

  const {
    createConnection,
  } = useIntegrations();

  const finish = (err) => {
    if (window.opener && window.opener.__onClose) {
      window.opener.__onClose(err);
      window.close();
    } else {
      window.location.href = '/';
    }
  };

  useEffect(() => {
    if (code) {
      const isPopup = !!window.opener && !!window.opener.__onClose;
      const actionText = isPopup ? 'Closing...' : 'Redirecting...';

      createConnection({ dataSource: dataSourceKey, code })
        .then(({ connection }) => {
          setMessage('Success! ' + actionText);
          setTimeout(() => {
            finish();
          }, 1000);
        })
        .catch(err => {
          setMessage('Error! ' + actionText);
          setTimeout(() => {
            finish(err);
          }, 1000);
        });
    }
  }, [code]);

  return (
    <div>
      {message}
    </div>
  )
}

export default withIntegrationsStore(AutoConnect); // TODO: Do I even need withIntegrationsStore here? just need the api... why i do this? Was API not initing with getters?
