import React from "react";


import { useQuery2 } from "../hooks/useQuery";
import StripeApi from "../stripe/StripeApi";
import { unixTimestampToDateStr } from "../utils/date";
import DopeTable from "../ui/DopeTable";
import DopeButton from "../ui/DopeButton";

const stripeApi = new StripeApi();

const columns = [
  { type: 'text', dataKey: 'id', label: 'Trans. ID' },
  { type: 'date', dataKey: 'created', label: 'Date', dateConverter: unixTimestampToDateStr },
  { type: 'currency', dataKey: 'amount', label: 'Amount' },
  { type: 'text', dataKey: 'charge', label: 'Payment' },
];

const RefundsTable = () => {
  const { tableProps, rest, reload, loading } = useQuery2({
    api: stripeApi,
    initialScopes: [{ name: 'refunds' }],
    initialPerPage: 10,
    extraParams: { route: 'stripe/index_transactions'},
  });

  const nextPage = () => {
    reload({ starting_after: rest.next_cursor });
  };

  const previousPage = () => {
    reload({ ending_before: rest.previous_cursor });
  };

  return (
    <div>
      <DopeTable
        {...tableProps}
        columns={columns}
      />

      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <DopeButton
          disabled={!rest.has_previous || loading}
          props={{
            onClick: previousPage,
            label: "Previous Page",
            buttonClass: "text-link"
          }}
        />
        <DopeButton
          disabled={!rest.has_next || loading}
          props={{
            onClick: nextPage,
            label: "Next Page",
            buttonClass: "text-link"
          }}
        />
      </div>
    </div>
  );
}

export default RefundsTable;
