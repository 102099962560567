import differenceBy from 'lodash/differenceBy';
import pick from 'lodash/pick';

const markRemovedForDestruction = (prev, current, attributeName, attrs = null) => {
  const removed = differenceBy(prev[attributeName], current[attributeName], 'id');
  const destroyItems = removed.map(item => {
    const itemParams = attrs ? pick(item, attrs) : item;
    return { ...itemParams, _destroy: true };
  });
  const currentItems = current[attributeName].map(item => {
    return attrs ? pick(item, attrs) : item;
  });

  return [...currentItems, ...destroyItems];
};

const asNestedDestroyable = (prev, current, attributeName, attrs = null) => {
  const marked = markRemovedForDestruction(prev, current, attributeName, attrs);
  return { [`${attributeName}_attributes`]: marked };
};

export {
  markRemovedForDestruction,
  asNestedDestroyable,
}
