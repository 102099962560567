import React from "react";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import { useAutomation } from "./automationSlice";
import { handleFilterChange } from "../utils/fn";

import DopeListPage from "../ui/DopeListPage";

const api = new DopeApi('mail_piece');

const columns = [
  { type: 'stacked_text', dataKey: ['contact.full_name', 'contact.company_name'], label: 'Name' },
  { type: 'address_text', dataKey: 'contact.full_address', label: 'Address' },
  { type: 'pill', pillTypeKey: 'status', dataKey: 'status', label: 'Status' },
  { type: 'text', dataKey: 'dispatch.date', label: 'Send Date', sortable: true }, // date formatter is thinking dates in zero offset timezone
  { type: 'image_url', dataKey: 'front_image_thumbnail_url', label: '' },
];

const statusOptions = [
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'processing', label: 'Processing' },
  { value: 'duplicate', label: 'Duplicate' },
  { value: 'undeliverable', label: 'Undeliverable' },
  { value: 'sent', label: 'Sent' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'error', label: 'Error' },
];

const AutomationMailPieceTable = () => {
  const { automation } = useAutomation();
  const automationFilter = { field: 'dispatches.campaigns.campaign_template_id', operator: '=', value: automation.campaign_template.id };
  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters }
    = useQuery2({
      api,
      initialFilters: [automationFilter],
      initialSearchScope: "contacts.full_name_and_address",
    });

  const applyStatusFilter = (statuses) => {
    const statusFilter = { filterField: 'mail_pieces.status', operator: 'in', filterCategory: 'Status' };
    handleFilterChange(filters, setFilters, statuses, statusFilter);
  };

  const filterInputs = [
    { title: 'Status', getOptions: () => { return statusOptions }, applyFilter: applyStatusFilter },
  ];

  return (
    <DopeListPage
      columns={columns}
      tableProps={tableProps}
      paginationProps={paginationProps}
      error={error}
      filterInputs={filterInputs}
      onSearchChange={onSearchInput}
      onSearchClear={onSearchClear}
      setFilters={setFilters}
      filters={filters}
    />
  );
};

export default AutomationMailPieceTable;
