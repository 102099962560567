import { useDispatch, useSelector } from "react-redux";
import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import { asNestedDestroyable } from "../services/createModelSelectors";
import DopeApi from '../services/DopeApi';

const api = new DopeApi('list_pull');

const [listPullSlice, asyncActions] = createModelSlice('list_pull', {

});

export default listPullSlice.reducer;


const listPullToParams = (listPull, prevListPull) => {
  const { name, description, contacts_type, query_data, keep_in_sync } = listPull;

  const params = {
    id: listPull.id === 'new' ? null : listPull.id,
    name,
    description,
    contacts_type,
    query_data,
    keep_in_sync,
  };

  return params;
};

export function useListPull() {
  const dispatch = useDispatch();
  const listPullState = useSelector(state => state.listPull);
  const listPull = useSelector(state => state.listPull.current);
  const prevListPull = useSelector(state => state.listPull.prev);

  const actions = wrapAll({
    ...listPullSlice.actions,
    ...asyncActions,
  }, dispatch);

  actions.save = async (additionalParams = {}) => {
    if (additionalParams.preventDefault instanceof Function) {
      console.error("You're passing an event to saveListPull!");
      additionalParams = {};
    }

    const params = listPullToParams(listPull, prevListPull);
    return dispatch(
      asyncActions.save({ ...params, ...additionalParams })
    );
  };

  return {
    ...listPullState,
    listPull,
    actions,
  };
}
