import React, { useState } from "react";

import map from "lodash/map";

import useObjectState from "../../hooks/useObjectState";
import DopeApi from "../../services/DopeApi";
import DopeForm from "../../ui/DopeForm";
import { humanize } from "../../utils/railsNames";

import { Drawer } from "rsuite";

const automationRunApi = new DopeApi('automation_run');

const allActionParamNames = ['first_name', 'last_name', 'address_1', 'address_2', 'city', 'state', 'zip', 'zip_4', 'phone_number', 'country'];

const Fix = ({ id, rowData, reload }) => {
  const [open, setOpen] = useState(false);
  const [retrying, setRetrying] = useState(false);
  const [nextRowData, updateNextRowData] = useObjectState(rowData.action_params);

  const actionParamInputConfigs = map(allActionParamNames, (name) => {
    return {
      inputType: 'text',
      name,
      props: {
        label: humanize(name),
      },
    };
  });

  const handleRetry = () => {
    setRetrying(true);
    automationRunApi.update({ id, action_params: nextRowData, actions: [{ name: 'run', args: [false] }] })
      .then(automationRun => {
        setRetrying(false);
        console.log(automationRun);
        if (automationRun.status === 'invalid') { // TODO better handle errors here
          // TODO set status message as error if status invalid or error
        } else {
          setOpen(false);
          reload();
        }
      })
      .catch(() => {
        setRetrying(false);
        // TODO
      });
  };


  return (
    <>
      <span className="link-label" onClick={() => setOpen(true)}>
        Fix
      </span>

      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        size="sm"
        placement="right"
      >
        <Drawer.Header>
          <Drawer.Title>Update Trigger Params And/Or Retry</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <DopeForm
            onSubmit={handleRetry}
            submitText="Retry"
            inputs={actionParamInputConfigs}
            values={nextRowData}
            loading={retrying}
            onChange={updateNextRowData}
          />
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default Fix;
