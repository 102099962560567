import { SelectPicker } from 'rsuite';

const DopeSelect = ({ name, options, onChange, valueKey = "value", ...rest }) => {

  const handleChange = (value) => {
    const item = options.find(o => o[valueKey] === value);
    onChange({ [name]: value }, item, value);
  };

  return (
    <SelectPicker
      data={options}
      onChange={handleChange}
      cleanable={false}
      style={{ minWidth: 200, width: '100%' }}
      menuStyle={{ zIndex: 9999, marginTop: "8px" }}
      valueKey={valueKey}
      {...rest}
    />
  );
};

export default DopeSelect;
