import React, { useEffect, useState } from "react";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import DopeDrawer from "../ui/DopeDrawer";
import MailPieceDrawer from "../mailPieces/MailPieceDrawer";
import { handleFilterChange } from "../utils/fn";
import { useMailPiece } from "../mailPieces/mailPieceSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ScansDrawer from "./ScansDrawer";
import { Modal } from "rsuite";
import DopePlayer from "../ui/DopePlayer";
import Cannot from "../ui/Cannot";
import DopeButton from "../ui/DopeButton";

const api = new DopeApi('mail_pieces');
const campaignApi = new DopeApi("campaign");

const columns = [
  { type: "stacked_text", dataKey: ["contact.full_name", "contact.full_address"], label: "Name", flexGrow: 2, sortable: true },
  { type: "event_time", dataKey: "last_scanned_at", label: "Latest Scan", sortable: true, flexGrow: 2 },
  { type: "stacked_text", dataKey: ["campaign_name", "mail_template_name"], label: "Campaign", flexGrow: 3, sortable: false },
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'hot_lead', flexGrow: 1 }
];

const HotLeadsTable = () => {
  const [infoVideoOpen, setInfoVideoOpen] = useState(false);

  const { id } = useParams();

  const [mailPiece, actions] = useMailPiece(id);

  const location = useLocation();
  const navigate = useNavigate();



  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, setScopes, scopes, reload }
  = useQuery2({
    api,
    initialFilters: [],
    initialScopes: [{ name: "scanned"}],
    initialSearchScope: "contacts.full_name_and_address",
  });


  const applyCampaignIdFilter = (campaigns) => {
    const filterObject = {
      filterField: "dispatches.campaign_id",
      operator: "in",
      filterCategory: "Campaign",
    }
    handleFilterChange(filters, setFilters, campaigns, filterObject)
  }

  const filterInputs = [
    { title: "Campaign",
      getOptions: (name = "") => campaignApi.getOptions({
        valueKey: "id",
        labelKey: "name",
        scopes: name.length > 0 ? [{ name: "search_by_name", args: name }] : []
      }),
      applyFilter: applyCampaignIdFilter,
      searchable: true
    },
  ];

  const openScansDrawer = !!id && location.pathname.includes('scans');

  const cannotHotLeadDisplay = (
    <DopeButton
      props={{
        label: "Learn More About Hot Leads",
        buttonClass: "text-link",
        onClick: () => setInfoVideoOpen(true),
      }}
    />
  );

  return (
    <>
      <Cannot action="create" subject="PostcardTemplate" data={{ mailer_type: "hot_lead" }} cannotDisplay={{ display: cannotHotLeadDisplay }} />

      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={filterInputs}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
      />

      <DopeDrawer
         open={!!id && !location.pathname.includes('scans')}
         onClose={() => navigate("/hot_leads")}
         bodyContent={<MailPieceDrawer id={id} />}
      />

      {openScansDrawer && <ScansDrawer
        open={openScansDrawer}
        scannable={mailPiece}
        scannableId={id}
        scope={"for_hot_lead"}
        onClose={() => {
          actions.resetToInitial();
          navigate('/hot_leads');
        }}
      />}

      {infoVideoOpen && <Modal open={infoVideoOpen} onClose={() => setInfoVideoOpen(false)} size="md">
        <Modal.Body>
          <div className="to-center">
            <DopePlayer url="" />
          </div>
        </Modal.Body>
      </Modal>}
    </>
  );
};

export default HotLeadsTable;
