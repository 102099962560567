import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, Outlet, useNavigate } from 'react-router-dom';
import { useAgency } from './agencySlice';
import useDopePageTabs from '../ui/useDopePageTabs';
import DopePageLoader from '../ui/DopePageLoader';
import DopeButton from "../ui/DopeButton";

import { objectURLToFile  } from "../utils/design";

import './Agency.scss';

const AgencyPages = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();



  return (
    <div className='campaign-page-wrapper'>
      {/* {!isDesigner && <BackLink className='margin' /> } */}

      <div className='campaign-page'>

        <div>
          {/* <PageTabs /> */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}

const Agency = () => {
  const { id } = useParams();
  const location = useLocation();

  const { agency, actions } = useAgency();

  useEffect(() => {
    if (!agency && id) {
      actions.get(id + location.search);
    }

    return actions.resetToInitial;
  }, []);


  return <AgencyPages />;
};

export default Agency;
