import { Toggle } from "rsuite";


const DopeToggle = ({ name, onChange, checkedText, uncheckedText, ...rest }) => {

  return (
    <Toggle
      checkedChildren={checkedText}
      unCheckedChildren={uncheckedText}
      checked={rest.value}
      onChange={value => onChange({ [name]: value })}
      {...rest}
    />
  );
};

export default DopeToggle;