
import React from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const initial = {
  status: 'active',
};

const inputs = [
  { inputType: "simpleSelect", name: "status", props: {
    label: "Status",
    options: [
      "scheduled",
      "processing",
      "sent",
      "delivered",
      "contact_removed_from_campaign_list",
      "list_removed_from_campaign",
      "dispatch_removed_from_campaign",
      "campaign_deleted",
    ],
  }},
];

const MailPieceForm = (props) => {
  return (
    <ModelForm
      modelName="mail_piece"
      initialResource={initial}
      inputs={inputs}
      {...props}
    />
  );
};

const columns = [
  { type: "text", dataKey: "id", label: "ID", sortable: true, filterable: true },
  { type: "text", dataKey: "account_name", label: "Account" },
  { type: "text", dataKey: "campaign_name", label: "Campaign" },
  { type: "text", dataKey: "mail_template_name", label: "Mail Template" },
  { type: "text", dataKey: "dispatch.date", label: "Date" },
  { type: "text", dataKey: "contact.full_name", label: "Contact Name" },
  { type: "text", dataKey: "contact.full_address", label: "Contact Address" },
  { type: "text", dataKey: "status", label: "Status", sortable: true, filterable: true },

  { type: 'text', dataKey: 'dispatches.campaigns.account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
  { type: 'text', dataKey: 'dispatches.campaign_id', label: 'Campaign', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'campaign' } } },
  { type: "date", dataKey: "dispatches.date", label: "Date", filterable: true, hidden: true },
];

const scopeOptions = [
  { name: 'future', label: 'Future' },
  { name: 'today', label: 'Today' },
  { name: 'tomorrow', label: 'Tomorrow' },
  { name: 'processing', label: 'Processing' },
  { name: 'ready_to_process', label: 'Ready to Process' },
  { name: 'expired', label: 'Expired' },
];

const MailPieceManagement = (props) => {
  return (
    <DopeManagement
      modelName="mail_piece"
      modelForm={MailPieceForm}
      columns={columns}
      scopeOptions={scopeOptions}
      disableCreate={true}
      {...props}
    />
  );
};

export default MailPieceManagement;
