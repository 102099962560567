import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import DopeApi from "../services/DopeApi";

const designApi = new DopeApi('design');

export const DesignPreview = ({
  design,
  onClick,
  selected
}) => {
  return (
    <div
      onClick={onClick}
      className={selected ? 'template-preview-wrapper active' :'template-preview-wrapper'}
    >
      <div className='image-wrapper'>
        <img src={design.mail_template.front_image_url} alt='Postcard Front' />
        <img src={design.mail_template.back_image_url} alt='Postcard Back' />
      </div>
    </div>
  );
};


const CustomTemplatesPanel = observer(({ store }) => {
 const [designs, setDesigns] = useState([]);

  useEffect(() => {
    designApi.query({
      per_page: 25,
      filters: [{ field: "mail_template_format", operator: "=", value: 'json' }]
    })
      .then(data => {
        setDesigns(data.designs);
      })
      .catch(error => console.log(error));
  }, []);

  const setTemplate = async (template) => {
    try {
      store.loadJSON(template);

      await store.waitLoading();
    } catch (err) {
      console.error('Error', err);
    }
  }


  return (
    <div className='templates-tab-container'>
      <div className='templates-list'>
        {designs.length === 0 && <div className='no-templates'>You don’t have any templates yet.</div>}
        {designs.length > 0 && designs.map(design => <DesignPreview key={design.id} design={design} onClick={() => setTemplate(design.mail_template.json_template) }/>)}
      </div>
    </div>
  );
});

export default CustomTemplatesPanel;
