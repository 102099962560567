import React from 'react';
import ModelForm from "../ui/ModelForm";

const initial = {
  account_id: null,
  name: '',
  created_by: '',
  starting_address: '',
};

const inputs = [
  {
    inputType: 'belongsTo',
    name: 'account_id',
    props: {
      label: 'Account',
      labelKey: 'name',
      belongsToModelName: 'account',
    }
  },
  {
    inputType: 'text',
    name: 'name',
    props: {
      label: 'Name',
    }
  },
  {
    inputType: 'text',
    name: 'data_source',
    props: {
      label: 'Data Source',
    }
  },
];

const ListDetailsForm = (props) => {
  return (
    <ModelForm
      modelName="list"
      initialResource={initial}
      inputs={inputs}
      {...props}
    />
  );
};

export default ListDetailsForm;
