import React from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import RecentActivityIcon from "./RecentActivityIcon";
import RelativeTime from "../ui/RelativeTime";
import { timeSince } from "../utils/date";
import { humanize } from "../utils/railsNames";

// TODO figure this out! Dont have names... dont have a good way to show multiple updates, dont have way to show json updates
// TODO clean up scss on this

const changeKeyToLabelMap = {
  subscription_data: "Trigger",
  0: "#1",
  1: "#2",
  2: "#3",
  3: "#4",
  4: "#5",
  5: "#6",
  6: "#7",
  7: "#8",
  8: "#9",
  9: "#10",
};

const formatChangeKey = changeKey => {
  const key = changeKeyToLabelMap[changeKey] || changeKey;
  return humanize(key);
};

const skipKeys = ["updated_at", "created_at", "id", "valueSource", "field_to_type_map", "not", "historical_id", "account_id", "discarded_at", "delayed_job_id", "stripe_subscription"];

const previousChanges = (before, after) => {
  const changes = {};

  for (const key in ({ ...before, ...after })) {
    if (!_.isEqual(before[key], after[key]) && !skipKeys.includes(key)) {
      changes[key] = [before[key], after[key]];
    }
  }

  return changes;
};

const attributesToChanges = (attributes) => {
  const changes = {};

  for (const key in attributes) {
    if (skipKeys.includes(key)) {
      continue;
    }
    changes[key] = [undefined, attributes[key]];
  }

  return changes;
};


const ActivityHeader = ({ activity }) => {
  const { historyable_type, historyable, action, created_at } = activity;
  const relativeTime = <RelativeTime time={created_at} />;
  return (
    <div>
      {humanize(historyable_type)} <span className="link">{historyable.name}</span> was {action}<span className="recent-activity-date">{relativeTime}</span>
    </div>
  );
};

const valueDisplay = value => {
  if (_.isString(value)) {
    return `"${value}"`;
  } else if (_.isDate(value)) {
    return value.toLocaleString();
  } else {
    return value;
  }
};

const UpdateListItem = ({ activity, changeKey }) => {
  const wasValue = activity.data[changeKey][0];
  const isValue = activity.data[changeKey][1];
  const isObject = _.isObject(wasValue) || _.isObject(isValue);
  const wasDisplay = _.isUndefined(wasValue) ? "" : `${valueDisplay(wasValue)} →`;
  const isDisplay = _.isUndefined(isValue) ? "removed" : `${valueDisplay(isValue)}`;

  let content = `${formatChangeKey(changeKey)}: ${wasDisplay} ${isDisplay}`;
  if (isObject) {
    const changes = previousChanges(wasValue || {}, isValue || {});
    if (_.isEmpty(changes)) {
      return null;
    }

    let updateText = "changed";
    if (_.isEmpty(wasValue)) {
      updateText = "was added";
    } else if (_.isEmpty(isValue)) {
      updateText = "was removed";
    }

    const subActivity = {
      historyable_type: activity.historyable_type,
      data: changes
    };

    content = (
      <div>
        <div>{`${formatChangeKey(changeKey)} ${updateText}`}</div>
        <UpdateList activity={subActivity} withName={false} />
      </div>
    );
  }

  return <div key={changeKey} className="recent-activity-item-super">&nbsp;&nbsp;&nbsp;&nbsp;{content}</div>;
};

const UpdateList = ({ activity, withName = true }) => {
  const changeKeys = Object.keys(activity.data);

  let name = null;
  if (withName) {
    name = <ActivityHeader activity={activity} />;
  }

  const updateList = (
    <div className="recent-activity-update-list">
      {name}
      {changeKeys.map(changeKey => {
        return <UpdateListItem key={changeKey} activity={activity} changeKey={changeKey} />;
      })}
    </div>
  );

  return updateList;
};

const RecentActivityText = ({ activity, withDetails }) => {
  const navigate = useNavigate();

  const relativeTime = <RelativeTime time={activity.created_at} />;

  const CreatedUpdatedComponent = withDetails ? UpdateList : ActivityHeader;

  let text;
  switch (activity.action) {
    case "updated":
      text = <CreatedUpdatedComponent activity={activity} />;
      break;
    case "approval":
      text = (
        <div>
          <span>{activity.historyable_type} </span>
          <span className="link"> {activity.historyable.name} </span>
          <span> was approved </span>
          <span className="recent-activity-date">{relativeTime}</span>
        </div>
      );
      break;
    case "scheduled":
      const route = `/${activity.historyable_type.toLowerCase()}s/${activity.historyable.id}/show`;
      text = <div className="recent-activity-body-text">
        <span> {activity.historyable_type} </span>
        <span
          className="link"
          onClick={() => navigate(route)}>
          {activity.historyable.name}
        </span>
        <span> was scheduled</span>
        <span className="recent-activity-date">{relativeTime}</span>
      </div>
      break;
    case "Design":
      text = `${activity.historyable_type} ${activity.action}`;
      break;
    case "created":
      const createActivity = {
        ...activity,
        data: attributesToChanges(activity.data),
      };
      text = <CreatedUpdatedComponent activity={createActivity} />;
      break;
    default:
      text = <ActivityHeader activity={activity} />;
  }

  return text
}

const RecentActivityItem = ({ activity, withDetails }) => {
  const { historyable_type, creator } = activity;
  const { profile_color, first_name, last_name } = creator;
  const userIconStyles = { backgroundColor: profile_color };

  return (
    <div className="recent-activity-item">

      <div className="flex">
        <RecentActivityIcon activityType={historyable_type} />

        <div className="recent-activity-item-description">
          <RecentActivityText activity={activity} withDetails={withDetails} />
        </div>
      </div>

      <div className="vertical-align pad-sm-t">
        <div className="recent-activity-creator-initials" style={userIconStyles}>
          {first_name[0]}{last_name[0]}
        </div>
        <div className="recent-activity-creator-name">
          {first_name} {last_name[0]}
        </div>
      </div>

    </div>
  );
}

export default RecentActivityItem;
