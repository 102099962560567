import React, { useState } from "react";
import {Col, Input, Row, Toggle} from "rsuite";

const additionalInputStyles = {
  width: "100%",
  fontSize: "14px",
  fontWeight: "400",
  color: "#000000"
}

const AdditionalField = ({ index, setCustomFields, inputStyles, columnStyles }) => {
  const [fieldHeader, setFieldHeader] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [active, setActive] = useState(false);

  const handleDataToggle = (value) => {
    setActive(value);
    if (value) {
      setCustomFields((prev) => {
        return {
          ...prev,
          [index]: {[fieldHeader]: fieldValue}
        }
      })
    } else {
      setCustomFields((prev) => {
        const newFields = {...prev};
        delete newFields[index];
        return newFields;
      })
    }
  }

  return (
    <Row style={{marginBottom: "10px", display: "flex", alignItems: "center"}} >
      <Col xs={7} style={columnStyles}>
        <Input
          style={inputStyles}
          value={fieldHeader}
          onChange={(value) => setFieldHeader(value)}
        />
      </Col>
      <Col xs={6} style={columnStyles}>
        <div style={{ fontWeight: 400, fontSize: "14px", color: "#000000"}}>
          <Input
            style={additionalInputStyles}
            value={fieldValue}
            onChange={(value) => setFieldValue(value)}
          />
        </div>
      </Col>
      <Col xs={8} style={columnStyles}>
        <Input
          value={fieldHeader}
          style={{width: "265px"}}
          disabled={true}
        />

      </Col>
      <Col xs={3} style={columnStyles}>
        <Toggle
          onChange={(value) => handleDataToggle(value) }
          checked={active}
        />
      </Col>
    </Row>
  )
}

export default AdditionalField;
