import React, { useState } from "react";
import { Accordion } from "rsuite";
import DopeAccordionHeader from "../ui/DopeAccordionHeader";

const AdditionalCampaignOptions = ({ additionalRulesApplied, types = null, panelBody, settings, header = "Additional campaign options" }) => {
  const defaultKey = additionalRulesApplied ? 1 : 0;
  const [activeKey, setActiveKey] = useState(defaultKey);
  //
  if (types && !types.some(type => type === settings.generation_type)) return null;
  const isOpen = activeKey === 1;

  const handleAccordionChange = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };

  return (
    <Accordion className="campaign-accordion" activeKey={activeKey} onSelect={handleAccordionChange}>
      <Accordion.Panel header={<DopeAccordionHeader open={isOpen} header={header} />} caretAs={() => <></>} eventKey={1} >
        {panelBody}
      </Accordion.Panel>
    </Accordion>
  );
}

export default AdditionalCampaignOptions;
