import React from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const initialListGeneration = {
  list_generation_setting_id: null,
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip: '',
  zip4: '',
  country: '',
};

const listGenerationInputs = [
  {
    inputType: 'belongsTo',
    name: 'list_generation_setting_id',
    props: {
      label: 'Settings',
      labelKey: 'id',
      belongsToModelName: 'list_generation_setting',
    }
  },
  {
    inputType: 'text',
    name: 'address_1',
    props: {
      label: 'Address 1',
    }
  },
  {
    inputType: 'text',
    name: 'address_2',
    props: {
      label: 'Address 2',
    }
  },
  {
    inputType: 'text',
    name: 'city',
    props: {
      label: 'City',
    }
  },
  {
    inputType: 'text',
    name: 'state',
    props: {
      label: 'State',
    }
  },
  {
    inputType: 'text',
    name: 'zip',
    props: {
      label: 'Zip',
    }
  },
  {
    inputType: 'text',
    name: 'zip_4',
    props: {
      label: 'Zip 4',
    }
  },
  {
    inputType: 'text',
    name: 'country',
    props: {
      label: 'Country',
    }
  }
];

const ListsGenerationForm = (props) => {
  return (
    <ModelForm
      modelName="list_generation"
      initialResource={initialListGeneration}
      inputs={listGenerationInputs}
      {...props}
    />
  );
};

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, filterable: true },
  { type: 'text', dataKey: 'account_name', label: 'Account' },
  { type: 'text', dataKey: 'list_name', label: 'List Name' },
  { type: 'text', dataKey: 'full_address', label: 'Starting Address' },

  { type: 'text', dataKey: 'list_generation_settings.account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
];

const scopeOptions = [];

function ListGenerationManagement() {
  return (
    <DopeManagement
      modelName="list_generation"
      modelForm={ListsGenerationForm}
      columns={columns}
      scopeOptions={scopeOptions}
    />
  );
}

export default ListGenerationManagement;
