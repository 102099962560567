import React, { useEffect, useState } from 'react';

import DopeApi from '../services/DopeApi';
import authApi from '../auth/authApi';

import CreditCardLogo from "../ui/CreditCardLogo";
import DopeLoader from "../ui/DopeLoader";
import DopeButton from '../ui/DopeButton';

import './Stripe.scss';

const accountApi = new DopeApi('account');

const StripeCard = ({ card, onClickUpdate }) => {
  const updateButton = onClickUpdate && (
    <div className="stripe-card-update">
      <DopeButton
        onClick={onClickUpdate}
        className='outlined-black'
      >Update</DopeButton>
    </div>
  );

  let cardDisplay;
  if (card) {
    const { brand, last4, exp_month, exp_year } = card;
    cardDisplay = (
      <>
        <span className="stripe-card-brand"><CreditCardLogo brand={brand} /></span>
        <div>
          <div className="stripe-card-last4">{brand} **{last4}</div>
          <div className="stripe-card-expiry">EXPIRES ON {exp_month}/{exp_year}</div>
        </div>
      </>
    );
  } else {
    cardDisplay = <span>No Card Found</span>
  }

  return (
    <div className="stripe-card-item">
      {cardDisplay}
      {updateButton}
    </div>
  );
};

const loaderStyle = {
  minHeight: 50,
};

const StripeDefaultCardDisplay = ({
  onClickUpdate,
  onLoad = () => {}
}) => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    accountApi.fetchMember(authApi.getCurrentAccountId(), 'stripe_card_options').then(cards => {
      setCards(cards);

      if (typeof onLoad === 'function') {
        onLoad({
          hasCards: cards.length > 0,
          hasDefaultCard: cards.some(card => card.is_default),
        });
      }

      setLoading(false);
    });
  }, []);

  if (loading) {
    return <DopeLoader style={loaderStyle} />;
  }

  const selectedCard = cards.find(card => card.is_default) || cards[0];

  return (<StripeCard card={selectedCard} onClickUpdate={onClickUpdate} />);
};

export default StripeDefaultCardDisplay;
