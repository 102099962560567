import React from "react";

import { useDesign } from "./designSlice";
import { TagDisplayList } from "../ui/DopeTags";
import HandwrittenCardBodyPreview from "./HandwrittenCardBodyPreview";

const HandwrittenCardReview = () => {
  const { design, actions } = useDesign();

  return (
    <div className="handwritten-card-form-wrapper">
      <div className="page-header">
        <p className="header-text">Let's Review!</p>
        <p className="help-text">Review your handwritten card and make sure everything looks right.</p>
      </div>

      <div className="handwritten-card-review">

        <div className="handwritten-card-review-header border-bottom">
          <h4>{design.name}</h4>
          <TagDisplayList
            tags={design.taggings.map(tagging => tagging.tag)}
          />
        </div>

        <h6>Piece Details</h6>
        <div className="handwritten-card-review-details">
          <div>
            <label>Front</label>
            <div className="handwritten-card-design-preview">
              <img src={design.mail_template.cover_image || design.mail_template.cover_image_url} alt="Front of Card" />
            </div>
          </div>
          <div>
            <label>Back</label>
            <HandwrittenCardBodyPreview
              header={design.mail_template.header_image || design.mail_template.header_image_url}
              footer={design.mail_template.footer_image || design.mail_template.footer_image_url}
              font={design.mail_template.font}
              body={design.mail_template.body}
              signature={design.mail_template.signature}
              limit={500}
            />
          </div>
        </div>
        <div className="handwritten-card-review-summary border-bottom">
          <div>
            <h6>Return Address</h6>
            <p>{design.mail_template.sender_name || ''}</p>
            <p>{design.mail_template.address_1 || ''} {design.mail_template.address_2 || ''}</p>
            <p>{design.mail_template.city || ''}, {design.mail_template.state || ''} {design.mail_template.zip || ''}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HandwrittenCardReview;
