import React, { useState } from 'react';
import { Form, SelectPicker, Input, Button, ButtonGroup, IconButton, Toggle, Stack, Loader } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';
import PlusIcon from '@rsuite/icons/Plus';
import ReloadIcon from '@rsuite/icons/Reload';
import { DopeInput } from './DopeForm';

import { useObjectState } from "../hooks/useObjectState";

const OPERATORS = [
  { label: 'Equals', value: '=' },
  { label: 'Not Equals', value: '!=' },
  { label: 'Contains', value: 'ilike' },
  { label: 'Includes', value: 'in' },
  { label: 'Greater than', value: '>' },
  { label: 'Less than', value: '<' },
];

const displayStyles = { marginRight: 5 };

const DopeFilterDisplay = ({ filter, onRemoveFilter, onEditFilter, disabled }) => {
  const { field, operator, value } = filter;

  return (
    <ButtonGroup style={displayStyles}>
      <IconButton
        icon={<CloseIcon />}
        onClick={onRemoveFilter}
        color="blue"
        appearance='primary'
        disabled={disabled}
      />
      <Button disabled={disabled} onClick={onEditFilter} color="blue" appearance='primary'>
        {field} {operator} {value}
      </Button>
    </ButtonGroup>
  );
}


const formStyle = { display: 'inline-block' };
const inputStyle = { marginBottom: 0, marginRight: 5, width: 150 };
const valueInputStyle = { ...inputStyle, width: 300 };
const buttonStyle = { marginRight: 5, marginBottom: 0 };

const DopeFilterForm = ({ fields, onApplyFilter, onCancelEdit, filter }) => {
  const [nextFilter, updateFilter] = useObjectState(filter);

  const { field, operator, value } = nextFilter;

  const selectedField = fields.find(f => f.value === field);
  const fieldInput = selectedField?.input || { inputType: 'text', props: { placeholder: 'Enter Value' } };

  const disabled = !field || !operator || !value;

  return (
    <Form layout="inline" style={formStyle}>
      <SelectPicker
        style={inputStyle}
        data={fields}
        placeholder="Select Field"
        onChange={field => updateFilter({ field })}
        value={field}
      />

      <SelectPicker
        style={inputStyle}
        data={OPERATORS}
        placeholder="Select Operator"
        onChange={operator => updateFilter({ operator })}
        value={operator}
      />

      <Form.Group style={valueInputStyle}>
        <DopeInput
          inputType={fieldInput.inputType}
          name="value"
          onChange={updateFilter}
          value={value}
          {...fieldInput.props}
        />
      </Form.Group>

      <Button
        style={buttonStyle}
        onClick={() => onApplyFilter(nextFilter)}
        disabled={disabled}
      >
        Apply
      </Button>

      <Button
        style={buttonStyle}
        onClick={onCancelEdit}
      >
        Cancel
      </Button>
    </Form>
  );
}

const filterWrapperStyles = { paddingBottom: 20, borderBottom: '1px solid #ccc' };
const scopeWrapperStyles = { paddingBottom: 20, paddingTop: 20 };
const filterBarLabelStyles = { width: 100, display: 'inline-block' };
const toggleStyles = { marginRight: 5 };

const DopeFilterBar = ({ fields, onChangeFilters, scopeOptions, onChangeScopes, loading, reload }) => {
  const [filters, setFilters] = useState([]);
  const [scopes, setScopes] = useState([]); // TODO: useCollectionState?
  const [editingIndex, setEditingIndex] = useState(null);

  const addNewFilter = () => {
    const newFilter = { field: null, operator: null, value: '' };
    const nextFilters = [...filters, newFilter];
    setFilters(nextFilters);
    setEditingIndex(nextFilters.length - 1);
  };

  const removeFilter = (index) => {
    const nextFilters = [...filters];
    nextFilters.splice(index, 1);
    setFilters(nextFilters);
    onChangeFilters(nextFilters);
  };

  const updateFilter = (index, filter) => {
    const nextFilters = [...filters];
    nextFilters[index] = filter;
    setFilters(nextFilters);
    setEditingIndex(null);
    onChangeFilters(nextFilters);
  };

  const onCancelEdit = () => {
    const nextFilters = filters.filter(filter => filter.field && filter.operator && filter.value);
    setFilters(nextFilters);
    setEditingIndex(null);
  };

  const addScope = (scope) => {
    const nextScopes = [...scopes, scope];
    setScopes(nextScopes);
    onChangeScopes(nextScopes);
  };

  const removeScope = (scope) => {
    const nextScopes = scopes.filter(s => s.name !== scope.name);
    setScopes(nextScopes);
    onChangeScopes(nextScopes);
  };

  return (
    <div className={loading ? 'Disabled' : ''}>

      <div style={filterWrapperStyles}>

        <span style={filterBarLabelStyles}>Filters: </span>

        {filters.map((filter, index) => {
          const isEditing = editingIndex === index;
          return isEditing ? (
              <DopeFilterForm
                key={index}
                fields={fields}
                filter={filter}
                onApplyFilter={nextFilter => updateFilter(index, nextFilter)}
                onCancelEdit={onCancelEdit}
              />
            ) : (
              <DopeFilterDisplay
                key={index}
                filter={filter}
                onRemoveFilter={() => removeFilter(index)}
                onEditFilter={() => setEditingIndex(index)}
                disabled={editingIndex !== null}
              />
            );
        })}

        {editingIndex === null && (<IconButton appearance="ghost" circle icon={<PlusIcon />} onClick={addNewFilter} />)}

      </div>

      <Stack style={scopeWrapperStyles}>

        <span style={filterBarLabelStyles}>Quick Apply: </span>

        <div>
          {scopeOptions.map((scope) => {
            const isActive = scopes.map(scope => scope.name).includes(scope.name);
            return (
              <Toggle
                style={toggleStyles}
                key={scope.name}
                checked={isActive}
                onChange={isActive ? () => removeScope(scope) : () => addScope(scope)}
                checkedChildren={scope.label}
                unCheckedChildren={scope.label}
              />
            );
          })}
        </div>

        <Stack.Item style={{ marginLeft: 'auto' }} className={loading ? 'spin' : ''}>
          <IconButton
            icon={<ReloadIcon />}
            onClick={() => reload()}
            circle
          />
        </Stack.Item>

      </Stack>

    </div>
  );
}

export default DopeFilterBar;
