import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { useCampaignListGenerationSettings } from "./campaignSlice";
import { useCampaignUI } from "./campaignUISlice";

import GoogleMapReact from 'google-map-react';
import { Icon } from '@rsuite/icons';
import { MdOutlineDraw, MdLocationSearching, MdOutlineFilterAlt, MdLocationPin } from "react-icons/md";

import GeoShapesList from './GeoShapesList';
import AudienceFiltersInput from './AudienceFiltersInput';
import CampaignFiltersSummary from './CampaignFiltersSummary';
import CampaignListBlitzSettings from './CampaignListBlitzSettings';
import DopeButton from "../ui/DopeButton";
import accountAbility from "../auth/accountAbility";
import CannotInfoBox from "../ui/CannotInfoBox";
import DopeAddressInput from "../ui/DopeAddressInput";


const shapeStyles = {
  strokeColor: '#48B3FF',
  strokeOpacity: 1,
  strokeWeight: 3,
  fillColor: '#3BABCD',
  fillOpacity: 0.5,
};
const hoverStrokeColor = '#D72E34';
const addShapesWrapperStyles = { display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "8px" };
const addShapesWrapperDisabledStyles = { ...addShapesWrapperStyles, pointerEvents: "none", opacity: 0.5 };
const shapeOverlayStyles = { color: 'black', fontSize: '20px', fontWeight: 'bold', background: 'white', minWidth: 65, height: 28, lineHeight: '28px', marginTop: -38, transform: "translateX(-50%)", textAlign: 'center', borderRadius: 8 };
const leftColStyles = { height: 550 };
const rightColStyles = { width: 300 };
const shapesContainerStyles = { ...rightColStyles, ...leftColStyles, padding: "0 16px 0px 16px", backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column' };

const mapOptions = {
  streetViewControl: false,
  mapTypeControl: true,
  fullscreenControl: false,
};

const extractGeoFilter = (shape) => {
  const { type, id } = shape;

  if (type === 'circle') {
    return {
      type,
      id,
      center: {
        lat: shape.getCenter().lat(),
        lng: shape.getCenter().lng(),
      },
      radius: shape.getRadius(),
    };
  } else if (type === 'polygon') {
    return {
      type,
      id,
      path: shape.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng(),
      })),
    };
  }
};

const getTopMiddleCoordsFromCircle = (circle) => {
  const { center, radius } = circle;
  const { lat, lng } = center;

  return { lat: lat + radius / 111320, lng };
};
const getTopCoordsFromPolygon = (polygon) => {
  const { path } = polygon;

  return _.maxBy(path, 'lat');
};
const getCountOverlayCoordsFromShape = (shape) => {
  if (shape.type === 'circle') {
    return getTopMiddleCoordsFromCircle(shape);
  } else if (shape.type === 'polygon') {
    return getTopCoordsFromPolygon(shape);
  }
};


const ShapeOverlay = ({ count }) => {
  const countDisplay = !count && count != 0 ? '...' : count;
  return (
    <div style={shapeOverlayStyles}>
      {countDisplay}
    </div>
  );
}

const StartingPointMarker = ({ size }) => {
  return (
    <Icon as={MdLocationPin} size={size} className="color-text-red" style={{ transform: 'translate(-50%, -100%)' }} />
  )
}

const GeoFilterInput = ({ editable = true }) => {
  const [settings, settingsActions] = useCampaignListGenerationSettings();
  const [campaignUI, campaignUIActions] = useCampaignUI();
  const noStartingAddress = !settings.latitude || !settings.longitude || !settings.address_1 || !settings.zip;

  const [map, setMap] = useState(null);
  const [mapsApi, setMapsApi] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);
  const [overlays, setOverlays] = useState([]);
  const [markerSize, setMarkerSize] = useState(14);

  const setDrawingMode = (mode) => {
    if (drawingManager) {
      drawingManager.setDrawingMode(mode);
    }
  };

  const updateShape = (shape) => {
    const geoFilter = extractGeoFilter(shape);
    settingsActions.updateGeoShape({ id: shape.id, update: geoFilter });
  };

  const addShapeListeners = (shape) => {
    if (!editable) return;

    const { type } = shape;

    shape.setEditable(true);
    shape.setDraggable(true);

    shape.addListener('mouseover', () => {
      campaignUIActions.setHoveringGeoShapeId(shape.id);
    });
    shape.addListener('mouseout', () => {
      campaignUIActions.setHoveringGeoShapeId(null);
    });

    if (type === 'circle') {
      shape.addListener('radius_changed', updateShape.bind(null, shape));
      shape.addListener('center_changed', updateShape.bind(null, shape));
    } else if (type === 'polygon') {
      shape.addListener('mouseup',  updateShape.bind(null, shape));
      shape.addListener('drag', updateShape.bind(null, shape));
    }
  };

  useEffect(() => { // add and update shapes
    if (map && mapsApi) {
      const manager = new window.google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        polygonOptions: shapeStyles,
        circleOptions: shapeStyles,
      });

      manager.setMap(map);
      setDrawingManager(manager);

      const listener = mapsApi.event.addListener(manager, 'overlaycomplete', (event) => {
        manager.setDrawingMode(null);

        const shape = event.overlay;
        shape.type = event.type;
        shape.id = uuidv4();

        const isInvalid = shape.type === 'polygon' && shape.getPath().getLength() < 3;
        if (isInvalid) {
          shape.setMap(null);
          return;
        }

        const geoFilter = extractGeoFilter(shape);
        settingsActions.addGeoShape(geoFilter);

        setOverlays((prev) => [...prev, shape]);
        addShapeListeners(shape);
      });

      // draw saved geo shapes
      const shapes = settings.geo_shapes.map(shape => {
        if (shape.type === 'circle') {
          const circle = new mapsApi.Circle({
            id: shape.id,
            type: shape.type,
            center: shape.center,
            radius: shape.radius,
            ...shapeStyles,
          });
          circle.setMap(map);
          addShapeListeners(circle);
          return circle;
        } else if (shape.type === 'polygon') {
          const polygon = new mapsApi.Polygon({
            id: shape.id,
            type: shape.type,
            path: shape.path,
            ...shapeStyles,
          });
          polygon.setMap(map);
          addShapeListeners(polygon);
          return polygon;
        }
      });

      setOverlays(shapes);

      return () => {
        mapsApi.event.removeListener(listener);
        manager.setMap(null);
      };
    }
  }, [map, mapsApi]);

  useEffect(() => { // keep hover states in sync
    if (campaignUI.hoveringGeoShapeId) {
      const hoveringOverlay = overlays.find(overlay => overlay.id === campaignUI.hoveringGeoShapeId);
      if (hoveringOverlay) {
        hoveringOverlay.setOptions({ strokeColor: hoverStrokeColor });
      }
    } else {
      overlays.forEach(overlay => {
        overlay.setOptions({ strokeColor: shapeStyles.strokeColor });
      });
    }
  }, [campaignUI.hoveringGeoShapeId]);

  useEffect(() => { // remove deleted shapes from the map
    overlays.forEach(overlay => {
      if (!settings.geo_shapes.find(shape => shape.id === overlay.id)) {
        overlay.setMap(null);
      }
    });
  }, [settings.geo_shapes.map(shape => shape.id).join(',')]);


  const cannotUseFiltersDisplay = (
    <CannotInfoBox
      icon={MdOutlineFilterAlt}
      body="Your subscription does not include Blitz filters. Feel free to play around with the tool and get in touch with us if you’re interested in using filters to hyper-target your marketing."
    />
  );

  const hasFilterPermissions = accountAbility.canUseFeature('create', 'ListGenerationSetting', ['data_axle_filters']);

  const addressAndFilters = editable && (
    <div className="flex gap-10">
      <div className="flex-grow">
        <DopeAddressInput values={settings} onChange={settingsActions.update} label="Starting Address" />
        <CampaignFiltersSummary />
        {!hasFilterPermissions && cannotUseFiltersDisplay}
      </div>

      <div style={rightColStyles}>
        <AudienceFiltersInput
          onUpdate={settingsActions.updateDataAxleFilters}
          onRemove={settingsActions.removeDataAxleFilter}
          values={settings}
          buttonClass="green full-width audience-page-offset"
        />
      </div>
    </div>
  );

  const mapSection = (
    <div style={leftColStyles} className="flex-grow">
      <GoogleMapReact
        center={settings.latitude ? { lat: parseFloat(settings.latitude), lng: parseFloat(settings.longitude) } : { lat: 39.8283, lng: -98.5795 }}
        zoom={settings.latitude ? 15 : 4}
        onChange={({ _center, zoom }) => setMarkerSize(zoom * 2)} //
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
          setMapsApi(maps);
        }}
        options={mapOptions}
      >
        {
          settings.geo_shapes.map((shape, i) => {
            const count = campaignUI.shapeIdToCount[shape.id];
            const coords = getCountOverlayCoordsFromShape(shape);
            return (
              <ShapeOverlay lat={coords.lat} lng={coords.lng} count={count} key={i} />
            );
          })
        }

        {settings.latitude && settings.generation_type === "blitz" && (
          <StartingPointMarker lat={settings.latitude} lng={settings.longitude} size={markerSize} />
        )}
      </GoogleMapReact>
    </div>
  );

  const editableShapesDisplay = settings.generation_type === 'list_blitz' ? <CampaignListBlitzSettings /> : (
    <>
      <div style={noStartingAddress ? addShapesWrapperDisabledStyles : addShapesWrapperStyles}>
        <DopeButton
          className="icon-clickable"
          icon={<Icon as={MdOutlineDraw} />}
          props={{
            buttonClass: "text-button",
            label: "Add a shape",
            onClick: () => setDrawingMode('polygon'),
          }}
        />

        <DopeButton
          className="icon-clickable"
          icon={<Icon as={MdLocationSearching} />}
          props={{
            buttonClass: "text-button",
            label: "Add a radius",
            onClick: () => setDrawingMode('circle'),
          }}
        />
      </div>

      <GeoShapesList editable={editable} />
    </>
  );

  const shapesDisplay = editable ? editableShapesDisplay : <GeoShapesList editable={editable} />;

  const shapesSection = (
    <div style={shapesContainerStyles}>
      {shapesDisplay}
    </div>
  );

  const mapAndShapes = (
    <div className="flex gap-10">
      {mapSection}
      {shapesSection}
    </div>
  );

  return (
    <div>
      {addressAndFilters}
      {mapAndShapes}
    </div>
  );
};

export default GeoFilterInput;
