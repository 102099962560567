import React from "react";

import { toDateString } from '../utils/date';

import DopeTabs from "../ui/DopeTabs";
import CampaignDetailsForm from "./CampaignDetailsForm";
import DispatchManagement from "./DispatchManagement";
import CampaignListManagement from "./CampaignListManagement";
import MailPieceManagement from "./MailPieceManagement";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const CampaignForm = (props) => {
  const campaignId = props.id;
  const belongsToCampaignFilter = [{ field: 'campaign_id', operator: '=', value: campaignId }];
  const mailPieceCampaignFilter = [{ field: 'dispatches.campaign_id', operator: '=', value: campaignId }];

  const initialBelongsToCampaign = {
    campaign_id: campaignId,
  };

  const initialDispatch = {
    ...initialBelongsToCampaign,
    date: toDateString(tomorrow),
    status: 'scheduled',
  };

  return (
    <DopeTabs>

      <DopeTabs.Tab name="details" label="Details">
        <CampaignDetailsForm {...props} />
      </DopeTabs.Tab>

      <DopeTabs.Tab name="lists" label="Lists" disabled={!campaignId}>
        <CampaignListManagement
          useDrawer={true}
          hideFiltering={true}
          initialFilters={belongsToCampaignFilter}
          formProps={{ initialResource: initialBelongsToCampaign }}
        />
      </DopeTabs.Tab>

      <DopeTabs.Tab name="dispatches" label="Dispatches" disabled={!campaignId}>
        <DispatchManagement
          useDrawer={true}
          hideFiltering={true}
          initialFilters={belongsToCampaignFilter}
          formProps={{ initialResource: initialDispatch }}
        />
      </DopeTabs.Tab>

      <DopeTabs.Tab name="mailPieces" label="Mail Pieces" disabled={!campaignId}>
        <MailPieceManagement
          useDrawer={true}
          hideFiltering={true}
          initialFilters={mailPieceCampaignFilter}
        />
      </DopeTabs.Tab>

    </DopeTabs>
  );
}

export default CampaignForm;
