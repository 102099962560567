import React, { useEffect } from "react";

import { useDesign } from "./designSlice";
import { DopeFormGroup } from "../ui/DopeForm";
import DopePlayer from "../ui/DopePlayer";

import './Design.scss';

const videoUrl = process.env.REACT_APP_HANDWRITTEN_SETUP_VIDEO_URL;

const nameInput = { inputType: "text", name: "name", props: { label: "Design Name" } };

const tagsInput = {
  inputType: "tags",
  name: "taggings",
  props: { label: "Design Tags", modelName: "design" }
};

const objectiveTypeInput = {
  inputType: "radio",
  name: 'objective_type',
  props: {
    label: "Mailer Tag",
    options: [
      { label: 'Marketing', value: 'marketing', helpText: '(Brand awareness targeting new customers)' },
      { label: 'Thank You', value: 'thank_you', helpText: '(More reviews & referrals from current jobs)' },
      { label: 'Reactivation', value: 'reactivation', helpText: '(Targeting existing customers)' },
    ],
    defaultValue: 'thank_you',
  },
};


const HandwrittenCardSetup = () => {
  const { design, actions } = useDesign();

  const inputProps = { values: design, onChange: actions.update, errors: design.errors };
  const templateInputProps = { values: design.mail_template, onChange: actions.updateMailTemplate, errors: design.errors };

  useEffect(() => {
    if (design) {
      actions.update({ mail_template_type: 'HandwrittenCardTemplate' });
    }
  }, [design]);

  return (
    <div className="handwritten-card-form-wrapper">
      <div className="page-header">
        <p className="header-text">Define your handwritten card</p>
        <p className="help-text">Name your card, select some dummy data and add tags to help keep you organized!</p>
      </div>

      <div className="rs-form split-cols">
        <div>
          <DopeFormGroup input={nameInput} {...inputProps} />

          <DopeFormGroup input={objectiveTypeInput} {...templateInputProps} />

          <DopeFormGroup input={tagsInput} {...inputProps} />
        </div>
        <div><DopePlayer url={videoUrl} /></div>
      </div>
    </div>
  );
};

export default HandwrittenCardSetup;
