import React, { useEffect, useState } from 'react';
import ModelForm from "../ui/ModelForm";
import DopeManagement from "../ui/DopeManagement";

const initialUser = {
  first_name: '',
  last_name: '',
  email: '',
  role: 'user',
  password: '',
  password_confirmation: '',
};

const userInputs = [
  {
    inputType: 'text',
    name: 'first_name',
    props: {
      label: 'First Name',
      type: 'text',
    }
  },
  {
    inputType: 'text',
    name: 'last_name',
    props: {
      label: 'Last Name',
      type: 'text',
    }
  },
  {
    inputType: 'text',
    name: 'email',
    props: {
      type: 'text',
      label: 'Email',
    }
  },
  {
    inputType: 'simpleSelect',
    name: 'role',
    props: {
      label: 'Role',
      options: ['user', 'admin', 'super_admin'],
    }
  },
  {
    inputType: 'text',
    name: 'password',
    props: {
      label: 'Password',
      type: 'password',
    }
  },
  {
    inputType: 'text',
    name: 'password_confirmation',
    props: {
      label: 'Password Confirmation',
      type: 'password',
    }
  },
];

const UserForm = (props) => {
  return (
    <ModelForm
      modelName="user"
      initialResource={initialUser}
      inputs={userInputs}
      {...props}
    />
  );
};

const columns = [
  { type: 'text', dataKey: 'id', label: 'ID', sortable: true, filterable: true },
  { type: 'text', dataKey: 'full_name', label: 'Name', sortable: true, filterable: true },
  { type: 'text', dataKey: 'email', label: 'Email', sortable: true, filterable: true },
  { type: 'text', dataKey: 'role', label: 'Role', sortable: true, filterable: true },
];

const scopeOptions = [
  { name: 'super_admins', label: 'Super Admins' },
  { name: 'admins', label: 'Admins' },
];

function UserManagement() {
  return (
    <DopeManagement
      modelName="user"
      modelForm={UserForm}
      columns={columns}
      scopeOptions={scopeOptions}
    />
  );
}

export default UserManagement;
