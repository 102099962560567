import React from "react";
import { Button } from "rsuite";
import { Icon } from "@rsuite/icons";
import { MdCheck} from "react-icons/md";
import './DopePill.scss'

const DopeQuickScopeButton = ({ selected, onClick, label }) => {
  const pillType = selected ? 'applied-filter' : 'default';
  const textColor = selected ? '#255FDF' : '#494B4E';

  return (
    <Button className={`dope-pill ${pillType} `} style={{ height: "28px", marginRight: "4px" }} onClick={onClick}>
        <div className="dope-pill-content">
          {selected && <Icon as={MdCheck} style={{marginRight: "4px"}} />}
          <div className="pill-text" style={{marginBottom: "1px", color: textColor}}>{label}</div>
        </div>
    </Button>
  );
}

export default DopeQuickScopeButton;
