import DopeApi from "../services/DopeApi";

const triggerDownload = (url, filename) => {
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const downloadFile = async (url, filename, crossOrigin = true) => {
  // Browsers no longer respect the download attribute on anchor tags
  // if the file is being served from a different origin.
  if (crossOrigin) {
    const api = new DopeApi('media_upload');
    const proxy = await api.get('proxy' , { url });
    const { content, mime_type } = proxy;

    const base64 = `data:${mime_type};base64,${content}`;
    triggerDownload(base64, filename);
    return;
  }

  triggerDownload(url, filename);
};
