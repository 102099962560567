import React, { useEffect, useState } from "react";

import { ResponsiveBar } from "@nivo/bar";
import { SelectPicker } from "rsuite";

import { humanize } from "../utils/railsNames";


const fieldTypeToFormatter = new Proxy({
    datetime: (d) => new Date(d).toLocaleDateString(),
    numeric: (d) => parseFloat(d).toLocaleString(),
  },
  { get: (target, name) => name in target ? target[name] : (d) => d }
);

const datetimeGroupingOptions = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
  { value: 'year', label: 'Year' },
];

const yFuncOptions = [
  { value: 'count', label: 'Count' },
  { value: 'sum', label: 'Sum' },
  { value: 'avg', label: 'Average' },
  { value: 'min', label: 'Min' },
  { value: 'max', label: 'Max' },
];

const DopeChartSelect = ({ label, options, value, onChange }) => {
  const parsedOptions = options.map(o => ({ value: o.value, label: o.label || humanize(o.value) }));

  return (
    <SelectPicker
      data={parsedOptions}
      value={value}
      onChange={onChange}
      label={label}
      searchable={false}
      cleanable={false}
      style={{ minWidth: 75 }}
      size="xs"
    />
  );
};

const DopeChartOptions = ({ xFields, yFields, xSelection, ySelection, xFunc, yFunc, setXSelection, setYSelection, setXFunc, setYFunc, show, onChangeShow }) => {
  const hasDatetimeGrouping = xSelection.type === 'datetime';
  const xFuncSelect = hasDatetimeGrouping && (
    <DopeChartSelect
      options={datetimeGroupingOptions}
      value={xFunc}
      onChange={setXFunc}
    />
  );

  const inputs = show && (
    <>
      <DopeChartSelect
        options={yFuncOptions}
        value={yFunc}
        onChange={setYFunc}
      />

      <div>Of</div>

      <DopeChartSelect
        options={yFields}
        value={ySelection.value}
        onChange={value => setYSelection(yFields.find(f => f.value === value))}
      />

      <div>By</div>

      <DopeChartSelect
        options={xFields}
        value={xSelection.value}
        onChange={value => setXSelection(xFields.find(f => f.value === value))}
      />

      {xFuncSelect}
    </>
  );

  return (
    <div className="vertical-align gap-10 border-top border-bottom pad-tb-10">
      <div onClick={e => onChangeShow(!show)} className="clickable">{show ? 'Hide Chart - Showing:' : 'Show Chart'}</div>

      {inputs}
    </div>
  );
};

const DopeChart = ({ api, params, options = {} }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    xFields = [{ value: 'created_at', type: 'datetime' }],
    yFields = [{ value: "*", type: 'numeric' }],
    initialXFunc = null,
    initialYFunc = 'count',
    initialShow = true,
  } = options;

  const [xSelection, setXSelection] = useState(xFields[0]);
  const [ySelection, setYSelection] = useState(yFields[0]);
  const [xFunc, setXFunc] = useState(initialXFunc || null);
  const [yFunc, setYFunc] = useState(initialYFunc || 'count');
  const [show, setShow] = useState(initialShow);

  const yAxisLegend = `${yFunc.toUpperCase()}( ${ySelection.value} )`;
  const xAxisLegend = `${xFunc ? `${xSelection.value}::${xFunc}` : xSelection.value}`;

  const xFormatter = fieldTypeToFormatter[xSelection.type];
  const yFormatter = fieldTypeToFormatter[ySelection.type];

  const chartParams = {
    ...params,
    x_field: xSelection.value,
    y_field: ySelection.value,
    x_func: xFunc,
    y_func: yFunc,
  };

  useEffect(() => {
    setLoading(true);
    api.fetchChartData(chartParams)
      .then(setChartData)
      .finally(() => setLoading(false));
  }, [JSON.stringify(chartParams)]);

  const optionsForm = (
    <DopeChartOptions
      xFields={xFields}
      yFields={yFields}
      xSelection={xSelection}
      ySelection={ySelection}
      xFunc={xFunc}
      yFunc={yFunc}
      setXSelection={setXSelection}
      setYSelection={setYSelection}
      setXFunc={setXFunc}
      setYFunc={setYFunc}
      show={show}
      onChangeShow={setShow}
    />
  );

  const chartWrapperStyles = show ? { height: 400, transition: '0.2s' } : { height: 0, transition: '0.2s' };

  return (
    <div>
      {optionsForm}
      <div style={chartWrapperStyles}>
        <ResponsiveBar
          colors={{ scheme: 'category10' }}
          data={chartData}
          margin={{ top: 10, right: 0, bottom: 30, left: 60 }}
          xScale={{ type: 'point' }}
          xFormat={xFormatter}
          indexBy="x"
          keys={['y']}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
          }}
          yFormat={yFormatter}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: xFormatter
          }}
          axisLeft={{
            format: yFormatter
          }}
          animate={false}
          tooltip={(point) => (
            <div
              style={{
                background: 'white',
                padding: '5px',
                border: '1px solid #ccc',
              }}
            >
              <strong>{xFormatter(point.data.x)}</strong>
              <br />
              <strong>{yFormatter(point.data.y)}</strong>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default DopeChart;
