import React, {useEffect, useState} from "react";
import {Row, Col, Form, Grid} from "rsuite";

import { useUser } from "../users/userSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import authApi from "../auth/authApi";
import DopeApi from "../services/DopeApi";
import DopeTextInput from "../ui/DopeTextInput";
import DopeSelect from "../ui/DopeSelect";
import DopeImageSelect from "../ui/DopeImageSelect";
import DopePhoneInput from "../ui/DopePhoneInput";
import DopeButton from "../ui/DopeButton";

const userApi = new DopeApi('user');

const AddTeamMemberForm = ({ roleOptions, handleClose, resetModal, reload, userId }) => {
  const [user, actions] = useUser(userId);
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [loading, setLoading] = useState(false);

  const updateUser = actions.update;

  const removeImage = () => {
    updateUser({avatar: null});
  }

  const handleSubmit = async () => {
    if (!user.dope_role) {
      dopeUIActions.addFlashMessage({header: 'Please select a position', body: 'before submitting', type: 'error' });
      return
    } else if (!user.first_name || !user.last_name || !user.email) {
      dopeUIActions.addFlashMessage({header: 'Please fill out all required fields', body: 'before submitting', type: 'error' });
      return
    }

    setLoading(true);
    try {
      await actions.save({role: 'admin', actions: [{name: 'add_dope_team_member', args: [authApi.getCurrentUserId()]}] });
      reload();
      handleClose();
      dopeUIActions.addFlashMessage({header: `${user.first_name} ${user.last_name}`, body: 'was successfully added to your team!', type: 'success' });
    } catch (error) {
      dopeUIActions.addFlashMessage({header: 'Something went wrong', body: 'please try again', type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async () => {
    setLoading(true);
    try {
      await userApi.update({id: user.id, actions: [{name: "revoke_dope_team_privileges"}]});
      reload();
      handleClose();
      dopeUIActions.addFlashMessage({header: `${user.first_name} ${user.last_name}`, body: 'was successfully deleted from your team!', type: 'success' });
    } catch (error) {
      dopeUIActions.addFlashMessage({header: 'Something went wrong', body: 'please try again', type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const newUser = user?.id === 'new'

  const currentUserIsSuperAdmin = authApi.currentUserIsSuperAdmin();

  return (
    <Form fluid>
      <Grid fluid>
        <Col xs={12} >
          <Row gutter={10} className="margin-16-b">
            <Col xs={24}>
              <label className="label">First Name</label>
              <DopeTextInput
                name="first_name"
                value={user?.first_name}
                onChange={updateUser}
              />
            </Col>
          </Row>

          <Row gutter={10} className="margin-16-b">
            <Col xs={24}>
              <label className="label">Last Name</label>
              <DopeTextInput
                name="last_name"
                value={user?.last_name}
                onChange={updateUser}
              />
            </Col>
          </Row>

          <Row gutter={10} className="margin-16-b">
            <Col xs={24}>
              <label className="label">Email</label>
              <DopeTextInput
                name="email"
                value={user?.email}
                onChange={updateUser}
              />
            </Col>
          </Row>

          <Row gutter={10} className="margin-16-b">
            <Col xs={24}>
              <label className="label">Position</label>
              <DopeSelect
                name="dope_role"
                value={user?.dope_role}
                placeholder={user?.dope_role || "Select a position"}
                onChange={updateUser}
                options={roleOptions}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={12}>
          <Row gutter={10} className="margin-16-b">
            <Col xs={24}>
              <div className="to-center margin-bottom">
                <DopeImageSelect
                  name="avatar"
                  value={user?.avatar}
                  values={{avatar: user?.avatar, avatar_url: user?.avatar_url}}
                  onChange={updateUser}
                  onRemove={removeImage}
                  avatar={true}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={10} className="margin-top margin-bottom">
            <Col xs={24} >
              <label className="label">Phone Number</label>
              <DopePhoneInput
                name="phone_number"
                value={user?.phone_number}
                onChange={updateUser}
              />
            </Col>
          </Row>

          <Row gutter={10} className="margin-16-b">
            <Col xs={24}>
              <label className="label">Calendar Link</label>
              <DopeTextInput
                name="calendar_link"
                value={user?.calendar_link}
                onChange={updateUser}
              />
            </Col>
          </Row>

        </Col>
      </Grid>

      <div className={ currentUserIsSuperAdmin ? 'flex space-between row margin-16-t' : 'margin-16-t' }>
        {newUser ? (
          <DopeButton
            props={{
              buttonClass: "text-link",
              label: "Back",
              onClick: resetModal,
            }}
          />
        ) : (
          <>
            { currentUserIsSuperAdmin &&
              <DopeButton
                props={{
                buttonClass: "outlined-black",
                label: "Remove Team Member",
                onClick: handleDelete,
              }}
              disabled={newUser}
              loading={loading}
              />}
          </>
        )}

        <DopeButton
          props={{
            buttonClass: "filled-black float-right",
            label: newUser ? "Add Team Member" : "Update",
            onClick: handleSubmit,
          }}
          loading={loading}
        />
      </div>
    </Form>
  );
}

export default AddTeamMemberForm;
