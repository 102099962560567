import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";


import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";

const [agencySlice, asyncActions] = createModelSlice('agency', {

});

export default agencySlice.reducer;

export const {
  updateAgency,
  cancelAgencyUpdate,
  resetToInitial,
} = agencySlice.actions;

export const selectAgency = (state) => state.agency.current

export function useAgency(agencyId) {
  const dispatch = useDispatch();
  const agency = useSelector(selectAgency);
  const isSaving = useSelector(state => state.design.isSaving);
  const isGetting = useSelector(state => state.design.isGetting);

  const actions = wrapAll({
    update: updateAgency,
    resetToInitial: resetToInitial,
    get: asyncActions.get,
    destroy: asyncActions.destroy,
  }, dispatch);

  actions.save = (update = {}) => {
    const params = {
      ...agency,
      ...update
    };

    return dispatch(asyncActions.save(params));
  };

  useEffect(() => {
    const hasId = typeof agencyId !== 'undefined' && agencyId !== null;
    const shouldGet = hasId && (!agency || agency.id !== agencyId);

    if (shouldGet) {
      actions.get(agencyId);
    }
  }, [agencyId]);


  return {
    agency,
    actions,
    isSaving,
    isGetting,
  };
}
