import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {Container, Radio, RadioGroup, Checkbox, SelectPicker} from 'rsuite';

import { useList } from "./listSlice";
import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import DopeContentPage from "../ui/DopeContentPage";
import DopeCsvDropZone from "../ui/DopeCsvDropZone";
import { DopeFormGroup } from "../ui/DopeForm";
import ArrowBackIcon from "@rsuite/icons/ArowBack";
import DopeButton from "../ui/DopeButton";

const listApi = new DopeApi("list");

const ListUploadPage = () => {
  const [listType, setListType] = useState('new');
  const [uploadFile, setUploadFile] = useState(null);
  const [fileHasHeaders, setFileHasHeaders] = useState(true);
  const [list, actions] = useList();
  const { tableProps, onSearchInput } = useQuery2({ api: listApi, initialSearchScope: "name" });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.listType) {
      setListType(location.state.listType)
    }
  }, []);

  const handleCheck = (value) => {
    setListType(value)
    actions.get('new')
  }

  const handleUpload = (file) => {
    setUploadFile(file);
  }

  const handleRemove = () => {
    setUploadFile(null);
  }

  // TODO: Add tags, description to model?, handle new/existing list
  const handleContinue = () => {
    navigate(`/lists/${list.id}/field_map`, { state: { uploadFile: uploadFile, fileHasHeaders: fileHasHeaders }});
  };

  window.list = list;

  const handleListSelect = (value) => {
    if (value !== null) {
      actions.get(value);
    } else {
      actions.get("new")
    }
  }

  return (
    <div style={{padding: "0 20px"}}>
      <div style={{marginBottom: "24px"}}>
        <h5 style={{ fontWeight: 600, fontSize: "24px", color: "#292B2E", marginBottom: "8px" }}>Upload a list</h5>
        <div style={{ fontWeight: 400, fontSize: "16px", color: "#6F6F6F" }}>
          Define your list, give it a name, add tags and upload!
        </div>
      </div>

      <Container style={{ flexDirection: "row" }} className="rs-form">
        <Container style={{ width: "50%", marginRight: "24px" }}>
          <DopeCsvDropZone
            onCsvUpload={handleUpload}
            onCsvRemoval={handleRemove}
            currentList={uploadFile}
          />

        </Container>

        <Container style={{ width: "50%"}}>
          <div style={{marginBottom: '16px'}}>
            <h5 style={{ fontWeight: 600, fontSize: '16px', color: "#292B2E" }}>List Details</h5>
            <Checkbox
              style={{marginLeft: '-10px', fontWeight: 400, fontSize: '14px', color: "#292B2E"}}
              checked={fileHasHeaders}
              onChange={(value) => setFileHasHeaders(value)}>
              The uploaded file contains a header column
            </Checkbox>
          </div>

          <div style={{marginBottom: '10px'}}>
            <label style={{fontWeight: 700, fontSize: '11px', color: "#8793A6"}}>Is this a new list or are you adding this data to an existing?</label>
            <RadioGroup
              name="radioListType"
              inline
              value={listType}
              onChange={handleCheck}
              disabled={location.state && location.state.listType}
              style={{fontWeight: 400, fontSize: "14px", color: "#292B2E", display: "flex", flexDirection: "column"}}
            >
              <Radio value="new">Create a new list</Radio>
              <Radio value="existing">Add to an existing list</Radio>
            </RadioGroup>
          </div>

          {listType === 'new' && <div>
            <DopeFormGroup
              input={{
                inputType: "text",
                name: "name",
                props: {label: "List Name", placeholder: "Name your list"}
              }}
              values={list}
              onChange={actions.update}
            />

            <DopeFormGroup
              input={{
                inputType: "text",
                name: "description",
                props: {
                  label: "Description",
                  placeholder: "Describe your list",
                  as: "textarea",
                  rows: 3,
                }
              }}
              values={list}
              onChange={actions.update}
            />
          </div>}

          {listType === 'existing' && tableProps.data && <div>
            <label style={{fontWeight: 700, fontSize: '11px', color: "#8793A6"}}>Choose a list</label>
            <SelectPicker
              data={tableProps.data.map((item) => ({label: item.name, value: item.id}))}
              onSearch={(value) => onSearchInput(value)}
              onChange={(value) => handleListSelect(value)}
              style={{ width: "100%", marginBottom: "16px" }}
              defaultValue={location.state && location.state.listId ? location.state.listId : null}
              disabled={location.state && location.state.listId}
            />
          </div>}

          <DopeFormGroup
            input={{
              inputType: "tags",
              name: "taggings",
              props: { label: "List Tags", modelName: "list" }
            }}
            values={list}
            onChange={actions.update}
          />

        </Container>

      </Container>
      <div style={{marginTop: '20px', display: 'flex', justifyContent: 'flex-end', height: "100%", alignItems: "end"}}>
        <DopeButton
          disabled={!uploadFile || !list.name || list.name.length === 0}
          props={{
            buttonClass: "filled",
            label: "Continue",
            onClick: handleContinue,
          }}
        />
      </div>
    </div>
  );
}

const navLinkStyles = {
  fontWeight: 700,
  fontSize: '13px',
  paddingLeft: '20px',
  paddingTop: '20px',
  marginBottom: '-10px',
  cursor: 'pointer',
  color: '#255FDF',
  width: "fit-content",
};

const tabComponents = []
const ListUploadContent = () => {
  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate("/lists_and_contacts");
  };

  const navLink = (
    <div onClick={handlePrevious} style={navLinkStyles}>
      <ArrowBackIcon /> Back to all Lists & Contacts
    </div>
  );

  return (
    <DopeContentPage
      pageContent={<ListUploadPage />}
      tabComponents={tabComponents}
      navLink={navLink}
    />
  );
}

export default ListUploadContent;
