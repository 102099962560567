const wrapAll = (actionObj, wrapper) => { // fuck writing dispatch 100 times
  const result = {};

  Object.keys(actionObj).forEach((key) => {
    result[key] = (...args) => {
      return wrapper(actionObj[key](...args));
    }
  });

  return result;
}

export default wrapAll;
