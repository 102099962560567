import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  deleteSweetSpotAnalysisId: null,
}

export const sweetSpotAnalysisUISlice = createSlice({
  name: "sweetSpotAnalysisUI",
  initialState,
  reducers: {
    setDeleteSweetSpotAnalysisId: (sweetSpotAnalysisUI, { payload: id }) => {
      sweetSpotAnalysisUI.deleteSweetSpotAnalysisId = id;
    },
  }
});

export default sweetSpotAnalysisUISlice.reducer;

export const {
  setDeleteSweetSpotAnalysisId,
} = sweetSpotAnalysisUISlice.actions;

export const selectSweetSpotAnalysisUI = (state) => state.sweetSpotAnalysisUI;

export function useSweetSpotAnalysisUI() {
  const dispatch = useDispatch();
  const sweetSpotAnalysisUI = useSelector(selectSweetSpotAnalysisUI);
  const actions = {
    setDeleteSweetSpotAnalysisId: (id) => dispatch(setDeleteSweetSpotAnalysisId(id)),
  };

  return [sweetSpotAnalysisUI, actions];
}
