import React, {useCallback, useEffect, useState} from 'react'
import { useDropzone } from 'react-dropzone'
import { Icon } from '@rsuite/icons'
import { IoMdImage } from "react-icons/io";
import DopeButton from "./DopeButton";

const baseStyle = {
  padding: '10px 20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  // color: '#bdbdbd',
  outline: 'none',
  transition: 'border .2s ease-in-out'
};

const activeStyle = {
  ...baseStyle,
  borderColor: '#2196f3'
};

const avatarWrapperStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  background: "#BCD2E11A",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

const imageTextStyles = {
  fontWeight: 600,
  fontSize: "11px",
  color: "#292B2E",
  width: "100px",
  display: "flex",
  justifyContent: "center"
}

const DopeImageSelect = ({ name, value, values, onChange, disabled, avatar = false, height = '100px', width = '100px', }) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const onDrop = useCallback(files => {
    setImagePreviewUrl(null);
    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    onChange({[name]: file});
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {
      'image/*': []
    },
    maxFiles: 1,
    onDrop,
  });

  const url = values[name + '_url'];

  let content = null;

  if (avatar && (url || value)) {
    content = <div style={{cursor: "pointer"}}>
      <img width={width || '100px'} src={imagePreviewUrl && imagePreviewUrl !== url ? imagePreviewUrl : url} alt="preview" style={{ width: height, height: height, borderRadius: "50%"}}/>
      {/*<div style={imageTextStyles}>Update image</div>*/}
    </div>
  } else if (url) {
    content = <img src={url} alt={name} style={{height: height}}/>;
  } else if (value) {
    content = <p style={activeStyle}><img width={width || '100px'} src={imagePreviewUrl} alt="preview"/> <br/> {value.name}</p>;
  } else if (isDragActive) {
    content = <p style={activeStyle}>Drop the files here ...</p>;
  } else if (avatar) {
    content = <div style={{cursor: "pointer"}}>
      <div style={avatarWrapperStyle}>
        <Icon as={IoMdImage} style={{fontSize: "64px"}}/>
      </div>
      {/*<div style={imageTextStyles}>Upload image</div>*/}
    </div>
  } else {
    content = <p style={baseStyle}>Drag 'n' drop some files here, or click to select files</p>;
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {content}
    </div>
  )
}

export default DopeImageSelect;

