import React from "react";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { Button } from "rsuite";

const uploaderStyles = {
  height: '237px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  border: '2px dashed #F3F3F3'
}

const buttonStyles = {
  color: "#255FDF",
  fontWeight: 700,
  fontSize: '11px',
  width: '111px',
  height: '32px',
  borderRadius: '16px',
  padding: '8px 16px',
  backgroundColor: 'transparent',
  border: '1px solid #EAECEF'
}

const DopeCsvDropZone = ({ onCsvUpload, onCsvRemoval, currentList }) => {

  const onDrop = async (acceptedFiles) => {
    // Check if at least one file was dropped
    if (acceptedFiles.length > 0) {
      const csvFile = acceptedFiles[0];
      onCsvUpload(csvFile);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': [],
    },
    maxFiles: 1,
  });

  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      {!!currentList ? (
        <div style={uploaderStyles}>
          <h4>{currentList.name}</h4>
          <div>{currentList.size} bytes</div>
          <div><Button onClick={onCsvRemoval} style={buttonStyles}>Remove</Button></div>
        </div>
      ) : (
        <div style={uploaderStyles}>
          <h4><FileUploadIcon />Drag your file here</h4>
          <div>(Files must be a .csv or tab-delineated text)</div>
          <div>or</div>
          <Button style={buttonStyles}>Browse Files</Button>
        </div>
      )}
    </div>
  );
};

export default DopeCsvDropZone;
