import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";
import { useDesign } from "../designs/designSlice";
import DopeListPage from "../ui/DopeListPage";
import DopeTabs from "../ui/DopeTabs";

import DopeButton from "../ui/DopeButton";
import { handleFilterChange } from "../utils/fn";

import AddDesignModal from "../designs/AddDesignModal";

const pages = [
  { name: "in_design", label: "Needs Revisions" },
  { name: "getting_worked_on", label: "Getting Worked On" },
  { name: "needs_review", label: "Waiting on Client" },
  { name: "revised", label: "Approved" },
];

const tabs = pages.map((page) => <DopeTabs.Tab key={page.name} name={page.name} label={page.label} />);

const columns = [
  { type: 'design_preview', dataKey: ['mail_template.front_image_url', 'mail_template.back_image_url'], label: '', flexShrink: 0 },
  { type: 'stacked_text', dataKey: ['account.name', 'account.account_email'], label: 'Company', sortable: true, flexGrow: 2 },
  { type: 'text', dataKey: 'name', label: 'Design Name', sortable: true, flexGrow: 2 },
  { type: 'timestamp', dataKey: 'created_at', label: 'Creation Date', sortable: true },
  { type: 'timestamp', dataKey: 'updated_at', label: 'Last Update', sortable: true },
];

const api = new DopeApi('designs');
const accountApi = new DopeApi('account');

const mailerTypes = [
  { label: 'Postcard', value: 'PostcardTemplate' },
  { label: 'HWC Front', value: 'DesignTemplate' },
];


const PulseDesignRevisions = () => {
  const { design, actions } = useDesign();
  const [showAddDesignModal, setShowAddDesignModal] = useState(false);
  const [designTemplateId, setDesignTemplateId] = useState('new');

  const [tab, setTab] = useState('in_design');

  const {
    error,
    tableProps,
    paginationProps,
    onSearchClear,
    onSearchInput,
    setFilters,
    filters,
    reload,
    setScopes,
    scopes,
  } = useQuery2({
    api,
    initialScopes: [{name: 'in_design'}],
    initialSortColumn: 'updated_at',
    initialSearchScope: 'name',
  });


  useEffect(() => {
    if (scopes.find(scope => scope.name === tab)) {
      return;
    }

    setScopes([{ name: tab }]);
  }, [tab]);

  const onMailerTypeSelect = (mailerType) => {
    const filterObject = {
      filterField: "mail_template_type",
      operator: "in",
      filterCategory: "Design Type"
    }

    handleFilterChange(filters, setFilters, mailerType, filterObject);
  };

  const onAccountsSelect = (accountIds) => {
    const filterObject = {
      filterField: "account_id",
      operator: "in",
      filterCategory: "Account",
    };

    handleFilterChange(filters, setFilters, accountIds, filterObject);
  };

  const onEmailsSelect = (emails) => {
    const filterObject = {
      filterField: "accounts.account_email",
      operator: "in",
      filterCategory: "Email",
    };

    handleFilterChange(filters, setFilters, emails, filterObject);
  };

  const filterInputs = [
    {
      title: "Account",
      type: "MultiSelect",
      name: "account_ids",
      field: "account_id",
      searchable: true,
      getOptions: (text = "") => accountApi.getOptions({
        valueKey: 'id',
        labelKey: 'name',
        search: { text, column: 'name' },
      }),
      applyFilter: onAccountsSelect,
    },
    {
      title: "Email",
      type: "MultiSelect",
      name: "account_emails",
      field: "account_email",
      searchable: true,
      getOptions: (text = "") => accountApi.getOptions({
        valueKey: 'account_email',
        labelKey: 'account_email',
        search: { text, column: 'account_email' },
      }),
      applyFilter: onEmailsSelect,
    },
    {
      title: "Design Type",
      getOptions: () => mailerTypes,
      applyFilter: onMailerTypeSelect,
      searchable: false,
    },
  ];

  const navigate = useNavigate();

  const onRowClick = (row) => {
    navigate(`/pulse/design_revisions/${row.id}/revision`);
  };

  return (
    <div className="page">
      <div className="margin-bottom" style={{display: 'flex', gap: '2rem', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <div className="header-2">Design Revisions</div>
        <DopeButton
          props={{
            buttonClass: "filled",
            label: "Upload New Design",
            onClick: () => setShowAddDesignModal(true),
          }}
        />
      </div>

      <AddDesignModal
        open={showAddDesignModal}
        handleClose={() => {
          actions.resetToInitial();
          setShowAddDesignModal(false);
        }}
        reload={reload}
        designTemplateId={designTemplateId}
      />

      <DopeTabs defaultTab={tab} onChange={(tab) => setTab(tab)}>
        {tabs}
      </DopeTabs>

      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={filterInputs}
        filters={filters}
        setFilters={setFilters}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
        onRowClick={onRowClick}
        />
    </div>
  );
}

export default PulseDesignRevisions;
