import React from "react";

const GraphBarIcon = ({ size, fill, className, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill={fill} stroke={fill} className={className}style={{ strokeWidth: 0 }}>
      <path d="M3.75 15.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39688 15.0156 2.25 14.6625 2.25 14.25V2.25H3.75V14.25H15.75V15.75H3.75ZM4.5 13.5V6.75H7.5V13.5H4.5ZM8.25 13.5V3H11.25V13.5H8.25ZM12 13.5V9.75H15V13.5H12Z" />
    </svg>
  );
};

export default GraphBarIcon;
