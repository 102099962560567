import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useBlocker, Link } from 'react-router-dom';
import authApi from "../auth/authApi";
import { timeSince } from "../utils/date";
import { BsSend } from "react-icons/bs";
import { BiSend } from "react-icons/bi";
import { FaFileAlt, FaFileImage, FaDownload, FaTrash, FaPlus, FaUpload } from "react-icons/fa";
import { MdOutlineCancel, MdOutlineCheckCircle, MdOutlineDraw, MdWorkHistory, MdRateReview } from "react-icons/md";
import { AreaSelector } from './AreaSelector';
import ReloadIcon from '@rsuite/icons/Reload';
import './Design.scss';
import Select from 'react-select';
import { Dropdown, IconButton, Modal, Stack, Popover, Whisper } from "rsuite";
import { useDesignVersion } from "./designVersionSlice";
import DopeAttachmentDropZone from "../ui/DopeAttachmentDropZone";
import DopeButton from "../ui/DopeButton";
import PostcardApprovalDrawer from "./PostcardApprovalDrawer";
import { sizeToRatio } from "../utils/design";
import EditIcon from '@rsuite/icons/Edit';
import { FaShare } from "react-icons/fa";

import AddDesignModal from "./AddDesignModal";
import { Icon } from "@rsuite/icons";
import { FaEllipsisVertical } from "react-icons/fa6";
import TrashIcon from '@rsuite/icons/Trash';
import { useDesign } from "./designSlice";
import { useLocation } from "react-router-dom";
import DopeApi from "../services/DopeApi";
import DopeLogo from "../icons/DopeLogo";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";

import { downloadFile } from "../utils/file";
import DopeLoader from "../ui/DopeLoader";

const api = new DopeApi('designs');

const viewOnlyStyle = {
  padding: '2rem',
  borderRadius: '10px',
};

const badge = (design) => {
  if (design.mail_template.mailer_type === 'hot_lead') {
    return 'Hot Lead';
  }

  return 'Postcard';
};

const Attachment = ({ attachment, actions }) => {
  let icon = <FaFileAlt />;

  switch (attachment.content_type) {
    case 'image/jpeg':
    case 'image/png':
      icon = <FaFileImage />;
      break;
    default:
      icon = <FaFileAlt />;
      break;
  }

  return (
    <div className="design-version-attachment">
      {icon}
      <p>{attachment.filename}</p>
      <div className="design-version-attachment-actions">
        <IconButton icon={<FaDownload />} onClick={() => downloadFile(attachment.url, attachment.filename)} title='Download' />
        <IconButton icon={<FaTrash />} onClick={() => actions.detach({ attachment_id: attachment.id })} title='Delete' />
      </div>
    </div>
  );
};

const DesignVersion = ({
  version,
  index,
  editable = false,
  pulse = false,
  currentAccount = null,
  viewOnly = false,
  inCurrentVersion = false,
  designTeamSide = false,
  hasComments = false,
  loading = false,
  requestRevision = () => {},
  setLoading = () => {},
}) => {
  const location = useLocation();

  const { designVersion, actions, isCommenting, isAttaching, isGetting } = useDesignVersion(version + location.search);
  const [view, setView] = useState('front');

  const [comment, setComment] = useState('');

  const [attachment, setAttachment] = useState(null);

  const [areas, setAreas] = useState([]);
  const [currentArea, setCurrentArea] = useState(null);
  const [canAddArea, setCanAddArea] = useState(true);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasUnsentRevision, setHasUnsentRevision] = useState(false);

  const postComment = () => {
    const data = { side: view, comment: comment.trim() };

    if (currentArea) {
      data.area = currentArea;
    }

    actions.comment(data);

    setCurrentArea(null);
    setCanAddArea(true);
    setComment('');
  };

  useEffect(() => {
    if (attachment) {
      actions.attach({ attachment });
    }

  }, [attachment]);

  useEffect(() => {
    if (designVersion) {
      setAttachment(null);
    }
  }, [designVersion?.attachments_urls]);

  useEffect(() => {
    if (designVersion) {
      setCanAddArea(false);

      setCurrentArea(null);
      setAreas([]);

      const viewComments = designVersion.comments.filter(c => c.side === view && c.area);
      const areas = viewComments.map(c => c.area);

      setAreas(areas);

      setCanAddArea(true);
    }
  }, [designVersion?.comments, view]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (comment.trim().length > 0) {
        event.preventDefault();
        event.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [comment]);  

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const hasUnsentComment = comment.trim().length > 0;
    const hasUnsentRevision = (designVersion?.comments ?? []).length > 0;

    return (hasUnsentComment || hasUnsentRevision) && !pulse && editable && currentLocation.pathname !== nextLocation.pathname;
  });


  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (comment.trim().length > 0) {
        setHasUnsavedChanges(true);
      } else if ((designVersion?.comments ?? []).length > 0) {
        setHasUnsentRevision(true);
      }
    }
  }, [blocker.state]);

  const addArea = (newAreas) => {
    const previousAreas = newAreas.slice(0, newAreas.length - 1);
    const lastArea = newAreas[newAreas.length - 1];


    setCurrentArea(lastArea);

    setAreas([...previousAreas, lastArea]);

    setCanAddArea(false);
  };

  const removeArea = (index) => {
    const newAreas = areas.slice(0, index);

    setAreas(newAreas);
    setCurrentArea(null);
    setCanAddArea(true);
  };


  const areaIndex = (area) => {
    const index = areas.findIndex(a => a.x === area.x && a.y === area.y && a.width === area.width && a.height === area.height);

    if (index > -1) {
      return index + 1;
    }

    return null;
  };

  const isCurrentArea = (area) => {
    return currentArea && currentArea.x === area.x && currentArea.y === area.y && currentArea.width === area.width && currentArea.height === area.height;
  };

  if (!designVersion) {
    return null;
  }

  const viewComments = designVersion.comments.filter(c => c.side === view);

  const canDeleteComment = (comment) => {
    if (!editable) {
      return false;
    }

    if (currentAccount && comment.account_id === currentAccount.id) {
      return true;
    }

    return false;
  };

  if (isGetting) {
    return <DopeLoader />;
  }

  return (
    <div className="design-version">
      <div className="design-version-preview">
        <div className="image-preview-container" onClick={() => setView('front')}>
          <img
            src={designVersion.mail_template.front_image_thumbnail_url}
            className={view === 'front' ? 'image-preview selected' : 'image-preview'}
            alt="Postcard Front"
          />
          <div className="image-preview-overlay-text">Front</div>
        </div>
        {designVersion.mail_template.back_image_thumbnail_url && (
            <div className="image-preview-container" onClick={() => setView('back')}>
            <img
              src={designVersion.mail_template.back_image_thumbnail_url}
              className={view === 'back' ? 'image-preview selected' : 'image-preview'}
              alt="Postcard Back"
            />
            <div className="image-preview-overlay-text">Back</div>
          </div>
        )}
      </div>

      <div className="design-version-content">
        <div className="design-version-viewer">
          {view === 'front' && (
            <AreaSelector areas={areas}
              onChange={addArea}
              unit="percentage"
              style={{maxWidth: '100%', maxHeight: '600px'}}
              disabled={!canAddArea || !editable}
              wrapperStyle={{
                maxWidth: '100%',
                maxHeight: '600px',
                position: 'relative',
                width: 'fit-content',
              }}
              onRemove={removeArea}
              isAreaEditable={isCurrentArea}
            >
              <img src={designVersion.mail_template.front_image_url} className="postcard-cover" alt="Postcard Preview"/>
            </AreaSelector>
          )}
          {view === 'back' && (
            <AreaSelector
              areas={areas}
              onChange={addArea}
              unit="percentage"
              style={{maxWidth: '100%', maxHeight: '600px'}}
              disabled={!canAddArea || !editable}
              wrapperStyle={{
                maxWidth: '100%',
                maxHeight: '600px',
                position: 'relative',
                width: 'fit-content',
              }}
              onRemove={removeArea}
              isAreaEditable={isCurrentArea}
            >
              <img src={designVersion.mail_template.back_image_url} className="postcard-cover" alt="Postcard Preview"/>
            </AreaSelector>
          )}
        </div>
        <div className="design-version-comments">
          <div className="design-version-comments-header">
            <h4>Comments ({viewComments.length})</h4>
          </div>
          <div className="design-version-comments-body">
            {viewComments.map((comment, index) => (
              <div key={comment.id} className="version-comment">
                <div className="version-comment-header">
                  {canDeleteComment(comment) && (
                    <div style={{justifyContent: 'flex-end'}}>
                      <IconButton
                        style={{background: 'transparent'}}
                        icon={<TrashIcon />}
                        onClick={() => {
                          actions.deleteComment({ comment_id: comment.id });
                        }} />
                    </div>
                  )}
                  <div>
                    <p className="version-comment-area"><small>{comment.area ? areaIndex(comment.area) : ''}</small> <b>{comment.creator.full_name}</b></p>
                    <p><small>{timeSince(new Date(comment.created_at))}</small></p>
                  </div>
                </div>
                <div className="version-comment-body">
                  <p>{comment.comment}</p>
                </div>
              </div>
            ))}
          </div>
          {editable && (
            <div className="design-version-comments-footer">
              {currentArea && (
                <div className="design-version-comments-area">
                  <div>
                    <span>{areas.length}</span>
                    <p><b>Selected Area</b></p>
                  </div>

                  <IconButton
                    icon={<FaTrash />}
                    onClick={() => {
                      const index = areas.length - 1;
                      removeArea(index);
                    }}
                  />
                </div>
              )}
              <div className="design-version-comments-textbox">
                <textarea  placeholder="Add a comment..." onChange={e => setComment(e.target.value)} value={comment} disabled={isCommenting} />
                <IconButton
                  style={{
                    background: '#255FDF',
                    color: '#fff',
                    borderRadius: '100%',
                  }}
                  icon={<FaPlus />}
                  disabled={isCommenting}
                  onClick={() => {
                    if (comment.trim().length > 0) {
                      postComment();
                    }
                  }}
                />
              </div>

            </div>
          )}

        </div>
        <div className="design-version-actions-placeholder"></div>
        {!viewOnly && inCurrentVersion && !designTeamSide && !pulse && (
          <div
            className="design-version-actions"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '400px',
            }}
          >
            <>

              <DopeButton
                style={{width: '100%'}}
                loading={loading}
                disabled={!hasComments}
                icon={<Icon  as={BiSend} style={{marginRight: '10px'}} />}
                props={{
                  onClick: () => {
                    setLoading(true);
                    requestRevision();
                  },
                  label: 'Send Revisions',
                  buttonClass: 'filled',
                }}
              />
              {!hasComments && (
                <p style={{color: '#666', textAlign: 'center', marginTop: '0.5rem' }}>Add at least one comment to request a revision.</p>
              )}
            </>

          </div>
        )}

      </div>


      <div>
        <h4>{editable ? 'Upload a' : 'A'}dditional files for Version #{index + 1}</h4>
        <div className="design-version-attachments">
          {editable && (
            <div className="design-version-attachments-input">
              <DopeAttachmentDropZone
                validator={(file) => {
                  const isImage = file.type.indexOf('image') === 0;
                  const isPSD = file.name.endsWith('.psd');

                  if (isImage || isPSD) {
                    return null;
                  }

                  return {
                    code: "invalid-type",
                    message: 'File must be an image or a PSD file'
                  };
                }}
                instructions="File must be a .psd, .jpeg, .jpg or .png"
                onUpload={(file) => setAttachment(file)}
                currentFile={attachment}
                onRemove={() => setAttachment(null)}
                loading={isAttaching}
              />
            </div>
          )}
          <div className="design-version-attachments-list">
            {!editable && designVersion.attachments_urls.length === 0 && <p>No attachments</p>}
            {designVersion.attachments_urls.map((attachment, index) => <Attachment attachment={attachment} actions={actions} key={index} />)}
          </div>
        </div>
      </div>

      <DopeConfirmationModal
        open={blocker.state === 'blocked' && hasUnsavedChanges}
        title="Unsaved Changes"
        message="You have unsaved changes. Are you sure you want to leave?"
        onConfirm={() => {
          setHasUnsavedChanges(false);
          setComment('');
          blocker.proceed();
        }}
        onCancel={() => {
          setHasUnsavedChanges(false);
          blocker.reset();
        }}
        confirmLabel="Discard Changes"
        cancelLabel="Keep Editing"
        size="sm"
      />

      <DopeConfirmationModal
        open={blocker.state === 'blocked' && hasUnsentRevision}
        title="Unsaved Changes"
        message="You have added some comments but have not sent your design to revision. Are you sure you want to leave?"
        onConfirm={() => {
          setHasUnsentRevision(false);
          blocker.proceed();
        }}
        onCancel={() => {
          setHasUnsentRevision(false);
          blocker.reset();
        }}
        confirmLabel="Leave"
        cancelLabel="Keep Editing"
        size="sm"
      />

    </div>
  );
};

const DesignRevisionPage = ({ pulse = false,  viewOnly = false }) => {
  const { design, actions } = useDesign();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const { designVersion } = useDesignVersion()

  let currentAccount = null;

  let isAdmin = false;

  if (!viewOnly) {
    currentAccount = authApi.getCurrentAccount();

    isAdmin = authApi.currentUserHasAdminPrivileges();
  }

  const originalStatus = useRef(design.status);

  const [version, setVersion] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);

  const [showApproval, setShowApproval] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [showGettingWorkedOnModal, setShowGettingWorkedOnModal] = useState(false);
  const [showMoveToNeedsReview, setShowMoveToNeedsReview] = useState(false);
  const [showAddDesignModal, setShowAddDesignModal] = useState(false);
  const [showAbandonModal, setShowAbandonModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);

  const [versionOptions, setVersionOptions] = useState([]);

  const navigate = useNavigate();

  const versions = design.design_versions;

  const handleApproval = () => {
    actions.approve();

    navigate(`/designs/${design.id}`);
  };

  useEffect(() => {
    if (design.status === 'in_design' && originalStatus.current !== 'in_design') {
      setShowConfirmation(true);
      setLoading(false);
    }

    originalStatus.current = design.status;
  }, [design.status]);


  useEffect(() => {
    setVersionOptions(versions.map((v, i) => ({ label: `Version #${i + 1}`, value: v.id })));
    const latest = versions[versions.length - 1];
    setLatestVersion(latest);
    setVersion(latest);
  }, [versions]);


  const inCurrentVersion = latestVersion && latestVersion?.id === version?.id;
  const editable = inCurrentVersion && design.status !== 'in_design' && design.status !== 'wip';

  const designTeamSide = design?.status === 'in_design' || design?.status === 'wip';
  const userSide = design?.status === 'needs_review';

  const hasActions = pulse || design.mail_template_format === 'json' || userSide;


  const hasComments = (designVersion?.comments ?? []).length > 0;
  const isApproved = design.status === 'approved';

  const canApprove = inCurrentVersion && (((!designTeamSide && !pulse) || (pulse && !userSide))) && !isApproved;

  const ActionsDropdown = ({ onClose, ...rest }, ref) => {
      return (
        <Popover {...rest}  className={`dope-dropdown design-revision-dropdown`} ref={ref} full arrow={false}>
          <div>
            { pulse && !isApproved && <p
              onClick={() => {
                setLoading(true);
                api.fetchMember(design.id, 'share').then((response) => {
                  // Copy the link to the clipboard
                  navigator.clipboard.writeText(response);
                  dopeUIActions.addFlashMessage({ header: "Success!", body: "Shareable link copied to clipboard.", type: "success" });
                  setLoading(false);
                }).catch((error) => {
                  console.error(error);
                  dopeUIActions.addFlashMessage({
                    header: "Error!",
                    body: `An error occurred while trying to generate a shareable link. ${error.message}`,
                    type: "error"
                  });
                });
              }}
            >
              {loading ? <ReloadIcon className="spin" /> : <FaShare /> }   Share Design
            </p>}
            {design.mail_template_format === 'json' && !isApproved && (
              <Link
                disabled={design.status === 'in_design' || design.status === 'wip'}
                to={design.approved_at ? `/editor/new?type=postcard&size=${design.mail_template.size}&clone=${design.id}` : `/editor/${design.id}?type=postcard&size=${design.mail_template.size}`}
              >
                <EditIcon /> Open In Design Tool
              </Link>
            )}
            {pulse && !isApproved && (
              <p
                onClick={() => {
                  setShowAddDesignModal(true);
                }}
              >
                <FaUpload /> Upload new version
              </p>
            )}
            {pulse && !isApproved && (
              <p
                onClick={() => {
                  setShowGettingWorkedOnModal(true);
                }}
              >
                <MdWorkHistory /> Getting Worked On
              </p>
            )}

            {pulse && (designTeamSide || isApproved) && (
              <p
                onClick={() => {
                  setShowMoveToNeedsReview(true);
                }}
              >
                <MdRateReview /> Move to Needs Review
              </p>
            )}

            {pulse && !isApproved && <hr />}

            {/* {canApprove && (
              <p
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  if (hasComments) {
                    setShowCommentsModal(true);
                  } else {
                    setShowApproval(true);
                  }
                }}
              >
                <MdOutlineCheckCircle /> Approve for Use
              </p>
            )} */}

            {!isApproved && (
              <p
                onClick={() => {
                  setShowAbandonModal(true);
                }}
              >
                <MdOutlineCancel /> Abandon Design
              </p>
            )}

          </div>
        </Popover>
      )
  };

  return (
    <div className="design-revision-page" style={viewOnly ? viewOnlyStyle : {}}>
      {viewOnly && (
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <DopeLogo style={{ height: "41px", cursor: "pointer" }} onClick={() => navigate("/")} />
          <p style={{ marginTop: '1rem', color: '#666' }}><b>{design.account_name}</b></p>
        </div>
      )}
      {(design.status === 'in_design' || design.status === 'wip') && !pulse && (
        <div className="design-version-status">
          <p><b>Sit tight - your revisions are being worked on!</b></p>
          <p>Once our Design Team has completed your requests you can access the newest version in the Needs Review section of your designs.</p>
          <p><small>Revision request sent {timeSince(new Date(design.updated_at))}</small></p>
        </div>
      )}
      <div className="page-header">
        <div>
          <h3>“{design.name}”</h3> <span className="badge">{badge(design)}</span>
        </div>
        <div>
          <Select
            styles={{
              container: (provided, state) => ({
                ...provided,
                minWidth: '250px' })
              }
            }
            options={versionOptions}
            value={versionOptions.find(v => v.value === version?.id)}
            onChange={(v) => setVersion(versions.find(version => version.id === v.value))}
            />
          {!viewOnly && hasActions && (
            <Whisper
              placement="bottomEnd"
              trigger="click"
              speaker={ActionsDropdown({}, null)}
            >

              <IconButton
                className="border"
                appearance="subtle"
                icon={<Icon as={FaEllipsisVertical} />}
                style={{ borderColor: 'hsl(0, 0%, 80%)', background: '#fff' }}
                />
            </Whisper>
          )}
        </div>
      </div>
      <p className="instruction">Draw on your designs and add comments to leave feedback for our design team. If the designs look good, use the approve button below and start sending mail.</p>
      <div>
        {version && <DesignVersion
          version={version.id}
          editable={editable && !viewOnly}
          index={versions.findIndex(v => v.id === version.id)}
          currentAccount={currentAccount}
          inCurrentVersion={inCurrentVersion}
          designTeamSide={designTeamSide}
          hasComments={hasComments}
          loading={loading}
          setLoading={setLoading}
          requestRevision={() => {
            actions.requestRevision();
          }}
          pulse={pulse}
        /> }
      </div>
     {!pulse && !viewOnly && (
        <div className="sticky-footer">
          <div className="sticky-wrapper">
            <DopeButton
              props={{
                onClick: () => {
                  navigate(`/designs`);
                },
                buttonClass: 'outlined',
                label: 'Close',
              }}
              />

              <DopeButton
                disabled={!canApprove}
                props={{
                  onClick: () => {
                    if (loading) {
                      return;
                    }

                    if (hasComments) {
                      setShowCommentsModal(true);
                    } else {
                      setShowApproval(true);
                    }
                  },
                  buttonClass: 'filled',
                  label: 'Approve',
                  disabled: !canApprove,
                }}
                />
            </div>
        </div>
     )}

      <PostcardApprovalDrawer
        open={showApproval}
        onConfirm={handleApproval}
        onCancel={() => setShowApproval(false)}
        loading={loading}
        design={design}
        actions={actions}
        front={latestVersion?.mail_template?.front_image_url}
        back={latestVersion?.mail_template?.back_image_url}
        aspectRatio={sizeToRatio(latestVersion?.mail_template?.size)}
      />

      <AddDesignModal
        open={showAddDesignModal}
        handleClose={() => setShowAddDesignModal(false)}
        designId={design.id}
        editable={false}
      />

      <DopeConfirmationModal
        open={design?.id && showAbandonModal}
        title={`Abandon design “${design?.name}”`}
        message='Are you sure you want to abandon your design? Your design will not be deleted but will be in your “Archived” folder and can always be moved back to your “Needs Review” designs in the future.'
        onConfirm={async () => {
          setLoading(true);
          try {
            await actions.archive();
            setShowAbandonModal(false);
            navigate(`/designs`);
          } catch (e) {
            console.error(e);
          } finally {
            setLoading(false);
          }
        }}
        onCancel={() => setShowAbandonModal(false)}
        errors={design?.errors || []}
        loading={loading}
        confirmLabel='Yes, Abandon Design'
        cancelLabel='Keep Design'
      />

      <DopeConfirmationModal
        size="sm"
        open={design?.id && showGettingWorkedOnModal}
        title={`Move design to Getting Worked On?`}
        message='Are you sure you want to move this design to Getting Worked On? A HubSpot ticket will be created.'
        onConfirm={async () => {
          setLoading(true);
          try {
            await actions.wip();
            setShowGettingWorkedOnModal(false);
            navigate(`/pulse/design_revisions`);
          } catch (e) {
            console.error(e);
          } finally {
            setLoading(false);
          }
        }}
        onCancel={() => setShowGettingWorkedOnModal(false)}
        errors={design?.errors || []}
        loading={loading}
        confirmLabel='Yes, This Design is Getting Worked On'
        cancelLabel='Cancel'
      />

      <DopeConfirmationModal
        size="sm"
        open={design?.id && showMoveToNeedsReview}
        title={`Move “${design?.name}” to Needs Review?`}
        message='This will change the status of the design and make it available to the client so they can add more comments.'
        onConfirm={async () => {
          setLoading(true);
          try {
            await actions.restoreForRevision();
            setShowMoveToNeedsReview(false);
            navigate(`/pulse/design_revisions`);
          } catch (e) {
            console.error(e);
          } finally {
            setLoading(false);
          }
        }}
        onCancel={() => setShowMoveToNeedsReview(false)}
        errors={design?.errors || []}
        loading={loading}
        confirmLabel='Yes, Change Status'
        cancelLabel='Cancel'
      />

      <Modal
        open={design?.id && showCommentsModal}
        onClose={() => setShowCommentsModal(false)}
        size="sm"
      >
        <Modal.Header>
          <Modal.Title style={{fontWeight: 'bold', marginBottom: '1rem'}}>Looks like you left revision comments for our team.</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{display: 'flex', gap: '1rem'}}>
          <MdOutlineDraw style={{fontSize: '3.5rem', flexShrink: 0}} />
          <div style={{color: '#8793A6'}}>In order to approve your design for use please remove those comments. If you're not ready to approve, continue adding revisions and send to our design team when you're ready.</div>
        </Modal.Body>
        <Modal.Footer>
          <Stack justifyContent="flex-end" spacing="1rem">
            <Stack.Item style={{width: 'auto'}} >
              <DopeButton
                props={{
                  onClick: async () => {
                    setShowCommentsModal(false);
                  },
                  buttonClass: 'filled-black',
                }}
              >
                Close
              </DopeButton>
            </Stack.Item>
          </Stack>
        </Modal.Footer>

      </Modal>

      <Modal open={showConfirmation} onClose={() => setShowConfirmation(false)} size="sm">
        <Modal.Body style={{display: 'flex', gap: '1rem'}}>
          <BiSend style={{fontSize: '3.5rem', transform: 'rotate(-25deg)', flexShrink: 0}} />
          <div>
            <Modal.Title style={{fontWeight: 'bold', marginBottom: '1rem'}}>Your revisions were sent to our design team!</Modal.Title>
            <div style={{color: '#8793A6'}}>Turn around on design revisions is usually 24 to 48 hours. You'll receive an email when your revisions are ready!</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Stack justifyContent="flex-end" spacing="1rem">
            <Stack.Item flex={0} style={{width: 'auto'}}>
              <DopeButton
                props={{
                  onClick: () => setShowConfirmation(false),
                  buttonClass: 'outlined',
                  disabled: loading,
                }}
              >
                Close
              </DopeButton>
            </Stack.Item>
            <Stack.Item style={{width: 'auto'}} >
              <DopeButton
                props={{
                  onClick: async () => {
                    setShowConfirmation(false);
                    navigate('/designs');
                  },
                  buttonClass: 'filled-black',
                  disabled: loading,
                }}
                loading={loading}
              >
                Go To My Dashboard
              </DopeButton>
            </Stack.Item>
          </Stack>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default DesignRevisionPage;
