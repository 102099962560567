import React, { useState } from "react";
import {Input, Modal} from 'rsuite';

import { useDopeUI } from "../ui/dopeUISlice";
import authApi from "../auth/authApi";
import DopeApi from "../services/DopeApi";
import DopeButton from "../ui/DopeButton";

const accountApi = new DopeApi("account");

const userRoles = [{
  "label": "Admin",
  "value": "admin"
  }, {
  "label": "Member",
  "value": "member"
}]

const buttonStyles = { width: "400px", background: "#292B2E", padding: "8px 16px", color: "#fff", borderRadius: "24px", fontWeight: 600, fontSize: "14px" }

const AddUserModal = ({ show, showHideModal, refetchAssignments }) => {
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [userAdded, setUserAdded] = useState(false);

  const handleAddUserClick = async () => {
    const userParams = {account_user: {first_name: firstName, last_name: lastName, email: email}}
    setLoading(true)
    try {
      await accountApi.postMember(authApi.getCurrentAccountId(), "create_account_user", userParams);
      refetchAssignments();
      setUserAdded(true);
      dopeUIActions.addFlashMessage({header: "User added successfully", type: "success"});
    } catch (error) {
      dopeUIActions.addFlashMessage({header: "Error adding user", type: "error"});
    } finally {
      setLoading(false)
    }
  }

  const closeModal = () => {
    showHideModal();
    setUserAdded(false);
    setFirstName("");
    setLastName("");
    setEmail("");
  }

  const addUserForm = (
    <div>
      <h5 className="header-5 margin-bottom">Add User</h5>

      <div className="margin-bottom">
        <div className="space-between margin-4-b">
          <label className="label">First Name</label>
          <label className="label optional">Required</label>
        </div>
        <Input placeholder="First Name" value={firstName} onChange={(value) => setFirstName(value)}/>
      </div>

      <div className="margin-bottom">
        <div className="space-between margin-4-b">
          <label className="label">Last Name</label>
          <label className="label optional">Required</label>
        </div>
        <Input placeholder="Last Name" value={lastName} onChange={(value) => setLastName(value)}/>
      </div>

      <div className="margin-bottom">
        <div className="space-between margin-4-b">
          <label className="label">Email</label>
          <label className="label optional">Required</label>
        </div>
        <Input placeholder="Email" value={email} onChange={(value) => setEmail(value)}/>
      </div>

      <div className="to-center full-width">
        <DopeButton
          props={{
            buttonClass: "filled-black",
            styles: buttonStyles,
            label: "Invite user",
            onClick: handleAddUserClick,
          }}
          loading={loading}
        />
      </div>
    </div>
  )

  const confirmAddUser = (
    <div>
      <h5 className="header-5 margin-bottom">User has been invited to your team!</h5>

      <div className="help-text large margin-bottom">
        If the email address provided was already in our system they will be added to your account immediately. If they are not already a user we have sent them an email invitation to create a user. Once they create an account they will be added to your team.
      </div>

      <div className="to-center full-width">
        <DopeButton
          props={{
            buttonClass: "filled-black",
            styles: buttonStyles,
            label: "Close",
            onClick: closeModal,
          }}
        />
      </div>

    </div>
  )

  return (
    <Modal open={show} onClose={closeModal} size="xs">
      <Modal.Header></Modal.Header>
      <Modal.Body className="pad">
        {userAdded ? confirmAddUser : addUserForm}
      </Modal.Body>
    </Modal>
  )
}

export default AddUserModal;
