import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { FaUpload } from "react-icons/fa6";

import { useDesign } from "./designSlice";
import { DopeFormGroup } from "../ui/DopeForm";
import DopePageStepHeader from "../ui/DopePageStepHeader";

import { objectURLToFile } from "../utils/design";

import './Design.scss';
import 'react-image-crop/src/ReactCrop.scss';
import DopeButton from "../ui/DopeButton";
import DopeImageSelect from "../ui/DopeImageSelect";
import Cannot from "../ui/Cannot";
import accountAbility from "../auth/accountAbility";


const nameInput = {
  inputType: "text",
  name: "name",
  props: { label: "Goodie Name" },
};

const senderNameInput = {
  inputType: "text",
  name: "sender_name",
  props: { label: "Sender Name" },
};



const objectiveTypeInput = {
  inputType: "radio",
  name: 'objective_type',
  props: {
    label: "Mailer Tag",
    options: [
      { label: 'Marketing', value: 'marketing', helpText: '(Brand awareness targeting new customers)' },
      { label: 'Thank You', value: 'thank_you', helpText: '(More reviews & referrals from current jobs)' },
      { label: 'Reactivation', value: 'reactivation', helpText: '(Targeting existing customers)' },
    ],
    defaultValue: 'marketing',
  },
};


const GoodieDesignForm = () => {
  const { design, actions } = useDesign();

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const designInputProps = { values: design, onChange: actions.update, errors: design.errors };
  const templateInputProps = { values: design.mail_template, onChange: actions.updateMailTemplate, errors: design.errors };


  const [custom, setCustom] = useState(design.mail_template?.custom_image_url);
  const [customFile, setCustomFile] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    actions.update({ mail_template_type: 'GoodieTemplate', goodie: params.get('goodie'), category: 'chocolate_chip', service: 'dirty_dough' });
  });

  const handleUpload = async () => {
    setLoading(true);

    const now = Date.now();

    const customImage = await objectURLToFile(custom, `custom_${now}.png`);

    await actions.save({
      files: {
        custom_image: customImage,
      },
      status: 'approved',
    });

    setLoading(false);

    navigate('/designs');
  };

  const canSubmit = () => {
    if (!design.name || design.name.trim() === '') {
      return false;
    }

    if (!design.mail_template.sender_name || design.mail_template.sender_name.trim() === '') {
      return false;
    }

    if (!custom) {
      return false;
    }

    return true;
  };

  const cannotCreateTooltip = (
    <div>
      <div>The subscription level you’re on does not include Goodie Boxes.</div>
      <div>Interested in adding Goodie Boxes to your account? Reach out to your Account Manager or our support team for assistance!</div>
    </div>
  )
  // TODO: pass disabled to Cannot children
  const canCreateGoodies = accountAbility.can("create", "Design", design)

  return (
    <div className="goodie-design-form-wrapper">
      <div className="page-header">
        <p className="header-text">Upload New Goodie Design</p>
      </div>

      <div>
        <DopePageStepHeader
          step="1"
          headerText="The Basics"
        />
        <div className="rs-form margin-40-b goodie-basics">
          <div>
            <DopeFormGroup input={nameInput} {...designInputProps} />
          </div>
          <div>
            <DopeFormGroup input={senderNameInput} {...templateInputProps} />
          </div>
          <div>
            <DopeFormGroup input={objectiveTypeInput} {...templateInputProps} />
          </div>
        </div>
      </div>

      <div>
        <DopePageStepHeader
          step="2"
          headerText="Upload Designs"
        />

        <div>
          <h6>Box Customization</h6>
          <div>
            <DopeImageSelect
              name="custom"
              value={custom}
              values={{ custom: design.mail_template?.custom_image, custom_url: design.mail_template?.custom_image_url }}

              onChange={({ custom }) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                  setCustomFile(custom);
                  setCustom(reader.result);
                };

                reader.readAsDataURL(custom);

              }}
              onRemove={(...args) => {
                setCustom(null);
                setCustomFile(null);

              }}
              width="100%"
              height="100%"
              />
          </div>

        </div>
      </div>
      <div className="page-actions">
        <Cannot action="create" subject="Design" data={design} cannotDisplay={{ tooltip: cannotCreateTooltip }} >
          <DopeButton
            disabled={!canSubmit() || !canCreateGoodies}
            style={{ width: "200px" }}
            loading={loading}
            props={{
              label: "Upload as Approved",
              buttonClass: "filled",
              onClick: () => {
                if (canSubmit()) {
                  handleUpload();
                }
              }
            }}
          />
        </Cannot>
      </div>

    </div>
  );
};

export default GoodieDesignForm;
