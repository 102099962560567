import React, { useEffect, useState } from "react";

import { useCampaign } from "./campaignSlice";
import { toDateString } from '../utils/date';
import authApi from "../auth/authApi";

import CloseIcon from '@rsuite/icons/Close';
import { Calendar, IconButton } from 'rsuite';
import CampaignMailerSelect from "./CampaignMailerSelect";
import HotLeadDrawer from "./HotLeadDrawer";

import "./CampaignDispatches.scss";


const calendarLocale = {
  sunday: "Sunday",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  formattedMonthPattern: 'MMMM yyyy',
};

const CampaignDispatches = () => {
  const [campaign, campaignActions] = useCampaign();
  const [date, setDate] = useState(null);
  const [mailersDrawerOpen, setMailersDrawerOpen] = useState(false);
  const [hotLeadDrawerOpen, setHotLeadDrawerOpen] = useState(false);
  const [dispatchTemplate, setDispatchTemplate] = useState(null);
  const [needsSetup, setNeedsSetup] = useState(false);
  const canScheduleToday = authApi.currentUserHasAdminPrivileges();

  useEffect(() => {
    if (needsSetup) {
      setMailersDrawerOpen(false);
      setDispatchTemplate(campaign.dispatches.length - 1);
      setHotLeadDrawerOpen(true);
    }
  }, [campaign?.dispatches, needsSetup]);

  const handleDateSelect = (date) => {
    setDate(toDateString(date));
    setMailersDrawerOpen(true);
  };

  const handleMailerSelect = (mailer) => {
    setMailersDrawerOpen(false);

    const dispatch = {
      date,
      effective_status: 'draft',
      ...mailer,
    };

    campaignActions.addDispatch(dispatch);

    if (mailer.mailer_type === 'hot_lead') {
      setNeedsSetup(true);
    } else {
      setMailersDrawerOpen(false);
    }
  };

  function renderCell(date) {
    const dispatch = campaign.dispatches.find((dispatch) => {
      return dispatch.date === toDateString(date);
    });

    if (dispatch) {
      return (
        <div className="dispatch-calendar-cell" onClick={e => e.stopPropagation()}>
          <img src={dispatch.front_image_url} alt="card-front-image" />
          {dispatch.back_image_url && <img src={dispatch.back_image_url} alt="card-front-image"/>}
          {/*<span className="dispatch-status">{dispatch.effective_status}</span>*/}
          <IconButton className="dispatch-remove" onClick={e => { e.stopPropagation(); campaignActions.removeDispatch(dispatch) }} icon={<CloseIcon />} circle size="xs" />
        </div>
      );
    }

    return (
      <div className="dispatch-calendar-cell">
        <div className="dispatch-add">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <mask id="mask0_1742_5856" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
              <rect x="0.285156" y="0.599976" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1742_5856)">
              <path d="M11.2852 13.6H5.28516V11.6H11.2852V5.59998H13.2852V11.6H19.2852V13.6H13.2852V19.6H11.2852V13.6Z" fill="#494B4E"/>
            </g>
          </svg>
        </div>
      </div>
    );
  }

  function getCellClassName(date) {
    let className = 'calendar-cell ';

    const dispatch = campaign.dispatches.find((dispatch) => {
      return dispatch.date === toDateString(date);
    });

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const isTimeDisabled = canScheduleToday ? date < today : date <= today;
    if (!dispatch && isTimeDisabled) {
      className += 'disabled-calendar-cell';
    } else if (date.getDay() !== 6) { // Saturday already has right border
      className += 'bordered-calendar-cell';
    }

    if (dispatch) {
      className += ' selected-calendar-cell';
    }

    return className;
  }

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <Calendar
        bordered={true}
        onSelect={handleDateSelect}
        renderCell={renderCell}
        cellClassName={getCellClassName}
        defaultValue={tomorrow}
        locale={calendarLocale}
      />

      <CampaignMailerSelect
        onSelect={handleMailerSelect}
        onClose={() => setMailersDrawerOpen(false)}
        open={mailersDrawerOpen}
      />

    <HotLeadDrawer
      open={hotLeadDrawerOpen}
      onClose={(dispatch) => {
        setNeedsSetup(false);
        setHotLeadDrawerOpen(false);
        setDispatchTemplate(null);
        campaignActions.removeDispatch(dispatch);
      }}
      dispatches={campaign.dispatches}
      index={dispatchTemplate}
      onSave={(dispatch) => {
        setNeedsSetup(false);
        setHotLeadDrawerOpen(false);
        setDispatchTemplate(null);
      }}
      actions={campaignActions}
    />
    </>


  );
};

export default CampaignDispatches;
