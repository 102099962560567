import React from "react";

import { MdCancel } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import { useTrackingNumberUI } from "./trackingNumberUISlice";
const styles = {
  cursor: "pointer",
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "20px",
  color: "#292B2E",
  marginBottom: "10px",
  display: "flex",
  alignItems: "center",
};

const lastItem = {
  ...styles,
  marginBottom: "0px",
};

const iconStyles = {
  marginRight: "7px",
  color: "#8793A6",
  fontSize: "18px",
};

const TrackingNumberDropdown = ({ rowData: currentTrackingNumber }) => {
  let { id } = currentTrackingNumber;

  const [trackingNumberUI, trackingNumberUIActions] = useTrackingNumberUI();

  return (
    <>
      <div>
        <Link to={`/tracking_numbers/${id}/calls`} style={styles}><SlGraph style={iconStyles} />See Call Data</Link>
        {currentTrackingNumber.status !== 'canceled' && (<Link to={`/tracking_numbers/${id}`} style={styles}><FaEdit style={iconStyles} />Edit Tracking Number</Link>)}
        {currentTrackingNumber.status !== 'canceled' && (<p onClick={() => trackingNumberUIActions.setCancelTrackingNumberId(id)} style={lastItem}><MdCancel style={iconStyles} />Cancel Tracking Number</p>)}
      </div>
    </>
  )
}

export default TrackingNumberDropdown;
