
import FlatCardIcon from '../icons/FlatCardIcon';
import FoldedCardIcon from '../icons/FoldedCardIcon';

import './HandwrittenCardItem.scss';

export default function HandwrittenCardItem ({ onClick, selected, showCover, ...card }) {
  const isFlat = card.closed_height === card.open_height && card.closed_width === card.open_width;

  return (
    <div className={`CardItem${ selected ? ' CardItem--selected' : ''}`} key={`highlighted_${card.id}`} >
      {showCover!== false && (
        <div className='CardItem__cover' onClick={() => onClick(card)} >
          <img src={card.cover} />
        </div>
      )}
      <div className='CardItem__body'>
        {isFlat ? <FlatCardIcon /> : <FoldedCardIcon />}
        <div>
          <p><b>{card.name}</b></p>
          <small>{isFlat ? 'Flat' : 'Folded'} Card</small>
        </div>
      </div>
    </div>
  );
}
