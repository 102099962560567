import React from "react";

const HomePinIcon = ({ ...props }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.9987 17.3327H14.6654V13.666H17.332V17.3327H19.9987V10.9993L15.9987 8.33268L11.9987 10.9993V17.3327ZM15.9987 25.7994C18.7098 23.3105 20.7209 21.0493 22.032 19.016C23.3431 16.9827 23.9987 15.1771 23.9987 13.5993C23.9987 11.1771 23.2265 9.19379 21.682 7.64935C20.1376 6.1049 18.2431 5.33268 15.9987 5.33268C13.7543 5.33268 11.8598 6.1049 10.3154 7.64935C8.77092 9.19379 7.9987 11.1771 7.9987 13.5993C7.9987 15.1771 8.65425 16.9827 9.96536 19.016C11.2765 21.0493 13.2876 23.3105 15.9987 25.7994ZM15.9987 28.4327C15.6876 28.4327 15.3765 28.3771 15.0654 28.266C14.7543 28.1549 14.4765 27.9882 14.232 27.766C12.7876 26.4327 11.5098 25.1327 10.3987 23.866C9.28759 22.5993 8.35981 21.3716 7.61536 20.1827C6.87092 18.9938 6.30425 17.8493 5.91536 16.7493C5.52648 15.6493 5.33203 14.5993 5.33203 13.5993C5.33203 10.266 6.40425 7.61046 8.5487 5.63268C10.6931 3.6549 13.1765 2.66602 15.9987 2.66602C18.8209 2.66602 21.3043 3.6549 23.4487 5.63268C25.5931 7.61046 26.6654 10.266 26.6654 13.5993C26.6654 14.5993 26.4709 15.6493 26.082 16.7493C25.6931 17.8493 25.1265 18.9938 24.382 20.1827C23.6376 21.3716 22.7098 22.5993 21.5987 23.866C20.4876 25.1327 19.2098 26.4327 17.7654 27.766C17.5209 27.9882 17.2431 28.1549 16.932 28.266C16.6209 28.3771 16.3098 28.4327 15.9987 28.4327Z" />
    </svg>
  );
}

export default HomePinIcon;
