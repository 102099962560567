import React from "react";
import {Drawer} from "rsuite";

import {useAccount} from "../accounts/accountSlice";
import ProductAccountPricing from "../purchasing/ProductAccountPricing";

const EditAccountPricingDrawer = ({ accountId, onClose, open, reload }) => {
  const [account, accountActions] = useAccount();

  return (
    <div style={{fontFamily: "IBM Plex Sans"}}>
      {account &&
        <Drawer open={open} onClose={onClose} size="md" closeButton={true}>
          <Drawer.Body>
            <div>
              <div className="margin-bottom">
                <h3 className="header-3">Update Pricing Tier</h3>
              </div>

              <div className="flex row margin-bottom vertical-align">
                {account.logo_url && <div className="account-logo margin-8-r">
                  <img src={account.logo_url} alt={`${account.name}-logo`} className="account-logo"/>
                </div>}

                <div>
                  <div className="header-6 non-bold">{account.name}</div>
                  <div className="label large">{account.account_email}</div>
                </div>
              </div>

              <div>
                <ProductAccountPricing providedAccountId={accountId} reload={reload}/>
              </div>
            </div>
          </Drawer.Body>
        </Drawer>
      }
    </div>
  );
};

export default EditAccountPricingDrawer;
