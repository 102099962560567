import React from "react";
import DopePill from "../DopePill";

const ListPills = ({lists}) => {
  const displayList = lists.length > 0 ? lists[0].name : "";
  const displayText = displayList.length > 20 ? displayList.slice(0, 20) + "..." : displayList;

  return (
    <div>
      {lists.length > 0 ? (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <DopePill text={displayText} size="small" pillType="tag"/>
          {lists.length > 1 &&
            <div style={{fontSize: "11px", fontWeight: 700, color: "#255FDF", marginLeft: "2px"}}>
              +{lists.length - 1}
            </div>}
        </div>) : (
      <div style={{fontSize: "11px", fontWeight: 500, color: "#000000"}}>
        none
      </div>
    )}
    </div>
  );
}

export default ListPills;
