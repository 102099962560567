import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripeApi from "./StripeApi";
import DopePlaceholder from "../ui/DopePlaceholder";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const stripeApi = new StripeApi;


// TODO: make useStripe hook instead of this wrapper
const StripeCardAdderWrapper = ({ Children, props }) => {
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    stripeApi.get("setup_stripe_intent").then((response) => {
      setClientSecret(response.client_secret);
    });
  }, []);

  return (
    <>
      { clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret: clientSecret, currency: "usd" }} >
          <Children clientSecret={clientSecret} props={props} />
        </Elements>
      ) : (<DopePlaceholder type={"image"} rows={5} />)}
    </>
  )
}

export default StripeCardAdderWrapper;