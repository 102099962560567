import React from "react";
import SpeakerIcon from '@rsuite/icons/Speaker';
import ImageIcon from '@rsuite/icons/Image';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import GearIcon from '@rsuite/icons/Gear';

const backgroundColors = {
  Account: "#C15D00",
  Automation: "#002A6B",
  Campaign: "#45669A",
  Design: "#009EA8",
};

const sizeStyle = { fontSize: "15px" };

const activityIcon = (activityType) => {
  let icon;

  switch (activityType) {
    case "Account":
      icon = <UserBadgeIcon style={sizeStyle} />;
      break;
    case "Automation":
      icon = <GearIcon style={sizeStyle} />;
      break;
    case "Campaign":
      icon = <SpeakerIcon style={sizeStyle} />;
      break;
    case "Design":
      icon = <ImageIcon style={sizeStyle} />;
      break;
    default:
      icon = <SpeakerIcon style={{background: "#002A6B"}} />;
  }
  return icon;
}

const RecentActivityIcon = ({activityType}) => {
  return (
    <div className="recent-activity-icon" style={{ background: backgroundColors[activityType]}}>
      {activityIcon(activityType)}
    </div>
  );
}

export default RecentActivityIcon;
