import React, { useState } from 'react';
import { Drawer, SelectPicker } from "rsuite";

import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeButton from "../ui/DopeButton";
import contact from "../contacts/Contact";

const listApi = new DopeApi('list');

const AddContactsToListDrawer = ({ open, onClose, contactIds }) => {
  const [selectedListId, setSelectedListId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dopeUI, dopeUIActions] = useDopeUI();
  const { data: lists, onSearchInput } = useQuery2({ api: listApi, initialSearchScope: "name" });

  const addContactsToList = async () => {
    setLoading(true);
    try {
      await listApi.update({ id: selectedListId, actions: [{ name: "add_contacts_to_list", args: contactIds }] });
      dopeUIActions.addFlashMessage({ type: "success", header: "Contacts added to list successfully" });
      onClose();
    } catch (error) {
      dopeUIActions.addFlashMessage({ type: "error", header: "Error adding contacts to list" });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Drawer open={open} onClose={onClose}>
      <Drawer.Body>
        <div className="flex space-between column full-height">
          <div>
            <h3 className="header-3 margin-bottom">What list should we add these contacts to?</h3>

            <label className="label">List Name</label>
            <SelectPicker
              data={lists.map((list) => ({label: list.name, value: list.id}))}
              style={{width: "100%"}}
              placeholder="Search and select a list"
              onSearch={onSearchInput}
              onChange={(value) => setSelectedListId(value)}
            />
          </div>

          <div className="flex space-between">
            <DopeButton
              props={{
                onClick: onClose,
                label: "Cancel",
                buttonClass: "outlined-black action-button",
              }}
            />

            <DopeButton
              disabled={!selectedListId}
              loading={loading}
              props={{
                onClick: addContactsToList,
                label: "Add to List",
                buttonClass: "filled-black action-button",
              }}
            />
          </div>
        </div>
      </Drawer.Body>

    </Drawer>
  )
}

export default AddContactsToListDrawer;
