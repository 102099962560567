import React from "react";
import DopeTabs from "../ui/DopeTabs";
import AccountsPricingTable from "./AccountsPricingTable";
import ProductStandardPricing from "../purchasing/ProductStandardPricing";
import FeaturesTable from "./FeaturesTable";

const PulsePricing = () => {

  return (
    <div className="page">
      <div className="page-header">
        <p className="header-text">Pricing</p>
      </div>

      <DopeTabs>
        <DopeTabs.Tab name="per_account" label="Per Account">
          <AccountsPricingTable />
        </DopeTabs.Tab>

        <DopeTabs.Tab name="standard" label="Standard Pricing">
          <ProductStandardPricing />
        </DopeTabs.Tab>

        <DopeTabs.Tab name="features" label="Features">
          <FeaturesTable />
        </DopeTabs.Tab>
      </DopeTabs>
    </div>
  )
}

export default PulsePricing
