import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox, InputPicker, InputNumber } from "rsuite";
import humanizeKey from "../integrations/util/humanizeKey";
import DataAxleDateRangeInput from "./DataAxleDateRangeInput";

const relationConverter = (relation) => {
  switch (relation) {
    case "string":
      return "equals";
    case "integer":
      return "between";
    case "date":
      return "between";
    case "integer_range":
      return "between";
    case "date_range":
      return "range";
    default:
      return "equals";
  }
}

const filterToInitialValue = (filter) => {
  if (filter.presence_requested) {
    return {
      value: true,
      relation: "present",
      data_type: filter.data_type,
    };
  } else if (filter.data_type === "string") {
    let value;
    if (Object.keys(filter.data_values).length > 0) {
      value = Object.keys(filter.data_values)[0];
    } else {
      value = filter.accepted_inputs.split(",").map(input => input.trim())[0];
    }
    return {
      value,
      relation: relationConverter(filter.data_type),
      data_type: filter.data_type
    };
  } else {
    return {
      upper_bound: filter.upper_bound,
      lower_bound: filter.lower_bound,
      relation: relationConverter(filter.data_type),
      data_type: filter.data_type,
      data_values: filter.data_values,
    };
  }
};

const DataAxleInputRow = ({ values, filter, onChange, onRemove, ...rest }) => {
  const filterKey = filter.filter_attribute;
  const filtersValue = values["data_axle_filters"];
  let filterValue = filtersValue[filter.filter_attribute];

  const checked = !!filterValue;

  const handleCheck = () => {
    if (checked) {
      onRemove(filter.filter_attribute);
    } else {
      const filterValue = filterToInitialValue(filter);
      if (filterValue.data_type === "boolean") {
        filterValue.value = true;
      }
      onChange({ [filterKey]: filterValue });
    }
  };

  const handleFilterChange = (value) => {
    onChange(value);
  };

  return (
    <Row className="vertical-align">
      <Col xs={2}>
        <Checkbox
          checked={checked}
          onChange={handleCheck}
          {...rest}
        />
      </Col>
      <DataAxleFilterInput
        filter={filter}
        values={values}
        value={filterValue}
        disabled={!checked}
        handleFilterChange={handleFilterChange}
      />
    </Row>
  );
};

const DataAxleInputPicker = ({ filter, values, value, disabled, handleFilterChange }) => {

  let formatted_inputs;
  if (filter.accepted_inputs !== null) {
    formatted_inputs = filter.accepted_inputs.split(",").map(input => ({ label: humanizeKey(input), value: input.trim() }));
  } else if (Object.keys(filter.data_values).length > 0) {
    formatted_inputs = Object.keys(filter.data_values).map(input => ({ label: filter.data_values[input], value: input.trim() }));
  }

  const handleInputChange = (value) => {
    const allFilters = values["data_axle_filters"];
    return {
      ...allFilters,
      [filter.filter_attribute]: {
        ...allFilters[filter.filter_attribute],
        value: value
      },
    }
  };

  return (
    <InputPicker
      data={formatted_inputs}
      searchable={true}
      onChange={value => handleFilterChange(handleInputChange(value))}
      disabled={disabled}
      style={{width: 200}}
      value={value?.value}
      placement={"auto"}
    />
  )
}

const DataAxleFilterInput = ({ filter, disabled, values, value, handleFilterChange }) => {
  const { data_type } = filter;

  if (filter.presence_requested) {
    return <DataAxleBooleanInput disabled={disabled} />;
  }

  // Some filters have a list of accepted inputs i.e. square footage, greenscore
  if (Object.keys(filter.data_values).length > 0) {
    return <DataAxleInputPicker
      filter={filter}
      values={values}
      value={value}
      disabled={disabled}
      handleFilterChange={handleFilterChange}
    />;
  }

  switch (data_type) {
    case "string":
      return <DataAxleInputPicker
              filter={filter}
              values={values}
              value={value}
              disabled={disabled}
              handleFilterChange={handleFilterChange}
            />;
    case "integer":
      return <DataAxleRangeInput
        filter={filter}
        inputType={"number"}
        disabled={disabled}
        values={values}
        value={value}
        handleFilterChange={handleFilterChange}
      />;
    case "date":
      return null;
    case "date_range":
      return <DataAxleDateRangeInput
        filter={filter}
        inputType={"date"}
        disabled={disabled}
        values={values}
        value={value}
        handleFilterChange={handleFilterChange}
      />;
    case "array":
      return null;
    case "boolean":
      return <DataAxleBooleanInput disabled={disabled} />;
    case "integer_range":
      return <DataAxleRangeInput
        filter={filter}
        inputType={"number"}
        disabled={disabled}
        values={values}
        value={value}
        handleFilterChange={handleFilterChange}
      />;
    case "time":
      return null;
    case "geo_point":
      return null;
    case "float":
      return null;
    default:
      throw new Error(`Invalid input type: ${data_type}`);
  }
}

const DataAxleBooleanInput = ({disabled}) => {
  return (
    <>
      <div className={disabled ? "color-text-secondary" : "color-black"}>
        {disabled ? "Filter Inactive" : "Filter Active"}
      </div>
    </>
  )
}

const DataAxleRangeInput = ({ inputType, values, value, disabled, filter, handleFilterChange }) => {
  const handleInputChange = (newValue, boundType) => {
    const allFilters = values["data_axle_filters"];
    return {
      ...allFilters,
      [filter.filter_attribute]: {
        ...allFilters[filter.filter_attribute],
        [boundType]: newValue
      }
    }
  };

  return (
    <>
      <Col xs={8}>
        <InputNumber
          disabled={disabled}
          onChange={value => handleFilterChange(handleInputChange(value, "lower_bound"))}
          inputType={inputType}
          defaultValue={filter.lower_bound}
          name={`data_axle_filters.${filter.filter_attribute}.lower_bound`}
          value={value?.lower_bound}
        />
      </Col>
      <Col xs={8}>
        <InputNumber
          disabled={disabled}
          onChange={value => handleFilterChange(handleInputChange(value, "upper_bound"))}
          inputType={inputType}
          defaultValue={filter.upper_bound}
          name={`data_axle_filters.${filter.filter_attribute}.upper_bound`}
          value={value?.upper_bound}
        />
      </Col>
    </>
  )
}
export default DataAxleInputRow;
