import React from "react";
import { Button, Loader } from "rsuite";
import classNames from "classnames";

import './DopeButton.scss';

// classes: filled, outlined-red, tonal, outlined-black, text-button, elevated
// TODO: I loaded this up to work without props prop, should converge to single style
const DopeButton = ({ icon, disabled = false, loading = false, className = '', children, props = {}, ...rest }) => { // TODO, should all be one props object
  const { buttonClass = "", label, styles, type, ...rest2 } = props;

  let button;
  switch (className) {
    case 'icon-clickable':
      button = <Button {...rest} {...rest2} type={type} className={`dope-button ${buttonClass} ${className}`} disabled={disabled} loading={loading} style={styles}>
        <div className="icon-clickable-items">
          <div className="icon">{icon}</div>
          <div className="text pad-sm-t">{label || children}</div>
        </div>
      </Button>;
      break;
    default:
      button = <Button style={styles} {...rest} {...rest2} type={type} className={`dope-button ${buttonClass} ${className}`} disabled={disabled} loading={loading}>
        {icon} {label || children}
      </Button>
  }

  return (
    <>
      {button}
    </>
  );
}

const DopeLinkButton = ({ disabled = false, loading = false, text, children, onClick = () => {} }) => {
  const classes = classNames('link vertical-align gap-xs', { disabled });

  const loader = loading && <Loader size="xs" />;

  return (
    <span className={classes} onClick={onClick}>
      {loader} {text || children}
    </span>
  );
}

export default DopeButton;

export {
  DopeLinkButton,
  DopeButton
}
