import React, { useState } from "react";
import {Accordion, Input, InputGroup} from "rsuite";
import { Icon } from '@rsuite/icons';
import { MdClear } from "react-icons/md";
import { FaCircleExclamation } from "react-icons/fa6";

import { useAccount } from "../accounts/accountSlice";
import StripeApi from "../stripe/StripeApi";
import DopeButton from "../ui/DopeButton";

const stripeApi = new StripeApi;

const CouponApplier = () => {
  const [loading, setLoading] = useState(false);
  const [account, accountActions] = useAccount();

  const handleApplyPromoCode = async ()  => {
    if (!account.subscription_promo_code) {
      accountActions.update({ subscription_promo_code_data: { verified: false, error: "Please enter a valid coupon code" } });
      return;
    }

    setLoading(true)

    const stripeData = await stripeApi.post("verify_stripe_promo_code", { promo_code: account.subscription_promo_code })

    if (stripeData.error) {
      await accountActions.update({ subscription_promo_code_data: { verified: false, error: stripeData.error } });
    } else {
      await accountActions.updatePromoCode(stripeData.promo_code);
    }

    setLoading(false)
  }

  const handleRemovePromoCode = async () => {
    await accountActions.update({ subscription_promo_code: "", subscription_promo_code_data: { verified: false, error: null } });
  }

  return (
    <Accordion>
      <Accordion.Panel header="Have an affiliated code?" style={{ color: "#255FDF", fontWeight: 600, fontSize: "14px", width: '100%' }}>
        <div className="flex row margin-4-b">
          <InputGroup>
            <Input
              placeholder="Enter Coupon Code"
              value={account?.subscription_promo_code}
              onChange={value => accountActions.update({ subscription_promo_code: value })}
              disabled={account.subscription_promo_code_data?.verified}
            />

            {(account.subscription_promo_code_data?.verified || account.subscription_promo_code_data?.error) && <InputGroup.Button>
              <Icon as={MdClear} onClick={handleRemovePromoCode}/>
            </InputGroup.Button>}
          </InputGroup>

          <DopeButton
            props={{
              label: "Apply Code",
              buttonClass: "text-link",
              onClick: handleApplyPromoCode
            }}
            loading={loading}
            disabled={account.subscription_promo_code_data?.verified}
          />
        </div>

        {account?.subscription_promo_code_data?.error && <div className="label non-bold color-text-error-red"><Icon as={FaCircleExclamation} /> {account.subscription_promo_code_data?.error}</div>}
        {account?.subscription_promo_code_data?.name && <div className="label non-bold color-text-blue">
          <Icon as={FaCircleExclamation} />
          {account.subscription_promo_code_data.percent_off ? `${account.subscription_promo_code_data.percent_off}%` : `$${account.subscription_promo_code_data.amount_off}`} discount applied!
        </div>}
      </Accordion.Panel>
    </Accordion>
  );
}

export default CouponApplier;
