import React from "react";

const FlatCardIcon = () => {
  return (
    <svg width="26" height="17" viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1H25V16H1V1Z" fill="white" stroke="black" stroke-width="2" stroke-linejoin="round"/>
    </svg>
  );
}

export default FlatCardIcon;
