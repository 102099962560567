import React, { useState } from 'react';

import authApi from "./authApi";
import { useObjectState } from '../hooks/useObjectState';
import DopeForm from '../ui/DopeForm';

const initialCredentials = {
  email: '',
  password: '',
};

const loginInputs = [
  {
    inputType: 'text',
    name: 'email',
    props: {
      label: 'Email',
      type: 'email',
    }
  },
  {
    inputType: 'text',
    name: 'password',
    props: {
      label: 'Password',
      type: 'password',
    }
  },
];

const submitButtonProps = {
  styles: {
    backgroundColor: '#EA0029',
    width: '100%',
    borderRadius: '24px',
    fontSize: '14px',
    fontWeight: 600,
  },
  className: 'dope-button filled'
}

const LoginForm = () => {
  const [credentials, updateCredentials, setCredentials, clearCredentials] = useObjectState(initialCredentials);
  const [errors, setErrors] = useState(null);

  const handleLogin = async (event) => {
    try {
      await authApi.login(credentials);
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  return (
    <DopeForm
      submitText="Sign In"
      onSubmit={handleLogin}
      inputs={loginInputs}
      values={credentials}
      onChange={updateCredentials}
      errors={errors}
      props={{ submitButtonProps: submitButtonProps }}
    />
  );
};

export default LoginForm;
