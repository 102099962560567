import React, {useEffect, useState} from "react";
import { DateRangePicker } from "rsuite";
import {timestampToDateStr, toDateString} from "../utils/date";

const DataAxleDateRangeInput = ({ value, filter, disabled, handleFilterChange}) => {
  const [dateRange, setDateRange] = useState([]);

  const setInitialDateRange = () => {
    const today = new Date();
    const currentDate = today.toISOString().slice(0, 10); // yyyy-mm-dd to match rsuite date range input
    const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 1)).toISOString().slice(0, 10);

    handleFilterChange({
      [filter.filter_attribute]: {
        relation: "date_range",
        value: [oneYearAgo, currentDate],
        lower_bound: oneYearAgo,
        upper_bound: currentDate
      }
    });
  };

  useEffect(() => {
    if (!disabled && value && !value.lower_bound && !value.upper_bound) {
      setInitialDateRange();
    }
  }, [disabled]);

  useEffect(() => {
    if (!value) {
      setDateRange([]);
    }
  }, [value]);

  const handleDateSelect = (value) => {
    let newStartDate, newEndDate;
    if (Array.isArray(value)) {
      newStartDate = value[0];
      newEndDate = value[1];
    } else {
      newStartDate = "";
      newEndDate = "";
    }

    setDateRange(value);
    handleFilterChange({
      [filter.filter_attribute]: {
        relation: "date_range",
        value: [toDateString(newStartDate), toDateString(newEndDate)],
        lower_bound: toDateString(newStartDate),
        upper_bound: toDateString(newEndDate)
      }
    });
  };

  let placeholder = "Select Date Range";
  if (value && value.lower_bound !== null && value.upper_bound !== null) {
    placeholder = `${value.lower_bound} - ${value.upper_bound}`;
  }

  const today = new Date();
  const todayDateObject = new Date(today);
  const lastYear = new Date(todayDateObject.setFullYear(todayDateObject.getFullYear() - 1));
  const predefinedDateRanges = [
    {
      label: 'Past year',
      value: [lastYear, today],
    }
  ];

  return (
    <DateRangePicker
      value={dateRange}
      onChange={value => handleDateSelect(value)}
      disabled={disabled}
      cleanable={false}
      format="yyyy-MM-dd"
      placeholder={placeholder}
      size="md"
      style={{ width: "240px" }}
      placement="auto"
      ranges={predefinedDateRanges}
    />
  );
}

export default DataAxleDateRangeInput;
