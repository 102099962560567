import axios from 'axios';

const client = axios.create({
  baseURL: 'https://api.handwrytten.com/v2',
  headers: {
    'Content-Type': 'application/json',
  },
});

const HandwryttenAPI = {
  cards: (categoryId = null, page = 0) => {
    return client.get(`/cards/list?page=${page}${categoryId ? `&category_id=${categoryId}` : ''}`);
  },

  fonts: () => {
    return client.get(`/fonts/list`);
  },

  giftCards: () => {
    return client.get(`/giftCards/list`);
  },
};

export default HandwryttenAPI;
