import React from "react";

import { toDisplayName } from "../utils/railsNames";

import { IconButton } from "rsuite";
import CloseIcon from '@rsuite/icons/Close';

import "./DopeSelectionPill.scss";

const DopeSelectionPill = ({ item, name, onRemove }) => {
  return (
    <span className="selection-pill">
      <span className="selection-pill-name">{name || toDisplayName(item.name)}</span>
      {onRemove && <IconButton className="selection-pill-remove" onClick={() => onRemove(item)} icon={<CloseIcon />} circle size="xs" />}
    </span>
  );
};

DopeSelectionPill.List = ({ children }) => {
  return (
    <div className="selection-pill-list">
      {children}
    </div>
  );
}

export default DopeSelectionPill;
