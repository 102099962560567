import React from "react";
import { Container } from "rsuite";

const DopeContentPage = ({ pageTitle, subHeader, pageContent, actionButtons, navLink }) => {

  return (
    <Container className="main-content-page" >
      {navLink && navLink}
      <Container className="content-page-body">
        <div className="vertical-align space-between margin-bottom">
          <div className="flex column">
            <h2 className="header-2">{pageTitle}</h2>
            <h5 className="label large color-text-secondary">{subHeader}</h5>
          </div>
          <div className="flex row" >
            {actionButtons}
          </div>
        </div>
        {pageContent}
      </Container>
    </Container>
  );
}

export default DopeContentPage;
