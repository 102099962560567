import { useState } from 'react';

export const useObjectState = (initialState) => {
  const [state, setState] = useState(initialState);

  const updateState = (update) => {
    setState(prevState => ({ ...prevState, ...update }));
  };

  const resetState = () => {
    setState(initialState);
  };

  return [state, updateState, setState, resetState];
};

export default useObjectState;
