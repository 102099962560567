import React from "react";
import { Link } from "react-router-dom";
import { Icon } from '@rsuite/icons';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import { MdOutlineCampaign } from "react-icons/md";
import { MdOutlineUploadFile } from "react-icons/md";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";

const QuickActions = () => {
  return (
    <div className="quick-actions">
      <h4 style={{ marginBottom: "15px"}} className="overview-header">Quick Actions</h4>
      <Link className="quick-links" to={"/campaigns"} state={{openDrawer: true}}>
        <div className="quick-links-text">
          <div style={{display :"flex", flexDirection: "row", alignItems: "center"}}>
            <Icon as={MdOutlineCampaign} size={"24px"} style={{marginRight: "8px"}} />
            Create a campaign
          </div>
        </div>
        <ArrowRightLineIcon />
      </Link>

      <Link className="quick-links" to={"/lists_and_contacts"} state={{uploadList: true}} >
        <div className="quick-links-text">
          <div style={{display :"flex", flexDirection: "row", alignItems: "center"}}>
            <Icon as={MdOutlineUploadFile} size={"24px"} style={{marginRight: "8px"}} />
            Upload a list
          </div>
        </div>
        <ArrowRightLineIcon />
      </Link>

      <Link to={{}} className="quick-links" onClick={() => window.open('https://forms.monday.com/forms/28ebd4dbffa54716a1bb26abc6bbd073?r=use1', '_blank')} >
        <div className="quick-links-text">
          <div style={{display :"flex", flexDirection: "row", alignItems: "center"}}>
            <Icon as={MdOutlineAddPhotoAlternate} size={"24px"} style={{marginRight: "8px"}} />
            Request a design
          </div>
        </div>
        <ArrowRightLineIcon />
      </Link>
    </div>
  );
}

export default QuickActions;
