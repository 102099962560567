import React, { useEffect, useRef } from 'react';
import { useParams, useLocation, Outlet, useNavigate } from 'react-router-dom';
import useDopePageTabs from '../ui/useDopePageTabs';
import DopePageLoader from '../ui/DopePageLoader';
import DopeButton from "../ui/DopeButton";

import './Slideover.scss';

import { useSlideover } from './slideoverSlice';

const slideoverPages = [{
  name: 'Slideover',
  path: '/pulse/slideovers/:id/edit',
  isValid: (slideover) => {
    return true;
  }
}];

const SlideoverPages = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { slideover, actions, isSaving } = useSlideover(id);


  let exitUrl = '/pulse/slideovers';
  let exitText = 'Back to all Slideovers';

  const pages = slideoverPages;

  const {
    PageTabs,
    BackLink,
    navigateForward,
    exit,
    isLastPage,
    isValid,
  } = useDopePageTabs({ pages, resource: slideover, exitUrl, exitText });

  const prevSlideoverIdRef = useRef();
  const prevStatusRef = useRef();

  useEffect(() => {
    const prevSlideoverId = prevSlideoverIdRef.current;
    const prevStatus = prevStatusRef.current;

    if (slideover.id !== 'new' && prevSlideoverId === 'new') {
      navigate(`/pulse/slideovers/${slideover.id}/edit`, { replace: true });
    }

    prevSlideoverIdRef.current = slideover.id;
    prevStatusRef.current = slideover.status;
  }, [slideover.id, slideover.status]);


  const handleSave = (status = 'active') => {
    return actions.save({ status });
  };

  const saveButton = (
    <DopeButton
      loading={isSaving}
      // disabled={!canCreateDesign}
      props={{
        onClick: () => handleSave('active'),
        label: 'Save',
        buttonClass: 'filled',
      }}
    />
  );

  return (
    <div className='campaign-page-wrapper'>
      <BackLink className='margin' />

      <div className='campaign-page slideover-page'>

        <div className="" >
          <Outlet />
        </div>
          <div className="footer-actions" style={{justifyContent: 'flex-end'}}>
            {saveButton}
          </div>
      </div>
    </div>
  );
}

const Slideover = () => {
  const { id } = useParams();
  const location = useLocation();


  const { slideover, actions } = useSlideover();

  useEffect(() => {
    if (!slideover) {
      if (id) {
        actions.get(id + location.search);
      }
    }

    return actions.resetToInitial;
  }, []);

  if (!slideover) {
    return <DopePageLoader />;
  }

  return <SlideoverPages />;
};

export default Slideover;
