import React, { useState } from "react";

import { useIntegrations } from "../integrationsSlice";

const ConnectionForm = ({ dataSource, onClickCancel }) => {
  const [form, setForm] = useState({});
  const [success, setSuccess] = useState(false);

  const { createConnection, creatingConnection, connectionError } = useIntegrations();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const connectionData = {
      dataSource: dataSource.value,
      credentials: form,
    };

    createConnection(connectionData)
      .then(action => {
        const success = action.meta.requestStatus === 'fulfilled';
        if (success) {
          setSuccess(true);
        }
      });
  };

  return (
    <div>
      <div className={`connection-form ${creatingConnection ? 'loading' : ''} ${success ? 'success' : ''}`}>
        <h3>{dataSource.label}</h3>
        {dataSource.config.inputs.map((input) => (
          <div key={input.name} className="connection-form__field">
            <label htmlFor={input.name}>{input.label}</label>
            <input
              type={input.type}
              name={input.name}
              value={form[input.name] || ''}
              onChange={handleChange}
              disabled={creatingConnection || success}
            />
          </div>
        ))}
        <div className="connection-form__actions">
          <button className="connection-form__cancel_button" onClick={onClickCancel}>Cancel</button>
          <button className="connection-form__connect_button" onClick={handleSubmit}>Connect</button>
        </div>
      </div>

      {creatingConnection && (
        <div className="connection-form__loading">
          <div className="connection-form__loading__spinner" />
          <div className="connection-form__loading__text">Connecting...</div>
        </div>
      )}

      {connectionError && (
        <div className="connection-form__error color-text-red">
          Error: {connectionError}
        </div>
      )}

      {success && (
        <div className="connection-form__success">
          <h4 className="message">Success!</h4>
          <button className="done" onClick={onClickCancel}>Done</button>
        </div>
      )}
    </div>
  );
}

export default ConnectionForm;
