const Credits = ({ row }) => {
  const { credit_balances } = row;

  const postcards = credit_balances.find((cb) => cb.product_name.indexOf('postcard') === 0 || cb.product_name.indexOf('eddm') === 0);
  const hwc = credit_balances.find((cb) => cb.product_name === 'handwritten_card');
  const goodies = credit_balances.find((cb) => cb.product_name === 'goodies');

  return (
    <div className="agency-client-credits">
      <p><span>Postcards</span><span>{postcards ? postcards.balance : 0}</span></p>
      <p><span>HWC</span><span>{hwc ? hwc.balance : 0}</span></p>
      <p><span>Goodies</span><span>{goodies ? goodies.balance : 0}</span></p>
    </div>
  );
};

const Automations = ({ row }) => {
  const { automations } = row;

  return (
    <div className="agency-client-credits">
      <p><span>Active</span><span>{automations.active || 0}</span></p>
      <p><span>In Validation</span><span>{automations.in_validation || 0}</span></p>
    </div>
  );
};

const columns = [
  { type: "stacked_text", dataKey: ['name', 'account_email'], label: "Name", flexGrow: 3, sortable: true, },
  { type: "cents", dataKey: 'statistics.spent', label: "Spend this month", flexGrow: 2, sortable: true, },
  { type: "text", dataKey: 'statistics.mail_count', label: "Mail This Month", flexGrow: 2, sortable: true, placeholder: '0', },
  { type: "text", dataKey: 'last_month_statistics.mail_count', label: "Mail Last Month", flexGrow: 2, sortable: true, placeholder: '0', },
  { type: "custom", dataKey: 'automations', label: "Automations", flexGrow: 2, sortable: true, render: (row) => {
    return <Automations row={row} />;
  } },
  { type: "custom", dataKey: 'credit_balances', label: "Credits", flexGrow: 2, sortable: true, render: (row) => {
    return <Credits row={row} />;
  } },
];

const simpleColumns = [
  { type: "stacked_text", dataKey: ['name', 'account_email'], label: "Name", flexGrow: 3, sortable: true, },
  { type: "text", dataKey: 'statistics.mail_count', label: "Mail This Month", flexGrow: 2, sortable: true, placeholder: '0' },
  { type: "custom", dataKey: 'automations', label: "Automations", flexGrow: 2, sortable: true, render: (row) => {
    return <Automations row={row} />;
  } },
  { type: "custom", dataKey: 'credit_balances', label: "Credits", flexGrow: 2, sortable: true, render: (row) => {
    return <Credits row={row} />;
  } },
];

export { columns, simpleColumns };
