import React from 'react';
import { Navbar, Nav } from 'rsuite';
import { useNavigate, useLocation } from "react-router-dom";

import { toPluralDisplayName } from "../utils/railsNames";

import LogoutButton from "../auth/LogoutButton";

const AdminNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const routes = ['accounts', 'users', 'data_axle_filters', 'list_generation_settings', 'list_generations', 'lists', 'contacts',
  'postcard_templates', 'campaign_templates', 'campaigns', 'dispatches', 'mail_pieces'];

  const formattedRoutes = routes.map((route) => {
    return { name: route, path: `/admin/${route}` }
  });

  const handleSelect = (activeKey) => {
    navigate(activeKey);
  };

  return (
    <Navbar appearance="inverse">

      <Nav onSelect={handleSelect} activeKey={location.pathname}>
        {formattedRoutes.map((route) => (
          <Nav.Item key={route.name} eventKey={route.path}>
            {toPluralDisplayName(route.name)}
          </Nav.Item>
        ))}
      </Nav>

      <Nav pullRight>
        <Nav.Item><LogoutButton /></Nav.Item>
      </Nav>

    </Navbar>
  );
};
export default AdminNavbar;
