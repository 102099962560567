import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useCampaign, useCampaignListGenerationSettings } from "./campaignSlice";

import CampaignDispatchList from "./CampaignDispatchList";
import CampaignPurchaseSummary from "./CampaignPurchaseSummary";
import CampaignDispatchWarning from "./CampaignDispatchWarning";
import HotLeadDrawer from "./HotLeadDrawer";
import { DopeFormGroup } from "../ui/DopeForm";
import { Drawer } from "rsuite";
import CampaignDetails from "./CampaignDetails";
import DopeButton from "../ui/DopeButton";
import AdditionalCampaignOptions from "./AdditionalCampaignOptions";

const nameInput = { inputType: "text", name: "name", props: { label: "Campaign Name", labelClass: "body-1 bold color-text-label-blue-grey margin-4-b" } };
const tagsInput = { inputType: "tags", name: "taggings", props: { label: "", modelName: "campaign" } };

const CampaignReview = () => {
  const [campaign, campaignActions] = useCampaign();
  const [settings] = useCampaignListGenerationSettings();
  const { scheduled } = campaign;
  const [hotLeadDrawerOpen, setHotLeadDrawerOpen] = useState(false);
  const [dispatchTemplate, setDispatchTemplate] = useState(null);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);

  const { pageTabs } = useOutletContext();

  const campaignInputProps = { values: campaign, onChange: campaignActions.update, errors: campaign.errors };

  const totalContacts = campaign.action_results?.purchase_summary?.total_contacts_count || 0;
  const campaignSubtitles = {
    list: ` that are on the ${settings.starting_list?.name} list.`,
    list_blitz: ` to neighbors of the contacts on the ${settings.starting_list?.name} list.`,
    blitz: ` from your blitz.`,
    eddm: ` to contacts from ${campaign.eddm.zip_crids.join(", ")} routes.`,
  };

  const pageHeader = !scheduled && (
    <div className="page-header">
      <p className="header-text">Let's name your campaign, review and send mail</p>
      <div className="body-1 color-text-primary inline">
        <span className="margin-4-r">You’re sending to an estimated {totalContacts} contacts {campaignSubtitles[campaign.audience_generation_type_key]}</span>

        <DopeButton
          props={{
            onClick: () => setDetailsDrawerOpen(true),
            label: "See full details.",
            buttonClass: "text-link",
          }}
          className="padding-none body-text inline vertical-align-base"
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="page">
        {pageTabs}
        {pageHeader}

        <div className="half-width">
          <DopeFormGroup input={nameInput} {...campaignInputProps} className="margin-bottom" />
          <AdditionalCampaignOptions
            header="Add tags to your campaign"
            panelBody={<DopeFormGroup input={tagsInput} {...campaignInputProps} className="margin-8-tb" />}
          />
        </div>
      </div>

      <div className="page">
        <div className="campaign-review-label large">Campaign Schedule</div>
        <CampaignDispatchList
          openDrawer={(index) => {
            setDispatchTemplate(index);
            setHotLeadDrawerOpen(true);
          }}
        />
      </div>

      <div className="page">
        <CampaignPurchaseSummary />
      </div>

      <CampaignDispatchWarning />

      <HotLeadDrawer
        open={hotLeadDrawerOpen}
        onClose={(dispatch) => {
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);
        }}
        dispatches={campaign.dispatches}
        index={dispatchTemplate}
        onSave={(dispatch) => {
          setHotLeadDrawerOpen(false);
          setDispatchTemplate(null);
        }}
        actions={campaignActions}
      />

      <Drawer open={detailsDrawerOpen} onClose={() => setDetailsDrawerOpen(false)} size="md">
        <Drawer.Body>
          <h3 className="header-3 margin-bottom">Campaign Info</h3>
          <CampaignDetails />
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default CampaignReview;
