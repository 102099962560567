import React from "react";
import './CreditCardLogo.scss';
import { Icon } from "@rsuite/icons";
import { RiVisaLine, RiMastercardLine } from "react-icons/ri";
import { LiaCcDinersClub } from "react-icons/lia";
import { SiJcb, SiDiscover } from "react-icons/si";
import { GrAmex } from "react-icons/gr";
import { MdPayment } from "react-icons/md";

const creditCardLogos = {
  visa: <Icon as={RiVisaLine} className="credit-card-logo" />,
  mastercard: <Icon as={RiMastercardLine} className="credit-card-logo" />,
  amex: <Icon as={GrAmex} className="credit-card-logo" />,
  discover: <Icon as={SiDiscover} className="credit-card-logo" />,
  diners: <Icon as={LiaCcDinersClub} className="credit-card-logo" />,
  jcb: <Icon as={SiJcb} className="credit-card-logo" />,
}

const CreditCardLogo = ({ brand }) => {
  return creditCardLogos[brand] || <Icon as={MdPayment} className="credit-card-logo" />;
}

export default CreditCardLogo;
