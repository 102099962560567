import React, { useState } from "react";
import { Modal } from "rsuite";

import DopeButton from "../ui/DopeButton";
import {useDopeUI} from "../ui/dopeUISlice";


const MailPieceExportModal = ({ show, setShow, exportCSV }) => {
  const [loading, setLoading] = useState(false);

  const [dopeUI, dopeUIActions] = useDopeUI();


  const downloadCSV = async () => {
    setLoading(true)
    try {
      await exportCSV();
      setShow(false);
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Mail Piece", body: "could not be exported", type: "error" });
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>Export Mail Pieces</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Selecting export will create a csv download of your mail pieces, any filters currently selected will apply to the export.
          The download will only include up to 100,000 mail pieces
        </p>
      </Modal.Body>
      <Modal.Footer>
        <DopeButton
          props={{
            label: "Cancel",
            onClick: () => setShow(false),
            buttonClass: "outlined-red"
          }}
          disabled={loading}
        />

        <DopeButton
          props={{
            label: "Export",
            onClick: downloadCSV,
            buttonClass: "filled"
          }}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default MailPieceExportModal;
