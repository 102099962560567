import { IconButton } from 'rsuite';
import { FaTrash } from 'react-icons/fa';
import Handles from './Handles';

const styles = {
  position: 'absolute',
  border: '4px dashed #ff00c7',
  outline: '1px dashed rgba(255,255,255,0.5)',
  cursor: 'move',
  boxSizing: 'border-box',
  touchAction: 'none',
  backgroundColor: 'transparent',
  opacity: '0.9',
};

export const Area = ({
  area,
  onCropStart,
  showHandles,
  globalAreaStyle,
  areaNumber,
  removeArea,
  editable = true,
}) => {
  const localStyle = {
    top: `${area.y}${area.unit}`,
    left: `${area.x}${area.unit}`,
    width: `${area.width}${area.unit}`,
    height: `${area.height}${area.unit}`,
  };

  const style = {
    ...styles,
    ...localStyle,
    ...globalAreaStyle,
    ...area.areaStyle,
    cursor: editable ? 'move' : 'default',
  };

  return (
    <div style={style} onPointerDown={(...args) =>{
      if (!editable) return;
      onCropStart(...args);
    }} data-wrapper="wrapper">
      <div key={areaNumber}>
      <span
        style={{
          position: 'absolute',
          fontWeight: 'bold',
          color: '#fff',
          backgroundColor: '#000',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          width: '25px',
          height: '25px',
          lineHeight: '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >{areaNumber}</span>
      {editable && (
        <IconButton
          icon={<FaTrash />}
          onClick={() => removeArea(areaNumber - 1)}
          title='Delete'
          style={{
            position: 'absolute',
            bottom: '-40px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          />
      )}
    </div>
    {showHandles && editable && <Handles />}
    </div>
  );
};
