import React from "react";
import { Col, Row } from "rsuite";
import { MdError } from "react-icons/md";

import DopeTextInput from "../ui/DopeTextInput";
import DopeStateSelector from "../ui/DopeStateSelector";
import { useContact } from "./contactSlice";

const ContactErrorLabel = ({ error }) => {
  return (
    <div className="vertical-align row margin-4-t">
      <MdError className="color-text-red margin-4-r" />
      <label className="label small optional color-text-error-red">{error}</label>
    </div>
  )
}

const FormInput = ({ label, name, value, onChange, error, invalidDisplays, attemptedToSave }) => {
  const hasInvalidField = invalidDisplays.some(invalidField => invalidField.showError) && attemptedToSave;

  return (
    <>
      <label className="label">{label}</label>
      <DopeTextInput
        style={{ borderColor: hasInvalidField ? '#EA0029' : '' }}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
      />

      {invalidDisplays.map((invalidField, index) => {
        const { showError, label } = invalidField;
        return (
          <>
            {showError && attemptedToSave && <ContactErrorLabel key={index} error={label} />}
          </>
        )
      })}
    </>
  )
}

const ContactFormInputs = ({ errors, attemptedToSave }) => {
  const [contact, actions] = useContact();
  const updateContact = actions.update;

  const nameHasInvalidChars = (name) => {
    const permittedChars = /^[\p{L}\p{M}\s\-']+$/u;
    return name && !permittedChars.test(name);
  }

  const zipHasInvalidChars = (zip) => {
    const permittedChars = /^[0-9]+$/;
    return zip && !permittedChars.test(zip);
  }

  const invalidFields = {
    first_name: [{ showError: !contact.first_name, label: "First Name is required" }, { showError: nameHasInvalidChars(contact.first_name), label: "Invalid characters" }],
    last_name: [{ showError: !contact.last_name, label: "Last Name is required" }, { showError: nameHasInvalidChars(contact.last_name), label: "Invalid characters" }],
    company_name: [],
    address_1: [{ showError: !contact.address_1, label: "Address 1 is required" }],
    address_2: [],
    city: [{ showError: !contact.city, label: "City is required" }],
    zip: [{ showError: !contact.zip, label: "Zip Code is required" }, { showError: zipHasInvalidChars(contact.zip), label: "Zip Code must be a number" }],
  }

  return (
    <>
      <Row gutter={10} className="margin-bottom">
        <Col xs={12}>
          <FormInput
            label="First Name"
            name="first_name"
            value={contact.first_name}
            onChange={updateContact}
            error={errors?.first_name}
            invalidDisplays={invalidFields.first_name}
            attemptedToSave={attemptedToSave}
          />

        </Col>
        <Col xs={12}>
          <FormInput
            label="Last Name"
            name="last_name"
            value={contact.last_name}
            onChange={updateContact}
            error={errors?.last_name}
            invalidDisplays={invalidFields.last_name}
            attemptedToSave={attemptedToSave}
          />
        </Col>
      </Row>

      <Row gutter={10} className="margin-bottom">
        <Col xs={24}>
          <FormInput
            label="Company Name"
            name="company_name"
            value={contact.company_name}
            onChange={updateContact}
            error={errors?.company_name}
            invalidDisplays={invalidFields.company_name}
            attemptedToSave={attemptedToSave}
          />
        </Col>
      </Row>

      <Row gutter={10} className="margin-bottom">
        <Col xs={24}>
          <FormInput
            label="Address 1*"
            name="address_1"
            value={contact.address_1}
            onChange={updateContact}
            error={errors?.address_1}
            invalidDisplays={invalidFields.address_1}
            attemptedToSave={attemptedToSave}
          />
        </Col>
      </Row>
      <Row gutter={10} className="margin-bottom">
        <Col xs={12}>
          <FormInput
            label="Address 2"
            name="address_2"
            value={contact.address_2}
            onChange={updateContact}
            error={errors?.address_2}
            invalidDisplays={invalidFields.address_2}
            attemptedToSave={attemptedToSave}
          />
        </Col>
        <Col xs={12}>
          <FormInput
            label="City*"
            name="city"
            value={contact.city}
            onChange={updateContact}
            error={errors?.city}
            invalidDisplays={invalidFields.city}
            attemptedToSave={attemptedToSave}
          />
        </Col>
      </Row>
      <Row gutter={10} className="margin-bottom">
        <Col xs={12}>
          <label className="label">State*</label>
          <DopeStateSelector
            name="state"
            value={contact.state}
            onChange={value => updateContact({state: value})}
            error={errors?.state}
          />

          {!contact.state && attemptedToSave && <ContactErrorLabel error="State is required" />}
        </Col>
        <Col xs={12}>
          <FormInput
            label="Zip Code*"
            name="zip"
            value={contact.zip}
            onChange={updateContact}
            error={errors?.zip}
            invalidDisplays={invalidFields.zip}
            attemptedToSave={attemptedToSave}
          />
        </Col>
      </Row>
    </>
  )
}

export default ContactFormInputs;
