
const wrapAll = (actionObj, wrapper) => { // fuck writing dispatch 100 times
  const result = {};

  Object.keys(actionObj).forEach((key) => {
    result[key] = (...args) => {
      return wrapper(actionObj[key](...args));
    }
  });

  return result;
}

const handleFilterChange = (filters, setFilters, filterItems, filterObject) => {
  const { filterField, operator, allowMultiples, fieldType, polymorphicJoins } = filterObject
  if ((!filterItems || filterItems.length === 0 || filterItems === false)) {
    if (filters.some(filter => filter.field === filterField)) {
      setFilters([...filters.filter(filter => filter.field !== filterField)]);
    }
    return;
  }

  let value, label;
  if (Array.isArray(filterItems)) {
    value = filterItems.map(filterItem => filterItem.value)
    // label = filterItems.map(filterItem => filterItem.label)
  } else if (typeof filterItems === "boolean" || typeof filterItems === "string" || filterItems instanceof Date) {
    value = filterItems
    // label = filterItems.label
  }

  const railsFilterObject = {
    field: filterField,
    operator: operator,
    value: value,
    field_type: fieldType,
    polymorphic_joins: polymorphicJoins,
    filter_category: filterObject.filterCategory
  }

  if (allowMultiples) {
    return railsFilterObject
  } else {
    setFilters([...filters.filter(filter => filter.field !== filterField), railsFilterObject])
  }
}

const handleDateFilter = (filters, setFilters, filterField, startDate, endDate) => {
  if (!startDate || !endDate) {
    setFilters(filters.filter(filter => filter.filter_category !== "Date Range"));
    return;
  }

  let filterObjects = [];
  [startDate, endDate].forEach((date, index) => {
    const filterObject = {
      filterField: filterField,
      operator: index === 0 ? ">=" : "<=", // 0 is start date, 1 is end date
      filterCategory: "Date Range",
      allowMultiples: true,
    }

    filterObjects.push(handleFilterChange(filters, setFilters, date, filterObject));
  });

  const newFilters = filters.filter(filter => filter.filter_category !== "Date Range");
  setFilters([...newFilters, ...filterObjects]);
}


export {
  wrapAll,
  handleFilterChange,
  handleDateFilter,
}
