import React from 'react';
import Select from 'react-select';
import { useIntegrations } from "../integrationsSlice";

const eventOptions = [
  { value: 'create', label: 'Is Created' },
  { value: 'update', label: 'Is Updated' },
];

const SubscriptionEvent = ({ header, query = false }) => {
  const { subscription, updateSubscription, setSubscriptionDataType, resetConditions, dataTypes, subscriptionTemplates } = useIntegrations();

  const eventSelect = query ? null : (
    <div style={{ flexBasis: '50%' }}>
      <label>
        &nbsp;
      </label>
      <Select
        id="event-type"
        value={eventOptions.find(opt => opt.value === subscription.event) || ''}
        onChange={selection => updateSubscription({ event: selection.value })}
        options={eventOptions}
        isDisabled={!subscription.data_source}
      />
    </div>
  );

  const handleTemplateSelect = (template) => {
    updateSubscription({
      data_type: template.data_type,
      event: template.event,
      conditions: template.conditions,
      field_to_type_map: template.field_to_type_map,
    });
  };


  let noTemplateSelection = true;
  const templateOptions = subscriptionTemplates.map(template => {
    const isSelected = template.data_type === subscription.data_type && template.event === subscription.event && JSON.stringify(template.conditions) === JSON.stringify(subscription.conditions);
    const className = isSelected ? 'data-source-item selected' : 'data-source-item';

    if (isSelected) {
      noTemplateSelection = false;
    }
    return (
      <div key={template.id} className={className} onClick={() => handleTemplateSelect(template)}>
        <div className="data-source-item__label">{template.name}</div>
      </div>
    );
  });

  const handleClickCustom = () => {
    if (!noTemplateSelection) {
      resetConditions();
    }
  };

  const customItemClassName = noTemplateSelection ? 'data-source-item data-source-item--disabled selected' : 'data-source-item';
  const customItem = (
    <div className={customItemClassName} onClick={handleClickCustom}>
      <div className="data-source-item__label">Custom</div>
    </div>
  );

  const templateSelect = !!subscriptionTemplates.length && (
    <div className="data-source-list margin-40-b">
      {templateOptions}
      {customItem}
    </div>
  );

  return (
    <>
      {header || <div className="subscription-header">Choose Your Event</div>}
      {templateSelect}
      <div style={{ display: 'flex', margin: '10px 0', maxWidth: 500, gap: 10, alignItems: 'end' }}>
        <div style={{ flexBasis: '50%' }}>
          <div className="label margin-4-b">
            {query ? 'Select your data type' : 'When'}
          </div>
          <Select
            id="data-type"
            value={dataTypes.find(opt => opt.value === subscription.data_type) || ''}
            onChange={selection => setSubscriptionDataType(selection.value)}
            options={dataTypes}
            isDisabled={!subscription.data_source}
          />
        </div>

        {eventSelect}
      </div>
    </>
  )
};

export default SubscriptionEvent;
