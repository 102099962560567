import React, { useState } from "react";

import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import DopeApi from "../services/DopeApi";
import { useDopeUI } from "../ui/dopeUISlice";
import { errorsToErrorList } from "../utils/errors";

const contactApi = new DopeApi('contact');

const DeleteContactsModal = ({ open, onClose, selectedContactIds, setSelectedContactIds, reload }) => {
  const [loading, setLoading] = useState(false);
  const [dopeUI, dopeUIActions] = useDopeUI();

  const deleteContacts = async () => {
    setLoading(true);
    try {
      await contactApi.post({ ids: selectedContactIds }, 'destroy_multiple');
      setSelectedContactIds([]);
      reload();
    } catch (error) {
      dopeUIActions.addFlashMessage({ header: "Error", body: errorsToErrorList(error), type: "error" });
    } finally {
      setLoading(false);
      onClose();
    }
  }

  return (
    <DopeConfirmationModal
      open={open}
      title={`Are you sure you want to delete these ${selectedContactIds.length} contacts?`}
      message={(
        <div>
          <p>We will not be able to undo this action after completed.</p>
          <p>Note: contacts that have been sent mail will not be deleted. If you don’t want someone to receive mail, add them to a suppression list to ensure they are removed from your campaigns and automations.</p>
        </div>
      )}
      onConfirm={deleteContacts}
      onCancel={onClose}
      size="sm"
      confirmButtonClass="filled-black action-button"
      cancelButtonClass="outlined-black action-button"
      confirmLabel="Yes, delete"
      loading={loading}
    />
  );
}

export default DeleteContactsModal;
