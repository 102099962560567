import React, { useState } from "react";

import Autocomplete from "react-google-autocomplete";


const autoCompleteOptions = {
  componentRestrictions: { country: "us" },
  types: ["address"],
  fields: ['geometry.location', 'address_components']
};

const inputStyles = {
  width: '100%',
  padding: '7px 11px',
  border: '1px solid #EAECEF',
  borderRadius: 4,
  height: 36,
};
const invalidStyles = { color: 'red', fontSize: 12 };

const isPlaceValid = (place) => {
  const hasLat = !!place.geometry?.location?.lat();
  const hasLng = !!place.geometry?.location?.lng();
  const hasZip = !!place.address_components?.some(component => component.types.includes('postal_code'));
  const hasAddress = !!place.address_components?.some(component => component.types.includes('street_number') || component.types.includes('route'));

  return hasLat && hasLng && hasZip && hasAddress;
};

const parseAddressComponents = (place) => {
  let result = {
    city: null,
    address_1: null,
    address_2: null,
    zip: null,
    zip_4: null,
    state: null,
    country: null,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
  };

  place.address_components.forEach(component => {
    if (component.types.includes('locality')) {
      result.city = component.long_name;
    }
    if (component.types.includes('street_number')) {
      result.address_1 = (result.address_1 ? result.address_1 + ' ' : '') + component.long_name;
    }
    if (component.types.includes('route')) {
      result.address_1 = (result.address_1 ? result.address_1 + ' ' : '') + component.long_name;
    }
    if (component.types.includes('sublocality_level_1')) {
      result.address_2 = component.long_name;
    }
    if (component.types.includes('postal_code')) {
      result.zip = component.long_name;
    }
    if (component.types.includes('postal_code_suffix')) {
      result.zip_4 = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      result.state = component.long_name;
    }
    if (component.types.includes('country')) {
      result.country = component.long_name;
    }
  });

  result.full_address = `${result.address_1}${result.address_2 ? ', ' + result.address_2 : ''}, ${result.city}, ${result.state} ${result.zip}${result.zip_4 ? '-' + result.zip_4 : ''}`;

  return result;
};

const DopeAddressInput = ({ values, onChange, label }) => {
  const [address, setAddress] = useState(values?.full_address || '');
  const [placeError, setPlaceError] = useState(false);

  const handlePlaceSelect = place => {
    const isValid = isPlaceValid(place);
    if (isValid) {
      const addressObj = parseAddressComponents(place);
      onChange(addressObj);
      setAddress(addressObj.full_address);
    } else {
      setAddress('');
      setPlaceError(true);
    }
  };

  const handleChange = (e) => {
    setAddress(e.target.value);
    setPlaceError(false);
  };

  return (
    <div>
      {label && (<label className="rs-form-control-label">{label}</label>)}
      {placeError && <span style={invalidStyles}> is invalid</span>}
      <Autocomplete
        options={autoCompleteOptions}
        onPlaceSelected={handlePlaceSelect}
        style={inputStyles}
        placeholder="Enter an address"
        value={address}
        onChange={handleChange}
      />
    </div>
  );
};

export default DopeAddressInput;
