import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import { asNestedDestroyable } from "../services/createModelSelectors";

const [listSlice, asyncActions] = createModelSlice('list', {
  cancelListUpdate: (state) => {
    state.current = state.prev;
  },
});

export default listSlice.reducer;

export const {
  updateList,
  cancelListUpdate,
  resetToInitial,
} = listSlice.actions;

export const selectList = (state) => state.list.current;
export const selectPrevList = (state) => state.list.prev;

export function useList(listId) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = useSelector(selectList);
  const prevList = useSelector(selectPrevList);

  const actions = wrapAll({
    update: updateList,
    cancelListUpdate: cancelListUpdate,
    resetToInitial: resetToInitial,
    get: (id) => asyncActions.get(id),
    destroy: asyncActions.destroy,
  }, dispatch);

  actions.goToUpload = () => navigate(`/lists/${list.id}/upload`);
  actions.goToFieldMap = () => navigate(`/lists/${list.id}/field_map`);
  actions.save = (update = {}) => {
    const params = {
      ...list,
      ...update,
      ...asNestedDestroyable(prevList, list, 'taggings'),
    };

    return dispatch(asyncActions.save(params));
  };

  actions.addTagToList = (tags) => {
    const params = {
      ...list,
      ...asNestedDestroyable(prevList, {...list, taggings: tags.taggings}, 'taggings'),
    }

    return dispatch(asyncActions.save(params))
  }

  useEffect(() => {
    const shouldGet = listId && (!list || list.id !== listId);
    if (shouldGet) {
      actions.get(listId);
    }
  }, [listId, list?.id]);

  return [list, actions];
}
