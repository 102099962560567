import React,  { useState, useEffect, useRef } from 'react';
import GoogleMapReact from "google-map-react";

import { useCampaign } from '../campaigns/campaignSlice';
import { useDopeUI } from "../ui/dopeUISlice";

const mapOptions = {
  streetViewControl: false,
  mapTypeControl: true,
  fullscreenControl: false,
};

const hoveredRouteLabels = {
  "ZIP_CRID": "ROUTE",
  "TOT_CNT": "TOTAL",
  "RES_CNT": "RESIDENTIAL",
  "BUS_CNT": "BUSINESS",
  "MED_AGE": "MED AGE",
  "MED_INCOME": "MED INCOME",
};

const EddmRouteMapInput = ({ editable = true }) => {
  const [campaign, campaignActions, options] = useCampaign();
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [map, setMap] = useState(null);
  const [mapsApi, setMapsApi] = useState(null);
  const [hoveredRoute, setHoveredRoute] = useState(null);

  const polylineRef = useRef([]);

  const { eddm } = campaign;
  const routes = options.routesData.routes;

  useEffect(() => {
    if (!editable) {
      campaignActions.fetchZipCodeRoutes(eddm.zip_code);
    }
  }, []);

  useEffect(() => {
    if (map && mapsApi && routes.length) {
      polylineRef.current.forEach(polyline => polyline.setMap(null));
      polylineRef.current = [];

      const manager = new window.google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        polylineOptions: {
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 3,
        },
      });
      manager.setMap(map);

      const polylineIdMap = new Map();

      routes.forEach((route) => {
        route.geometry.paths.map(path => {
          const zip_crid = route.attributes.ZIP_CRID;
          const isSelectedRoute = eddm.zip_crids.includes(zip_crid);

          const polylinePath = path.map(([lng, lat]) => {
            const latlng = new mapsApi.LatLng(lat, lng);
            return latlng;
          });

          const polyline = new mapsApi.Polyline({
            id: zip_crid,
            geodesic: true,
            path: polylinePath,
            strokeColor: isSelectedRoute ? '#2196F3' : '#9A9A9A',
            strokeWeight: 4,
            strokeOpacity: isSelectedRoute ? 1.0 : 0.7,
          });
          polyline.setMap(map);

          polylineIdMap.set(polyline, zip_crid);

          polyline.addListener('mouseover', () => {
            polylineIdMap.forEach((polylineId, pl) => {
              if (polylineId === zip_crid) {
                pl.setOptions({
                  strokeWeight: 6,
                  strokeOpacity: 1.0,
                });
              }
            });

            setHoveredRoute(route.attributes);
          });

          polyline.addListener('mouseout', () => {
            polylineIdMap.forEach((polylineId, pl) => {
              const currentColor = polyline.get('strokeColor');

              if (polylineId === zip_crid) {
                pl.setOptions({
                  strokeWeight: 4,
                  strokeOpacity: currentColor === "#2196F3" ? 1.0 : 0.7,
                });
              }
            });

            setHoveredRoute(null);
          });

          if (editable) {
            polyline.addListener('click', () => {
              if (!route.attributes.TOT_CNT || route.attributes.TOT_CNT === 0) {
                dopeUIActions.addFlashMessage({ header: 'Route has no deliverable addresses', body: 'Please select another route', type: 'warning' });
                return;
              }

              campaignActions.toggleEDDMRoute(route.attributes.ZIP_CRID);

              polylineIdMap.forEach((polylineId, pl) => {
                if (polylineId === zip_crid && !isSelectedRoute) {
                  pl.setOptions({
                    strokeOpacity: 1.0,
                    strokeColor: '#2196F3',
                  });
                } else if (polylineId === zip_crid && isSelectedRoute) {
                  pl.setOptions({
                    strokeOpacity: 0.7,
                    strokeColor: '#9A9A9A',
                  });
                }
              });
            });
          }

          polylineRef.current.push(polyline);

          return polyline;
        });
      });

      return () => {
        manager.setMap(null);
        polylineRef.current.forEach(polyline => polyline.setMap(null));
      };
    }
  }, [map, mapsApi, routes, eddm.zip_crids]);

  useEffect(() => {
    if (map && mapsApi && routes.length) {
      const bounds = new mapsApi.LatLngBounds();
      routes.forEach((route) => {
        route.geometry.paths.forEach(path => {
          path.map(([lng, lat]) => {
            bounds.extend(new mapsApi.LatLng(lat, lng));
          });
        });
      });

      if (!bounds.isEmpty()) {
        map.fitBounds(bounds, 0);
      }
    }
  }, [map, mapsApi, routes]);

  return (
    <div className="full-width square-border rounded-border-bottom pad">
      <div className="flex row full-width space-between margin-bottom">
        {Object.keys(hoveredRouteLabels).map((column) => (
          <div className="flex column" key={column}>
            <div className="label large label-blue-grey bold">{hoveredRouteLabels[column]}</div>
            {column !== 'MED_INCOME' && <div className="label large bold" >{hoveredRoute && hoveredRoute[column] !== null ? hoveredRoute[column] : '---'}</div>}
            {column === 'MED_INCOME' && <div className="label large bold" >{hoveredRoute && hoveredRoute[column] !== null ? `$${hoveredRoute[column].toLocaleString()}` : '---'}</div>}
          </div>
        ))}
      </div>
      <div style={{height: 550}}>
        <GoogleMapReact
          defaultCenter={{lat: 39.8283, lng: -98.5795}}
          defaultZoom={4}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {
            setMap(map);
            setMapsApi(maps);
          }}
          options={mapOptions}
        />
      </div>
    </div>
  );
}

export default EddmRouteMapInput;
