import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { useSweetSpotAnalysis } from "./sweetSpotAnalysisSlice";
import DopeTable from "../ui/DopeTable";
import DopeDrawer from "../ui/DopeDrawer";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import MarkerImg from "../images/gmap_marker.png";
import './SweetSpotAnalysis.scss';


const columns = [
  { dataKey: 'zip', label: 'Zip Code', type: 'text', sortable: true },
  { dataKey: 'contacts_count', label: 'Amount', type: 'text', sortable: true },
  { dataKey: 'average_value', label: 'Avg Customer Value', type: 'currency', sortable: true },
  { dataKey: 'total_value', label: 'Total Customer Value', type: 'currency', sortable: true },
  { type: "icon", dataKey: "zip", icon: <ArrowRightLineIcon />, width: 50 },
];

const getContactType = (contact) => {
  if (contact.is_client) { return "Customer" }
  if (contact.is_lead) { return "Lead" }

  return "Prospect";
};

const contactColumns = [
  { dataKey: 'full_name', label: 'Full Name', type: 'text' },
  { dataKey: 'full_address', label: 'Address', type: 'address_text' },
  { dataKey: 'id', label: 'Contact Type', type: 'custom', render: getContactType },
  { dataKey: 'client_value', label: 'Customer Value', type: 'currency' },
];

const messageStyles = {
  backgroundColor: '#f8fbfc',
  padding: '1rem',
  borderRadius: '4px',
  marginBottom: '2rem',
  color: '#6c757d',
};


const mapToggleItemStyles = {
  color: '#6F6F6F',
  fontSize: '16px',
  width: 150,
  padding: 10,
  borderRadius: '4px',
  textAlign: 'center',
  cursor: 'pointer',
};
const mapToggleItemSelectedStyles = {
  ...mapToggleItemStyles,
  color: '#292B2E',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  pointerEvents: 'none',
  fontWeight: 'bold',
};
const mapToggleWrapperStyles = {
  display: 'flex',
  padding: 4,
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
};
const MapToggle = ({ options, value, onChange, style = {} }) => {
  const optionButtons = options.map(option => {
    const isSelected = option.value === value;
    const style = isSelected ? mapToggleItemSelectedStyles : mapToggleItemStyles;
    return (
      <div style={style} onClick={() => onChange(option.value)}>
        {option.label}
      </div>
    );
  });

  return (
    <div style={{ ...mapToggleWrapperStyles, ...style }}>
      {optionButtons}
    </div>
  );
};


const SweetSpotAnalysisReview = ({ viewOnly = false }) => {
  const { sweetSpotAnalysis, actions } = useSweetSpotAnalysis();

  const mapRef = useRef(null);
  const mapsRef = useRef(null);
  const heatmapRef = useRef(null);
  const listIdRef = useRef(null);

  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(4);
  const [center, setCenter] = useState({ lat: 39.8283, lng: -98.5795 });
  const [zip, setZip] = useState(null);
  const [displayMode, setDisplayMode] = useState('heatmap');

  const [sortColumn, setSortColumn] = useState('zip');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    if (sweetSpotAnalysis.id === 'new') {
      actions.preview();
    }
  }, [sweetSpotAnalysis.id]);

  // Call the preview if the list_id changes
  useEffect(() => {
    if (listIdRef.current !== sweetSpotAnalysis.list_id) {
      actions.preview();
      listIdRef.current = sweetSpotAnalysis.list_id;
    }
  }, [sweetSpotAnalysis.list_id]);


  useEffect(() => {
    if (sweetSpotAnalysis) {
      actions.sort({ sortColumn, sortDirection });
    }
  }, [sortColumn, sortDirection]);

  useEffect(() => {
    if (sweetSpotAnalysis && sweetSpotAnalysis.status === 'complete' && sweetSpotAnalysis.report_data && mapsRef.current && !heatmapRef.current) {
      const contacts = sweetSpotAnalysis.report_data.flatMap(data => data.contacts);

      const nextMarkers = [];
      const heatmapData = [];

      contacts.forEach(contact => {
        let { latitude, longitude } = contact;

        if (latitude && longitude) {
          const latLng = new mapsRef.current.LatLng(parseFloat(latitude), parseFloat(longitude));
          heatmapData.push(latLng);

          const marker = new mapsRef.current.Marker({
            position: latLng,
            map: null,
            icon: {
              url: MarkerImg,
              scaledSize: new mapsRef.current.Size(20, 20),
            }
          });

          nextMarkers.push(marker);
        }
      });

      if (nextMarkers.length > 0) {
        setMarkers(nextMarkers);

        const bounds = new mapsRef.current.LatLngBounds();
        nextMarkers.forEach(marker => bounds.extend(marker.position));

        mapRef.current.fitBounds(bounds);

        const heatmap = new mapsRef.current.visualization.HeatmapLayer({ data: heatmapData });
        heatmap.setMap(mapRef.current);
        heatmapRef.current = heatmap;
      }
    }
  }, [sweetSpotAnalysis?.list_id, sweetSpotAnalysis?.status, sweetSpotAnalysis?.report_data, mapsRef.current]);

  const toggleDisplayMode = () => {
    if (displayMode === 'heatmap') {
      setDisplayMode('markers');
      heatmapRef.current.setMap(null);
      markers.forEach(marker => marker.setMap(mapRef.current));
    } else {
      setDisplayMode('heatmap');
      heatmapRef.current.setMap(mapRef.current);
      markers.forEach(marker => marker.setMap(null));
    }
  };

  const updateMarkerSize = () => {
    const size = Math.max(10, mapRef.current.zoom * 3);
    markers.forEach(marker => {
      marker.setIcon({
        url: MarkerImg,
        scaledSize: new mapsRef.current.Size(size, size),
      });
    });
  };

  const isNew = sweetSpotAnalysis.id === 'new' || !sweetSpotAnalysis.id;

  const displayOptions = [
    { label: 'Heat map', value: 'heatmap' },
    { label: 'Markers', value: 'markers' },
  ];
  const displayModeToggle = (
    <MapToggle
      options={displayOptions}
      value={displayMode}
      onChange={toggleDisplayMode}
      style={{ position: 'absolute', top: 10, right: 10, pointerEvents: mapRef.current ? 'auto' : 'none' }}
    />
  );

  return (
    <div>
      <div className="page-header">
        <p className="header-text">{sweetSpotAnalysis.name}{viewOnly ? '' : ' Review'}</p>
      </div>
      {sweetSpotAnalysis.status === 'complete' && (
        <div style={{ width: '100%', height: '500px', marginBottom: '1rem', position: 'relative' }}>

          <GoogleMapReact
            center={center}
            zoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              mapRef.current = map;
              mapsRef.current = maps;
            }}
            onZoomAnimationEnd={updateMarkerSize}
            options={{
              streetViewControl: false,
              mapTypeControl: true,
              fullscreenControl: false,
            }}
          >
          </GoogleMapReact>

          {displayModeToggle}

        </div>
      )}

      {!isNew && (sweetSpotAnalysis.status === 'pending' || sweetSpotAnalysis.status === 'processing') && (
        <div style={messageStyles}>
          <p><b>Sit tight - your report is being generated!</b></p>
          <p>Once your report has completed, you'll be able to view your data as a map.</p>
        </div>
      )}

      <DopeTable
        data={sweetSpotAnalysis.report_data || []}
        columns={columns}
        loading={!sweetSpotAnalysis.report_data || sweetSpotAnalysis.isGetting}
        onRowClick={(rowData) => setZip(rowData)}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSortColumn={(sortColumn, sortDirection) => {
          setSortColumn(sortColumn);
          setSortDirection(sortDirection);
        }}
        onClickZip={(zip) => {}}
      />

      <DopeDrawer
        size="lg"
        header={`Zip Code ${zip?.zip}`}
        subheader={`(${zip?.contacts?.length ?? 0}) Contacts`}
        open={!!zip}
        onClose={() => setZip(null)}
        bodyContent={
          <div>
            <DopeTable
              data={zip?.contacts || []}
              columns={contactColumns}
            />
          </div>
        }
      />
    </div>
  );
};

export default SweetSpotAnalysisReview;
