import flatMap from 'lodash/map';

import { humanize } from './railsNames';

function errorsToErrorList(errors) {
  return flatMap(errors, (errorMessages, field) => {
    const fieldDisplay = field === "base" ? "General:" : humanize(field);
    return errorMessages.map((message) => {
      return `${fieldDisplay} ${message}`;
    });
  });
}

export { errorsToErrorList };
