import React, { useEffect, useState } from "react";

import DopeApi from "../services/DopeApi";

import MailingsGraph from "./MailingsGraph";
import DopeLoader from "../ui/DopeLoader";

const mailPiecesApi = new DopeApi("mail_piece");
const loaderStyles = { height: '100%' };
const wrapperStyles = { height: '428px' };

const MailingsGraphWrapper = () => {
  const [loading, setLoading] = useState(true)
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    setLoading(true);
    mailPiecesApi
      .fetchCollection("recently_shipped_by_template_type_by_week")
      .then(setSeriesData)
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="overview-section">
      <h4 className="overview-header">Mailings at a Glance</h4>
      <div className="help-text small">Mailings are grouped by week</div>
      <div style={wrapperStyles}>
        {loading ? (
          <DopeLoader style={loaderStyles} size="lg" />
        ) : (
          <MailingsGraph data={seriesData} />
        )}
      </div>
    </div>
  );
}

export default MailingsGraphWrapper;
