import React from 'react';
import authApi from "./authApi";

import { Button } from  'rsuite'

const LogoutButton = () => {
  const handleLogout = async () => {
    try {
      await authApi.logout();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div>
      <Button onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );
};

export default LogoutButton;