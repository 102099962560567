import dopeClient from "./dopeClient";

const addressSearchApi = {
  search: async (params, signal) => {
    const response = await dopeClient.post(`/search/summary`, params, { signal });
    return response.data;
  },
};

export default addressSearchApi;
