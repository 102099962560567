import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from '@reduxjs/toolkit';

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";

const [qrCodeSlice, asyncActions] = createModelSlice('qr_code', { });

export default qrCodeSlice.reducer;

export const {
  resetToInitial,
} = qrCodeSlice.actions;

const qrCodeToParams = (qrCode, prevQRCode) => {
  return {
    ...qrCode,
  };
};

const qrCodeSelector = state => {
  return state.qrCode.current;
}

const derivedQRCodeAttrsSelector = createSelector([qrCodeSelector], (qrCode) => {
  if (!qrCode) return {};

  const derivedUpdates = {};

  return derivedUpdates;
});

export function useQRCode(qrCodeId) {
  const dispatch = useDispatch();
  const qrCode = useSelector(qrCodeSelector);
  const derivedQRCodeAttrs = useSelector(derivedQRCodeAttrsSelector);
  const prevQRCode = useSelector(state => state.qrCode.prev);
  const isSaving = useSelector(state => state.qrCode.isSaving);

  const actions = wrapAll({
    ...qrCodeSlice.actions,
    ...asyncActions,
    resetToInitial: resetToInitial,
    save: (additionalParams = {}) => {
      const { code, ...extras } = additionalParams;
      const { ...params } = { ...qrCodeToParams(qrCode, prevQRCode), ...extras };

      delete params.errors;

      return asyncActions.save({...params, code });
    },
  }, dispatch);

  useEffect(() => {
    const shouldGet = qrCodeId && (!qrCode || qrCode.id !== qrCodeId);
    if (shouldGet) {
      actions.get(qrCodeId);
    }
  }, [qrCodeId, qrCode?.id]);



  return {
    qrCode,
    derivedQRCodeAttrs,
    actions,
    isSaving,
  };
};

