import React, { useState, useEffect } from 'react';

const HasAndBelongsToTheSea = () => {
  const [keySequence, setKeySequence] = useState('');
  const [showPirateShip, setShowPirateShip] = useState(false);
  const [exitClass, setExitClass] = useState('');
  const [showScalawag, setShowScalawag] = useState(false);

  const handleKeyPress = (event) => {
    setKeySequence((prevSequence) => {
      const nextSequence = prevSequence + event.key;
      if (nextSequence.endsWith('pirate')) {
        setShowPirateShip(true);
        return '';
      } else if (nextSequence.endsWith('arrgh')) {
        setShowScalawag(true);
        return '';
      } else {
        return nextSequence;
      }
    });
  };

  const handleClick = () => {
    setExitClass('explode');
    setTimeout(() => {
      setShowPirateShip(false);
      setExitClass('');
    }, 250);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [keySequence]);

  useEffect(() => {
    setTimeout(() => {
      setShowScalawag(false);
    }, 7000);
  }, [showScalawag]);

  return (
    <>
      { showPirateShip && (
        <div className={`pirate-ship ${exitClass}`} onClick={handleClick}>
          <div className="fire"/>
        </div>
      )}
      { showScalawag && (
        <div>
          <div className={`scalawag-right ${exitClass}`} >
            <div className="fire"/>
          </div>
          <div className={`scalawag-left ${exitClass}`} />
        </div>
      )}
    </>
  )
};

export default HasAndBelongsToTheSea;
