import React, { useState, useEffect } from "react";

import authApi from "../auth/authApi";

import DopeTabs from "../ui/DopeTabs";
import ProductStandardPricing from "./ProductStandardPricing";
import ProductAccountPricing from "./ProductAccountPricing";


const ProductPricing = () => {
  const canEdit = authApi.currentUserIsSuperAdmin();

  let content = (<ProductAccountPricing />);
  if (canEdit) {
    content = (
      <DopeTabs>
        <DopeTabs.Tab name="standard" label="Standard Pricing">
          <ProductStandardPricing />
        </DopeTabs.Tab>

        <DopeTabs.Tab name="account" label="Per Account">
          <ProductAccountPricing />
        </DopeTabs.Tab>
      </DopeTabs>
    );
  }

  return (
    <div className="page">
      <div className="page-header">
        <p className="header-text">Pricing</p>
      </div>

      {content}
    </div>
  );
};

export default ProductPricing;
