import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineAddCircleOutline } from "react-icons/md";

import { useIntegrations } from "../integrationsSlice";

import DataSourceItem from "../DataSources/DataSourceItem";
import ConnectDrawer from "../Connections/ConnectDrawer";
import DopeButton from "../../ui/DopeButton";

const SubscriptionConnectionSelect = ({ header }) => {
  const {
    subscription,
    setSubscriptionDataSource,
    connections,
  } = useIntegrations();

  const [showAddConnection, setShowAddConnection] = useState(false);

  return (
    <>
      {header || <div className="subscription-header">Choose Your Connection</div>}

      <div className="data-source-list">
        {connections.map((connection, index) => (
          <DataSourceItem
            key={index}
            dataSource={connection.data_source}
            onClick={() => setSubscriptionDataSource(connection)}
            isSelected={subscription.data_source === connection.data_source.name}
          />
        ))}

          <DataSourceItem
            key="ADD"
            dataSource={{ label: (<span className="vertical-align text-med"><MdOutlineAddCircleOutline className="margin-4-r text-large" /> Add</span>) }}
            onClick={() => setShowAddConnection(true)}
          />
      </div>

      <DopeButton
        icon={<FaRegEdit className="margin-4-r" />}
        props={{
          buttonClass: "outlined-black rectangle margin-16-t",
          label: "Edit connections",
          onClick: () => setShowAddConnection(true),
        }}
      />

      <ConnectDrawer open={showAddConnection} onClose={() => setShowAddConnection(false)} />
    </>
  );
};

export default SubscriptionConnectionSelect;
