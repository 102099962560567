import { useDispatch, useSelector } from "react-redux";

import createModelSlice from "../services/createModelSlice";
import { wrapAll } from "../utils/fn";
import {useEffect} from "react";

const [mailPieceSlice, asyncActions] = createModelSlice('mail_piece');

export default mailPieceSlice.reducer;

export const {
  updateMailPiece,
  resetToInitial,
} = mailPieceSlice.actions;

export const selectMailPiece = (state) => state.mailPiece.current;

export const selectPrevMailPiece = (state) => state.mailPiece.prev;

export function useMailPiece(mailPieceId) {
  const dispatch = useDispatch();
  const mailPiece = useSelector(selectMailPiece);
  const prevMailPiece = useSelector(selectPrevMailPiece);

  const actions = wrapAll({
    update: updateMailPiece,
    resetToInitial: resetToInitial,
    get: asyncActions.get,
    destroy: asyncActions.destroy,
  }, dispatch);

  useEffect(() => {
    const shouldGet = mailPieceId && (!mailPiece || mailPiece.id !== mailPieceId);
    if (shouldGet) {
      actions.get(mailPieceId);
    }
  }, [mailPieceId, mailPiece?.id]);

  return [mailPiece, actions];
}
