import React, { useEffect, useState} from "react";
import {Col, Form, Grid, Row, SelectPicker} from "rsuite";

import { PolotnoContainer, WorkspaceWrap } from 'polotno';
import { Workspace } from 'polotno/canvas/workspace';

import { useDesignTemplate } from "../designs/designTemplateSlice";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeApi from "../services/DopeApi";
import DopeTextInput from "../ui/DopeTextInput";

import DopeButton from "../ui/DopeButton";

import createStore from "polotno/model/store";
import { parsePSD } from "../utils/psd";
import { useMediaUpload } from "../editor/mediaUploadSlice";
import { customAlphabet } from "nanoid";
import { objectURLToFile } from "../utils/design";
import BelongsToSelect from "../ui/BelongsToSelect";
import { sizeToPixels, dataURLtoFile } from "../utils/design";
import DopeAttachmentDropZone from "../ui/DopeAttachmentDropZone";

import DopeMessage from "../ui/DopeMessage";

// TODO: delegate this to backend to keep it consistent?
const SIZES = [
  { label: "6x9 Postcard", value: 'PostcardTemplate:6x9' },
];


const labelStyles = {
  fontWeight: 600,
  fontSize: "11px",
  color: "#8793A6",
  margin: '1rem 0',
  display: 'block'
}

const detailsStyles = {
  fontWeight: 'normal',
  fontSize: '11px',
  color: "#8793A6",
  margin: '1rem 0',
  display: 'block'
}

const rowStyles = {
  marginBottom: "16px"
}

const actionStyle = {
  textAlign: 'left',
  marginTop: '0.5rem'
};

const selectStyles = {
  width: "100%",
  minWidth: "200px"
};

const designTemplateApi = new DopeApi('design_template');

const store = createStore({ key: process.env.REACT_APP_POLOTNO_API_KEY });

const AddDesignTemplateForm = ({ handleClose, reload}) => {
  const { designTemplate, actions } = useDesignTemplate();
  const { mediaUpload, actions: mediaUploadActions } = useMediaUpload();

  const [dopeUI, dopeUIActions] = useDopeUI();
  const [loading, setLoading] = useState(false);

  const [frontFile, setFrontFile] = useState(null);
  const [frontPage, setFrontPage] = useState(null);

  const [backFile, setBackFile] = useState(null);
  const [backPage, setBackPage] = useState(null);

  const [frontError, setFrontError] = useState(null);
  const [backError, setBackError] = useState(null);

  const [frontLoading, setFrontLoading] = useState(false);
  const [backLoading, setBackLoading] = useState(false);


  const nanoid = customAlphabet('01223456789abcdefghijklmnoprstuvwxyz', 5);



  const updateDesignTemplate = actions.update;


  const handleSubmit = async () => {
    setLoading(true);
    try {
      store.clear();

      const { width, height } = sizeToPixels(designTemplate.size);
      store.setSize(width, height);

      const now = Date.now();
      const files = {};

      const pages = [frontPage];

      if (backPage) {
        pages.push(backPage);
      }

      store.loadJSON({ pages }, false);

      await store.waitLoading();
      const front = await store.toBlob({
        pageId: store.pages[0].id,
        dpi: 300,
        pixelRatio: 2,
      });

      files.front_image = await objectURLToFile(front, `front_${now}.png`);

      if (backPage) {
        const back = await store.toBlob({
          pageId: store.pages[1].id,
          dpi: 300,
          pixelRatio: 2
        });

        files.back_image = await objectURLToFile(back, `back_${now}.png`);
      }

      await actions.save({
        files,
        json_template: JSON.stringify(store.toJSON()),
      })
      reload();
      handleClose();
      dopeUIActions.addFlashMessage({header: designTemplate.name, body: 'was successfully added!', type: 'success' });
    } catch (error) {
      console.error(error);
      dopeUIActions.addFlashMessage({header: 'Something went wrong', body: 'please try again', type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async () => {
    setLoading(true);
    try {
      await designTemplateApi.destroy(designTemplate.id);
      reload();
      handleClose();
      dopeUIActions.addFlashMessage({header: `${designTemplate.name}`, body: 'was successfully deleted!', type: 'success' });
    } catch (error) {
      dopeUIActions.addFlashMessage({header: 'Something went wrong', body: 'please try again', type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const newDesignTemplate = designTemplate?.id === 'new';

  const upload = async (base64) => {
    const id = nanoid();

    const file = dataURLtoFile(base64, `layer_${id}_${Date.now()}.png`);

    const result = await mediaUploadActions.save({ media: file, media_type: 'layer' });

    return result.payload.media_url;
  };


  const currentSize = (designTemplate?.size && designTemplate?.template_type) ? `${designTemplate.template_type}:${designTemplate?.size}` : null;

  const hasBack = () => {
    return designTemplate?.template_type === 'PostcardTemplate';
  };

  // TODO: As soon as polotno supports headless image rendering, we can remove the
  // workspace element from here.

  return (
    <Form fluid>
      <Grid fluid>

        <Col>
          <Row gutter={10} style={rowStyles}>

            <Col xs={24}>

              <label style={labelStyles}>Template Name</label>
              <DopeTextInput
                name="name"
                value={designTemplate?.name}
                onChange={updateDesignTemplate}
              />
            </Col>

            <Col xs={24}>
              <label style={labelStyles}>Template Type</label>
              <SelectPicker
                data={SIZES}
                onChange={value => {
                  const [template_type, size] = value.split(':');
                  actions.update({ template_type, size });
                }}
                value={currentSize}
                cleanable={false}
                style={selectStyles}
                menuStyle={{ zIndex: 9999, marginTop: "4px" }}
              />
            </Col>

            <Col xs={24}>
              <label style={labelStyles}>Customer Category</label>
              <p style={detailsStyles}>If none is chosen, this template will be available to all customers.</p>
              <BelongsToSelect
                belongsToModelName="customer_category"
                name="customer_category_id"
                labelKey="name"
                value={designTemplate?.customer_category_id}
                onChange={({ customer_category_id }, customerCategory) => {
                  actions.update({ customer_category_id });
                }}
              />
              {designTemplate?.customer_category_id && <p style={actionStyle}><small><a onClick={() => actions.update({ customer_category_id: null })}>Remove</a></small></p>}
            </Col>

            <Col xs={24}>
              <label style={labelStyles}>Account</label>
              <p style={detailsStyles}>If chosen, this template will only show up for this account.</p>
              <BelongsToSelect
                belongsToModelName="account"
                labelKey="name"
                name="account_id"
                value={designTemplate?.account_id}
                onChange={({ account_id }, customer) => {
                  actions.update({ account_id });
                }}
              />
              {designTemplate?.account_id && <p style={actionStyle}><small><a onClick={() => actions.update({ account_id: null })}>Remove</a></small></p>}
            </Col>

            <Col xs={24}>
              <label style={labelStyles}>Front Image</label>
              {frontError &&
                <DopeMessage
                  type="error"
                  header="Error Parsing PSD File"
                  body={frontError}
                  style={{boxShadow: 'none' }}
                  />
              }
              <DopeAttachmentDropZone
                name="front_image"
                onUpload={async (psd) => {
                  setFrontLoading(true);
                  try {
                    const page = await parsePSD(psd, upload);
                    setFrontFile(psd);
                    setFrontPage(page);
                  } catch (error) {
                    setFrontError(error.message);
                    setFrontFile(null);
                    setFrontPage(null);
                  }

                  setFrontLoading(false);
                }}
                onRemoval={() => {
                  setFrontFile(null);
                  setFrontPage(null);
                }}
                currentFile={frontFile}
                accept={{
                  'image/vnd.adobe.photoshop': [],
                  'application/x-photoshop': [],
                  'application/photoshop': [],
                  'application/psd': [],
                  'image/psd': [],
                  'image/x-photoshop': [],
                }}
                loading={frontLoading}
              />

            </Col>

            {hasBack() && (
              <Col xs={24}>
                <label style={labelStyles}>Back Image</label>
                {backError &&
                  <DopeMessage
                    type="error"
                    header="Error Parsing PSD File"
                    body={backError}
                    style={{boxShadow: 'none' }}
                    />
                }
                <DopeAttachmentDropZone
                  name="back_image"
                  onUpload={async (psd) => {
                    setBackLoading(true);
                    try {
                      const page = await parsePSD(psd, upload);

                      setBackFile(psd);
                      setBackPage(page);
                    } catch (error) {
                      setBackError(error.message);
                      setBackFile(null);
                      setBackPage(null);
                    }

                    setBackLoading(false);
                  }}
                  onRemoval={() => {
                    setBackFile(null);
                    setBackPage(null);
                  }}
                  currentFile={backFile}
                  accept={{
                    'image/vnd.adobe.photoshop': [],
                    'application/x-photoshop': [],
                    'application/photoshop': [],
                    'application/psd': [],
                    'image/psd': [],
                    'image/x-photoshop': [],
                  }}
                  loading={backLoading}
                />
              </Col>
            )}
          </Row>

        </Col>
      </Grid>

      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "16px"}}>
        {!newDesignTemplate && (
          <DopeButton
            props={{
              buttonClass: "outlined-black",
              label: "Delete Template",
              onClick: handleDelete,
            }}
            disabled={newDesignTemplate}
            loading={loading}
          />
        )}
        <DopeButton
          props={{
            buttonClass: "filled-black",
            label: newDesignTemplate ? "Create Template" : "Update Template",
            onClick: handleSubmit,
          }}
          disabled={!designTemplate?.name || !frontPage || (hasBack() && !backPage) || !designTemplate?.template_type}
          loading={loading}
        />
      </div>
      <div style={{ width: '1000px', height: '1000px', display: 'none' }}>
        <Workspace store={store} />
        </div>
    </Form>
  );
}

export default AddDesignTemplateForm;
