import React, { useEffect } from "react";

import DopeListPage from "../ui/DopeListPage";
import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import { handleFilterChange } from "../utils/fn";
import authApi from "../auth/authApi";
import ListStatusCell from "./ListStatusCell";
import DopeDrawer from "../ui/DopeDrawer";
import ListDrawerForm from "./ListDrawerForm";
import { useListUI } from "./listUISlice";
import { useList } from "./listSlice";
import DeleteListConfirmationModal from "./DeleteListConfirmationModal";

const listApi = new DopeApi('list');
const taggingsApi = new DopeApi('taggings')

const setFavorite = async (rowData) => {
  await listApi.update({id: rowData.id, favorite: !rowData.favorite});
}

const columns = [
  { type: 'name_link',
    dataKey: 'name',
    sortable: true,
    label: 'Name',
    getUrl: (list) => `/lists/${list.id}/details`,
    customStyles: { fontSize: '14px', fontWeight: 400, color: '#292B2E' },
    flexGrow: 2
  },
  { type: 'text', dataKey: 'contact_lists_count', label: 'Contacts', sortable: true },
  { type: 'timestamp', dataKey: 'created_at', label: 'Date Created', sortable: true },
  { type: 'pill', dataKey: 'creator_type_display', label: 'List Type', pillType: 'tag', sortable: true },
  { type: 'text', dataKey: 'account_id', label: 'Account', filterable: true, hidden: true, input: { inputType: 'belongsTo', props: { belongsToModelName: 'account' } } },
  { type: 'custom_component', width: 180, label: 'Upload Status', dataKey: 'effective_status', Component: ListStatusCell },
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'list', width: 75 }
];

const listTypeOptions = [
  { value: "user", label: "User" },
  { value: "data_axle", label: "Blitz" },
];

const ListListPage = ({ setListCount, listCount }) => {
  const { error, tableProps, paginationProps , onSearchClear, onSearchInput, setFilters, filters, reload }
    = useQuery2({
      api: listApi,
      initialSearchScope: "name"
    });

  const [list, listActions] = useList();
  const [listUI, listUIActions] = useListUI();

  useEffect(() => {
    setListCount(paginationProps.total);
  }, [paginationProps.total]);

  useEffect(() => {
    if (listCount > 0 && paginationProps.total > 0 && listCount !== paginationProps.total) {
      reload();
    }
  }, [listCount]);

  const applyTagFilters = (tags) => {
    const filterObject = {
      filterField: "taggings.tag_id",
      operator: "in",
      filterCategory: "Tags"
    }
    handleFilterChange(filters, setFilters, tags, filterObject);
  }

  const applyListTypeFilters = (lists) => {
    const filterObject = {
      filterField: "data_source",
      operator: "in",
      filterCategory: "List Type"
    }
    handleFilterChange(filters, setFilters, lists, filterObject)
  }

  const filterInputs = [
    { title: "Tags",
      getOptions: (name = "") => taggingsApi.getOptions( {
        valueKey: "tag_id",
        labelKey: "tag.name",
        filters: [{ field: "taggable_type", operator: "=", value: "List" }, {field: "tags.account_id", operator: "=", value: authApi.getCurrentAccountId()}],
        scopes: [{ name: "search_tag_by_name", args: name }]
      }),
      applyFilter: applyTagFilters,
      searchable: true
    },
    { title: "List Type", getOptions: () => listTypeOptions, applyFilter: applyListTypeFilters }
  ]

  return (
    <>
      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        filterInputs={filterInputs}
        setFilters={setFilters}
        filters={filters}
        error={error}
      />

      <DopeDrawer
        open={!!listUI.drawerListId}
        onClose={() => listUIActions.setDrawerListId(null)}
        header={`Edit ${list?.name}`}
        bodyContent={
          <ListDrawerForm id={listUI.drawerListId} reload={reload} />
        }
      />

      <DeleteListConfirmationModal reload={reload} open={!!listUI.modalListId} onClose={() => listUIActions.setModalListId(null)} />
    </>
  );
}

export default ListListPage;
