import { observer } from 'mobx-react-lite';
// import { useSelector } from 'react-redux';

import { DEFAULT_SECTIONS } from 'polotno/side-panel';

export const customText = ({ fontSize, title, fontFamily, fontWeight}) => {
  return {
    type: "text",
    x: 800,
    y: 800,
    fontSize: fontSize,
    fill: "black",
    text: title,
    fontWeight: fontWeight,
    fontFamily: fontFamily,
    width: 800,
    height: 300,
  };
};

const CustomHeaders = ({ store }) => {
  const handleHeader = (fontSize, title, fontWeight) => {
    store.activePage?.addElement(customText({fontSize, title, fontWeight}));
  }
  return (
    <div className='custom-headers'>
      <button
        draggable='true'
        type='button'
        style={{ fontSize:'25px' }}
        onClick={() => handleHeader(200, 'Add a header', 'bold')}
      >
        Add a header
      </button>
      <button
        draggable='true'
        type='button'
        style={{ fontSize:'18px' }}
        onClick={() => handleHeader(100, 'Add a subheader', 'bold')}
      >
        Add a subheader
      </button>
      <button
        draggable='true'
        type='button'
        style={{ fontSize:'14px' }}
        onClick={() => handleHeader(50, 'Add body text')}
      >
        Add body text
      </button>
    </div>
  );
}
const TextDefSection = DEFAULT_SECTIONS.find(
  (section) => section.name === 'text'
);

const DefaultTextSidePanel = observer(({ store }) => {

  const isAdmin = true;

  return (
    <div>
      <CustomHeaders store={store} />
      { isAdmin ? <TextDefSection.Panel store={store} /> : null }
    </div>
  );
});

export default DefaultTextSidePanel;
