import React, { useState } from "react";
import useObjectState from "../hooks/useObjectState";

import dopeClient from "../services/dopeClient";
import { useDopeUI } from "../ui/dopeUISlice";

import DopeTabs from "../ui/DopeTabs";
import DopeForm from "../ui/DopeForm";

const importHistoricalConfig = {
  taskName: "import:historical",
  taskLabel: "Import Historical Account",
  inputs: [
    {
      inputType: 'text',
      name: 'historical_id',
      props: {
        label: 'Historical Account ID',
      },
    },
  ],
};

const taskConfigs = [
  importHistoricalConfig,
];

const RakeTask = ({ config }) => {
  const [dopeUI, { addFlashMessage }] = useDopeUI();
  const [loading, setLoading] = useState(false);
  const [args, updateArgs, setArgs, resetArgs] = useObjectState({});
  const { taskName, taskLabel, inputs, onSuccess = () => {} } = config;
  const argValues = Object.keys(args).sort().map(key => args[key]);
  const disabled = loading || argValues.some(arg => !arg);

  const rakeParams = {
    task_name: taskName,
    task_args: argValues,
  };

  const handleSumbit = () => {
    setLoading(true);
    dopeClient.post(`/rake_tasks/run`, rakeParams)
      .then(response => {
        addFlashMessage({ type: 'success', body: response.data.message });
        onSuccess(response.data);
      })
      .catch(error => {
        addFlashMessage({ type: 'error', body: 'Task Run Request Failed With Message: ' + error.message });
      })
      .finally(() => {
        setLoading(false);
        resetArgs();
      });
  };

  return (
    <DopeForm
      inputs={inputs}
      values={args}
      onChange={updateArgs}
      onSubmit={handleSumbit}
      submitText={`Run ${taskLabel}`}
      loading={loading}
    />
  );
};

const RakeTasks = () => {
  const taskTabs = taskConfigs.map(taskConfig => {
    const { taskName, taskLabel } = taskConfig;

    return (
      <DopeTabs.Tab key={taskName} name={taskName} label={taskLabel}>
        <RakeTask config={taskConfig} />
      </DopeTabs.Tab>
    );
  });

  return (
    <div className="page">
      <DopeTabs>
        {taskTabs}
      </DopeTabs>
    </div>
  );
};

export default RakeTasks;
